import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon';
import {
  colorBlueBasic,
  colorGreenBasic,
  colorRedBasic,
  colorYellowBasic,
} from 'styles/GlobalStyles';
import {
  NotificationCloseButton,
  NotificationIconContainer,
  NotificationMessageContainer,
  NotificationWrapper,
} from './Notification.style';

type tNotification = {
  message?: string | JSX.Element;
  position?: string;
  show: boolean;
  type?: string;
  customClose?: boolean;
  closeNotification?: Function;
};

const Notification: React.FC<tNotification> = ({
  message = 'Something went wrong. Try again',
  position = 'top-right',
  show = false,
  type = 'error',
  customClose = false,
  closeNotification = () => {},
}): JSX.Element | null => {
  const { t } = useTranslation();

  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    setShowNotification(show);
  }, [show]);

  if (!showNotification) {
    return null;
  }

  const xfn = () => {
    setShowNotification(false);
    if (customClose) {
      closeNotification(false);
    }
  };

  const renderTypeIcon = (notificationType: string) => {
    switch (notificationType) {
      case 'error':
        return <Icon fill={colorRedBasic} icon='error' />;
      case 'info':
        return <Icon fill={colorBlueBasic} icon='info' />;
      case 'success':
        return <Icon fill={colorGreenBasic} icon='checkCircleOutline' />;
      case 'warning':
        return <Icon fill={colorYellowBasic} icon='reportProblem' />;
      default:
        return <Icon fill={colorBlueBasic} icon='info' />;
    }
  };

  return (
    <NotificationWrapper className={position}>
      <NotificationMessageContainer>
        <NotificationIconContainer>{renderTypeIcon(type)}</NotificationIconContainer>
        {typeof message === 'string' ? t(message) : message}
      </NotificationMessageContainer>
      <NotificationCloseButton onClick={() => xfn()}>
        <Icon fill={colorRedBasic} icon='close' />
      </NotificationCloseButton>
    </NotificationWrapper>
  );
};

export default Notification;
