import { useTranslation } from 'react-i18next';
import { FileUpload, Label, Icon, Datepicker, Tooltip2 } from 'components';
import { Flex, Relative } from 'styles/GlobalStyledComponents';
import { colorBlueBasic } from 'styles/GlobalStyles';
import { tSinglePreviewItem } from 'types/views/preview';
import {
  CenteredFlex,
  ContainerWithMargin,
  CommentIconContainer,
  FieldComment,
  Value,
} from './Preview.style';

type tAttachment = {
  id: number;
  name: string;
  size: number;
  url: string;
};

type tPreview = {
  data: tSinglePreviewItem[] | [];
  labelMinWidth?: number;
  comments?: boolean;
  setComment?: Function;
  previewComments?: boolean;
};

const Preview: React.FC<tPreview> = ({
  previewComments = false,
  setComment = () => {},
  data = [],
  labelMinWidth = 90,
  comments = false,
}): JSX.Element => {
  const { t } = useTranslation();

  const transformValue = (type: string, value: any) => {
    switch (type) {
      case 'list':
        return value.map((listItem: any) => {
          const { id, content } = listItem;

          return <div key={id}>{content}</div>;
        });
      case 'date':
        return (
          <CenteredFlex>
            {value ? (
              <Datepicker name='dp' start={value} readOnly />
            ) : (
              <Datepicker name='dp' readOnly />
            )}
          </CenteredFlex>
        );
      case 'date-range':
        return (
          <CenteredFlex>
            {value && value?.start ? (
              <Datepicker
                name='dp'
                variant='range'
                start={value.start}
                end={value.end}
                indefinitely={value?.indefinitely}
                noEndDateAllowed={value?.indefinitely}
                readOnly
              />
            ) : (
              <Datepicker name='dp' variant='range' readOnly />
            )}
          </CenteredFlex>
        );
      case 'attachment':
        return value.map((attachment: tAttachment) => {
          const { id } = attachment;
          return (
            <FileUpload
              key={id}
              withoutGap
              name='attachments'
              disabled
              files={[attachment]}
              errors={[]}
              onChange={() => {}}
            />
          );
        });
      default:
        return value;
    }
  };

  const renderFieldComment = (apiComment: string) => {
    if (!!apiComment) {
      return (
        <FieldComment>
          <b>{t('Comments')}: </b>
          {apiComment}
        </FieldComment>
      );
    }

    return null;
  };

  const renderPreviewItems = () =>
    data.map((previewItem: tSinglePreviewItem, itemIndex: number) => {
      const {
        label,
        type,
        value,
        commentMode,
        comment,
        labelOptional = false,
        elementHidden = false,
        apiComment,
      } = previewItem;
      const transformedValue = type !== 'string' ? transformValue(type, value) : value;

      return !elementHidden ? (
        <ContainerWithMargin key={itemIndex} value='1rem 0'>
          <Flex>
            {!!label ? (
              <Label
                optional={labelOptional}
                styles={{ justifyContent: 'flex-start' }}
                labelMinWidth={labelMinWidth}
              >
                {t(label)}
              </Label>
            ) : null}
            <Relative>
              <Value>{transformedValue}</Value>
              {previewComments && renderFieldComment(apiComment ?? '')}
              {comments && commentMode && (
                <CommentIconContainer>
                  <Tooltip2 tooltip={t('Add comments')}>
                    <Icon
                      width='1rem'
                      height='1rem'
                      asButton
                      icon={!!comment ? 'commentAdded' : 'comment'}
                      fill={colorBlueBasic}
                      onClick={() => setComment(true, label, comment)}
                    />
                  </Tooltip2>
                </CommentIconContainer>
              )}
            </Relative>
          </Flex>
        </ContainerWithMargin>
      ) : null;
    });

  return !data.length ? (
    <div>{t('No data to preview')}</div>
  ) : (
    <ContainerWithMargin value='1rem 0' style={{ lineHeight: '1.5' }}>
      {renderPreviewItems()}
    </ContainerWithMargin>
  );
};

export default Preview;
