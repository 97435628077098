import { useTranslation } from 'react-i18next';
import { Button } from 'components';
import { LeftMargin1Rem } from 'styles/GlobalStyledComponents';
import { ButtonsContainer } from './DiscardAndAcceptButtons.style';

type tDiscardAndAcceptButtons = {
  disabled: boolean;
  onAccept: (() => void) | null;
  onAcceptCopy?: string;
  onDiscard: (() => void) | null;
  onDiscardCopy?: string;
};

const DiscardAndAcceptButtons: React.FC<tDiscardAndAcceptButtons> = ({
  disabled,
  onAccept,
  onAcceptCopy = 'Save',
  onDiscard,
  onDiscardCopy = 'Cancel',
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ButtonsContainer>
      {!!onDiscard && (
        <Button disabled={!!disabled} kind='outlined' variant='red' onClick={onDiscard}>
          {t(onDiscardCopy)}
        </Button>
      )}
      {!!onAccept && (
        <LeftMargin1Rem>
          <Button disabled={!!disabled} variant='green' onClick={onAccept}>
            {t(onAcceptCopy)}
          </Button>
        </LeftMargin1Rem>
      )}
    </ButtonsContainer>
  );
};

export default DiscardAndAcceptButtons;
