import { useTranslation } from 'react-i18next';
import { weekDaysShort } from 'utils/constants';
import { getTodaysDate, extractDate } from 'utils/helpers';

import MonthYearSelect from './MonthYearSelect';

import {
  CalendarContainer,
  DaysGrid,
  WeekDayStyle,
  DayStyle,
  HighlightedDayStyle,
  EmptyDayStyle,
} from './Calendar.style';

export type tDayStyle = {
  selected: boolean;
  today: boolean;
  disabled: boolean;
  grayed?: boolean;
};

export type tHighlightedDayStyle = {
  startRadius: boolean;
  endRadius: boolean;
};

export type tCalendar = {
  variant?: 'single' | 'range';
  startDate: Date;
  endDate?: Date;
  setDate: (val: Date) => void;
  focusDate: Date;
  setFocusDate: (val: Date) => void;
  min?: Date;
  max?: Date;
  highlightFilter?: (val: Date) => boolean;
  disableFilter?: (val: Date) => boolean;
  disabled?: boolean;
  readOnly?: boolean;
};

const today = getTodaysDate();

const Calendar: React.FC<tCalendar> = ({
  variant = 'single',
  startDate,
  endDate = startDate,
  setDate,
  focusDate = startDate,
  setFocusDate,
  min,
  max,
  highlightFilter,
  disableFilter,
  disabled = false,
  readOnly = false,
}) => {
  const { t } = useTranslation();
  const month = focusDate.getMonth() + 1;
  const year = focusDate.getFullYear();

  const outOfRange = (date: Date): boolean => {
    if (min && date.getTime() < min.getTime()) return true;
    if (max && date.getTime() > max.getTime()) return true;
    return false;
  };

  const firstWeekDay = extractDate(new Date(year, month - 1)).weekDay;
  const monthDays = 40 - new Date(year, month - 1, 40).getDate();
  const days = Array.from(new Array(monthDays), (val, index) => index + 1);

  const dayClickHandler = (newDay: number) => {
    const newDate = new Date(year, month - 1, newDay);
    setDate(newDate);
  };

  return (
    <CalendarContainer>
      <div>
        <MonthYearSelect
          month={month}
          year={year}
          setYearMonth={setFocusDate}
          min={min}
          max={max}
          disabled={disabled}
          readOnly={readOnly}
        />
      </div>
      <DaysGrid>
        {weekDaysShort.map((d) => (
          <WeekDayStyle key={d}>{t(d)}</WeekDayStyle>
        ))}
        {firstWeekDay
          ? Array.from(new Array(firstWeekDay - 1), (val, index) => index + 1).map((e) => (
              <EmptyDayStyle key={e} />
            ))
          : null}
        {days.map((d) => {
          const currentDate = new Date(year, month - 1, d);
          const selected =
            (year === startDate.getFullYear() &&
              month === startDate.getMonth() + 1 &&
              d === startDate.getDate()) ||
            (variant === 'range' &&
              year === endDate.getFullYear() &&
              month === endDate.getMonth() + 1 &&
              d === endDate.getDate());
          const now = year === today.year && month === today.month && d === today.day;
          const highlighted =
            (variant === 'range' &&
              currentDate.getTime() > startDate.getTime() &&
              currentDate.getTime() < endDate.getTime()) ||
            (highlightFilter && highlightFilter(currentDate));
          const disab =
            disabled ||
            (disableFilter && disableFilter(currentDate)) ||
            outOfRange(new Date(year, month - 1, d));

          let dayTags = (
            <DayStyle
              onClick={() => dayClickHandler(d)}
              selected={selected}
              today={now}
              disabled={readOnly || disab}
              grayed={!readOnly && disab}
              key={d}
              type='button'
            >
              {d}
            </DayStyle>
          );

          if (highlighted) {
            const start =
              (highlightFilter && !highlightFilter(new Date(year, month - 1, d - 1))) ||
              extractDate(currentDate).weekDay === 1 ||
              d === 1;
            const end =
              (highlightFilter && !highlightFilter(new Date(year, month - 1, d + 1))) ||
              extractDate(currentDate).weekDay === 7 ||
              d === monthDays;
            dayTags = (
              <HighlightedDayStyle startRadius={start} endRadius={end} key={d}>
                {dayTags}
              </HighlightedDayStyle>
            );
          }

          return dayTags;
        })}
      </DaysGrid>
    </CalendarContainer>
  );
};

export default Calendar;
