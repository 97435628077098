import styled from 'styled-components';
import asideImg from 'assets/img/login_aside.jpg';
import { ReactComponent as SmartFMLogo } from 'assets/img/smartfm_logo.svg';

export const Wrapper = styled.div`
  background-color: var(--theme-white);
  display: flex;
  justify-content: space-between;
  height: 100vh;
`;

export const FlexV = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 700px;
`;

export const Aside = styled(FlexV)`
  background-image: url(${asideImg});
  background-position: 100% 0;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin-top: 2rem;
`;

export const GidLogoContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  bottom: 0;
  margin: 1rem;
  margin-bottom: 4rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: var(--theme-mainGrey);
`;

export const SmartFMLogoStyle = styled(SmartFMLogo)`
  width: 340px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem;
  min-width: 420px;
  min-height: 350px;
`;
