import { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addNotification } from 'store/notifications/actions';
import { withRouter, RouteComponentProps } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, useSingleFetch } from 'hooks';
import itsStructureService, { prepareData } from 'services/itsStructure';
import { tGet, tError } from 'types/global';
import { tItsStructure, tSupervisor, tITSUser } from 'types/services/itsStructure';
import { tSupervisors } from 'types/components/itsStructure';
import tStore from 'types/store';
import { transformErrorsFromApi, getAvatarBase64FromFiles } from 'utils/helpers';
import formActions from 'utils/formActions';
import { ExpansionPanelWrapper, FormWrapper } from 'styles/GlobalStyledComponents';
import { personalIdNumberRegex } from 'utils/regex';
import { REQUIRED_FIELDS } from 'utils/requiredFormFields';
import { validateRequiredFields } from 'utils/form';
import {
  ExpansionPanel,
  Button,
  Form,
  Input,
  Column,
  DropdownV2,
  Avatar,
  Spinner,
} from 'components';
import { ButtonWrapper } from '../AddEditFormItsStructure.style';

interface iMainForm extends RouteComponentProps<any> {
  isAddMode?: boolean;
  disabled?: boolean;
  preview?: boolean;
  setId: (id: number | string | null) => void;
  setFormName?: (name: string) => void;
}

export const transformField = (field: string): string => {
  switch (field) {
    case 'first_name':
      return 'firstName';
    case 'last_name':
      return 'lastName';
    case 'position':
      return 'position';
    case 'phone_number':
      return 'phone';
    case 'email':
      return 'email';
    case 'personal_id_number':
      return 'personalIdNumber';
    case 'external_system_id':
      return 'externalSystemId';
    case 'content':
    case 'filename':
      return 'avatar';
    case 'supervisors_ids':
      return 'supervisor';
    default:
      return '__noField__';
  }
};

export const dataModel: tItsStructure = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  personalIdNumber: '',
  externalSystemId: '',
  position: '',
  supervisor: null,
  avatar: [],
};

export const getInitialValues = (data) =>
  !!data && Object.keys(data).length
    ? {
        ...data,
        avatar: Array.isArray(data.avatar) ? [...data.avatar] : [],
      }
    : dataModel;

const MainForm: React.FC<iMainForm> = ({
  isAddMode = false,
  disabled = false,
  preview = false,
  match,
  setId = () => {},
  setFormName = () => {},
}): JSX.Element => {
  const { id } = match.params;
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const permissions = useSelector((state: tStore) => state.user.permissions);
  const canWrite = preview && permissions.includes('tunAddressesWrite');
  const canRemove = !isAddMode && permissions.includes('tunAddressesWrite');

  const [loading, setLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<tItsStructure>(dataModel);
  const [supervisors, setSupervisors] = useState<tSupervisors>({
    data: [],
    error: false,
    errorMessage: '',
    loading: false,
  });

  // TODO_! Check fetch return type
  const {
    fetch: setParamsHandler,
    loading: itsStructureLoading,
    data,
  } = useSingleFetch<tITSUser, tGet>(
    itsStructureService.get,
    'An error occurred while getting ITS structure',
  );
  const [errors, setErrors] = useState<tError[]>([]);

  const setNotificationHandler = (message: string, type = 'error' as 'error' | 'success') => {
    const enableTimeout = type === 'success';
    dispatch(addNotification(t(message), type, enableTimeout));
  };

  const createOrUpdate = async (values: tItsStructure, isAddMode: boolean) => {
    try {
      setLoading(true);
      const avatar = await getAvatarBase64FromFiles(values.avatar);

      const requiredFields = REQUIRED_FIELDS.ITS_STRUCTURE_FORM.MAIN;
      const validationErrors = validateRequiredFields(prepareData(values), requiredFields);
      if (validationErrors.length) {
        return setErrors(transformErrorsFromApi(validationErrors, transformField));
      }

      const result = isAddMode
        ? await itsStructureService.create({ ...values, avatar })
        : await itsStructureService.update({ ...values, avatar }, id);

      const { state } = result;

      if (!state) {
        const { errors: responseErrors } = result;
        if (responseErrors.includes('Internal Server Error')) {
          return setNotificationHandler(t('Something went wrong. Try again'));
        }
        setErrors(transformErrorsFromApi(responseErrors, transformField));
      } else {
        const createdUserId = result.data?.id;
        const message = isAddMode ? t('User was added') : t('User was updated');
        setNotificationHandler(message, 'success');
        setErrors([]);
        if (isAddMode && createdUserId) history.push(`/its-structure/${createdUserId}/edit`);
      }
    } catch (error) {
      if (isAddMode) {
        setNotificationHandler(
          error?.message ?? t('An error occurred while creating the its user account'),
        );
      } else {
        setNotificationHandler(
          error?.message ?? t('An error occurred while updating the user account'),
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = (values: any, isAddMode: boolean) => createOrUpdate(values.values, isAddMode);

  const { values, handleChange, handleSubmit, removeUploadedFile } = useForm({
    initialValues,
    onSubmit: (values) => onSubmit(values, isAddMode),
  });

  useEffect(() => {
    const get = async () => {
      try {
        setLoading(true);
        setSupervisors({ ...supervisors, loading: true });
        const result = await itsStructureService.getAllItsEmployees();
        const supervisorsHelper: tSupervisor[] = result?.data?.results ?? [];

        setSupervisors({
          data: supervisorsHelper.filter((supervisor: tSupervisor) => supervisor.id !== id),
          error: false,
          errorMessage: '',
          loading: false,
        });
      } catch (error) {
        setSupervisors({ data: [], error: true, errorMessage: error.message, loading: false });
      } finally {
        setLoading(false);
      }
    };
    get();
  }, []);

  useEffect(() => {
    const errorsHelper = errors.filter((error) => error.field === '__noField__');
    if (errorsHelper.length > 0) {
      setErrors((prev) => [...prev.filter((error) => error.field !== '__noField__')]);
      setNotificationHandler(errorsHelper.map((error) => error.error).join(', '));
    }
  }, [errors]);

  const fetchData = useCallback(() => {
    if (!!id) setParamsHandler({ id });
  }, [setParamsHandler, id]);

  useEffect(() => {
    fetchData();
    setErrors([]);
  }, [fetchData, preview, disabled]);

  useEffect(() => {
    const initialValues = getInitialValues(data);
    setInitialValues(initialValues);
    setId(data?.id ?? null);
    setFormName(`${data.firstName} ${data.lastName}`);
  }, [data]);

  const {
    avatar,
    firstName,
    lastName,
    phone,
    email,
    personalIdNumber,
    externalSystemId,
    position,
    supervisor,
  }: tItsStructure = values;

  const edit = () => {
    history.push(`/its-structure/${id}/edit`);
  };

  const remove = async () => {
    try {
      setLoading(true);
      const result = await itsStructureService.remove(id);
      const { status, error } = result;
      if (status !== 204) {
        dispatch(addNotification(t(error ?? 'Something went wrong. Try again'), 'error'));
      } else {
        dispatch(addNotification(t('User was archived'), 'success', true));
        history.push(`/its-structure`);
      }
    } catch (error) {
      dispatch(
        addNotification(error ?? error?.msg ?? t('Something went wrong. Try again'), 'error'),
      );
    } finally {
      setLoading(false);
    }
  };

  const actions = formActions([
    { type: 'edit', action: edit, show: canWrite },
    {
      type: 'remove',
      action: remove,
      show: canRemove,
      dialogTitle: 'Delete user',
      dialogMessage: `${t('Are you sure you want to delete user')} ${firstName} ${lastName}?`,
    },
  ]);

  const isLoading = loading || itsStructureLoading || supervisors.loading;

  return (
    <ExpansionPanelWrapper>
      <ExpansionPanel
        hasBottomBorder={!disabled && !preview}
        title={t('Worker data')}
        hasIcon
        iconName='personAdd'
        actions={actions}
      >
        <FormWrapper>
          <Form onSubmit={handleSubmit}>
            <>
              {isLoading && <Spinner />}
              <Column>
                <Avatar
                  errors={errors}
                  isEditable={!disabled && !preview}
                  name='avatar'
                  label={t('Photo')}
                  fileSrc={!!avatar.length ? avatar[0]?.content : ''}
                  onChange={handleChange}
                  removeFile={() => removeUploadedFile(avatar[0]?.filename ?? '', 'avatar', true)}
                  showWithLabel
                  labelOptional
                />
              </Column>
              <Column>
                <Input
                  name='firstName'
                  label='First name'
                  onChange={handleChange}
                  errors={errors}
                  value={firstName}
                  disabled={disabled}
                  preview={preview}
                />
              </Column>
              <Column>
                <Input
                  name='lastName'
                  label='Last name'
                  onChange={handleChange}
                  errors={errors}
                  value={lastName}
                  disabled={disabled}
                  preview={preview}
                />
              </Column>
              <Column>
                <Input
                  name='phone'
                  label='Phone'
                  onChange={handleChange}
                  errors={errors}
                  value={phone}
                  disabled={disabled}
                  preview={preview}
                  allowedExp={new RegExp(/^([0-9]{0,22})$/)}
                />
              </Column>
              <Column>
                <Input
                  name='email'
                  label='E-mail address'
                  onChange={handleChange}
                  errors={errors}
                  value={email}
                  disabled={disabled}
                  preview={preview}
                />
              </Column>
              <Column>
                <Input
                  name='personalIdNumber'
                  label={t('Identity card')}
                  onChange={handleChange}
                  errors={errors}
                  value={personalIdNumber?.toUpperCase() ?? ''}
                  disabled={disabled}
                  preview={preview}
                  placeholder='___ ______'
                  mask={personalIdNumberRegex}
                />
              </Column>
              <Column>
                <Input
                  name='externalSystemId'
                  label='Worker identifier'
                  onChange={handleChange}
                  errors={errors}
                  value={externalSystemId}
                  disabled={disabled}
                  preview={preview}
                />
              </Column>
              <Column>
                <Input
                  name='position'
                  label='Position'
                  onChange={handleChange}
                  errors={errors}
                  value={position}
                  disabled={disabled}
                  preview={preview}
                />
              </Column>
              <Column>
                <DropdownV2
                  options={supervisors.data.map((option) => ({
                    value: option.id,
                    label: option.userNameAndSurname,
                    fieldName: 'supervisor',
                  }))}
                  name='supervisor'
                  label='Supervisor'
                  onChange={handleChange}
                  errors={errors}
                  value={supervisor}
                  disabled={disabled}
                  preview={preview}
                />
              </Column>
              {!disabled && !preview && (
                <ButtonWrapper>
                  <Button variant='green' type='submit'>
                    {t('Save')}
                  </Button>
                </ButtonWrapper>
              )}
            </>
          </Form>
        </FormWrapper>
      </ExpansionPanel>
    </ExpansionPanelWrapper>
  );
};

export default withRouter(MainForm);
