import { useTranslation } from 'react-i18next';
import { Button, ExpansionPanel, Label } from 'components';
import { FlexHWrapper, TopMargin1Rem } from 'styles/GlobalStyledComponents';

type tPriceListBillingActivities = {
  disabled: boolean;
  isAddMode: boolean;
  openRightPanel: ({ form, id, disabled }) => void;
};

const PriceListBillingActivities: React.FC<tPriceListBillingActivities> = ({
  disabled = false,
  isAddMode = false,
  openRightPanel,
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <TopMargin1Rem>
      <ExpansionPanel
        hasBottomBorder
        title={`Price list for billing activities'`}
        hasIcon
        iconName='monetization'
      >
        <FlexHWrapper>
          <Label labelMinWidth={100}>{t(`Price list for billing activities'`)}</Label>
          <Button
            variant='blue'
            disabled={isAddMode}
            onClick={() =>
              openRightPanel({ form: 'PriceListBillingActivities', id: 11123, disabled })
            }
          >
            {t('Open')}
          </Button>
        </FlexHWrapper>
      </ExpansionPanel>
    </TopMargin1Rem>
  );
};

export default PriceListBillingActivities;
