import { tTableHead } from 'types/components/table';

export const tableHead: tTableHead[] = [
  {
    sortable: false,
    name: 'No.',
    key: 'order',
  },
  {
    sortable: true,
    name: 'Worker',
    key: 'userNameAndSurname',
  },
  {
    sortable: false,
    name: 'Identity card series and number',
    key: 'userPersonalIdNumber',
  },
];

export const searchableFields = ['first_name', 'last_name', 'personal_id_number'];
