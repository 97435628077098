import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { tTableHead, tTableBody, tTableActions, tTable, tFilter } from 'types/components/table';
import { useTranslation } from 'react-i18next';
import { Icon, Tooltip, Tooltip2 } from 'components';
import { getContactStatus } from 'utils/helpers';
import {
  colorBlack,
  colorBlueBasic,
  colorGreenBasic,
  colorGreenLight,
  colorGreyBasic,
  colorOrangeBasic,
  colorOrangeLight,
  colorRedDark,
  colorRedLight,
} from 'styles/GlobalStyles';
import { FlexHWrapper } from 'styles/GlobalStyledComponents';
import { useFilters } from 'hooks';
import Filters from './Filters';
import List from './List';

import {
  TableTd,
  TableTr,
  TableThAction,
  TableTh,
  TableClearButton,
  NoDataTd,
  Wrapper,
  MainTable,
  RowMarker,
  TableTdAction,
  SortableButtonContainer,
  TableTdDownload,
} from './Table.style';

type tCell =
  | null
  | string
  | string[]
  | {
      key: string;
      id: string | number;
      element: JSX.Element;
    };

const Table: React.FC<tTable> = ({
  isRowClickable = false,
  onRowClick,
  tableHead,
  tableBodyData,
  tableRowActions = [],
  setSortParams,
  setFilters: setTableFilters = () => {},
  columnFilters = {},
  filtersKey = '',
  overflowStyles = {},
  onDownloadRowDataClick,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [filters, removeFilter, clearFilters, changeFilters, setFilters] = useFilters(filtersKey);
  const [sort, setSort] = useState<{ [key: string]: string }>({ field: '', direction: 'asc' });

  useEffect(() => {
    setTableFilters(filters);
  }, [filters]);

  useEffect(() => {
    if (setSortParams) {
      setSortParams(sort.field, sort.direction);
    }
  }, [sort]);

  useEffect(() => {
    Object.keys(columnFilters).forEach((filter) => {
      if (!columnFilters[filter]) {
        clearFilters(filter);
      }
    });
  }, [columnFilters]);

  const renderActions = (
    actions: tTableActions[],
    id: number | string,
    status: string,
    isActionsBlocked = false,
  ) => {
    if (isActionsBlocked) return null;
    let filteredActions: tTableActions[] = [];
    filteredActions = actions;
    if (status) filteredActions = getContactStatus(status, actions);

    return filteredActions.map((item: tTableActions) => (
      <TableClearButton key={item.key} onClick={() => item.onClick(id)}>
        <Tooltip element={item.icon}>{t(item.name)}</Tooltip>
      </TableClearButton>
    ));
  };

  const renderBackground = (type: string) => {
    switch (type) {
      case 'timeExceeded':
        return `linear-gradient(180deg, ${colorRedLight} 50%, ${colorRedDark} 100%)`;
      case 'timeInDanger':
        return `linear-gradient(180deg, ${colorOrangeLight} 50%, ${colorOrangeBasic} 100%)`;
      case 'inTime':
        return `linear-gradient(180deg, ${colorGreenLight} 50%, ${colorGreenBasic} 100%)`;
      default:
        return colorBlack;
    }
  };

  const onRowClickHandler = (itemId: number | string) => {
    if (onRowClick) {
      onRowClick(itemId);
    } else {
      const {
        location: { pathname },
      } = history;
      history.push(`${pathname}/${itemId}`);
    }
  };

  const renderCell = (element: tCell) => {
    if (element === null) {
      return <div>-</div>;
    }

    if (typeof element === 'string') {
      return element;
    }
    if (Array.isArray(element) && element.length > 1) {
      return element.map((item) => {
        return <div>{item}</div>;
      });
    }

    // eslint-disable-next-line no-prototype-builtins
    if (!Array.isArray(element) && element?.hasOwnProperty('element')) {
      return element.element;
    }

    return element;
  };

  const setSortable = (key: string) => {
    let direction = 'asc';

    if (sort?.field === key && sort?.direction === 'asc') {
      direction = 'desc';
    }

    if (sort?.field === key && sort?.direction === 'desc') {
      return setSort({ field: '', direction: '' });
    }
    setSort({ field: key, direction });
  };

  const tdOnClik = (tableHeadKey: string, itemId: number | string) => {
    if (isRowClickable && tableHeadKey !== 'exportCheckbox') {
      return onRowClickHandler(itemId);
    }
  };

  return (
    <>
      <List filters={filters} removeFilter={removeFilter} removeFilters={() => setFilters({})} />
      <Wrapper style={{ ...overflowStyles }} id='table-scroll'>
        <MainTable>
          <thead>
            <tr>
              {tableHead.map((item: tTableHead) => (
                <TableTh
                  key={item.key}
                  name={item.key}
                  style={{
                    minWidth: `${item?.minWidth ?? 'auto'}`,
                  }}
                >
                  <FlexHWrapper>
                    {t(item.name)}
                    {item.sortable && (
                      <TableClearButton onClick={() => setSortable(item.key)}>
                        <SortableButtonContainer>
                          <div style={{ marginBottom: '-0.5rem' }}>
                            <Icon
                              icon='arrowSortUp'
                              fill={`${
                                !!sort && sort.field === item.key && sort.direction === 'asc'
                                  ? colorBlack
                                  : colorGreyBasic
                              }`}
                              width='1.25rem'
                              height='1.25rem'
                            />
                          </div>
                          <div style={{ marginTop: '-0.5rem' }}>
                            <Icon
                              icon='arrowSortDown'
                              fill={`${
                                !!sort && sort.field === item.key && sort.direction === 'desc'
                                  ? colorBlack
                                  : colorGreyBasic
                              }`}
                              width='1.25rem'
                              height='1.25rem'
                            />
                          </div>
                        </SortableButtonContainer>
                      </TableClearButton>
                    )}
                    {filters && !!item.customFilters && !!item?.filters?.length && (
                      <Filters
                        filters={item.filters}
                        onChange={(val: tFilter[]) => changeFilters(val, item.key)}
                        checked={filters[item.key] ?? []}
                        clearFilters={() => clearFilters(item.key)}
                      />
                    )}
                  </FlexHWrapper>
                </TableTh>
              ))}
              {tableRowActions.length > 0 && (
                <TableThAction key='actions'>{t('Actions')}</TableThAction>
              )}
              {typeof onDownloadRowDataClick === 'function' && <TableThAction> </TableThAction>}
            </tr>
          </thead>
          {tableBodyData.length > 0 && (
            <tbody>
              {tableBodyData.map((item: tTableBody) => {
                const { bold, id: itemId, rowInfo } = item;
                return (
                  <TableTr key={itemId} showPointerCursor={isRowClickable}>
                    {tableHead.map((tableHeadItem: tTableHead) => {
                      return (
                        <TableTd
                          key={tableHeadItem.key}
                          name={tableHeadItem.key}
                          style={{ fontWeight: !!bold ? 'bold' : 'normal' }}
                          onClick={() => tdOnClik(tableHeadItem.key, itemId)}
                        >
                          <>
                            {tableHeadItem.key === 'order' && !!rowInfo && (
                              <Tooltip2
                                tipId={itemId}
                                place='right'
                                tooltip={<span>{rowInfo.copy}</span>}
                              >
                                <RowMarker
                                  style={{
                                    background: renderBackground(rowInfo.type),
                                  }}
                                />
                              </Tooltip2>
                            )}
                            {renderCell(item[tableHeadItem.key])}
                          </>
                        </TableTd>
                      );
                    })}
                    {typeof onDownloadRowDataClick === 'function' && (
                      <TableTdDownload>
                        <Icon
                          icon='download'
                          asButton
                          onClick={onDownloadRowDataClick}
                          fill={colorBlueBasic}
                        />
                      </TableTdDownload>
                    )}
                    {!!tableRowActions.length && (
                      <TableTdAction>
                        {renderActions(tableRowActions, itemId, item.status, item.isActionsBlocked)}
                      </TableTdAction>
                    )}
                  </TableTr>
                );
              })}
            </tbody>
          )}
          {!tableBodyData.length && (
            <tbody>
              <tr>
                <NoDataTd colSpan={tableHead.length + 1}>{t('No results')}</NoDataTd>
              </tr>
            </tbody>
          )}
        </MainTable>
      </Wrapper>
    </>
  );
};

export default Table;
