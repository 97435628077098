import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import auth from './auth/reducer';
import user from './user/reducer';
import notifications from './notifications/reducer';
import messageCounters from './messageCounters/reducer';
import contract from './contract/reducer';

export default combineReducers({
  auth,
  user,
  notifications,
  routing,
  messageCounters,
  contract,
});
