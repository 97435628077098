import { createRef, RefObject, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Notification, FormErrorMessage, Label } from 'components';
import contractsAgent from 'agents/contracts';
import { colorBlueBasic, colorBlueLight, colorRedBasic } from 'styles/GlobalStyles';
import File, { allowedExtension } from 'utils/File';
import { formatBytes } from 'utils/helpers';
import { useFormErrorsReset } from 'hooks';
import {
  FileUploadInput,
  AddFileWrapper,
  FileInfoWrapper,
  FileName,
  FileWrapper,
  FileInfoData,
  TableWrapper,
  LabelWrapper,
  FileSize,
} from './FileUpload.style';

type tFileUpload = {
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  files: any[];
  errors: {
    field: string;
    error: string;
  }[];
  acceptExtensions?: string[];
  attachmentsMaxLength?: number;
  handleSoftDelete?: (item) => void;
  disabled?: boolean;
  withoutGap?: boolean;
  labelOptional?: boolean;
};

const FileUpload: React.FC<tFileUpload> = ({
  name,
  onChange,
  label,
  files,
  errors,
  acceptExtensions = [],
  attachmentsMaxLength = null,
  handleSoftDelete = () => {},
  disabled,
  withoutGap = false,
  labelOptional = false,
}): JSX.Element => {
  const { t } = useTranslation();
  const inputFileRef: RefObject<HTMLInputElement> = createRef();
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const [uploadErrors, setUploadErrors] = useState<string[]>([]);
  const extensions = acceptExtensions.length ? acceptExtensions : allowedExtension;
  const convertedExtensions = extensions.map((ext) => '.' + ext).join(', ');

  const { formElemErrors, resetFormError } = useFormErrorsReset(errors);

  const downloadFile = async (file) => {
    const attachmentsURL = file.url.slice(5);
    const { data } = await contractsAgent.getAttachment(attachmentsURL);
    const link = document.createElement('a');
    link.href = data;
    link.download = file.filename;
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      }),
    );
    setTimeout(() => {
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  };

  useEffect(() => {
    if (Array.isArray(files)) {
      setUploadedFiles(files);
    }
  }, [files]);

  const renderErrorNotification = () =>
    !!uploadErrors.length
      ? uploadErrors.map((error) => (
          <Notification
            show
            message={error}
            key={error}
            customClose
            closeNotification={() => setUploadErrors([])}
          />
        ))
      : null;

  const handleClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const handleChange = (event) => {
    if (!!attachmentsMaxLength && attachmentsMaxLength < uploadedFiles?.length + 1) {
      return setUploadErrors([t('The file attachment limit for this field has been exceeded')]);
    }

    const currentFile = event.target.files[0];
    if (!currentFile) return setUploadErrors(['No files uploaded']);
    const { type, size } = currentFile;

    const fileWithTheSameName = uploadedFiles.filter((file) => file.filename === currentFile.name);
    if (fileWithTheSameName.length) {
      return setUploadErrors(['Cannot upload file. File with the same name is already added']);
    }

    const convertedType = File.convertType(type);
    const errors: string[] = [];
    const sizeError = File.checkFileSize(size);
    const extensionError = File.checkFileExtension(convertedType, extensions);

    if (sizeError) errors.push(t(sizeError));
    if (extensionError) errors.push(t(extensionError));

    setUploadErrors(errors);

    if (errors.length) return;
    resetFormError(event?.target?.name);
    onChange(event);
  };

  const renderFileUploadInput = () => {
    if (!!attachmentsMaxLength && uploadedFiles.length >= attachmentsMaxLength) {
      return null;
    }
    return (
      <AddFileWrapper onClick={handleClick}>
        <FileUploadInput
          ref={inputFileRef}
          accept={convertedExtensions}
          id={name}
          type='file'
          name={name}
          onChange={handleChange}
          key={uploadedFiles.length + uploadErrors.length}
        />
        <Icon icon='add' fill={colorBlueLight} />
      </AddFileWrapper>
    );
  };

  return (
    <TableWrapper style={withoutGap ? { marginBottom: 0 } : {}}>
      <tbody>
        <tr>
          {label && (
            <LabelWrapper>
              <Label optional={labelOptional}>{t(label)}</Label>
            </LabelWrapper>
          )}
          <td>
            {disabled && !uploadedFiles?.length && <Label labelMinWidth={0}>-</Label>}
            {uploadedFiles?.length
              ? uploadedFiles.map((file) => {
                  if (file) {
                    const current = file.currentFile || file;
                    const { size } = current;
                    const currentFileName = file.currentFile
                      ? file.currentFile.name
                      : file.filename;
                    const fileType = currentFileName && currentFileName.split('.')[1];
                    return (
                      <FileWrapper
                        key={`${currentFileName} - ${size}`}
                        style={withoutGap ? { paddingBottom: 0 } : {}}
                      >
                        <Icon
                          icon={File.convertType(fileType || 'fileCopy')}
                          width='2.5rem'
                          height='2.5rem'
                        />
                        <FileInfoWrapper>
                          <FileInfoData>
                            <FileName>{currentFileName}</FileName>
                            <FileSize>{formatBytes(size)}</FileSize>
                          </FileInfoData>
                          {!file.currentFile && (
                            <>
                              <Icon
                                icon='download'
                                asButton
                                onClick={() => downloadFile(file)}
                                fill={colorBlueBasic}
                              />
                              <div style={{ marginLeft: '0.75rem' }} />
                            </>
                          )}
                          {!disabled && (
                            <Icon
                              icon='close'
                              onClick={() => handleSoftDelete(current)}
                              fill={colorRedBasic}
                              asButton
                            />
                          )}
                        </FileInfoWrapper>
                      </FileWrapper>
                    );
                  }
                  return null;
                })
              : null}
            {renderErrorNotification()}

            {!disabled && renderFileUploadInput()}
            <FormErrorMessage name={name} errors={formElemErrors} />
          </td>
        </tr>
      </tbody>
    </TableWrapper>
  );
};

export default FileUpload;
