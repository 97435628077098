import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addNotification } from 'store/notifications/actions';
import { saveStatus } from 'store/contract/actions';
import { withRouter, RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useForm } from 'hooks';
import contractsService from 'services/contracts';
import { validateRequiredFields } from 'utils/form';
import { REQUIRED_FIELDS } from 'utils/requiredFormFields';
import { ExpansionPanel, Form, Input, Row, Column, Dropdown, Button, Spinner } from 'components';
import { FormWrapper } from 'styles/GlobalStyledComponents';
import { tKeyString, tError } from 'types/global';
import { ButtonWrapper } from '../../AddEditFormContracts.style';

export type tSapStatusDataModel = {
  sapId: string;
  status: string;
};

const dataModel = {
  sapId: '',
  status: '',
};

export interface iSapStatusForm extends RouteComponentProps<any> {
  isAddMode?: boolean;
  disabled?: boolean;
  preview?: boolean;
  data: tSapStatusDataModel;
  fetchData: () => void;
}

export const getStatusForApi = (status: string) => {
  switch (status) {
    case 'Aktywna':
      return 'active';
    case 'Nieaktywna':
      return 'inactive';
    case 'Zarchiwizowana':
      return 'archived';
    case 'Wygasająca':
      return 'expired';
    case 'Niedokończona':
      return 'draft';

    default:
      return '';
  }
};

export const getStatusFromApi = (status: string) => {
  switch (status) {
    case 'active':
      return 'Aktywna';
    case 'inactive':
      return 'Nieaktywna';
    case 'archived':
      return 'Zarchiwizowana';
    case 'expired':
      return 'Wygasająca';
    case 'draft':
      return 'Niedokończona';

    default:
      return '';
  }
};

const getInitialValues = (data: tSapStatusDataModel) => {
  if (Object.keys(data).length > 0) {
    return {
      sapId: data.sapId ?? '',
      status: getStatusFromApi(data.status),
    };
  }
  return dataModel;
};

const SapStatusForm: React.FC<iSapStatusForm> = ({
  isAddMode = false,
  disabled = false,
  preview = false,
  match,
  data,
  fetchData,
}): JSX.Element => {
  const { id } = match.params;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<tSapStatusDataModel>(dataModel);
  const [errors, setErrors] = useState<tError[]>([]);
  const [statuses, setStatuses] = useState<string[]>([]);

  useEffect(() => {
    setErrors([]);
  }, [fetchData]);

  useEffect(() => {
    const get = async () => {
      try {
        setLoading(true);
        if (!disabled) {
          const result = await contractsService.getStatuses();
          setStatuses(result);
        }
      } catch (error) {
        dispatch(
          addNotification(
            error?.message ?? t('An error occurred while getting contract statuses'),
            'error',
          ),
        );
      } finally {
        setLoading(false);
      }
    };
    get();
  }, [disabled]);

  useEffect(() => {
    const initialValues = getInitialValues(data);
    dispatch(saveStatus({ status: data?.status }));
    setInitialValues(initialValues);
  }, [data]);

  const setResponseErrors = (setter: Function, errors: tKeyString[]) => {
    const transformField = (field: string): string => {
      switch (field) {
        case 'sap_id':
          return 'sapId';
        case 'status':
          return 'status';
        default:
          return '__noField__';
      }
    };

    setter(
      errors
        ? errors?.map((error: tKeyString) => {
            const { key, msg } = error;
            return {
              field: transformField(key),
              error: msg,
            };
          })
        : [],
    );
  };

  const onSubmit = async (values: tSapStatusDataModel) => {
    try {
      setLoading(true);
      const { sapId, status: contractStatus } = values;
      const statusName = getStatusForApi(contractStatus);
      const bodyRequest = {
        sap_id: !!sapId ? sapId : null,
        status: statusName,
      };

      const requiredFields =
        statusName === 'active' ? REQUIRED_FIELDS.CONTRACTS_FORM.SAP_STATUS_FORM : ['status'];
      const validationErrors = validateRequiredFields(bodyRequest, requiredFields);
      if (validationErrors.length) return setResponseErrors(setErrors, validationErrors);

      const result = await contractsService.patchContract(id, bodyRequest);
      if (!!result) {
        fetchData();
        setErrors([]);
        dispatch(addNotification(t('The contract has been updated'), 'success', true));
        dispatch(saveStatus({ status: result?.data?.status }));
      }
    } catch (error) {
      if (!!error?.data) {
        setResponseErrors(setErrors, error?.data);
      } else {
        dispatch(
          addNotification(
            error?.message ?? t('An error occurred while saving the contract data'),
            'error',
          ),
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit } = useForm({
    initialValues,
    onSubmit: (values) => onSubmit(values.values),
  });

  const { sapId, status } = values;

  return (
    <Form onSubmit={handleSubmit}>
      <ExpansionPanel title='SAP and status' hasIcon iconName='article' hasBottomBorder={false}>
        <FormWrapper>
          {loading && <Spinner />}
          <Row justifyContent='space-between'>
            <Column>
              <Input
                name='sapId'
                label='SAP contract number'
                onChange={handleChange}
                errors={errors}
                value={sapId}
                disabled={disabled}
                preview={preview}
              />
            </Column>
            <Column>
              <Dropdown
                name='status'
                label='Contract status'
                options={statuses.map((status) => ({
                  value: getStatusFromApi(status),
                  label: getStatusFromApi(status),
                  fieldName: 'status',
                }))}
                onChange={handleChange}
                errors={errors}
                value={status}
                disabled={disabled}
                preview={preview}
              />
            </Column>
          </Row>
          {!preview && (
            <ButtonWrapper>
              <Button variant='green' type='submit' disabled={disabled || isAddMode}>
                {t('Save')}
              </Button>
            </ButtonWrapper>
          )}
        </FormWrapper>
      </ExpansionPanel>
    </Form>
  );
};

export default withRouter(SapStatusForm);
