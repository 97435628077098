import i18n from 'i18next';
import { includesAll } from 'utils/helpers';

export type tIssuePreviewVariant =
  | 'acceptance'
  | 'comments'
  | 'confirmation'
  | 'verification'
  | 'assign'
  | 'changeAssign'
  | 'resend'
  | 'previewComments'
  | 'createComplaint'
  | 'reject'
  | 'subcontractorAcceptance'
  | 'changeTechnician'
  | '';

export const getTitleCopy = (status: string) => {
  if (status === 'Usługa niepotwierdzona') return 'Issue reception'; // TODO_! ang/id status?
  if (status === 'Nowy') return 'Issue acceptance';
  return 'Issue preview';
};

export const getPreviewVariant = (
  statusFromApi: string,
  actions: {},
  commentsMode = false as boolean,
): tIssuePreviewVariant => {
  const actionsFromApi = Object.keys({ ...actions });
  const acceptanceActions = ['open', 'cancel', 'edit'];
  const commentActions = ['view_comments', 'comment_and_send_to_regional_manager'];
  const verificationActions = ['assign_technician', 'reject_issue'];
  const rejectActions = ['reject_issue', 'change_technician'];
  const changeTechnicianActions = ['change_technician'];
  const assignActions = ['assign_technician'];
  const assignSubcontractorTechnicianActions = ['assign_subcontractors_technician'];
  const changeAssignActions = ['assign_technician', 'reject_issue'];
  const resendActions = ['edit_and_send_to_tech_manager'];
  const previewCommentsActions = ['view_comments'];
  const createComplaintActions = ['create_complaint'];
  const confirmationActions = ['confirm_service', 'create_complaint'];

  if (statusFromApi === 'otwarte' && includesAll(actionsFromApi, commentActions) && commentsMode) {
    return 'comments';
  }
  if (statusFromApi === 'nowe' && includesAll(actionsFromApi, acceptanceActions)) {
    return 'acceptance';
  }
  if (includesAll(actionsFromApi, confirmationActions)) {
    return 'confirmation';
  }
  if (includesAll(actionsFromApi, assignSubcontractorTechnicianActions)) {
    return 'subcontractorAcceptance';
  }
  if (includesAll(actionsFromApi, verificationActions)) {
    return 'verification';
  }
  if (includesAll(actionsFromApi, assignActions)) {
    return 'assign';
  }
  if (includesAll(actionsFromApi, changeAssignActions)) {
    return 'changeAssign';
  }
  if (includesAll(actionsFromApi, resendActions)) {
    return 'resend';
  }
  if (includesAll(actionsFromApi, previewCommentsActions)) {
    return 'previewComments';
  }
  if (includesAll(actionsFromApi, createComplaintActions)) {
    return 'createComplaint';
  }
  if (includesAll(actionsFromApi, rejectActions)) {
    return 'reject';
  }
  if (includesAll(actionsFromApi, changeTechnicianActions)) {
    return 'changeTechnician';
  }
  return '';
};

export type tGetIssueCopyData = {
  commentsMode: boolean;
  status: string;
  issueId: string;
  issueTitle: string;
  isIssueArchived: boolean;
};

export const getIssueCopy = (statusFromApi: string, actions: {}, data: tGetIssueCopyData) => {
  const { status, issueId, issueTitle, isIssueArchived, commentsMode } = data;
  const variant = getPreviewVariant(statusFromApi, actions, commentsMode);

  switch (variant) {
    case 'comments': {
      return {
        title: i18n.t('Issue acceptance'),
        crumbs: [
          { url: '/issues', label: getTitleCopy(status) },
          { url: `/issues/${issueId}`, label: `${i18n.t('Issue')} ${issueTitle}` },
          { url: `/issues/${issueId}/comments`, label: 'Issue comments' },
        ],
        showStatus: false,
        showActionPanel: true,
      };
    }
    case 'acceptance': {
      return {
        title: i18n.t('Issue acceptance'),
        crumbs: [
          { url: '/issues', label: 'Issues' },
          { url: `/issues/${issueId}`, label: `${i18n.t('Issue')} ${issueTitle}` },
          { url: `/issues/${issueId}/acceptance`, label: 'Issue acceptance' },
        ],
        showStatus: true,
        showActionPanel: true,
      };
    }
    case 'confirmation': {
      return {
        title: i18n.t('Issue confirmation'),
        crumbs: [
          { url: '/issues', label: 'Issues' },
          { url: `/issues/${issueId}/complaint`, label: `${i18n.t('Issue')} ${issueTitle}` },
        ],
        showStatus: true,
        showCreatedAt: true,
        showVisitData: true,
        showActionPanel: true,
      };
    }
    case 'verification': {
      return {
        title: i18n.t('Issue verification'),
        crumbs: [
          { url: '/issues', label: 'Issues' },
          { url: `/issues/${issueId}`, label: `${i18n.t('Issue')} ${issueTitle}` },
          { url: `/issues/${issueId}/verification`, label: 'Issue verification' },
        ],
        showCreatedAt: true,
        showStatus: true,
        showTechnicianSelect: true,
        showActionPanel: true,
      };
    }
    case 'assign': {
      return {
        title: i18n.t('Assign service technician'),
        crumbs: [
          { url: '/issues', label: 'Issues' },
          { url: `/issues/${issueId}`, label: `${i18n.t('Issue')} ${issueTitle}` },
          { url: `/issues/${issueId}/assign`, label: 'Assign service technician' },
        ],
        showCreatedAt: true,
        showStatus: true,
        showTechnicianSelect: true,
        showActionPanel: true,
      };
    }
    case 'subcontractorAcceptance': {
      return {
        title: i18n.t('Issue acceptance'),
        crumbs: [
          { url: '/issues', label: 'Issues' },
          { url: `/issues/${issueId}/assign`, label: `${i18n.t('Issue')} ${issueTitle}` },
        ],
        showCreatedAt: true,
        showStatus: true,
        showSubcontractorTechnicianSelect: true,
        showActionPanel: true,
      };
    }
    case 'resend': {
      return {
        title: i18n.t('Issue improvement'),
        crumbs: [
          { url: '/issues', label: 'Issues' },
          { url: `/issues/${issueId}`, label: `${i18n.t('Issue')} ${issueTitle}` },
        ],
        showPreviewEdit: true,
      };
    }
    case 'previewComments': {
      return {
        title: i18n.t('Issue preview'),
        crumbs: [
          { url: '/issues', label: 'Issues' },
          { url: `/issues/${issueId}`, label: `${i18n.t('Issue')} ${issueTitle}` },
        ],
        showStatus: true,
        showPreviewComments: true,
      };
    }
    case 'createComplaint': {
      return {
        title: i18n.t('Issue preview'),
        crumbs: [
          { url: '/issues', label: 'Issues' },
          { url: `/issues/${issueId}`, label: `${i18n.t('Issue')} ${issueTitle}` },
        ],
        showCreateComplaintBtn: true,
      };
    }
    case 'reject': {
      return {
        title: i18n.t('Issue preview'),
        crumbs: [
          { url: '/issues', label: 'Issues' },
          { url: `/issues/${issueId}`, label: `${i18n.t('Issue')} ${issueTitle}` },
        ],
        showActionPanel: true,
        showTechnicianSelect: true,
      };
    }
    case 'changeTechnician': {
      return {
        title: i18n.t('Issue preview'),
        crumbs: [
          { url: '/issues', label: 'Issues' },
          { url: `/issues/${issueId}`, label: `${i18n.t('Issue')} ${issueTitle}` },
        ],
        showActionPanel: true,
        showTechnicianSelect: true,
        showStatus: false,
      };
    }
    default: {
      return {
        title: i18n.t('Issue preview'),
        crumbs: isIssueArchived
          ? [
              { url: '/issues', label: 'Issues' },
              { url: `/issues/finished`, label: 'Finished issues' },
              { url: `/issues/${issueId}`, label: `${i18n.t('Finished issue')} ${issueTitle}` },
            ]
          : [
              { url: '/issues', label: 'Issues' },
              { url: `/issues/${issueId}`, label: `${i18n.t('Issue')} ${issueTitle}` },
            ],
        showStatus: false,
        showActionPanel: true,
      };
    }
  }
};
