import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon';
import { colorRedBasic } from 'styles/GlobalStyles';
import { RemoveButton } from './RemoveShapeButton.style';

type tRemoveShapeButton = {
  resetMapPath: () => void;
};

export const RemoveShapeButton = ({ resetMapPath }: tRemoveShapeButton) => {
  const { t } = useTranslation();

  return (
    <RemoveButton onClick={resetMapPath}>
      <Icon icon='deleteBin' fill={colorRedBasic} />
      {t('Remove area')}
    </RemoveButton>
  );
};
