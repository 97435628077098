import { useState, useEffect, useCallback } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { useDispatch } from 'react-redux';
import { addNotification } from 'store/notifications/actions';
import { useTranslation } from 'react-i18next';
import { useSingleFetch, useForm } from 'hooks';
import { Button, Form, Spinner } from 'components';
import contractsService from 'services/contracts';
import { toBase64, dateToDashYYYMMDD, catchErrors } from 'utils/helpers';
import { tContractFormValues, tAttachmentsToDelete } from 'types/views/contracts';
import { tGet, tError, tDropdownOption } from 'types/global';
import { validateRequiredFields } from 'utils/form';
import { REQUIRED_FIELDS } from 'utils/requiredFormFields';
import { ContractDataForm } from './ContractDataForm';
import { FinancialConditionsForm } from './FinancialConditionsForm';
import { ButtonWrapper } from '../../AddEditFormContracts.style';
import { setResponseErrors } from './errors';

export type tFormValues = {
  clientsName: {
    label: string;
    value: string;
  };
  contractsName: string;
  clientsContractSupervisor: string;
  contractDuration: Date;
  contractDurationEnd: Date;
  contractContent: any[];
  attachments: any[];
  status: string;
};

interface iContractData extends RouteComponentProps<any> {
  isAddMode?: boolean;
  disabled?: boolean;
  preview?: boolean;
  setFormName?: (name: string) => void;
  setCompanyData: React.Dispatch<React.SetStateAction<tDropdownOption | null>>;
}

const ContractData: React.FC<iContractData> = ({
  isAddMode = false,
  disabled = false,
  preview = false,
  setFormName = () => {},
  match,
  history,
  setCompanyData,
}): JSX.Element => {
  const { t } = useTranslation();
  const { id } = match.params;
  const dispatch = useDispatch();

  const [attachmentsToDelete, setAttachmentsToDelete] = useState<tAttachmentsToDelete>({});
  const [initialValues, setInitialValues] = useState<{}>({});
  const [loading, setLoading] = useState<boolean>(false);

  const {
    fetch: setParamsHandler,
    loading: loadingContractData,
    data,
  } = useSingleFetch<tContractFormValues, tGet>(
    contractsService.get,
    'An error occurred while getting contract data',
  );

  const [errors, setErrors] = useState<tError[]>([]);
  const fetchData = useCallback(() => {
    if (!!id) setParamsHandler({ id: Number(id) });
  }, [setParamsHandler, id]);

  useEffect(() => {
    fetchData();
    setErrors([]);
  }, [fetchData, preview, disabled]);

  const getInitialValues = (data) => {
    if (Object.keys(data).length > 0) return data;
    return {};
  };

  const setNotificationHandler = (message: string, type = 'error' as 'error' | 'success') => {
    const enableTimeout = type === 'success';
    dispatch(addNotification(t(message), type, enableTimeout));
  };

  useEffect(() => {
    const initialValues = getInitialValues(data);
    setInitialValues(initialValues);
  }, [data]);

  const onSubmit = async ({ values }: { values: tContractFormValues }, isAddMode: boolean) => {
    try {
      setLoading(true);
      const {
        contractsName,
        clientsContractSupervisor,
        contractDurationEnd,
        clientsName,
        billingCurrency,
        VATRate,
        paymentDeadline,
        invoicingInterval,
        billingPeriodTo,
        acceptanceOfSettlement,
        timeForAcceptance,
        valorizationIndicator,
        valorizationPeriod,
        nightModeStart,
        nightModeEnd,
        contractDuration,
        contractContent,
        attachments,
      } = values;

      const filteredContractContent = contractContent?.length
        ? contractContent.filter((file) => file.fieldName === 'contractContent')
        : [];
      const filteredAttachments = attachments?.length
        ? attachments.filter((file) => file.fieldName === 'attachments')
        : [];

      const contractAttachmentData = await Promise.all(
        filteredContractContent?.length
          ? filteredContractContent.map(async (contractAttachment) => {
              const { currentFile } = contractAttachment;
              const base64FullData = await toBase64(currentFile);
              const base64Content = base64FullData.split('base64,')[1];
              return {
                filename: currentFile.name || '',
                content: base64Content || '',
              };
            })
          : [],
      );
      const attachmentsData = await Promise.all(
        filteredAttachments?.length
          ? filteredAttachments.map(async (attachment) => {
              const { currentFile } = attachment;
              const base64FullData = await toBase64(currentFile);
              const base64Content = base64FullData.split('base64,')[1];
              return {
                filename: currentFile.name || '',
                content: base64Content || '',
              };
            })
          : [],
      );
      const contractStartDate = !!contractDuration ? dateToDashYYYMMDD(contractDuration) : null;
      const contractDurationEndDate = !!contractDurationEnd
        ? dateToDashYYYMMDD(contractDurationEnd)
        : null;
      const indefinitely = contractDurationEnd === null || contractDuration === contractDurationEnd;
      const contractDurationEndData =
        !!contractDurationEndDate && !indefinitely ? contractDurationEndDate : null;

      const financialConditions = {
        currency: billingCurrency?.value,
        vat_rate: VATRate?.value,
        payment_due_date: paymentDeadline?.value,
        payment_due_rule: paymentDeadline?.optionLabel || null,
        billing_interval: invoicingInterval?.value,
        billing_interval_unit: invoicingInterval?.optionLabel,
        billing_period_days: billingPeriodTo,
        billing_approval: acceptanceOfSettlement || false,
        billing_approval_period: timeForAcceptance || null,
        valorizations: valorizationIndicator?.length
          ? valorizationIndicator.map((val) => ({
              id: val.id,
              name: val.value,
            }))
          : [],
        valorization_interval: valorizationPeriod?.value,
        valorization_interval_unit: valorizationPeriod?.optionLabel,
        night_time_start: nightModeStart || null,
        night_time_end: nightModeEnd || null,
      };

      const data = {
        name: contractsName,
        admin_name: clientsContractSupervisor,
        contract_attachment: contractAttachmentData || [],
        attachments: attachmentsData || [],
        start: contractStartDate,
        end: contractDurationEndData,
        sap_id: null,
        client_id: clientsName?.value,
        financial_conditions: financialConditions,
      };

      const requiredFields = [
        ...REQUIRED_FIELDS.CONTRACTS_FORM.MAIN,
        ...REQUIRED_FIELDS.CONTRACTS_FORM.FINANCIAL_CONDITIONS,
      ];
      const validationErrors = validateRequiredFields(
        { ...data, ...financialConditions },
        requiredFields,
      );
      if (validationErrors.length) return setResponseErrors(setErrors, validationErrors);

      const response = isAddMode
        ? await contractsService.createContract(data)
        : await contractsService.editContract(id, data, attachmentsToDelete);

      const {
        response: { data: result },
        error,
      } = response;

      if (!!result.id) {
        const message = isAddMode ? t('Contract was added') : t('Contract was updated');
        if (!!error) setNotificationHandler(error);
        else setNotificationHandler(message, 'success');

        setErrors([]);
        history.push(`/contracts/${result.id}/edit`);
        await fetchData();
      } else {
        setNotificationHandler(t('Something went wrong. Try again'));
      }
    } catch (error) {
      const setters = { setNotificationHandler, setResponseErrors, setErrors };
      catchErrors(error, setters);
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, removeUploadedFile } = useForm({
    initialValues,
    onSubmit: (values) => onSubmit(values, isAddMode),
  });

  const {
    clientsName,
    contractsName,
    clientsContractSupervisor,
    contractDuration,
    contractDurationEnd,
    contractContent,
    billingCurrency,
    VATRate,
    invoicingInterval,
    billingPeriodTo,
    acceptanceOfSettlement,
    timeForAcceptance,
    nightModeStart,
    nightModeEnd,
    valorizationIndicator,
    paymentDeadline,
    paymentDeadlineDropdown,
    valorizationPeriod,
    attachments,
    status,
  } = values;

  const contractDataValues: tFormValues = {
    clientsName,
    contractsName,
    clientsContractSupervisor,
    contractDuration,
    contractDurationEnd,
    contractContent,
    attachments,
    status,
  };

  const financialConditionsValues = {
    billingCurrency,
    VATRate,
    invoicingInterval,
    billingPeriodTo,
    acceptanceOfSettlement,
    timeForAcceptance,
    nightModeStart,
    nightModeEnd,
    valorizationIndicator,
    paymentDeadline,
    paymentDeadlineDropdown,
    valorizationPeriod,
  };

  useEffect(() => {
    setFormName(contractsName);
  }, [clientsName]);

  const handleAttachmentsSoftDelete = (attachment, fieldName) => {
    const file = { ...attachment, name: attachment.filename || attachment.name };
    if (!!file.id) {
      const fileToAdd = !!attachmentsToDelete[fieldName]
        ? [...attachmentsToDelete[fieldName], file.id]
        : [file.id];
      setAttachmentsToDelete({
        ...attachmentsToDelete,
        [fieldName]: fileToAdd,
      });
    }
    removeUploadedFile(file.name, fieldName);
  };

  const isLoading = loading || loadingContractData;

  return (
    <Form onSubmit={handleSubmit}>
      <>
        {isLoading && <Spinner />}
        <ContractDataForm
          disabled={disabled}
          preview={preview}
          isAddMode={isAddMode}
          handleChange={handleChange}
          errors={errors}
          values={contractDataValues}
          handleSoftDelete={handleAttachmentsSoftDelete}
          setCompanyData={setCompanyData}
        />
        <FinancialConditionsForm
          values={financialConditionsValues}
          disabled={disabled}
          preview={preview}
          handleChange={handleChange}
          errors={errors}
        />
        {!disabled && !preview && (
          <ButtonWrapper>
            <Button variant='green' type='submit'>
              {t('Save')}
            </Button>
          </ButtonWrapper>
        )}
      </>
    </Form>
  );
};

export default withRouter(ContractData);
