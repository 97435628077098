/* eslint-disable camelcase */
import contractsAgent from 'agents/contracts';
import { tGet, tDropdownOption } from 'types/global';
import { tPeriodicActivityValues } from 'types/views/contracts';
import { tPeriodicActivityCreate, tPeriodicActivityUpdate } from 'types/services/contracts';
import { AxiosResponse } from 'axios';

export type tPeriodicActivity = { id: number; name: string };

const getAll = async ({ id }: tGet) => {
  if (!!id) {
    const response: AxiosResponse = await contractsAgent.getAllPeriodicActivities(+id);
    const {
      data,
    }: {
      data: tPeriodicActivity;
    } = response;
    return { data: { results: data } };
  }
};

const get = async ({ id }: tGet) => {
  const { data }: AxiosResponse = await contractsAgent.getPeriodicActivity(+id);
  const {
    name,
    type,
    appliance_industry: applianceIndustry,
    system,
    group,
    appliance,
    service_activities: serviceActivities,
    mruk_sites: mrukSites,
    maintenance_interval: maintenanceInterval,
    maintenance_interval_unit: maintenanceIntervalUnit,
  } = data;

  const formData: tPeriodicActivityValues = {
    name,
    type: type
      ? {
          value: type,
          label: type,
        }
      : null,
    applianceIndustry: applianceIndustry?.industry?.name
      ? {
          value: applianceIndustry?.industry?.name,
          label: applianceIndustry?.industry?.name,
        }
      : null,
    system: system?.name
      ? {
          value: system?.name,
          label: system?.name,
        }
      : null,
    group: group?.name
      ? {
          value: group?.name,
          label: group?.name,
        }
      : null,
    appliance: appliance?.name
      ? {
          value: appliance?.name,
          label: appliance?.name,
        }
      : null,
    serviceActivities: serviceActivities?.map((el) => el?.name) ?? [],
    mrukSites: mrukSites?.map((el) => ({ id: el.id, value: el.name, label: el.name })),
    maintenanceIntervals: {
      optionLabel: maintenanceIntervalUnit,
      value: maintenanceInterval,
    },
  };

  return {
    data: formData,
  };
};

const create = async (contractId: number, body: tPeriodicActivityCreate) => {
  try {
    const { data, status }: AxiosResponse = await contractsAgent.createPeriodicActivity(
      contractId,
      body,
    );
    return { state: true, errors: [], data, status };
  } catch (error) {
    return { state: false, errors: !!error?.data ? error.data : [], data: {} };
  }
};

const update = async (activityId: number, body: tPeriodicActivityUpdate) => {
  try {
    const { data, status }: AxiosResponse = await contractsAgent.updatePeriodicActivity(
      activityId,
      body,
    );
    return { state: true, errors: [], data, status };
  } catch (error) {
    return { state: false, errors: !!error?.data ? error.data : [], data: {} };
  }
};

const remove = (id: number) =>
  contractsAgent
    .deletePeriodicActivity(id)
    .then((response) => {
      const { status } = response;
      return { status, error: '' };
    })
    .catch((error) => {
      const { data, status } = error;
      return {
        status,
        error: !!data.length && data[0]?.msg ? data[0].msg : 'Something went wrong. Try again',
      };
    });

const getPeriodicActivityTypes = async () => {
  const response: AxiosResponse = await contractsAgent.getPeriodicActivityTypes();
  const { data }: { data: string[] } = response;
  const results: tDropdownOption[] =
    data.map((type, index) => ({ id: index, value: type, label: type })) ?? [];
  return { data: { results } };
};

const getPeriodicActivityMaintenanceUnits = async () => {
  const response: AxiosResponse = await contractsAgent.getPeriodicActivityMaintenanceUnits();
  const { data }: { data: string[] } = response;
  const results: tDropdownOption[] =
    data.map((unit, index) => ({ id: index, value: unit, label: unit })) ?? [];
  return { data: { results } };
};

const getLocations = async ({ id }: tGet) => {
  const response: AxiosResponse = await contractsAgent.getLocations(+id);
  const {
    data: { results },
  } = response;
  const mapResults: tDropdownOption[] =
    results.map((loc) => ({ id: loc.id, value: loc.name, label: loc.name })) ?? [];
  return { data: { results: mapResults } };
};

export default {
  getAll,
  get,
  create,
  update,
  remove,
  getPeriodicActivityTypes,
  getPeriodicActivityMaintenanceUnits,
  getLocations,
};
