import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addNotification } from 'store/notifications/actions';
import {
  Column,
  ExpansionPanel,
  Row,
  Input,
  TreeView,
  EditElement,
  DiscardAndAcceptButtons,
  Spinner,
} from 'components';
import { FormWrapper } from 'styles/GlobalStyledComponents';
import structureService, { tStructureNode } from 'services/structures';
import { useForm, useSingleFetch } from 'hooks';
import TreeViewUtils from 'utils/TreeView';
import { tGet, tError, tUUIDName } from 'types/global';
import { REQUIRED_FIELDS } from 'utils/requiredFormFields';
import { validateRequiredFields } from 'utils/form';
import { ExpansionPanelWrapper } from '../../AddEditFormContracts.style';

type tSingleLocationForm = {
  disabled: boolean;
  id: string | null;
  preview: boolean;
  openRightPanel: ({ form, id, disabled }) => void;
};

type tDataModel = {
  name: string;
  clientMPK: string;
};

const dataModel = {
  name: '',
  clientMPK: '',
};

const SingleLocationForm: React.FC<tSingleLocationForm> = ({
  disabled = false,
  preview = false,
  id: localizationId,
  openRightPanel,
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = useState<tDataModel>(dataModel);
  const [types, setTypes] = useState<tUUIDName[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorsHelper, setErrorsHelper] = useState<tError[]>([]);

  const {
    fetch: setParamsHandler,
    loading: loadingData,
    data,
    errors,
  } = useSingleFetch<{ data: tStructureNode }, tGet>(structureService.getCurrentStructure);

  const setApiErrorHandler = (error: string) => dispatch(addNotification(t(error), 'error'));

  const setNotificationHandler = (notification: string) =>
    dispatch(addNotification(t(notification), 'success'));

  const errorsHandler = (errors, defaultCopy, main = false) => {
    if (Array.isArray(errors?.data)) {
      const inputFields = ['name', 'client_mpk'];
      if (errors.status === 400) {
        return setErrorsHelper(
          errors.data
            .filter((error) => main && inputFields.includes(error.key))
            .map(({ key, msg }) => ({
              field: key,
              error: msg,
            })),
        );
      }
      return setApiErrorHandler(
        errors.data
          .filter(({ key }) => !main || !inputFields.includes(key))
          .map((single) => single.msg)
          .join(', '),
      );
    }
    setApiErrorHandler(errors?.message ?? defaultCopy);
  };

  const fetchData = useCallback(() => {
    if (!!localizationId) {
      setParamsHandler({ id: localizationId });
    }
  }, [setParamsHandler, localizationId]);

  const getInitialValues = (data: tDataModel) => {
    if (Object.keys(data).length > 0) {
      return {
        name: data.name,
        clientMPK: data.clientMPK,
      };
    }
    return dataModel;
  };

  const patchElement = async (id, data, main = false) => {
    try {
      setLoading(true);
      setErrorsHelper([]);
      const result = await structureService.patchCurrentStructure(id, {
        ...data,
      });
      if (!!result) {
        setNotificationHandler('Location was updated');
        fetchData();
      }
    } catch (errors) {
      errorsHandler(errors, t('An error occurred while saving element from structure'), main);
    } finally {
      setLoading(false);
    }
  };

  const removeElement = async (id: string) => {
    try {
      setLoading(true);
      const result = await structureService.removeElement(id);
      if (!!result) fetchData();
    } catch (error) {
      errorsHandler(errors, t('An error occurred while removing element from structure'));
    } finally {
      setLoading(false);
    }
  };

  const addElement = async (name, typeId, parent) => {
    try {
      setLoading(true);
      if (!name) return;
      const result = await structureService.addElement(name, typeId, parent);
      if (!!result) fetchData();
    } catch (error) {
      errorsHandler(errors, t('An error occurred while adding element from structure'));
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = (values: any) => {
    const requiredFields = REQUIRED_FIELDS.CONTRACTS_FORM.LOCATION.LOCATION_FORM;
    const validationErrors = validateRequiredFields({ name: values?.name }, requiredFields);
    if (validationErrors.length) {
      return setErrorsHelper(
        validationErrors.map(({ key, msg }) => ({
          field: key,
          error: msg,
        })),
      );
    }
    return patchElement(
      localizationId,
      {
        name: values?.name || '',
        client_mpk: values.clientMPK,
      },
      true,
    );
  };

  const getAddElementFor = (typeName, parent) => {
    switch (typeName) {
      case 'kondygnacja': {
        const typeId = types.find((type) => type.name === 'obszar');
        if (!typeId) return null;
        return () => (
          <EditElement action={(val) => addElement(val, typeId.id, parent)}>
            {t('Add area')}
          </EditElement>
        );
      }
      case 'obszar': {
        const typeId = types.find((type) => type.name === 'pomieszczenie');
        if (!typeId) return null;
        return () => (
          <EditElement action={(val) => addElement(val, typeId.id, parent)}>
            {t('Add room')}
          </EditElement>
        );
      }
      default:
        return null;
    }
  };

  const addElementToTree = (treeFromApi, parentId) => {
    let treeHelper: any = [];
    treeFromApi.forEach((tree) => {
      const addons = preview
        ? {}
        : {
            add: getAddElementFor(tree.type, tree.id),
            edit: (clickHandler) => (
              <EditElement
                type='element'
                action={(val) => {
                  if (!val) return;
                  return patchElement(tree.id, {
                    name: val,
                  });
                }}
                clickHandler={clickHandler}
              >
                {tree.name}
              </EditElement>
            ),
          };
      treeHelper.push({
        ...tree,
        ...addons,
        parent: tree.type === 'kondygnacja' ? null : tree.parent || parentId,
      });
      const children = addElementToTree(tree.children, tree.id);
      treeHelper = [...treeHelper, ...children];
    });
    return treeHelper;
  };

  const prepareTree = useCallback(
    () => addElementToTree(data.data?.children ?? [], localizationId),
    [data, localizationId, types],
  );
  const { values, handleChange } = useForm({
    initialValues,
    onSubmit: (values) => onSubmit(values),
  });

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const get = async () => {
      try {
        const types = await structureService.getLocationTypes();
        setTypes(types.data);
      } catch (error) {
        setApiErrorHandler(error?.message ?? t('An error occurred while getting locations types'));
      }
    };
    get();
  }, []);

  useEffect(() => {
    if (!!data.data) {
      setInitialValues(
        getInitialValues({
          name: data?.data?.name ?? '',
          clientMPK: data?.data?.client_mpk ?? '', // eslint-disable-line camelcase
        }),
      );
    }
  }, [data, localizationId]);

  const { name, clientMPK } = values;
  const storeyType = types.find((type) => type.name === 'kondygnacja');

  const topLevelActionComponent = (
    <EditElement action={(val) => addElement(val, storeyType?.id ?? null, localizationId)}>
      {t('Add storey')}
    </EditElement>
  );

  const isLoading = loading || loadingData;

  return (
    <ExpansionPanelWrapper>
      <ExpansionPanel hasBottomBorder title='Location' hasIcon iconName='place'>
        <FormWrapper>
          {isLoading && <Spinner />}
          <Row>
            <Column>
              <Input
                name='name'
                label='Location name'
                onChange={handleChange}
                errors={errorsHelper}
                value={name}
                disabled={disabled}
                preview={preview}
              />
            </Column>
            <Column>
              <Input
                name='clientMPK'
                label='MPK on the clients side'
                onChange={handleChange}
                errors={errorsHelper}
                value={clientMPK}
                disabled={disabled}
                preview={preview}
                labelOptional
              />
            </Column>
            <Column>
              <TreeView
                data={TreeViewUtils.createTreeStructure({
                  items: prepareTree(),
                  depthActionLevel: 2,
                })}
                title={name}
                itemsActions={
                  preview
                    ? []
                    : [
                        {
                          key: 'ia1',
                          icon: 'close',
                          fill: '#FF3E4A',
                          onClick: removeElement,
                        },
                      ]
                }
                topLevelActionComponent={preview ? null : topLevelActionComponent}
                isOpen
              />
            </Column>
          </Row>
        </FormWrapper>
      </ExpansionPanel>
      {!preview && (
        <DiscardAndAcceptButtons
          disabled={disabled}
          onAccept={() => onSubmit({ name, clientMPK })}
          onDiscard={() => openRightPanel({ form: '', id: null, disabled })}
        />
      )}
    </ExpansionPanelWrapper>
  );
};

export default SingleLocationForm;
