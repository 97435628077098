import styled, { css } from 'styled-components';
import { CenteredTitleStyle } from './ExpansionPanel';

export const ExpansionButton = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
  height: 1.5rem;
  outline: none;
`;

export const ExpansionChildrenContainer = styled.div`
  margin: 1rem 0;
  padding: 0 1rem;
`;

export const ExpansionIconContainer = styled.div`
  padding-right: 1rem;
`;

export const ExpansionLineGap = styled.div`
  border-bottom: 1px solid var(--theme-mainGrey);
`;

export const ExpansionTitle = styled.div<CenteredTitleStyle>`
  font-size: ${({ centered }) => (centered ? '0.75rem' : '1.125rem')};
  text-transform: ${({ centered }) => (centered ? 'uppercase' : 'none')};
  font-weight: bold;
  line-height: 1.5rem;

  ${({ style }) =>
    style &&
    css`
      ...style
    `}
`;

export const ExpansionOptionsWrapper = styled.div`
  display: flex;
  margin-right: 1rem;
`;

export const ExpansionTitleContainer = styled.div`
  border-bottom: 1px solid var(--theme-mainGrey);
  color: var(--theme-mainGrey);
  display: flex;
  justify-content: space-between;
  padding: 0 1rem 1rem;
`;

export const ExpansionCenteredContainer = styled.div`
  color: var(--theme-mainDarkGrey);
  display: flex;
  justify-content: center;
  padding: 1rem 0;
`;
