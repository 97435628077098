import { Wrapper, Loader } from './Spinner.style';

const Spinner: React.FC = (): JSX.Element => {
  return (
    <Wrapper>
      <Loader />
    </Wrapper>
  );
};

export default Spinner;
