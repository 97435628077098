import { tAuthState, tAction } from 'types/store';
import types from './types';

const initialState: tAuthState = {
  loading: false,
  errors: [],
  kc: null,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, action: tAction): tAuthState => {
  switch (action.type) {
    case types.LOGIN_SUCCESS: {
      return {
        ...state,
      };
    }
    case types.LOGIN_FAILURE: {
      return {
        ...state,
        errors: action?.error ? [...state.errors, action.error] : [...state.errors],
      };
    }
    case types.LOGOUT_SUCCESS: {
      return {
        ...state,
        kc: null,
      };
    }
    case types.LOGOUT_FAILURE: {
      return {
        ...state,
        errors: action?.error ? [...state.errors, action.error] : [...state.errors],
      };
    }
    case types.SET_KEYCLOAK: {
      return {
        ...state,
        kc: action.payload,
      };
    }
    case types.SET_LOADING_AUTH: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    default:
      return state;
  }
};
