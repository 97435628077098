import { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import tStore from 'types/store';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { colorRedBasic } from 'styles/GlobalStyles';
import { logout } from 'store/auth/actions';
import { Icon } from 'components';
import { getModules } from 'menuModules/modules';
import { useFetchNewIssues } from 'hooks';
import { commonElements } from 'utils/helpers';
import { logoutIdleTime, logoutButtonShowTimer } from 'utils/constants';
import { Ul, Li, NavCopy, MenuItemButton } from './Menu.style';

interface iMenu extends RouteComponentProps<any> {
  closeMenu: () => void;
}

const Menu: React.FC<iMenu> = ({ history, closeMenu }): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { fetchAllIssues } = useFetchNewIssues();
  const permissions = useSelector((state: tStore) => state.user.permissions);
  const counters = useSelector((state: tStore) => state.messageCounters);
  const idleTime = useSelector((state: tStore) => state.user.idleTime);
  const [activeUrl, setActiveUrl] = useState<string>('/issues');

  useEffect(() => {
    const storedUrl = localStorage.getItem('activeUrl');
    if (storedUrl) {
      const storedActiveUrl = String(localStorage.getItem('activeUrl'));
      setActiveUrl(storedActiveUrl);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('activeUrl', activeUrl);
  }, [activeUrl]);

  const logoutHandler = () => {
    dispatch(logout());
  };

  const getModulesFiltered = () => {
    const modules = getModules(counters);
    return modules.filter(
      (module) => !!commonElements(permissions, module.permissions ?? []).length,
    );
  };

  const onMenuItemClick = (to) => {
    fetchAllIssues();
    setActiveUrl(to);
    history.push(to);
    closeMenu();
  };

  const remainingTime = logoutIdleTime - idleTime >= 0 ? logoutIdleTime - idleTime : 0;
  const hours = String(Math.floor(remainingTime / 60));
  const minutes = String(remainingTime % 60).padStart(2, '0');
  const modulesFiltered = getModulesFiltered();

  return (
    <Ul>
      {modulesFiltered.map((single) => (
        <Li key={single.id}>
          <MenuItemButton
            active={activeUrl === single.to}
            onClick={() => onMenuItemClick(single.to)}
            type='button'
          >
            <single.icon /> <NavCopy>{t(single.label)}</NavCopy>
          </MenuItemButton>
        </Li>
      ))}
      <Li onClick={logoutHandler}>
        <NavLink to='/' exact>
          <Icon icon='power' fill={colorRedBasic} />
          <NavCopy>
            {t('Logout')}
            {logoutButtonShowTimer && ` (${hours}:${minutes})`}
          </NavCopy>
        </NavLink>
      </Li>
    </Ul>
  );
};

export default withRouter(Menu);
