import { useCallback, useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addNotification } from 'store/notifications/actions';
import { useFetch, useForm, useSingleFetch, useFormErrorsReset } from 'hooks';
import settlementModesService from 'services/settlementModes';
import {
  Button,
  Column,
  Dropdown,
  DropdownV2,
  DropdownWithList,
  ExpansionPanel,
  Form,
  Icon,
  Input,
  Row,
  Spinner,
  Label,
} from 'components';
import {
  Absolute,
  BorderGap,
  CenterWithGaps,
  Flex,
  FormWrapper,
  LeftMargin1Rem,
  Relative,
} from 'styles/GlobalStyledComponents';
import { validateRequiredFieldsWithLoc, validateRequiredFields } from 'utils/form';
import { catchErrors, snakeToStr } from 'utils/helpers';
import { ErrorMessage } from 'components/FormErrorMessage/FormErrorMessage.style';
import {
  tAccountingModeBodyRequest,
  tSingleAccountingMode,
  tAttributeAPI,
} from 'types/services/contracts';
import { tGet, tEnumsResult, tError, tFieldValidationError } from 'types/global';
import { REQUIRED_FIELDS } from 'utils/requiredFormFields';
import { ExpansionPanelWrapper } from '../../AddEditFormContracts.style';
import {
  AttributesContainer,
  ButtonsContainer,
  RemoveAttributeButtonContainer,
  Paragraph,
} from './SingleSettlementModeForm.style';

const dataModel: Omit<tSingleAccountingMode, 'attributes'> = {
  settlementModeName: '',
  dayType: null,
  hours: null,
  hoursOfValidity: null,
  industry: [],
  reactionTime: '',
  unit: null,
};

interface iSingleSettlementModesForm extends RouteComponentProps<any> {
  disabled?: boolean;
  preview?: boolean;
  id: number | string | null;
  openRightPanel: Function;
  isSubcontractorContract?: boolean;
  clientContractId?: number | null;
}

type tStringOrNumber = string | number;

type tTransformedAttribute = {
  id: number;
  value: string;
  unit: string;
  errorIndex: number | null;
};

type tResponseError = {
  loc: tStringOrNumber[];
  key: string;
  msg: string;
};

type tLocalError = {
  field: string;
  error: string;
  errorIndex: null | number;
};

type tOnSubmitValues = {
  dayType: { value: string; label: string };
  hours: { value: string; label: string };
  hoursOfValidity: { value: string; label: string };
  industry: { id: tStringOrNumber; value: string }[];
  reactionTime: number;
  settlementModeName: string;
  unit: { value: string; label: string };
};

const getInitialValues = (data: tSingleAccountingMode, setAttributes: Function) => {
  if (Object.keys(data).length > 0) {
    const {
      attributes,
      dayType,
      settlementModeName,
      hours,
      hoursOfValidity,
      industry,
      reactionTime,
      unit,
    } = data;

    setAttributes(
      attributes.map((item, index: number) => {
        const {
          id,
          value,
          unit: { name: unitName },
        } = item;
        return {
          id,
          value,
          unit: unitName,
          errorIndex: index,
        };
      }),
    );

    const result = {
      dayType,
      hours,
      hoursOfValidity,
      industry,
      reactionTime,
      settlementModeName,
      unit,
    };

    return result;
  }

  return dataModel;
};

const SingleSettlementModeForm: React.FC<iSingleSettlementModesForm> = ({
  disabled = false,
  preview = false,
  id: accountingModeId,
  openRightPanel,
  match,
  isSubcontractorContract = false,
  clientContractId,
}): JSX.Element => {
  const { id: contractId } = match.params;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [initialValues, setInitialValues] = useState<any>({});
  const [attributes, setAttributes] = useState<any[]>([]);
  const [filteredErrors, setFilteredErrors] = useState<tError[]>([]);
  const { formElemErrors, resetFormError } = useFormErrorsReset(filteredErrors);

  const {
    fetch: setParamsHandler,
    loading,
    data,
  } = useSingleFetch<tSingleAccountingMode, tGet>(
    settlementModesService.get,
    'An error occurred while getting settlement mode',
  );
  const [errors, setErrors] = useState<any[]>([]);

  useEffect(() => {
    setFilteredErrors(errors);
  }, [errors]);

  const {
    fetch: setParamsHandlerAccountingModesDaysTypes,
    loading: loadingAccountingModesDaysTypes,
    data: dataAccountingModesDaysTypes,
  } = useFetch<tEnumsResult>(
    settlementModesService.getAccountingModesDaysTypes,
    'Something went wrong. Try again',
  );
  const fetchDataAccountingModesDaysTypes = useCallback(
    () => setParamsHandlerAccountingModesDaysTypes(),
    [setParamsHandlerAccountingModesDaysTypes],
  );
  useEffect(() => {
    fetchDataAccountingModesDaysTypes();
  }, [fetchDataAccountingModesDaysTypes]);

  const {
    fetch: setParamsHandlerAccountingModesHoursTypes,
    loading: loadingAccountingModesHoursTypes,
    data: dataAccountingModesHoursTypes,
  } = useFetch<tEnumsResult>(
    settlementModesService.getAccountingModesHoursTypes,
    'Something went wrong. Try again',
  );
  const fetchDataAccountingModesHoursTypes = useCallback(
    () => setParamsHandlerAccountingModesHoursTypes(),
    [setParamsHandlerAccountingModesHoursTypes],
  );
  useEffect(() => {
    fetchDataAccountingModesHoursTypes();
  }, [fetchDataAccountingModesHoursTypes]);

  const {
    fetch: setParamsHandlerAccountingModesValidityHoursTypes,
    loading: loadingAccountingModesValidityHoursTypes,
    data: dataAccountingModesValidityHoursTypes,
  } = useFetch<tEnumsResult>(
    settlementModesService.getAccountingModesValidityHoursTypes,
    'Something went wrong. Try again',
  );
  const fetchDataAccountingModesValidityHoursTypes = useCallback(
    () => setParamsHandlerAccountingModesValidityHoursTypes(),
    [setParamsHandlerAccountingModesValidityHoursTypes],
  );
  useEffect(() => {
    fetchDataAccountingModesValidityHoursTypes();
  }, [fetchDataAccountingModesValidityHoursTypes]);

  const {
    fetch: setParamsHandlerAccountingModesReactionTimeUnits,
    loading: loadingAccountingModesReactionTimeUnits,
    data: dataAccountingModesReactionTimeUnits,
  } = useFetch<tEnumsResult>(
    settlementModesService.getAccountingModesReactionTimeUnits,
    'Something went wrong. Try again',
  );
  const fetchDataAccountingModesReactionTimeUnits = useCallback(
    () => setParamsHandlerAccountingModesReactionTimeUnits(),
    [setParamsHandlerAccountingModesReactionTimeUnits],
  );
  useEffect(() => {
    fetchDataAccountingModesReactionTimeUnits();
  }, [fetchDataAccountingModesReactionTimeUnits]);

  const {
    fetch: setParamsHandlerIndustries,
    loading: loadingIndustries,
    data: dataIndustries,
  } = useFetch<tEnumsResult>(
    settlementModesService.getIndustriesAssignedToContract,
    'Something went wrong. Try again',
  );
  const fetchDataIndustries = useCallback(() => {
    setParamsHandlerIndustries(
      clientContractId && isSubcontractorContract
        ? { contractId: clientContractId }
        : { contractId },
    );
  }, [setParamsHandlerIndustries]);

  useEffect(() => {
    fetchDataIndustries();
  }, [fetchDataIndustries]);

  const {
    fetch: setParamsHandlerSlaPrices,
    loading: loadingSlaPrices,
    data: dataSlaPrices,
  } = useFetch<tEnumsResult>(
    settlementModesService.getSlaPrices,
    'Something went wrong. Try again',
  );
  const fetchDataSlaPrices = useCallback(
    () => setParamsHandlerSlaPrices(),
    [setParamsHandlerSlaPrices],
  );
  useEffect(() => {
    fetchDataSlaPrices();
  }, [fetchDataSlaPrices]);

  const fetchData = useCallback(() => {
    if (!!accountingModeId) {
      setErrors([]);
      setParamsHandler({
        id: accountingModeId,
      });
    } else {
      setAttributes([]);
      setInitialValues(dataModel);
    }

    setLoadingData(
      loading &&
        loadingAccountingModesDaysTypes &&
        loadingAccountingModesHoursTypes &&
        loadingAccountingModesValidityHoursTypes &&
        loadingAccountingModesReactionTimeUnits &&
        loadingIndustries &&
        loadingSlaPrices,
    );
  }, [setParamsHandler, accountingModeId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setInitialValues(getInitialValues(data, setAttributes));
  }, [data]);

  const setNotificationHandler = (message: string, type = 'error' as 'error' | 'success') => {
    const enableTimeout = type === 'success';
    dispatch(addNotification(t(message), type, enableTimeout));
  };

  const setResponseErrors = (setter: Function, errors: tResponseError[]) => {
    const transformField = (field: string, locErrorIndex: tStringOrNumber | null): string => {
      switch (field) {
        case 'name':
          return 'settlementModeName';
        case 'days_type':
          return 'dayType';
        case 'hours_type':
          return 'hours';
        case 'validity_hours_type':
          return 'hoursOfValidity';
        case 'reaction_time':
          return 'reactionTime';
        case 'reaction_time_unit':
          return 'unit';
        case 'industry_ids':
          return 'industry';
        case 'attributes':
          return 'attributes';
        case 'value':
          if (typeof locErrorIndex === 'number') return `value-${locErrorIndex}`;

          return 'value';
        case 'sla_price_id':
          if (typeof locErrorIndex === 'number') return `attribute-${locErrorIndex}`;

          return 'attribute';
        default:
          return '__noField__';
      }
    };

    if (Array.isArray(errors) && !!errors.length) {
      setter(
        errors.map((error: tResponseError) => {
          const { key, msg, loc } = error;
          const locParam: tStringOrNumber | null = loc?.length > 1 ? loc[1] : null;
          return {
            field: transformField(key, locParam),
            error: msg,
            errorIndex: locParam,
          };
        }),
      );
    }
  };

  const addOrUpdatingAccountingMode = async ({
    bodyRequest,
    contractId,
  }: {
    bodyRequest: Omit<tAccountingModeBodyRequest, 'contract_id'>;
    contractId: tStringOrNumber;
  }) => {
    try {
      setLoadingData(true);
      const result = !accountingModeId
        ? await settlementModesService.addAccountingMode(
            bodyRequest,
            contractId,
            isSubcontractorContract,
          )
        : await settlementModesService.updateAccountingMode(
            { ...bodyRequest, contract_id: contractId },
            accountingModeId,
          );
      const { state } = result;

      if (state) {
        openRightPanel({ form: '', id: null });
      } else {
        const { errors: responseErrors } = result;
        return setResponseErrors(setErrors, responseErrors);
      }
    } catch (error) {
      const setters = { setNotificationHandler, setResponseErrors, setErrors };
      catchErrors(error, setters);
    } finally {
      setLoadingData(false);
    }
  };

  const onSubmit = (values: { values: tOnSubmitValues }) => {
    const {
      values: { dayType, hours, hoursOfValidity, industry, reactionTime, settlementModeName, unit },
    } = values;

    const industries =
      Array.isArray(industry) && !!industry.length
        ? industry.map((industry: { id: tStringOrNumber }) => {
            const { id } = industry;
            return id;
          })
        : [];

    const transformedAttributes =
      Array.isArray(attributes) && !!attributes.length
        ? attributes.map((attribute: { unit: string; value: number; errorIndex: number }) => {
            const slaPrice = dataSlaPrices.find(
              (slaPrice) => slaPrice.label === attribute.unit && slaPrice.value,
            );
            return {
              value: attribute?.value ?? null,
              sla_price_id: slaPrice?.value ?? null,
              index: attribute?.errorIndex ?? null,
            };
          })
        : [];

    const bodyRequest: Omit<tAccountingModeBodyRequest, 'contract_id'> = {
      name: settlementModeName,
      days_type: dayType?.value ?? '',
      hours_type: hours?.value ?? '',
      validity_hours_type: hoursOfValidity?.value ?? '',
      reaction_time: reactionTime,
      reaction_time_unit: unit?.value ?? '',
      industry_ids: industries,
      attributes: transformedAttributes,
    };

    const requiredFields = REQUIRED_FIELDS.CONTRACTS_FORM.SINGLE_SETTLEMENT_MODE_FORM.MAIN;
    const validationErrorsMain = validateRequiredFields(bodyRequest, requiredFields);
    const validationErrorsAttributes = validateRequiredFieldsWithLoc(
      transformedAttributes,
      REQUIRED_FIELDS.CONTRACTS_FORM.SINGLE_SETTLEMENT_MODE_FORM.ATTRIBUTES,
    );
    const validationErrors: tFieldValidationError[] = [];
    if (validationErrorsMain.length) {
      validationErrors.push(...validationErrorsMain.map((err) => ({ ...err, loc: [] })));
    }
    if (validationErrorsAttributes.length) validationErrors.push(...validationErrorsAttributes);
    if (validationErrors.length) return setResponseErrors(setErrors, validationErrors);

    addOrUpdatingAccountingMode({ bodyRequest, contractId });
  };

  const { values, handleChange, handleSubmit } = useForm({
    initialValues,
    onSubmit: (values: any) => onSubmit(values),
  });

  const onChange = (event) => {
    handleChange(event);
    if (event?.fieldName) return setFilteredErrors(resetFormError(event.fieldName));
    return setFilteredErrors(resetFormError(event.target.name));
  };

  const { dayType, hours, hoursOfValidity, industry, reactionTime, settlementModeName, unit } =
    values;

  const updateFieldChanged = (field: string, value: tStringOrNumber, itemId: number) => {
    setAttributes(
      attributes.map((item: { id: number; value: string; unit: string }) => {
        if (item.id === itemId) {
          return { ...item, [field]: value };
        }

        return item;
      }),
    );
  };

  const renderAttributeError = () => {
    const foundedError = errors.filter((error: { field: string }) => error.field === 'attributes');

    return foundedError.length > 0 ? <ErrorMessage>{foundedError[0].error}</ErrorMessage> : null;
  };

  const updateAttributes = (item: Pick<tTransformedAttribute, 'errorIndex'>, itemId: number) => {
    const updatedErrors: Omit<tLocalError, 'error'>[] = [];
    const removedAttributeErrorIndex = attributes.find(
      (attribute: Pick<tTransformedAttribute, 'errorIndex'>) =>
        attribute.errorIndex === item.errorIndex,
    )?.errorIndex;

    setAttributes(
      attributes
        .filter((attribute: Pick<tAttributeAPI, 'id'>) => attribute.id !== itemId)
        .map((attribute: tAttributeAPI, index: number) => ({
          ...attribute,
          errorIndex: index,
        })),
    );

    errors.forEach((error: Omit<tLocalError, 'error'>) => {
      const { errorIndex } = error;

      if (errorIndex === null) updatedErrors.push(error);
      else if (removedAttributeErrorIndex > errorIndex) updatedErrors.push(error);
      else if (removedAttributeErrorIndex < errorIndex) {
        updatedErrors.push({
          ...error,
          errorIndex: errorIndex - 1,
          field: `${error.field.split('-')[0]}-${errorIndex - 1}`,
        });
      }
    });

    setErrors(updatedErrors);
  };

  const onAddAttributeClick = () => {
    const filteredErrors = formElemErrors.filter((error) => error.field !== 'attributes');
    setErrors(filteredErrors);
    setAttributes([
      ...attributes,
      {
        id: new Date().getTime(),
        value: '',
        unit: '',
        errorIndex: attributes.length,
      },
    ]);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Absolute>
        <ExpansionPanelWrapper>
          {loadingData ? (
            <Spinner />
          ) : (
            <>
              <ExpansionPanel
                shouldExpanded={false}
                hasBottomBorder={false}
                title='Settlement modes'
                hasIcon
                iconName='payments'
              >
                <FormWrapper>
                  <Row justifyContent='space-between'>
                    <Column>
                      <Input
                        name='settlementModeName'
                        label={t('Settlement mode name')}
                        onChange={onChange}
                        errors={formElemErrors}
                        value={settlementModeName}
                        disabled={disabled}
                        preview={preview}
                      />
                    </Column>
                    <Column>
                      <DropdownV2
                        name='dayType'
                        label='Day type'
                        options={dataAccountingModesDaysTypes.map(({ value, label }) => ({
                          value,
                          label: t(snakeToStr(label)),
                          fieldName: 'dayType',
                        }))}
                        onChange={onChange}
                        errors={formElemErrors}
                        value={
                          !!dayType?.value
                            ? { value: dayType.value, label: t(snakeToStr(dayType.label)) }
                            : null
                        }
                        disabled={disabled}
                        preview={preview}
                      />
                    </Column>
                    <Column>
                      <DropdownV2
                        name='hours'
                        label='Hours'
                        options={dataAccountingModesHoursTypes.map(({ value, label }) => ({
                          value,
                          label: t(snakeToStr(label)),
                          fieldName: 'hours',
                        }))}
                        onChange={onChange}
                        errors={formElemErrors}
                        value={
                          !!hours?.value
                            ? { value: hours.value, label: t(snakeToStr(hours.label)) }
                            : null
                        }
                        disabled={disabled}
                        preview={preview}
                      />
                    </Column>
                    <Column>
                      <DropdownV2
                        name='hoursOfValidity'
                        label='Hours of validity'
                        options={dataAccountingModesValidityHoursTypes.map(({ value, label }) => ({
                          value,
                          label: t(snakeToStr(label)),
                          fieldName: 'hoursOfValidity',
                        }))}
                        onChange={onChange}
                        errors={formElemErrors}
                        value={
                          !!hoursOfValidity?.value
                            ? {
                                value: hoursOfValidity.value,
                                label: t(snakeToStr(hoursOfValidity.label)),
                              }
                            : null
                        }
                        disabled={disabled}
                        preview={preview}
                      />
                    </Column>
                    <Column col={preview ? 12 : 6}>
                      <Input
                        name='reactionTime'
                        label={t('Reaction time')}
                        onChange={onChange}
                        errors={formElemErrors}
                        value={
                          preview
                            ? `${reactionTime || '-'} ${t(unit?.label ?? '', {
                                count: +reactionTime,
                              })}`
                            : reactionTime
                        }
                        disabled={disabled}
                        preview={preview}
                      />
                    </Column>
                    {!preview && (
                      <Column col={6}>
                        <DropdownV2
                          name='unit'
                          label='Unit'
                          value={
                            !!unit?.value
                              ? {
                                  value: unit.value,
                                  label: t(unit.label, { count: +reactionTime }),
                                }
                              : null
                          }
                          options={dataAccountingModesReactionTimeUnits.map(({ value, label }) => ({
                            value,
                            label: t(label, { count: +reactionTime }),
                            fieldName: 'unit',
                          }))}
                          onChange={onChange}
                          errors={formElemErrors}
                          disabled={disabled}
                          preview={preview}
                        />
                      </Column>
                    )}
                    <Column>
                      <DropdownWithList
                        dropdownDisabled={disabled}
                        preview={preview}
                        dropdownErrors={formElemErrors}
                        dropdownLabel='Industries'
                        optionsData={dataIndustries}
                        optionFieldName='industry'
                        handleChange={onChange}
                        defaultItems={!!industry && !!industry.length ? industry : []}
                      />
                    </Column>
                  </Row>
                </FormWrapper>
              </ExpansionPanel>
              <BorderGap />
              <AttributesContainer>
                <Flex>
                  <Label
                    styles={{ justifyContent: 'flex-start', marginTop: preview ? '0' : '0.5rem' }}
                  >
                    {t('Attributes')}
                  </Label>
                  {!!attributes && !!attributes.length ? (
                    <Column col={12}>
                      {attributes.map((item: tTransformedAttribute, index: number) => {
                        const { id: itemId } = item;

                        return (
                          <Relative key={itemId}>
                            <Flex>
                              <Column col={7}>
                                <Dropdown
                                  name={`attribute-${index}`}
                                  labelMinWidth={0}
                                  options={dataSlaPrices}
                                  onChange={(target) =>
                                    updateFieldChanged('unit', target.label, itemId)
                                  }
                                  errors={formElemErrors}
                                  value={attributes[index].unit}
                                  disabled={disabled}
                                  preview={preview}
                                />
                              </Column>
                              <Column col={5} offset={2}>
                                <Input
                                  name={`value-${index}`}
                                  labelMinWidth={0}
                                  onChange={({ target: { value } }) =>
                                    updateFieldChanged('value', value, itemId)
                                  }
                                  errors={formElemErrors}
                                  value={attributes[index].value}
                                  disabled={disabled}
                                  preview={preview}
                                />
                              </Column>
                              <RemoveAttributeButtonContainer>
                                {!disabled && (
                                  <Icon
                                    asButton
                                    icon='close'
                                    fill='red'
                                    onClick={() => updateAttributes(item, itemId)}
                                  />
                                )}
                              </RemoveAttributeButtonContainer>
                            </Flex>
                          </Relative>
                        );
                      })}
                      {renderAttributeError()}
                    </Column>
                  ) : (
                    <Column col={12}>
                      <Paragraph>{t('No attributes')}</Paragraph>
                      {renderAttributeError()}
                    </Column>
                  )}
                </Flex>
              </AttributesContainer>
              {!preview && (
                <CenterWithGaps>
                  <Button
                    disabled={!!disabled}
                    icon='addCircle'
                    variant='blue'
                    onClick={onAddAttributeClick}
                  >
                    {t('Add')}
                  </Button>
                </CenterWithGaps>
              )}
              <BorderGap />
              <ButtonsContainer>
                <Button
                  kind='outlined'
                  variant='red'
                  onClick={() => openRightPanel({ form: '', id: null })}
                >
                  {t(preview ? 'Close' : 'Cancel')}
                </Button>
                {!preview && (
                  <LeftMargin1Rem>
                    <Button disabled={!!disabled} type='submit' variant='green'>
                      {t('Save')}
                    </Button>
                  </LeftMargin1Rem>
                )}
              </ButtonsContainer>
            </>
          )}
        </ExpansionPanelWrapper>
      </Absolute>
    </Form>
  );
};

export default withRouter(SingleSettlementModeForm);
