import { tGet } from 'types/global';
import { AxiosResponse } from 'axios';
import usersAgent, { tGetAllUsersParamsAPI } from 'agents/users';
import contractAgent from 'agents/contracts';
import { tITSUser, tSupervisor, tItsStructureList } from 'types/services/itsStructure';
import { tGetAllUsersParams } from 'types/services/users';
import { tGetUserAPI, tCreateUserAPI } from 'types/api/users';
import usersService from 'services/users';
import { sortArrayById } from 'utils/helpers';
import { itsCompanyId } from 'utils/constants';

const getAll = async ({
  limit,
  offset = 0,
  search = '',
  searchFields = [],
  kind = 'own',
}: tGetAllUsersParams) => {
  const params: tGetAllUsersParamsAPI = { offset, kind };

  if (limit) params.limit = limit;
  if (search) params.search = search;
  if (searchFields.length) params.search_fields = searchFields.join(',');

  const result = await usersAgent.getAll(params).then((response) => {
    const {
      status,
      data: { count, results: users },
    } = response;

    const results: tItsStructureList[] = users.map((item, index: number) => {
      const {
        id,
        email,
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber,
        position,
        external_system_id: externalSystemId,
      } = item;
      const order: number = index + 1 + offset;
      const userNameAndSurname = `${firstName} ${lastName}`;

      return {
        id,
        order,
        email,
        userNameAndSurname,
        phoneNumber,
        position,
        externalSystemId: externalSystemId ?? '',
      };
    });

    return {
      data: {
        count,
        results: sortArrayById(results),
      },
      status,
    };
  });

  return result;
};

const getAllItsEmployees = async () => {
  const result = await usersAgent.getAll({ company_id: itsCompanyId }).then((response) => {
    const {
      status,
      data: { results: users, count },
    } = response;

    const results: tSupervisor[] = users.map((item) => {
      const { id, first_name: firstName, last_name: lastName } = item;
      const userNameAndSurname = `${firstName} ${lastName}`;

      return {
        id,
        userNameAndSurname,
      };
    });

    return {
      data: {
        count,
        results,
      },
      status,
    };
  });

  return result;
};

const getAllItsLocations = async () => {
  const params = {
    impel_location: true,
  };
  return usersAgent.getAddresses(params).then((response) => {
    const results = response.data.results.map((result) => {
      const { name, id } = result;

      return {
        name,
        id,
      };
    });

    const { status } = response;
    return {
      data: { ...response.data, results },
      status,
    };
  });
};

const remove = (id: string | number) => usersService.remove(id);

const getItsUserLocation = ({ id }: tGet) => contractAgent.getItsUserLocation(id);

const putItsUserLocation = (id: string | number, locations: (string | number)[]) =>
  contractAgent.putItsUserLocation(id, locations).then(() => true);

const get = async ({ id }: tGet) => {
  const result: AxiosResponse<tGetUserAPI> = await usersAgent.get(id);
  const {
    data: {
      avatar,
      email,
      first_name: firstName,
      last_name: lastName,
      phone_number: phone,
      position,
      external_system_id: externalSystemId,
      personal_id_number: personalIdNumber,
      supervisors,
    },
  } = result;

  let avatarHelper: string[] = [];
  let extension = '';
  let prefix = '';
  const avatarIsAvailable = !!avatar;
  if (avatarIsAvailable && avatar?.filename) {
    avatarHelper = avatar.filename.split('.');
    extension = avatarHelper[avatarHelper.length - 1];
    prefix = `data:image/${extension};base64,`;
  }

  const resultData: tITSUser = {
    id: String(id),
    avatar: avatarIsAvailable
      ? [
          {
            ...avatar,
            content: `${prefix}${avatar?.content}`,
          },
        ]
      : [],
    email,
    firstName,
    lastName,
    phone,
    position,
    externalSystemId,
    personalIdNumber,
    supervisor: !!supervisors[0]
      ? {
          value: supervisors[0].id,
          label: `${supervisors[0].first_name} ${supervisors[0].last_name}`,
        }
      : null,
  };

  return {
    data: resultData,
  };
};

const getQualificationsAndPermissions = ({ id }: tGet) =>
  new Promise((resolve, reject) => {
    // TODO when API ready
    const current: any = [
      {
        id: 3,
        qualifications: [],
      },
    ].find((structure) => structure.id === id);
    if (current) {
      const result = {
        data: {
          qualifications: [...current.qualifications],
        },
        status: 200,
      };
      resolve(result);
      return;
    }
    const response = {
      data: {},
      status: 401,
    };
    reject(response);
  });

export type tGetQualification = tGet & {
  qualificationId: number;
};

const getCurrentQualificationsAndPermissions = ({ id, qualificationId }: tGetQualification) =>
  new Promise((resolve, reject) => {
    // TODO when API ready
    const current: any = [
      {
        id: 3,
        qualifications: [],
      },
    ].find((structure) => structure.id === id);
    if (current) {
      const qualification = current.qualifications.find(
        (qualification) => qualification.id === qualificationId,
      );
      const result = {
        data: {
          ...qualification,
        },
        status: 200,
      };
      resolve(result);
      return;
    }
    const response = {
      data: {},
      status: 401,
    };
    reject(response);
  });

export const prepareData = (data) => {
  const {
    avatar,
    email,
    firstName,
    lastName,
    personalIdNumber,
    externalSystemId,
    phone,
    position,
    supervisor,
  } = data;

  const preparedData: tCreateUserAPI = {
    first_name: firstName,
    last_name: lastName,
    phone_number: phone,
    email,
    position,
    personal_id_number: personalIdNumber,
    external_system_id: externalSystemId,
    supervisors_ids: !!supervisor?.value ? [supervisor?.value] : [],
    company_id: itsCompanyId,
    avatar,
  };
  return preparedData;
};

const create = (bodyRequest) => usersService.create(prepareData(bodyRequest));

const update = (bodyRequest, id) => usersService.update(prepareData(bodyRequest), id);

export default {
  getAll,
  get,
  remove,
  getQualificationsAndPermissions,
  getCurrentQualificationsAndPermissions,
  getAllItsEmployees,
  create,
  update,
  getItsUserLocation,
  putItsUserLocation,
  getAllItsLocations,
};
