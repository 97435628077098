import { createRoot } from 'react-dom/client';
import store from 'store/store';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import initializeInternalization from 'i18n';
import { initSentry } from 'utils/sentry';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { GlobalStyles } from './styles/GlobalStyles';
import { ReactComponent as GlobalSVGGradients } from './styles/global_gradients.svg';

declare global {
  interface Window {
    __env__: any;
  }
}

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

initializeInternalization();
initSentry();

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    <GlobalStyles />
    <GlobalSVGGradients />
  </Provider>,
);

serviceWorker.unregister();
