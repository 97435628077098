import { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import { regionalAdministratorRoleUUID, systemAdminRoleUUID } from 'utils/constants';
import tStore from 'types/store';
import {
  Spinner,
  PageWrapper,
  ComplexTable,
  Breadcrumbs,
  Tiles,
  ListTiles,
  ListTitle,
} from 'components';
import { useFetch } from 'hooks';
import usersService from 'services/users';
import { tGetAllUsersData } from 'types/services/users';
import { ListBreadcrumb, ListTableContainer } from 'styles/GlobalStyledComponents';
import { tableHead, searchableFields } from './TableConfig';

interface iUsersList extends RouteComponentProps<any> {}

const UsersList: React.FC<iUsersList> = ({ history }): JSX.Element => {
  const { t } = useTranslation();

  const permissions = useSelector((state: tStore) => state.user.permissions);
  const userRoles = useSelector((state: tStore) => state.user.roles);
  const userCompanyId = useSelector((state: tStore) => state.user?.company?.id);
  const canWrite = !!permissions.includes('tunUsersWrite');
  const isRegionalAdmin = userRoles.map((role) => role.id).includes(regionalAdministratorRoleUUID);
  const isSysAdmin = userRoles.map((role) => role.id).includes(systemAdminRoleUUID);

  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [sort, setSort] = useState<string>('');

  const { fetch, loading, data, count } = useFetch<tGetAllUsersData>(usersService.getAll);

  const fetchData = useCallback(() => {
    fetch({
      search,
      searchFields: searchableFields,
      limit,
      offset,
      ordering: sort,
      companyId: isRegionalAdmin && !isSysAdmin ? userCompanyId : null,
    });
  }, [search, searchableFields, limit, offset, sort, fetch]);

  const setSortParams = (field: string, sort: string) => {
    const type: string = sort ? '-' : '';
    setSort(`${type}${field}`);
  };

  const changeRoute = () => history.push('/users/new');

  const showUserDetails = (id: number | string) => history.push(`/users/${id}`);

  const setRequestParams = useCallback(
    (newOffset: number, newLimit: number) => {
      if (newOffset !== offset) setOffset(newOffset);
      if (newLimit !== limit) setLimit(newLimit);
    },
    [limit, offset],
  );

  useEffect(() => {
    setOffset(0);
  }, [search]);

  useEffect(() => {
    fetchData();
  }, [limit, offset, sort, fetchData]);

  return (
    <PageWrapper>
      <>
        {loading && <Spinner />}
        <ListBreadcrumb>
          <Breadcrumbs />
        </ListBreadcrumb>
        <ListTitle title={t('Users')} />
        <ListTiles>
          <Tiles
            text={t('Add new user')}
            icon='personAdd'
            color='orange'
            variant='filled'
            onClick={changeRoute}
            disabled={!canWrite}
          />
        </ListTiles>
        <ListTableContainer>
          <ComplexTable
            isRowClickable
            onRowClick={showUserDetails}
            value={search}
            resetValue={() => setSearch('')}
            onChange={({ target: { value } }) => setSearch(value)}
            name='tableSearch'
            tableBodyData={data}
            tableHead={tableHead}
            setSortParams={(field: string, sort: string) => setSortParams(field, sort)}
            totalRecords={count}
            setParams={(offset: number, limit: number) => setRequestParams(offset, limit)}
            tableName='Users list'
            limit={limit}
            offset={offset}
          />
        </ListTableContainer>
      </>
    </PageWrapper>
  );
};

export default withRouter(UsersList);
