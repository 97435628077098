import { useTranslation } from 'react-i18next';
import { monetaryUnit } from 'utils/constants';
import { tInvoiceSumsData } from 'types/components/invoices';
import { formatMonetaryValue } from 'utils/helpers';
import {
  StickyCell,
  UppercaseCell,
  BigSumCell,
  SumSufix,
  SeparatorTH,
  SeparatorTR,
  ActionsCell,
} from './InvoiceTable.style';
import Separator from './Separator';

export type tInvoiceFooter = {
  data: tInvoiceSumsData | null;
  showGross?: boolean;
  showContractor?: boolean;
  showReport?: boolean;
  clientActions?: React.ReactNode;
  contractorActions?: React.ReactNode;
};

const InvoiceFooter: React.FC<tInvoiceFooter> = ({
  data,
  showGross = true,
  showContractor = true,
  showReport = false,
  clientActions,
  contractorActions,
}) => {
  const { t } = useTranslation();

  const actionsPanelOffset = !clientActions && !contractorActions ? -65 : 0;
  const separatorPosition = showContractor ? 225 + actionsPanelOffset : 125 + actionsPanelOffset;
  const sumsPosition = showContractor ? 165 + actionsPanelOffset : 65 + actionsPanelOffset;
  const profitPosition = actionsPanelOffset + 105;
  const profitabilityPosition = actionsPanelOffset + 65;

  return (
    <>
      <SeparatorTR>
        <SeparatorTH colSpan={showGross ? 8 : 7} style={{ bottom: separatorPosition }} />
        {showContractor && (
          <SeparatorTH colSpan={showGross ? 5 : 4} style={{ bottom: separatorPosition }} />
        )}
        {showReport && <Separator />}
      </SeparatorTR>
      <tr>
        <StickyCell colSpan={3} style={{ bottom: sumsPosition }} />
        <Separator hideLine style={{ bottom: sumsPosition, position: 'sticky' }} />
        <UppercaseCell style={{ bottom: sumsPosition }}>{t('Sum')}:</UppercaseCell>
        <BigSumCell
          colSpan={showGross ? 2 : 1}
          style={{ bottom: sumsPosition, position: 'sticky' }}
        >
          {formatMonetaryValue(showGross ? data?.clientGross : data?.clientNet) ?? '-'}
          <SumSufix> {monetaryUnit}</SumSufix>
        </BigSumCell>
        {showContractor && (
          <>
            <Separator hideLine style={{ bottom: sumsPosition, position: 'sticky' }} />
            <UppercaseCell style={{ bottom: sumsPosition }}>{t('Sum')}:</UppercaseCell>
            <BigSumCell colSpan={showGross ? 2 : 1} style={{ bottom: sumsPosition }}>
              {formatMonetaryValue(showGross ? data?.contractorGross : data?.contractorNet) ?? '-'}
              <SumSufix> {monetaryUnit}</SumSufix>
            </BigSumCell>
          </>
        )}
        {showReport && <Separator />}
      </tr>
      {showContractor && (
        <>
          <tr>
            <StickyCell colSpan={showGross ? 10 : 9} style={{ bottom: profitPosition }} />
            <UppercaseCell style={{ bottom: profitPosition }}>{t('Profit')}:</UppercaseCell>
            <BigSumCell colSpan={showGross ? 2 : 1} style={{ bottom: profitPosition }}>
              {formatMonetaryValue(showGross ? data?.profitGross : data?.profitNet) ?? '-'}
              <SumSufix> {monetaryUnit}</SumSufix>
            </BigSumCell>
            {showReport && <Separator />}
          </tr>
          <tr>
            <StickyCell
              colSpan={showGross ? 10 : 9}
              style={{ paddingTop: '0px', bottom: profitabilityPosition }}
            />
            <UppercaseCell style={{ paddingTop: '0px', bottom: profitabilityPosition }}>
              {t('Profitability')}:
            </UppercaseCell>
            <BigSumCell
              colSpan={showGross ? 2 : 1}
              profitability={Number(data?.profitability)}
              style={{
                paddingTop: '0px',
                bottom: profitabilityPosition,
              }}
            >
              {formatMonetaryValue(data?.profitability) ?? '-'}
              <SumSufix> %</SumSufix>
            </BigSumCell>
            {showReport && <Separator />}
          </tr>
        </>
      )}

      {(clientActions || contractorActions) && (
        <SeparatorTR>
          <ActionsCell colSpan={3} />
          <Separator hideLine horizontalBorder='top' style={{ position: 'sticky', bottom: 0 }} />
          <ActionsCell colSpan={showGross ? 3 : 2}>{clientActions}</ActionsCell>
          {showContractor && (
            <>
              <Separator
                hideLine
                horizontalBorder='top'
                style={{ position: 'sticky', bottom: 0 }}
              />
              <ActionsCell colSpan={showGross ? 3 : 2}>{contractorActions}</ActionsCell>
            </>
          )}
          {showReport && <Separator />}
        </SeparatorTR>
      )}
    </>
  );
};

export default InvoiceFooter;
