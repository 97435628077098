import styled from 'styled-components';

export const ButtonStyle = styled.button`
  outline: 0;
  padding: 0;
  margin: 0;
  background: transparent;
  cursor: pointer;
  border: 0;
`;

export const DialogContentWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  font-size: 0.75rem;
  color: var(--theme-mainDarkGrey);
`;
