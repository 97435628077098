import { tTreeType } from './TreeView';
import { FileBlock, StyledFile } from './TreeView.style';

type tFile = tTreeType & {
  hasParent: boolean;
  name: string;
  renderActions: JSX.Element[] | null;
  edit?: any;
};

const File: React.FC<tFile> = ({
  hasParent = false,
  name = '-',
  renderActions = null,
  edit = null,
  type = 'tree',
}): JSX.Element => (
  <FileBlock
    className={
      hasParent
        ? `tree-item-with-parent ${type === 'location' ? 'without-line location-line' : ''}`
        : 'location-line'
    }
  >
    <StyledFile type={type}>{!!edit ? edit() : name}</StyledFile>
    {renderActions}
  </FileBlock>
);

export default File;
