/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import { tDropdownOption } from 'types/global';
import applianceTreeService from 'services/applianceTree';
import useSingleFetch from './useSingleFetch';

type tAppliances = {
  group_id: number;
  id: number;
  name: string;
};

type tGroups = {
  appliances: tAppliances[];
  id: number;
  name: string;
  system_id: number;
};

type tSystems = {
  appliance_industry_id: number;
  groups: tGroups[];
  id: number;
  name: string;
};

type tIndustryTree = {
  id: number;
  industry: {
    name: string;
    id: number;
  };
  systems: tSystems[];
  tree_id: number;
};

const useApplianceTree = (values) => {
  const {
    fetch: fetchTree,
    loading: loadingTree,
    data: applianceTree,
    errors: applianceTreeError,
  } = useSingleFetch<any>(applianceTreeService.getTree);
  const [industryOptions, setIndustryOptions] = useState<
    {
      applianceIndustryId: string | number;
      generalIndustryId: string | number;
      industryName: string;
    }[]
  >([]);
  const [systemOptions, setSystemOptions] = useState<tDropdownOption[]>([]);
  const [groupOptions, setGroupOptions] = useState<tDropdownOption[]>([]);
  const [applianceOptions, setApplianceOptions] = useState<tDropdownOption[]>([]);

  const { applianceIndustry, system, group, appliance } = values;

  useEffect(() => {
    if (!!applianceTree?.appliance_industries) {
      const industryTree: tIndustryTree[] = applianceTree.appliance_industries;
      setIndustryOptions(
        industryTree.map((ind) => {
          return {
            // industry ID connected to contract
            applianceIndustryId: ind?.id,
            // general industry ID
            generalIndustryId: ind?.industry.id,
            industryName: ind?.industry?.name,
          };
        }),
      );

      if (applianceIndustry) {
        const systemsTree =
          industryTree.find((el) => el?.industry?.name === applianceIndustry?.label)?.systems ?? [];
        if (!!systemsTree.length) {
          setSystemOptions(
            systemsTree?.map((el) => ({
              id: el.id,
              value: el?.id,
              label: el?.name,
            })),
          );

          if (system) {
            const groupsTree = systemsTree.find((el) => el?.name === system?.label)?.groups ?? [];
            if (!!groupsTree.length) {
              setGroupOptions(
                groupsTree?.map((el) => ({
                  id: el.id,
                  value: el?.id,
                  label: el?.name,
                })),
              );

              if (group) {
                const appliancesTree =
                  groupsTree.find((el) => el?.name === group?.label)?.appliances ?? [];
                if (!!appliancesTree.length) {
                  setApplianceOptions(
                    appliancesTree?.map((el) => ({
                      id: el.id,
                      value: el?.id,
                      label: el?.name,
                    })),
                  );
                } else {
                  setApplianceOptions([]);
                }
              }
            } else {
              setGroupOptions([]);
            }
          }
        } else {
          setSystemOptions([]);
        }
      }
    }
  }, [applianceTree, applianceIndustry, system, group, appliance]);
  return {
    industryOptions,
    systemOptions,
    groupOptions,
    applianceOptions,
    setIndustryOptions,
    setSystemOptions,
    setGroupOptions,
    setApplianceOptions,
    fetchTree,
    loadingTree,
    applianceTreeError,
  };
};

export default useApplianceTree;
