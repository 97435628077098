import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import MaskedInput from 'react-text-mask';
import { FormErrorMessage, Label } from 'components';
import { useFormErrorsReset } from 'hooks';
import { tError } from 'types/global';
import DoubleInput from './DoubleInput';
import {
  InputStyle,
  Wrapper,
  MainWrapper,
  InputWrapper,
  InputSufixContainer,
  MaxLength,
  PreviewText,
} from './Input.style';

export type tInput = {
  name: string | string[];
  value: any;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  label?: string;
  placeholder?: string | string[];
  errors: tError[];
  disabled?: boolean;
  preview?: boolean;
  labelMinWidth?: number;
  double?: boolean;
  mask?: (string | RegExp)[];
  inputSufix?: string;
  middleSign?: string;
  iconSufix?: string;
  wrapperStyle?: object;
  labelOptional?: boolean;
  maxLength?: number;
  onFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inFocus?: boolean;
  allowedExp?: RegExp;
};

export type tLabel = {
  labelMinWidth: number;
};

const Input: React.FC<tInput> = ({
  name,
  onChange,
  value,
  label = '',
  type = 'text' || ['text', 'text'],
  placeholder = '' || ['', ''],
  errors = [],
  disabled = false,
  preview = false,
  labelMinWidth = 90,
  double = false,
  mask,
  inputSufix,
  middleSign,
  iconSufix,
  wrapperStyle = {},
  labelOptional = false,
  maxLength = undefined,
  onFocus = () => {},
  onBlur = () => {},
  inFocus = false,
  allowedExp,
}): JSX.Element => {
  const { t } = useTranslation();
  const [filteredErrors, setFilteredErrors] = useState<tError[]>([]);
  const { formElemErrors, resetFormError } = useFormErrorsReset(filteredErrors);

  useEffect(() => {
    setFilteredErrors(errors);
  }, [errors]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilteredErrors(resetFormError(event?.target?.name));
    if (!allowedExp || allowedExp.test(event.target?.value)) {
      onChange(event);
    }
  };

  const renderMaxLength = () => {
    return maxLength ? (
      <MaxLength>
        {value ? value.length : 0}/{maxLength}
      </MaxLength>
    ) : null;
  };

  const renderMoreInformation = () => (
    <>
      {renderMaxLength()}
      {inputSufix && <InputSufixContainer>{t(inputSufix)}</InputSufixContainer>}
    </>
  );

  const inputWithSufix = !double && inputSufix ? `${value || '-'} ${inputSufix}` : value;

  const renderInput = () => {
    if (preview) {
      return <PreviewText>{double ? value?.join(' ') : inputWithSufix || '-'}</PreviewText>;
    }
    if (double) {
      return (
        <DoubleInput
          name={name as string[]}
          type={type as unknown as string[]}
          value={value as string[]}
          onChange={handleChange}
          placeholder={placeholder as string[]}
          errors={formElemErrors}
          disabled={disabled}
          middleSign={middleSign}
          mask={mask}
          iconSufix={iconSufix}
        />
      );
    }
    if (mask) {
      return (
        <>
          <MaskedInput
            mask={mask}
            maxLength={maxLength}
            render={(ref, props) => <InputStyle ref={ref} {...props} />}
            name={name as string}
            type={type}
            value={value}
            onChange={handleChange}
            placeholder={placeholder === '__:__' ? '__:__' : t(placeholder)}
            errors={formElemErrors}
            disabled={disabled}
            onBlur={onBlur}
            onFocus={onFocus}
            inFocus={inFocus}
            autoComplete='off'
          />
          {renderMoreInformation()}
        </>
      );
    }
    return (
      <>
        <InputStyle
          maxLength={maxLength}
          name={name as string}
          type={type}
          value={value ?? ''}
          onChange={handleChange}
          placeholder={t(placeholder)}
          errors={formElemErrors}
          disabled={disabled}
          onBlur={onBlur}
          onFocus={onFocus}
          inFocus={inFocus}
        />
        {renderMoreInformation()}
      </>
    );
  };

  return (
    <MainWrapper style={{ ...wrapperStyle }}>
      <Wrapper>
        {label && (
          <Label optional={labelOptional} labelMinWidth={labelMinWidth}>
            {t(label)}
          </Label>
        )}
        <InputWrapper>{renderInput()}</InputWrapper>
      </Wrapper>
      {double ? null : (
        <FormErrorMessage
          name={name as string}
          errors={formElemErrors}
          labelMinWidth={labelMinWidth}
        />
      )}
    </MainWrapper>
  );
};

export default Input;
