import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'hooks';
import { Modal, DropdownV2, Row, Column, Button } from 'components';
import { TopMargin1Rem, LeftMargin1Rem, AlignTextLeft } from 'styles/GlobalStyledComponents';
import issuesService from 'services/issues';
import { tDropdownOption } from 'types/global';

export type tRejectIssueAction = {
  issueId: number | string;
  setNotification: Function;
  history: { push: Function };
};

const RejectIssueAction: React.FC<tRejectIssueAction> = ({ issueId, setNotification, history }) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [reason, setReason] = useState<tDropdownOption | null>(null);

  const {
    fetch: fetchReasons,
    loading: loadingReasons,
    data: reasonsOptions,
  } = useFetch<tDropdownOption>(
    issuesService.rejectIssueReasons,
    'Something went wrong. Try again',
  );

  useEffect(() => {
    fetchReasons();
  }, []);

  useEffect(() => {
    if (reasonsOptions.length) setReason(reasonsOptions[0]);
  }, [reasonsOptions]);

  const rejectIssueHandler = async () => {
    if (issueId && reason?.value) {
      try {
        const reasonId = +reason?.value;
        const result = await issuesService.rejectIssue(issueId, { reasonId });
        if (result?.status === 200) {
          setNotification(t('Issue was rejected'), 'success');
          history.push('/issues');
        }
      } catch (err) {
        if (err?.status >= 400) {
          setNotification(err.data[0].msg);
        }
        setNotification(t('Something went wrong. Try again'));
      }
    }
  };

  const setReasonHandler = (data) => {
    setReason({ value: data?.value, label: data?.label });
  };

  return (
    <>
      {showModal && (
        <AlignTextLeft>
          <Modal
            icon='announcement'
            title={t('Reject issue')}
            cancelButtonText={t('Close')}
            cancelMethod={() => setShowModal(false)}
            confirmButtonText={t('Cancel issue')}
            confirmMethod={rejectIssueHandler}
            confirmDisabled={!!!reason?.value}
            confirmVariant='redFilled'
            loading={loadingReasons}
          >
            {t('Rejection of issue will result in its archiving')}.
            <TopMargin1Rem>
              <Row>
                <Column>
                  <DropdownV2
                    name='reason'
                    label={t('Rejection reason')}
                    options={reasonsOptions?.map((el) => ({ ...el, fieldName: 'reason' })) ?? []}
                    onChange={setReasonHandler}
                    errors={[]}
                    value={reason}
                  />
                </Column>
              </Row>
            </TopMargin1Rem>
          </Modal>
        </AlignTextLeft>
      )}
      <LeftMargin1Rem>
        <Button kind='filled' variant='redFilled' onClick={() => setShowModal(true)}>
          {t('Reject')}
        </Button>
      </LeftMargin1Rem>
    </>
  );
};

export default RejectIssueAction;
