import { useCallback, useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { addNotification } from 'store/notifications/actions';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { catchErrors, getAvatarBase64FromFiles } from 'utils/helpers';
import { tUser } from 'types/services/users';
import { tUserFormValues } from 'types/views/users';
import { tOption, tGet, tEnumsResult, tError, tKeyString } from 'types/global';
import tStore from 'types/store';
import {
  Avatar,
  Button,
  Column,
  DropdownV2,
  ExpansionPanel,
  Form,
  Input,
  Row,
  Spinner,
} from 'components';
import { REQUIRED_FIELDS } from 'utils/requiredFormFields';
import { validateRequiredFields } from 'utils/form';
import { useSingleFetch, useForm, useFetch } from 'hooks';
import usersService, { tGetCompaniesParams } from 'services/users';
import {
  ExpansionPanelWrapper,
  FormWrapper,
  WrapperWithBottomGap,
} from 'styles/GlobalStyledComponents';
import formActions from 'utils/formActions';
import { ButtonContainer } from '../AddEditViewUser.style';

const dataModel: tUser = {
  userName: '',
  userSurname: '',
  userPhoto: '',
  userEmployer: null,
  userPhoneNumber: '',
  userEmail: '',
  userPosition: '',
};

interface iMainForm extends RouteComponentProps<any> {
  disabled?: boolean;
  preview?: boolean;
  isAddMode?: boolean;
  setFormName?: (name: string) => void;
  setUserCompany: (data: tOption) => void;
}

const MainForm: React.FC<iMainForm> = ({
  disabled = false,
  preview = false,
  isAddMode = false,
  setFormName = () => {},
  match,
  setUserCompany,
}): JSX.Element => {
  const history = useHistory();
  const { id } = match.params;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const permissions = useSelector((state: tStore) => state.user.permissions);
  const canWrite = preview && permissions.includes('tunUsersWrite');
  const canRemove = !isAddMode && permissions.includes('tunUsersWrite');

  const [loading, setLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>(dataModel);

  const {
    fetch: setParamsHandler,
    loading: usersDataLoading,
    data,
  } = useSingleFetch<tUser, tGet>(usersService.get, 'An error occurred while getting users');

  const {
    fetch: setParamsHandlerCompanies,
    loading: loadingCompaniesData,
    data: dataCompanies,
  } = useFetch<tEnumsResult, tGetCompaniesParams>(
    usersService.getCompanies,
    'An error occurred while getting companies',
  );

  const [errors, setErrors] = useState<tError[]>([]);

  const setNotificationHandler = (message: string, type = 'error' as 'error' | 'success') => {
    const enableTimeout = type === 'success';
    dispatch(addNotification(t(message), type, enableTimeout));
  };

  const getInitialValues = () => {
    if (Object.keys(data).length > 0) return data;
    return dataModel;
  };

  const fetchDataCompanies = useCallback(
    () => setParamsHandlerCompanies({ kind: 'client', archived: false }),
    [setParamsHandlerCompanies],
  );

  const fetchData = useCallback(() => {
    if (!!id) setParamsHandler({ id });
    setErrors([]);
  }, [setParamsHandler, id]);

  useEffect(() => {
    fetchDataCompanies();
  }, [fetchDataCompanies]);

  useEffect(() => {
    fetchData();
  }, [fetchData, preview, disabled]);

  useEffect(() => {
    setInitialValues(getInitialValues());
    if (!!data.userEmployer) {
      setUserCompany(data.userEmployer);
    }
  }, [data]);

  const setResponseErrors = (setter: Function, errors: any) => {
    const transformField = (field: string): string => {
      switch (field) {
        case 'first_name':
          return 'userName';
        case 'last_name':
          return 'userSurname';
        case 'position':
          return 'userPosition';
        case 'phone_number':
          return 'userPhoneNumber';
        case 'email':
          return 'userEmail';
        case 'company_id':
          return 'userEmployer';
        default:
          return '__noField__';
      }
    };

    if (Array.isArray(errors) && !!errors.length) {
      setter(
        errors.map((error: tKeyString) => {
          const { key, msg } = error;

          return {
            field: transformField(key),
            error: msg,
          };
        }),
      );
    }

    if (errors.includes('502 Bad Gateway')) {
      setNotificationHandler(t('Something went wrong. Try again'));
    }
  };

  const createOrUpdateUser = async (values: tUserFormValues, isAddMode: boolean) => {
    try {
      setLoading(true);

      /* eslint-disable camelcase */
      const {
        userEmail: email,
        userEmployer: company_id,
        userName: first_name,
        userPhoneNumber: phone_number,
        userPhoto,
        userPosition: position,
        userSurname: last_name,
      } = values;
      const body = {
        avatar: userPhoto,
        company_id,
        email,
        first_name,
        last_name,
        phone_number,
        position,
      };

      const requiredFields = REQUIRED_FIELDS.USERS_FORM.MAIN;
      const validationErrors = validateRequiredFields(body, requiredFields);
      if (validationErrors.length) return setResponseErrors(setErrors, validationErrors);

      const result = isAddMode
        ? await usersService.create(body)
        : await usersService.update(body, id);
      const { state } = result;

      if (!state) {
        const { errors: responseErrors } = result;
        return responseErrors.length
          ? setResponseErrors(setErrors, responseErrors)
          : setNotificationHandler('Something went wrong. Try again');
      }

      const notificationMessage = isAddMode ? t('User was added') : t('User was updated');
      setNotificationHandler(notificationMessage, 'success');
      setErrors([]);

      const createdUserId = result.data?.id;
      if (createdUserId) history.push(`/users/${createdUserId}/edit`);
    } catch (error) {
      const setters = { setNotificationHandler, setResponseErrors, setErrors };
      catchErrors(error, setters);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (values: any, isAddMode: boolean) => {
    const {
      values: { userPhoto, userEmployer },
    } = values;

    const avatar = await getAvatarBase64FromFiles(userPhoto);
    return createOrUpdateUser(
      {
        ...values?.values,
        userEmployer: userEmployer?.value ?? '',
        userPhoto: avatar,
      },
      isAddMode,
    );
  };

  const { values, handleChange, handleSubmit, removeUploadedFile } = useForm({
    initialValues,
    onSubmit: (values: any) => onSubmit(values, isAddMode),
  });

  const {
    userName,
    userSurname,
    userPhoto,
    userEmployer,
    userPhoneNumber,
    userEmail,
    userPosition,
  } = values;

  useEffect(() => {
    setFormName(`${userName} ${userSurname}`);
  }, [userName, userSurname]);

  useEffect(() => {
    const errorsHelper = errors.filter((error) => error.field === '__noField__');
    if (errorsHelper.length > 0) {
      setErrors((prev) => [...prev.filter((error) => error.field !== '__noField__')]);
      setNotificationHandler(errorsHelper.map((error) => error.error).join(', '));
    }
  }, [errors]);

  const editUser = () => history.push(`/users/${id}/edit`);

  const removeUser = async () => {
    try {
      setLoading(true);

      const result = await usersService.remove(id);
      const { status, error } = result;

      if (status !== 204) {
        dispatch(
          addNotification(error ?? error?.msg ?? t('Something went wrong. Try again'), 'error'),
        );
      } else {
        dispatch(addNotification(t('User was archived'), 'success', true));
        history.push(`/users`);
      }
    } catch (error: any) {
      dispatch(
        addNotification(error ?? error?.msg ?? t('Something went wrong. Try again'), 'error'),
      );
    } finally {
      setLoading(false);
    }
  };

  const actions = formActions([
    { type: 'edit', action: editUser, show: canWrite },
    {
      type: 'remove',
      action: removeUser,
      show: canRemove,
      dialogTitle: 'Delete user',
      dialogMessage: `${t('Are you sure you want to delete user')} ${userName} ${userSurname}?`,
    },
  ]);

  const isLoading = loading || usersDataLoading || loadingCompaniesData;

  return (
    <Form onSubmit={handleSubmit}>
      <ExpansionPanelWrapper>
        {isLoading && <Spinner />}
        <ExpansionPanel
          hasBottomBorder={!preview}
          title='User data'
          hasIcon
          iconName='personAdd'
          actions={actions}
        >
          <FormWrapper>
            <Row justifyContent='space-between'>
              <Column>
                <WrapperWithBottomGap>
                  <Avatar
                    fileSrc={!!userPhoto?.length ? userPhoto : ''}
                    errors={errors}
                    isEditable={!disabled && !preview}
                    name='userPhoto'
                    label='Photo'
                    showWithLabel
                    onChange={handleChange}
                    removeFile={() => removeUploadedFile(userPhoto[0].filename, 'userPhoto', true)}
                    labelOptional
                  />
                </WrapperWithBottomGap>
              </Column>
              <Column>
                <DropdownV2
                  name='userEmployer'
                  label='Employer'
                  options={dataCompanies}
                  onChange={handleChange}
                  errors={errors}
                  value={userEmployer}
                  disabled={!!disabled}
                  preview={preview}
                />
              </Column>
              <Column>
                <Input
                  name='userName'
                  label='First name'
                  onChange={handleChange}
                  errors={errors}
                  value={userName}
                  disabled={!!disabled}
                  preview={preview}
                />
              </Column>
              <Column>
                <Input
                  name='userSurname'
                  label='Last name'
                  onChange={handleChange}
                  errors={errors}
                  value={userSurname}
                  disabled={!!disabled}
                  preview={preview}
                />
              </Column>
              <Column>
                <Input
                  name='userPhoneNumber'
                  label='Phone'
                  onChange={handleChange}
                  errors={errors}
                  value={userPhoneNumber}
                  disabled={!!disabled}
                  preview={preview}
                  allowedExp={new RegExp(/^([0-9]{0,22})$/)}
                />
              </Column>
              <Column>
                <Input
                  name='userEmail'
                  label='Mail'
                  onChange={handleChange}
                  errors={errors}
                  value={userEmail}
                  disabled={!!disabled}
                  preview={preview}
                />
              </Column>
              <Column>
                <Input
                  name='userPosition'
                  label='Position'
                  onChange={handleChange}
                  errors={errors}
                  value={userPosition}
                  disabled={!!disabled}
                  preview={preview}
                />
              </Column>
            </Row>
          </FormWrapper>
        </ExpansionPanel>
        {!preview && (
          <ButtonContainer>
            <Button disabled={!!disabled} type='submit' variant='green'>
              {t('Save')}
            </Button>
          </ButtonContainer>
        )}
      </ExpansionPanelWrapper>
    </Form>
  );
};

export default withRouter(MainForm);
