import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose } from 'redux';
import { sentryReduxEnhancer } from 'utils/sentry';
import { History } from 'history';
import api from './api';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const middleware = [api, thunk];

if (process.env.NODE_ENV === `development`) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
  const { logger } = require(`redux-logger`);
  middleware.push(logger);
}

export default (history: History<any>) =>
  compose(applyMiddleware(routerMiddleware(history), ...middleware), sentryReduxEnhancer);
