import { useTranslation } from 'react-i18next';
import MaskedInput from 'react-text-mask';
import { tError } from 'types/global';
import { FormErrorMessage, Icon } from 'components';
import { colorGreyBasic } from 'styles/GlobalStyles';
import {
  DoubleInputStyle,
  DoubleInputWrapper,
  MiddleSign,
  IconSufixWrapper,
  IconFirstSufixWrapper,
} from './DoubleInput.style';
import { MainWrapper } from './Input.style';

export type tDoubleInput = {
  name: string[];
  value: any;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type: string[];
  placeholder: string[];
  errors: tError[];
  disabled?: boolean;
  middleSign?: string;
  mask?: (string | RegExp)[];
  iconSufix?: string;
};

const DoubleInput: React.FC<tDoubleInput> = ({
  name,
  type,
  value,
  placeholder,
  onChange,
  errors,
  disabled,
  middleSign,
  mask,
  iconSufix = '',
}): JSX.Element => {
  const { t } = useTranslation();
  const [firstName, secondName] = name;
  const [firstType, secondType] = type;
  const [firstValue, secondValue] = value;
  const [firstPlaceholder, secondPlaceholder] = placeholder;

  const renderIconSufix = () => {
    return <Icon icon={iconSufix} width='1.25rem' height='1.25rem' fill={colorGreyBasic} />;
  };

  return mask ? (
    <DoubleInputWrapper>
      <MaskedInput
        mask={mask}
        render={(ref, props) => <DoubleInputStyle ref={ref} {...props} />}
        name={firstName}
        type={firstType}
        value={firstValue}
        placeholder={firstPlaceholder}
        onChange={onChange}
        errors={errors}
        disabled={disabled}
      />
      {iconSufix && <IconFirstSufixWrapper>{renderIconSufix()}</IconFirstSufixWrapper>}
      {middleSign && <MiddleSign>{middleSign}</MiddleSign>}
      <MaskedInput
        mask={mask}
        render={(ref, props) => <DoubleInputStyle ref={ref} {...props} />}
        name={secondName}
        type={secondType}
        value={secondValue}
        placeholder={secondPlaceholder}
        onChange={onChange}
        errors={errors}
        disabled={disabled}
        style={{ marginLeft: middleSign ? 0 : '1rem' }}
      />
      {iconSufix && <IconSufixWrapper>{renderIconSufix()}</IconSufixWrapper>}
    </DoubleInputWrapper>
  ) : (
    <DoubleInputWrapper>
      <MainWrapper>
        <DoubleInputStyle
          name={firstName}
          type={firstType}
          value={firstValue}
          placeholder={t(firstPlaceholder)}
          onChange={onChange}
          errors={errors}
          disabled={disabled}
        />
        <FormErrorMessage name={firstName} errors={errors} />
      </MainWrapper>
      {iconSufix && <Icon icon={iconSufix} />}
      <MainWrapper>
        <DoubleInputStyle
          name={secondName}
          type={secondType}
          value={secondValue}
          placeholder={t(secondPlaceholder)}
          onChange={onChange}
          errors={errors}
          disabled={disabled}
          style={{ marginLeft: '1rem' }}
        />
        <div style={{ marginLeft: '1rem' }}>
          <FormErrorMessage name={secondName} errors={errors} />
        </div>
      </MainWrapper>
    </DoubleInputWrapper>
  );
};

export default DoubleInput;
