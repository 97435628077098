import styled, { css } from 'styled-components';
import { tCheckbox, tWrapper, tTitle } from 'components/Checkbox/Checkbox';

export const Title = styled.span<tTitle>`
  margin-left: 0.75rem;
  font-size: 0.75rem;
  text-align: left;
  flex: 1;
  color: var(--theme-mainGrey);
  ${({ titleStyles }) =>
    titleStyles &&
    css`
      ${titleStyles}
    `};
`;

export const RoundWrapper = styled.div<tWrapper>`
  min-height: 32px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  ${({ wrapperStyles }) =>
    wrapperStyles &&
    css`
      ${wrapperStyles}
    `};
`;

export const RoundContainer = styled.label<tWrapper>`
  ${({ wrapperStyles }) =>
    wrapperStyles &&
    css`
      ${wrapperStyles}
    `};
  width: 16px;
  height: 16px;
  border: 2px solid var(--theme-mainBlue);
  border-radius: 50%;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ disabled }) =>
    disabled &&
    css`
      border: 2px solid var(--theme-mainGrey);
      color: var(--theme-mainGrey);
    `}
`;

export const RoundTitle = styled.span<tTitle>`
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  background-color: var(--theme-mainBlue);
  border-radius: 50%;
  display: none;
`;

export const RoundInputStyle = styled.input<tCheckbox>`
  display: none;
  &:checked + ${RoundTitle} {
    display: inline-block;
  }
`;
