import { useTranslation } from 'react-i18next';
import FormErrorMessage from 'components/FormErrorMessage';
import { tError } from 'types/global';
import { useFormErrorsReset } from 'hooks';
import {
  Title,
  RoundContainer,
  RoundInputStyle,
  RoundTitle,
  RoundWrapper,
} from './InputRadio.style';

export type tWrapper = {
  wrapperStyles?: React.CSSProperties | any;
};

export type tTitle = {
  titleStyles?: React.CSSProperties | any;
};

export type tInputRadio = tTitle &
  tWrapper & {
    checked: string;
    onChange: (name: string) => void;
    name: string;
    disabled?: boolean;
    errors: tError[];
    label?: string;
  };

const InputRadio: React.FC<tInputRadio> = ({
  checked,
  onChange,
  name,
  disabled = false,
  errors,
  label,
  wrapperStyles,
  titleStyles,
}): JSX.Element => {
  const { t } = useTranslation();
  const { formElemErrors, resetFormError } = useFormErrorsReset(errors);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    resetFormError(event?.target?.name);
    onChange(event?.target?.name);
  };

  return (
    <div>
      <RoundWrapper wrapperStyles={wrapperStyles}>
        <RoundContainer disabled={disabled}>
          <RoundInputStyle
            checked={checked === name}
            onChange={handleChange}
            name={name}
            disabled={disabled}
            errors={formElemErrors}
            type='radio'
          />
          <RoundTitle titleStyles={titleStyles} />
        </RoundContainer>
        {label && <Title titleStyles={titleStyles}>{t(label)}</Title>}
      </RoundWrapper>
      <FormErrorMessage name={name} errors={formElemErrors} />
    </div>
  );
};

export default InputRadio;
