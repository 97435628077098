import { Tooltip2, Icon } from 'components';
import { colorWhite } from 'styles/GlobalStyles';
import { Round, Element, Text } from './TableBadge.style';

export type tTableBadgeColor = 'red' | 'green' | 'orange' | 'blue' | 'aquamarine';

export type tTableBadge = {
  copy?: string;
  color?: tTableBadgeColor;
  icon?: string;
  children;
};

export const mapColor = (color: string | null | undefined) => {
  switch (color) {
    case 'red':
      return '--theme-mainRed';
    case 'blue':
      return '--theme-mainBlue';
    case 'green':
      return '--theme-mainGreen';
    case 'orange':
      return '--theme-mainYellow';
    case 'aquamarine':
      return '--theme-aquamarine';
    default:
      return '--theme-black';
  }
};

const TableBadge: React.FC<tTableBadge> = ({ children, copy, color = 'red', icon }) => {
  const renderBadge = () => (
    <Element color={color}>
      <Text>{children}</Text>
      {icon ? (
        <Icon width='1rem' height='1rem' icon={icon} fill={colorWhite} />
      ) : (
        <Round color={mapColor(color)}>!</Round>
      )}
    </Element>
  );

  return copy ? <Tooltip2 tooltip={copy}>{renderBadge()}</Tooltip2> : renderBadge();
};

export default TableBadge;
