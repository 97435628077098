import styled, { css } from 'styled-components';
import { ClearButton } from 'styles/GlobalStyledComponents';
import { tPosition } from './Filters';

export const Wrapper = styled.div`
  position: relative;
  margin: 0 0.25rem;
`;

export const Container = styled.div<tPosition>`
  z-index: calc(var(--theme-containerZIndex) + 3);
  position: absolute;
  background: #fff;
  padding: 1rem;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: var(--theme-borderRadius-sm);
  min-width: 12rem;
  &:after {
    bottom: 100%;
    right: 10%;
    border: 5px solid #fff;
    border-color: transparent transparent #fff #fff;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(0, 0, 0, 0);
    border-bottom-color: #fff;
    border-width: 15px;
    margin-left: -15px;
    transform-origin: 0 0;
    transform: rotate(0);
  }
  ${({ position }) =>
    position === 'right' &&
    css`
      &:after {
        left: 10%;
        right: unset;
      }
    `}
  ${({ top, left, position }) =>
    top &&
    left &&
    position &&
    css`
      top: ${top + 30}px;
      ${position === 'left'
        ? `left: ${left + 36}px;  transform:translate(-100%, 0%);`
        : `left: ${left - 22}px; `};
    `}
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
export const CloseButton = styled(ClearButton)`
  font-weight: bold;
  color: var(--theme-mainRed);
  margin-right: 1rem;
`;

export const ClearingButton = styled(ClearButton)`
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 1rem;
`;

export const Title = styled.span`
  margin-left: 0.75rem;
  margin-bottom: 0.75rem;
  font-weight: normal;
  white-space: nowrap;
`;
