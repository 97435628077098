import styled from 'styled-components';

export const BadgeWrapper = styled.div`
  color: white;
  text-decoration: none;
  position: relative;
  display: inline-block;
  border-radius: 2px;
  width: max-content;
`;

export const NumberWrapper = styled.div`
  position: absolute;
  bottom: 1px;
  right: -8px;
  width: 15px;
  height: 15px;
  border-radius: 30px;
  background: red;
  color: white;
  font-size: 0.625rem;
  border: 3px solid white;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2px;
  & p {
    margin: 0;
    padding: 0;
    width: max-content;
  }
`;
