import applianceTree from 'agents/applianceTree';
import { tGet } from 'types/global';

const getTree = ({ id }: tGet) =>
  applianceTree.get(id).then(({ data, status }) => ({
    data,
    status,
  }));

const getIndustries = () => applianceTree.getIndustries().then(({ data }) => [...data.results]);

const addIndustry = (id: string | number, industryId: string | number) =>
  applianceTree.addIndustry(id, industryId).then(() => true);

const deleteIndustry = (id: string | number) => applianceTree.deleteIndustry(id).then(() => true);

const manageSystem = (
  industryId: string | number | null,
  name: string,
  systemId: string | number | null,
) => {
  if (!!industryId) return applianceTree.addSystem(industryId, name).then(() => true);
  if (!!systemId) return applianceTree.patchSystem(systemId, name).then(() => true);
  throw new Error('Industry ID and system ID cannot be empty');
};

const deleteSystem = (id: string | number) => applianceTree.deleteSystem(id).then(() => true);

const manageGroup = (
  systemId: string | number | null,
  name: string,
  groupId: string | number | null,
) => {
  if (!!systemId) return applianceTree.addGroup(systemId, name).then(() => true);
  if (!!groupId) return applianceTree.patchGroup(groupId, name).then(() => true);
  throw new Error('System ID and group ID cannot be empty');
};

const deleteGroup = (id: string | number) => applianceTree.deleteGroup(id).then(() => true);

const manageAppliance = (
  groupId: string | number | null,
  name: string,
  applianceId: string | number | null,
) => {
  if (!!groupId) return applianceTree.addAppliance(groupId, name).then(() => true);
  if (!!applianceId) return applianceTree.patchAppliance(applianceId, name).then(() => true);
  throw new Error('Group ID and appliance ID cannot be empty');
};

const deleteAppliance = (id: string | number) => applianceTree.deleteAppliance(id).then(() => true);

export default {
  getTree,
  getIndustries,
  addIndustry,
  deleteIndustry,
  manageSystem,
  deleteSystem,
  manageGroup,
  deleteGroup,
  manageAppliance,
  deleteAppliance,
};
