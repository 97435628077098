import { ClearButton } from 'styles/GlobalStyledComponents';

import styled from 'styled-components';

type tRowModHistory = {
  color?: string;
};

export const RowModHistory = styled.p<tRowModHistory>`
  font-size: 0.75rem;
  line-height: 0.9375rem;
  letter-spacing: 0.58px;
  color: var(--theme-mainDarkGrey);
  margin: 0;
  padding: 0;
  display: flex;
`;

export const RowFieldModHistory = styled(RowModHistory)`
  color: var(--theme-mainGrey);
`;

export const Space = styled.div`
  margin-bottom: 0.5625rem;
`;

export const ModSpace = styled.div`
  margin-bottom: 1rem;
`;

export const ShowMore = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 1rem 1rem;
  font-weight: bold;
`;

export const ShowMoreButton = styled(ClearButton)`
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  letter-spacing: 0.29px;
  text-transform: uppercase;
`;

export const Label = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const ExpansionPanelWrapper = styled.div`
  background: var(--theme-white);
  padding: 1.625rem 2rem;
  border-radius: 8px;
  margin-bottom: 2rem;
`;

export const CommentText = styled.p`
  margin: 0;
  padding: 0;
  margin-left: 0.5rem;
`;

export const AdditionalInfoText = styled(RowModHistory)`
  margin-top: 0.5rem;
  width: 50%;
`;

export const AdditionalInfoLightText = styled(AdditionalInfoText)`
  color: var(--theme-mainGrey);
`;

export const SpaceLeft = styled.span`
  margin-left: 0.5rem;
`;

export const SpaceRight = styled.span`
  margin-right: 0.5rem;
`;
