import { useState } from 'react';
import { Icon, Input, Dropdown } from 'components';
import {
  colorGreyBasic,
  colorGreenBasic,
  colorRedBasic,
  colorBlueBasic,
} from 'styles/GlobalStyles';
import { ClearButton } from 'styles/GlobalStyledComponents';
import { tOptions } from '../Dropdown/Dropdown';
import { TreeTitle, BoldTreeTitle, Wrapper } from './EditElement.style';

type tEditElement = {
  children: string;
  action: (value: string | number) => void;
  disabled?: boolean;
  type?: 'type' | 'element';
  clickHandler?: () => void;
  options?: tOptions[];
  name?: string;
};

const EditElement: React.FC<tEditElement> = ({
  children,
  action,
  disabled = false,
  type = 'type',
  clickHandler = () => {},
  options = [],
  name = 'editElement',
}) => {
  const [edit, setEdit] = useState<boolean>(false);
  const [value, setValue] = useState<any>(type === 'element' ? children : null);

  const saveItem = () => {
    if (value !== children) action(value);
    setEdit(false);
    if (type === 'type') setValue('');
  };

  if (edit) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
        {type === 'type' && (
          <ClearButton onClick={() => setEdit(false)}>
            <Icon
              icon='indeterminateCheckBox'
              width='1.125rem'
              height='1.125rem'
              fill={colorRedBasic}
            />
          </ClearButton>
        )}
        {!!options.length ? (
          <Dropdown
            options={options}
            onChange={(value) => setValue(value)}
            errors={[]}
            name={name}
            value={value?.label ?? null}
            wrapperStyle={{
              marginBottom: 0,
              maxWidth: '15rem',
              width: '100%',
              marginRight: '0.5rem',
              marginLeft: '0.5rem',
            }}
          />
        ) : (
          <Input
            name='add-tree-view'
            type='text'
            errors={[]}
            value={value ?? ''}
            onChange={(e) => setValue(e.target.value)}
            wrapperStyle={{
              marginBottom: 0,
              maxWidth: '15rem',
              width: '100%',
              marginRight: '0.5rem',
              marginLeft: '0.5rem',
            }}
          />
        )}

        <Icon
          asButton
          icon='checkCircle'
          disable={disabled}
          fill={disabled ? colorGreyBasic : colorGreenBasic}
          onClick={saveItem}
        />
      </div>
    );
  }
  if (type === 'type') {
    return (
      <ClearButton onClick={() => setEdit(true)}>
        <Wrapper>
          <Icon icon='addBox' width='1.125rem' height='1.125rem' fill={colorBlueBasic} />
          <BoldTreeTitle>{children}</BoldTreeTitle>
        </Wrapper>
      </ClearButton>
    );
  }
  return (
    <Wrapper>
      <ClearButton onClick={clickHandler}>
        <TreeTitle>{children}</TreeTitle>
      </ClearButton>
      <ClearButton onClick={() => setEdit(true)}>
        <Icon fill={colorBlueBasic} icon='edit' />
      </ClearButton>
    </Wrapper>
  );
};

export default EditElement;
