import { withRouter, RouteComponentProps } from 'react-router';
import { ButtonsFormContainer } from 'styles/GlobalStyledComponents';
import { tSinglePreviewItem } from 'types/views/preview';
import RejectIssueAction from './RejectIssueAction';
import RejectSubcontractorIssueAction from './RejectSubcontractorIssueAction';
import AssignIssueAction from './AssignIssueAction';
import AcceptIssueAction from './AcceptIssueAction';
import CancelIssueAction from './CancelIssueAction';
import SendCommentsAction from './SendCommentsAction';
import ComplaintIssueAction from './ComplaintIssueAction';
import ConfirmReceptionIssueAction from './ConfirmReceptionIssueAction';
import ChangeDateNotifiedIssue from './ChangeDateNotifiedIssue';

interface iIssueActionsPanel extends RouteComponentProps<any> {
  actions: { [key: string]: string };
  issueId: number | string;
  contractorTechnicianId: number | string | null;
  isSubcontractorAssigned: boolean;
  previewData: tSinglePreviewItem[];
  commentsMode?: boolean;
  setNotification: (error, type) => void;
  setErrors: (value) => void;
}

const IssueActionsPanel: React.FC<iIssueActionsPanel> = ({
  history,
  actions,
  issueId,
  contractorTechnicianId,
  isSubcontractorAssigned,
  setNotification,
  commentsMode = false,
  previewData = [],
  setErrors,
}): JSX.Element => {
  const actionsArr = Object.keys({ ...actions });

  return (
    <ButtonsFormContainer>
      {commentsMode ? (
        <SendCommentsAction
          actions={actions}
          history={history}
          setNotification={setNotification}
          previewData={previewData}
        />
      ) : (
        <>
          {actionsArr.includes('cancel') && (
            <CancelIssueAction
              actions={actions}
              history={history}
              setNotification={setNotification}
            />
          )}
          {actionsArr.includes('reject_issue') && (
            <RejectIssueAction
              issueId={issueId}
              setNotification={setNotification}
              history={history}
            />
          )}
          {actionsArr.includes('reject_by_subcontractor') && (
            <RejectSubcontractorIssueAction
              actions={actions}
              issueId={issueId}
              history={history}
              setNotification={setNotification}
            />
          )}
          {(actionsArr.includes('assign_technician') ||
            actionsArr.includes('assign_subcontractors_technician')) && (
            <AssignIssueAction
              actions={actions}
              contractorTechnicianId={contractorTechnicianId}
              setNotification={setNotification}
              history={history}
              isSubcontractorAssigned={isSubcontractorAssigned}
              setErrors={setErrors}
            />
          )}
          {actionsArr.includes('change_technician') && (
            <AssignIssueAction
              actions={actions}
              contractorTechnicianId={contractorTechnicianId}
              setNotification={setNotification}
              history={history}
              buttonText='Change technician'
              isSubcontractorAssigned={isSubcontractorAssigned}
              setErrors={setErrors}
            />
          )}
          {actionsArr.includes('open') && (
            <AcceptIssueAction
              actions={actions}
              history={history}
              setNotification={setNotification}
            />
          )}
          {actionsArr.includes('create_complaint') && (
            <ComplaintIssueAction issueId={issueId} history={history} />
          )}
          {actionsArr.includes('confirm_service') && (
            <ConfirmReceptionIssueAction
              issueId={issueId}
              history={history}
              setNotification={setNotification}
            />
          )}
          {actionsArr.includes('reject_visit_by_user') && (
            <ChangeDateNotifiedIssue setNotification={setNotification} actions={actions} />
          )}
        </>
      )}
    </ButtonsFormContainer>
  );
};

export default withRouter(IssueActionsPanel);
