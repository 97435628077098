import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addNotification } from 'store/notifications/actions';
import { Column, ExpansionPanel, Row, Form, Button, DropdownWithList, Spinner } from 'components';
import { catchErrors } from 'utils/helpers';
import { regionalAdministratorRoleUUID, workerRoleUUID } from 'utils/constants';
import contracts, { tGetUsersByRoleAssignedToLocationParams } from 'services/contracts';
import { tOption, tKeyString, tRole, tError, tDropdownOption } from 'types/global';
import { tSingleUsersValues } from 'types/views/contracts';
import { tUserInContractsLocation } from 'types/views/users';
import {
  tGetUsersByRoleAssignedToLocationData,
  tGetUsersByFiltersParams,
} from 'types/services/contracts';
import { useForm, useFetch } from 'hooks';
import { FormWrapper } from 'styles/GlobalStyledComponents';
import { REQUIRED_FIELDS } from 'utils/requiredFormFields';
import { validateRequiredFields } from 'utils/form';
import { ExpansionPanelWrapper, ButtonWrapper } from '../../AddEditFormContracts.style';

type tSingleUsersForm = {
  disabled: boolean;
  preview: boolean;
  id: string;
  addressId: string | null | number;
  companyData: tDropdownOption | null;
};

type tInitialValues = {
  regionalAdmin: tUserInContractsLocation[];
  employee: tUserInContractsLocation[];
};

const dataModel = {
  regionalAdmin: [],
  employee: [],
};

const SingleUsersForm: React.FC<tSingleUsersForm> = ({
  disabled = false,
  preview = false,
  id: locationId,
  addressId,
  companyData,
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<tInitialValues>(dataModel);
  const [roles, setRoles] = useState<tRole[]>([]);

  const {
    fetch: setRegionalAdminAssignedToWorkplace,
    loading: loadingRegionalAdminsAssignedToWorkplace,
    data: regionalAdminAssignedToWorkplace,
  } = useFetch<tOption & { id: number }, tGetUsersByFiltersParams>(
    contracts.getUsersByFilters,
    'Something went wrong while fetching data',
  );

  const {
    fetch: setEmployeeAssignedToWorkplace,
    loading: loadingEmployeeAssignedToWorkplace,
    data: employeeAssignedToWorkplace,
  } = useFetch<tOption & { id: number }, tGetUsersByFiltersParams>(
    contracts.getUsersByFilters,
    'Something went wrong while fetching data',
  );

  const {
    fetch: setRegionalAdminData,
    loading: loadingRegionalAdminsAssignedToLocation,
    data: regionalAdminData,
  } = useFetch<tGetUsersByRoleAssignedToLocationData, tGetUsersByRoleAssignedToLocationParams>(
    contracts.getUsersByRoleAssignedToLocation,
    'Something went wrong while fetching data',
  );

  const {
    fetch: setEmployeeData,
    loading: loadingEmployeeAssignedToLocation,
    data: employeeData,
  } = useFetch<tGetUsersByRoleAssignedToLocationData, tGetUsersByRoleAssignedToLocationParams>(
    contracts.getUsersByRoleAssignedToLocation,
    'Something went wrong while fetching data',
  );

  const [errors, setErrors] = useState<tError[]>([]);

  const fetchData = useCallback(() => {
    const regionalAdminRole = roles.find((role) => role.id === regionalAdministratorRoleUUID);
    const employeeRole = roles.find((role) => role.id === workerRoleUUID);

    setRegionalAdminAssignedToWorkplace({
      administratedLocationId: addressId,
      companyId: companyData?.value,
    });
    if (!!locationId && !!regionalAdminRole?.id) {
      setRegionalAdminData({ locationId, roleId: regionalAdminRole?.id });
    }

    if (!!employeeRole?.id) {
      setEmployeeAssignedToWorkplace({
        workplaceId: addressId,
        roleId: employeeRole?.id,
        companyId: companyData?.value,
      });
      if (!!locationId) {
        setEmployeeData({ locationId, roleId: employeeRole?.id });
      }
    }
  }, [
    setRegionalAdminAssignedToWorkplace,
    setRegionalAdminData,
    setEmployeeAssignedToWorkplace,
    setEmployeeData,
    locationId,
    roles,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setErrors([]);
  }, [addressId]);

  const getInitialValues = (employeeData, regionalAdminData) => {
    return {
      regionalAdmin: !!regionalAdminData?.length ? regionalAdminData : [],
      employee: !!employeeData?.length ? employeeData : [],
    };
  };

  useEffect(() => {
    const initialValues = getInitialValues(employeeData, regionalAdminData);
    setInitialValues(initialValues);
  }, [employeeData, regionalAdminData]);

  const setNotificationHandler = (message: string, type = 'error' as 'error' | 'success') => {
    const enableTimeout = type === 'success';
    dispatch(addNotification(t(message), type, enableTimeout));
  };

  const setResponseErrors = (setter: Function, errors: tKeyString[]) => {
    const transformField = (field: string): string => {
      switch (field) {
        case 'regional_admins':
          return 'regionalAdmin';
        default:
          return field;
      }
    };

    setter(
      errors
        ? errors?.map((error: tKeyString) => {
            const { key, msg } = error;
            return {
              field: transformField(key),
              error: msg,
            };
          })
        : [],
    );
  };

  useEffect(() => {
    const get = async () => {
      try {
        setLoading(true);
        const roles = await contracts.getUsersRoles();
        setRoles(roles.data);
      } catch (error) {
        setNotificationHandler(
          error?.message ?? t('An error occurred while getting locations types'),
        );
      } finally {
        setLoading(false);
      }
    };
    get();
  }, []);

  const getMessage = (regionalAdminLength, employeeLength) => {
    if (!regionalAdminLength && !employeeLength) {
      return t(
        `You didn't choose any employees and regional administrators. Select item and confirm by clicking `,
      );
    }
    if (!regionalAdminLength && employeeLength) {
      return t(
        `Employees have been saved but you didn't choose any regional administrators. Select item and confirm by clicking `,
      );
    }
    if (regionalAdminLength && !employeeLength) {
      return t(
        `Regional administrators have been saved but you didn't choose any employees. Select item and confirm by clicking `,
      );
    }
  };

  const onSubmit = async ({ values }: { values: tSingleUsersValues }) => {
    try {
      setLoading(true);
      const { regionalAdmin, employee } = values;
      const regionalAdminLength = regionalAdmin?.length;
      const employeeLength = employee?.length;
      const regionalAdminIds = !!regionalAdminLength
        ? regionalAdmin.map((admin) => {
            return {
              id: admin.id,
            };
          })
        : [];
      const employeeIds = !!employeeLength
        ? employee.map((emp) => {
            return { id: emp.id };
          })
        : [];
      const data = {
        workers: employeeIds,
        regional_admins: regionalAdminIds,
      };

      const requiredFields = REQUIRED_FIELDS.CONTRACTS_FORM.LOCATION.SINGLE_USERS_FORM;
      const validationErrors = validateRequiredFields(data, requiredFields);
      if (validationErrors.length) return setResponseErrors(setErrors, validationErrors);

      const response = await contracts.putUsersAndRegionalAdminsToLocation(locationId, data);

      if (response.status === 200) {
        if (!regionalAdminLength || !employeeLength) {
          setNotificationHandler(
            getMessage(regionalAdminLength, employeeLength) ?? 'Something went wrong. Try again',
          );
        } else {
          setNotificationHandler('Users were saved', 'success');
        }
        setErrors([]);
      } else {
        const { data: responseErrors } = response;
        setErrors(
          responseErrors.map((error) => {
            const { key, msg } = error;
            return {
              field: key,
              error: msg,
            };
          }),
        );
      }
    } catch (error) {
      const setters = { setNotificationHandler, setResponseErrors, setErrors };
      catchErrors(error, setters);
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit } = useForm({
    initialValues,
    onSubmit: (values) => onSubmit(values),
  });

  const { regionalAdmin, employee } = values;

  const isLoading =
    loadingRegionalAdminsAssignedToWorkplace ||
    loadingEmployeeAssignedToWorkplace ||
    loadingRegionalAdminsAssignedToLocation ||
    loadingEmployeeAssignedToLocation ||
    loading;

  return (
    <ExpansionPanelWrapper>
      {isLoading && <Spinner />}
      <Form onSubmit={handleSubmit}>
        <>
          <ExpansionPanel hasBottomBorder title='Users' hasIcon iconName='person'>
            <FormWrapper>
              <Row>
                <Column>
                  <DropdownWithList
                    dropdownDisabled={disabled}
                    dropdownErrors={errors}
                    dropdownLabel='Regional Admin'
                    preview={preview}
                    optionsData={
                      !!regionalAdminAssignedToWorkplace.length
                        ? regionalAdminAssignedToWorkplace.map((option) => ({
                            ...option,
                            fieldName: 'regionalAdmin',
                          }))
                        : []
                    }
                    handleChange={handleChange}
                    optionFieldName='regionalAdmin'
                    defaultItems={!!regionalAdmin && !!regionalAdmin.length ? regionalAdmin : []}
                  />
                </Column>
                <Column>
                  <DropdownWithList
                    preview={preview}
                    dropdownDisabled={disabled}
                    dropdownErrors={errors}
                    dropdownLabel='Employee'
                    optionsData={
                      !!employeeAssignedToWorkplace.length
                        ? employeeAssignedToWorkplace.map((option) => ({
                            ...option,
                            fieldName: 'employee',
                          }))
                        : []
                    }
                    handleChange={handleChange}
                    optionFieldName='employee'
                    defaultItems={!!employee && !!employee.length ? employee : []}
                    labelOptional
                  />
                </Column>
              </Row>
            </FormWrapper>
          </ExpansionPanel>
          {!disabled ? (
            <ButtonWrapper>
              <Button variant='green' type='submit'>
                {t('Save')}
              </Button>
            </ButtonWrapper>
          ) : null}
        </>
      </Form>
    </ExpansionPanelWrapper>
  );
};

export default SingleUsersForm;
