import { AxiosResponse } from 'axios';
import { iApiError } from 'types/global';

class Api {
  public static getErrors(
    response: AxiosResponse | iApiError,
    errors: iApiError[] = [],
  ): iApiError[] {
    if (!response) return errors;

    const { data } = response as AxiosResponse;
    if (!data) {
      return errors.includes(response as iApiError) ? errors : [...errors, response as iApiError];
    }

    const { details } = data;
    if (!details || !details.length) {
      return errors.includes(data) ? errors : [...errors, data];
    }

    return errors.includes(details) ? errors : [...errors, ...details];
  }
}

export default Api;
