import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router';
import {
  ExpansionPanel,
  DropdownV2,
  Row,
  Column,
  Datepicker,
  Input,
  Button,
  Spinner,
} from 'components';
import { FormWrapper } from 'styles/GlobalStyledComponents';
import { useFetch } from 'hooks';
import { tContractTerminationValues } from 'types/views/contracts';
import { tOption, tError } from 'types/global';
import contractsTerminationService from 'services/contractsTermination';
import { calculateDate } from 'utils/helpers';
import { getSectionData } from './sectionData';
import { ButtonWrapper } from '../../AddEditFormContracts.style';

type tSectionData = {
  sectionKey: number;
  expansionPanel: {
    title: string;
    iconName: string;
    hasBottomBorder: boolean;
  };
  datepicker: {
    receivedDate: {
      name: string;
      label: string;
    };
    endDate: {
      name: string;
      label: string;
    };
  };
  input: {
    name: string;
    label: string;
  };
  dropdown: {
    name: string;
    label: string;
    options: tOption[];
  };
};

interface iContractTerminationForm extends RouteComponentProps<any> {
  isClientTerminationVisible: boolean;
  isContractorTerminationVisible: boolean;
  disabled?: boolean;
  preview?: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement> | any) => void;
  values: tContractTerminationValues;
  errors: tError[];
}

const ContractTerminationForm: React.FC<iContractTerminationForm> = ({
  isClientTerminationVisible = false,
  isContractorTerminationVisible = false,
  disabled = false,
  preview = false,
  handleChange,
  values,
  errors,
}): JSX.Element | null => {
  const { t } = useTranslation();
  const [clientTerminationEndDate, setClientTerminationEndDate] = useState<Date | null>(null);
  const [contractorTerminationEndDate, setContractorTerminationEndDate] = useState<Date | null>(
    null,
  );
  const hasBottomBorder = !(isClientTerminationVisible && isContractorTerminationVisible);
  const {
    fetch: setTerminationUnitsParamsHandler,
    loading: terminationUnitsLoading,
    data: terminationUnits,
  } = useFetch<tOption>(
    contractsTerminationService.getContractTerminationsUnits,
    'An error occurred while getting termination period units',
  );

  useEffect(() => {
    setTerminationUnitsParamsHandler();
  }, [setTerminationUnitsParamsHandler]);

  const sectionData = getSectionData(hasBottomBorder, terminationUnits);

  useEffect(() => {
    if (!!Object.keys(values).length) {
      const {
        clientTerminationUnit,
        clientTerminationPeriod,
        clientTerminationReceivedDate,
        contractorTerminationUnit,
        contractorTerminationPeriod,
        contractorTerminationReceivedDate,
      } = values;

      const clientTerminationEndDate = calculateDate(
        clientTerminationPeriod || '',
        clientTerminationUnit?.value || '',
        clientTerminationReceivedDate || null,
      );
      setClientTerminationEndDate(clientTerminationEndDate);

      const contractorTerminationEndDate = calculateDate(
        contractorTerminationPeriod || '',
        contractorTerminationUnit?.value || '',
        contractorTerminationReceivedDate || null,
      );
      setContractorTerminationEndDate(contractorTerminationEndDate);
    }
  }, [values]);

  const renderSection = (data) => {
    return data.map((item) => {
      const { expansionPanel, datepicker, input, dropdown, sectionKey } = item;
      const { hasBottomBorder, title, iconName } = expansionPanel;
      const { receivedDate, endDate } = datepicker;
      const { name, label } = input;
      const { name: dropdownName, label: dropdownLabel, options: dropdownOptions } = dropdown;
      const datepickerEndDateValue = {
        clientTerminationEndDate,
        contractorTerminationEndDate,
      };

      return (
        <ExpansionPanel
          hasBottomBorder={hasBottomBorder}
          title={title}
          hasIcon
          iconName={iconName}
          key={sectionKey}
        >
          {terminationUnitsLoading && <Spinner />}
          <FormWrapper>
            <Row>
              <Column>
                <Datepicker
                  errors={errors}
                  name={receivedDate.name}
                  label={receivedDate.label}
                  onChange={handleChange}
                  start={!!Object.keys(values).length ? values[receivedDate.name] : null}
                  readOnly={disabled}
                  disabled={disabled || preview}
                  hideIcon={preview}
                  allowDelete
                />
              </Column>
              <Column col={preview ? 12 : 6}>
                <Input
                  name={name}
                  label={label}
                  onChange={handleChange}
                  errors={errors}
                  value={
                    preview
                      ? `${values[name] || '-'} ${t(values[dropdownName]?.label ?? '', {
                          count: +values[name],
                        })}`
                      : values[name]
                  }
                  disabled={disabled}
                  preview={preview}
                  type='number'
                />
              </Column>
              {!preview && (
                <Column col={6}>
                  <DropdownV2
                    options={dropdownOptions.map(({ value, label }) => ({
                      value,
                      label: t(label, { count: +values[name] }),
                      fieldName: dropdownName,
                    }))}
                    name={dropdownName}
                    label={dropdownLabel}
                    onChange={handleChange}
                    errors={errors}
                    value={
                      !!values[dropdownName]?.value
                        ? {
                            value: values[dropdownName].value,
                            label: t(values[dropdownName].label, { count: +values[name] }),
                          }
                        : null
                    }
                    disabled={disabled}
                    preview={preview}
                  />
                </Column>
              )}
              <Column>
                <Datepicker
                  errors={errors}
                  name={endDate.name}
                  label={endDate.label}
                  readOnly
                  disabled={disabled || preview}
                  hideIcon={preview}
                  start={datepickerEndDateValue[endDate.name]}
                />
              </Column>
            </Row>
          </FormWrapper>
        </ExpansionPanel>
      );
    });
  };

  const [client, contractor] = sectionData;
  let data: tSectionData[] = [];
  if (isClientTerminationVisible) data = [client];
  if (isContractorTerminationVisible) data = [...data, contractor];
  return data.length ? (
    <>
      {renderSection(data)}
      {!disabled && !preview ? (
        <ButtonWrapper>
          <Button variant='green' type='submit'>
            {t('Save')}
          </Button>
        </ButtonWrapper>
      ) : null}
    </>
  ) : null;
};

export default withRouter(ContractTerminationForm);
