import { Icon } from 'components';
import { Blue, Green, Red, RedFilled, Container, ButtonText } from './Button.style';

export type tButton = {
  variant: 'red' | 'redFilled' | 'green' | 'blue';
  kind?: 'filled' | 'outlined';
  children: JSX.Element[] | JSX.Element | string;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  icon?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  id?: string;
};

const Button: React.FC<tButton> = ({
  variant = 'blue',
  kind = 'filled',
  children,
  type = 'button',
  onClick = () => {},
  icon,
  disabled = false,
  style,
  id,
}): JSX.Element => {
  if (kind === 'outlined') {
    switch (variant) {
      default: {
        return (
          <Red disabled={disabled} type={type} onClick={onClick} style={style} id={id}>
            <Container>
              {icon && <Icon icon={icon} width='1.2rem' height='1.2rem' />}
              <ButtonText icon={icon}>{children}</ButtonText>
            </Container>
          </Red>
        );
      }
    }
  } else {
    switch (variant) {
      case 'green': {
        return (
          <Green disabled={disabled} type={type} onClick={onClick} style={style} id={id}>
            <Container>
              {icon && <Icon icon={icon} width='1.2rem' height='1.2rem' />}
              <ButtonText icon={icon}>{children}</ButtonText>
            </Container>
          </Green>
        );
      }
      case 'redFilled': {
        return (
          <RedFilled onClick={onClick} disabled={disabled} type={type} style={style} id={id}>
            <Container>
              {icon && <Icon icon={icon} width='1.2rem' height='1.2rem' />}
              <ButtonText icon={icon}>{children}</ButtonText>
            </Container>
          </RedFilled>
        );
      }
      default: {
        return (
          <Blue disabled={disabled} type={type} onClick={onClick} style={style} id={id}>
            <Container>
              {icon && <Icon icon={icon} width='1.2rem' height='1.2rem' />}
              <ButtonText icon={icon}>{children}</ButtonText>
            </Container>
          </Blue>
        );
      }
    }
  }
};
export default Button;
