import Icon from 'components/Icon';
import { colorGreyBasic } from 'styles/GlobalStyles';
import {
  TilesContainer,
  ArrowContainer,
  TileIcon,
  TileBGIcon,
  TilesContent,
  TilesTitle,
  TilesText,
} from './Tiles.style';

export type tTileContainer = {
  filled?: boolean;
  bgColor?: string;
  clickable?: boolean;
  disabled?: boolean;
};

export type tInverted = {
  inverted?: boolean;
};

export type tTileColorVariant = 'blue' | 'red' | 'green' | 'orange' | 'aquamarine';

export type tTiles = {
  color?: tTileColorVariant;
  variant?: 'outlined' | 'filled';
  arrow?: boolean;
  title?: JSX.Element[] | JSX.Element | string | number;
  text?: JSX.Element[] | JSX.Element | string;
  icon?: string;
  bgIcon?: string;
  onClick?: () => void;
  disabled?: boolean;
};

const Tiles: React.FC<tTiles> = ({
  color = 'orange',
  variant = 'outlined',
  arrow,
  title,
  text,
  icon,
  bgIcon = icon,
  onClick,
  disabled = false,
}): JSX.Element => {
  const filled = variant === 'filled';
  return (
    <TilesContainer
      filled={filled}
      bgColor={color}
      onClick={!onClick || disabled ? () => {} : onClick}
      clickable={!disabled && !!onClick}
      disabled={disabled}
    >
      {arrow && (
        <ArrowContainer>
          <Icon icon='arrowForward' width='1.5rem' height='1.5rem' fill={colorGreyBasic} />
        </ArrowContainer>
      )}
      {bgIcon && (
        <TileBGIcon
          icon={bgIcon}
          fill={filled || disabled ? 'white' : `url(#svg-gradient-${color}) ${color}`}
        />
      )}
      <TilesContent>
        {icon && (
          <TileIcon
            icon={icon}
            fill={filled || disabled ? 'white' : `url(#svg-gradient-${color}) ${color}`}
          />
        )}
        {title && <TilesTitle inverted={filled}>{title}</TilesTitle>}
        <TilesText inverted={filled || disabled}>{text}</TilesText>
      </TilesContent>
    </TilesContainer>
  );
};

export default Tiles;
