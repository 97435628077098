import Keycloak from 'keycloak-js';
import types from './types';

const keycloak = new Keycloak('/keycloak.json');

export const setLoadingAuth = (loading) => {
  return {
    type: types.SET_LOADING_AUTH,
    payload: loading,
  };
};

export const setKeycloak = (kcInstance) => {
  return {
    type: types.SET_KEYCLOAK,
    payload: kcInstance,
  };
};

export const loginSuccess = () => {
  return {
    type: types.LOGIN_SUCCESS,
  };
};

export const loginFailure = (message: string) => {
  return {
    type: types.LOGIN_FAILURE,
    error: message,
  };
};

export const login = () => (dispatch, getState) => {
  try {
    const {
      auth: { kc },
    } = getState();
    if (kc) {
      kc.login();
      dispatch(loginSuccess());
    } else {
      throw new Error('No Keycloak instance');
    }
  } catch (error) {
    if (error?.message) {
      dispatch(loginFailure(error.message));
    }
  }
};

export const logoutSuccess = () => {
  return {
    type: types.LOGOUT_SUCCESS,
  };
};

export const logoutFailure = (message: string) => {
  return {
    type: types.LOGOUT_FAILURE,
    error: message,
  };
};

export const logout = () => (dispatch, getState) => {
  try {
    const {
      auth: { kc },
    } = getState();
    if (kc) {
      kc.logout();
      localStorage.clear();
      dispatch(logoutSuccess());
    } else {
      throw new Error('Logout failed');
    }
  } catch (error) {
    if (error?.message) {
      dispatch(loginFailure(error.message));
    }
  }
};

export const initKeycloak = () => (dispatch) => {
  dispatch(setLoadingAuth(true));
  keycloak
    .init({ onLoad: 'check-sso', checkLoginIframe: false })
    .then()
    .catch(() => {
      dispatch(loginFailure('Keycloak initialisation error'));
    })
    .finally(() => {
      dispatch(setLoadingAuth(false));
    });
  dispatch(setKeycloak(keycloak));
};
