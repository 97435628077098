import { useState, useEffect, useRef } from 'react';

export type tCustomInputEvent = {
  name: string;
  type: string;
  value: any;
  custom: boolean;
};

const useCustomForm = ({ initialValues, onSubmit, isFormDisabled = false }) => {
  const [values, setValues] = useState(initialValues || {});
  const [touched, setTouched] = useState({});
  const [disabled, setDisabled] = useState(false);
  const formRendered = useRef(true);

  useEffect(() => {
    if (!formRendered.current) {
      setValues(initialValues);
      setTouched({});
    }
    formRendered.current = false;
    setDisabled(isFormDisabled);
  }, [initialValues, isFormDisabled]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement> | tCustomInputEvent | any) => {
    if (event?.custom) {
      const { type, name, value } = event;
      switch (type) {
        case 'date': {
          const newValues = {
            ...values,
            [name]: value.start,
          };
          if (typeof value.indefinitely !== 'undefined') {
            newValues[`${name}Indefinitely`] = value.indefinitely;
          }
          if (value.end) {
            newValues[`${name}End`] = value.end;
          }
          setValues(newValues);
          break;
        }
        case 'dropdown-with-tree': {
          const currentValues =
            !!values[name] && values[name].filter((value) => value.fieldName === name);
          if (!!currentValues.length) {
            setValues({ ...values, [name]: [...currentValues, value] });
          } else {
            setValues({ ...values, [name]: [value] });
          }
          break;
        }
        case 'date-time':
        case 'input-dropdown':
        case 'timetable':
        default:
          setValues({ ...values, [name]: value });
      }
    } else if (event && event.target) {
      const getValue = () => {
        const { type, checked, value } = event.target;
        switch (type) {
          case 'file': {
            const fieldName = event.target.name;
            const currentFile = event.target.files[0];

            if (currentFile) {
              const { size, name } = currentFile;
              const file = {
                currentFile,
                filename: name,
                size,
                fieldName,
              };

              let newElement;

              if (!!values[fieldName]) {
                newElement = [...values[fieldName], file];
              } else {
                newElement = [file];
              }
              return newElement;
            }
            return;
          }
          case 'checkbox':
            return checked;
          case 'customCheckbox':
            return checked;
          default:
            return value;
        }
      };
      const formValue = getValue();
      if (event.target.type === 'file' && !!formValue) {
        setValues({ ...values, [event.target.name]: formValue });
      } else {
        setValues({ ...values, [event.target.name]: formValue });
      }
    } else if (event?.type === 'dropdownv2') {
      const { fieldName, label, value, id = null } = event;
      setValues({ ...values, [fieldName]: { label, value, id } });
    } else {
      const { fieldName, label } = event;
      setValues({ ...values, [fieldName]: label });
    }
  };

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    setTouched((prevTouched) => ({ ...prevTouched, [name]: true }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit({ values }, event);
  };

  const removeUploadedFile = (fileName: string, fieldName: string, avatar = false as boolean) => {
    if (avatar) return setValues({ ...values, [fieldName]: [] });
    const currentFile = values[fieldName];
    const filteredFile = !!currentFile?.length
      ? currentFile.filter((file) => file.filename !== fileName)
      : [];
    setValues({ ...values, [fieldName]: filteredFile });
  };

  return {
    values,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    disabled,
    removeUploadedFile,
  };
};

export default useCustomForm;
