import * as Sentry from '@sentry/browser';
import { createReduxEnhancer } from '@sentry/react';
import { appVersion } from 'utils/constants';
import tState from 'types/store';

export type tErrorInfo = { [key: string]: any };

const breadcrumbsSanitize = (breadcrumbs) =>
  breadcrumbs.map((crumb) =>
    crumb?.category === 'console'
      ? {
          ...crumb,
          data: '✂ [...]',
        }
      : crumb,
  );

export const initSentry = () => {
  // eslint-disable-next-line no-underscore-dangle
  const sentryUrl: string | null = window?.__env__?.SENTRY_URL ?? null;

  if (sentryUrl) {
    Sentry.init({
      dsn: sentryUrl,
      // eslint-disable-next-line no-underscore-dangle
      environment: window?.__env__?.ENVIRONMENT ?? 'unknown',
      normalizeDepth: 10,
      beforeSend(event) {
        return { ...event, breadcrumbs: breadcrumbsSanitize(event.breadcrumbs) };
      },
    });
  }
  Sentry.setTag('app_version', appVersion ?? 'unknown');
  Sentry.setTag('app_type', 'web');
  Sentry.setTag('release', appVersion ?? 'unknown');
};

export const setSentryError = (error, errorInfo: tErrorInfo | null = null) => {
  if (errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  } else Sentry.captureException(error);
};

export const sentryReduxEnhancer = createReduxEnhancer({
  stateTransformer: (state: tState) => {
    const transformedState = {
      user: { errors: state.auth.errors },
      auth: {
        id: state.user.id,
        permissions: state.user.permissions,
        roles: state.user.roles,
      },
    };
    return transformedState;
  },
  actionTransformer: (action) => {
    switch (action.type) {
      // don't log
      case 'IDLE_TIME_INCREMENT':
      case 'IDLE_TIME_RESET':
        return null;
      // log
      case 'LOGIN_SUCCESS':
      case 'LOGIN_FAILURE':
      case 'LOGOUT_SUCCESS':
      case 'LOGOUT_FAILURE':
      case 'LOADING_USER':
      case 'SET_LOADING_AUTH':
        return action;
      // sanitize and log
      case 'SAVE_USER':
      case 'SET_KEYCLOAK':
      default:
        return {
          type: action.type,
          payload: '✂ [...]',
        };
    }
  },
});
