/* eslint-disable camelcase */
import { fetchProvider } from 'utils/Axios';
import { iAPIResults, iAPIData } from 'types/global';
import { tGetAllByGet } from 'types/services/issues';
import {
  tGetAllMaintenancesAPI,
  tGetMaintenanceAPI,
  tGetFiltersMaintenanceAPI,
} from 'types/api/maintenances';
import { tMaintenancesPostBodyRequest } from 'types/services/maintenances';

const microservicePath = '/contracts/periodic_inspections';

const maintenances = {
  getAll: (params: tGetAllByGet & { client_ids?: string }): iAPIResults<tGetAllMaintenancesAPI> =>
    fetchProvider.get(microservicePath, { params }),
  get: (id: number | string): iAPIData<tGetMaintenanceAPI> =>
    fetchProvider.get(`${microservicePath}/${id}`),
  create: (data: tMaintenancesPostBodyRequest): iAPIData<tGetMaintenanceAPI> =>
    fetchProvider.post(microservicePath, data),
  update: (data: tMaintenancesPostBodyRequest, id: number | string): iAPIData<tGetMaintenanceAPI> =>
    fetchProvider.put(`${microservicePath}/${id}`, data),
  delete: (id: number | string): iAPIData<{}> => fetchProvider.delete(`${microservicePath}/${id}`),
  getMaintenancesIntervalUnits: (): iAPIData<string[]> =>
    fetchProvider.get('/contracts/periodic_inspection_interval_units'),
  getInspectionAdvancePeriodUnits: (): iAPIData<string[]> =>
    fetchProvider.get('/contracts/periodic_inspection_advance_period_units'),
  getMaintenancesInspectionType: (): iAPIData<string[]> =>
    fetchProvider.get('/contracts/periodic_inspection_inspection_type'),
  getFilters: (): iAPIData<tGetFiltersMaintenanceAPI> =>
    fetchProvider.get(`/contracts/periodic_inspection/filters`),
};

export default maintenances;
