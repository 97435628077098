import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addNotification } from 'store/notifications/actions';
import { Column, ExpansionPanel, Row, Form, Button, DropdownWithList, Spinner } from 'components';
import { catchErrors } from 'utils/helpers';
import applianceTreeService from 'services/applianceTree';
import contracts, { tGetUsersByRoleAssignedToLocationParams } from 'services/contracts';
import itsStructureService from 'services/itsStructure';
import { tItsStructureList } from 'types/services/itsStructure';
import { useForm, useFetch } from 'hooks';
import { tGetUsersByRoleAssignedToLocationData } from 'types/services/contracts';
import { tUserInContractsLocation } from 'types/views/users';
import { tError, tAPIFieldError, tDropdownOption } from 'types/global';
import { tITSTechnicalManagersValues } from 'types/services/locations';
import { tGetAllUsersParams } from 'types/services/users';
import { FormWrapper } from 'styles/GlobalStyledComponents';
import { REQUIRED_FIELDS } from 'utils/requiredFormFields';
import { validateRequiredFieldsWithLoc } from 'utils/form';
import { ExpansionPanelWrapper, ButtonWrapper } from '../../AddEditFormContracts.style';

type tSingleTechnicalManagersITSForm = {
  disabled: boolean;
  preview: boolean;
  isAddMode: boolean;
  id: string;
  addressId: string | number | null;
};

type tInitialValues = {
  technicalManagersITS: tUserInContractsLocation[];
};
export type tNotificationJSXElement = {
  show: boolean;
  message: JSX.Element | string;
  type: 'success' | 'error' | 'warning';
};

const dataModel = {
  technicalManagersITS: [],
};

const SingleTechnicalManagersITSForm: React.FC<tSingleTechnicalManagersITSForm> = ({
  disabled = false,
  preview = false,
  isAddMode = false,
  id: locationId,
  addressId,
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<tInitialValues>(dataModel);
  const [errors, setErrors] = useState<tError[]>([]);
  const [ITSManagerRoles, setITSManagerRoles] = useState<{ name: string; id: string }[]>([]);
  const [industries, setIndustries] = useState<tDropdownOption[]>([]);

  const {
    fetch: setITSUsersParamsHandler,
    loading: ITSUsersLoading,
    data: ITSUsers,
  } = useFetch<tItsStructureList, tGetAllUsersParams>(
    itsStructureService.getAll,
    'Something went wrong while fetching data',
  );

  const {
    fetch: setParamsHandler,
    loading: loadingData,
    data,
  } = useFetch<tGetUsersByRoleAssignedToLocationData, tGetUsersByRoleAssignedToLocationParams>(
    contracts.getUsersByRoleAssignedToLocation,
    'Something went wrong while fetching data',
  );

  const setNotificationHandler = (message: string, type = 'error' as 'error' | 'success') => {
    const enableTimeout = type === 'success';
    dispatch(addNotification(t(message), type, enableTimeout));
  };

  useEffect(() => {
    const get = async () => {
      try {
        const roles = await contracts.getUsersRoles();
        setITSManagerRoles(roles.data);
      } catch (error) {
        setNotificationHandler(error?.message ?? t('An error occurred while getting users roles'));
      }
    };
    get();
  }, []);

  useEffect(() => {
    setErrors([]);
  }, [addressId]);

  const fetchData = useCallback(() => {
    const ITSManagerRole = ITSManagerRoles.find((role) => role.name === 'Kierownik techniczny ITS');
    if (!addressId || isAddMode) return;
    setITSUsersParamsHandler();
    if (!!ITSManagerRole) setParamsHandler({ locationId, roleId: ITSManagerRole.id });
  }, [setParamsHandler, setITSUsersParamsHandler, locationId, ITSManagerRoles, addressId]);

  const getIndustries = async () => {
    try {
      setLoading(true);
      const result: { id: number; name: string }[] = await applianceTreeService.getIndustries();
      const industriesOptions = result.map((industry) => {
        const { id, name } = industry;
        return {
          id,
          value: name,
          label: name,
        };
      });
      setIndustries(industriesOptions);
    } catch (error) {
      setIndustries({ ...industries });
      setNotificationHandler(
        error?.message ?? t('There was a problem with downloading industries'),
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getIndustries();
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const getInitialValues = (data) => {
    if (Object.keys(data).length > 0) return { technicalManagersITS: data };
    return dataModel;
  };

  useEffect(() => {
    setInitialValues(getInitialValues(data));
  }, [data]);

  const setResponseErrors = (setter: Function, errors: tAPIFieldError[]) => {
    const transformField = (field: string): string => {
      switch (field) {
        case 'sub_contract_number':
          return 'subcontractNumber';
        case 'controlling_order_number':
          return 'controllingOrderNumber';
        case 'industries_ids':
          return 'industry';
        default:
          return '__noField__';
      }
    };

    setter(
      errors
        ? errors?.map((error: tAPIFieldError) => {
            const { key, msg, loc } = error;
            return {
              field: transformField(key),
              error: msg,
              errorIndex: !!loc?.length ? loc[1] : null,
            };
          })
        : [],
    );
  };

  const onSubmit = async ({ values }: { values: tITSTechnicalManagersValues }) => {
    try {
      setLoading(true);
      const { technicalManagersITS } = values;
      const data = technicalManagersITS.map((managerITS, index) => {
        const { subcontractNumber, controllingOrderNumber, id, industry } = managerITS;
        return {
          id,
          sub_contract_number: subcontractNumber,
          controlling_order_number: controllingOrderNumber,
          industries_ids: industry.length ? industry.map((item) => item.id) : [],
          index,
        };
      });

      const requiredFields =
        REQUIRED_FIELDS.CONTRACTS_FORM.LOCATION.SINGLE_TECHNICAL_MANAGERS_ITS_FORM;
      const validationErrors = validateRequiredFieldsWithLoc(data, requiredFields);
      if (validationErrors.length) return setResponseErrors(setErrors, validationErrors);

      const response = await contracts.putITSManagersAssignedToLocation(locationId, data);
      if (response.status === 200) {
        if (!technicalManagersITS.length) {
          setNotificationHandler(
            t(`You didn't choose any ITS managers. Select item and confirm by clicking`),
            'error',
          );
        } else {
          setNotificationHandler(t('Technical Managers ITS were saved'), 'success');
        }
        setErrors([]);
      } else {
        const { data: responseErrors } = response;
        setErrors(
          responseErrors.map((error) => {
            const { key, msg } = error;
            return {
              field: key,
              error: msg,
            };
          }),
        );
      }
    } catch (error) {
      const setters = { setNotificationHandler, setResponseErrors, setErrors };
      catchErrors(error, setters);
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit } = useForm({
    initialValues,
    onSubmit: (values) => onSubmit(values),
  });

  const extraFields = [
    {
      type: 'input',
      name: 'controllingOrderNumber',
      label: 'Controlling order number',
    },
    {
      type: 'input',
      name: 'subcontractNumber',
      label: 'Subcontract number',
    },
    {
      type: 'dropdownWithList',
      name: 'industry',
      label: 'Industry',
      optionsData: industries,
    },
  ];

  const { technicalManagersITS } = values;

  const ITSUsersOptions = !!ITSUsers?.length
    ? ITSUsers.map((option) => {
        const { userNameAndSurname, id } = option;
        return {
          id,
          value: userNameAndSurname,
          label: userNameAndSurname,
          fieldName: 'technicalManagersITS',
        };
      })
    : [];

  const isLoading = loadingData || loading || ITSUsersLoading;

  return (
    <ExpansionPanelWrapper>
      {isLoading && <Spinner />}
      <ExpansionPanel hasBottomBorder title='Technical Managers ITS' hasIcon iconName='person'>
        <Form onSubmit={handleSubmit}>
          <>
            <FormWrapper>
              <Row>
                <Column>
                  <DropdownWithList
                    dropdownDisabled={disabled}
                    preview={preview}
                    dropdownErrors={errors}
                    dropdownLabel='Technical Managers ITS'
                    optionsData={ITSUsersOptions}
                    handleChange={handleChange}
                    optionFieldName='technicalManagersITS'
                    defaultItems={
                      !!technicalManagersITS?.length && !!technicalManagersITS.length
                        ? technicalManagersITS
                        : []
                    }
                    extraFields={extraFields}
                  />
                </Column>
              </Row>
            </FormWrapper>
            {!disabled ? (
              <ButtonWrapper>
                <Button variant='green' type='submit'>
                  {t('Save')}
                </Button>
              </ButtonWrapper>
            ) : null}
          </>
        </Form>
      </ExpansionPanel>
    </ExpansionPanelWrapper>
  );
};

export default SingleTechnicalManagersITSForm;
