import styled from 'styled-components';
import { colorRedDark } from 'styles/GlobalStyles';

type tContainerWithMargin = {
  value: string;
};

export const CenteredFlex = styled.div`
  align-items: center;
  display: flex;
`;
export const CommentIconContainer = styled.div`
  line-height: 1.5rem;
  position: absolute;
  right: -1.5rem;
  top: 0;
`;

export const ContainerWithMargin = styled.div<tContainerWithMargin>`
  margin: ${({ value }) => `${value}`};
`;

export const Value = styled.div`
  font-size: 0.75rem;
`;

export const FieldComment = styled.div`
  font-size: 0.75rem;
  color: ${colorRedDark};
`;
