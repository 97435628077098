/* eslint-disable camelcase */
import clients from 'agents/clients';
import { tGetClientData, tGetAllClientsData } from 'types/services/clients';
import { tCreateClientAPI, tEditClientAPI } from 'types/api/clients';
import { tGet, tGetAll } from 'types/global';

export type tGetAllArchived = tGetAll & { archived?: boolean };

const getAll = (params: tGetAllArchived) => {
  const { offset = 0 } = params;

  const result = clients.getAll(params).then((response) => {
    const results: tGetAllClientsData[] = response.data.results.map((result, index) => {
      const { address, name, sap_number, nip, id } = result;
      return {
        id,
        order: index + 1 + offset,
        // eslint-disable-next-line max-len
        address: `${address.street} ${address.building_number} ${address.city} ${address.postal_code}`,
        customerName: name,
        sapNumber: sap_number,
        taxPayerId: nip,
      };
    });

    const { status } = response;
    return {
      data: { ...response.data, results },
      status,
    };
  });

  return result;
};

const get = ({ id }: tGet) => {
  const result = clients.get(id).then(({ data, status }) => {
    const { segment, legal_form, address, name, type, nip, regon, web_page, sap_number, archived } =
      data;

    const result: tGetClientData = {
      id: String(id),
      customerName: name,
      typeOfClient: {
        value: type,
        label: type === 'stationary' ? 'Stacjonarny' : 'Sieciowy',
        fieldName: 'typeOfClient',
      },
      taxPayerId: nip,
      regon,
      webPage: web_page,
      sapNumber: sap_number,
      archivingSwitch: archived,
      segment: {
        value: segment.id,
        label: segment.name,
        fieldName: 'segment',
      },
      legalForm: {
        value: legal_form.id,
        label: legal_form.name,
        fieldName: 'legalForm',
      },
      address: {
        id: address.id,
        city: address.city,
        street: address.street,
        buildingNumber: address.building_number,
        postCode: address.postal_code,
        region: {
          value: address.region.id,
          label: address.region.name,
          fieldName: 'province',
        },
        country: {
          value: address.region.country.id,
          label: address.region.country.name,
          fieldName: 'country',
        },
      },
    };

    return {
      data: result,
      status,
    };
  });
  return result;
};

const getSegments = () => {
  const result = clients.getSegments().then(({ data }) => {
    const results = data.results.map(({ id, name }) => {
      return {
        value: id,
        label: name,
      };
    });

    return {
      data: { results },
    };
  });
  return result;
};

const getRegions = () => {
  const result = clients.getRegions().then(({ data }) => {
    const results = data.results.map(({ id, name }) => {
      return {
        value: id,
        label: name,
      };
    });

    return {
      data: { results },
    };
  });
  return result;
};

const getCountries = () => {
  const result = clients.getCountries().then(({ data }) => {
    const results = data.results.map(({ id, name }) => {
      return {
        value: id,
        label: name,
      };
    });

    return {
      data: { results },
    };
  });
  return result;
};

const getLegalForm = () => {
  const result = clients.getLegalForm().then(({ data }) => {
    const results = data.results.map(({ id, name }) => {
      return {
        value: id,
        label: name,
      };
    });

    return {
      data: { results },
    };
  });
  return result;
};

const create = (data: tCreateClientAPI) => clients.create(data);

const edit = async (id: string, data: tEditClientAPI) => clients.edit(id, data);

export default {
  getAll,
  get,
  getSegments,
  getRegions,
  getCountries,
  getLegalForm,
  create,
  edit,
};
