/* eslint-disable no-param-reassign */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, Icon, Row, Input, DropdownV2, Label } from 'components';
import { sortArrayById } from 'utils/helpers';
import { useFormErrorsReset } from 'hooks';
import { colorGreyBasic, colorGreenBasic } from 'styles/GlobalStyles';
import { BottomMargin1Rem } from 'styles/GlobalStyledComponents';
import {
  ButtonWrapper,
  ComponentWrapper,
  ItemRow,
  ItemTitle,
  List,
  Relative,
  PreviewWrapper,
  ItemsWrapper,
  Item,
} from './DropdownWithList.style';

export type tListOption = {
  value: string | number | null;
  label: string;
  fieldName: string;
  id: number | string | null;
  __isNew__?: boolean;
};

type tExtraFields = {
  type: string;
  name: string;
  label: string;
  optionsData?: any[];
  value?: any;
};

type tDropdownWithList = {
  defaultItems?: any[];
  handleChange: Function;
  preview?: boolean;
  dropdownDisabled: boolean;
  dropdownErrors: any[];
  dropdownLabel: string;
  labelMinWidth?: number;
  optionsData: tListOption[];
  optionFieldName: string;
  extraFields?: tExtraFields[];
  isItemRemovable?: boolean;
  labelOptional?: boolean;
};

const DropdownWithList: React.FC<tDropdownWithList> = ({
  defaultItems = [],
  handleChange,
  preview = false,
  dropdownDisabled = false,
  dropdownErrors,
  dropdownLabel,
  labelMinWidth = 90,
  optionsData,
  optionFieldName,
  extraFields = [],
  isItemRemovable = false,
  labelOptional = false,
}): JSX.Element => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<tListOption[]>([]);
  const [chosenItem, setChosenItem] = useState<any>(null);
  const [items, setItems] = useState<tListOption[]>([]);
  const [filteredErrors, setFilteredErrors] = useState<any[]>([]);
  const { formElemErrors, resetFormError } = useFormErrorsReset(filteredErrors);

  useEffect(() => {
    setFilteredErrors(dropdownErrors);
  }, [dropdownErrors]);

  const onChange = ({ fieldName, label: itemsCopy, itemName, index = null }) => {
    if (!!itemName) setFilteredErrors(resetFormError(itemName, index));
    handleChange({ fieldName, label: itemsCopy });
  };

  useEffect(() => {
    if (!!defaultItems.length) {
      setItems(defaultItems);
      setOptions(
        optionsData.filter(
          (optionItem: any) =>
            defaultItems.filter((defaultItem: any) => {
              return !!defaultItem && defaultItem.id === optionItem.id;
            }).length === 0,
        ),
      );
    } else {
      setItems([]);
      setOptions(optionsData);
    }
  }, [defaultItems]);

  const setItemsList = (chosenItem: tListOption) => {
    // eslint-disable-next-line no-underscore-dangle
    if (chosenItem.__isNew__) chosenItem.id = null;
    const newItems = [...items, chosenItem];
    if (!!extraFields.length) {
      extraFields.forEach((extraField) => {
        newItems.forEach((item) => {
          item[extraField.name] = item[extraField.name] || '';
        });
      });
    }
    setItems(newItems);
    setOptions((prevOptions: tListOption[]) => [
      ...prevOptions.filter((item: tListOption) => item.id !== chosenItem.id),
    ]);
    setChosenItem(null);
    const itemName = null;
    onChange({ fieldName: optionFieldName, label: newItems, itemName });
  };

  const removeItemFromList = (removedItem: tListOption) => {
    const newItems = items.filter((item: tListOption) => item.value !== removedItem.value);

    setOptions((prevArray: tListOption[]) => [...prevArray, removedItem]);
    setItems(newItems);
    const itemName = null;
    onChange({ fieldName: optionFieldName, label: newItems, itemName });
  };

  const handleExtraFieldsChange = (event, itemId, elementType, index) => {
    const itemsCopy = [...items];
    itemsCopy.forEach((itemCopy) => {
      if (itemCopy.id === itemId) {
        switch (elementType) {
          case 'input':
            itemCopy[event.target.name] = event.target.value;
            break;
          case 'dropdownWithList':
            itemCopy[event.fieldName] = event.label;
            break;
          default:
            return null;
        }
      }
    });
    setItems(itemsCopy);
    const itemName = elementType === 'input' ? event?.target?.name : event.fieldName;
    onChange({ fieldName: optionFieldName, label: itemsCopy, itemName, index });
  };

  const renderInput = (extraField, currentItem, index) => {
    const { name, label, type } = extraField;
    const filteredErrors = formElemErrors.filter((err) => err?.errorIndex === index);
    return (
      <Input
        key={name}
        name={name}
        label={label}
        onChange={(event) => handleExtraFieldsChange(event, currentItem.id, type, index)}
        errors={filteredErrors}
        value={currentItem[name]}
      />
    );
  };
  const renderDropdownWithList = (extraField, currentItem, index) => {
    const { name, label, type, optionsData } = extraField;
    const filteredErrors = formElemErrors.filter((err) => err?.errorIndex === index);
    return (
      <DropdownWithList
        key={name}
        dropdownDisabled={dropdownDisabled}
        dropdownErrors={filteredErrors}
        dropdownLabel={label}
        optionsData={optionsData ?? []}
        handleChange={(event) => handleExtraFieldsChange(event, currentItem.id, type, index)}
        optionFieldName={name}
        defaultItems={!!currentItem[name] ? currentItem[name] : []}
      />
    );
  };

  return preview ? (
    <PreviewWrapper>
      {dropdownLabel && (
        <BottomMargin1Rem>
          <Label labelMinWidth={labelMinWidth} optional={labelOptional}>
            {t(dropdownLabel)}
          </Label>
        </BottomMargin1Rem>
      )}
      <ItemsWrapper>
        {items.length ? (
          defaultItems.map((item) => {
            const { id, value } = item;
            return <Item key={id}>{value || '-'}</Item>;
          })
        ) : (
          <Item>-</Item>
        )}
      </ItemsWrapper>
    </PreviewWrapper>
  ) : (
    <ComponentWrapper>
      <Row justifyContent='space-between'>
        <Column>
          <Relative>
            <DropdownV2
              name={optionFieldName}
              label={dropdownLabel}
              options={sortArrayById(options)}
              onChange={(e) => setChosenItem(e)}
              errors={formElemErrors}
              value={chosenItem?.value ?? ''}
              disabled={dropdownDisabled}
              isCreatable
              labelOptional={labelOptional}
            />
            <ButtonWrapper>
              <Icon
                asButton
                icon='checkCircle'
                disable={!chosenItem?.value}
                fill={!chosenItem?.value ? colorGreyBasic : colorGreenBasic}
                onClick={() => setItemsList(chosenItem)}
              />
            </ButtonWrapper>
          </Relative>
        </Column>
      </Row>
      {!!items.length && (
        <List>
          {items.map((item: tListOption, index: number) => {
            const { id, value } = item;
            return (
              <div key={id || index}>
                <ItemRow>
                  <ItemTitle>{value}</ItemTitle>
                  {(!dropdownDisabled || isItemRemovable) && (
                    <Icon
                      asButton
                      icon='close'
                      fill='red'
                      onClick={() => removeItemFromList(item)}
                    />
                  )}
                </ItemRow>
                {!!extraFields &&
                  !!extraFields.length &&
                  extraFields.map((extraField: tExtraFields) => {
                    switch (extraField.type) {
                      case 'input':
                        return renderInput(extraField, item, index);
                      case 'dropdownWithList':
                        return renderDropdownWithList(extraField, item, index);
                      default:
                        return null;
                    }
                  })}
              </div>
            );
          })}
        </List>
      )}
    </ComponentWrapper>
  );
};

export default DropdownWithList;
