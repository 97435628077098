/* eslint-disable no-param-reassign */
import { useState } from 'react';
import { Column, Icon, Row, DropdownV2 } from 'components';
import { colorGreyBasic, colorGreenBasic } from 'styles/GlobalStyles';
import { Relative } from 'styles/GlobalStyledComponents';
import { tDropdownOption } from 'types/global';
import { ButtonWrapper, ComponentWrapper } from './DropdownWithCheck.style';

type tDropdownWithCheck = {
  onChange: Function;
  disabled: boolean;
  errors: any[];
  label: string;
  optionsData: tDropdownOption[];
  optionFieldName: string;
};

const DropdownWithCheck: React.FC<tDropdownWithCheck> = ({
  onChange,
  disabled,
  errors,
  label,
  optionsData,
  optionFieldName,
}): JSX.Element => {
  const [chosenItem, setChosenItem] = useState<any>(null);

  return (
    <ComponentWrapper>
      <Row justifyContent='space-between'>
        <Column>
          <Relative>
            <DropdownV2
              name={optionFieldName}
              label={label}
              options={optionsData}
              onChange={(e) => setChosenItem(e)}
              errors={errors}
              value={chosenItem?.label ?? ''}
              disabled={disabled}
              isCreatable
            />
            <ButtonWrapper>
              <Icon
                asButton
                icon='checkCircle'
                disable={!chosenItem?.value}
                fill={!chosenItem?.value ? colorGreyBasic : colorGreenBasic}
                onClick={() => onChange(chosenItem)}
              />
            </ButtonWrapper>
          </Relative>
        </Column>
      </Row>
    </ComponentWrapper>
  );
};

export default DropdownWithCheck;
