import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'hooks';
import { Row, Column, DropdownV2, InputRadio, Label } from 'components';
import contractsService from 'services/contracts';
import subcontractorsService from 'services/subcontractors';
import { tSubcontractorListItem } from 'types/services/subcontractors';
import { tError, tDropdownOption, tOption } from 'types/global';

export type tTechnicianSelect = {
  technician: tDropdownOption | null;
  setTechnician: (data: tDropdownOption | null) => void;
  setIsSubcontractorAssigned: (isSubcontractorAssigned: boolean) => void;
  setLoading: (loading: boolean) => void;
  errors: tError[];
};

export const TechnicianSelect: React.FC<tTechnicianSelect> = ({
  technician,
  setTechnician,
  setIsSubcontractorAssigned,
  setLoading,
  errors,
}): JSX.Element => {
  const { t } = useTranslation();

  const [technicianChecked, setTechnicianChecked] = useState<'technicianITS' | 'subcontractor'>(
    'technicianITS',
  );

  const {
    fetch: fetchSubcontractors,
    loading: loadingSubcontractors,
    data: subcontractorsOptions,
  } = useFetch<tSubcontractorListItem>(
    subcontractorsService.getAll,
    'An error occurred while getting subcontractors',
  );

  const {
    fetch: fetchTechniciansITS,
    loading: loadingTechniciansITS,
    data: technicianITSOptions,
  } = useFetch<tOption>(
    contractsService.getITSTechnicians,
    'An error occurred while getting ITS technicians',
  );
  const [technicianOptions, setTechnicianOptions] = useState<tDropdownOption[] | null>([]);

  useEffect(() => {
    fetchTechniciansITS();
  }, [fetchTechniciansITS]);

  useEffect(() => {
    if (technicianChecked === 'technicianITS') {
      setIsSubcontractorAssigned(false);
      setTechnicianOptions(technicianITSOptions);
    }

    if (technicianChecked === 'subcontractor') {
      setIsSubcontractorAssigned(true);
      const subcontractors = subcontractorsOptions?.length
        ? subcontractorsOptions.map((subcontractor) => {
            return {
              label: subcontractor.name,
              value: subcontractor.id,
            };
          })
        : [];
      setTechnicianOptions(subcontractors);
    }
  }, [technicianChecked, technicianITSOptions, subcontractorsOptions]);

  useEffect(() => {
    setLoading(loadingTechniciansITS);
  }, [loadingTechniciansITS]);

  useEffect(() => {
    setLoading(loadingSubcontractors);
  }, [loadingSubcontractors]);

  const onTechnicianITSRadioChange = () => {
    setTechnician(null);
    setTechnicianChecked('technicianITS');
    setTechnicianOptions(technicianITSOptions);
  };

  const onSubcontractorRadioChange = () => {
    if (!subcontractorsOptions.length) fetchSubcontractors({ archived: false });
    setTechnician(null);
    setTechnicianChecked('subcontractor');
  };

  const setTechnicianHandler = ({ value, label }) => setTechnician({ value, label });

  return (
    <Row justifyContent='space-between'>
      <Column>
        <div style={{ display: 'flex', marginBottom: '1rem' }}>
          <Label>{t('Executor')}</Label>
          <InputRadio
            label='Service technician'
            name='technicianITS'
            checked={technicianChecked}
            onChange={onTechnicianITSRadioChange}
            errors={errors}
          />
          <InputRadio
            label='Subcontractor company'
            name='subcontractor'
            checked={technicianChecked}
            onChange={onSubcontractorRadioChange}
            errors={errors}
          />
        </div>
      </Column>

      <Column>
        <DropdownV2
          name='technician'
          label=' '
          labelMinWidth={100}
          options={
            technicianOptions?.length
              ? technicianOptions.map((el) => ({ ...el, fieldName: 'technician' }))
              : []
          }
          onChange={setTechnicianHandler}
          errors={[]}
          value={technician}
        />
      </Column>
    </Row>
  );
};

export default TechnicianSelect;
