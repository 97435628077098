import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExpansionPanel } from 'components';
import { FormWrapper } from 'styles/GlobalStyledComponents';
import { ShowMore, ShowMoreButton, ModSpace, ExpansionPanelWrapper } from './ModHistory.style';
import SingleModHistory, { tSingleModHistoryData } from './SingleModHistory';

type tModHistory = {
  data: tSingleModHistoryData[];
  isAddMode?: boolean;
};

const ModHistory: React.FC<tModHistory> = ({
  data = [],
  isAddMode = false,
}): JSX.Element | null => {
  const [quantity, setQuantity] = useState<number>(1);
  const { t } = useTranslation();

  if (isAddMode || !data.length) return null;
  return (
    <ExpansionPanelWrapper>
      <ExpansionPanel hasBottomBorder title='Mod history' hasIcon iconName='history'>
        <FormWrapper>
          {data.map(
            (item, index) => index < quantity && <SingleModHistory key={item.id} data={item} />,
          )}
        </FormWrapper>
      </ExpansionPanel>
      {data.length > quantity && (
        <>
          <ModSpace />
          <ShowMore>
            <ShowMoreButton type='button' onClick={() => setQuantity(quantity + 3)}>
              {t('See more')}
            </ShowMoreButton>
          </ShowMore>
        </>
      )}
    </ExpansionPanelWrapper>
  );
};

export default ModHistory;
