import styled from 'styled-components';

export const MainButtonWrapper = styled.div`
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 0.875rem;
  display: flex;
`;

export const ButtonWrapper = styled(MainButtonWrapper)`
  justify-content: flex-end;
`;

export const AddButtonWrapper = styled(MainButtonWrapper)`
  justify-content: center;
`;
