import { useState } from 'react';
import { Modal, Textarea } from 'components';
import { useTranslation } from 'react-i18next';
import { FlexV } from 'styles/GlobalStyledComponents';
import { tError } from 'types/global';
import { ChangeDateModalTitle } from '../AddEditFormIssues.style';

export type tChangeDateDialog = {
  show: boolean;
  hideDialog: (hide: boolean) => void;
  confirmMethod: (comments: string) => void;
  errors: tError[];
};

const ChangeDateDialog: React.FC<tChangeDateDialog> = ({
  show,
  hideDialog,
  confirmMethod,
  errors,
}) => {
  const { t } = useTranslation();
  const [comments, setComments] = useState<string>('');

  if (!show) return null;
  return (
    <Modal
      title={t('Change date')}
      cancelButtonText={t('Cancel')}
      confirmButtonText={t('Send')}
      icon='calendar'
      cancelMethod={() => hideDialog(false)}
      confirmMethod={() => confirmMethod(comments)}
    >
      <FlexV>
        <ChangeDateModalTitle>{t('Suggest a new date for visit')}</ChangeDateModalTitle>
        <Textarea
          name='comments'
          label='Comments'
          errors={errors}
          onChange={(e) => setComments(e.target.value)}
          value={comments}
        />
      </FlexV>
    </Modal>
  );
};

export default ChangeDateDialog;
