import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import Icon from 'components/Icon';
import { colorGreyBasic, colorBlack } from 'styles/GlobalStyles';
import {
  JumpToPageText,
  JumpToPageInput,
  JumpToPageBlock,
  PaginationContainer,
  Pages,
  Results,
  ResultsButton,
  Wrapper,
  ResultsTitle,
  ResultsContainer,
  JumpToPageContainer,
} from './Pagination.style';

type tPagination = {
  totalRecords: number;
  setParams: Function;
  limit?: number;
  offset?: number;
};

export const countOffset = (page: number, limit: number): number => {
  return Math.ceil(page * limit);
};
export const countPage = (offset: number, limit: number): number => Math.ceil(offset / limit);

const limits: number[] = [10, 20, 50];

const Pagination: React.FC<tPagination> = ({
  totalRecords,
  setParams,
  limit = 10,
  offset = 0,
}): JSX.Element => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(0);
  const [showLimit, setShowLimit] = useState<boolean>(false);
  const [jumpPage, setJumpPage] = useState<number>(1);
  const pageCountHelper: number = Math.ceil(totalRecords / limit);

  const correctJumpPage = (newPage: number) => {
    const max: number = pageCountHelper;
    const pageValue: number = newPage >= max ? max : newPage;

    setJumpPage(pageValue <= 0 ? 1 : pageValue);
  };
  useEffect(() => {
    if (typeof offset === 'number' && typeof limit === 'number') {
      const pageNumber: number = countPage(offset, limit);
      const isMorePagesThanRecords = (pageNumber + 1) * limit >= totalRecords;
      const isMoreLimitThanRecords = totalRecords <= limit;
      if (page === pageNumber) return;
      if (isMoreLimitThanRecords || isMorePagesThanRecords) {
        setPage(0);
        setJumpPage(1);
        setParams(countOffset(0, limit), limit);
        return;
      }
      setPage(pageNumber);
      setJumpPage(pageNumber + 1);
      setParams(countOffset(pageNumber, limit), limit);
    }
  }, [offset, limit]);

  const handlePageClick = (data: { selected: number }) => {
    setPage(data.selected);
    setJumpPage(data.selected + 1);
    setParams(countOffset(data.selected, limit), limit);
  };

  const setLimitHandler = (newLimit: number) => {
    setPage(0);
    setJumpPage(1);
    setShowLimit(false);
    setParams(countOffset(page, newLimit), newLimit);
  };

  return (
    <Wrapper>
      <Results>
        <ResultsTitle>{t('Results on page')}:</ResultsTitle>
        <ResultsContainer>
          <span data-testid='Pagination-span'>{limit}</span>
          <Icon
            icon='arrowDown'
            onClick={() => setShowLimit(true)}
            data-testid='Pagination-ResultsButton'
            fill={colorGreyBasic}
            asButton
          />
        </ResultsContainer>
        {showLimit && (
          <ul>
            {limits.map((limitItem) => (
              <li key={limitItem}>
                <ResultsButton
                  data-testid={`Pagination-ResultsButton-${limitItem}`}
                  type='button'
                  onClick={() => setLimitHandler(limitItem)}
                >
                  {limitItem}
                </ResultsButton>
              </li>
            ))}
          </ul>
        )}
      </Results>
      <PaginationContainer>
        <Pages>
          <Icon
            icon='firstPage'
            fill={page === 0 ? colorGreyBasic : colorBlack}
            asButton
            disable={page === 0}
            onClick={() => handlePageClick({ selected: 0 })}
          />

          <ReactPaginate
            previousLabel={<Icon icon='chevronLeft' />}
            nextLabel={<Icon icon='chevronRight' />}
            breakLabel={<Icon icon='moreHoriz' fill={colorGreyBasic} />}
            breakClassName='break-me'
            pageCount={pageCountHelper}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName='pagination'
            activeClassName='active'
            forcePage={page}
          />

          <Icon
            icon='lastPage'
            fill={page === pageCountHelper - 1 ? colorGreyBasic : colorBlack}
            asButton
            disable={page === pageCountHelper - 1}
            onClick={() => handlePageClick({ selected: pageCountHelper - 1 })}
          />
        </Pages>
        <JumpToPageBlock>
          <JumpToPageText>{t('Jump to page')}:</JumpToPageText>
          <JumpToPageContainer>
            <JumpToPageInput
              type='number'
              min={1}
              value={jumpPage}
              placeholder=''
              onChange={({ target: { value } }) => correctJumpPage(Number(value))}
            />
            <Icon
              onClick={() => handlePageClick({ selected: Number(jumpPage) - 1 })}
              icon='chevronRight'
              fill={colorGreyBasic}
              asButton
            />
          </JumpToPageContainer>
        </JumpToPageBlock>
      </PaginationContainer>
    </Wrapper>
  );
};

export default Pagination;
