import styled from 'styled-components';

export const TooltipText = styled.div`
  cursor: pointer;
`;

export const TooltipBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: calc(-100% - 15px);
  left: 50%;
  transform: translateX(-50%);
  color: var(--theme-white);
  background-color: var(--theme-mainGrey);
  width: max-content;
  padding: var(--small-padding) var(--medium-padding);
  visibility: hidden;
  border-radius: var(--theme-borderRadius-xs);

  &:before {
    content: '';
    left: 50%;
    transform: translateX(-50%);
    bottom: -10px;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid var(--theme-mainGrey);
    transition: border 0.3s ease-in-out;
  }
`;

export const TooltipCard = styled.div`
  position: relative;
  & ${TooltipText}:hover + ${TooltipBox} {
    visibility: visible;
    color: var(--theme-white);
    background-color: var(--theme-mainGrey);
    width: max-content;
    padding: var(--small-padding) var(--medium-padding);
    border-radius: var(--theme-borderRadius-xs);
  }
`;
