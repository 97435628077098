import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addNotification } from 'store/notifications/actions';
import { useFetch } from 'hooks';
import { Button, ExpansionPanel, Icon, List, Spinner, Label, SimpleDialog } from 'components';
import { CenterWithGaps, Flex, FormWrapper } from 'styles/GlobalStyledComponents';
import { colorBlueBasic, colorRedBasic } from 'styles/GlobalStyles';
import periodicActivitiesService, { tPeriodicActivity } from 'services/contractsPeriodicActivities';
import { tGet, tOpenedRightForm } from 'types/global';

type tPeriodicActivitiesForm = {
  id: number | undefined | null;
  disabled?: boolean;
  preview?: boolean;
  openRightPanel: ({ form, id, disabled }) => void;
  rightForm: tOpenedRightForm;
};

const PeriodicActivitiesForm: React.FC<tPeriodicActivitiesForm> = ({
  id,
  openRightPanel,
  rightForm,
  disabled = false,
  preview = false,
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [lastFormOpened, setLastFormOpened] = useState('');

  const {
    fetch: fetchPeriodicActivities,
    loading: LoadingPeriodicActivities,
    data: periodicActivities,
  } = useFetch<tPeriodicActivity, tGet>(
    periodicActivitiesService.getAll,
    'Something went wrong while fetching data',
  );

  const fetchData = useCallback(() => {
    if (!!id) fetchPeriodicActivities({ id });
  }, [fetchPeriodicActivities, preview, disabled]);

  useEffect(() => {
    fetchData();
  }, [fetchData, id]);

  useEffect(() => {
    if (
      lastFormOpened === 'SinglePeriodicActivitiesForm' &&
      rightForm?.form !== 'SinglePeriodicActivitiesForm' &&
      !!id
    ) {
      fetchPeriodicActivities({ id });
    }
    setLastFormOpened(rightForm?.form);
  }, [rightForm?.form]);

  const removeItemHandler = async (itemId: number) => {
    const result = await periodicActivitiesService.remove(itemId);
    const { status, error } = result;
    if (status === 204) {
      dispatch(addNotification(t('Periodic activity was removed'), 'success', true));
      openRightPanel({ form: '', id: null, disabled });
    } else {
      dispatch(addNotification(t(error), 'error'));
    }
    fetchData();
  };

  const items = periodicActivities?.map((item: { id: number; name: string }) => {
    const { id: itemId, name } = item;

    return {
      ...item,
      actions:
        disabled || preview
          ? [
              {
                action: <Icon width='1rem' height='1rem' icon='visibility' fill={colorBlueBasic} />,
                actionHandler: () =>
                  openRightPanel({ form: 'SinglePeriodicActivitiesForm', id: itemId, disabled }),
              },
            ]
          : [
              {
                action: <Icon width='1rem' height='1rem' icon='edit' fill={colorBlueBasic} />,
                actionHandler: () =>
                  openRightPanel({ form: 'SinglePeriodicActivitiesForm', id: itemId, disabled }),
              },
              {
                action: (
                  <SimpleDialog
                    action={() => removeItemHandler(itemId)}
                    type='remove'
                    title='Delete periodic activity'
                    copy={`${t('Are you sure you want to delete periodic activity')}${
                      name ? ` "${name}"` : ''
                    }?`}
                  >
                    <Icon width='1rem' height='1rem' icon='close' fill={colorRedBasic} />
                  </SimpleDialog>
                ),
              },
            ],
    };
  });

  const addHandler = () => {
    openRightPanel({ form: 'SinglePeriodicActivitiesForm', id: null, disabled });
  };

  return (
    <>
      {LoadingPeriodicActivities && <Spinner />}
      <ExpansionPanel
        hasBottomBorder={!disabled && !preview}
        title='Periodic activities'
        hasIcon
        iconName='timelapse'
      >
        <FormWrapper>
          <Flex alignItems='flex-start' justifyContent='flex-start'>
            <Label>{t('Periodic activities')}</Label>
            <List items={items} noItemsCopy={t('No periodic activities assigned')} />
          </Flex>
          {!disabled && !preview && (
            <CenterWithGaps>
              <Button disabled={disabled} icon='addCircle' variant='blue' onClick={addHandler}>
                {t('Add')}
              </Button>
            </CenterWithGaps>
          )}
        </FormWrapper>
      </ExpansionPanel>
    </>
  );
};

export default PeriodicActivitiesForm;
