import { useTranslation } from 'react-i18next';
import { Button, Column, List, Label, Icon } from 'components';
import { tCurrentMaintenanceOperation } from 'types/services/devices';
import { tError } from 'types/global';
import { colorBlueBasic, colorRedBasic } from 'styles/GlobalStyles';
import { AddButtonWrapper, ListWrapper } from '../AddEditFormDevices.style';

type tMaintenanceOperations = {
  disabled?: boolean;
  preview?: boolean;
  openForm: (id: number | null) => void;
  data: tCurrentMaintenanceOperation[];
  onChange: (data: any) => void;
  errors: tError[];
};

const MaintenanceOperations: React.FC<tMaintenanceOperations> = ({
  disabled = false,
  preview = false,
  openForm = () => {},
  data: maintenanceOperations,
  onChange,
  errors = [],
}): JSX.Element => {
  const { t } = useTranslation();

  const deleteItem = (id: number) => {
    onChange({
      fieldName: 'maintenanceOperations',
      label: maintenanceOperations.filter((item) => item.id !== id),
    });
  };

  const errorNum = errors.map((err) => (err?.loc ? err?.loc[0] : null));

  const items =
    maintenanceOperations?.map((maintenanceOperation, index) => {
      return {
        ...maintenanceOperation,
        error: errorNum.includes(index),
        actions:
          disabled || preview
            ? [
                {
                  action: (
                    <Icon width='1rem' height='1rem' icon='visibility' fill={colorBlueBasic} />
                  ),
                  actionHandler: () => openForm(maintenanceOperation.id),
                },
              ]
            : [
                {
                  action: <Icon width='1rem' height='1rem' icon='edit' fill={colorBlueBasic} />,
                  actionHandler: () => openForm(maintenanceOperation.id),
                },
                {
                  action: <Icon width='1rem' height='1rem' icon='close' fill={colorRedBasic} />,
                  actionHandler: () => deleteItem(maintenanceOperation.id),
                },
              ],
      };
    }) ?? [];

  return (
    <>
      <Column>
        <ListWrapper>
          <Label
            styles={{
              alignItems: 'flex-start',
              marginTop: maintenanceOperations.length > 0 ? '5px' : 0,
              maxWidth: '90px',
            }}
          >
            {t('Maintenance operations')}
          </Label>
          <List items={items} noItemsCopy={t('No assigned maintenance operations')} />
        </ListWrapper>
      </Column>
      {!disabled && !preview && (
        <AddButtonWrapper>
          <Button
            icon='addCircle'
            onClick={() => openForm(null)}
            variant='blue'
            disabled={disabled}
          >
            {t('Add')}
          </Button>
        </AddButtonWrapper>
      )}
    </>
  );
};

export default MaintenanceOperations;
