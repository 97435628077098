import styled, { css } from 'styled-components';

type tMenuItemButton = {
  active: boolean;
};

const menuItemStyles = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.58px;
  color: var(--theme-mainGrey);
  padding: 1rem 0 1rem 1.5rem;
  font-weight: 600;
  color: var(--theme-mainGrey);
  cursor: pointer;
  text-align: left;
  svg {
    width: 24px;
    height: 24px;
  }
  &:hover {
    background: var(--theme-hoverGrey);
    position: relative;
    span {
      color: var(--theme-black);
    }
    svg {
      fill: var(--theme-black);
    }
  }
`;

export const Ul = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  min-width: 220px;
`;
export const Li = styled.li`
  a {
    ${menuItemStyles}
  }
`;

export const NavCopy = styled.span`
  margin-left: 1rem;
`;

export const MenuItemButton = styled.button<tMenuItemButton>`
  ${menuItemStyles};
  border: none;
  outline: none;
  background: none;

  ${({ active }) =>
    active &&
    css`
      background: var(--theme-hoverGrey);
      position: relative;
      span {
        color: var(--theme-black);
        text-align: left;
      }
      svg {
        fill: var(--theme-black);
      }
    `}
`;
