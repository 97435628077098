import { Fragment } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { crumbsFromRouter } from 'utils/routerCrumbs';
import { truncate } from 'utils/helpers';
import Icon from 'components/Icon';

import {
  BreadcrumbsContainer,
  CrumbContainer,
  InactiveCrumbContainer,
  NavLinkStyle,
} from './Breadcrumbs.style';

export const pathToUrl = (path: string, url: string) =>
  `/${url
    .split('/')
    .splice(1, path.split('/').length - 1)
    .join('/')}`;

export type tCrumb = {
  url: string;
  label: string;
};

export type tBreadcrumbs = {
  crumbs?: tCrumb[];
  aliases?: string[];
};

const Breadcrumbs: React.FC<tBreadcrumbs> = ({ crumbs, aliases }): JSX.Element => {
  const { path, url } = useRouteMatch();
  const { t } = useTranslation();

  let currentCrumbs: tCrumb[] = [];
  let aliasesCount = 0;
  if (Array.isArray(crumbs)) {
    currentCrumbs = crumbs;
  } else {
    currentCrumbs = crumbsFromRouter(path).map((crumb) => {
      let newLabel = crumb.label;
      const firstCharOfLastSubpath = crumb.path.split('/').slice(-1)[0].slice(0, 1);

      if (
        aliases &&
        aliases[aliasesCount] &&
        aliases[aliasesCount].trim().length &&
        firstCharOfLastSubpath === ':'
      ) {
        newLabel = truncate(aliases[aliasesCount], 42);
        aliasesCount += 1;
      }
      return {
        url: pathToUrl(crumb.path, url),
        label: newLabel,
      };
    });
  }

  const mainUrl = currentCrumbs?.length ? currentCrumbs[0].url : '/';

  return (
    <BreadcrumbsContainer>
      <NavLinkStyle to={mainUrl}>
        <Icon icon='home' />
      </NavLinkStyle>
      {currentCrumbs.map((crumb, index) => {
        return index === currentCrumbs.length - 1 ? (
          <Fragment key={crumb.url}>
            <InactiveCrumbContainer>
              <Icon icon='chevronRight' />
            </InactiveCrumbContainer>
            <InactiveCrumbContainer key={crumb.url}>{t(crumb.label)}</InactiveCrumbContainer>
          </Fragment>
        ) : (
          <Fragment key={crumb.url}>
            <CrumbContainer>
              <Icon icon='chevronRight' />
            </CrumbContainer>
            <NavLinkStyle to={crumb.url}>{t(crumb.label)}</NavLinkStyle>
          </Fragment>
        );
      })}
    </BreadcrumbsContainer>
  );
};

export default Breadcrumbs;
