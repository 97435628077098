import { tAccountingModeBodyRequest, tContractTerminationsPost } from 'types/services/contracts';
import { tGetAll } from 'types/global';
import {
  tSubcontractorContractCreateUpdate,
  tSubcontractorPostAPI,
  tRoundAreaData,
  tPolygonAreaData,
  tSubcontractorPatchAPI,
} from 'types/services/subcontractors';
import { fetchProvider, fetchProviderForAttachment } from 'utils/Axios';

const microservicePath = 'contracts/subcontractors';

const subcontractors = {
  getAll: (params: tGetAll) => fetchProvider.get(microservicePath, { params }),
  get: (subcontractorId: string | number) =>
    fetchProvider.get(`${microservicePath}/${subcontractorId}`),
  create: (data: tSubcontractorPostAPI) => fetchProvider.post(microservicePath, data),
  edit: (subcontractorId: string, data: tSubcontractorPostAPI) =>
    fetchProvider.put(`${microservicePath}/${subcontractorId}`, data),
  patch: (subcontractorId: string, data: tSubcontractorPatchAPI) =>
    fetchProvider.patch(`${microservicePath}/${subcontractorId}`, data),
  getCompanySizes: () => fetchProvider.get(`${microservicePath}/company_sizes`),

  // contracts
  getAllContracts: (params: tGetAll) =>
    fetchProvider.get(`${microservicePath}/contracts`, { params }),
  getContract: (contractId: string | number) =>
    fetchProvider.get(`${microservicePath}/contracts/${contractId}`),
  createContract: (data: tSubcontractorContractCreateUpdate) =>
    fetchProvider.post(`${microservicePath}/contracts/`, data),
  editContract: (contractId: string | number, data: tSubcontractorContractCreateUpdate) =>
    fetchProvider.put(`${microservicePath}/contracts/${contractId}`, data),
  patchContract: (contractId: string | number, data: { status: string }) =>
    fetchProvider.patch(`${microservicePath}/contracts/${contractId}`, data),
  getAccountingModes: (contractId: string | number, params: { priceList: boolean } | {} = {}) =>
    fetchProvider.get(`${microservicePath}/contracts/${contractId}/accounting_modes`, { params }),
  getContractTermination: (contractId: number) =>
    fetchProvider.get(`${microservicePath}/contracts/${contractId}/terminations`),
  addAccountingMode: (
    bodyRequest: Omit<tAccountingModeBodyRequest, 'contract_id'>,
    contractId: string | number,
  ) =>
    fetchProvider.post(`${microservicePath}/contracts/${contractId}/accounting_modes`, bodyRequest),
  createContractTermination: (bodyRequest: tContractTerminationsPost, contractId: number) =>
    fetchProvider.post(`${microservicePath}/contracts/${contractId}/terminations`, bodyRequest),
  postContractAttachment: (contractId: number, data) =>
    fetchProviderForAttachment.post(
      `${microservicePath}/contracts/${contractId}/contract_attachment`,
      data,
    ),
  postAttachment: (contractId: number, data) =>
    fetchProviderForAttachment.post(
      `${microservicePath}/contracts/${contractId}/attachments`,
      data,
    ),
  getAreas: (subcontractorId: number | string) =>
    fetchProvider.get(`${microservicePath}/${subcontractorId}/areas`),
  createArea: (subcontractorId: string, areaData: tRoundAreaData | tPolygonAreaData[]) =>
    fetchProvider.put(`${microservicePath}/${subcontractorId}/areas`, areaData),
  getFilters: () => fetchProvider.get(`${microservicePath}/contracts/filters`),
};

export default subcontractors;
