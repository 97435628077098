import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Input, Label } from 'components';
import { colorGreyBasic, colorGreenBasic } from 'styles/GlobalStyles';
import { tError } from 'types/global';
import { BottomMargin1Rem } from 'styles/GlobalStyledComponents';
import {
  ButtonWrapper,
  ItemRow,
  ItemTitle,
  List,
  Relative,
  PreviewWrapper,
  PreviewListWrapper,
  ItemPreviewTitle,
  NoItem,
} from './InputWithList.style';

type tReadyItems = {
  fieldName: string;
  label: string[];
};

type tInputWithList = {
  errors?: tError[];
  label: string;
  labelMinWidth?: number;
  handleChange: (object: tReadyItems) => void;
  disabled?: boolean;
  preview?: boolean;
  items?: string[];
  name: string;
  labelOptional?: boolean;
};

const InputWithList: React.FC<tInputWithList> = ({
  errors = [],
  label,
  labelMinWidth = 90,
  handleChange,
  disabled = false,
  preview = false,
  items = [],
  name = '',
  labelOptional,
}): JSX.Element => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string>('');

  const removeItemFromList = (removedItem: string) => {
    handleChange({ fieldName: name, label: items.filter((item) => item !== removedItem) });
  };

  const saveItem = () => {
    if (value) {
      handleChange({ fieldName: name, label: [...items, value] });
      setValue('');
    }
  };

  return preview ? (
    <PreviewWrapper>
      {label && (
        <BottomMargin1Rem>
          <Label labelMinWidth={labelMinWidth}>{t(label)}</Label>
        </BottomMargin1Rem>
      )}
      {!!items?.length ? (
        <PreviewListWrapper>
          {items.map((item: string, index: number) => (
            <ItemRow key={index}>
              <ItemPreviewTitle>{item}</ItemPreviewTitle>
            </ItemRow>
          ))}
        </PreviewListWrapper>
      ) : (
        <NoItem>{t('None')}</NoItem>
      )}
    </PreviewWrapper>
  ) : (
    <>
      <Relative>
        <Input
          name={name}
          label={label}
          onChange={(e) => setValue(e.target.value)}
          errors={errors}
          value={value}
          disabled={disabled}
          labelOptional={labelOptional}
        />
        <ButtonWrapper>
          <Icon
            asButton
            icon='checkCircle'
            disable={disabled}
            fill={disabled ? colorGreyBasic : colorGreenBasic}
            onClick={saveItem}
          />
        </ButtonWrapper>
      </Relative>
      {!!items?.length && (
        <List>
          {items.map((item: string, index: number) => (
            <ItemRow key={index}>
              <ItemTitle>{item}</ItemTitle>
              {!disabled && !preview && (
                <Icon asButton icon='close' fill='red' onClick={() => removeItemFromList(item)} />
              )}
            </ItemRow>
          ))}
        </List>
      )}
    </>
  );
};

export default InputWithList;
