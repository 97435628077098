import { OverlayView, OverlayViewProps } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import { TooltipContainer } from './OverlayContainer.style';

type tOverlayContainer = {
  mapPath: any;
  overStartPoint: boolean;
  mapEdit: boolean;
  position: any;
};

class MyOverlayView extends OverlayView {
  props!: {
    children?: React.ReactNode;
  } & OverlayViewProps;
}

export const OverlayContainer = ({
  mapPath,
  overStartPoint,
  mapEdit,
  position,
}: tOverlayContainer) => {
  const { t } = useTranslation();
  return overStartPoint && mapPath?.length > 2 && mapEdit ? (
    <MyOverlayView position={position} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
      <TooltipContainer>{t('Close the area')}</TooltipContainer>
    </MyOverlayView>
  ) : null;
};
