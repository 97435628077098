import { tTableHead } from 'types/components/table';

export const tableHead: tTableHead[] = [
  {
    sortable: false,
    name: 'No.',
    key: 'order',
  },
  {
    sortable: false,
    name: 'MRU name',
    key: 'MRUName',
  },

  {
    sortable: false,
    name: 'SAP ID',
    key: 'sapNumber',
  },
  {
    sortable: false,
    name: 'Address',
    key: 'MRUFullAddress',
  },
];
