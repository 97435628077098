import { useTranslation } from 'react-i18next';
import { Button } from 'components';
import { tSinglePreviewItem } from 'types/views/preview';
import { tCommentsRequestBody } from 'types/services/issues';
import issuesService from 'services/issues';

export type tSendCommentsAction = {
  history: { push: Function };
  actions: { [key: string]: string };
  setNotification: Function;
  previewData: tSinglePreviewItem[];
};

const SendCommentsAction: React.FC<tSendCommentsAction> = ({
  history,
  actions,
  setNotification,
  previewData = [],
}) => {
  const { t } = useTranslation();
  const { comment_and_send_to_regional_manager: commentAndSendToRegionalManager } = actions;

  const commentIssueHandler = async () => {
    const bodyRequst: tCommentsRequestBody[] = previewData?.length
      ? previewData.map((item) => {
          const { apiKey, comment } = item;
          return {
            field: apiKey,
            message: comment,
          };
        })
      : [];

    try {
      const result = await issuesService.commentAndSendToRegionalManager(
        commentAndSendToRegionalManager,
        bodyRequst,
      );
      if (result?.status === 200 || result?.status === 201) {
        setNotification(t('Issue was send to improve'), 'success');
        history.push('/issues');
      }
    } catch (err) {
      if (err?.status) {
        switch (err?.status) {
          case 409:
          case 400:
            return err.data.forEach((error) => setNotification(error.msg));
          default:
            return setNotification(t('Something went wrong. Try again'));
        }
      }
      setNotification(t('Something went wrong. Try again'));
    }
  };

  return (
    <Button
      variant='blue'
      onClick={commentIssueHandler}
      disabled={!!!commentAndSendToRegionalManager}
    >
      {t('To improve')}
    </Button>
  );
};

export default SendCommentsAction;
