import styled from 'styled-components';

type tExecutionDateInput = {
  isModeChosen: boolean;
};

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 0.875rem;
  display: flex;
  justify-content: flex-end;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 2rem 0;
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const ListElement = styled.li`
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  letter-spacing: 0.58px;
  color: var(--theme-mainDarkGrey);
`;

export const IssueLocationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  margin-bottom: 1rem;
`;

export const Label = styled.div`
  font-size: 0.75rem;
  line-height: 0.9375rem;
  letter-spacing: 0.58px;
  margin-left: 0.5rem;
`;

export const ExecutionDateInput = styled.input<tExecutionDateInput>`
  border: none;
  color: var(--theme-mainDarkGrey);
  font-size: 0.75rem;
  opacity: ${({ isModeChosen }) => (isModeChosen ? 1 : 0.4)};
  letter-spacing: 0.58px;
  background-color: transparent;
  width: inherit;
`;

export const ExecutionDateWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  height: auto;
`;

export const MapIconWrapper = styled.div`
  margin-bottom: 1rem;
  position: absolute;
  top: 50%;
  right: -2rem;
  transform: translateY(-45%);
`;

export const ModalText = styled.p`
  font-size: 0.75rem;
  margin-bottom: 1rem;
`;

export const CommentContainer = styled.div`
  margin: -0.5rem 0 1rem 6.75rem;
  text-align: left;
  color: var(--theme-mainRed);
  font-size: 0.75rem;
`;

export const LineGap = styled.div`
  margin: 0.5rem -0.5rem 1.5rem -0.5rem;
  border-bottom: 1px solid var(--theme-mainGrey);
`;

export const Text = styled.p`
  font-size: 0.75rem;
`;

export const PreviewWrapper = styled.div`
  margin-top: -1rem;
`;

export const ChangeDateModalTitle = styled.p`
  text-align: left;
`;
