import styled, { css } from 'styled-components';
import { ClearButton } from 'styles/GlobalStyledComponents';

type tItemStyle = {
  selected: boolean;
};

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 0px 0px 0.5rem #0000000a;
  border-radius: 0.5rem;
  width: fit-content;
`;

export const ItemStyle = styled(ClearButton)<tItemStyle>`
  display: flex;
  align-items: center;
  color: var(--theme-mainGrey);
  font-size: 0.75rem;
  line-height: 0.9375rem;
  letter-spacing: 0.29px;
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  text-transform: uppercase;
  svg {
    margin-right: 0.5rem;
    fill: var(--theme-mainGrey);
  }
  ${({ selected }) =>
    selected &&
    css`
      color: #fff;
      background: var(--theme-mainBlue);
      svg {
        fill: #fff;
      }
    `}
`;
