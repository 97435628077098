import { ReactComponent as accountTree } from './account_tree-24px.svg';
import { ReactComponent as addBox } from './add_box-24px.svg';
import { ReactComponent as addCircle } from './add_circle-24px.svg';
import { ReactComponent as addComment } from './add_comment-24px.svg';

import { ReactComponent as addPhoto } from './add_photo_alternate-24px.svg';
import { ReactComponent as add } from './add-24px.svg';
import { ReactComponent as arrowBack } from './arrow_back-24px.svg';
import { ReactComponent as arrowForward } from './arrow_forward-24px.svg';
import { ReactComponent as arrowSortUp } from './arrow_drop_up-24px.svg';
import { ReactComponent as arrowSortDown } from './arrow_drop_down-24px.svg';
import { ReactComponent as article } from './article-24px.svg';
import { ReactComponent as assignmentInd } from './assignment_ind-24px.svg';
import { ReactComponent as assignmentLate } from './assignment_late-24px.svg';
import { ReactComponent as barChart } from './bar_chart-24px.svg';
import { ReactComponent as businessCenter } from './business_center-24px.svg';

import { ReactComponent as checkBoxOutline } from './check_box_outline_blank-24px.svg';
import { ReactComponent as checkBox } from './check_box-24px.svg';
import { ReactComponent as checkCircleOutline } from './check_circle_outline-24px.svg';
import { ReactComponent as checkCircle } from './check_circle-24px.svg';
import { ReactComponent as chevronLeft } from './chevron_left-24px.svg';

import { ReactComponent as chevronRight } from './chevron_right-24px.svg';
import { ReactComponent as circleSlice } from './circle_slice-24px.svg';
import { ReactComponent as close } from './close-24px.svg';
import { ReactComponent as construction } from './construction-24px.svg';
import { ReactComponent as corporateFare } from './corporate_fare-24px.svg';
import { ReactComponent as create } from './create-24px.svg';
import { ReactComponent as dateRange } from './date_range-24px.svg';

import { ReactComponent as deleteForever } from './delete_forever-24px.svg';
import { ReactComponent as deleteBin } from './delete-24px.svg';
import { ReactComponent as description } from './description-24px.svg';
import { ReactComponent as edit } from './edit-24px.svg';
import { ReactComponent as error } from './error_outline-24px.svg';
import { ReactComponent as engineering } from './engineering-24px.svg';

import { ReactComponent as fileCopy } from './file_copy-24px.svg';
import { ReactComponent as fileImport } from './file_import-24px.svg';
import { ReactComponent as firstPage } from './first_page-24px.svg';
import { ReactComponent as fontDownload } from './font_download-24px.svg';
import { ReactComponent as gavel } from './gavel-24px.svg';
import { ReactComponent as group } from './group-24px.svg';
import { ReactComponent as groups } from './groups-24px.svg';

import { ReactComponent as help } from './help_outline-24px.svg';
import { ReactComponent as history } from './history-24px.svg';
import { ReactComponent as archive } from './archive-24px.svg';
import { ReactComponent as home } from './home-24px.svg';
import { ReactComponent as hourglass } from './hourglass_empty-24px.svg';
import { ReactComponent as howToReg } from './how_to_reg-24px.svg';

import { ReactComponent as info } from './info-24px.svg';
import { ReactComponent as arrowDown } from './keyboard_arrow_down-24px.svg';
import { ReactComponent as arrowUp } from './keyboard_arrow_up-24px.svg';
import { ReactComponent as lastPage } from './last_page-24px.svg';
import { ReactComponent as location } from './location_on-24px.svg';
import { ReactComponent as login } from './login-24px.svg';

import { ReactComponent as lock } from './lock-24px.svg';
import { ReactComponent as monetization } from './monetization_on-24px.svg';
import { ReactComponent as moreHoriz } from './more_horiz-24px.svg';
import { ReactComponent as moreVert } from './more_vert-24px.svg';
import { ReactComponent as noEncryption } from './no_encryption-24px.svg';

import { ReactComponent as noteAdd } from './note_add-24px.svg';
import { ReactComponent as payments } from './payments-24px.svg';
import { ReactComponent as personAdd } from './person_add-24px.svg';
import { ReactComponent as person } from './person-24px.svg';
import { ReactComponent as place } from './place-24px.svg';

import { ReactComponent as power } from './power_settings_new-24px.svg';
import { ReactComponent as reportProblem } from './report_problem-24px.svg';
import { ReactComponent as schedule } from './schedule-24px.svg';
import { ReactComponent as search } from './search-24px.svg';
import { ReactComponent as settings } from './settings-24px.svg';

import { ReactComponent as thumbDown } from './thumb_down-24px.svg';
import { ReactComponent as thumbUp } from './thumb_up-24px.svg';
import { ReactComponent as toggleOff } from './toggle_off-24px.svg';
import { ReactComponent as toggleOn } from './toggle_on-24px.svg';

import { ReactComponent as wallet } from './account_balance_wallet-24px.svg';
import { ReactComponent as visibility } from './visibility-24px.svg';
import { ReactComponent as visibilityOff } from './visibility_off-24px.svg';
import { ReactComponent as zoomIn } from './zoom_in-24px.svg';
import { ReactComponent as zoomOut } from './zoom_out-24px.svg';

import { ReactComponent as doc } from './doc_icon.svg';
import { ReactComponent as jpg } from './jpg_icon.svg';
import { ReactComponent as pdf } from './pdf_icon.svg';
import { ReactComponent as png } from './png_icon.svg';
import { ReactComponent as xls } from './xls_icon.svg';

import { ReactComponent as menu } from './menu-24px.svg';
import { ReactComponent as announcement } from './announcement-24px.svg';

import { ReactComponent as commentAdded } from './comment_added.svg';
import { ReactComponent as comment } from './maps_ugc-24px.svg';
import { ReactComponent as apartment } from './apartment-24px.svg';
import { ReactComponent as assignmentTurned } from './assignment_turned_in-24px.svg';
import { ReactComponent as miscellaneousServices } from './miscellaneous_services-24px.svg';
import { ReactComponent as developerBoard } from './developer_board-24px.svg';
import { ReactComponent as memory } from './memory-24px.svg';
import { ReactComponent as settingsApplications } from './settings_applications-24px.svg';
import { ReactComponent as timelapse } from './timelapse-24px.svg';
import { ReactComponent as indeterminateCheckBox } from './indeterminate_check_box-24px.svg';
import { ReactComponent as arrowRightAlt } from './arrow_right_alt-24px.svg';
import { ReactComponent as filterAlt } from './filter_alt-24px.svg';
import { ReactComponent as download } from './get_app-24px.svg';
import { ReactComponent as column } from './view_column-24px.svg';
import { ReactComponent as issue } from './issue-24px.svg';
import { ReactComponent as warning } from './warning-24px.svg';
import { ReactComponent as list } from './list-24px.svg';
import { ReactComponent as calendar } from './calendar_today-24px.svg';
import { ReactComponent as map } from './map-24px.svg';
import { ReactComponent as errorFilled } from './error-24px.svg';
import { ReactComponent as repair } from './repair-24px.svg';
import { ReactComponent as calendarToday } from './rounded_calendar_today-24px.svg';
import { ReactComponent as widgets } from './widgets-24px.svg';
import { ReactComponent as people } from './people_black_24dp.svg';
import { ReactComponent as trackChanges } from './track_changes_24px.svg';
import { ReactComponent as accountBox } from './account_box_24px.svg';
import { ReactComponent as eventNote } from './event_note_black_24dp.svg';
import { ReactComponent as playlistAdd } from './playlist_add_black_24dp.svg';
import { ReactComponent as analytics } from './analytics_black_24dp.svg';
import { ReactComponent as chat } from './chat_black_24dp.svg';
import { ReactComponent as check } from './check_black_24dp.svg';
import { ReactComponent as fileExport } from './file_export.svg';

export default {
  commentAdded,
  comment,
  wallet,
  accountTree,
  addBox,
  addCircle,
  addComment,
  addPhoto,
  add,
  arrowBack,
  arrowForward,
  arrowSortUp,
  arrowSortDown,
  article,
  assignmentInd,
  assignmentLate,
  barChart,
  businessCenter,
  checkBoxOutline,
  checkBox,
  checkCircleOutline,
  checkCircle,
  chevronLeft,
  chevronRight,
  circleSlice,
  close,
  construction,
  corporateFare,
  create,
  dateRange,
  deleteForever,
  deleteBin,
  description,
  edit,
  error,
  engineering,
  fileCopy,
  fileImport,
  firstPage,
  fontDownload,
  gavel,
  group,
  groups,
  help,
  history,
  archive,
  home,
  hourglass,
  howToReg,
  info,
  arrowDown,
  arrowUp,
  lastPage,
  location,
  login,
  lock,
  monetization,
  moreHoriz,
  moreVert,
  noEncryption,
  noteAdd,
  payments,
  personAdd,
  person,
  place,
  power,
  reportProblem,
  schedule,
  search,
  settings,
  thumbDown,
  thumbUp,
  toggleOff,
  toggleOn,
  visibility,
  visibilityOff,
  zoomIn,
  zoomOut,
  doc,
  jpg,
  pdf,
  png,
  xls,
  menu,
  announcement,
  apartment,
  timelapse,
  assignmentTurned,
  miscellaneousServices,
  developerBoard,
  memory,
  settingsApplications,
  indeterminateCheckBox,
  arrowRightAlt,
  filterAlt,
  download,
  column,
  issue,
  warning,
  list,
  calendar,
  map,
  repair,
  errorFilled,
  calendarToday,
  widgets,
  people,
  trackChanges,
  accountBox,
  eventNote,
  playlistAdd,
  analytics,
  chat,
  check,
  fileExport,
};
