/* eslint-disable camelcase */
import {
  tGetAllIssuesParams,
  tRequestBody,
  tCreateIssueData,
  tCommentsRequestBody,
  tNewComplaintData,
  tGetAllByGet,
} from 'types/services/issues';
import { fetchProvider, fetchProviderForAttachment } from 'utils/Axios';

const microservicePath = 'contracts/issues';

const issues = {
  getAll: (bodyRequest: tRequestBody, params: tGetAllIssuesParams) =>
    fetchProvider.post(`${microservicePath}/list`, { ...bodyRequest }, { params: { ...params } }),
  getFilters: () => fetchProvider.get(`${microservicePath}/filters`),
  get: (issueId: string | number) => fetchProvider.get(`${microservicePath}/${issueId}`),
  getComments: (issueId: string | number, resolved: boolean) =>
    fetchProvider.get(`${microservicePath}/${issueId}/comments`, { params: { resolved } }),
  getContractAddressUsers: (contractAddressId: string | number, roleId: string | number) =>
    fetchProvider.get(
      `contracts/users/?contract_address_id=${contractAddressId}&role_id=${roleId}`,
    ),
  getContractAddressesActive: (userId: string | number) =>
    fetchProvider.get(`contracts/contract_addresses?user_id=${userId}&active=true`),
  getContractAddressesActiveByRole: (userId: string | number, roleId: string | number) =>
    fetchProvider.get(
      `contracts/contract_addresses?user_id=${userId}&role_id=${roleId}&active=true`,
    ),
  getAccountingModesByIndustry: (contractId: string | number, industryId: string | number) =>
    fetchProvider.get(
      `contracts/contracts/${contractId}/accounting_modes?industry_id=${industryId}`,
    ),
  create: (issueData: tCreateIssueData) => fetchProvider.post(`${microservicePath}/`, issueData),
  edit: (editedIssueId: string, issueData: tCreateIssueData) =>
    fetchProvider.put(`${microservicePath}/${editedIssueId}`, issueData),
  postAttachment: (issueId: string, data: tCreateIssueData) =>
    fetchProviderForAttachment.post(`${microservicePath}/${issueId}/attachments`, data),
  rejectAcceptIssue: (url: string) => fetchProvider.post(`contracts/${url}`),
  rejectIssueReasons: () => fetchProvider.get(`${microservicePath}/rejection_reasons`),
  rejectIssue: (
    issueId: number | string,
    params: { reason_id?: string | number; comment?: string },
  ) => fetchProvider.post(`${microservicePath}/${issueId}/reject`, params),
  assignIssueToTechnician: (
    issueId: number | string,
    params: { technician_id?: string | number },
  ) => fetchProvider.post(`${microservicePath}/${issueId}/assignments`, params),
  assignIssueToSubcontractor: (
    issueId: number | string,
    params: { subcontractor_id?: string | number },
  ) => fetchProvider.post(`${microservicePath}/${issueId}/subcontractor_assignments`, params),
  assignIssue: (
    url: string,
    params: { subcontractor_id?: string | number; technician_id?: string | number },
  ) => fetchProvider.post(`contracts/${url}`, params),
  commentAndSendToRegionalManager: (url: string, bodyRequest: tCommentsRequestBody[]) =>
    fetchProvider.post(`contracts/${url}`, bodyRequest),
  getAllVisits: (params: tGetAllByGet & { address?: (string | number)[] }) =>
    fetchProvider.get(`/contracts/visits`, { params }),
  getVisit: (id: string | number) => fetchProvider.get(`/contracts/visits/${id}`),
  acceptVisit: (visitId: string | number, bodyRequest: { comment: string }) =>
    fetchProvider.post(`/contracts/visits/${visitId}/accept`, bodyRequest),
  rejectVisit: (visitId: string | number, bodyRequest: { comment: string }) =>
    fetchProvider.post(`/contracts/visits/${visitId}/reject`, bodyRequest),
  editAndSendToTechManager: (editedIssueId: string, issueData: tCreateIssueData) =>
    fetchProvider.post(`${microservicePath}/${editedIssueId}/resend`, issueData),
  getIssueFullInfo: (issuesId: string) =>
    fetchProvider.get(`${microservicePath}/${issuesId}/full_info`),
  createComplaint: (complaintData: tNewComplaintData) =>
    fetchProvider.post(`${microservicePath}/`, complaintData),
  confirmReceipt: (issueId: string | number) =>
    fetchProvider.post(`${microservicePath}/${issueId}/confirm_service`),
  rejectNotifiedIssue: (url: string, comment: string) =>
    fetchProvider.post(`contracts/${url}`, { comment }),
  rejectSubcontractorIssue: (rejectAssignmentUri: string, comment: string) =>
    fetchProvider.post(`contracts/${rejectAssignmentUri}`, {
      rejection_reason: comment,
    }),
  getIssueHistory: (issueId: string) => fetchProvider.get(`${microservicePath}/${issueId}/history`),
  getVisitsFilters: () => fetchProvider.get(`/contracts/visits/filters`),
};

export default issues;
