/* eslint-disable camelcase */
import { AxiosResponse } from 'axios';
import maintenances from 'agents/maintenances';
import { tGet } from 'types/global';
import { tGetMaintenanceData, tMaintenancesPostBodyRequest } from 'types/services/maintenances';
import { tMaintenancesListRow } from 'types/views/maintenances';
import { tGetAllByGet, tGetAllByPost } from 'types/services/issues';

const transformSortByField = (field: string) => {
  switch (field) {
    case 'clients':
      return 'client_name';
    case 'contract':
      return 'contract_name';
    case 'frequency':
      return 'interval';
    case 'maintenanceLocationsAmount':
      return 'maintenance_locations_amount';
    case 'name':
    default:
      return field;
  }
};

const getFilters = async () => {
  const filters = await maintenances.getFilters();
  const { data, status } = filters;
  return {
    data: {
      count: 1,
      results: {
        clients: data.clients,
      },
    },
    status,
  };
};

const getAll = async ({
  offset = 0,
  limit,
  ordering = { by: '', order: '' },
  search,
  filters,
}: tGetAllByPost) => {
  const params: tGetAllByGet & { client_ids?: string } = { offset };

  const clientIds = filters?.client
    ? filters.client.map((item: { value: string | number }) => item.value).join(',')
    : null;

  if (limit) params.limit = limit;
  if (offset) params.offset = offset;
  if (!!ordering?.by) params.sort = transformSortByField(ordering.by);
  if (!!ordering?.order) params.order = ordering.order;
  if (search) params.search = search;
  if (clientIds) params.client_ids = clientIds;

  const response = await maintenances.getAll(params);
  const { data } = response;
  const { results: resultsData, count } = data;

  const results = resultsData.map((maintenance, index) => {
    const {
      id,
      name,
      client,
      interval_value: intervalValue,
      interval_unit: intervalUnit,
      contract,
      maintenance_locations_amount: maintenanceLocationsAmount,
    } = maintenance;

    const singleMaintenance: tMaintenancesListRow = {
      id,
      order: index + 1 + offset,
      name,
      contract: contract?.name ?? null,
      clients: client?.name ?? null,
      intervalValue,
      intervalUnit,
      maintenanceLocationsAmount,
    };

    return singleMaintenance;
  });

  const apiFilters = await getFilters();
  const {
    data: { results: filtersToData },
  } = apiFilters;

  return {
    data: {
      count,
      results,
      filters: filtersToData,
    },
  };
};

const get = async ({ id: maintenancesId }: tGet) => {
  const response = await maintenances.get(maintenancesId);
  const { data, status } = response;
  const {
    appliance,
    group,
    system,
    industry,
    client,
    contract,
    interval_unit,
    interval_value,
    maintenance_locations,
    maintenance_operations,
    advance_period_value,
    advance_period_unit,
    name,
    id,
    starting_from,
    refers_to,
    accounting_mode,
  } = data;

  const result: tGetMaintenanceData = {
    id,
    maintenanceName: name,
    client: {
      label: client?.name,
      value: client?.id,
    },
    contract: {
      label: contract?.name,
      value: contract?.id,
    },
    refersTo: {
      label: refers_to,
      value: refers_to,
    },
    industry: {
      label: industry?.industry?.name,
      value: industry?.id,
    },
    system: !!system ? { label: system?.name, value: system?.id } : null,
    group: !!group ? { label: group?.name, value: group?.id } : null,
    deviceModel: !!appliance ? { label: appliance?.name, value: appliance?.id } : null,
    maintenanceOperations: maintenance_operations,
    maintenanceIntervals: {
      value: interval_value || null,
      optionLabel: interval_unit || null,
    },
    maintenanceCreationDate: {
      value: advance_period_value || null,
      optionLabel: advance_period_unit || null,
    },
    startingFrom: starting_from ? new Date(starting_from) : null,
    MRUPoint: maintenance_locations?.length
      ? maintenance_locations.flatMap((location) => [
          {
            label: location?.name,
            value: location?.name,
            id: location?.id,
          },
        ])
      : [],
    accountingMode: {
      value: accounting_mode?.id,
      label: accounting_mode?.name,
    },
  };

  return {
    data: result,
    status,
  };
};

const edit = async (subcontractorId: string, dataToEdit: tMaintenancesPostBodyRequest) => {
  const response: AxiosResponse = await maintenances.update(dataToEdit, subcontractorId);
  const { data, status } = response;
  return {
    data: { id: data?.id },
    status,
  };
};

const create = async (dataToCreate: tMaintenancesPostBodyRequest) => {
  const response = await maintenances.create(dataToCreate);
  const { data, status } = response;
  return {
    data: { id: data?.id },
    status,
  };
};

const deleteMaintenance = (id: number | string) => maintenances.delete(id);

const getMaintenancesIntervalUnits = async () => {
  const response = await maintenances.getMaintenancesIntervalUnits();
  const { data, status } = response;
  return {
    data: { results: data },
    status,
  };
};

const getInspectionAdvancePeriodUnits = async () => {
  const response = await maintenances.getInspectionAdvancePeriodUnits();
  const { data, status } = response;
  return {
    data: { results: data },
    status,
  };
};

const getMaintenancesInspectionType = async () => {
  const response = await maintenances.getMaintenancesInspectionType();
  const { data, status } = response;
  return {
    data: { results: data },
    status,
  };
};

export default {
  getAll,
  get,
  create,
  edit,
  deleteMaintenance,
  getMaintenancesIntervalUnits,
  getMaintenancesInspectionType,
  getInspectionAdvancePeriodUnits,
};
