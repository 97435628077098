import { iAPIResults, iAPIData, tGetAll, tIdName } from 'types/global';
import {
  tGetAllClientsAPI,
  tGetClientAPI,
  tGetRegionsAPI,
  tGetCountriesAPI,
  tCreateClientAPI,
  tEditClientAPI,
} from 'types/api/clients';
import { fetchProvider } from 'utils/Axios';

const microservicePath = 'contracts';

const clients = {
  getAll: (params: tGetAll): iAPIResults<tGetAllClientsAPI> =>
    fetchProvider.get(`${microservicePath}/clients`, { params }),
  get: (id: number | string): iAPIData<tGetClientAPI> =>
    fetchProvider.get(`${microservicePath}/clients/${id}`),
  getLegalForm: (): iAPIResults<tIdName> => fetchProvider.get(`${microservicePath}/legal_forms`),
  getSegments: (): iAPIResults<tIdName> => fetchProvider.get(`${microservicePath}/segments`),
  getRegions: (): iAPIResults<tGetRegionsAPI> => fetchProvider.get(`${microservicePath}/regions`),
  getCountries: (): iAPIResults<tGetCountriesAPI> =>
    fetchProvider.get(`${microservicePath}/countries`),
  create: (data: tCreateClientAPI): iAPIData<tGetClientAPI> =>
    fetchProvider.post(`/${microservicePath}/clients`, data),
  edit: (id: string, data: tEditClientAPI): iAPIData<tGetClientAPI> =>
    fetchProvider.put(`${microservicePath}/clients/${id}`, data),
};

export default clients;
