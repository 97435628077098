/* eslint-disable camelcase */
import { fetchProvider } from 'utils/Axios';
import { tElement, tSchedulesStructureApi } from 'types/services/locations';

const microservicePath = 'structures';

const structure = {
  getLocationTypes: () => fetchProvider.get(`${microservicePath}/location_types`),
  getStructure: (params: { contract_id: number | null; type_id: string | null }) =>
    fetchProvider.get(`${microservicePath}/locations`, { params }),
  getCurrentStructure: (structureUuid: string) =>
    fetchProvider.get(`${microservicePath}/locations/${structureUuid}`),
  patchCurrentStructure: (structureId: string, data: { name: string; client_mpk: string }) =>
    fetchProvider.patch(`${microservicePath}/locations/${structureId}`, data),
  removeElement: (id: string) => fetchProvider.delete(`${microservicePath}/locations/${id}`),
  addElement: (data: tElement) => fetchProvider.post(`${microservicePath}/locations`, data),
  getSchedulesStructure: (locationId: string) =>
    fetchProvider.get(`${microservicePath}/locations/${locationId}/schedules`),
  putSchedulesStructure: (locationId: string, data: tSchedulesStructureApi) =>
    fetchProvider.put(`${microservicePath}/locations/${locationId}/schedules`, data),
  getIssueLocationTree: (locationId: number | string) =>
    fetchProvider.get(`${microservicePath}/locations/${locationId}/full_tree`),
};

export default structure;
