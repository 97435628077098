import styled from 'styled-components';

export const AttributesContainer = styled.div`
  margin: 1rem 0;
  padding: 0px 4rem 0 1.5rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 2rem 0;
`;

export const RemoveAttributeButtonContainer = styled.div`
  position: absolute;
  right: -2rem;
  top: 2px;
`;

export const Paragraph = styled.p`
  padding: 0;
  margin: 0;
  margin-top: 0.5rem;
  color: var(--theme-mainDarkGrey);
  font-size: 0.75rem;
`;
