import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { idleTimeIncrement, tokenUpdate } from 'store/user/actions';
import { logout } from 'store/auth/actions';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components';
import tStore from 'types/store';
import { logoutIdleTime, logoutIdleTimeWarning } from 'utils/constants';
import { throttledResetIdleTimer } from 'utils/helpers';
import Timer from './Timer';

const SessionMonitor = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const idleTime = useSelector((state: tStore) => state.user.idleTime);
  const kc = useSelector((state: tStore) => state.auth.kc);

  const idleWarning = idleTime >= logoutIdleTime - logoutIdleTimeWarning;

  const extendSessionHandler = () => {
    if (kc && kc?.updateToken) {
      kc.updateToken(-1)
        .catch(() => {
          dispatch(logout());
        })
        .finally(() => {
          throttledResetIdleTimer();
        });
    }
  };

  useEffect(() => {
    const minuteTick = setInterval(() => {
      dispatch(idleTimeIncrement());
      dispatch(tokenUpdate(kc));
    }, 60000);
    return () => clearInterval(minuteTick);
  }, []);

  useEffect(() => {
    if (!idleWarning) {
      window.addEventListener('keydown', throttledResetIdleTimer);
      window.addEventListener('mousedown', throttledResetIdleTimer);
      return () => {
        window.removeEventListener('keydown', throttledResetIdleTimer);
        window.removeEventListener('mousedown', throttledResetIdleTimer);
      };
    }
  }, [idleWarning]);

  if (!idleWarning) return null;
  return (
    <Modal
      title={t('Session ending')}
      confirmButtonText={t('Extend session')}
      icon='schedule'
      confirmMethod={extendSessionHandler}
      showOnlyConfirm
      width='426px'
    >
      {t('Your session expires in')}:<Timer />
      {t('After your session expires, you will be logged out')}
    </Modal>
  );
};

export default SessionMonitor;
