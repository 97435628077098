import { Tooltip2 } from 'components';
import { FlexHWrapper } from 'styles/GlobalStyledComponents';
import { Round, Item } from './TooltipList.style';

export type tTooltipList = {
  items: string[];
  place?: string;
  listKey: string;
};

const TooltipList: React.FC<tTooltipList> = ({ listKey, items, place = 'bottom' }) => {
  const count = items?.length;

  return (
    <FlexHWrapper>
      {count ? items[0] : '-'}
      {count > 1 && (
        <Tooltip2
          place={place}
          key={listKey}
          tooltip={items.slice(1).map((item) => (
            <Item key={item}>{`· ${item}`}</Item>
          ))}
        >
          <Round color='blue'>{count - 1 > 9 ? '+9' : count - 1}</Round>
        </Tooltip2>
      )}
    </FlexHWrapper>
  );
};

export default TooltipList;
