import styled from 'styled-components';

export const NotificationCloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  height: 24px;
  outline: none;
  padding: 0;
`;

export const NotificationIconContainer = styled.div`
  display: flex;
  margin-right: 12px;
`;

export const NotificationMessageContainer = styled.div`
  display: flex;
  font-size: 0.875rem;
  font-weight: 500;
  margin-right: 24px;
`;

export const NotificationWrapper = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 8px #0000000a;
  display: flex;
  line-height: 24px;
  max-width: 300px;
  padding: 20px 12px 20px 16px;
  position: fixed;
  z-index: var(--theme-notificationZIndex);
`;
