import styled, { css } from 'styled-components';
import { ClearButton } from 'styles/GlobalStyledComponents';
import { tFolderStyles, tTreeType } from './TreeView';

export const ActionComponent = styled.div`
  padding-left: 2rem;
  position: relative;
  &:before {
    background-color: var(--theme-mainGrey);
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1.25rem;
  }
  &:after {
    background-color: var(--theme-mainGrey);
    bottom: 50%;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    width: 1px;
  }
`;

export const ActionDepthComponent = styled.div`
  padding-bottom: 0.5rem;
  padding-left: 2rem;
  position: relative;
  &:after {
    background-color: var(--theme-mainGrey);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: -50%;
    width: 1px;
  }
  &:before {
    background-color: var(--theme-mainGrey);
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1rem;
  }
`;

export const Collapsible = styled.div<tFolderStyles>`
  height: ${(p) => (!p.isOpen ? '0' : 'auto')};
  overflow: hidden;
`;

export const ExtraInfo = styled.span`
  font-weight: 400;
  padding-left: 0.5rem;
  text-transform: none;
`;

export const FileBlock = styled.div`
  display: flex;
`;

export const FolderIcon = styled(ClearButton)`
  display: flex;
  margin-left: -11px;
`;

export const FolderLabel = styled.div<tTreeType>`
  display: flex;
  position: relative;
  ${({ type }) =>
    type === 'location' &&
    css`
      &:after {
        background-color: var(--theme-mainGrey);
        content: '';
        height: 100%;
        left: -2rem;
        position: absolute;
        top: 0;
        transform: translateY(-50%);
        width: 1px;
      }
    `}
  &:before {
    background-color: var(--theme-mainGrey);
    content: '';
    height: 1px;
    left: -2rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1rem;
  }
`;

export const FolderTitle = styled.div`
  cursor: pointer;
  font-weight: 500;
  line-height: 1.5rem;
  padding-left: 0.5rem;
`;

export const StyledFile = styled.div<tTreeType>`
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.5rem;
  padding-left: 2rem;
  position: relative;
  &:before {
    background-color: var(--theme-mainGrey);
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1rem;
  }
  ${({ type }) =>
    type === 'location' &&
    css`
      &:after {
        background-color: var(--theme-mainGrey);
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transform: translateY(-50%);
        width: 1px;
      }
    `}
`;

export const StyledFolder = styled.div`
  position: relative;
  padding-left: 2rem;
`;

export const StyledTree = styled.div<tTreeType>`
  font-size: 0.75rem;
  position: relative;
  padding-top: 0.5rem;
  ${({ type }) =>
    type === 'tree' &&
    css`
      &:before {
        background-color: var(--theme-mainGrey);
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
        width: 1px;
      }
    `}
`;

export const TreeItemActionButton = styled.button`
  outline: none;
  margin-left: 0.5rem;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`;

export const TreeViewHeader = styled.div`
  font-size: 0.75rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  margin-left: -0.5rem;
  text-transform: uppercase;
  display: flex;
  line-height: 1.5rem;
`;

export const TreeViewTitle = styled.div`
  padding: 0.25rem 0.5rem;
`;
