import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components';
import { tConfirmVariants } from 'components/Modal/Modal';
import { ButtonStyle, DialogContentWrapper } from './SimpleDialog.style';

export type tDialogTypes = 'message' | 'accept' | 'remove' | 'removeRequest';

export type tSimpleDialog = {
  type: tDialogTypes;
  action: () => void;
  title?: string;
  copy?: string;
  children: JSX.Element | string;
};

const typeConfig = (type: tDialogTypes) => {
  switch (type) {
    case 'remove':
      return {
        confirmButtonText: 'Delete',
        confirmVariant: 'redFilled' as tConfirmVariants,
        icon: 'deleteBin',
        defaultTitle: 'Delete',
        defaultCopy: 'Are you sure you want to delete',
      };
    case 'removeRequest':
      return {
        confirmButtonText: 'Send',
        confirmVariant: 'green' as tConfirmVariants,
        icon: 'deleteBin',
        defaultTitle: 'Delete',
        defaultCopy: 'Are you sure you want to send a deletion request',
      };
    case 'accept':
      return {
        confirmButtonText: 'Accept',
        confirmVariant: 'green' as tConfirmVariants,
        icon: 'announcement',
        defaultTitle: 'Accept',
        defaultCopy: 'Are you sure you want to accept',
      };
    case 'message':
    default:
      return {
        confirmButtonText: null,
        confirmVariant: 'green' as tConfirmVariants,
        icon: 'announcement',
        defaultTitle: 'Message',
        defaultCopy: 'No message',
      };
  }
};

const SimpleDialog: React.FC<tSimpleDialog> = ({
  children,
  type,
  action,
  title,
  copy,
}): JSX.Element | null => {
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(false);

  const { confirmButtonText, confirmVariant, icon, defaultTitle, defaultCopy } = typeConfig(type);

  const showDialog = () => {
    setShow(true);
  };

  const confirmHandler = () => {
    setShow(false);
    action();
  };

  return (
    <>
      {show && (
        <Modal
          icon={icon}
          cancelMethod={() => setShow(false)}
          confirmMethod={confirmHandler}
          confirmButtonText={t(confirmButtonText ?? '')}
          confirmVariant={confirmVariant}
          showOnlyCancel={!confirmButtonText}
          title={t(title || defaultTitle)}
        >
          <DialogContentWrapper>{t(copy ?? '') || `${t(defaultCopy)}?`}</DialogContentWrapper>
        </Modal>
      )}
      <ButtonStyle type='button' onClick={showDialog}>
        {children}
      </ButtonStyle>
    </>
  );
};

export default SimpleDialog;
