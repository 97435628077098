/* eslint-disable camelcase */
import { AxiosResponse } from 'axios';
import structureAgent from 'agents/structure';
import { getDayNameFromNumber } from 'utils/helpers';
import {
  tElement,
  tSchedulesStructureApi,
  tSchedulesStructureValue,
  tSchedulesStructureApiElement,
  tLocationNode,
} from 'types/services/locations';
import { tGet } from 'types/global';
import { tIssueLocationTree } from 'types/services/issues';

export const getDuplicatedAvailabilityHours = (
  array: tSchedulesStructureApiElement[],
  arrayWithoutDuplicates = false as boolean,
) => {
  const isDuplicated = array.reduce((acc, elem) => {
    acc[elem.day_type] = acc[elem.day_type] + 1 || 0;
    return acc;
  }, {});
  return array.filter((elem) =>
    arrayWithoutDuplicates ? !isDuplicated[elem.day_type] : isDuplicated[elem.day_type],
  );
};

export const getWorkHours = (openingHours: tSchedulesStructureApiElement[]) =>
  !!openingHours?.length
    ? openingHours.map((hour) => {
        const { since, to, day_type } = hour;
        return {
          id: day_type,
          name: getDayNameFromNumber(day_type, true),
          fullName: getDayNameFromNumber(day_type),
          isChecked: true,
          firstValue: {
            valueFrom: since.slice(0, -3),
            valueTo: to.slice(0, -3),
          },
        };
      })
    : [];

export const getAvailabilitySchedule = (
  availabilitySchedule: tSchedulesStructureApiElement[],
  additionalValues: tSchedulesStructureValue[],
) => {
  const values = availabilitySchedule?.length
    ? availabilitySchedule.map((availability) => {
        const { since, to, day_type } = availability;
        return {
          id: day_type,
          name: getDayNameFromNumber(day_type, true),
          fullName: getDayNameFromNumber(day_type),
          isChecked: true,
          firstValue: {
            valueFrom: since.slice(0, -3),
            valueTo: to.slice(0, -3),
          },
        };
      })
    : [];
  return [...values, ...additionalValues];
};

const getLocationTypes = () =>
  structureAgent.getLocationTypes().then(({ data, status }) => ({
    data,
    status,
  }));

export type tGetStructureParams = {
  contractId: number | null;
  typeId: string | null;
};

const getStructure = ({ contractId, typeId }: tGetStructureParams) => {
  const params = {
    contract_id: contractId,
    type_id: typeId,
  };
  return structureAgent
    .getStructure(params)
    .then(({ data }: { data: tLocationNode[] }) => ({ data: { results: data } }));
};

export type tStructureNode = {
  id: string;
  name: string;
  type: {
    id: string;
    name: string;
  };
  client_mpk?: string | null;
  parent: string;
  children: tStructureNode[];
};

const getCurrentStructure = ({ id }: tGet) =>
  structureAgent.getCurrentStructure(String(id)).then((data) => ({
    data,
  }));

const removeElement = (id: string) => structureAgent.removeElement(id).then(() => true);

const addElement = (
  name: tElement['name'],
  typeId: tElement['type_id'],
  parent: tElement['parent'],
) => structureAgent.addElement({ name, type_id: typeId, parent }).then(() => true);

const addMruk = (
  typeId: tElement['type_id'],
  parent: tElement['parent'],
  addressId: tElement['address'],
  contractId: tElement['contract_id'],
) =>
  structureAgent
    .addElement({ type_id: typeId, parent, address: addressId, contract_id: contractId })
    .then(() => true);

const patchCurrentStructure = (uuid, data) =>
  structureAgent.patchCurrentStructure(uuid, data).then(() => true);

export type tSchedulesStructure = {
  workHours: tSchedulesStructureValue[];
  availabilitySchedule: tSchedulesStructureValue[];
  availabilityPeriodsChecked: boolean;
};

const getSchedulesStructure = async ({ id }: tGet) => {
  const duplicated: tSchedulesStructureValue[] = [];
  const response: AxiosResponse = await structureAgent.getSchedulesStructure(String(id));
  const { data }: { data: tSchedulesStructureApi } = response;
  const { opening_hours, availability_hours } = data;

  const sortedAvailabilityHours = availability_hours?.length
    ? availability_hours.sort((a, b) => a.day_type - b.day_type)
    : [];

  const availabilityHoursDuplicate = getDuplicatedAvailabilityHours(sortedAvailabilityHours);
  const availabilityHoursWithoutDuplicate = getDuplicatedAvailabilityHours(
    sortedAvailabilityHours,
    true,
  );

  if (!!availabilityHoursDuplicate.length) {
    availabilityHoursDuplicate.reduce((acc, next) => {
      if (acc.day_type === next.day_type) {
        duplicated.push({
          id: acc.day_type,
          name: getDayNameFromNumber(acc.day_type, true),
          fullName: getDayNameFromNumber(acc.day_type),
          isChecked: true,
          firstValue: {
            valueFrom: acc.since.slice(0, -3),
            valueTo: acc.to.slice(0, -3),
          },
          secondValue: {
            valueFrom: next.since.slice(0, -3),
            valueTo: next.to.slice(0, -3),
          },
        });
      }
      return next;
    });
  }

  return {
    data: {
      workHours: getWorkHours(opening_hours),
      availabilitySchedule: getAvailabilitySchedule(availabilityHoursWithoutDuplicate, duplicated),
      availabilityPeriodsChecked: !!duplicated.length,
    },
  };
};

const putSchedulesStructure = (id: string, data: tSchedulesStructureApi) =>
  structureAgent.putSchedulesStructure(id, data);

export type tGetIssueLocationTreeParams = { locationId: number | string };

const getIssueLocationTree = async ({ locationId }: tGetIssueLocationTreeParams) => {
  const response = await structureAgent.getIssueLocationTree(locationId);
  const { data }: { data: tIssueLocationTree } = response;
  return {
    data: [data],
  };
};

export default {
  getStructure,
  getCurrentStructure,
  getLocationTypes,
  removeElement,
  addElement,
  patchCurrentStructure,
  addMruk,
  getSchedulesStructure,
  putSchedulesStructure,
  getIssueLocationTree,
};
