import { useTranslation } from 'react-i18next';
import { monetaryUnit } from 'utils/constants';
import { formatMonetaryValue } from 'utils/helpers';
import { Input, DropdownV2, Icon } from 'components';
import { tDropdownOption, tError } from 'types/global';
import { tInvoiceComments, tInvoiceRowData } from 'types/components/invoices';
import { colorBlueBasic, colorRedBasic } from 'styles/GlobalStyles';
import { Flex, RelativeWithOffsets } from 'styles/GlobalStyledComponents';
import { Cell, CommentContainer } from './InvoiceTable.style';
import Separator from './Separator';

export type tRowType = 'input' | 'dropdown';

export type tRowConfig = {
  hideUnit?: boolean;
  previewUnit?: boolean;
  hideQuantity?: boolean;
  previewRate?: boolean;
};

export type tInvoiceRow = {
  rowData: tInvoiceRowData;
  onChange: (newRow: tInvoiceRowData) => void;
  deleteRow?: (id: number | string) => void;
  allowDelete?: boolean;
  edit?: boolean;
  addComment: (rowData: tInvoiceRowData) => void;
  rowConfig?: tRowConfig;
  allItemOptions?: tDropdownOption[];
  itemOptions?: tDropdownOption[];
  unitOptions?: tDropdownOption[];
  type?: tRowType;
  showGross?: boolean;
  showContractor?: boolean;
  showReport?: boolean;
  topPadding?: boolean;
  bottomPadding?: boolean;
  errors: tError[];
  commentMode: boolean;
  comments: tInvoiceComments;
  subcontractorCommentMode: boolean;
};

const InvoiceRow: React.FC<tInvoiceRow> = ({
  rowData,
  onChange,
  allowDelete = false,
  deleteRow,
  edit = false,
  rowConfig = {},
  itemOptions = [],
  unitOptions = [],
  allItemOptions = [],
  type = 'input',
  showGross = true,
  showContractor = true,
  addComment,
  showReport = false,
  topPadding = false,
  bottomPadding = false,
  errors,
  commentMode = false,
  comments,
  subcontractorCommentMode,
}) => {
  const { t } = useTranslation();

  const {
    hideUnit = false,
    previewUnit = false,
    hideQuantity = false,
    previewRate = false,
  } = rowConfig;

  const isEditMode = edit && !rowData?.nonEditable;

  const itemInputChangeHandler = (data) => {
    onChange({ ...rowData, title: data?.target?.value });
  };

  const itemDropdownChangeHandler = (data) => {
    if (data?.dataType === 'billable_activities') {
      return onChange({
        ...rowData,
        title: data?.label,
        unit: data?.unit,
        type: data?.dataType,
        client: { value: data?.rate, net: 0, gross: 0 },
      });
    }

    onChange({ ...rowData, title: data?.label });
  };
  const unitChangeHandler = (data) => {
    onChange({ ...rowData, unit: data });
  };

  const quantityChangeHandler = (data) => {
    onChange({ ...rowData, quantity: data?.target?.value });
  };

  const clientRateChangeHandler = (data) => {
    onChange({
      ...rowData,
      client: rowData?.client ? { ...rowData?.client, value: data?.target?.value } : null,
    });
  };

  const deleteRowHandler = () => {
    if (deleteRow) deleteRow(rowData?.id);
  };

  const dropdownOptions = allItemOptions?.length ? allItemOptions : itemOptions;

  const inputsBottomMarginSmall = { marginBottom: 0 };

  const renderTitle = () => {
    if (isEditMode) {
      if (type === 'input') {
        return (
          <Input
            name='name'
            value={rowData?.title ?? ''}
            onChange={itemInputChangeHandler}
            errors={errors}
            wrapperStyle={inputsBottomMarginSmall}
          />
        );
      }
      return (
        <DropdownV2
          name={`name-${rowData?.rowNo}`}
          value={dropdownOptions?.find((el) => el?.label === rowData?.title) ?? null}
          onChange={itemDropdownChangeHandler}
          errors={errors}
          options={itemOptions}
          wrapperStyle={inputsBottomMarginSmall}
        />
      );
    }
    return rowData?.title;
  };

  const calcColSpan = () => {
    if (hideUnit && hideQuantity) return 3;
    if (hideUnit) return 2;
    return 1;
  };

  const renderMonetaryValue = (value: number | null | undefined) =>
    value ? `${formatMonetaryValue(value)} ${monetaryUnit}` : '-';

  const comment = comments.find((comment) => comment.id === rowData.id)?.comment?.length;

  return (
    <tr>
      <Cell
        lowPadding={isEditMode}
        align='left'
        colSpan={calcColSpan()}
        topPadding={topPadding}
        bottomPadding={bottomPadding}
      >
        <Flex>
          {renderTitle()}
          {allowDelete && isEditMode && (
            <RelativeWithOffsets top={5} left={8}>
              <Icon icon='close' fill={colorRedBasic} asButton onClick={deleteRowHandler} />
            </RelativeWithOffsets>
          )}
          {commentMode && (
            <RelativeWithOffsets top={-5} left={10}>
              <Icon
                icon={!!comment ? 'commentAdded' : 'comment'}
                fill={colorBlueBasic}
                asButton
                onClick={() => addComment(rowData)}
              />
            </RelativeWithOffsets>
          )}
          {subcontractorCommentMode && rowData?.subcontractorRow ? (
            <RelativeWithOffsets top={-5} left={10}>
              <Icon
                icon={!!comment ? 'commentAdded' : 'comment'}
                fill={colorBlueBasic}
                asButton
                onClick={() => addComment(rowData)}
              />
            </RelativeWithOffsets>
          ) : null}
        </Flex>
        {rowData?.comment && (
          <CommentContainer>
            <strong>{t('Comments')}: </strong>
            {rowData?.comment}
          </CommentContainer>
        )}
      </Cell>
      {!hideUnit && (
        <Cell
          lowPadding={isEditMode && !previewUnit}
          width='7rem'
          topPadding={topPadding}
          bottomPadding={bottomPadding}
        >
          {isEditMode && !previewUnit ? (
            <DropdownV2
              name={`unit-${rowData?.rowNo}`}
              value={rowData?.unit}
              onChange={unitChangeHandler}
              errors={errors}
              options={unitOptions}
              preview={!isEditMode}
              wrapperStyle={inputsBottomMarginSmall}
              labelMinWidth={0}
            />
          ) : (
            rowData?.unit?.label ?? ''
          )}
        </Cell>
      )}
      {!hideQuantity && (
        <Cell
          lowPadding={isEditMode}
          width='7rem'
          topPadding={topPadding}
          bottomPadding={bottomPadding}
        >
          {isEditMode ? (
            <Input
              name={`quantity-${rowData?.rowNo}`}
              value={rowData?.quantity ?? ''}
              onChange={quantityChangeHandler}
              errors={errors}
              preview={!isEditMode}
              wrapperStyle={inputsBottomMarginSmall}
              type='number'
              labelMinWidth={0}
            />
          ) : (
            rowData?.quantity ?? ''
          )}
        </Cell>
      )}
      <Separator />
      {rowData?.client ? (
        <>
          <Cell
            lowPadding={isEditMode && !previewRate}
            width='7rem'
            topPadding={topPadding}
            bottomPadding={bottomPadding}
          >
            {isEditMode && !previewRate ? (
              <Input
                name={`clientRate-${rowData?.rowNo}`}
                value={rowData?.client?.value ?? ''}
                onChange={clientRateChangeHandler}
                errors={errors}
                inputSufix={monetaryUnit}
                wrapperStyle={inputsBottomMarginSmall}
                type='number'
                labelMinWidth={0}
              />
            ) : (
              renderMonetaryValue(rowData?.client?.value)
            )}
          </Cell>
          <Cell width='7rem' topPadding={topPadding} bottomPadding={bottomPadding}>
            {renderMonetaryValue(rowData?.client?.net)}
          </Cell>
          {showGross && (
            <Cell width='7rem' topPadding={topPadding} bottomPadding={bottomPadding}>
              {renderMonetaryValue(rowData?.client?.gross)}
            </Cell>
          )}
        </>
      ) : (
        <Cell colSpan={showGross ? 3 : 2} />
      )}

      {showContractor && (
        <>
          <Separator />
          {rowData?.contractor ? (
            <>
              <Cell width='7rem' topPadding={topPadding} bottomPadding={bottomPadding}>
                {renderMonetaryValue(rowData?.contractor?.value)}
              </Cell>
              <Cell width='7rem' topPadding={topPadding} bottomPadding={bottomPadding}>
                {renderMonetaryValue(rowData?.contractor?.net)}
              </Cell>
              {showGross && (
                <Cell width='7rem' topPadding={topPadding} bottomPadding={bottomPadding}>
                  {renderMonetaryValue(rowData?.contractor?.gross)}
                </Cell>
              )}
            </>
          ) : (
            <Cell colSpan={showGross ? 3 : 2} />
          )}
        </>
      )}
      {showReport && <Separator />}
    </tr>
  );
};

export default InvoiceRow;
