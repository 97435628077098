import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: 'relative';
  @media screen and (max-width: 767px) {
    & > div {
      min-height: 70vh;
    }
  }
`;

export const Error = styled.div`
  background: var(--theme-white);
  border-radius: 8px;
  box-shadow: 0px 0px 8px #0000000a;
  flex: 1;
  height: inherit;
  padding: 27px 24px;
`;
