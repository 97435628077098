import styled from 'styled-components';

export const Ul = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const Li = styled.li`
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  letter-spacing: 0.58px;
  line-height: 0.9375rem;
`;

export const Paragraph = styled.p`
  font-size: 0.75rem;
  padding: 0;
  margin: 0;
  color: var(--theme-mainDarkGrey);
`;

export const Error = styled.p`
  padding: 0;
  margin: 0;
  color: red;
`;

export const Button = styled.button`
  background: transparent;
  border: 0;
  outline: 0;
  margin-left: var(--small-padding);
  cursor: pointer;
`;

export const NoItemsParagraph = styled.div`
  display: flex;
  align-items: center;
  height: 1.75rem;
  font-size: 0.75rem;
  font-style: italic;
  padding: 0;
  margin: 0;
  color: var(--theme-mainDarkGrey);
`;
