import styled, { css } from 'styled-components';
import { tCheckbox, tWrapper, tTitle } from './Checkbox';

export const InputStyle = styled.input.attrs({ type: 'checkbox' })<tCheckbox>`
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  position: relative;
  appearance: none;
  outline: 0;
  cursor: pointer;
  border: 2px solid;
  border-color: ${(props) => {
    if (props.errors.find((error) => error.field === props.name)) {
      return 'var(--theme-mainRed)';
    }
    if (props.disabled) {
      return 'var(--theme-borderDisabled)';
    }
    return 'var(--theme-mainBlue)';
  }};

  &:after {
    content: '';
    position: absolute;
    width: 6px;
    height: 12px;
    border-right: 3px solid var(--theme-white);
    border-bottom: 3px solid var(--theme-white);
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%) rotateZ(40deg);
  }
  &:checked {
    background: ${({ disabled }) =>
      disabled ? 'var(--theme-borderDisabled)' : 'var(--theme-mainBlue)'};
  }
  ${({ halfCheck }) =>
    !!halfCheck &&
    css`
      background: transparent;
      &:after {
        content: '';
        position: absolute;
        width: 14px;
        height: 14px;
        border: 0;
        background: var(--theme-mainBlue);
        transform: none;
        top: 1px;
        left: 1px;
      }
    `}
`;

export const Container = styled.span`
  display: flex;
  align-items: center;
`;

export const Title = styled.span<tTitle>`
  margin-left: 0.75rem;
  font-size: 0.75rem;
  font-weight: bold;
  text-align: left;
  flex: 1;
  color: var(--theme-mainDarkGrey);
  ${({ titleStyles }) =>
    titleStyles &&
    css`
      ${titleStyles}
    `};
`;

export const Wrapper = styled.div<tWrapper>`
  min-height: 32px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  ${({ wrapperStyles }) =>
    wrapperStyles &&
    css`
      ${wrapperStyles}
    `};
`;

export const PreviewWrapper = styled.span`
  display: flex;
  margin-bottom: 1rem;
`;

export const CheckedTextStyle = styled.p`
  font-size: 0.75rem;
  margin: 0;
`;
