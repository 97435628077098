import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import i18n from 'i18n';
import { baseURL } from 'utils/constants';
import store from 'store/store';
import { logout } from 'store/auth/actions';
import { Dispatch } from 'redux';

const options: AxiosRequestConfig = {
  baseURL,
  timeout: 30000,
  headers: { 'Content-Type': 'application/json' },
};

const optionsForAttachments: AxiosRequestConfig = {
  ...options,
  responseType: 'blob',
};

export const fetchProvider = axios.create(options);
export const fetchProviderForAttachment = axios.create(optionsForAttachments);

const { interceptors } = fetchProvider;
const { interceptors: interceptorsForAttachments } = fetchProviderForAttachment;
const { language } = i18n();

const getRequestConfig = (config: AxiosRequestConfig) => {
  const {
    auth: { kc },
  } = store.getState();
  const token = kc?.token ?? '';

  if (config?.headers) {
    /* eslint-disable no-param-reassign */
    if (token) config.headers.Authorization = `Bearer ${token}`;
    /* eslint-disable no-param-reassign */
    config.headers['Accept-Language'] = language;
  }

  return config;
};

const getRequestError = (error: AxiosError) => Promise.reject(error);

const getResponse = (response: AxiosResponse) => response;

const geResponseError = (error: AxiosError) => {
  const status = error?.response?.status;
  const { dispatch }: { dispatch: Dispatch<any> } = store;

  if (status === 401 || status === 403) {
    dispatch(logout());
  }

  return Promise.reject(error.response);
};

interceptors.request.use(getRequestConfig, getRequestError);
interceptorsForAttachments.request.use(getRequestConfig, getRequestError);

interceptors.response.use(getResponse, geResponseError);
interceptorsForAttachments.response.use(getResponse, geResponseError);
