import devicesAgent from 'agents/devices';
import { tGet, tGetAll, tDropdownOption } from 'types/global';
import { tGetDeviceData, tGetAllDevicesData } from 'types/services/devices';
import { tCreateDeviceAPI, tUpdateDeviceAPI } from 'types/api/devices';

const getAll = (params: tGetAll) => {
  const { offset = 0 } = params;

  const result = devicesAgent.getAll(params).then(({ data, status }) => {
    const results: tGetAllDevicesData[] = data.results.map(
      ({ id, manufacturer, model, location }, index) => ({
        id,
        order: index + offset + 1,
        manufacturer,
        model,
        location: location?.name ?? '-',
      }),
    );
    return {
      data: { ...data, results },
      status,
    };
  });

  return result;
};

const get = async ({ id }: tGet) => {
  const {
    data: {
      manufacturer,
      model,
      manual_url: manual,
      production_date: production,
      serial_number: sn,
      part_number: pn,
      ean,
      utility_id: uid,
      project_id: pid,
      rfid,
      qr,
      warranty_expiration_date: warranty,
      installation_date: installation,
      last_maintenance_date: lastMaintenance,
      maintenance_intervals_value: maintenanceIntervalsValue,
      maintenance_intervals_unit: maintenanceIntervalsUnit,
      periodic_assessment: periodicAssessment,
      warranty_company: { name: warrantyName, sap_id: warrantySapId, contact: warrantyContact },
      maintenance_company: {
        name: maintenanceName,
        sap_id: maintenanceSapId,
        contact: maintenanceContact,
      },
      maintenance_operations: maintenanceOperations,
      consumables,
      parts,
      specifications,
      location,
      storey,
      area,
      room,
      industry,
    },
  } = await devicesAgent.get(id);

  const body: tGetDeviceData = {
    id: +id,
    manufacturer,
    model,
    manual: manual ?? '',
    production: production ? new Date(production) : null,
    sn: sn ?? '',
    pn: pn ?? '',
    ean: ean ?? '',
    uid: uid ?? '',
    pid: pid ?? '',
    rfid: rfid ?? '',
    qr: qr ?? '',
    warranty: warranty ? new Date(warranty) : null,
    installation: installation ? new Date(installation) : null,
    lastMaintenance: lastMaintenance ? new Date(lastMaintenance) : null,
    warrantyName,
    warrantySapId: warrantySapId ?? '',
    warrantyContact: warrantyContact ?? '',
    maintenanceName,
    maintenanceSapId: maintenanceSapId ?? '',
    maintenanceContact: maintenanceContact ?? '',
    maintenanceIntervals: {
      optionLabel: maintenanceIntervalsUnit ?? '',
      value: String(maintenanceIntervalsValue ?? ''),
    },
    periodicAssessment: periodicAssessment ?? '',
    maintenanceOperations: maintenanceOperations.map((op) => ({
      id: op.id,
      name: op.name,
      value: op.value,
      unit: !!op?.unit ? op.unit : null,
      type: !!op?.type ? op.type : null,
    })),
    consumables: consumables.map((con) => ({
      id: con.id,
      name: con.name,
      quantity: con.quantity,
      unit: con.unit,
      ean: con.ean ?? '',
      manufacturer: con.manufacturer ?? '',
    })),
    parts: parts?.map((part) => part.name) ?? [],
    specifications: specifications.map((el) => ({
      id: el.id,
      name: el.name,
      value: el.value,
      unit: !!el.unit ? { value: el.unit.id, label: el.unit.name } : null,
    })),
    location: location ? { value: location.id, label: location.name } : null,
    storey: storey ? { value: storey.id, label: storey.name } : null,
    area: area ? { value: area.id, label: area.name } : null,
    room: room ? { value: room.id, label: room.name } : null,
    industry: industry?.id && industry?.name ? { value: industry.id, label: industry.name } : null,
    components: [],
  };
  return { data: body };
};

const getIndustries = async () => {
  const response = await devicesAgent.getIndustries();
  const { data } = response;
  const results: tDropdownOption[] = data.map((ind) => ({ value: ind.id, label: ind.name })) ?? [];
  return { data: { results } };
};

const getSpecificationUnits = async () => {
  const response = await devicesAgent.getSpecificationUnits();
  const { data } = response;
  const results: tDropdownOption[] =
    data.map((unit) => ({ value: unit.id, label: unit.name })) ?? [];
  return { data: { results } };
};

export type tGetLocationParams = { typeId?: number | string; parentId?: string };
const getLocation = async ({ typeId, parentId }: tGetLocationParams) => {
  const response = await devicesAgent.getLocation({
    type_id: typeId,
    parent_id: parentId,
  });
  const { data } = response;
  const results: tDropdownOption[] = data.map((loc) => ({ value: loc.id, label: loc.name })) ?? [];
  return { data: { results } };
};

const getLocationTypes = async () => {
  const response = await devicesAgent.getLocationTypes();
  const { data } = response;
  return { data: { results: data } };
};

const remove = (id: number) =>
  devicesAgent
    .delete(id)
    .then(({ status }) => ({ status, error: '' }))
    .catch(({ data, status }) => ({
      status,
      error: !!data.length ? data[0].msg : 'Something went wrong. Try again',
    }));

const create = (bodyRequest: tCreateDeviceAPI) => {
  const result = devicesAgent
    .create(bodyRequest)
    .then((response) => {
      const { status, data } = response;
      return { state: true as const, errors: [], data, status };
    })
    .catch((error) => {
      return { state: false as const, errors: !!error?.data ? error.data : [], data: {} };
    });

  return result;
};

const update = async (bodyRequest: tUpdateDeviceAPI, id: number | string) => {
  const result = devicesAgent
    .update(bodyRequest, id)
    .then((response) => {
      const { status, data } = response;
      return { state: true as const, errors: [], data, status };
    })
    .catch((error) => {
      return { state: false as const, errors: !!error?.data ? error.data : [], data: {} };
    });

  return result;
};

export default {
  getAll,
  get,
  getIndustries,
  getSpecificationUnits,
  getLocation,
  getLocationTypes,
  create,
  update,
  remove,
};
