import { ReactComponent as GidLogo } from 'assets/img/gid_logo.svg';

import {
  Wrapper,
  FlexV,
  Aside,
  MainContainer,
  SmartFMLogoStyle,
  GidLogoContainer,
  ContentContainer,
} from './LoginPageWrapper.style';

export type tLoginPageWrapper = { children: JSX.Element[] | JSX.Element | string };

const LoginPageWrapper: React.FC<tLoginPageWrapper> = ({ children }) => (
  <Wrapper>
    <FlexV>
      <MainContainer>
        <SmartFMLogoStyle />
        <ContentContainer>{children}</ContentContainer>
      </MainContainer>
      <GidLogoContainer>
        Wykonane przez:
        <GidLogo style={{ paddingLeft: '1rem' }} />
      </GidLogoContainer>
    </FlexV>
    <Aside />
  </Wrapper>
);

export default LoginPageWrapper;
