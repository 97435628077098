import { useState, useEffect } from 'react';
import isEqual from 'lodash.isequal';
import { tFilters, tFilter } from 'types/components/table';

const useFilters = (localStorageKey = '' as string) => {
  const [filters, setFilters] = useState<tFilters>(null);

  const setFiltersHandler = (filtersHelper: tFilters, saveToLocalStorage = true as boolean) => {
    setFilters({
      ...filtersHelper,
    });
    if (!!localStorageKey && saveToLocalStorage) {
      localStorage.setItem(localStorageKey, JSON.stringify(filtersHelper));
    }
  };

  const removeFilter = (filter: string, item: tFilter) => {
    const filtersHelper = { ...filters };
    delete filtersHelper[filter];
    if (!!filters) {
      const currentFilter = filters[filter].filter(
        (current) => current.value.toString() !== item.value.toString(),
      );
      if (currentFilter?.length) {
        filtersHelper[filter] = [...currentFilter];
      }

      setFiltersHandler({
        ...filtersHelper,
      });
    }
  };

  const clearFilters = (name: string | number) => {
    const filtersHelper = { ...filters };
    delete filtersHelper[name];
    setFiltersHandler({
      ...filtersHelper,
    });
  };

  const removeFilters = () => {
    setFiltersHandler({});
  };

  const changeFilters = (value: tFilter[], name: string) => {
    const filtersHelper = { ...filters };
    delete filtersHelper[name];
    if (!!value.length) {
      filtersHelper[name] = [...value];
    }
    setFiltersHandler({
      ...filtersHelper,
    });
  };

  useEffect(() => {
    if (!localStorageKey) return;
    const filtersFromLocalStorage = localStorage.getItem(localStorageKey);
    if (!filtersFromLocalStorage) {
      setFiltersHandler({});
      return;
    }
    const parsedFiltersFromLocalStorage = JSON.parse(filtersFromLocalStorage);

    if (!isEqual(parsedFiltersFromLocalStorage, filters)) {
      setFiltersHandler(parsedFiltersFromLocalStorage, false);
    }
  }, [filters]);

  useEffect(() => {
    if (!localStorageKey) {
      setFiltersHandler({}, false);
    }
  }, []);

  return [
    filters,
    removeFilter,
    clearFilters,
    changeFilters,
    setFiltersHandler,
    removeFilters,
  ] as const;
};

export default useFilters;
