import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  margin-bottom: 1rem;
  position: absolute;
  top: 50%;
  right: -2rem;
  transform: translateY(-45%);
`;

export const ComponentWrapper = styled.div`
  margin: 0 0.5rem;
`;

export const TreeViewWrapper = styled.div`
  padding: 0.75rem 0 0 6rem;
`;

export const Relative = styled.div`
  position: relative;
`;
