import { useCallback, useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { useSelector } from 'react-redux';
import tStore from 'types/store';
import { useTranslation } from 'react-i18next';
import {
  Spinner,
  PageWrapper,
  ComplexTable,
  Breadcrumbs,
  Tiles,
  ListTiles,
  ListTitle,
} from 'components';
import { useFetch } from 'hooks';
import clients, { tGetAllArchived } from 'services/clients';
import { tGetAllClientsData } from 'types/services/clients';
import { ListBreadcrumb, ListTableContainer } from 'styles/GlobalStyledComponents';
import { tableHead } from './TableConfig';

interface iClient extends RouteComponentProps<any> {}

const ClientsList: React.FC<iClient> = ({ history }): JSX.Element => {
  const { t } = useTranslation();

  const permissions = useSelector((state: tStore) => state.user.permissions);
  const canWrite = !!permissions.includes('tunClientsWrite');

  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [sort, setSort] = useState<string>('');

  const { fetch, loading, data, count } = useFetch<tGetAllClientsData, tGetAllArchived>(
    clients.getAll,
    'Something went wrong. Try again',
  );

  const fetchData = useCallback(
    () => fetch({ search, limit, offset, ordering: sort }),
    [search, limit, offset, sort, fetch],
  );

  const setSortParams = (field: string, sort: string) => {
    const type: string = sort ? '-' : '';
    setSort(`${type}${field}`);
  };

  const setRequestParams = useCallback(
    (newOffset: number, newLimit: number) => {
      if (newOffset !== offset) setOffset(newOffset);
      if (newLimit !== limit) setLimit(newLimit);
    },
    [limit, offset],
  );

  useEffect(() => {
    setOffset(0);
  }, [search]);

  useEffect(() => {
    fetchData();
  }, [limit, offset, sort, fetchData]);

  const changeRoute = () => history.push('/customers/new');

  const showClientDetails = (id: number | string) => history.push(`/customers/${id}`);

  return (
    <PageWrapper>
      {loading && <Spinner />}
      <ListBreadcrumb>
        <Breadcrumbs />
      </ListBreadcrumb>
      <ListTitle title={t('Clients')} />
      <ListTiles>
        <Tiles
          text={t(`Create new client'`)}
          icon='personAdd'
          bgIcon='addComment'
          color='orange'
          variant='filled'
          onClick={changeRoute}
          disabled={!canWrite}
        />
      </ListTiles>
      <ListTableContainer>
        <ComplexTable
          isRowClickable
          onRowClick={showClientDetails}
          value={search}
          resetValue={() => setSearch('')}
          onChange={({ target: { value } }) => setSearch(value)}
          name='tableSearch'
          tableBodyData={data}
          tableHead={tableHead}
          setSortParams={(field: string, sort: string) => setSortParams(field, sort)}
          totalRecords={count}
          setParams={(offset: number, limit: number) => setRequestParams(offset, limit)}
          tableName='List of Clients'
          limit={limit}
          offset={offset}
          overflowStyles={{ overflow: 'hidden', overflowX: 'scroll' }}
        />
      </ListTableContainer>
    </PageWrapper>
  );
};

export default withRouter(ClientsList);
