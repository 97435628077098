import styled from 'styled-components';

export const Title = styled.p`
  font-size: 1.5rem;
  margin-top: 1.625rem;
  margin-bottom: 1.875rem;
  font-weight: 900;
`;

export const ExpansionPanelWrapper = styled.div`
  background: var(--theme-white);
  padding: 1.625rem 2rem;
  border-radius: 8px;
  margin-bottom: 2rem;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 0.875rem;
  display: flex;
  justify-content: flex-end;
`;

export const ContainerWithGap = styled.div`
  margin-bottom: 1rem;
`;

export const TreeViewWrapper = styled.div`
  padding: 0.75rem 0 0 6rem;
`;
