import { monetaryUnit } from 'utils/constants';
import { formatMonetaryValue } from 'utils/helpers';
import { useTranslation } from 'react-i18next';
import { Cell, StickyCell } from './InvoiceTable.style';
import Separator from './Separator';

export type tNetGrossPercentageValue =
  | {
      net: number | null;
      gross: number | null;
      value: number | null;
    }
  | null
  | undefined;

export type tSubSums = {
  name?: string;
  data: { client: tNetGrossPercentageValue; contractor: tNetGrossPercentageValue };
  showGross?: boolean;
  showContractor?: boolean;
  showReport?: boolean;
};

const MarkupSums: React.FC<tSubSums> = ({
  name = 'Total with markup',
  data,
  showGross = true,
  showContractor = true,
  showReport = false,
}) => {
  const { t } = useTranslation();

  return (
    <tr>
      <Cell colSpan={3} />
      <Separator />
      <StickyCell>
        {t(name)} ({data?.client?.value}%)
      </StickyCell>
      <Cell>{`${formatMonetaryValue(data?.client?.net) ?? '-'} ${monetaryUnit}`}</Cell>
      {showGross && (
        <Cell>{`${formatMonetaryValue(data?.client?.gross) ?? '-'} ${monetaryUnit}`}</Cell>
      )}

      {showContractor && (
        <>
          <Separator />
          <StickyCell>
            {t(name)} ({data?.contractor?.value}%)
          </StickyCell>
          <Cell>{`${formatMonetaryValue(data?.contractor?.net) ?? '-'} ${monetaryUnit}`}</Cell>
          {showGross && (
            <Cell>{`${formatMonetaryValue(data?.contractor?.gross) ?? '-'} ${monetaryUnit}`}</Cell>
          )}
        </>
      )}
      {showReport && <Separator />}
    </tr>
  );
};

export default MarkupSums;
