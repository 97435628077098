import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { tError } from 'types/global';
import { hoursMinutesToHHMM } from 'utils/helpers';
import { useOutsideClickAction, useFormErrorsReset } from 'hooks';
import { tCustomInputEvent } from 'hooks/useForm';
import { timeRegex } from 'utils/regex';
import { Input } from 'components';
import { Relative } from 'styles/GlobalStyledComponents';
import {
  Wrapper,
  RelativeContainer,
  WithIcon,
  TimepickerContainer,
  ScrollContainer,
  FlexHorizontal,
  ButtonStyle,
  BottomContainer,
  NowButtonStyle,
  OkButtonStyle,
} from './Timepicker.style';

export type tButton = {
  selected: boolean;
};

export type tTimepicker = {
  name: string;
  value: string;
  onChange?: (e: tCustomInputEvent) => void;
  label?: string;
  placeholder?: string;
  errors?: tError[];
  disabled?: boolean;
  preview?: boolean;
  labelMinWidth?: number;
  fullWidth?: boolean;
  canZero?: boolean;
};

const Timepicker: React.FC<tTimepicker> = ({
  name,
  value,
  onChange = () => {},
  label = '',
  placeholder = '__:__',
  errors = [],
  disabled = false,
  preview = false,
  labelMinWidth = 90,
  fullWidth = false,
  canZero = false,
}) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [valueHelper, setValueHelper] = useState('');

  const timepickerContainerRef = useRef(null);

  const hoursArr = Array.from(new Array(24), (val, index) => String(index));
  const minutesArr = Array.from(new Array(60), (val, index) => String(index));
  const pattern = '[0-2]{1}[0-9]{1}:[0-5]{1}[0-9]{1}';

  const { formElemErrors, resetFormError } = useFormErrorsReset(errors);

  const handleChange = (customEvent: tCustomInputEvent) => {
    resetFormError(customEvent?.name);
    onChange(customEvent);
  };

  useEffect(() => {
    if (valueHelper.match(pattern)) {
      const customEvent = {
        name,
        type: 'timepicker',
        custom: true,
        value: valueHelper,
      };
      handleChange(customEvent);
    } else if (canZero) {
      const customEvent = {
        name,
        type: 'timepicker',
        custom: true,
        value: '',
      };
      handleChange(customEvent);
    }
  }, [valueHelper]);

  useEffect(() => {
    if (value && value.match(pattern)) {
      const [h, m] = value.split(':');
      setHours(+h);
      setMinutes(+m);
      setValueHelper(value);
    }
  }, [value]);

  const selectHoursHandler = (hour) => {
    setHours(hour);
    setValueHelper(`${hour.padStart(2, '0')}:${String(minutes).padStart(2, '0')}`);
  };

  const selectMinutesHandler = (minute) => {
    setMinutes(minute);
    setValueHelper(`${String(hours).padStart(2, '0')}:${minute.padStart(2, '0')}`);
  };

  const nowButtonHandler = () => {
    const now = new Date();
    const h = now.getHours();
    const m = now.getMinutes();
    setHours(h);
    setMinutes(m);
    setIsVisible(false);
    setValueHelper(hoursMinutesToHHMM(h, m));
  };

  const okButtonHandler = () => {
    setIsVisible(false);
    setValueHelper(hoursMinutesToHHMM(hours, minutes));
  };

  const clickOutsideHandler = () => {
    setIsVisible(false);
  };

  const setValueHandler = (value) => {
    if (value.length < 4) {
      setHours(0);
      setMinutes(0);
    } else {
      const [h, m] = value.split(':');
      setHours(h);
      setMinutes(m);
    }
    setValueHelper(value);
  };

  const inputFocusHandler = () => {
    if (!disabled) setIsVisible(true);
  };

  const inputBlurHandler = (e) => {
    if (!e.target.value.match(pattern)) {
      if (canZero) {
        setValueHelper('');
      } else {
        setValueHelper(value);
      }
    }
  };

  useOutsideClickAction(clickOutsideHandler, timepickerContainerRef);
  return (
    <Wrapper>
      <RelativeContainer>
        <Relative>
          <Input
            name={name}
            value={valueHelper}
            placeholder={placeholder}
            disabled={disabled}
            preview={preview}
            mask={timeRegex}
            onFocus={inputFocusHandler}
            onBlur={inputBlurHandler}
            inFocus={isVisible}
            onChange={(e) => setValueHandler(e.target.value)}
            errors={formElemErrors}
            wrapperStyle={{ marginBottom: '0', width: fullWidth ? '100%' : '120px' }}
            label={t(label)}
            labelMinWidth={labelMinWidth}
          />
          {!preview && <WithIcon icon='schedule' />}

          {isVisible && !disabled && !preview && (
            <TimepickerContainer ref={timepickerContainerRef}>
              <FlexHorizontal>
                <ScrollContainer>
                  {hoursArr.map((h) => (
                    <ButtonStyle
                      selected={
                        !!valueHelper && h.padStart(2, '0') === String(hours).padStart(2, '0')
                      }
                      onClick={() => {
                        selectHoursHandler(h);
                      }}
                      key={h}
                      type='button'
                    >
                      {h.padStart(2, '0')}
                    </ButtonStyle>
                  ))}
                </ScrollContainer>
                <ScrollContainer>
                  {minutesArr.map((min) => (
                    <ButtonStyle
                      selected={
                        !!valueHelper && min.padStart(2, '0') === String(minutes).padStart(2, '0')
                      }
                      onClick={() => {
                        selectMinutesHandler(min);
                      }}
                      key={min}
                      type='button'
                    >
                      {min.padStart(2, '0')}
                    </ButtonStyle>
                  ))}
                </ScrollContainer>
              </FlexHorizontal>
              <FlexHorizontal>
                <BottomContainer>
                  <NowButtonStyle type='button' onClick={nowButtonHandler}>
                    {t('Now')}
                  </NowButtonStyle>
                  <OkButtonStyle type='button' onClick={okButtonHandler}>
                    {t('Ok')}
                  </OkButtonStyle>
                </BottomContainer>
              </FlexHorizontal>
            </TimepickerContainer>
          )}
        </Relative>
      </RelativeContainer>
    </Wrapper>
  );
};

export default Timepicker;
