import styled, { css } from 'styled-components';
import { Icon } from 'components';
import { tButton } from './Timepicker';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex: 0 1 100%;
`;

export const RelativeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
`;

export const WithIcon = styled(Icon)`
  position: absolute;
  fill: var(--theme-mainGrey);
  top: 0.25rem;
  right: 0.5rem;
`;

export const TimepickerContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: min-content;
  margin-top: 0.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 0.5rem;
  background: var(--theme-white);
  box-shadow: var(--theme-mainShadow);
  z-index: var(--theme-dropdownZIndex);
`;

export const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60px;
  height: 270px;
  overflow-x: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const FlexHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ButtonStyle = styled.button<tButton>`
  display: block;
  min-width: 60px;
  min-height: 27px;
  height: 27px;
  padding: 0;
  padding-right: 0.75rem;
  outline: none;
  border: none;
  cursor: pointer;
  background: none;

  font-size: 0.75rem;
  &:hover {
    background: var(--theme-transparent40Blue);
    color: var(--theme-white);
  }
  ${({ selected }) =>
    selected &&
    css`
      background: var(--theme-mainBlue);
      color: white;
      &:hover {
        background: var(--theme-mainBlue);
      }
    `}
`;

export const BottomContainer = styled(FlexHorizontal)`
  width: min-content;
  margin-top: 0.375rem;
  padding-top: 0.5rem;
  padding-bottom: 0.25rem;
  border-top: 1px solid var(--theme-mainGrey);
`;

export const NowButtonStyle = styled.button`
  height: 23px;
  padding: 0px;
  margin-left: 0px;
  margin-right: 0.5rem;
  background: transparent;
  outline: 0;
  border: 0;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
  font-size: 0.625rem;
  letter-spacing: 0.016rem;
  font-weight: bold;
`;

export const OkButtonStyle = styled(NowButtonStyle)`
  width: 48px;
  margin-left: 0.5rem;
  margin-right: 0;
  border-radius: 1rem;
  color: var(--theme-white);
  background: var(--theme-gradientBlue);
`;
