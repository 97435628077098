/* eslint-disable camelcase */
import applianceTree from 'agents/applianceTree';
import contractsService from 'services/contracts';
import { AxiosResponse } from 'axios';
import subcontractors from 'agents/subcontractors';
import users from 'agents/users';
import { kindSubcontractor, representativeRoleUUID } from 'utils/constants';
import { tGetUserAPI } from 'types/api/users';
import { tWorker } from 'types/services/users';
import { tOption, tGet, tUUIDName } from 'types/global';
import { tGetAllByGet, tGetAllByPost } from 'types/services/issues';
import {
  tCombinedRadiusPolygonArea,
  tPolygonAreaData,
  tRoundAreaData,
  tSubcontractorListItem,
  tSubcontractorListItemAPI,
  tSubcontractorContractGetAPI,
  tSubcontractorContractCreateUpdate,
  tSubcontractorGetAPI,
  tSubcontractorPostAPI,
  tSubcontractorPatchAPI,
  tGetAllSubcontractorContractsByGet,
  tRepresentative,
  tGetAreasData,
  tSubcontractorContractFormValues,
} from 'types/services/subcontractors';
import { formatDate } from 'utils/helpers';
import { tAttachmentsToDelete } from 'types/views/contracts';
import { tFilterItem } from './issues';

const getFilters = async () => {
  const industries = await applianceTree.getIndustries();
  const { data, status } = industries;
  return {
    data: {
      count: 1,
      results: {
        industries: data.results,
      },
    },
    status,
  };
};

const getAll = async ({
  offset = 0,
  limit,
  ordering = { by: '', order: '' },
  search,
  filters,
  archived,
}) => {
  const params: tGetAllByGet & { industry_ids?: string } = { offset };
  const industryIds = filters?.industries
    ? filters.industries.map((item: tFilterItem) => item.value).join(',')
    : '';

  if (limit) params.limit = limit;
  if (offset) params.offset = offset;
  if (!!ordering?.by) params.sort = ordering.by;
  if (!!ordering?.order) params.order = ordering.order;
  if (search) params.search = search;
  if (industryIds) params.industry_ids = industryIds;
  if (typeof archived !== undefined) params.archived = archived;

  const response: AxiosResponse<{
    count: number;
    results: tSubcontractorListItemAPI[];
  }> = await subcontractors.getAll(params);

  const {
    data: { results, count },
  } = response;

  const mappedResults: tSubcontractorListItem[] = results.map((result, index) => {
    const { name, nip, industries = [], number_of_active_contracts, id, areas = [] } = result;

    return {
      order: index + 1 + offset,
      name,
      nip,
      industries: industries.map((industry) => industry.name),
      activeContracts: number_of_active_contracts,
      workingArea: areas.map((area) => area.name),
      id,
    };
  });

  const apiFilters = await getFilters();
  const {
    data: { results: filtersToData },
  } = apiFilters;

  return {
    data: {
      count,
      results: mappedResults,
      filters: filtersToData,
    },
  };
};

const get = async ({ id: subcontractorId }: tGet) => {
  const response: AxiosResponse<tSubcontractorGetAPI> = await subcontractors.get(subcontractorId);
  const { data, status }: { data: tSubcontractorGetAPI; status: number } = response;
  const {
    address: { city, street, building_number, postal_code, region },
    company_size,
    archived,
    name,
    phone_number,
    regon,
    web_page,
    sap_number,
    legal_form,
    vat_payer,
    industries,
    nip,
    contact_person,
    representative,
  } = data;
  const result = {
    archived,
    name,
    regon,
    nip,
    sapNumber: sap_number,
    webPage: web_page,
    city,
    street,
    buildingNumber: building_number,
    postCode: postal_code,
    companyClassification: {
      value: company_size?.id,
      label: company_size?.name,
    },
    phone: phone_number,

    activeVATPayer: vat_payer,
    industry: industries?.length
      ? industries.map((industry) => {
          const { id, name } = industry;
          return {
            id,
            label: name,
            value: name,
          };
        })
      : [],
    region: {
      value: region?.id,
      label: region?.name,
    },
    legalForm: {
      value: legal_form?.id,
      label: legal_form?.name,
    },
    country: {
      value: region?.country?.id,
      label: region?.country?.name,
    },
    contactPerson: contact_person,
    representative: {
      value: representative?.id,
      label: `${representative?.first_name} ${representative?.last_name}`,
    },
  };
  return {
    data: result,
    status,
  };
};

const edit = async (subcontractorId: string, dataToEdit: tSubcontractorPostAPI) => {
  const response: AxiosResponse<tSubcontractorGetAPI> = await subcontractors.edit(
    subcontractorId,
    dataToEdit,
  );
  const { data, status } = response;
  return {
    data: { id: data?.id },
    status,
  };
};

const patchSubcontractorRepresentative = async (
  subcontractorId: string,
  dataToEdit: tSubcontractorPatchAPI,
) => {
  const response: AxiosResponse<tSubcontractorGetAPI> = await subcontractors.patch(
    subcontractorId,
    dataToEdit,
  );
  const { data, status } = response;

  return {
    data: { id: data?.id },
    status,
  };
};

const create = async (dataToCreate: tSubcontractorPostAPI) => {
  const response: AxiosResponse<tSubcontractorGetAPI> = await subcontractors.create(dataToCreate);
  const { data, status } = response;
  return {
    data: { id: data?.id },
    status,
  };
};

const getCompanySizes = async () => {
  const response: AxiosResponse = await subcontractors.getCompanySizes();
  const results = response?.data?.results?.length
    ? response.data.results.map((item: { id: number; name: string }) => {
        const { id, name } = item;
        return {
          value: id,
          label: name,
        };
      })
    : [];

  return { data: { results } };
};

const getAreas = async ({ id: subcontractorId }: tGet) => {
  const response: AxiosResponse = await subcontractors.getAreas(subcontractorId);
  const {
    data: { areas, kind },
  }: {
    data: { areas: Array<tCombinedRadiusPolygonArea>; kind: 'polygons' | 'radius' };
  } = response;

  const GPSRadiusLocations = areas.map((point: tCombinedRadiusPolygonArea) => ({
    location: null,
    name: null,
    id: point.id,
  }));

  if (kind === 'radius') {
    return {
      kind,
      radius: areas[0].radius,
      GPSLocations: GPSRadiusLocations,
      center: areas[0].center,
    };
  }
  const GPSLocations = areas.map((point: tCombinedRadiusPolygonArea) => {
    const locations = !!point?.contours
      ? point?.contours?.map((contour) => ({
          lat: contour?.latitude,
          lng: contour?.longitude,
        }))
      : [];
    return {
      locations,
      name: point.name ?? '',
      id: point.id,
      localId: point.id,
    };
  });

  const returnData: tGetAreasData = {
    kind,
    radius: 0,
    GPSLocations,
    center: {},
  };

  return {
    data: returnData,
  };
};

const addEditRadiusArea = async (subcontractorId: string, roundAreaData: tRoundAreaData) => {
  const response: AxiosResponse<tCombinedRadiusPolygonArea> = await subcontractors.createArea(
    subcontractorId,
    roundAreaData,
  );
  const { status } = response;
  return {
    status,
  };
};

const addEditPolygonArea = async (subcontractorId: string, polygonAreaData: tPolygonAreaData[]) => {
  const response: AxiosResponse = await subcontractors.createArea(subcontractorId, polygonAreaData);
  return response;
};

const getRepresentativesOptions = async ({ contractorId }) => {
  const response: AxiosResponse = await users.getAll({
    role_id: representativeRoleUUID,
    kind: 'subcontractor',
    company_id: contractorId,
  });

  const results: tOption[] = response?.data?.results?.length
    ? response.data.results.map((item: { id: string; first_name: string; last_name: string }) => {
        const { id, first_name, last_name } = item;
        return {
          value: id,
          label: `${first_name} ${last_name}`,
        };
      })
    : [];

  return { data: { results } };
};

const getWorker = async ({ id }: tGet) => {
  const result: AxiosResponse<tGetUserAPI> = await users.get(id);

  const {
    data: {
      avatar,
      personal_id_number: workerPersonalIdNumber,
      email: workerEmail,
      first_name: workerName,
      last_name: workerSurname,
      phone_number: workerPhoneNumber,
      position: workerPosition,
    },
  } = result;

  let workerPhoto = '';

  if (!!avatar) {
    const { content, filename: fileName = 'avatar.jpg' } = avatar;
    const avatarContent: string = content;
    const separatedAvatarNameStrings: string[] = fileName.split('.');
    const avatarExtension: string =
      separatedAvatarNameStrings[separatedAvatarNameStrings.length - 1];
    workerPhoto = `data:image/${avatarExtension};base64,${avatarContent}`;
  }

  const returnData: tWorker = {
    workerEmail,
    workerName,
    workerPhoneNumber,
    workerPhoto,
    workerPosition,
    workerSurname,
    workerPersonalIdNumber,
  };

  return { data: returnData };
};

const getRepresentative = async ({ id }: tGet) => {
  const result = await users.get(id);

  const {
    data: {
      avatar,
      company,
      first_name: firstName,
      last_name: lastName,
      personal_id_number: personalIdNumber,
      phone_number: phone,
      email,
      position,
      supervisors,
    },
  } = result;

  let userPhoto = '';

  if (!!avatar) {
    const { content, filename: fileName = 'avatar.jpg' } = avatar;
    const avatarContent: string = content;
    const separatedAvatarNameStrings: string[] = fileName.split('.');
    const avatarExtension: string =
      separatedAvatarNameStrings[separatedAvatarNameStrings.length - 1];

    userPhoto = `data:image/${avatarExtension};base64,${avatarContent}`;
  }

  const supervisor = supervisors[0] ?? {};
  const {
    id: supervisorId,
    first_name: supervisorFirstName,
    last_name: supervisorLastName,
  } = supervisor;

  const returnData: tRepresentative = {
    avatar: userPhoto,
    employer: company?.id && company?.name ? { value: company.id, label: company.name } : null,
    firstName,
    lastName,
    personalIdNumber,
    phone,
    email,
    position,
    supervisor:
      supervisorId && supervisorFirstName && supervisorLastName
        ? { value: supervisorId, label: `${supervisorFirstName} ${supervisorLastName}` }
        : null,
  };

  return { data: returnData };
};

const getEmployers = async () => {
  const response: AxiosResponse = await subcontractors.getAll({});
  const results = response?.data?.results?.length
    ? response.data.results.map((item: tUUIDName) => {
        const { id, name } = item;
        return {
          value: id,
          label: name,
        };
      })
    : [];

  return { data: { results } };
};

export type tGetSupervisorsParams = { companyId: string };

const getSupervisors = async ({ companyId }: tGetSupervisorsParams) => {
  const response: AxiosResponse = await users.getAll({
    company_id: companyId,
  });
  const results = response?.data?.results?.length
    ? response.data.results.map((item: { id: string; first_name: string; last_name: string }) => {
        const { id, first_name, last_name } = item;
        return {
          value: id,
          label: `${first_name} ${last_name}`,
        };
      })
    : [];

  return { data: { results } };
};

const getSubcontractorContractFilters = async () => {
  const filters: AxiosResponse = await subcontractors.getFilters();

  const { data, status } = filters;
  return {
    data: {
      count: 1,
      results: {
        clientName: data.client_contract_clients,
      },
    },
    status,
  };
};

export type tSubcontractorContractRow = {
  id: number;
  order: number;
  clientName: string;
  contractName: string;
  status: string;
  contractExpirationDate: string | null;
};

const getAllContracts = async ({
  offset = 0,
  limit,
  ordering = { by: '', order: '' },
  search,
  filters,
}: tGetAllByPost) => {
  const params: tGetAllSubcontractorContractsByGet = { offset };
  const clientsIds = filters?.clientName
    ? filters.clientName.map((item: tFilterItem) => item.value).join(',')
    : '';

  if (limit) params.limit = limit;
  if (offset) params.offset = offset;
  if (search) params.search = search;
  if (!!ordering?.by) params.sort = ordering.by;
  if (!!ordering?.order) params.order = ordering.order;
  if (clientsIds) params.client_contract_client_ids = clientsIds;

  const response: AxiosResponse<{
    count: number;
    results: tSubcontractorContractGetAPI[];
  }> = await subcontractors.getAllContracts(params);

  const {
    data: { results, count },
  } = response;

  const mappedResults: tSubcontractorContractRow[] = results.map((result, index) => {
    const {
      name,
      client_contract: { client },
      status,
      end,
      id,
    } = result;

    return {
      id,
      order: index + 1 + offset,
      clientName: client?.name,
      contractName: name,
      status,
      contractExpirationDate: !!end ? formatDate(new Date(end)) : null,
    };
  });

  const apiFilters = await getSubcontractorContractFilters();
  const {
    data: { results: filtersToData },
  } = apiFilters;

  return {
    data: {
      count,
      results: mappedResults,
      filters: filtersToData,
    },
  };
};

export type tGetSubcontractorContractParams = { contractId: string | number };

const getSubcontractorContract = async ({ contractId }: tGetSubcontractorContractParams) => {
  const response: AxiosResponse<tSubcontractorContractGetAPI> = await subcontractors.getContract(
    contractId,
  );
  const { data, status } = response;
  const {
    id,
    name,
    client_contract: { client, name: clientsContractName, id: clientsContractId },
    subcontractor,
    start,
    end,
    contract_attachment: contractContent,
    attachments,
    financial_conditions,
  } = data;

  const {
    vat_rate,
    payment_due_date,
    payment_due_rule,
    billing_interval,
    billing_interval_unit,
    billing_period_days,
  } = financial_conditions;

  const returnData: tSubcontractorContractFormValues = {
    id,
    contractsName: name,
    clientsName: {
      label: client?.name,
      value: client?.id,
    },
    subcontractorName: {
      label: subcontractor?.name,
      value: subcontractor?.id,
    },
    clientsContractName: {
      label: clientsContractName,
      value: clientsContractId,
    },
    contractDuration: !!start ? new Date(start) : null,
    contractDurationEnd: !!end ? new Date(end) : null,
    attachments,
    contractContent: !!contractContent ? [contractContent] : [],
    VATRate: {
      label: vat_rate,
      value: vat_rate,
    },
    paymentDeadline: {
      value: payment_due_date,
      optionLabel: payment_due_rule,
    },
    invoicingInterval: {
      value: billing_interval,
      optionLabel: billing_interval_unit,
    },
    billingPeriodTo: billing_period_days,
  };

  return {
    data: returnData,
    status,
  };
};

export type tSubcontractorContractStatus = {
  id: number;
  status: string;
};

const getStatus = async ({ id }: tGet) => {
  const response: AxiosResponse<tSubcontractorContractGetAPI> = await subcontractors.getContract(
    id,
  );
  const { id: contractId, status } = response.data;
  const responseData: tSubcontractorContractStatus = { id: contractId, status };
  return { data: responseData };
};

const createSubcontractorContract = async (data: tSubcontractorContractCreateUpdate) => {
  const response: AxiosResponse = await subcontractors.createContract(data);
  const contractId: number = response?.data?.id;
  const isSubcontractorContract = true;
  await contractsService.sendAttachments(contractId, data, isSubcontractorContract);

  return { response, error: null };
};

const editSubcontractorContract = async (
  id: number,
  data: tSubcontractorContractCreateUpdate,
  attachmentsToDelete: tAttachmentsToDelete,
) => {
  const response: AxiosResponse = await subcontractors.editContract(id, data);
  const contractId: number = response?.data?.id;
  const isSubcontractorContract = true;
  await contractsService.sendAttachments(contractId, data, isSubcontractorContract);

  let error = '';
  if (!!Object.keys(attachmentsToDelete).length) {
    const deletingResponse = await contractsService.deleteAllAttachments(
      contractId,
      attachmentsToDelete,
    );
    if (!!deletingResponse) error = deletingResponse;
  }
  return { response, error };
};

const patchSubcontractorContract = (id: number, data: { status: string }) =>
  subcontractors.patchContract(id, data);

const getSubcontractorTechnicians = async ({ userCompanyId }: { userCompanyId: string }) => {
  const params: { company_id?: string; kind: string } = { kind: kindSubcontractor };

  if (userCompanyId) params.company_id = userCompanyId;

  const response: AxiosResponse = await users.getAll(params);
  const {
    data: { results },
  } = response;
  const optionsData: tOption[] = results?.map((tech) => ({
    value: tech.id,
    label: `${tech.first_name} ${tech.last_name}`,
  }));
  return { data: { results: optionsData } };
};

export default {
  getAll,
  getWorker,
  get,
  create,
  edit,
  getCompanySizes,
  getRepresentative,
  getEmployers,
  getSupervisors,
  getAllContracts,
  getSubcontractorContract,
  getStatus,
  createSubcontractorContract,
  editSubcontractorContract,
  patchSubcontractorContract,
  getAreas,
  addEditRadiusArea,
  addEditPolygonArea,
  getRepresentativesOptions,
  patchSubcontractorRepresentative,
  getSubcontractorTechnicians,
};
