const NOTIFICATION_CREATE = 'NOTIFICATION_CREATE';
const NOTIFICATION_REMOVE = 'NOTIFICATION_REMOVE';
const NOTIFICATION_REMOVE_ALL = 'NOTIFICATION_REMOVE_ALL';
const NOTIFICATION_TIMEOUT = 'NOTIFICATION_TIMEOUT';
const NOTIFICATION_POSITION = 'NOTIFICATION_POSITION';

export default {
  NOTIFICATION_CREATE,
  NOTIFICATION_REMOVE,
  NOTIFICATION_REMOVE_ALL,
  NOTIFICATION_TIMEOUT,
  NOTIFICATION_POSITION,
};
