import styled, { css } from 'styled-components';

export const Absolute = styled.div`
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

export type tRelative = {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
};

export const RelativeWithOffsets = styled.div<tRelative>`
  position: relative;
  ${({ top }) =>
    top &&
    css`
      top: ${top}px;
    `};
  ${({ bottom }) =>
    bottom &&
    css`
      bottom: ${bottom}px;
    `};
  ${({ left }) =>
    left &&
    css`
      left: ${left}px;
    `}
  ${({ right }) =>
    right &&
    css`
      right: ${right}px;
    `}
`;

export const BorderGap = styled.div`
  border-bottom: 1px solid var(--theme-mainGrey);
`;

export const CenterWithGaps = styled.div`
  margin-bottom: 2rem;
  margin-top: 1.5rem;
  text-align: center;
`;

export const CustomLabelTitle = styled.div`
  color: var(--theme-mainGrey);
  font-size: 0.75rem;
  margin-right: 1.5rem;
  text-align: right;
`;

export type tFlex = {
  flexDirection?: 'column' | 'row';
  alignItems?: 'flex-start' | 'center' | 'flex-end';
  justifyContent?: 'flex-start' | 'center' | 'flex-end';
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
};

export const Flex = styled.div<tFlex>`
  display: flex;
  ${({ flexDirection }) =>
    flexDirection &&
    css`
      flex-direction: ${flexDirection};
    `};
  ${({ alignItems }) =>
    alignItems &&
    css`
      align-items: ${alignItems};
    `};
  ${({ justifyContent }) =>
    justifyContent &&
    css`
      justify-content: ${justifyContent};
    `};
  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop};
    `};
  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom};
    `};
  ${({ marginLeft }) =>
    marginLeft &&
    css`
      margin-left: ${marginLeft};
    `};
  ${({ marginRight }) =>
    marginRight &&
    css`
      margin-right: ${marginRight};
    `};
`;

export const FlexV = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexHWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const FormWrapper = styled.div`
  padding: 0 3rem 0 1rem;
`;

export const AlignTextLeft = styled.div`
  text-align: left;
`;

export const GapLine = styled.div`
  border-bottom: 1px solid var(--theme-mainGrey);
  margin: 1rem 0 1rem 0;
  width: 100%;
`;

export const ClearButton = styled.button`
  background: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
`;

export const LeftMargin1Rem = styled.div`
  margin-left: 1rem;
`;

export const HorizontalMarginSmall = styled.div`
  margin: 0 0.5rem;
`;

export const TopMargin1Rem = styled.div`
  margin-top: 1rem;
`;

export const BottomMargin1Rem = styled.div`
  margin-bottom: 1rem;
`;

export const Relative = styled.div`
  position: relative;
`;

export const CenteredFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Left = styled.div`
  width: 40vw;
  min-width: 32rem;
  display: flex;
  flex-direction: column;
`;

export const Right = styled.div`
  width: 40vw;
  min-width: 32rem;
  margin-left: 2.5rem;
  position: sticky;
  top: 0;
  height: 90vh;
  z-index: 10;
  overflow: auto;
`;

export const Title = styled.p`
  font-size: 1.5rem;
  margin-top: 1.625rem;
  margin-bottom: 1.875rem;
  font-weight: 900;
`;

export const Subtitle = styled.p`
  font-size: 1.125rem;
  font-weight: 900;
`;

export const ExpansionPanelWrapper = styled.div`
  background: var(--theme-white);
  padding: 1.625rem 2rem;
  border-radius: 8px;
  margin-bottom: 2rem;
`;

export const ListTableContainer = styled.div`
  margin-top: 2.5rem;
`;

export const ListBreadcrumb = styled.div`
  margin-bottom: 1.5rem;
`;

export const ButtonsFormContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  text-align: right;
`;

export const ModalText = styled.p`
  font-size: 0.75rem;
  margin-bottom: 1rem;
`;

export const WrapperWithBottomGap = styled.div`
  margin-bottom: 1rem;
`;
