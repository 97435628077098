import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Spinner, Icon } from 'components';
import { colorGreyBasic } from 'styles/GlobalStyles';
import {
  Title,
  ModalContainer,
  ModalContent,
  ButtonsContainer,
  LineGap,
  TitleWrapper,
  TextWrapper,
  InnerButtonWrapper,
} from './Modal.style';

export type tConfirmVariants = 'green' | 'redFilled';

type tModalConfirm = {
  children: React.ReactNode | JSX.Element[] | JSX.Element | string;
  title: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  cancelMethod?: () => void;
  confirmMethod?: () => void;
  confirmDisabled?: boolean;
  confirmVariant?: tConfirmVariants;
  shouldCloseWhenClickedOutside?: boolean;
  loading?: boolean;
  icon: string;
  width?: string;
  showOnlyCancel?: boolean;
  showOnlyConfirm?: boolean;
  textWrapperStyle?: React.CSSProperties;
};

const Modal: React.FC<tModalConfirm> = ({
  children,
  title,
  cancelButtonText,
  confirmButtonText,
  cancelMethod = () => {},
  confirmMethod = () => {},
  confirmDisabled = false,
  confirmVariant = 'green',
  shouldCloseWhenClickedOutside = true,
  loading = false,
  icon,
  width = '600px',
  showOnlyCancel = false,
  showOnlyConfirm = false,
  textWrapperStyle,
}): JSX.Element => {
  const { t } = useTranslation();
  const refContainer = useRef() as React.MutableRefObject<HTMLInputElement>;
  const refContent = useRef() as React.MutableRefObject<HTMLInputElement>;
  const onClick = (e: React.MouseEvent) => {
    if (
      shouldCloseWhenClickedOutside &&
      refContainer.current.contains(e.target as HTMLElement) &&
      !refContent.current.contains(e.target as HTMLElement)
    ) {
      cancelMethod();
    }
  };

  return (
    <ModalContainer ref={refContainer} onClick={onClick}>
      <ModalContent ref={refContent} width={width} data-testid='modal-content'>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <TitleWrapper>
              <Icon icon={icon} fill={colorGreyBasic} />
              <Title>{title}</Title>
            </TitleWrapper>
            <LineGap />
            <TextWrapper style={textWrapperStyle}>{children}</TextWrapper>
            <LineGap />
            <ButtonsContainer>
              {!showOnlyConfirm && (
                <InnerButtonWrapper>
                  <Button
                    variant='red'
                    kind='outlined'
                    onClick={cancelMethod}
                    data-testid='modal-cancel-button'
                  >
                    {cancelButtonText || t('Cancel')}
                  </Button>
                </InnerButtonWrapper>
              )}
              {!showOnlyCancel && (
                <Button
                  variant={confirmVariant}
                  onClick={confirmMethod}
                  disabled={confirmDisabled}
                  data-testid='modal-confirm-button'
                >
                  {confirmButtonText || t('Confirm')}
                </Button>
              )}
            </ButtonsContainer>
          </>
        )}
      </ModalContent>
    </ModalContainer>
  );
};

export default Modal;
