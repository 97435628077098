import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, SimpleDialog, Checkbox } from 'components';
import { FlexV } from 'styles/GlobalStyledComponents';
import File from './File';
import Folder from './Folder';
import {
  ActionDepthComponent,
  ActionComponent,
  ExtraInfo,
  StyledTree,
  TreeItemActionButton,
  TreeViewHeader,
  TreeViewTitle,
} from './TreeView.style';

export type tTreeType = {
  type?: 'tree' | 'location';
};

type tTreeView = tTreeType & {
  data?: any[];
  extraInfo?: string;
  mainItemActions?: any[];
  title: string;
  topLevelActionComponent: JSX.Element | null;
  depthActionComponent?: JSX.Element | null;
  itemsActions?: any[];
  isOpen?: boolean;
  edit?: Function | null;
  rootId?: string;
  isRootChecked?: boolean;
  isRootHalfChecked?: boolean;
  setRootChecked?: (rootId: string) => void | null;
};

export type tFolderStyles = {
  isOpen: boolean;
};

const TreeView: React.FC<tTreeView> = ({
  data = [],
  extraInfo = '',
  mainItemActions = [],
  title = '-',
  topLevelActionComponent = null,
  depthActionComponent = null,
  itemsActions = [],
  type = 'tree',
  isOpen = false,
  edit = null,
  rootId,
  isRootChecked = false,
  isRootHalfChecked = false,
  setRootChecked = null,
}): JSX.Element => {
  const { t } = useTranslation();
  const renderActions = (actions: any[], id: number | null, name = '' as string) => {
    if (!actions.length) return null;

    return actions.map((action) => {
      const { fill, icon, key, onClick } = action;

      if (icon === 'close') {
        return (
          <SimpleDialog
            action={() => onClick(id, name)}
            type='remove'
            copy={`${t('Are you sure you want to delete')}${name ? ` "${name}"` : ''}?`}
            key={key}
          >
            <TreeItemActionButton>
              <Icon width='1rem' height='1rem' fill={fill} icon={icon} />
            </TreeItemActionButton>
          </SimpleDialog>
        );
      }

      return (
        <TreeItemActionButton onClick={() => onClick(id, name)} key={key}>
          <Icon width='1rem' height='1rem' fill={fill} icon={icon} />
        </TreeItemActionButton>
      );
    });
  };

  const editElement = (item) => {
    if (!!item.edit) return item.edit;
    if (edit instanceof Function) return () => edit(item);
    return null;
  };

  const renderTree = (parsedTreeData: any) => {
    return parsedTreeData.map((item: any) => {
      return !!item.children.length || (item.allowAction && type === 'tree') ? (
        <Folder
          hasParent={!!item.parent}
          key={item.key}
          name={item.name}
          renderActions={renderActions(itemsActions, item.id, item.name)}
          edit={editElement(item)}
          type={type}
          isOpen={isOpen}
        >
          {renderTree(item.children)}
          {item.allowAction && (
            <>
              {typeof item.add === 'function' && (
                <ActionDepthComponent>{item.add()}</ActionDepthComponent>
              )}
              {!!depthActionComponent && (
                <ActionDepthComponent>depthActionComponent</ActionDepthComponent>
              )}
            </>
          )}
        </Folder>
      ) : (
        <Fragment key={item.key}>
          <File
            hasParent={!!item.parent}
            name={item.name}
            renderActions={renderActions(itemsActions, item.id, item.name)}
            edit={editElement(item)}
            type={type}
          />
          {item.allowAction && typeof item.add === 'function' && (
            <ActionDepthComponent>{item.add()}</ActionDepthComponent>
          )}
        </Fragment>
      );
    });
  };

  const setRootCheckboxHandler = () => {
    if (!!setRootChecked && !!rootId) {
      setRootChecked(rootId);
    }
  };

  return (
    <FlexV>
      <TreeViewHeader>
        {setRootChecked && (
          <Checkbox
            onChange={setRootCheckboxHandler}
            name='rootCheckbox'
            errors={[]}
            checked={isRootChecked}
            halfCheck={isRootHalfChecked && !isRootChecked}
          />
        )}
        <TreeViewTitle>
          {title}
          {!!extraInfo?.length && <ExtraInfo>{extraInfo}</ExtraInfo>}
          {!!mainItemActions?.length && renderActions(mainItemActions, null)}
        </TreeViewTitle>
      </TreeViewHeader>
      {!data.length ? null : <StyledTree type={type}>{renderTree(data)}</StyledTree>}
      {!!topLevelActionComponent && <ActionComponent>{topLevelActionComponent}</ActionComponent>}
    </FlexV>
  );
};

export default TreeView;
