import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { tError } from 'types/global';
import { dateToDashDDMMYYYY } from 'utils/helpers';
import { tCustomInputEvent } from 'hooks/useForm';
import { useOutsideClickAction, useRect, useFormErrorsReset } from 'hooks';
import { colorRedBasic } from 'styles/GlobalStyles';

import { Label, Calendar, Button, Checkbox, FormErrorMessage, Icon } from 'components';

import {
  Wrapper,
  IconStyle,
  DisabledIconStyle,
  ErrorIconStyle,
  PositioningContainer,
  InputContainer,
  DateOutputContainer,
  DatepickerContainer,
  TitleContainer,
  TitleStyle,
  MiddleContainer,
  CalendarContainer,
  CheckboxContainer,
  FooterContainer,
  ButtonContainer,
} from './Datepicker.styles';

const addMonths = (date, months) => new Date(date.getFullYear(), date.getMonth() + months, 1);

export type tInputContainer = {
  selected: boolean;
  clickable: boolean;
  error: boolean;
};

export type tOffset = {
  offset?: string;
};

export type tDatepickerCustomEventValues = {
  start: Date | null;
  end?: Date | null;
  indefinitely?: boolean;
};

export type tDatepicker = {
  name: string;
  label?: string;
  title?: string;
  variant?: 'single' | 'range';
  start?: Date;
  end?: Date;
  min?: Date;
  max?: Date;
  noEndDateAllowed?: Boolean;
  indefinitely?: boolean;
  onChange?: (e: tCustomInputEvent) => void;
  disableFilter?: (val: Date) => boolean;
  preview?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  hideIcon?: boolean;
  allowDelete?: boolean;
  errors?: tError[];
  labelMinWidth?: number;
  noFlip?: boolean;
  flip?: boolean;
  labelOptional?: boolean;
};

const noError: tError[] = [];

const Datepicker: React.FC<tDatepicker> = ({
  name,
  label = '',
  title = '',
  variant = 'single',
  start,
  end,
  min,
  max,
  noEndDateAllowed = false,
  indefinitely = false,
  onChange = () => {},
  disableFilter,
  disabled = false,
  readOnly = false,
  hideIcon = false,
  allowDelete = false,
  errors = noError,
  labelMinWidth = 90,
  noFlip = false,
  flip = false,
  labelOptional = false,
}) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<boolean>(false);
  const [startInput, setStartInput] = useState<string>('');
  const [endInput, setEndInput] = useState<string>(indefinitely ? t('Indefinitely') : '');
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [primaryFocus, setPrimaryFocus] = useState<Date>(start ?? new Date());
  const [secondaryFocus, setSecondaryFocus] = useState<Date>(addMonths(start ?? new Date(), 1));
  const [isIndefinitely, setIndefinitely] = useState<boolean>(indefinitely);
  const [toggle, setToggle] = useState<boolean>(false);
  const [hasError, setHasErrors] = useState<boolean>(false);

  const datepickerContainerRef = useRef(null);
  const inputContainerRef = useRef(null);
  const inputRect = useRect(inputContainerRef);

  const isRange = variant === 'range';
  const offsetValue = isRange ? '-25rem' : '-13rem';
  const offsetThreshold = isRange ? 650 : 350;
  const offset = window.innerWidth - inputRect.x < offsetThreshold || flip ? offsetValue : '0';

  const { formElemErrors, resetFormError } = useFormErrorsReset(errors);

  const handleChange = (customEvent: tCustomInputEvent) => {
    resetFormError(customEvent?.name);
    onChange(customEvent);
    setHasErrors(false);
  };

  useEffect(() => {
    const hasError = errors.map((error) => error.field).includes(name);
    setHasErrors(hasError);
  }, [errors]);

  const resetValues = () => {
    setSelected(false);
    setStartInput('');
    setStartDate(new Date());
    setEndDate(new Date());
  };

  useEffect(() => {
    setVisible(false);
    setToggle(false);
    if (start) {
      setStartInput(dateToDashDDMMYYYY(start));
      setStartDate(start);
      setPrimaryFocus(start);
      setSelected(true);
      setIndefinitely(indefinitely);
    } else {
      resetValues();
    }
    if (end && !indefinitely) {
      setEndInput(dateToDashDDMMYYYY(end));
      setEndDate(end);
      setSelected(true);
    }
    if (indefinitely && start) {
      setEndDate(start);
      setIndefinitely(indefinitely);
    }
    if (
      start &&
      end &&
      start.getFullYear === end.getFullYear &&
      start.getMonth() <= end.getMonth()
    ) {
      setSecondaryFocus(addMonths(start, 1));
    } else if (end) {
      setSecondaryFocus(end);
    }
  }, [start, end, variant, noEndDateAllowed, indefinitely]);

  const scrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  const inputClickHandler = () => {
    if (!disabled) {
      setVisible(true);
      scrollToRef(inputContainerRef);
    }
  };

  const primaryCalendarClickHandler = (date) => {
    if (isIndefinitely && isRange) {
      setStartDate(date);
      setEndDate(date);
    } else if (isRange) {
      if (date.getTime() < startDate.getTime()) {
        setStartDate(date);
        setEndDate(date);
        setToggle(true);
      } else if (!toggle) {
        setStartDate(date);
        setEndDate(date);
        setToggle(true);
      } else {
        setEndDate(date);
        setToggle(false);
      }
    } else {
      setStartDate(date);
    }
  };

  const secondaryCalendarClickHandler = (date) => {
    if (isIndefinitely && isRange) {
      setStartDate(date);
      setEndDate(date);
      setPrimaryFocus(addMonths(date, 0));
      setSecondaryFocus(addMonths(date, 1));
    } else {
      if (date.getTime() <= startDate.getTime()) {
        setStartDate(date);
      }
      setEndDate(date);
    }
  };

  const primaryCalendarFocusHandler = (date) => {
    setPrimaryFocus(date);
    if (isIndefinitely || date.getTime() >= secondaryFocus.getTime()) {
      setSecondaryFocus(addMonths(date, 1));
    }
  };

  const secondaryCalendarFocusHandler = (date) => {
    setSecondaryFocus(date);
    if (date.getTime() <= primaryFocus.getTime()) {
      setPrimaryFocus(addMonths(date, -1));
    }
  };

  const checkboxHandler = () => {
    setIndefinitely(!isIndefinitely);
    setEndDate(startDate);
    setPrimaryFocus(addMonths(startDate, 0));
    setSecondaryFocus(addMonths(startDate, 1));
  };

  const saveButtonHandler = () => {
    const startStr = dateToDashDDMMYYYY(startDate);
    const endStr = dateToDashDDMMYYYY(endDate);

    const customValues: tDatepickerCustomEventValues = {
      start: startDate,
    };

    if (isRange) {
      customValues.indefinitely = isIndefinitely;
      customValues.end = isIndefinitely ? startDate : endDate;
      if (isIndefinitely) {
        setStartInput(startStr);
        setEndInput('Indefinitely');
      } else {
        setStartInput(startStr);
        setEndInput(endStr);
      }
    } else {
      setStartInput(startStr);
    }

    const customEvent = {
      name,
      type: 'date',
      custom: true,
      value: customValues,
    };
    handleChange(customEvent);

    setSelected(true);
    setVisible(false);
  };

  const closeButtonHandler = () => {
    setVisible(false);
  };

  const deleteDateHandler = () => {
    const customValues: tDatepickerCustomEventValues = {
      start: null,
    };
    if (isRange) customValues.end = null;

    const customEvent = {
      name,
      type: 'date',
      custom: true,
      value: customValues,
    };
    handleChange(customEvent);

    setSelected(true);
    setVisible(false);
  };

  useOutsideClickAction(() => setVisible(false), datepickerContainerRef, inputContainerRef);

  const renderIcon = (disabled: boolean, hasError: boolean) => {
    if (hideIcon) return null;
    if (disabled) return <DisabledIconStyle icon='dateRange' />;
    if (hasError) return <ErrorIconStyle icon='dateRange' />;
    return <IconStyle icon='dateRange' />;
  };

  const showAllowDeleteIcon = allowDelete && !readOnly && !disabled && (start || end);

  return (
    <Wrapper>
      {label && (
        <Label optional={labelOptional} labelMinWidth={labelMinWidth}>
          {t(label)}
        </Label>
      )}
      <PositioningContainer>
        <InputContainer
          selected={selected && !disabled}
          error={hasError}
          clickable={!disabled}
          onClick={inputClickHandler}
          ref={inputContainerRef}
        >
          {renderIcon(disabled, hasError)}
          <DateOutputContainer preview={hideIcon}>
            {isRange
              ? `${t(startInput) || t('Select date')} — ${t(endInput) || t('Select date')}`
              : t(startInput) || t('Select date')}
          </DateOutputContainer>
          {showAllowDeleteIcon && (
            <Icon icon='close' fill={colorRedBasic} asButton onClick={deleteDateHandler} />
          )}
          <input
            id={`${name}Start`}
            name={`${name}Start`}
            value={startInput}
            readOnly
            type='hidden'
          />
          {isRange && (
            <input id={`${name}End`} name={`${name}End`} value={endInput} type='hidden' readOnly />
          )}
        </InputContainer>
        {visible && (
          <DatepickerContainer offset={noFlip ? '0' : offset} ref={datepickerContainerRef}>
            <TitleContainer>
              <DisabledIconStyle icon='dateRange' />
              <TitleStyle>{readOnly ? t('Calendar') : t(title)}</TitleStyle>
            </TitleContainer>
            <MiddleContainer>
              <CalendarContainer>
                <Calendar
                  variant={isRange ? 'range' : 'single'}
                  startDate={startDate}
                  endDate={endDate}
                  focusDate={primaryFocus}
                  min={min}
                  max={max}
                  setDate={primaryCalendarClickHandler}
                  setFocusDate={primaryCalendarFocusHandler}
                  disableFilter={disableFilter}
                  disabled={disabled}
                  readOnly={readOnly}
                />
                {isRange && (
                  <Calendar
                    variant={isRange ? 'range' : 'single'}
                    startDate={startDate}
                    endDate={endDate}
                    focusDate={secondaryFocus}
                    min={min}
                    max={max}
                    setDate={secondaryCalendarClickHandler}
                    setFocusDate={secondaryCalendarFocusHandler}
                    disableFilter={disableFilter}
                    disabled={disabled}
                    readOnly={readOnly}
                  />
                )}
              </CalendarContainer>
              {isRange && noEndDateAllowed && (
                <CheckboxContainer>
                  <Checkbox
                    label={t('Indefinitely')}
                    name={`${name}Indefinitely`}
                    checked={isIndefinitely}
                    onChange={checkboxHandler}
                    errors={[]}
                    disabled={readOnly}
                  />
                </CheckboxContainer>
              )}
            </MiddleContainer>
            <FooterContainer>
              <ButtonContainer>
                <Button variant='red' kind='outlined' onClick={closeButtonHandler}>
                  {t('Close')}
                </Button>
              </ButtonContainer>
              {!readOnly && (
                <ButtonContainer>
                  <Button variant='green' onClick={saveButtonHandler}>
                    {t('Save')}
                  </Button>
                </ButtonContainer>
              )}
            </FooterContainer>
          </DatepickerContainer>
        )}
        <FormErrorMessage name={name} errors={formElemErrors} />
      </PositioningContainer>
    </Wrapper>
  );
};

export default Datepicker;
