/* eslint-disable no-param-reassign */
import { useState, useEffect, useRef, createRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Label, Timepicker, Checkbox, Icon } from 'components';
import { colorBlueBasic } from 'styles/GlobalStyles';
import { sortArrayById } from 'utils/helpers';
import { NoItemsParagraph } from 'components/List/List.style';
import {
  WeekDayBox,
  ListWrapper,
  CheckboxInput,
  ListItemWrapper,
  Wrapper,
  TimepickerWrapper,
  TimepickerMiddleSign,
  CheckboxWrapper,
  CopyWrapper,
  CopyText,
  LeftSideWrapper,
  RightSideWrapper,
} from './Timetable.style';

type tWeekDaysOptions = {
  id: number;
  name: string;
  fullName: string;
  isChecked: boolean;
  firstValue: { valueFrom: string; valueTo: string };
  secondValue: { valueFrom: string; valueTo: string };
};

type tTimetable = {
  onChange: (e) => void;
  errors: any[];
  name: string;
  twoAvailabilityPeriods?: boolean;
  defaultItems?: tWeekDaysOptions[];
  onCheckboxChange?: () => void;
  isCheckboxChecked?: boolean;
  disabled?: boolean;
  preview?: boolean;
  noItemsCopy?: string;
};

export type tWeekDayBox = {
  isChecked: boolean;
  preview: boolean;
};

const Timetable: React.FC<tTimetable> = ({
  onChange,
  errors,
  name,
  twoAvailabilityPeriods = false,
  defaultItems = [],
  onCheckboxChange = () => {},
  isCheckboxChecked = false,
  preview = false,
  noItemsCopy = '',
}): JSX.Element => {
  const { t } = useTranslation();
  const [weekDaysShort, setWeekDaysShort] = useState<tWeekDaysOptions[]>([]);
  const [activeElements, setActiveElements] = useState<tWeekDaysOptions[]>([]);
  const [valueToCopy, setValueToCopy] = useState({
    firstValue: { from: '', to: '' },
    secondValue: { from: '', to: '' },
  });
  const checkboxRefs = useRef<Array<any>>([]);
  checkboxRefs.current = weekDaysShort.map(
    // eslint-disable-next-line no-return-assign
    (ref, index) => (checkboxRefs.current[index] = createRef()),
  );

  const copyValue = (secondField: boolean, event) => {
    event.preventDefault();
    const activeElementsCopy = [...activeElements];
    if (secondField) {
      activeElementsCopy.forEach((activeElement) => {
        activeElement.secondValue.valueFrom = valueToCopy.secondValue.from;
        activeElement.secondValue.valueTo = valueToCopy.secondValue.to;
      });
    } else {
      activeElementsCopy.forEach((activeElement) => {
        activeElement.firstValue.valueFrom = valueToCopy.firstValue.from;
        activeElement.firstValue.valueTo = valueToCopy.firstValue.to;
      });
    }
    setActiveElements(activeElementsCopy);
  };

  const renderCopyForAll = (secondField = false) => {
    return (
      <CopyWrapper onClick={(event) => copyValue(secondField, event)}>
        <Icon icon='fileCopy' fill={colorBlueBasic} />
        <CopyText>{t('Copy for all')}</CopyText>
      </CopyWrapper>
    );
  };

  const WeekDayCheckbox = (props) => {
    const { handleChange, isChecked, name, index } = props;
    return (
      <ListItemWrapper>
        <CheckboxInput
          ref={checkboxRefs.current[index]}
          readOnly
          name={name}
          onChange={(e) => handleChange(e.target)}
          type='checkbox'
          checked={isChecked}
        />
      </ListItemWrapper>
    );
  };

  const getValuesForWeekDays = (weekDay, targetElement) => {
    if (targetElement.name.includes(weekDay.name) && targetElement.name.includes('from')) {
      if (targetElement.name.includes('second')) {
        weekDay.secondValue = !!weekDay.secondValue
          ? {
              ...weekDay.secondValue,
              valueFrom: targetElement.value,
            }
          : {
              valueFrom: targetElement.value,
            };
      } else {
        weekDay.firstValue = weekDay.firstValue
          ? {
              ...weekDay.firstValue,
              valueFrom: targetElement.value,
            }
          : {
              valueFrom: targetElement.value,
            };
      }
    }
    if (targetElement.name.includes(weekDay.name) && targetElement.name.includes('to')) {
      if (targetElement.name.includes('second')) {
        weekDay.secondValue = weekDay.secondValue
          ? {
              ...weekDay.secondValue,
              valueTo: targetElement.value,
            }
          : { valueTo: targetElement.value };
      } else {
        weekDay.firstValue = weekDay.firstValue
          ? {
              ...weekDay.firstValue,
              valueTo: targetElement.value,
            }
          : { valueTo: targetElement.value };
      }
    }
  };

  const handleChange = (event) => {
    const weekDaysShortCopy = [...weekDaysShort];
    weekDaysShortCopy.forEach((weekDay) => {
      if (event && weekDay.name === event.name) {
        weekDay.isChecked = event.checked;
      }
      getValuesForWeekDays(weekDay, event);
    });
    setWeekDaysShort(weekDaysShortCopy);

    const activeWeekDays = weekDaysShortCopy.filter((weekDay) => weekDay.isChecked);
    setActiveElements(activeWeekDays);
    const [firstActiveWeekDay] = activeWeekDays;
    if (activeWeekDays.length) {
      if (event.name.includes('second')) {
        setValueToCopy({
          firstValue: { ...valueToCopy.firstValue },
          secondValue: {
            from: firstActiveWeekDay.secondValue.valueFrom || '',
            to: firstActiveWeekDay.secondValue.valueTo || '',
          },
        });
      } else {
        setValueToCopy({
          secondValue: { ...valueToCopy.secondValue },
          firstValue: {
            from: firstActiveWeekDay.firstValue.valueFrom || '',
            to: firstActiveWeekDay.firstValue.valueTo || '',
          },
        });
      }
    }
    const customEvent = {
      custom: true,
      type: 'timetable',
      name,
      value: activeWeekDays,
    };
    if (onChange) onChange(customEvent);
  };

  const renderTimepicker = () => {
    return activeElements.map((elem, index) => {
      const { id, name, fullName, firstValue } = elem;
      const secondValue = elem.secondValue || { valueFrom: '', valueTo: '' };
      return (
        <div key={id}>
          <TimepickerWrapper>
            <LeftSideWrapper>
              <Label>{t(fullName)}</Label>
              <Timepicker
                onChange={handleChange}
                name={`from-${name}`}
                value={firstValue.valueFrom}
                errors={errors}
                fullWidth
                labelMinWidth={0}
                preview={preview}
              />
              <TimepickerMiddleSign>—</TimepickerMiddleSign>
              <Timepicker
                onChange={handleChange}
                name={`to-${name}`}
                value={firstValue.valueTo}
                errors={errors}
                fullWidth
                labelMinWidth={0}
                preview={preview}
              />
            </LeftSideWrapper>
            {preview ? null : <RightSideWrapper>{!index && renderCopyForAll()}</RightSideWrapper>}
          </TimepickerWrapper>
          {isCheckboxChecked ? (
            <TimepickerWrapper>
              <LeftSideWrapper>
                <Label> </Label>
                <Timepicker
                  onChange={handleChange}
                  name={`from-${name}-second`}
                  value={secondValue.valueFrom}
                  errors={errors}
                  fullWidth
                  labelMinWidth={0}
                  preview={preview}
                />
                <TimepickerMiddleSign>—</TimepickerMiddleSign>
                <Timepicker
                  onChange={handleChange}
                  name={`to-${name}-second`}
                  value={secondValue.valueTo}
                  errors={errors}
                  fullWidth
                  labelMinWidth={0}
                  preview={preview}
                />
              </LeftSideWrapper>
              {preview ? null : (
                <RightSideWrapper>{!index && renderCopyForAll(true)}</RightSideWrapper>
              )}
            </TimepickerWrapper>
          ) : null}
        </div>
      );
    });
  };

  const onClick = (index: number, event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (checkboxRefs.current[index]) {
      checkboxRefs.current[index].current.click();
    }
  };

  useEffect(() => {
    const weekDays: tWeekDaysOptions[] = [
      {
        id: 1,
        name: 'Mo',
        fullName: 'Monday',
        isChecked: false,
        firstValue: { valueFrom: '', valueTo: '' },
        secondValue: { valueFrom: '', valueTo: '' },
      },
      {
        id: 2,
        name: 'Tu',
        fullName: 'Tuesday',
        isChecked: false,
        firstValue: { valueFrom: '', valueTo: '' },
        secondValue: { valueFrom: '', valueTo: '' },
      },
      {
        id: 3,
        name: 'We',
        fullName: 'Wednesday',
        isChecked: false,
        firstValue: { valueFrom: '', valueTo: '' },
        secondValue: { valueFrom: '', valueTo: '' },
      },
      {
        id: 4,
        name: 'Th',
        fullName: 'Thursday',
        isChecked: false,
        firstValue: { valueFrom: '', valueTo: '' },
        secondValue: { valueFrom: '', valueTo: '' },
      },
      {
        id: 5,
        name: 'Fr',
        fullName: 'Friday',
        isChecked: false,
        firstValue: { valueFrom: '', valueTo: '' },
        secondValue: { valueFrom: '', valueTo: '' },
      },
      {
        id: 6,
        name: 'Sa',
        fullName: 'Saturday',
        isChecked: false,
        firstValue: { valueFrom: '', valueTo: '' },
        secondValue: { valueFrom: '', valueTo: '' },
      },
      {
        id: 7,
        name: 'Su',
        fullName: 'Sunday',
        isChecked: false,
        firstValue: { valueFrom: '', valueTo: '' },
        secondValue: { valueFrom: '', valueTo: '' },
      },
    ];
    const allValues = [...defaultItems, ...weekDays];
    const uniqueArray = allValues.filter(
      (value, index, array) => array.findIndex((foundItem) => foundItem.id === value.id) === index,
    );
    const sortedArr = sortArrayById(uniqueArray);
    setWeekDaysShort(sortedArr);
    const activeValues = sortedArr.filter((weekDay) => weekDay.isChecked);
    setActiveElements(activeValues);
  }, [defaultItems]);

  const checkboxHandleChange = () => {
    if (onCheckboxChange) onCheckboxChange();
  };

  return (
    <Wrapper>
      <ListWrapper>
        <LeftSideWrapper>
          <Label> </Label>
          {preview
            ? null
            : weekDaysShort.map((weekDay, index) => {
                return (
                  <WeekDayBox
                    isChecked={weekDay.isChecked}
                    onClick={(event) => onClick(index, event)}
                    key={weekDay.id}
                    preview={preview}
                  >
                    {t(weekDay.name)}
                    <WeekDayCheckbox handleChange={handleChange} {...weekDay} index={index} />
                  </WeekDayBox>
                );
              })}
        </LeftSideWrapper>
        {!preview && twoAvailabilityPeriods && (
          <CheckboxWrapper>
            <Checkbox
              onChange={checkboxHandleChange}
              name='availabilityPeriods'
              errors={errors}
              label='2 availability periods'
              checked={isCheckboxChecked}
            />
          </CheckboxWrapper>
        )}
      </ListWrapper>
      {preview && activeElements.length === 0 && (
        <NoItemsParagraph>{t(noItemsCopy)}</NoItemsParagraph>
      )}
      {renderTimepicker()}
    </Wrapper>
  );
};

export default Timetable;
