import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

export const NoDataTd = styled.td`
  box-shadow: 0px 3px 6px var(--theme-mainShadow);
  padding: 0px var(--large-padding);
  background-color: var(--theme-white);
  height: 100px;
  border-radius: var(--theme-borderRadius);
  text-align: center;
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const MainTable = styled.table`
  width: 100%;
  min-width: 800px;
  border-collapse: separate;
  border-spacing: 0 0.75rem;
  padding-bottom: 0.75rem;

  &:first-child td:first-child {
    border-top-left-radius: var(--theme-borderRadius);
    border-color: var(--theme-white);
    border-bottom-left-radius: var(--theme-borderRadius);
  }
  &:last-child td:last-child {
    border-top-right-radius: var(--theme-borderRadius);
    border-color: var(--theme-white);
    border-bottom-right-radius: var(--theme-borderRadius);
    background-color: var(--theme-white);
  }
`;

type tTableTh = {
  name: string;
};

export const TableTh = styled.th<tTableTh>`
  text-align: left;
  padding: var(--large-padding) var(--large-padding);
  color: var(--theme-black);
  background-color: var(--theme-white);
  font-size: 0.875rem;
  &:first-child {
    border-top-left-radius: var(--theme-borderRadius);
    border-color: var(--theme-white);
    border-bottom-left-radius: var(--theme-borderRadius);
    width: 4rem;
    position: sticky;
    left: 0;
    z-index: calc(var(--theme-containerZIndex) + 2);
  }
  &:last-child {
    border-top-right-radius: var(--theme-borderRadius);
    border-bottom-right-radius: var(--theme-borderRadius);
  }
  ${({ name }) =>
    name === 'exported' &&
    css`
      max-width: 20px;
    `}
`;

export const TableThAction = styled.th`
  text-align: left;
  color: var(--theme-black);
  background-color: var(--theme-white);
  border-top-right-radius: var(--theme-borderRadius);
  border-color: var(--theme-white);
  border-bottom-right-radius: var(--theme-borderRadius);
  padding: calc(var(--small-padding) / 2) var(--large-padding);
  min-width: 5rem;
  font-size: 0.875rem;
`;

export type tTableTr = {
  showPointerCursor: boolean;
};
export const TableTr = styled.tr<tTableTr>`
  box-shadow: 0px 3px 6px var(--theme-mainShadow);
  padding: 0px var(--large-padding);
  background-color: var(--theme-white);
  ${({ showPointerCursor }) => showPointerCursor && 'cursor: pointer'};
`;
export type tTableTd = {
  name: string;
  children?: ReactNode;
};

export const TableTd = styled.td<tTableTd>`
  padding: var(--theme-spacing) var(--large-padding);
  line-height: 1;
  position: relative;
  font-size: 0.875rem;
  &:first-child {
    position: sticky;
    left: 0;
    z-index: calc(var(--theme-containerZIndex) + 2);
    background: var(--theme-white);
  }
  &:first-child:after {
    display: flex;
    content: '';
    display: inline-block;
    vertical-align: middle;
    min-height: 28px;
  }

  ${({ name }) =>
    name === 'exported' &&
    css`
      text-align: center;
      padding: 0px;
      vertical-align: bottom;
    `}
`;

export const TableTdAction = styled.td`
  min-width: 7rem;
  padding: var(--theme-spacing) var(--large-padding);
  line-height: 1;
`;

export const TableClearButton = styled.button`
  outline: none;
  background: transparent;
  border: 0;
  line-height: 1;
`;

export const RowMarker = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 10px;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
`;

export const SortableButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const TableTdDownload = styled.td`
  text-align: right;
  padding-right: 2rem;
`;
