/* eslint-disable camelcase */
import { fetchProvider } from 'utils/Axios';
import {
  tGetAllUsersAPI,
  tGetUserAPI,
  tGetCurrentUserAPI,
  tCreateUserAPI,
  tUpdateUserAPI,
  tGetCompaniesUsersAPI,
  tGetLocationUsersAPI,
  tUpdateLocationUsersAPI,
  tGetAddressesUsersAPI,
} from 'types/api/users';
import { iAPIResults, iAPIData } from 'types/global';
import { tGetCompaniesParams } from 'services/users';

type tGetAddressesParamsAPI = {
  client_id?: number | string;
  impel_location?: boolean;
};

export type tGetAllUsersParamsAPI = {
  limit?: number;
  offset?: number;
  search?: string;
  search_fields?: string;
  kind?: string;
  company_id?: string;
  role_id?: string;
  representative_id?: string;
  sort?: string;
  order?: string;
};

const microservicePath = 'users';

const users = {
  getAll: (params: tGetAllUsersParamsAPI): iAPIResults<tGetAllUsersAPI> =>
    fetchProvider.get(`${microservicePath}/users`, { params }),
  get: (id: number | string): iAPIData<tGetUserAPI> =>
    fetchProvider.get(`${microservicePath}/users/${id}`),
  create: (bodyRequest: tCreateUserAPI): iAPIData<tGetUserAPI> =>
    fetchProvider.post(`${microservicePath}/users`, bodyRequest),
  update: (bodyRequest: tUpdateUserAPI, id: string | number): iAPIData<tGetUserAPI> =>
    fetchProvider.patch(`${microservicePath}/users/${id}`, bodyRequest),
  remove: (id: string | number): iAPIData<{}> =>
    fetchProvider.delete(`${microservicePath}/users/${id}`),
  getCurrent: (): iAPIData<tGetCurrentUserAPI> =>
    fetchProvider.get(`${microservicePath}/users/current`),
  getCompanies: (params: tGetCompaniesParams): iAPIResults<tGetCompaniesUsersAPI> =>
    fetchProvider.get(`${microservicePath}/companies`, { params }),

  // Location
  getAddresses: (params: tGetAddressesParamsAPI): iAPIResults<tGetAddressesUsersAPI> =>
    fetchProvider.get(`contracts/addresses`, { params }),
  getLocation: (id: string | number): iAPIData<tGetLocationUsersAPI> =>
    fetchProvider.get(`contracts/users_location/${id}`),
  updateLocation: (
    userId: string | number,
    bodyRequest: tUpdateLocationUsersAPI,
  ): iAPIData<tGetLocationUsersAPI> =>
    fetchProvider.put(`contracts/users_location/${userId}`, bodyRequest),
};

export default users;
