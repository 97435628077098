import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs, AccessCheck } from 'components';
import { Left, Title } from 'styles/GlobalStyledComponents';
import { tOption } from 'types/global';
import { MainForm, LocationForm } from './Forms';
import { PageWrapper } from './AddEditViewUser.style';

type tAddEditViewUser = {
  isAddMode?: boolean;
  disabled?: boolean;
  preview?: boolean;
};

export type tCompany = {
  label: string;
  value: number | string;
  id: string;
  fieldName?: string;
};

const AddEditViewUser: React.FC<tAddEditViewUser> = ({
  isAddMode = false,
  disabled = false,
  preview = false,
}): JSX.Element => {
  const { t } = useTranslation();
  const accessPermissions = disabled || preview ? ['tunUsersRead'] : ['tunUsersWrite'];

  const [formName, setFormName] = useState<string>('');
  const [userCompany, setUserCompany] = useState<tOption | null>(null);

  const getTitle = () => {
    if (isAddMode) return t('New user');
    if (disabled || preview) return t('User details');
    return t('Edit user');
  };

  return (
    <PageWrapper>
      <AccessCheck permissions={accessPermissions} redirectTo='/users' />
      <Breadcrumbs aliases={[formName]} />
      <Title>{getTitle()}</Title>
      <Left>
        <MainForm
          isAddMode={isAddMode}
          disabled={disabled}
          preview={preview}
          setFormName={setFormName}
          setUserCompany={setUserCompany}
        />
        <LocationForm
          disabled={isAddMode || disabled}
          preview={preview}
          userCompany={userCompany}
        />
      </Left>
    </PageWrapper>
  );
};

export default AddEditViewUser;
