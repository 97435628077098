/* eslint-disable no-prototype-builtins */

import { useTranslation } from 'react-i18next';
import { Button } from 'components';
import { LeftMargin1Rem } from 'styles/GlobalStyledComponents';
import issuesService from 'services/issues';
import { catchErrors } from 'utils/helpers';
import { tKeyString } from 'types/global';

export type tAssignIssueAction = {
  actions: { [key: string]: string };
  contractorTechnicianId: number | string | null;
  isSubcontractorAssigned: boolean;
  setNotification: (error, type) => void;
  setErrors: (value) => void;
  history: { push: Function };
  buttonText?: string;
};

const choseAssignAction = (actions, isSubcontractorAssigned) => {
  if (!isSubcontractorAssigned && actions.hasOwnProperty('assign_technician')) {
    return actions.assign_technician;
  } else if (
    !isSubcontractorAssigned &&
    actions.hasOwnProperty('assign_subcontractors_technician')
  ) {
    return actions.assign_subcontractors_technician;
  } else if (isSubcontractorAssigned && actions.hasOwnProperty('delegate_to_subcontractor')) {
    return actions.delegate_to_subcontractor;
  } else if (actions.hasOwnProperty('change_technician')) {
    return actions.change_technician;
  }
};

const AssignIssueAction: React.FC<tAssignIssueAction> = ({
  actions,
  contractorTechnicianId,
  isSubcontractorAssigned,
  setNotification,
  setErrors,
  history,
  buttonText = '',
}) => {
  const { t } = useTranslation();

  const setResponseErrors = (setter: Function, errors: tKeyString[]) => {
    const transformField = (field: string): string => {
      switch (field) {
        case 'subcontractor_id':
          return `subcontractor`;
        case 'technician_id':
          return `technicianITS`;
        default:
          return field;
      }
    };

    setter(
      errors
        ? errors?.map((error: tKeyString) => {
            const { key, msg } = error;
            return {
              field: transformField(key),
              error: msg,
            };
          })
        : [],
    );
  };

  const assignIssueHandler = async () => {
    if (actions && contractorTechnicianId) {
      try {
        const result = await issuesService.assignIssue(
          choseAssignAction(actions, isSubcontractorAssigned),
          {
            contractorTechnicianId,
            isSubcontractorAssigned,
          },
        );
        if (result?.status === 200) {
          setNotification(t('Service technician assigned to issue'), 'success');
          history.push('/issues');
        }
      } catch (error) {
        const setters = { setNotificationHandler: setNotification, setResponseErrors, setErrors };
        catchErrors(error, setters);
      }
    }
  };

  return (
    <LeftMargin1Rem>
      <Button variant='green' onClick={assignIssueHandler} disabled={!contractorTechnicianId}>
        {t(buttonText) || t('Assign')}
      </Button>
    </LeftMargin1Rem>
  );
};

export default AssignIssueAction;
