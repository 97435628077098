import { useEffect } from 'react';
import firebase from '@firebase/app';
import '@firebase/analytics';
import { Route, Redirect } from 'react-router-dom';

type tPrivateRoute = {
  children: JSX.Element[] | JSX.Element | string;
  authenticated: boolean;
  loading: boolean;
};

/* eslint-disable no-underscore-dangle */
const PrivateRoute: React.FC<tPrivateRoute> = ({ children, authenticated, loading }) => {
  useEffect(() => {
    if (!!window?.__env__?.FIREBASE?.measurementId) {
      const firebaseConfig = !!window?.__env__?.FIREBASE
        ? {
            ...window.__env__.FIREBASE,
          }
        : {};

      if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
      } else {
        firebase.app();
      }

      firebase.analytics();
    }
  }, []);

  if (loading) return null;
  if (authenticated) return <Route render={() => children} />;
  return <Redirect to='/login' />;
};

export default PrivateRoute;
