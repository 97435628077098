import Select, { components } from 'react-select';
import { useTranslation } from 'react-i18next';
import FormErrorMessage from 'components/FormErrorMessage';
import { tError } from 'types/global';
import { Icon, Label } from 'components';
import { useFormErrorsReset } from 'hooks';
import { colorRedBasic, colorGreyBasic } from 'styles/GlobalStyles';
import { Flex } from 'styles/GlobalStyledComponents';
import {
  DropdownContainer,
  customTheme,
  MessageWrapper,
  Message,
  InnerDropdownContainer,
  PreviewText,
} from './Dropdown.style';

export type tOptions = {
  fieldName: string;
  label: string;
  value: string | number | null;
};

export type tDropdown = {
  options: tOptions[];
  onChange: (val: any) => void;
  value?: any | null;
  menuPlacement?: 'top' | 'bottom';
  name: string;
  errors: tError[];
  disabled?: boolean;
  preview?: boolean;
  label?: string;
  wrapperStyle?: object;
  labelMinWidth?: number;
  menuPortalTarget?: HTMLElement | null;
  styles?: { [key: string]: (base) => React.CSSProperties };
};

const Dropdown: React.FC<tDropdown> = ({
  options,
  onChange,
  value,
  menuPlacement = 'bottom',
  name,
  errors,
  disabled,
  preview,
  label,
  wrapperStyle,
  labelMinWidth = 90,
  menuPortalTarget,
  styles,
}): JSX.Element => {
  const { t } = useTranslation();
  const { formElemErrors, resetFormError } = useFormErrorsReset(errors);

  const handleChange = (event: any) => {
    resetFormError(name);
    return event ? onChange(event) : onChange({ fieldName: name, value: '', label: '' });
  };

  const DropdownIndicator = (props) => {
    const { selectProps } = props;

    if (!selectProps.menuIsOpen) {
      return (
        <components.DropdownIndicator {...props}>
          <Icon asButton icon='arrowDown' fill={disabled ? undefined : colorGreyBasic} />
        </components.DropdownIndicator>
      );
    }
    if (
      selectProps.menuIsOpen &&
      (selectProps.value === null || selectProps.value[0].value?.length === 0)
    ) {
      return (
        <components.DropdownIndicator {...props}>
          <Icon asButton icon='search' />
        </components.DropdownIndicator>
      );
    }
    return null;
  };

  const ClearIndicator = (props) => {
    const { selectProps } = props;
    if (
      selectProps.menuIsOpen &&
      (selectProps.value === null || selectProps.value[0].value?.length === 0)
    ) {
      return (
        <components.ClearIndicator {...props}>
          <Icon asButton icon='close' fill={colorRedBasic} />
        </components.ClearIndicator>
      );
    }
    return null;
  };

  const NoDataMessage = () => (
    <MessageWrapper>
      <Icon icon='thumbDown' fill={colorGreyBasic} />
      <Message>{t('Not found')}</Message>
    </MessageWrapper>
  );

  const customStyles = {
    option: (provided: any) => ({
      ...provided,
      padding: '0.5rem 1rem;',
      color: 'var(--theme-black)',
      fontSize: '0.75rem',
    }),
    container: (provided: any) => ({
      ...provided,
      width: '100%',
    }),
    control: (provided: any) => ({
      ...provided,
      width: '100%',
      minHeight: '32px',
      minWidth: '80px',
      height: '32px',
      borderRadius: '40px',
      flexWrap: 'unset',
      padding: '0.5rem 0.25rem 0.5rem 1rem',
      fontSize: '0.75rem',
      borderColor: formElemErrors.find((error) => error.field === name)
        ? 'var(--theme-mainRed)'
        : 'var(--theme-mainGrey)',
      ':hover': {
        borderColor: 'var(--theme-mainBlue)',
      },
      ':focus': {
        borderColor: 'var(--theme-mainBlue)',
      },
      color: disabled && 'var(--theme-mainBlue)',
      backgroundColor: disabled && 'var(--theme-lightGrey)',
      border: disabled && 'none',
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: 0,
      height: '32px',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      marginLeft: 0,
      color: disabled ? 'var(--theme-mainGrey)' : 'var(--theme-black)',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    menu: (provided: any) => ({
      ...provided,
      borderRadius: 'var(--small-padding)',
      marginTop: 'var(--theme-spacing-xs)',
      marginBottom: 0,
      overflow: 'hidden',
    }),
    menuList: (provided: any) => ({
      ...provided,
      padding: 0,
      overflow: 'auto',
      maxHeight: '200px',
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      opacity: disabled ? 0.2 : 0.8,
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: 'var(--theme-mainDarkGrey)',
    }),
    ...styles,
  };

  return (
    <DropdownContainer wrapperStyle={wrapperStyle}>
      <Flex>
        {label && <Label labelMinWidth={labelMinWidth}>{t(label)}</Label>}
        {preview ? (
          <PreviewText>{value || '-'}</PreviewText>
        ) : (
          <InnerDropdownContainer>
            <Select
              name={name}
              styles={customStyles}
              value={!!value ? [{ label: value, value, fieldName: name }] : null}
              onChange={handleChange}
              options={options}
              noOptionsMessage={() => NoDataMessage()}
              placeholder={t('Select')}
              blurInputOnSelect
              menuPlacement={menuPlacement}
              components={{ DropdownIndicator, ClearIndicator }}
              theme={customTheme}
              errors={formElemErrors}
              isDisabled={disabled}
              isClearable
              menuPortalTarget={menuPortalTarget}
            />
          </InnerDropdownContainer>
        )}
      </Flex>
      <FormErrorMessage name={name} errors={formElemErrors} labelMinWidth={labelMinWidth} />
    </DropdownContainer>
  );
};

export default Dropdown;
