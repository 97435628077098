import styled, { css } from 'styled-components';
import { tDayStyle, tHighlightedDayStyle } from './Calendar';

export const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  text-align: center;
  font-size: 0.75rem;
  color: var(--theme-mainDarkGrey);
  letter-spacing: 0.025rem;
`;

export const DaysGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(7, 1fr);
  row-gap: 3px;
`;

export const WeekDayStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: var(--theme-mainGrey);
  text-transform: uppercase;
`;

export const DayStyle = styled.button<tDayStyle>`
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  border-radius: 0.25rem;
  color: var(--theme-mainDarkGrey);
  &:hover {
    background: var(--theme-transparent50Blue);
  }
  ${({ grayed }) =>
    grayed &&
    css`
      color: var(--theme-mainGrey);
    `}
  ${({ selected }) =>
    selected &&
    css`
      background: var(--theme-mainBlue);
      color: var(--theme-white);
      font-weight: bold;
      &:hover {
        background: var(--theme-mainBlue);
      }
    `}
  ${({ today }) =>
    today &&
    css`
      border: 1px solid var(--theme-transparent40Blue);
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: context-menu;
      &:hover {
        background: none;
      }
    `}
  ${({ disabled, selected }) =>
    disabled &&
    selected &&
    css`
      background: var(--theme-mainBlue);
      color: var(--theme-white);
      &:hover {
        background: var(--theme-mainBlue);
      }
    `}
`;

export const EmptyDayStyle = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
`;

export const HighlightedDayStyle = styled.div<tHighlightedDayStyle>`
  display: flex;
  width: 32px;
  height: 32px;
  background: var(--theme-transparent20Blue);
  ${({ startRadius = false }) =>
    startRadius &&
    css`
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    `}
  ${({ endRadius = false }) =>
    endRadius &&
    css`
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    `}
`;
