import { useCallback, useEffect } from 'react';
import { useFetch } from 'hooks';
import { tGetAllByPost } from 'types/services/issues';
import issues from 'services/issues';

const useFetchNewIssues = () => {
  const { fetch: setNewIssuesParamsHandler, data: newIssues } = useFetch<unknown, tGetAllByPost>(
    issues.getAll,
  );

  const fetchAllIssues = useCallback(
    () =>
      setNewIssuesParamsHandler({
        limit: 10000,
        offset: 0,
        ordering: { by: '', order: '' },
        filters: { status: [{ value: 'nowe', label: 'nowe' }] },
      }),
    [],
  );

  useEffect(() => {
    if (Array.isArray(newIssues) && !!newIssues?.length) {
      const newIssuesLength = newIssues.length;
      localStorage.setItem('newIssuesCount', String(newIssuesLength));
    }
  }, [newIssues]);

  useEffect(() => {
    fetchAllIssues();
  }, []);

  return { fetchAllIssues };
};

export default useFetchNewIssues;
