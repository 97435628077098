import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { addNotification } from 'store/notifications/actions';
import { Modal, Tiles, Spinner } from 'components';
import issuesService, { tGetContractAddressesActiveByRoleParams } from 'services/issues';
import { tCreateIssueData, tContractAddresses } from 'types/services/issues';
import tStore from 'types/store';
import { regionalAdministratorRoleUUID } from 'utils/constants';
import importerXLSGetin from './importerXLSGetin';

import SingleIssue, { tXLSIssueFullData, tXLSDataStatus } from './SingleIssue';
import { TextWrapper, ImporterWrapper, HiddenInput } from './ImportXLS.style';

export type tImportXLS = {
  updateTrigger?: () => void;
};

const ImportXLS: React.FC<tImportXLS> = ({ updateTrigger = () => {} }): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loadingInProgress, setLoadingInProgress] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showFileMessage, setShowFileMessage] = useState<boolean>(false);
  const [issuesData, setIssuesData] = useState<tXLSIssueFullData[] | null>(null);
  const [data1, setData1] = useState<tXLSIssueFullData | null>(null);
  const [data2, setData2] = useState<tXLSIssueFullData | null>(null);
  const [data3, setData3] = useState<tXLSIssueFullData | null>(null);

  const {
    fetch: fetchAddresses,
    loading: loadingAddresses,
    data: addresses,
  } = useFetch<tContractAddresses, tGetContractAddressesActiveByRoleParams>(
    issuesService.getContractAddressesActiveByRole,
    'Something went wrong while fetching data',
  );

  const inputFile = useRef<any>();
  const userId = useSelector((state: tStore) => state.user.id);

  const setNotificationHandler = (message: string, type = 'error' as 'error' | 'success') => {
    const enableTimeout = type === 'success';
    dispatch(addNotification(t(message), type, enableTimeout));
  };

  useEffect(() => {
    const tempData: tXLSIssueFullData[] = [];
    if (data1) tempData.push(data1);
    if (data2) tempData.push(data2);
    if (data3) tempData.push(data3);
    setIssuesData([...tempData]);
  }, [data1, data2, data3]);

  useEffect(() => {
    if (showModal) {
      if (!!userId && !!regionalAdministratorRoleUUID) {
        fetchAddresses({ userId, roleId: regionalAdministratorRoleUUID });
      } else {
        setNotificationHandler(t('User id cannot be empty'));
      }
    }
  }, [showModal, userId]);

  useEffect(() => {
    if (addresses && !!addresses.length) {
      // eslint-disable-next-line no-unused-expressions
      inputFile?.current?.click();
    }
  }, [addresses]);

  const formatOneIssueData = (data): tXLSIssueFullData | null =>
    data ? { ...data, status: '-', loading: false, alreadySent: false, dataToSend: null } : null;

  const uploadHandler = (e) => {
    e.preventDefault();
    setLoadingInProgress(true);

    try {
      const {
        target: { files },
      } = e;
      const file = files[0];

      const reader = new FileReader();
      reader.onload = (e) => {
        const data = importerXLSGetin(e.target?.result);
        setShowFileMessage(true);
        if (data) {
          setData1(formatOneIssueData(data[0]));
          setData2(formatOneIssueData(data[1]));
          setData3(formatOneIssueData(data[2]));
        }
      };
      reader.readAsBinaryString(file);
    } catch (err) {
      setNotificationHandler(t('Error processing file content'));
      setShowModal(false);
    } finally {
      setLoadingInProgress(false);
    }
  };

  const setData = (data: tXLSIssueFullData) => {
    if (data1?.id === data?.id) setData1({ ...data });
    if (data2?.id === data?.id) setData2({ ...data });
    if (data3?.id === data?.id) setData3({ ...data });
  };

  const closeModalHandler = () => {
    setData1(null);
    setData2(null);
    setData3(null);
    setShowFileMessage(false);
    setShowModal(false);
  };

  const removeHandler = (id) => {
    if (data1?.id === id) setData1(null);
    if (data2?.id === id) setData2(null);
    if (data3?.id === id) setData3(null);
  };

  const setLoading = (id: number, state: boolean) => {
    if (data1 && data1?.id === id) setData1({ ...data1, loading: state });
    if (data2 && data2?.id === id) setData2({ ...data2, loading: state });
    if (data3 && data3?.id === id) setData3({ ...data3, loading: state });
  };

  const setStatus = (id: number, status: tXLSDataStatus) => {
    if (data1 && data1?.id === id) setData1({ ...data1, status });
    if (data2 && data2?.id === id) setData2({ ...data2, status });
    if (data3 && data3?.id === id) setData3({ ...data3, status });
  };

  const formatIssueData = (data: tXLSIssueFullData | null): tCreateIssueData | null => {
    if (data && data?.dataToSend) {
      const {
        contractAddressId,
        reporterId,
        industryId,
        modeId,
        shortDescription,
        description,
        clientSystemId,
      } = data.dataToSend;

      const formattedData: tCreateIssueData = {
        short_description: shortDescription,
        description,
        issue_type_id: 1,
        appliance_industry_id: industryId ?? null,
        system_id: null,
        group_id: null,
        appliance_id: null,
        accounting_mode_id: modeId ?? null,
        budget: null,
        client_preferred_fix_date: null,
        client_system_id: clientSystemId,
        contract_address_id: contractAddressId ?? null,
        location_ids: [],
        mruk_site_ids: [],
        attachments: [],
      };
      if (reporterId) {
        formattedData.reporter_id = String(reporterId);
      }
      return formattedData;
    }
    return null;
  };

  const onSend = () => {
    if (issuesData && !!issuesData.length) {
      const bodyData0 = formatIssueData(issuesData[0]);
      if (bodyData0 && issuesData[0]) {
        setLoadingInProgress(true);
        issuesService
          .create(bodyData0)
          .then(() => {
            setStatus(issuesData[0]?.id, 'sent');

            const bodyData1 = formatIssueData(issuesData[1]);
            if (bodyData1 && issuesData[1]) {
              issuesService
                .create(bodyData1)
                .then(() => {
                  setStatus(issuesData[1]?.id, 'sent');

                  const bodyData2 = formatIssueData(issuesData[2]);
                  if (bodyData2 && issuesData[2]) {
                    issuesService
                      .create(bodyData2)
                      .then(() => {
                        setStatus(issuesData[2]?.id, 'sent');
                      })
                      .catch(() => {
                        setStatus(issuesData[2]?.id, 'failure');
                      });
                  }
                })
                .catch(() => {
                  setStatus(issuesData[1]?.id, 'failure');
                });
            }
          })
          .catch(() => {
            setStatus(issuesData[0]?.id, 'failure');
          })
          .finally(() => {
            updateTrigger();
            setLoadingInProgress(false);
          });
      }
    }
  };

  const loading =
    loadingAddresses || loadingInProgress || data1?.loading || data2?.loading || data3?.loading;

  const fileName = inputFile?.current?.files[0]?.name ?? '';

  const getMessage = () => {
    if (showFileMessage) {
      return t('No issues found in file');
    }
    if (!loadingAddresses && !addresses?.length) {
      return t(
        'There are no associated contracts addresses for this user. Issues cannot be created!',
      );
    }
    return t('Upload file');
  };

  return (
    <>
      {loading && <Spinner />}
      {showModal && (
        <Modal
          title={t('Import issue')}
          icon='description'
          cancelButtonText={t('Close')}
          cancelMethod={closeModalHandler}
          confirmButtonText={t('Send')}
          confirmMethod={onSend}
          confirmDisabled={
            !issuesData ||
            !issuesData?.length ||
            !!issuesData?.filter((el) => el.status !== 'ready to send').length
          }
          width='55vw'
        >
          <ImporterWrapper>
            <HiddenInput
              type='file'
              onChange={uploadHandler}
              ref={inputFile}
              accept='.xls,.xlsx'
              data-testid='xls-import-upload-input'
            />
            {fileName && <TextWrapper>{fileName}</TextWrapper>}
            {issuesData && !!issuesData.length ? (
              issuesData.map((issue) => (
                <SingleIssue
                  key={issue?.id}
                  data={issue}
                  remove={removeHandler}
                  contractAddresses={addresses}
                  setData={setData}
                  setLoading={setLoading}
                  setNotification={setNotificationHandler}
                  data-testid='xls-import-single-issue'
                />
              ))
            ) : (
              <TextWrapper data-testid='xls-import-message'>{getMessage()}</TextWrapper>
            )}
          </ImporterWrapper>
        </Modal>
      )}
      <Tiles
        text={t('Import issue')}
        icon='fileImport'
        bgIcon='addComment'
        color='blue'
        variant='filled'
        onClick={() => {
          setShowModal(true);
        }}
      />
    </>
  );
};

export default ImportXLS;
