import { tStringsArray } from 'types/global';

export const appVersion = '2.6.8';

/* eslint-disable no-underscore-dangle */
export const baseURL: string =
  window?.__env__?.API ?? 'https://dev-tun-0.internal.cloudapp.net/api';

export const logoutIdleTime = 30; // minutes, min. 3min
export const logoutIdleTimeWarning = 5; // minutes, min 1min. < logoutIdleTime
export const logoutButtonShowTimer = false;
export const idleResetThrottleIntervalMS = 15000;

export const notificationsTimeoutMS = 10000;
export const notificationsLimit = 10;

export const itsCompanyId = 'f16b2e8c-e71d-4d9d-9759-24bfc32463e4';
export const systemAdminRoleUUID = 'a711818c-3e27-4055-9a1e-c72dee6e25e9';
export const managerITSRoleUUID = '5e1dd94d-72d4-41f6-a29f-df7e9664cfaa';
export const workerRoleUUID = '716f96b0-ed9b-495c-97c8-654e118e5c1d';
export const serviceTechnicianRoleUUID = '1bfff358-a22f-471c-94d4-7bc0a570936e';
export const regionalAdministratorRoleUUID = '21e28678-341a-4743-a617-afd0c1d5aaec';
export const representativeRoleUUID = 'e7346123-7ce3-44ad-9e8e-a5ec6907b4db';
export const subcontractorsTechnicianRoleUUID = '1cee5946-6fd1-403f-b4c4-60eec5282153';

export const uniqueKTITSPermission = 'tunIssuesTransitionsAcceptance';
export const uniqueARPermission = 'tunIssuesAppointmentsAcceptOrReject';

export const monetaryUnit = 'zł';

export const kindSubcontractor = 'subcontractor';

export const dropdownZIndex = 300;

export const afterTheDeadlineBadgeBannedStatuses = [
  'nowe',
  'anulowane',
  'reklamacja niezasadna',
  'odrzucone',
  'do poprawy',
  'nie podjęte',
  'przyjęte',
  'w awizacji',
  'zrealizowane',
  'w kontynuacji',
  'usługa niepotwierdzona',
  'reklamacja',
  'reklamacja zasadna',
  'w trakcie rozliczenia',
  'w akceptacji',
  'rozliczone',
  'rozliczenie zarchiwizowane',
];

export const weekDays: tStringsArray = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];
export const weekDaysShort: tStringsArray = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

export const months: tStringsArray = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const monthsShort: tStringsArray = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const middleOfPoland = {
  lat: 51.781436,
  lng: 19.2769,
};
