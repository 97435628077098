import { tGetAll } from 'types/global';
import { fetchProvider, fetchProviderForAttachment } from 'utils/Axios';
import {
  tContractPostAPI,
  tContractPatchAPI,
  tContractTerminationsPost,
  tAccountingModeBodyRequest,
  tPeriodicActivityCreate,
  tPeriodicActivityUpdate,
  tPriceList,
  tManagersAssignedToLocation,
  tUsersAndRegionalAdminsPost,
} from 'types/services/contracts';
import { serviceTechnicianRoleUUID } from 'utils/constants';
import { tUsersApiFilters } from 'types/api/users';

const microservicePath = 'contracts';

const contracts = {
  // General
  getAll: (params: tGetAll) => fetchProvider.get(`${microservicePath}/contracts`, { params }),
  get: (id: number) => fetchProvider.get(`${microservicePath}/contracts/${id}`),
  delete: (id: number) => fetchProvider.delete(`${microservicePath}/contracts/${id}`),
  create: (contractData: tContractPostAPI) =>
    fetchProvider.post(`${microservicePath}/contracts/`, contractData),
  edit: (id: number, contractData: tContractPostAPI) =>
    fetchProvider.put(`${microservicePath}/contracts/${id}`, contractData),
  patch: (id: number, contractData: tContractPatchAPI) =>
    fetchProvider.patch(`${microservicePath}/contracts/${id}`, contractData),

  // Contract Termination
  getContractTermination: (contractId: number) =>
    fetchProvider.get(`${microservicePath}/contracts/${contractId}/terminations`),
  createContractTermination: (bodyRequest: tContractTerminationsPost, contractId: number) =>
    fetchProvider.post(`${microservicePath}/contracts/${contractId}/terminations`, bodyRequest),
  updateContractTermination: (bodyRequest: tContractTerminationsPost, terminationId: number) =>
    fetchProvider.put(`${microservicePath}/contracts/terminations/${terminationId}`, bodyRequest),
  getContractTerminationTypes: () =>
    fetchProvider.get(`${microservicePath}/contracts/terminations/termination_types`),
  getContractTerminationsUnits: () =>
    fetchProvider.get(`${microservicePath}/contracts/terminations/units`),

  // Contractual Penalties
  getContractualPenalties: (contractId: number) =>
    fetchProvider.get(`${microservicePath}/contracts/${contractId}/penalties`),
  getPenaltiesTypes: () => fetchProvider.get(`${microservicePath}/contracts/penalties/types`),
  getPenaltiesUnits: () => fetchProvider.get(`${microservicePath}/contracts/penalties/units`),
  getPenaltiesDiscountTypes: () =>
    fetchProvider.get(`${microservicePath}/contracts/penalties/discount_types`),
  addPenalties: (bodyRequest: any[], contractId: number) =>
    fetchProvider.post(`${microservicePath}/contracts/${contractId}/penalties`, bodyRequest),
  updatePenalties: (bodyRequest: any[], contractId: number) =>
    fetchProvider.put(`${microservicePath}/contracts/${contractId}/penalties`, bodyRequest),

  // Periodic Activities
  getAllPeriodicActivities: (contractId: number) =>
    fetchProvider.get(`${microservicePath}/contracts/${contractId}/periodic_activities`),
  getPeriodicActivity: (id: number) =>
    fetchProvider.get(`${microservicePath}/periodic_activities/${id}`),
  createPeriodicActivity: (contractId: number, activityData: tPeriodicActivityCreate) =>
    fetchProvider.post(
      `${microservicePath}/contracts/${contractId}/periodic_activities`,
      activityData,
    ),
  updatePeriodicActivity: (id: number, activityData: tPeriodicActivityUpdate) =>
    fetchProvider.put(`${microservicePath}/periodic_activities/${id}`, activityData),
  deletePeriodicActivity: (id: number) =>
    fetchProvider.delete(`${microservicePath}/periodic_activities/${id}`),
  getPeriodicActivityTypes: () =>
    fetchProvider.get(`${microservicePath}/periodic_activities/periodic_activity_types`),
  getPeriodicActivityMaintenanceUnits: () =>
    fetchProvider.get(`${microservicePath}/periodic_activities/maintenance_units`),
  getLocations: (contractId: number) =>
    fetchProvider.get(`${microservicePath}/locations/?contract_id=${contractId}`),

  // Attachments
  getAttachment: (attachmentUrl: string) =>
    fetchProviderForAttachment(attachmentUrl)
      .then((response) => {
        const { data } = response;

        return {
          error: false,
          errorMessage: '',
          data: URL.createObjectURL(new Blob([data])),
        };
      })
      .catch(() => {
        return {
          error: true,
          errorMessage: 'Image failed to download',
          data: '',
        };
      }),
  postContractAttachment: (contractId: number, data) =>
    fetchProviderForAttachment.post(
      `${microservicePath}/contracts/${contractId}/contract_attachment`,
      data,
    ),
  postAttachment: (contractId: number, data) =>
    fetchProviderForAttachment.post(
      `${microservicePath}/contracts/${contractId}/attachments`,
      data,
    ),
  deleteAttachments: (attachmentsId: number) =>
    fetchProvider.delete(`${microservicePath}/attachments/${attachmentsId}`),
  deleteContractAttachments: (contractId: number) =>
    fetchProvider.delete(`${microservicePath}/contracts/${contractId}/contract_attachment`),

  // Accounting Modes
  updateAccountingMode: (
    bodyRequest: tAccountingModeBodyRequest,
    accountingModeId: string | number,
  ) => fetchProvider.put(`${microservicePath}/accounting_modes/${accountingModeId}`, bodyRequest),
  addAccountingMode: (
    bodyRequest: Omit<tAccountingModeBodyRequest, 'contract_id'>,
    contractId: string | number,
  ) =>
    fetchProvider.post(`${microservicePath}/contracts/${contractId}/accounting_modes`, bodyRequest),
  deleteAccountingMode: (accountingModeId: string | number) =>
    fetchProvider.delete(`${microservicePath}/accounting_modes/${accountingModeId}`),
  getSlaPrices: () => fetchProvider.get(`${microservicePath}/sla_prices`),
  getAccountingModesReactionTimeUnits: () =>
    fetchProvider.get(`${microservicePath}/accounting_modes/reaction_time_units`),
  getAccountingModesValidityHoursTypes: () =>
    fetchProvider.get(`${microservicePath}/accounting_modes/validity_hours_types`),
  getAccountingModesHoursTypes: () =>
    fetchProvider.get(`${microservicePath}/accounting_modes/hours_types`),
  getAccountingModesDaysTypes: () =>
    fetchProvider.get(`${microservicePath}/accounting_modes/days_types`),
  getAccountingModes: (contractId: string | number, params: { priceList: boolean } | {} = {}) =>
    fetchProvider.get(`${microservicePath}/contracts/${contractId}/accounting_modes`, { params }),
  getSingleAccountingMode: (accountingModeId: string | number) =>
    fetchProvider.get(`${microservicePath}/accounting_modes/${accountingModeId}`),
  getIndustriesAssignedToContract: (id: number | string) =>
    fetchProvider.get(`${microservicePath}/contracts/${id}/industries`),

  // Users
  putITSManagersAssignedToLocation: (locationId: string, data: tManagersAssignedToLocation[]) =>
    fetchProvider.put(`${microservicePath}/locations/${locationId}/its_tech_managers`, data),
  getUsersRoles: () => fetchProvider.get(`${microservicePath}/users/roles`),
  getUsersByRoleAssignedToLocation: (locationId: string, roleId: string) =>
    fetchProvider.get(`${microservicePath}/locations/${locationId}/users/?role_id=${roleId}`),
  getUsersByFilters: (params: tUsersApiFilters) =>
    fetchProvider.get(`${microservicePath}/users/`, { params }),
  putUsersAndRegionalAdminsToLocation: (locationId: string, data: tUsersAndRegionalAdminsPost) =>
    fetchProvider.put(`${microservicePath}/locations/${locationId}/contract_users`, data),
  getITSTechnicians: () =>
    fetchProvider.get(
      `${microservicePath}/users?role_id=${serviceTechnicianRoleUUID}&archived=false`,
    ),

  // Additional endpoints
  getCurrencies: () => fetchProvider.get(`${microservicePath}/contracts/currencies`),
  getVATRates: () => fetchProvider.get(`${microservicePath}/contracts/vat_rates`),
  getPaymentDeadlines: () => fetchProvider.get(`${microservicePath}/contracts/payment_due_rules`),
  getBillingIntervalUnits: () =>
    fetchProvider.get(`${microservicePath}/contracts/billing_interval_units`),
  getValorizationIntervalUnits: () =>
    fetchProvider.get(`${microservicePath}/contracts/valorization_interval_units`),
  getValorizations: () => fetchProvider.get(`${microservicePath}/contracts/valorizations`),

  getStatuses: () => fetchProvider.get(`${microservicePath}/contracts/statuses`),
  getItsUserLocation: (userItsId: number | string) =>
    fetchProvider.get(`${microservicePath}/its_users_location/${userItsId}`),
  putItsUserLocation: (userItsId: number | string, locations: (string | number)[]) =>
    fetchProvider.put(`${microservicePath}/its_users_location/${userItsId}`, {
      workplaces_ids: locations,
    }),

  // Accounting Modes

  createUpdateSinglePriceListBilling: (
    accountingModeId: string | number,
    bodyRequest: tPriceList[],
  ) =>
    fetchProvider.put(
      `${microservicePath}/accounting_modes/${accountingModeId}/billable_activities`,
      bodyRequest,
    ),
  getSinglePriceListBilling: (accountingModeId: number | string) =>
    fetchProvider.get(
      `${microservicePath}/accounting_modes/${accountingModeId}/billable_activities`,
    ),
  getSpecificationUnits: () => fetchProvider.get(`${microservicePath}/specification_units`),

  // Counters

  getUnreadCounters: () => fetchProvider.get(`${microservicePath}/unread_counters`),
};

export default contracts;
