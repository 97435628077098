import { iAPIResults, iAPIData, tGetAll, tIdName } from 'types/global';
import { tGetLocations } from 'types/services/devices';
import {
  tGetAllDevicesAPI,
  tGetDeviceAPI,
  tGetLocationDevicesAPI,
  tCreateDeviceAPI,
  tUpdateDeviceAPI,
} from 'types/api/devices';

import { fetchProvider } from 'utils/Axios';

const microservicePath = '/appliances';

const devices = {
  getAll: (params: tGetAll): iAPIResults<tGetAllDevicesAPI> =>
    fetchProvider.get(`${microservicePath}/appliances`, { params }),
  get: (id: number | string): iAPIData<tGetDeviceAPI> =>
    fetchProvider.get(`${microservicePath}/appliances/${id}`),
  getLocation: (params: tGetLocations): iAPIData<tGetLocationDevicesAPI[]> =>
    fetchProvider.get(`${microservicePath}/locations`, { params }),
  getLocationTypes: (): iAPIData<tIdName[]> =>
    fetchProvider.get(`${microservicePath}/location_types`),
  getIndustries: (): iAPIData<tIdName[]> => fetchProvider.get(`${microservicePath}/industries`),
  getSpecificationUnits: (): iAPIData<tIdName[]> =>
    fetchProvider.get(`${microservicePath}/specification_units`),
  create: (data: tCreateDeviceAPI): iAPIData<tGetDeviceAPI> =>
    fetchProvider.post(`${microservicePath}/appliances`, data),
  update: (data: tUpdateDeviceAPI, id: number | string): iAPIData<tGetDeviceAPI> =>
    fetchProvider.put(`${microservicePath}/appliances/${id}`, data),
  delete: (id: number | string): iAPIData<{}> =>
    fetchProvider.delete(`${microservicePath}/appliances/${id}`),
};

export default devices;
