import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const DateText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 0.75rem;
  font-weight: 900;
  margin: 0 0.75rem;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const EmptyListText = styled.p`
  color: var(--theme-mainDarkGrey);
  font-size: 0.75rem;
`;
