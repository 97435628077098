import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useFetch } from 'hooks';
import { newUID, errorsLocFilter } from 'utils/helpers';
import devicesService from 'services/devices';
import { ExpansionPanel, Button, Form, Input, Row, Column, DropdownV2, Spinner } from 'components';
import { tSpecificationDeviceData } from 'types/services/devices';
import { tError, tDropdownOption } from 'types/global';
import { ExpansionPanelWrapper, FormWrapper, Flex } from 'styles/GlobalStyledComponents';
import { ButtonWrapper } from '../AddEditFormDevices.style';

const dataModel: tSpecificationDeviceData = {
  id: 0,
  name: '',
  value: null,
  unit: null,
};

type tGetDeviceDataSpecComponent = {
  disabled?: boolean;
  preview?: boolean;
  data: tSpecificationDeviceData[];
  setData: (data: any) => void;
  closeForm: () => void;
  devicespecId: number | null;
  errors: tError[];
};

const DeviceSpec: React.FC<tGetDeviceDataSpecComponent> = ({
  disabled = false,
  preview = false,
  devicespecId = null,
  data = [],
  setData,
  closeForm,
  errors = [],
}): JSX.Element => {
  const { t } = useTranslation();
  const {
    fetch: fetchSpecificationUnits,
    loading,
    data: specificationUnits,
  } = useFetch<tDropdownOption>(devicesService.getSpecificationUnits);

  useEffect(() => {
    fetchSpecificationUnits();
  }, [fetchSpecificationUnits]);

  const current = data?.find((el) => el?.id === devicespecId) ?? dataModel;
  const currentIndex = data.indexOf(current);
  const itemErrors = errorsLocFilter(errors, currentIndex ?? '');

  const { values, handleChange, handleSubmit } = useForm({
    initialValues: current,
    // eslint-disable-next-line no-use-before-define
    onSubmit: () => onSubmit(!!!devicespecId),
  });

  const { name, value, unit } = values;

  const onSubmit = (isAddMode: boolean) => {
    let newSpecs: tSpecificationDeviceData[] = [];
    if (isAddMode) {
      newSpecs = [...data, { id: newUID(data), name, value, unit }];
    } else {
      newSpecs = data.map((el) =>
        el?.id !== devicespecId ? el : { id: el?.id, name, value, unit },
      );
    }
    setData({ fieldName: 'specifications', label: newSpecs });
    closeForm();
  };

  const saveButtonEnabled = !!name.replace(/\s/g, '') && !!value && !!unit?.value;

  return (
    <Form onSubmit={handleSubmit}>
      {loading && <Spinner />}
      <ExpansionPanelWrapper>
        <ExpansionPanel
          hasBottomBorder
          title={t('Device spec')}
          hasIcon
          iconName='settingsApplications'
        >
          <FormWrapper>
            <Row justifyContent='space-between'>
              <Column>
                <Input
                  name='name'
                  label='Name'
                  onChange={handleChange}
                  errors={itemErrors}
                  value={name}
                  disabled={disabled}
                  preview={preview}
                />
              </Column>
              <Column col={preview ? 12 : 6}>
                <Input
                  name='value'
                  label='Value'
                  onChange={handleChange}
                  errors={itemErrors}
                  value={preview ? `${value || '-'} ${unit?.label ?? ''}` : value}
                  disabled={disabled}
                  preview={preview}
                />
              </Column>
              {!preview && (
                <Column col={6}>
                  <DropdownV2
                    name='unit'
                    label='Unit'
                    options={specificationUnits?.map((el) => ({ ...el, fieldName: 'unit' })) ?? []}
                    onChange={handleChange}
                    errors={itemErrors}
                    value={unit}
                    disabled={disabled}
                    preview={preview}
                  />
                </Column>
              )}
            </Row>
          </FormWrapper>
        </ExpansionPanel>
        <ButtonWrapper>
          <Button onClick={closeForm} variant='red' kind='outlined'>
            {t('Close')}
          </Button>
          {!preview && !disabled && (
            <>
              <Flex marginRight='1rem' />
              <Button variant='green' type='submit' disabled={!saveButtonEnabled}>
                {t('Save')}
              </Button>
            </>
          )}
        </ButtonWrapper>
      </ExpansionPanelWrapper>
    </Form>
  );
};

export default DeviceSpec;
