import { useCallback, useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import tStore from 'types/store';
import { tGetAll, tKeyString } from 'types/global';
import { tMaintenancesListRow } from 'types/views/maintenances';
import { useFetch, useCommonFilters } from 'hooks';
import { tableHead } from 'views/Issues/Maintenances/TableConfig';
import {
  PageWrapper,
  ComplexTable,
  Breadcrumbs,
  Tiles,
  ListTiles,
  ListTitle,
  Spinner,
} from 'components';
import { tFilters } from 'types/components/table';
import { ListBreadcrumb, ListTableContainer } from 'styles/GlobalStyledComponents';
import maintenances from 'services/maintenances';

interface iMaintenancesList extends RouteComponentProps<any> {}

const MaintenancesList = ({ history }: iMaintenancesList): JSX.Element => {
  const { t } = useTranslation();

  const permissions = useSelector((state: tStore) => state.user.permissions);
  const canWrite = permissions.includes('tunSubcontractorsPeriodicInspectionsWrite');

  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [sort, setSort] = useState<tKeyString>({ by: '', order: '' });
  const [filters, setFilters] = useState<tFilters | null>(null);

  const {
    fetch: fetchMaintenancesPlans,
    loading,
    data,
    count,
    filters: filtersToData,
  } = useFetch<tMaintenancesListRow, tGetAll & { filters: any }>(
    maintenances.getAll,
    'Something went wrong. Try again',
  );

  const { tableColumns } = useCommonFilters(filtersToData, tableHead);

  const fetchData = useCallback(() => {
    fetchMaintenancesPlans({
      limit,
      offset,
      ordering: sort,
      search,
      filters: !!filters ? filters : [],
    });
  }, [fetchMaintenancesPlans, limit, offset, sort, search, filters]);

  const setRequestParams = useCallback(
    (newOffset: number, newLimit: number) => {
      if (newOffset !== offset) setOffset(newOffset);
      if (newLimit !== limit) setLimit(newLimit);
    },
    [limit, offset],
  );

  useEffect(() => {
    setOffset(0);
  }, [search]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const showMaintenanceDetails = (id: number | string) =>
    history.push(`/issues/maintenances/${id}`);
  const addMaintenance = () => history.push('/issues/maintenances/new');

  const dataWithFrequency = data.map((row) => ({
    ...row,
    frequency:
      row?.intervalValue && row?.intervalUnit ? `${row.intervalValue} ${t(row.intervalUnit)}` : '',
  }));

  const onChange = ({ target: { value } }) => setSearch(value);

  const setParams = (offset: number, limit: number) => setRequestParams(offset, limit);

  const setSortParams = (by: string, order: string) => {
    setSort({ by, order });
  };

  const crumbs = [{ url: `/issues/maintenances`, label: `${t('Maintenances plan')}` }];

  return (
    <PageWrapper>
      {loading && <Spinner />}
      <ListBreadcrumb>
        <Breadcrumbs crumbs={crumbs} />
      </ListBreadcrumb>
      <ListTitle title={t('Maintenances plan')} />
      <ListTiles>
        <Tiles
          text={t('New maintenance plan')}
          icon='playlistAdd'
          color='orange'
          onClick={addMaintenance}
          variant='filled'
          disabled={!canWrite}
        />
      </ListTiles>
      <ListTableContainer>
        <ComplexTable
          isRowClickable
          onRowClick={showMaintenanceDetails}
          tableBodyData={dataWithFrequency}
          tableHead={tableColumns}
          totalRecords={count}
          setParams={setParams}
          tableName='Maintenances plans list'
          limit={limit}
          offset={offset}
          value={search}
          onChange={onChange}
          setSortParams={setSortParams}
          setFilters={setFilters}
          filtersKey='maintenancesFilters'
        />
      </ListTableContainer>
    </PageWrapper>
  );
};

export default withRouter(MaintenancesList);
