import styled from 'styled-components';

export const FileUploadInput = styled.input`
  display: none;
  visibility: hidden;
`;

export const AddFileWrapper = styled.p`
  width: 40px;
  height: 40px;
  border: 1px dashed var(--theme-mainGrey);
  border-radius: var(--theme-borderRadius);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;
  margin: 0;
`;

export const FileInfoWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  margin: 0 1rem;
  min-height: 40px;
  width: 100%;
`;

export const FileName = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 900;
  font-size: 0.75rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 260px;
`;

export const FileWrapper = styled.div`
  padding-bottom: var(--theme-spacing-lg);
  display: flex;
  align-items: center;
`;

export const FileInfoData = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-right: 1rem;
`;

export const TableWrapper = styled.table`
  margin-bottom: 1rem;
  width: 100%;
`;

export const LabelWrapper = styled.td`
  vertical-align: top;
  width: 90px;
  padding-top: 1rem;
`;

export const FileSize = styled.p`
  font-size: 0.75rem;
  padding: 0;
  margin-top: 0.5rem;
  color: var(--theme-mainGrey);
`;
