import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import common from './pl/common.json';
import components from './pl/components.json';
import errors from './pl/errors.json';
import login from './pl/login.json';
import clients from './pl/clients.json';
import contracts from './pl/contracts.json';
import devicesAndFacilities from './pl/devicesAndFacilities.json';
import issues from './pl/issues.json';
import itsStructure from './pl/itsStructure.json';
import users from './pl/users.json';
import mru from './pl/mru.json';
import invoices from './pl/invoices.json';
import subcontractors from './pl/subcontractors.json';
import representative from './pl/representative.json';
import workers from './pl/workers.json';
import maintenance from './pl/maintenance.json';
import units from './pl/units.json';
import reports from './pl/reports.json';

const resources = {
  pl: {
    translation: {
      ...common,
      ...components,
      ...errors,
      ...login,
      ...clients,
      ...contracts,
      ...devicesAndFacilities,
      ...issues,
      ...itsStructure,
      ...users,
      ...mru,
      ...invoices,
      ...subcontractors,
      ...representative,
      ...workers,
      ...maintenance,
      ...units,
      ...reports,
    },
  },
};
const DEFAULT_LANGUAGE = 'pl';

export default () => {
  i18n.use(initReactI18next).init({
    resources,
    lng: DEFAULT_LANGUAGE,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    returnNull: false,
    returnObjects: false,
  });
  return i18n;
};
