import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Checkbox, Button } from 'components';
import { colorBlueBasic } from 'styles/GlobalStyles';
import {
  Wrapper,
  Container,
  ButtonsContainer,
  CloseButton,
  Title,
  FilterHeader,
} from './ColumnFilter.style';

type tColumnFilter = {
  filters: any;
  onClick: (checked: {}) => void;
  actualColumnFilters: {};
};

const ColumnFilter: React.FC<tColumnFilter> = ({ actualColumnFilters, filters, onClick }) => {
  const { t } = useTranslation();
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [checked, setChecked] = useState({});

  useEffect(() => {
    const marked = {};
    filters.forEach((filter) => {
      marked[filter.key] = true;
    });
    setChecked(marked);
    onClick(marked);
  }, []);

  const closeFiltersWithoutSaving = () => {
    setChecked(actualColumnFilters);
    setShowFilters(false);
  };

  const changeColumnVisibility = (filter) => {
    let visibleColumnCounter = -1;
    Object.keys(checked).forEach((item) => {
      if (checked[item]) {
        // eslint-disable-next-line no-plusplus
        visibleColumnCounter++;
      }
    });

    if (visibleColumnCounter > 1) {
      setChecked({
        ...checked,
        [filter.key]: !checked[filter.key],
      });
    } else if (!checked[filter.key]) {
      setChecked({
        ...checked,
        [filter.key]: !checked[filter.key],
      });
    }
  };

  const calcVisibleColumns = () => {
    const visibleFilters = Object.keys(checked).filter(
      (singleFilter) => checked[singleFilter] === true,
    );
    return visibleFilters.length - 1;
  };

  return (
    <Wrapper>
      <Icon
        asButton
        icon='column'
        onClick={() => setShowFilters(!showFilters)}
        fill={colorBlueBasic}
      />
      {showFilters && (
        <Container>
          <FilterHeader>
            {t('Select columns')}: {calcVisibleColumns()}
          </FilterHeader>
          {filters.map(
            (filter) =>
              filter.key !== 'order' && (
                <div key={filter.key} style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    name={filter.key}
                    onChange={() => {
                      changeColumnVisibility(filter);
                    }}
                    checked={checked[filter.key]}
                    errors={[]}
                    wrapperStyles={{ marginBottom: '0.75rem', minHeight: 'unset' }}
                  />
                  <Title>{t(filter.name)}</Title>
                </div>
              ),
          )}
          <div style={{ marginBottom: '1rem' }} />
          <ButtonsContainer>
            <CloseButton onClick={closeFiltersWithoutSaving}>{t('Close')}</CloseButton>
            <Button
              variant='blue'
              onClick={() => {
                onClick(checked);
                setShowFilters(false);
              }}
            >
              {t('Select')}
            </Button>
          </ButtonsContainer>
        </Container>
      )}
    </Wrapper>
  );
};

export default ColumnFilter;
