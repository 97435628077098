import { TooltipBox, TooltipText, TooltipCard } from './Tooltip.styles';

type tTooltip = {
  children: string;
  element: JSX.Element | any;
};

const Tooltip: React.FC<tTooltip> = ({ children, element }): JSX.Element => {
  return (
    <TooltipCard>
      <TooltipText>{element}</TooltipText>
      <TooltipBox>{children}</TooltipBox>
    </TooltipCard>
  );
};

export default Tooltip;
