export const REQUIRED_FIELDS = {
  SUBCONTRACTORS_DATA_FORM: {
    MAIN: [
      'name',
      'country',
      'sap_number',
      'nip',
      'phone_number',
      'regon',
      'legal_form_id',
      'company_size_id',
      'industry_ids',
      'contact_person',
      'region',
      'city',
      'street',
      'building_number',
      'postal_code',
    ],
    WORKING_AREA: ['name', 'locations'],
    REPRESENTATIVE_DATA: ['representative_id'],
  },
  ISSUES_FORM: [
    'contract_address_id',
    'appliance_industry_id',
    'short_description',
    'description',
    'accounting_mode_id',
  ],
  CLIENTS_FORM: [
    'name',
    'sap_number',
    'segment_id',
    'legal_form_id',
    'nip',
    'regon',
    'type',
    'region_id',
    'city',
    'street',
    'building_number',
    'postal_code',
  ],
  USERS_FORM: {
    MAIN: ['first_name', 'last_name', 'position', 'phone_number', 'email', 'company_id'],
    LOCATION: ['workplaces_ids'],
  },
  ITS_STRUCTURE_FORM: {
    MAIN: [
      'first_name',
      'last_name',
      'phone_number',
      'email',
      'email',
      'personal_id_number',
      'external_system_id',
      'position',
      'supervisors_ids',
    ],
    LOCATION: ['workPlace'],
  },
  MRU_FORM: [
    'name',
    'region_id',
    'latitude',
    'longitude',
    'city',
    'street',
    'building_number',
    'postal_code',
  ],
  DEVICES_FORM: ['model', 'manufacturer', 'serial_number', 'industry_id', 'location_id'],
  REPRESENTATIVES_FORM: [
    'company_id',
    'first_name',
    'last_name',
    'personal_id_number',
    'phone_number',
    'email',
  ],
  WORKERS_FORM: ['first_name', 'last_name', 'personal_id_number', 'phone_number', 'email'],
  MAINTENANCES_FORM: [
    'name',
    'client_id',
    'contract_id',
    'refers_to',
    'industry_id',
    'maintenance_operations',
    'starting_from',
    'maintenance_operations',
    'maintenance_locations',
    'interval_value',
    'interval_unit',
    'advance_period_value',
    'advance_period_unit',
    'accounting_mode_id',
  ],
  CONTRACTS_FORM: {
    MAIN: ['name', 'admin_name', 'start', 'client_id'],
    FINANCIAL_CONDITIONS: [
      'currency',
      'vat_rate',
      'payment_due_date',
      'payment_due_rule',
      'billing_interval',
      'billing_interval_unit',
      'billing_period_days',
      'billing_approval',
      'valorization_interval',
      'valorization_interval_unit',
    ],
    SINGLE_SETTLEMENT_MODE_FORM: {
      MAIN: [
        'name',
        'days_type',
        'hours_type',
        'validity_hours_type',
        'reaction_time',
        'reaction_time_unit',
        'industry_ids',
        'attributes',
      ],
      ATTRIBUTES: ['value', 'sla_price_id'],
    },
    SINGLE_PERIODIC_ACTIVITIES_FORM: [
      'name',
      'type',
      'appliance_industry_id',
      'service_activities',
      'mruk_sites_ids',
      'maintenance_interval',
      'maintenance_interval_unit',
    ],
    SINGLE_PRICE_LIST_BILLING_ACTIVITIES_FORM: ['unit_id', 'net'],
    LOCATION: {
      LOCATION_FORM: ['name'],
      WORKING_HOURS_FORM: ['since', 'to'],
      SINGLE_USERS_FORM: ['regional_admins'],
      SINGLE_TECHNICAL_MANAGERS_ITS_FORM: [
        'sub_contract_number',
        'controlling_order_number',
        'industries_ids',
      ],
    },
    SAP_STATUS_FORM: ['sap_id', 'status'],
    CONTRACTUAL_PENALTIES: ['name', 'type', 'unit_amount', 'penalty_unit'],
    CONTRACTUAL_PENALTIES_FORM_DISCOUNT: [
      'name',
      'type',
      'unit_amount',
      'penalty_unit',
      'discount',
      'discount_type',
    ],
    CONTRACTUAL_PENALTIES_FORM_MONEY_AMOUNT: [
      'name',
      'type',
      'unit_amount',
      'penalty_unit',
      'money_amount',
    ],
  },
  SUBCONTRACTORS_CONTRACTS_FORM: {
    MAIN: ['subcontractor_id', 'name', 'client_id', 'client_contract_id', 'start'],
    FINANCIAL_CONDITIONS: [
      'vat_rate',
      'payment_due_date',
      'payment_due_rule',
      'billing_interval',
      'billing_interval_unit',
      'billing_period_days',
    ],
    STATUS: ['status'],
  },
  REPORT_TECH_MANAGER_FORM: ['client_contract_id', 'client', 'month', 'year'],
  REPORT_REGIONAL_ADMIN_FORM: {
    MONTHLY: ['client_contract_id', 'report_type', 'month', 'year'],
    QUARTERLY: ['client_contract_id', 'report_type', 'quarter', 'year'],
  },
};

export const POSTAL_CODE_LENGTH = 6;
