import { SeparatorCell, tHorizontalBorder } from './InvoiceTable.style';

export type tSeparator = {
  hideLine?: boolean;
  horizontalBorder?: tHorizontalBorder;
  style?: React.CSSProperties;
};

const Separator: React.FC<tSeparator> = ({ hideLine = false, horizontalBorder, style }) => (
  <>
    <SeparatorCell
      separator={hideLine ? null : 'right'}
      horizontalBorder={horizontalBorder}
      style={style}
    />
    <SeparatorCell horizontalBorder={horizontalBorder} style={style} />
  </>
);

export default Separator;
