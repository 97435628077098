import { tNotificationState, tAction } from 'types/store';
import { notificationsLimit } from 'utils/constants';
import types from './types';

export const initState: tNotificationState = {
  notifications: [],
  position: 'top-right',
};

// eslint-disable-next-line default-param-last
export default (state = initState, action: tAction): tNotificationState => {
  switch (action.type) {
    case types.NOTIFICATION_CREATE: {
      const { message = '-', type = 'error', timeout = 0, id = Date.now() } = action.payload;
      return {
        ...state,
        notifications: [
          ...state.notifications.slice(-notificationsLimit + 1),
          { id, show: true, message, type, timeout },
        ],
      };
    }
    case types.NOTIFICATION_REMOVE:
    case types.NOTIFICATION_TIMEOUT: {
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.id !== action.payload,
        ),
      };
    }
    case types.NOTIFICATION_REMOVE_ALL: {
      return {
        ...state,
        notifications: [],
      };
    }
    case types.NOTIFICATION_POSITION: {
      return {
        ...state,
        position: action.payload,
      };
    }
    default:
      return { ...state };
  }
};
