import styled from 'styled-components';

export const SearchContainer = styled.div`
  min-width: 230px;
  border-bottom: 1px solid var(--theme-mainGrey);
  display: flex;
  padding-bottom: 3px;
  &:hover {
    border-color: var(--theme-mainBlue);
  }
  &:focus-within {
    border-color: var(--theme-mainBlue);
  }
`;

export const SearchInput = styled.input`
  border: 0;
  outline: 0;
  background: transparent;
  margin-left: 5px;
  font-size: 0.875rem;
`;
