import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const TreeTitle = styled.p`
  display: flex;
  line-height: 1.5rem;
  margin: 0;
  padding: 0;
  margin-right: 0.5rem;
`;
export const BoldTreeTitle = styled(TreeTitle)`
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.75rem;
  margin-left: 0.5rem;
`;
