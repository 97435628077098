import { useTranslation } from 'react-i18next';
import { Button } from 'components';
import { LeftMargin1Rem } from 'styles/GlobalStyledComponents';
import issuesService from 'services/issues';

export type tCancelIssueAction = {
  history: { push: Function };
  actions: { [key: string]: string };
  setNotification: Function;
};

const CancelIssueAction: React.FC<tCancelIssueAction> = ({ history, actions, setNotification }) => {
  const { t } = useTranslation();
  const { cancel } = actions;

  const cancelIssueHandler = async () => {
    try {
      const result = await issuesService.rejectAcceptIssue(cancel);
      if (result?.status === 200) {
        setNotification(t('Issue was canceled'), 'success');
        history.push('/issues');
      }
    } catch (err) {
      if (err?.status === 409) return setNotification(err.data[0].msg);
      setNotification(t('Something went wrong. Try again'));
    }
  };

  return (
    <LeftMargin1Rem>
      <Button variant='redFilled' onClick={cancelIssueHandler} disabled={!!!cancel}>
        {t('Cancel')}
      </Button>
    </LeftMargin1Rem>
  );
};

export default CancelIssueAction;
