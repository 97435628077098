import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { login } from 'store/auth/actions';

import { Button } from 'components';
import LoginPageWrapper from './LoginPageWrapper';

const Login: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const ssoLoginHandler = () => {
    history.push('/issues');
    dispatch(login());
  };

  return (
    <LoginPageWrapper>
      <Button variant='blue' icon='login' onClick={ssoLoginHandler}>
        {t('Login with GID')}
      </Button>
    </LoginPageWrapper>
  );
};

export default Login;
