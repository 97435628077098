import { useTranslation } from 'react-i18next';
import { colorWhite } from 'styles/GlobalStyles';
import Icon from 'components/Icon';
import {
  EventCardContainer,
  EventCardHeader,
  EventCardContent,
  EventCardFooter,
  ClientName,
  FooterContent,
  SmallElement,
  SmallText,
} from './EventCard.style';

type tEventCard = {
  type: string;
  client?: string;
  description: string;
  deadline: number;
};

export type tEventCardContainer = {
  type: string;
};

const EventCard: React.FC<tEventCard> = ({
  type,
  client = '',
  description,
  deadline,
}): JSX.Element => {
  const { t } = useTranslation();

  const getEventIcon = (type) => {
    switch (type) {
      case 'complaint': {
        return <Icon icon='reportProblem' width='1.1rem' height='1.1rem' />;
      }
      case 'overview': {
        return <Icon icon='calendarToday' width='1.3rem' height='1.3rem' />;
      }
      case 'repair': {
        return <Icon icon='repair' width='1.3rem' height='1.3rem' />;
      }
      default:
        return null;
    }
  };

  const renderFooter = (deadline) => {
    if (deadline) {
      return (
        <FooterContent>
          {t('Expires in')} {deadline} {t('days')}
        </FooterContent>
      );
    }
    return (
      <SmallElement>
        <SmallText>{t('After deadline')}</SmallText>
        <Icon icon='errorFilled' width='1rem' height='1rem' fill={colorWhite} />
      </SmallElement>
    );
  };

  const getEventType = (deadline) => {
    // TO DO WHEN API READY
    if (deadline < 3 && deadline > 0) {
      return 'closeDate';
    }
    if (!deadline) {
      return 'afterDeadline';
    }
    return 'safeDate';
  };

  return (
    <EventCardContainer type={getEventType(deadline)}>
      <EventCardHeader>
        {getEventIcon(type)}
        {client && <ClientName>{client}</ClientName>}
      </EventCardHeader>
      <EventCardContent>{description} </EventCardContent>
      <EventCardFooter>{renderFooter(deadline)}</EventCardFooter>
    </EventCardContainer>
  );
};

export default EventCard;
