import styled from 'styled-components';
import { tWeekDayBox } from './Timetable';

export const CheckboxInput = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
  appearance: none;
  outline: 0;
`;

export const ListWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  box-sizing: border-box;
  @media screen and (max-width: 1600px) {
    display: block;
  }
`;

export const WeekDayBox = styled.button<tWeekDayBox>`
  width: 32px;
  height: 32px;
  background-image: ${(props) =>
    props.isChecked ? 'var(--theme-gradientBlue)' : 'var(--theme-lightGrey)'};
  border-radius: 50%;
  color: var(--theme-white);
  font-weight: 900;
  margin-right: 0.5rem;
  outline: 0;
  border: none;
  padding: 0.5rem;
  cursor: ${({ preview }) => (preview ? 'default' : 'pointer')};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 0.75rem;
`;

export const ListItemWrapper = styled.li`
  display: none;
`;

export const Wrapper = styled.div`
  width: 100%;
  text-align: center;
`;

export const TimepickerWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

export const TimepickerMiddleSign = styled.span`
  margin: 0 0.75rem;
  color: var(--theme-mainGrey);
  display: flex;
  align-items: center;
`;

export const CheckboxWrapper = styled.span`
  margin-left: 1rem;
  width: 25%;
  display: flex;
  @media screen and (max-width: 1600px) {
    margin-top: 0.5rem;
    margin-left: 6.625rem;
  }
`;

export const CopyWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: left;
  cursor: pointer;
  border: none;
  background: none;
  outline: 0;
  overflow: initial;
`;

export const CopyText = styled.p`
  color: var(--theme-mainDarkGrey);
  font-size: 0.75rem;
  padding: 0;
  margin: 0;
  margin-left: 0.5rem;
`;

export const LeftSideWrapper = styled.div`
  width: 75%;
  display: flex;
`;

export const RightSideWrapper = styled.div`
  width: 25%;
  display: flex;
  @media screen and (max-width: 1600px) {
    margin-top: 0.5rem;
  }
`;
