/* eslint-disable no-param-reassign */
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Icon } from 'components';
import Filters from 'components/Table/Filters';
import List from 'components/Table/List';
import { Flex } from 'styles/GlobalStyledComponents';
import { useFilters } from 'hooks';
import { tFilters, tTableHead } from 'types/components/table';
import {
  getWeekNumber,
  getDateRangeOfWeek,
  getFirstDayOfTheWeek,
  capitalizeFirst,
} from 'utils/helpers';
import { colorGreyBasic, colorBlueBasic } from 'styles/GlobalStyles';
import {
  DashboardCalendarContainer,
  HorizontalLine,
  Calendar,
  UnplannedEventsContainer,
  DayContainerHeader,
  EventsCardsContainer,
  DayContainer,
  DayName,
  HolidayName,
  UnplannedHeader,
  FilterTitle,
  WeekHandlerWrapper,
  WeekText,
  WeekArrowsWrapper,
  DateWrapper,
  DateText,
  ListFiltersWrapper,
  StyledHWrapper,
  LastListFiltersWrapper,
} from './DashboardCalendar.style';
import EventCard from './EventCard/EventCard';

const holidayDays = [
  {
    name: 'Nowy Rok',
    date: '01/01/2020',
  },
  {
    name: 'Święto Trzech Króli',
    date: '06/01/2020',
  },
  {
    name: 'Poniedziałek Wielkanocny',
    date: '13/04/2020',
  },
  {
    name: 'Wielkanoc',
    date: '14/04/2020',
  },
  {
    name: 'Święto Pracy',
    date: '01/05/2020',
  },
  {
    name: 'Święto Narodowe Trzeciego Maja',
    date: '03/05/2020',
  },
  {
    name: 'Zielone Świątki',
    date: '31/05/2020',
  },
  {
    name: 'Boże Ciało',
    date: '11/06/2020',
  },
  {
    name: 'Wniebowzięcie Najświętszej Marii Panny',
    date: '15/08/2020',
  },
  {
    name: 'Wszystkich Świętych',
    date: '01/11/2020',
  },
  {
    name: 'Narodowe Święto Niepodległości',
    date: '11/11/2020',
  },
  {
    name: 'Katarzynki',
    date: '24/11/2020',
  },
  {
    name: 'Drugi Dzień Bożego Narodzenia',
    date: '26/12/2020',
  },
];

const weekDays = {
  monday: {
    date: '23/11/2020',
    isDayOff: false,
    events: [
      {
        type: 'repair',
        description: 'Lorem ipsum dolor, sit amet consectetur',
        deadline: 1,
      },
      {
        type: 'overview',
        description: 'Lorem ipsum dolor, sit amet consectetur',
        deadline: 0,
      },
      {
        type: 'overview',
        description: 'Lorem ipsum dolor, sit amet consectetur',
        deadline: 12,
      },
      {
        type: 'complaint',
        description: 'Lorem ipsum dolor, sit amet consectetur',
        deadline: 30,
      },
    ],
    holiday: '',
  },
  tuesday: {
    date: '24/11/2020',
    isDayOff: false,
    events: [],
    holiday: '',
  },
  wednesday: {
    date: '25/11/2020',
    isDayOff: false,
    events: [
      {
        type: 'overview',
        description: 'Lorem ipsum dolor, sit amet consectetur',
        deadline: 0,
      },
      {
        type: 'overview',
        description: 'Lorem ipsum dolor, sit amet consectetur',
        deadline: 12,
      },
      {
        type: 'complaint',
        description: 'Lorem ipsum dolor, sit amet consectetur',
        deadline: 30,
      },
    ],
    holiday: '',
  },
  thursday: {
    date: '26/11/2020',
    isDayOff: false,
    events: [
      {
        type: 'repair',
        description: 'Lorem ipsum dolor, sit amet consectetur',
        deadline: 0,
      },
    ],
    holiday: '',
  },
  friday: {
    date: '27/11/2020',
    isDayOff: false,
    events: [
      {
        type: 'repair',
        description: 'Lorem ipsum dolor, sit amet consectetur',
        deadline: 1,
      },
      {
        type: 'complaint',
        description: 'Lorem ipsum dolor, sit amet consectetur',
        deadline: 30,
      },
    ],
    holiday: '',
  },
  saturday: {
    date: '28/11/2020',
    isDayOff: true,
    events: [],
    holiday: '',
  },
  sunday: {
    date: '29/11/2020',
    isDayOff: true,
    events: [],
    holiday: '',
  },
};

const unplannedEvents = [
  { deadline: 12, type: 'repair', description: 'Lorem ipsum dolor, sit amet consectetur' },
  {
    deadline: 1,
    type: 'repair',
    description: 'Lorem ipsum dolor, sit ametdfg',
  },
  { deadline: 0, type: 'repair', description: 'Lorem ipsum dolor, sit ametdfg' },
];

type tDashboardCalendar = {
  setFilters: (filters: tFilters) => void;
  filters: tTableHead[];
  filtersKey?: string;
};

type tDaysOfTheWeek = {
  [key: string]: {
    date: string;
    isDayOff: boolean;
    events: {
      type: string;
      description: string;
      deadline: number;
    }[];
    holiday: string;
  };
};

const DashboardCalendar: React.FC<tDashboardCalendar> = ({
  setFilters: setTableFilters,
  filters: mainFilters,
  filtersKey = '',
}) => {
  const { t } = useTranslation();
  const [filters, removeFilter, clearFilters, changeFilters, setFilters] = useFilters(filtersKey);
  const [showWorkingDays, setShowWorkingDays] = useState(false);
  const [chosenDate, setChosenDate] = useState<Date>(new Date());
  const [weekNumber, setWeekNumber] = useState<number>(getWeekNumber(new Date()));
  const [firstDayOfTheWeek, setFirstDayOfTheWeek] = useState<Date | string>();
  const [lastDayOfTheWeek, setLastDayOfTheWeek] = useState<Date | string>();
  const [daysOfTheWeek, setDaysOfTheWeek] = useState<tDaysOfTheWeek>(weekDays);

  useEffect(() => {
    setTableFilters(filters);
  }, [filters]);

  useEffect(() => {
    const daysOfTheWeekCopy = { ...daysOfTheWeek };
    Object.values(daysOfTheWeekCopy).forEach((weekDay) => {
      holidayDays.forEach((holidayDay) => {
        if (holidayDay.date === weekDay.date) {
          weekDay.holiday = holidayDay.name;
          weekDay.isDayOff = true;
        }
      });
    });
    setDaysOfTheWeek(daysOfTheWeekCopy);
  }, [holidayDays]);

  const setPrevNextWeek = (action) => {
    setChosenDate(getFirstDayOfTheWeek(chosenDate));

    const days = action === 'prev' ? chosenDate.getDate() - 7 : chosenDate.getDate() + 7;
    const updatedChosenDate = new Date(chosenDate.getFullYear(), chosenDate.getMonth(), days);
    setChosenDate(updatedChosenDate);

    const updatedWeekNumber = getWeekNumber(updatedChosenDate);
    setWeekNumber(updatedWeekNumber);

    const weekRange = getDateRangeOfWeek(updatedChosenDate);
    const [rangeIsFrom, rangeIsTo] = weekRange;
    setFirstDayOfTheWeek(rangeIsFrom);
    setLastDayOfTheWeek(rangeIsTo);
  };

  useEffect(() => {
    setChosenDate(new Date(chosenDate.getFullYear(), chosenDate.getMonth(), chosenDate.getDate()));
    setWeekNumber(getWeekNumber(chosenDate));
    setChosenDate(getFirstDayOfTheWeek(chosenDate));

    const datesFromWeek = getDateRangeOfWeek(chosenDate);
    setFirstDayOfTheWeek(datesFromWeek[0]);
    setLastDayOfTheWeek(datesFromWeek[1]);
  }, []);

  const renderEventsList = () => {
    // Give proper key to values from map TO DO
    return showWorkingDays
      ? Object.keys(daysOfTheWeek).map(
          (day) =>
            !daysOfTheWeek[day].isDayOff && (
              <DayContainer key={day} isDayOff={daysOfTheWeek[day].isDayOff}>
                <DayContainerHeader>
                  <DayName> {t(capitalizeFirst(day))}</DayName>
                  {daysOfTheWeek[day].holiday && (
                    <HolidayName>{t(capitalizeFirst(daysOfTheWeek[day].holiday))}</HolidayName>
                  )}
                </DayContainerHeader>
                <EventsCardsContainer>
                  {daysOfTheWeek[day].events.map((event) => (
                    <EventCard
                      key={day}
                      type={event.type}
                      deadline={event.deadline}
                      description={event.description}
                    />
                  ))}
                </EventsCardsContainer>
              </DayContainer>
            ),
        )
      : Object.keys(daysOfTheWeek).map((day) => (
          <DayContainer key={day} isDayOff={daysOfTheWeek[day].isDayOff}>
            <DayContainerHeader>
              <DayName> {t(capitalizeFirst(day))}</DayName>
              {daysOfTheWeek[day].holiday && (
                <HolidayName>{t(capitalizeFirst(daysOfTheWeek[day].holiday))}</HolidayName>
              )}
            </DayContainerHeader>
            <EventsCardsContainer>
              {daysOfTheWeek[day].events.map((event) => (
                <EventCard
                  key={day}
                  type={event.type}
                  deadline={event.deadline}
                  description={event.description}
                />
              ))}
            </EventsCardsContainer>
          </DayContainer>
        ));
  };

  const renderUnplannedEvents = () => {
    // Give proper key to values from map TO DO
    return (
      <EventsCardsContainer>
        {unplannedEvents.map((event, index) => (
          <EventCard
            key={index}
            deadline={event.deadline}
            type={event.type}
            description={event.description}
          />
        ))}
      </EventsCardsContainer>
    );
  };

  const renderWeekHandler = () => (
    <WeekHandlerWrapper>
      <WeekArrowsWrapper>
        <Icon
          icon='chevronLeft'
          asButton
          onClick={() => setPrevNextWeek('prev')}
          fill={colorGreyBasic}
          width='1rem'
          height='1rem'
        />
        <WeekText>
          {t('week')} {weekNumber}
        </WeekText>
        <Icon
          icon='chevronRight'
          asButton
          onClick={() => setPrevNextWeek('next')}
          fill={colorGreyBasic}
          width='1rem'
          height='1rem'
        />
      </WeekArrowsWrapper>

      <DateWrapper>
        <Icon icon='dateRange' fill={colorBlueBasic} />
        <DateText>{`${firstDayOfTheWeek}  —  ${lastDayOfTheWeek}`}</DateText>
      </DateWrapper>
    </WeekHandlerWrapper>
  );

  return (
    <>
      <List filters={filters} removeFilter={removeFilter} removeFilters={() => setFilters({})} />
      <DashboardCalendarContainer>
        {!!mainFilters?.length && (
          <Flex>
            <Flex style={{ alignItems: 'flex-end' }}>
              {filters &&
                mainFilters.map((item, index) => (
                  <StyledHWrapper key={item.key}>
                    <FilterTitle>{t(item.name)}</FilterTitle>
                    <Filters
                      filters={item.filters ?? []}
                      onChange={(val) => changeFilters(val, item.key)}
                      checked={filters[item.key] ?? []}
                      clearFilters={() => clearFilters(item.name)}
                      position={index === 0 ? 'right' : 'left'}
                    />
                  </StyledHWrapper>
                ))}
            </Flex>
            <ListFiltersWrapper justifyContent='center'>{renderWeekHandler()}</ListFiltersWrapper>
            <LastListFiltersWrapper justifyContent='flex-end'>
              <Checkbox
                name='onlyWorkingDays'
                errors={[]}
                onChange={() => setShowWorkingDays(!showWorkingDays)}
                label={t('Only working days')}
                titleStyles={{ color: 'black', fontWeight: 900 }}
                checked={showWorkingDays}
              />
            </LastListFiltersWrapper>
          </Flex>
        )}
        <HorizontalLine />
        <Calendar>
          <UnplannedEventsContainer isDayOff={false}>
            <DayContainerHeader>
              <UnplannedHeader>{t('Unplanned')} (0)</UnplannedHeader>
            </DayContainerHeader>
            <EventsCardsContainer>{renderUnplannedEvents()}</EventsCardsContainer>
          </UnplannedEventsContainer>
          {renderEventsList()}
        </Calendar>
      </DashboardCalendarContainer>
    </>
  );
};

export default DashboardCalendar;
