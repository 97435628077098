import styled from 'styled-components';
import { tAvatarImage } from './Avatar';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
`;

export const AvatarInputWrapper = styled.div`
  border: 1px dashed var(--theme-mainGrey);
  width: 48px;
  height: 48px;
  border-radius: 100px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

export const AvatarInput = styled.input`
  display: none;
  visibility: hidden;
`;

export const AvatarImage = styled.img<tAvatarImage>`
  width: 48px;
  height: 48px;
  border-radius: 100px;
`;

export const ImageButton = styled.button`
  display: inline;
  padding: 0;
  margin: 0;
  background: transparent;
  border-radius: 100px;
  position: relative;
  border: none;
`;
export const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 100px;
  z-index: 100;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(245, 246, 250, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AvatarSingleImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 100px;
  border: 1px solid var(--theme-mainGrey);
`;

export const DefaultIconWrapper = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 100px;
  border: 1px solid var(--theme-mainGrey);
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;
