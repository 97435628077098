import styled from 'styled-components';
import { Button } from 'components/Button/Button.style';

export const JumpToPageText = styled.div`
  padding-right: var(--theme-spacing);
  font-size: 0.75rem;
  align-self: center;
  color: var(--theme-mainGrey);
`;

export const JumpToPageInput = styled.input`
  border: none;
  outline: none;
  font-size: 0.75rem;
  max-width: 50px;
`;

export const JumpToPageBlock = styled.div`
  display: flex;
  align-items: center;
  margin-left: 3rem;
  @media screen and (max-width: 1099px) {
    margin-left: 0;
    justify-content: center;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  border-radius: var(--theme-borderRadius);

  @media screen and (max-width: 1099px) {
    overflow-x: auto;
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }
  .pagination {
    list-style: none;
    margin: 0;
    padding: 0.5rem;
    display: flex;
    li {
      margin-right: var(--small-padding);
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 0.75rem;
      border: 1px solid var(--theme-mainGrey);
      border-radius: var(--theme-borderRadius);
      &:hover:not(.active) {
        border: 1px solid var(--theme-mainBlue);
      }
      &.next {
        margin-right: 0;
        &:hover {
          border: 0;
        }
      }
      &.previous {
        &:hover {
          border: 0;
        }
      }
      &.disabled {
        svg {
          fill: var(--theme-mainGrey);
        }
      }
      &.active {
        background: var(--theme-gradientBlue);
        border-radius: var(--theme-borderRadius);
        a {
          color: var(--theme-white);
        }
      }
      &.break-me {
        border: none;
        pointer-events: none;
      }
      a {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 3px var(--medium-padding);
        outline: none;
        font-size: 0.75rem;
      }
      &:last-child {
        border: none;
      }
      &:first-child {
        border: none;
      }
    }
  }
`;

export const Pages = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 1099px) {
    overflow-x: auto;
    width: 100%;
    justify-content: center;
    margin-bottom: var(--small-padding);
  }
`;

export const Results = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  line-height: 19px;
  position: relative;
  @media screen and (max-width: 1099px) {
    margin-bottom: var(--small-padding);
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: -3px;
    right: -5px;
    background: var(--theme-white);
    box-shadow: var(--theme-mainShadow);
    border-radius: var(--theme-borderRadius);
    overflow: hidden;
    li {
      button {
        padding: var(--theme-spacing-xs) var(--theme-spacing-sm);
      }
      &:hover {
        background: var(--theme-gradientBlue);
        button {
          color: var(--theme-white);
        }
      }
    }
  }
`;

export const ResultsButton = styled<any>(Button)`
  font-size: 0.75rem;
  width: 100%;
  display: flex;
  align-items: center;
  svg {
    margin-left: 5px;
  }
  span {
    font-size: 0.75rem;
    margin-left: var(--medium-padding);
  }
`;

export const Wrapper = styled.footer`
  z-index: calc(var(--theme-containerZIndex) + 4);
  box-shadow: var(--theme-mainShadow);
  border-radius: var(--theme-borderRadius);
  position: sticky;
  bottom: 0;
  right: 0;
  background: var(--theme-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--theme-spacing) calc(var(--theme-spacing) * 2);
  @media screen and (max-width: 1099px) {
    left: 0;
  }
  @media screen and (max-width: 1099px) {
    flex-direction: column;
    padding: var(--medium-padding);
  }
`;

export const ResultsContainer = styled.p`
  display: flex;
  flex-grow: 1;
  align-items: center;
  border: 1px solid var(--theme-mainGrey);
  border-radius: var(--theme-paginationRadius);
  max-width: 6rem;
  min-width: 3rem;
  padding: 5px var(--medium-padding);
  justify-content: space-between;
`;

export const ResultsTitle = styled.p`
  color: var(--theme-mainGrey);
  padding-right: var(--medium-padding);
  font-size: 0.75rem;
`;

export const JumpToPageContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--theme-mainGrey);
  border-radius: var(--theme-paginationRadius);
  padding: 5px var(--medium-padding);
  max-width: 6rem;
`;
