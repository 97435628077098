import { iContractState, tAction } from 'types/store';
import types from './types';

const initialState: iContractState = {
  status: null,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, action: tAction): iContractState => {
  switch (action.type) {
    case types.SAVE_STATUS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
