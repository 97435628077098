import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { Button } from 'components';
import issuesService from 'services/issues';
import { catchErrors } from 'utils/helpers';
import { tError, tKeyString } from 'types/global';
import { LeftMargin1Rem } from 'styles/GlobalStyledComponents';
import ChangeDateDialog from './ChangeDateDialog';

interface iRejectNotifiedIssue extends RouteComponentProps<any> {
  setNotification: (error, type) => void;
  actions: { [key: string]: string };
}

const RejectNotifiedIssue = ({ history, setNotification, actions }: iRejectNotifiedIssue) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<tError[]>([]);
  const [showChangeDateDialog, setShowChangeDateDialog] = useState<boolean>(false);

  const setResponseErrors = (setter: Function, errors: tKeyString[]) => {
    setter(
      errors?.length
        ? errors.map((error: tKeyString) => {
            const { key, msg } = error;
            return {
              field: key,
              error: msg,
            };
          })
        : [],
    );
  };

  const rejectNotifiedIssue = async (comment: string) => {
    try {
      const result = await issuesService.rejectNotifiedIssue(actions.reject_visit_by_user, comment);
      if (result?.status === 200) {
        setNotification(t('Request to reschedule has been sent'), 'success');
        history.push('/issues');
      }
    } catch (err) {
      catchErrors(err, { setNotificationHandler: setNotification, setResponseErrors, setErrors });
    }
  };

  return (
    <>
      <LeftMargin1Rem>
        <Button kind='filled' variant='blue' onClick={() => setShowChangeDateDialog(true)}>
          {t('Change date')}
        </Button>
      </LeftMargin1Rem>
      {showChangeDateDialog && (
        <ChangeDateDialog
          show={showChangeDateDialog}
          hideDialog={() => setShowChangeDateDialog(false)}
          confirmMethod={rejectNotifiedIssue}
          errors={errors}
        />
      )}
    </>
  );
};

export default withRouter(RejectNotifiedIssue);
