import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addNotification } from 'store/notifications/actions';
import { tFiltersAPI } from 'types/global';
import { AxiosResponse } from 'axios';

import Api from 'utils/Api';

function useFetch<TData = unknown, TParams = object>(
  fetchMethod: (params) => any,
  defaultErrorMessage = '' as string | null,
) {
  const dispatch = useDispatch();
  const [data, setData] = useState<TData[]>([]);
  const [filtersToData, setFiltersToData] = useState<tFiltersAPI | null>(null);
  const [count, setCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const fetch = useCallback(
    async (params?: TParams) => {
      try {
        setLoading(true);
        const result = await fetchMethod({ ...params });
        const {
          data: { count, results, filters },
        } = result;

        if (!!filters) {
          setFiltersToData(filters);
        }

        setData(results);
        setCount(count);
      } catch (error) {
        const apiErrors = Api.getErrors(error as AxiosResponse);
        if (defaultErrorMessage !== null) {
          apiErrors.forEach((error) => {
            dispatch(
              addNotification(
                defaultErrorMessage || error?.message || 'Something went wrong. Try again',
                'error',
              ),
            );
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [fetchMethod],
  );

  return { fetch, loading, data, count, filters: filtersToData };
}

export default useFetch;
