import { ReactNode } from 'react';
import { Wrapper } from './Column.style';

type tColumn = {
  children?: ReactNode | ReactNode[];
  width?: number;
  col?: number;
  offset?: number;
  labelMinWidth?: boolean;
};

export type tColumnStyle = {
  col: number;
  offset: number;
  labelMinWidth?: boolean;
};

export const Column: React.FC<tColumn> = ({
  children,
  col = 12,
  offset = 0,
  labelMinWidth = false,
}): JSX.Element => {
  return (
    <Wrapper col={col} offset={offset} labelMinWidth={labelMinWidth}>
      {children}
    </Wrapper>
  );
};

export default Column;
