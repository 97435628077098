import styled from 'styled-components';
import { tTextarea } from './Textarea';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex: 0 1 100%;
  flex-direction: row;
`;

export const MainWrapper = styled(Wrapper)`
  margin-bottom: 1rem;
  flex-direction: column;
`;

export const TextareaStyle = styled.textarea<tTextarea>`
  font-size: 0.75rem;
  display: flex;
  flex: 0 1 100%;
  width: 100%;
  min-height: 4rem;
  max-height: 30rem;
  box-sizing: border-box;
  resize: both;
  overflow: auto;
  padding: 0.5rem 1rem;
  border-radius: var(--theme-borderRadius-sm);
  border: 1px solid
    ${(props) => (props.disabled ? 'var(--theme-borderDisabled)' : 'var(--theme-mainGrey)')};
  border: 1px solid
    ${({ errors, name }) =>
      errors.find((error) => error.field === name)
        ? 'var(--theme-mainRed)'
        : 'var(--theme-mainGrey)'};
  &:focus {
    outline: none;
    border: 1px solid var(--theme-mainBlue);
  }
  scrollbar-color: #38383830 #b0bdd010;
  scrollbar-width: thin;
  &::-webkit-scrollbar-track {
    border-radius: 0.5rem;
    background-color: #b0bdd005;
  }
  &::-webkit-scrollbar {
    width: 0.5rem;
    border-radius: 10px;
    background-color: #38383820;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #38383810;
  }
`;

export const ErrorMessage = styled.p`
  font-size: 0.75rem;
  color: var(--theme-mainRed);
  margin: 0;
  margin-top: var(--theme-spacing-xs);
`;

export const PreviewText = styled.div`
  font-size: 0.75rem;
`;
