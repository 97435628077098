import { RefObject, useState, useEffect, createRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addNotification } from 'store/notifications/actions';
import { Icon, FormErrorMessage, Label } from 'components';
import { colorRedBasic, colorBlueLight } from 'styles/GlobalStyles';
import { FlexV } from 'styles/GlobalStyledComponents';
import { useFormErrorsReset } from 'hooks';
import File from 'utils/File';
import {
  AvatarInputWrapper,
  AvatarInput,
  AvatarImage,
  ImageButton,
  IconWrapper,
  Wrapper,
  AvatarSingleImage,
  DefaultIconWrapper,
} from './Avatar.style';

type tAvatar = {
  isEditable?: boolean;
  label?: string;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errors?: {
    field: string;
    error: string;
  }[];
  fileSrc?: string;
  removeFile?: (fileName: string, fieldName: string) => void;
  showWithLabel?: boolean;
  labelOptional?: boolean;
};

export type tAvatarImage = {
  deleteIcon: boolean;
};

const Avatar: React.FC<tAvatar> = ({
  isEditable = false,
  label = '',
  name = '',
  errors,
  onChange,
  fileSrc: fileSource,
  removeFile,
  showWithLabel = false,
  labelOptional = false,
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const inputFileRef: RefObject<HTMLInputElement> = createRef();

  const [deleteIcon, setDeleteIcon] = useState<boolean>(false);
  const [file, setFile] = useState<string>('');
  const [fileSrc, setFileSrc] = useState<string>('');
  const [fileName, setFileName] = useState<string>('');

  const { formElemErrors, resetFormError } = useFormErrorsReset(errors);

  useEffect(() => {
    if (fileSource) setFileSrc(fileSource);
  }, [fileSource]);

  const handleClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const handleChange = (event) => {
    const targetFile = event.target.files[0];
    const { type, size } = targetFile;
    resetFormError(event?.target?.name);

    const imagesTypeAllowed = ['image/png', 'png', 'image/jpeg', 'jpg'];
    if (File.checkFileExtension(type, imagesTypeAllowed)) {
      return dispatch(addNotification(t('Wrong file format'), 'error'));
    }
    if (File.checkFileSize(size)) {
      return dispatch(addNotification(t('File is too big'), 'error'));
    }
    setFileName(targetFile.name);
    setFile(URL.createObjectURL(targetFile));
    setDeleteIcon(false);
    if (onChange) onChange(event);
  };

  const handleRemove = () => {
    setFile('');
    setFileSrc('');
    if (removeFile) removeFile(fileName, name);
  };

  const renderDefaultAvatar = () => (
    <DefaultIconWrapper>
      <Icon icon='person' width='4.5rem' height='4.5rem' />
    </DefaultIconWrapper>
  );

  const renderNotEditableContent = (fileSrc) => {
    if (fileSrc) {
      return (
        <Wrapper>
          {label && <Label>{t(label)}</Label>}
          <AvatarSingleImage src={fileSrc} alt='avatar-img' />
        </Wrapper>
      );
    }

    if (showWithLabel) {
      return (
        <Wrapper>
          {label && <Label>{t(label)}</Label>}
          {renderDefaultAvatar()}
        </Wrapper>
      );
    }
    return renderDefaultAvatar();
  };

  return isEditable ? (
    <Wrapper>
      {label && <Label optional={labelOptional}>{t(label)}</Label>}
      {file || fileSrc ? (
        <ImageButton
          onClick={handleRemove}
          onMouseOver={() => setDeleteIcon(true)}
          onMouseLeave={() => setDeleteIcon(false)}
          type='button'
        >
          <AvatarImage src={file || fileSrc} alt='avatar-img' deleteIcon={deleteIcon} />
          {deleteIcon && (
            <IconWrapper>
              <Icon icon='deleteBin' fill={colorRedBasic} />
            </IconWrapper>
          )}
        </ImageButton>
      ) : (
        <FlexV>
          <AvatarInputWrapper onClick={handleClick}>
            <AvatarInput
              ref={inputFileRef}
              id={name}
              type='file'
              name={name}
              onChange={handleChange}
              accept='image/*'
            />
            <Icon icon='addPhoto' fill={colorBlueLight} asButton />
          </AvatarInputWrapper>
          {!!formElemErrors.length && <FormErrorMessage name={name} errors={formElemErrors} />}
        </FlexV>
      )}
    </Wrapper>
  ) : (
    renderNotEditableContent(fileSrc)
  );
};

export default Avatar;
