import { useTranslation } from 'react-i18next';
import { Title } from './ListTitle.style';

type tListTitle = {
  title: string;
};

const ListTitle: React.FC<tListTitle> = ({ title }): JSX.Element => {
  const { t } = useTranslation();

  return <Title>{t(title)}</Title>;
};

export default ListTitle;
