const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAILURE = 'LOGIN_FAILURE';
const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
const SET_KEYCLOAK = 'SET_KEYCLOAK';
const SET_LOADING_AUTH = 'SET_LOADING_AUTH';
const SET_TOKEN = 'SET_TOKEN';
const REMOVE_TOKEN = 'REMOVE_TOKEN';

export default {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  SET_KEYCLOAK,
  SET_LOADING_AUTH,
  SET_TOKEN,
  REMOVE_TOKEN,
};
