import styled from 'styled-components';

export const AcceptedPenaltyNameBox = styled.div`
  align-items: center;
  display: flex;
  line-height: 2rem;
`;

export const FlexBox = styled.div<any>`
  flex: ${({ flex }) => flex};
`;

export const Header = styled.div`
  border-bottom: 1px solid var(--theme-mainGrey);
  display: flex;
  font-weight: bold;
  margin-bottom: 1rem;
  padding: 1rem 0;
`;

export const MarginBox = styled.div<any>`
  margin: ${({ value }) => value};
`;

export const NoPenalties = styled.div`
  font-size: 1rem;
`;

export const PaddingBox = styled.div<any>`
  padding: ${({ value }) => value};
`;
