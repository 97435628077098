import styled from 'styled-components';

export const Round = styled.div`
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  color: var(--theme-white);
  background-color: var(--theme-mainBlue);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  line-height: 1;
  font-weight: bold;
  font-size: 0.75rem;
`;

export const Item = styled.p`
  margin-top: 0;
  margin-bottom: 0;
`;
