import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { colorGreyBasic } from 'styles/GlobalStyles';

export const ReactTooltipStyle = styled<any>(ReactTooltip)`
  z-index: var(--theme-tooltipZIndex) !important;
  font-weight: normal !important;
  color: white !important;
  opacity: 1 !important;
  background-color: ${colorGreyBasic} !important;
  font-size: 0.75rem !important;
  line-height: 1.5 !important;
  &.place-top {
    &:after {
      border-top-color: ${colorGreyBasic} !important;
    }
  }
  &.place-right {
    &:after {
      border-right-color: ${colorGreyBasic} !important;
    }
  }
  &.place-bottom {
    &:after {
      border-bottom-color: ${colorGreyBasic} !important;
    }
  }
  &.place-left {
    &:after {
      border-left-color: ${colorGreyBasic} !important;
    }
  }
`;

export const TooltipSpan = styled.span`
  line-height: 1.5;
`;
