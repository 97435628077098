import styled, { css } from 'styled-components';
import { FlexHWrapper } from 'styles/GlobalStyledComponents';

type tDayContainer = {
  isDayOff?: boolean;
};

type tListFiltersWrapper = {
  justifyContent: string;
};

export const DashboardCalendarContainer = styled.div`
  display: flex;
  background-color: #fff;
  flex-direction: column;
  padding: 1.75rem 2rem 1rem 2rem;
  margin: 1rem 0rem 2.5rem 0;
  min-width: 800px;
  border-radius: var(--theme-borderRadius);
`;

export const Calendar = styled.div`
  display: flex;
`;

export const DayContainer = styled.div<tDayContainer>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: var(--theme-borderRadius);
  min-width: 140px;
  max-width: 200px;
  min-height: 380px;
  padding-bottom: 5%;
  flex: 1;
  ${({ isDayOff }) =>
    isDayOff &&
    css`
      flex: 0;
      background: var(--theme-lightGrey);
    `}
  &:not(:first-child):not(:last-child):after {
    position: absolute;
    content: '';
    width: 1px;
    height: 80%;
    right: 0;
    top: 10%;
    background-color: var(--theme-mainGrey);
  }
`;

export const UnplannedEventsContainer = styled(DayContainer)`
  border: 1px dashed var(--theme-mainGrey);
`;

export const HorizontalLine = styled.div`
  border-bottom: 1px solid var(--theme-mainGrey);
  width: 100%;
  margin: 1rem 0;
`;

export const DayContainerHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--theme-mainGrey);
  padding: 1.5rem 0;
  margin: 0;
`;

export const EventsCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DayName = styled.div`
  font-weight: 900;
  font-size: 0.875rem;
`;

export const HolidayName = styled.div`
  font-size: 0.625rem;
  padding: 0 0.5rem;
  display: flex;
  justify-content: center;
`;

export const UnplannedHeader = styled(DayName)`
  color: black;
`;

export const FilterTitle = styled.h4`
  font-size: 0.875rem;
  line-height: 1.0625rem;
`;

export const WeekHandlerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const WeekArrowsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
`;

export const WeekText = styled.p`
  font-size: 0.875rem;
  color: var(--theme-mainGrey);
  margin: 0 0.75rem;
  text-transform: capitalize;
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DateText = styled.p`
  font-size: 0.75rem;
  color: var(--theme-mainDarkGrey);
  margin: 0.6rem 0 0.6rem 0.5rem;
`;

export const ListFiltersWrapper = styled.div<tListFiltersWrapper>`
  width: calc(100% / 3);
  display: flex;
  align-items: flex-end;
  justify-content: ${({ justifyContent }) => `${justifyContent}`};
  margin: 0;
`;

export const StyledHWrapper = styled(FlexHWrapper)`
  margin-right: 3rem;
  padding-bottom: 4px;
  padding-left: 1rem;
`;

export const LastListFiltersWrapper = styled(ListFiltersWrapper)`
  padding-right: 1rem;
`;
