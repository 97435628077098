import styled, { css } from 'styled-components';
import { tSwitch, tLabel } from './Switch';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

export const SwitchInput = styled.input<tSwitch>`
  height: 0;
  width: 0;
  visibility: hidden;
  appearance: none;
  outline: 0;
  &:checked + label {
    background: var(--theme-mainGreen);
    ${({ disabled }) =>
      disabled &&
      css`
        background: var(--theme-lightGrey);
      `}
  }
  &:checked + label:after {
    left: calc(100% - 3px);
    transform: translateX(-100%);
  }
`;

export const SwitchLabel = styled.label<tLabel>`
  cursor: pointer;
  text-indent: -9999px;
  width: 2rem;
  height: 1rem;
  background-color: ${({ checked }) => {
    if (!checked) {
      return 'var(--theme-mainRed)';
    }
    return 'var(--theme-mainGreen)';
  }};
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: var(--theme-lightGrey);
      cursor: default;
    `}
  transition: 0.2s;
  display: block;
  border-radius: 100px;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 3px;
    width: 12px;
    height: 12px;
    background: var(--theme-white);
    border-radius: 90px;
    transition: all 0.3s;
  }
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;

type tDropdownOption = {
  disabled?: boolean;
};

export const SwitchOption = styled.p<tDropdownOption>`
  font-size: 0.875rem;
  margin: 0 0 0 0.875rem;
  color: ${({ disabled }) => (disabled ? 'var(--theme-mainGrey)' : 'var(--theme-black)')};
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
`;

export const CheckedTextStyle = styled.p`
  font-size: 0.75rem;
  margin: 0;
`;
