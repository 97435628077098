/* eslint-disable camelcase */
import usersAgent, { tGetAllUsersParamsAPI } from 'agents/users';
import { tGet, tDropdownDataApi } from 'types/global';
import { tRole, tCreateUserAPI, tUpdateUserAPI, tUpdateLocationUsersAPI } from 'types/api/users';
import {
  tGetAllUsersParams,
  tGetAllUsersData,
  tGetUserData,
  tCurrentUserData,
  tGetLocation,
} from 'types/services/users';
import { strToCamelCase } from 'utils/helpers';

export const transformSortByField = (field: string) => {
  switch (field) {
    case 'userNameAndSurname':
      return 'name';
    default:
      return field;
  }
};

const getAll = async ({
  limit,
  offset = 0,
  search = '',
  searchFields = [],
  ordering = { by: '', order: '' },
  kind = 'client',
  roleId = '',
  companyId = '',
}: tGetAllUsersParams) => {
  const params: tGetAllUsersParamsAPI = { offset, kind };
  const { by, order } = ordering;

  if (!!by) params.sort = transformSortByField(by);
  if (!!order) params.order = order;
  if (limit) params.limit = limit;
  if (search) params.search = search;
  if (searchFields.length) params.search_fields = searchFields.join(',');
  if (roleId) params.role_id = roleId;
  if (companyId) params.company_id = companyId;

  const result = await usersAgent.getAll(params).then((response) => {
    const {
      status,
      data: { count, results: users },
    } = response;

    const results: tGetAllUsersData[] = users.map((item, index) => {
      const {
        company,
        id,
        email: userEmail,
        first_name,
        last_name,
        phone_number: userPhoneNumber,
        position: userPosition,
        personal_id_number: userPersonalIdNumber,
      } = item;
      const order = index + 1 + offset;
      const userNameAndSurname = `${first_name} ${last_name}`;

      return {
        id,
        order,
        userEmail,
        userEmployer: company?.name ?? '',
        userNameAndSurname,
        userPhoneNumber,
        userPosition,
        userPersonalIdNumber,
      };
    });

    return {
      data: {
        count,
        results,
      },
      status,
    };
  });

  return result;
};

const get = async ({ id }: tGet) => {
  const result = await usersAgent.get(id);

  const {
    data: {
      avatar,
      company,
      email: userEmail,
      first_name: userName,
      last_name: userSurname,
      phone_number: userPhoneNumber,
      position: userPosition,
    },
  } = result;

  let userPhoto = '';
  if (!!avatar) {
    const { content: avatarContent, filename: fileName = 'avatar.jpg' } = avatar;
    const separatedAvatarNameStrings = fileName.split('.');
    const avatarExtension = separatedAvatarNameStrings[separatedAvatarNameStrings.length - 1];
    userPhoto = `data:image/${avatarExtension};base64,${avatarContent}`;
  }

  const data: tGetUserData = {
    userPhoto,
    userEmployer:
      company?.id && company?.name
        ? {
            value: company.id,
            label: company.name,
          }
        : null,
    userName,
    userSurname,
    userPhoneNumber,
    userEmail,
    userPosition,
  };

  return {
    data,
  };
};

const create = (bodyRequest: tCreateUserAPI) => {
  const result = usersAgent
    .create(bodyRequest)
    .then((response) => {
      const { status, data } = response;
      return { state: true as const, errors: [], data, status };
    })
    .catch((error) => {
      return { state: false as const, errors: !!error?.data ? error.data : [], data: null };
    });

  return result;
};

const update = async (bodyRequest: tUpdateUserAPI, id: number | string) => {
  const result = await usersAgent
    .update(bodyRequest, id)
    .then((response) => {
      const { status, data } = response;
      return { state: true as const, errors: [], data, status };
    })
    .catch((error) => {
      return { state: false as const, errors: !!error?.data ? error.data : [], data: null };
    });

  return result;
};

const remove = (id: string | number) =>
  usersAgent
    .remove(id)
    .then((response) => {
      const { status } = response;

      return { status, error: '' };
    })
    .catch((error) => {
      const { data, status } = error;

      return { status, error: !!data.length ? data[0].msg : 'Something went wrong. Try again' };
    });

const getCurrent = () =>
  usersAgent.getCurrent().then((result) => {
    const {
      data: {
        id,
        username,
        avatar,
        first_name: firstName,
        last_name: lastName,
        email,
        phone_number: phoneNumber,
        company,
        position,
        roles,
      },
    } = result;

    const allPermissions = (roles: tRole[]) => {
      const permissionsArr: string[] = [];
      roles.forEach((role) => {
        role.permissions.forEach((permission) => {
          const permissionCamelized = strToCamelCase(permission.name);
          if (permissionCamelized && !permissionsArr.includes(permissionCamelized)) {
            permissionsArr.push(permissionCamelized);
          }
        });
      });
      return permissionsArr;
    };

    const currentUserData: tCurrentUserData = {
      id,
      username: username ?? '',
      avatar,
      firstName,
      lastName,
      email,
      phoneNumber,
      company,
      position,
      roles,
      permissions: allPermissions(roles),
    };

    return currentUserData;
  });

export type tGetCompaniesParams = { kind?: string; archived?: boolean };
const getCompanies = async ({ kind = 'client', archived = false }: tGetCompaniesParams) => {
  const params: tGetCompaniesParams = { kind, archived };
  const result = await usersAgent.getCompanies(params).then((response) => {
    const {
      status,
      data: { count, results: companies },
    } = response;

    const results =
      count === 0
        ? []
        : companies.map((company) => {
            const { id: companyId, name: companyName } = company;

            return {
              value: companyId,
              label: companyName,
              fieldName: 'userEmployer',
              id: companyId,
            };
          });

    return {
      data: {
        count,
        results,
      },
      status,
    };
  });

  return result;
};

const getUserLocation = ({ id }: tGet) => {
  const result = usersAgent.getLocation(id).then(({ data }) => {
    const { is_regional_admin, id, workplaces, administrated_locations } = data;
    const result: tGetLocation = {
      isRegionalAdmin: is_regional_admin,
      id,
      userWorkplaces: workplaces.map(({ name, id }) => {
        return {
          value: name,
          label: name,
          id,
        };
      }),
      userSubordinateInstitutions: administrated_locations.map(({ name, id }) => {
        return {
          value: name,
          label: name,
          id,
        };
      }),
    };
    return {
      data: result,
    };
  });

  return result;
};

const addUserLocation = (userId: string | number, bodyRequest: tUpdateLocationUsersAPI) =>
  usersAgent.updateLocation(userId, bodyRequest);

export type tGetAddressesParams = { clientId: string | number };

const getAddresses = ({ clientId }: tGetAddressesParams) => {
  const params = {
    client_id: clientId,
  };
  const result = usersAgent.getAddresses(params).then(({ data }) => {
    const results: tDropdownDataApi[] = data.results.map(({ id, name }) => {
      return {
        value: name,
        label: name,
        id: String(id),
      };
    });

    return {
      data: { results },
    };
  });
  return result;
};

export default {
  getAll,
  get,
  create,
  update,
  remove,
  getCompanies,
  getCurrent,
  getUserLocation,
  addUserLocation,
  getAddresses,
};
