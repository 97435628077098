import { tTableHead } from 'types/components/table';

export const tableHead: tTableHead[] = [
  {
    sortable: false,
    name: 'No.',
    key: 'order',
  },
  {
    sortable: true,
    name: 'Name',
    key: 'name',
  },
  {
    sortable: true,
    name: 'Contracts name',
    key: 'contract',
  },
  {
    sortable: true,
    name: 'Client',
    key: 'clients',
  },
  {
    sortable: true,
    name: 'Frequency',
    key: 'frequency',
  },
  {
    sortable: true,
    name: 'MRU points number',
    key: 'maintenanceLocationsAmount',
  },
];
