import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components';
import { Wrapper, ItemStyle } from './SwitchView.style';

type tItem = {
  value: number | string;
  label: string;
  prefix?: JSX.Element;
  placeholder?: boolean;
};

type tSwitchView = {
  items: tItem[];
  onSelect: (value: number | string) => void;
  selected?: number | string | null;
};

const SwitchView: React.FC<tSwitchView> = ({ items, onSelect, selected = null }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <Wrapper>
      {showModal && (
        <Modal
          cancelButtonText={t('Close')}
          title={t('Option unavailable')}
          icon='warning'
          showOnlyCancel
          cancelMethod={() => setShowModal(false)}
        >
          <div>{t('This option will be available in the future')}</div>
        </Modal>
      )}
      {items.map((item) => {
        const { label, prefix, value, placeholder = false } = item;

        return (
          <ItemStyle
            key={value}
            selected={value === selected}
            onClick={() => (placeholder ? setShowModal(true) : onSelect(value))}
          >
            {prefix} {label}
          </ItemStyle>
        );
      })}
    </Wrapper>
  );
};

export default SwitchView;
