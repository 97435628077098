import { notificationsTimeoutMS } from 'utils/constants';
import { tNotificationsTypes } from 'types/global';
import types from './types';

export const createNotification = (
  message: string,
  type: string,
  timeout = false,
  id = Date.now(),
) => ({
  type: types.NOTIFICATION_CREATE,
  payload: {
    message,
    type,
    timeout,
    id,
  },
});

export const removeNotification = (id: number | string) => ({
  type: types.NOTIFICATION_REMOVE,
  payload: id,
});

export const removeAllNotifications = () => ({
  type: types.NOTIFICATION_REMOVE_ALL,
});

export const timeoutNotification = (id: number | string) => ({
  type: types.NOTIFICATION_TIMEOUT,
  payload: id,
});

export const changeNotificationPosition = (position: string) => ({
  type: types.NOTIFICATION_POSITION,
  payload: position,
});

export const addNotification =
  (message: string, type: tNotificationsTypes, timeout = false) =>
  (dispatch) => {
    const newId = Date.now();
    dispatch(createNotification(message, type, timeout, newId));
    if (timeout) {
      setTimeout(() => {
        dispatch(timeoutNotification(newId));
      }, notificationsTimeoutMS);
    }
  };
