import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useSingleFetch } from 'hooks';
import itsStructureService, { tGetQualification } from 'services/itsStructure';
import { tQualification } from 'types/services/itsStructure';

import {
  ExpansionPanel,
  Button,
  Form,
  Input,
  Row,
  Column,
  Textarea,
  Datepicker,
  FileUpload,
} from 'components';
import { ExpansionPanelWrapper, FormWrapper } from 'styles/GlobalStyledComponents';
import { ButtonWrapper } from '../AddEditFormItsStructure.style';

type iSingleQualificationsAndPermissionsForm = {
  isAddMode?: boolean;
  disabled?: boolean;
  id: number | string | null;
  qualificationId: number | null;
  closeForm: () => void;
};
const dataModel = {
  name: '',
  number: '',
  range: '',
  start: undefined,
  end: undefined,
  attachments: [],
};

const getInitialValues = (data: tQualification) => {
  return data && Object.keys(data).length ? data : dataModel;
};

const SingleQualificationsAndPermissionsForm: React.FC<iSingleQualificationsAndPermissionsForm> = ({
  isAddMode = false,
  disabled = false,
  id = null,
  qualificationId = null,
  closeForm = () => {},
}): JSX.Element => {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState<tQualification>(dataModel);
  const { fetch: setParamsHandler, data } = useSingleFetch<any, tGetQualification>(
    itsStructureService.getCurrentQualificationsAndPermissions,
  );

  const fetchData = useCallback(() => {
    if (!!id && !!qualificationId) {
      setParamsHandler({
        id: Number(id),
        qualificationId,
      });
    }
  }, [setParamsHandler, id, qualificationId]);

  useEffect(() => {
    fetchData();
  }, [fetchData, id, qualificationId]);

  useEffect(() => {
    const initialValues = getInitialValues(data);
    setInitialValues(initialValues);
  }, [data]);

  const onSubmit = (values: any, isAddMode: boolean) => {
    // TODO_! check on periodOfValidity, periodOfValidityEnd, start, end fields, when API ready
    // eslint-disable-next-line no-console
    return isAddMode ? console.log('addMru()') : console.log('editMru(editedItem)');
  };

  const { values, handleChange, handleSubmit } = useForm({
    initialValues,
    onSubmit: (values) => onSubmit(values, isAddMode),
  });

  const {
    name,
    number,
    range,
    periodOfValidity,
    periodOfValidityEnd,
    periodOfValidityIndefinitely,
    attachments,
  } = values;
  const errors = [];

  return (
    <Form onSubmit={handleSubmit}>
      <ExpansionPanelWrapper>
        <ExpansionPanel
          hasBottomBorder
          title={t('Qualifications and permissions')}
          hasIcon
          iconName='assignmentTurned'
        >
          <FormWrapper>
            <Row justifyContent='space-between'>
              <Column>
                <Input
                  name='name'
                  label='Permission name'
                  onChange={handleChange}
                  errors={errors}
                  value={name}
                  disabled={disabled}
                />
              </Column>
              <Column>
                <Input
                  name='number'
                  label='Permission number'
                  onChange={handleChange}
                  errors={errors}
                  value={number}
                  disabled={disabled}
                />
              </Column>
              <Column>
                <Textarea
                  name='range'
                  label='Range'
                  onChange={handleChange}
                  errors={errors}
                  value={range}
                  disabled={disabled}
                />
              </Column>
              <Column>
                <Datepicker
                  name='periodOfValidity'
                  label='Period of validity'
                  variant='range'
                  onChange={handleChange}
                  start={periodOfValidity}
                  end={periodOfValidityEnd}
                  indefinitely={periodOfValidityIndefinitely}
                  noEndDateAllowed
                  disabled={disabled}
                />
              </Column>
              <Column>
                <FileUpload
                  name='attachments'
                  onChange={handleChange}
                  label='Attachments'
                  errors={errors}
                  files={attachments}
                />
              </Column>
            </Row>
          </FormWrapper>
        </ExpansionPanel>
        {!disabled ? (
          <ButtonWrapper>
            <Button onClick={closeForm} variant='red' kind='outlined'>
              {t('Close')}
            </Button>
            <div style={{ marginRight: '1rem' }} />
            <Button variant='green' type='submit'>
              {t('Save')}
            </Button>
          </ButtonWrapper>
        ) : null}
      </ExpansionPanelWrapper>
    </Form>
  );
};

export default SingleQualificationsAndPermissionsForm;
