import styled from 'styled-components';

export const CommentField = styled.div`
  font-size: 0.75rem;
`;

export const TextareaContainer = styled.div`
  padding-right: 2rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
