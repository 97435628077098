import { useTranslation } from 'react-i18next';
import { BoldCell } from './InvoiceTable.style';
import Separator from './Separator';

export type tSubHeader = {
  name?: string;
  showUnit?: boolean;
  showQuantity?: boolean;
  showGross?: boolean;
  showContractor?: boolean;
  showReport?: boolean;
  rateTitle?: string;
};

const SubHeader: React.FC<tSubHeader> = ({
  name = '',
  showUnit = false,
  showQuantity = false,
  showGross = true,
  showContractor = true,
  showReport = false,
  rateTitle = '',
}) => {
  const { t } = useTranslation();
  return (
    <tr>
      <BoldCell align='left'>{t(name)}</BoldCell>
      <BoldCell>{showUnit && t('unit')}</BoldCell>
      <BoldCell>{showQuantity && t('quantity')}</BoldCell>
      <Separator />
      <BoldCell>{t(rateTitle)}</BoldCell>
      <BoldCell>{t('net value')}</BoldCell>
      {showGross && <BoldCell>{t('gross value')}</BoldCell>}
      {showContractor && (
        <>
          <Separator />
          <BoldCell>{t('mode rate')}</BoldCell>
          <BoldCell>{t('net value')}</BoldCell>
          {showGross && <BoldCell>{t('gross value')}</BoldCell>}
        </>
      )}
      {showReport && <Separator />}
    </tr>
  );
};

export default SubHeader;
