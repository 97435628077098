import styled from 'styled-components';
import { tLabelStyle } from './Label';

export const LabelStyle = styled.label<tLabelStyle>`
  font-size: 0.75rem;
  color: var(--theme-mainGrey);
  margin-right: var(--theme-spacing-md);
  justify-content: flex-end;
  min-width: ${(props) => `${props.labelMinWidth}px`};
  max-width: ${(props) => `${props.labelMinWidth}px`};
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 1099px) {
    min-width: 90px;
    max-width: 90px;
  }
`;

export const OptionalText = styled.p`
  font-size: 0.625rem;
  font-weight: 300;
  padding: 0;
  margin: 0;
`;
