import { iUserState, tAction } from 'types/store';
import types from './types';

const initialState: iUserState = {
  id: '',
  username: '',
  avatar: null,
  firstName: '',
  lastName: '',
  company: null,
  position: '',
  roles: [],
  permissions: [],
  loading: false,
  idleTime: 0,
  email: '',
  phoneNumber: '',
};

// eslint-disable-next-line default-param-last
export default (state = initialState, action: tAction): iUserState => {
  switch (action.type) {
    case types.SAVE_USER: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case types.LOADING_USER: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case types.IDLE_TIME_INCREMENT: {
      return {
        ...state,
        idleTime: state.idleTime + 1,
      };
    }
    case types.IDLE_TIME_RESET: {
      return {
        ...state,
        idleTime: 0,
      };
    }
    default:
      return state;
  }
};
