import styled from 'styled-components';
import { capitalizeFirst } from 'utils/helpers';

export type tElementStyle = { color?: string };

export const Round = styled.div<tElementStyle>`
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  background: var(--theme-white);
  color: var(--theme-mainRed);
  ${({ color }) => color && `color: var(${color});`}
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
  line-height: 1;
`;

export const Element = styled.div<tElementStyle>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: -0.5rem;
  background: transparent var(--theme-gradientRed) 0% 0% no-repeat padding-box;
  ${({ color }) =>
    color &&
    `
      background: transparent var(--theme-gradient${capitalizeFirst(
        color,
      )}) 0% 0% no-repeat padding-box;
    `}
  border-radius: 12px;
  color: var(--theme-white);
  font-weight: 900;
`;

export const Text = styled.span`
  line-height: 1;
`;
