/* eslint-disable camelcase */
import i18n from 'i18next';
import contractsAgent from 'agents/contracts';
import subcontractorsAgent from 'agents/subcontractors';
import { AxiosResponse } from 'axios';
import { tGet, tDropdownDataApi, tAttachmentToAPI, tDropdownOption } from 'types/global';
import { formatDate } from 'utils/helpers';
import { tAttachmentsToDelete } from 'types/views/contracts';
import {
  tContract,
  tContractAPI,
  FinancialConditionsAPI,
  tContractPostAPI,
  tContractPatchAPI,
  tManagersAssignedToLocation,
  tPriceList,
  tUsersAndRegionalAdminsPost,
  tAccountingModeApiData,
  tGetAllContractsAPI,
  tGetUsersByRoleAssignedToLocationData,
  tGetUsersByFiltersParams,
} from 'types/services/contracts';
import { tUsersApiFilters } from 'types/api/users';
import { tAccountingMode } from './settlementModes';

const getAll = async (params: tGetAllContractsAPI) => {
  const { offset = 0 } = params;

  const response: AxiosResponse = await contractsAgent.getAll(params);
  const {
    data,
  }: {
    data: {
      count: number;
      results: tContractAPI[];
    };
  } = response;
  const results: tContract[] = data.results.map((contract: tContractAPI, index: number) => {
    const { id, name, client, status, end } = contract;
    const contractExpirationDate = end === null ? 'Indefinitely' : formatDate(new Date(end));
    return {
      id,
      order: index + 1 + offset,
      name,
      status,
      clientName: client.name,
      clientId: client.id,
      contractExpirationDate,
    };
  });
  const { count } = data;

  return {
    data: {
      count,
      results,
    },
  };
};

const get = async ({ id }: tGet) => {
  const response: AxiosResponse = await contractsAgent.get(+id);
  const { data }: { data: tContractAPI } = response;
  const {
    client,
    name,
    admin_name,
    attachments,
    contract_attachment,
    start,
    end,
    financial_conditions,
    status,
  } = data;

  const {
    currency,
    vat_rate,
    payment_due_date,
    payment_due_rule,
    billing_interval,
    billing_interval_unit,
    billing_period_days,
    billing_approval,
    billing_approval_period,
    valorizations,
    valorization_interval,
    valorization_interval_unit,
    night_time_start,
    night_time_end,
  } = financial_conditions;

  return {
    data: {
      clientsName: {
        label: client.name,
        value: client.id,
      },
      contractsName: name,
      clientsContractSupervisor: admin_name,
      contractDuration: new Date(start),
      contractDurationEnd: end === null ? null : new Date(end),
      attachments,
      contractContent: !!contract_attachment ? [contract_attachment] : [],
      billingCurrency: {
        label: currency,
        value: currency,
      },
      VATRate: {
        label: vat_rate,
        value: vat_rate,
      },
      paymentDeadline: {
        value: payment_due_date,
        optionLabel: payment_due_rule,
      },
      invoicingInterval: {
        value: billing_interval,
        optionLabel: billing_interval_unit,
      },
      billingPeriodTo: billing_period_days,
      acceptanceOfSettlement: billing_approval,
      timeForAcceptance: billing_approval_period,
      valorizationIndicator: valorizations.length
        ? valorizations.map((valorization) => ({
            label: valorization.name,
            value: valorization.name,
            id: valorization.id,
          }))
        : [],
      valorizationPeriod: {
        value: valorization_interval,
        optionLabel: valorization_interval_unit,
      },
      nightModeStart: night_time_start ? night_time_start.slice(0, -3) : '',
      nightModeEnd: night_time_end ? night_time_end.slice(0, -3) : '',
      status,
    },
  };
};

const sendAttachments = async (
  contractId: number,
  data: {
    contract_attachment: tAttachmentToAPI[] | null;
    attachments: tAttachmentToAPI[];
  },
  isSubcontractorContract = false as boolean,
) => {
  const agent = isSubcontractorContract ? subcontractorsAgent : contractsAgent;
  const hasAttachments = !!data.attachments.length;
  if (data?.contract_attachment?.length) {
    const contractAttachments = data.contract_attachment;
    try {
      await Promise.all(
        contractAttachments.map(async (contractAttachment) => {
          await agent.postContractAttachment(contractId, contractAttachment);
        }),
      );
    } catch (error) {
      return {
        error: true,
        errorMessage: 'Image failed to download',
        data: '',
      };
    }
  }

  if (hasAttachments) {
    const { attachments } = data;
    try {
      await Promise.all(
        attachments.map(async (attachment) => {
          await agent.postAttachment(contractId, attachment);
        }),
      );
    } catch (error) {
      return {
        error: true,
        errorMessage: 'Image failed to download',
        data: '',
      };
    }
  }
};

const createContract = async (data: tContractPostAPI) => {
  const response: AxiosResponse = await contractsAgent.create(data);
  const contractId: number = response?.data?.id;
  const { attachments, contract_attachment } = data;
  await sendAttachments(contractId, { attachments, contract_attachment });

  return { response, error: null };
};

const deleteAttachments = (id: number) => contractsAgent.deleteAttachments(id);

const deleteContractAttachments = (id: number) => contractsAgent.deleteContractAttachments(id);

const deleteAllAttachments = async (
  contractId: number,
  attachmentsToDelete: tAttachmentsToDelete,
) => {
  try {
    if (!!attachmentsToDelete.contractContent?.length) {
      await deleteContractAttachments(contractId);
    }
    if (!!attachmentsToDelete.attachments?.length) {
      await Promise.all(
        attachmentsToDelete.attachments.map(async (attachment) => {
          await deleteAttachments(attachment);
        }),
      );
    }
    return '';
  } catch (error) {
    return (
      error?.message ??
      i18n.t('Contract was updated but something went wrong while deleting an attachments')
    );
  }
};

const editContract = async (
  id: number,
  data: tContractPostAPI,
  attachmentsToDelete: tAttachmentsToDelete,
) => {
  const response: AxiosResponse = await contractsAgent.edit(id, data);
  const contractId: number = response?.data?.id;
  await sendAttachments(contractId, data);

  let error = '';
  if (!!Object.keys(attachmentsToDelete).length) {
    const deletingResponse = await deleteAllAttachments(contractId, attachmentsToDelete);
    if (!!deletingResponse) error = deletingResponse;
  }
  return { response, error };
};

const patchContract = (id: number, data: tContractPatchAPI) => contractsAgent.patch(id, data);

const deleteContract = (id: number) => contractsAgent.delete(id);

const getStatus = async ({ id }: tGet) => {
  const response: AxiosResponse = await contractsAgent.get(+id);
  const { id: contractId, sap_id: sapId, status }: tContractAPI = response.data;
  return {
    data: {
      id: contractId,
      sapId,
      status,
    },
  };
};

const getStatuses = () => contractsAgent.getStatuses().then((response) => response.data);

const getContractFinancialConditions = async ({ id }: tGet) => {
  const response: AxiosResponse = await contractsAgent.get(+id);
  const data: FinancialConditionsAPI = response.data.financial_conditions;
  return { data };
};

const getCurrencies = async () => {
  const response: AxiosResponse = await contractsAgent.getCurrencies();
  const { data }: { data: string[] } = response;
  return {
    data: {
      results: data.map((currency) => {
        return {
          value: currency,
          label: currency,
        };
      }),
    },
  };
};
const getVATRates = async () => {
  const response: AxiosResponse = await contractsAgent.getVATRates();
  const { data }: { data: string[] } = response;
  return {
    data: {
      results: data.map((item) => {
        return {
          value: item,
          label: item,
        };
      }),
    },
  };
};

const getPaymentDeadlines = async () => {
  const response: AxiosResponse = await contractsAgent.getPaymentDeadlines();
  const { data }: { data: string[] } = response;
  return {
    data: {
      results: data.map((item) => {
        return {
          value: item,
          label: item,
        };
      }),
    },
  };
};
const getBillingIntervalUnits = async () => {
  const response: AxiosResponse = await contractsAgent.getBillingIntervalUnits();
  const { data }: { data: string[] } = response;
  return {
    data: {
      results: data.map((item) => {
        return {
          value: item,
          label: item,
        };
      }),
    },
  };
};

const getValorizationIntervalUnits = async () => {
  const response: AxiosResponse = await contractsAgent.getValorizationIntervalUnits();
  const { data }: { data: string[] } = response;
  return {
    data: {
      results: data.map((item) => {
        return {
          value: item,
          label: item,
        };
      }),
    },
  };
};

const getValorizations = async () => {
  const response: AxiosResponse = await contractsAgent.getValorizations();
  const { data }: { data: { id: number; name: string }[] } = response;
  return {
    data: {
      results: data.map((item) => {
        return { value: item.name, label: item.name, id: item.id };
      }),
    },
  };
};

const putITSManagersAssignedToLocation = (
  locationId: string,
  data: tManagersAssignedToLocation[],
) => contractsAgent.putITSManagersAssignedToLocation(locationId, data);

const getUsersRoles = async () => {
  const response: AxiosResponse = await contractsAgent.getUsersRoles();
  const { data } = response;
  return { data };
};

export type tGetUsersByRoleAssignedToLocationParams = {
  locationId: string;
  roleId: string;
};

const getUsersByRoleAssignedToLocation = async ({
  locationId,
  roleId,
}: tGetUsersByRoleAssignedToLocationParams) => {
  const response: AxiosResponse = await contractsAgent.getUsersByRoleAssignedToLocation(
    locationId,
    roleId,
  );
  const { data } = response;

  const returnData: tGetUsersByRoleAssignedToLocationData[] = data.map((item) => {
    const { first_name, last_name, id, sub_contract_number, controlling_order_number, industries } =
      item;
    const industry = industries.map((industry) => {
      const { name, id } = industry;
      return {
        id,
        value: name,
        label: name,
      };
    });

    return {
      id,
      value: `${first_name} ${last_name}`,
      label: `${first_name} ${last_name}`,
      subcontractNumber: sub_contract_number,
      controllingOrderNumber: controlling_order_number,
      industry,
    };
  });

  return {
    data: { results: returnData },
  };
};

const getUsersByFilters = async ({
  workplaceId,
  roleId,
  companyId,
  administratedLocationId,
}: tGetUsersByFiltersParams) => {
  const params: tUsersApiFilters = {};

  if (workplaceId) params.workplace_id = String(workplaceId);
  if (roleId) params.role_id = String(roleId);
  if (companyId) params.company_id = String(companyId);
  if (administratedLocationId) params.administrated_location_id = String(administratedLocationId);

  const response: AxiosResponse = await contractsAgent.getUsersByFilters(params);
  const {
    data: { results },
  } = response;
  return {
    data: {
      results: results.map((item) => {
        const { first_name, last_name, id } = item;
        return {
          id,
          value: `${first_name} ${last_name}`,
          label: `${first_name} ${last_name}`,
        };
      }),
    },
  };
};

const putUsersAndRegionalAdminsToLocation = (
  locationId: string,
  data: tUsersAndRegionalAdminsPost,
) => contractsAgent.putUsersAndRegionalAdminsToLocation(locationId, data);

const getITSTechnicians = async () => {
  const response: AxiosResponse = await contractsAgent.getITSTechnicians();
  const {
    data: { results },
  } = response;
  const optionsData: tDropdownOption[] = results?.map((tech) => ({
    value: tech.id,
    label: `${tech.first_name} ${tech.last_name}`,
  }));
  return { data: { results: optionsData } };
};

// SinglePriceListBillingActivitiesForm

export type tGetAccountingModesParams = {
  contractId: number | string;
  priceList: boolean;
  isSubcontractorContract: boolean;
};

const getAccountingModes = async ({
  contractId,
  priceList,
  isSubcontractorContract,
}: tGetAccountingModesParams) => {
  const params = { pricelist: priceList };
  const agent = isSubcontractorContract ? subcontractorsAgent : contractsAgent;
  const response: AxiosResponse = await agent.getAccountingModes(contractId, params);
  const { data } = response;
  const count: number = data.length;

  let results: [] | tAccountingMode[] = [];

  if (!!count) {
    results = data.map((accountingMode: tAccountingMode) => {
      const { id, name } = accountingMode;
      return {
        id,
        name,
      };
    });
  }

  return {
    data: {
      count,
      results,
    },
  };
};

const createUpdateSinglePriceListBilling = async (
  accountingModeId: string | number,
  bodyRequest: tPriceList[],
) => {
  const response: AxiosResponse = await contractsAgent.createUpdateSinglePriceListBilling(
    accountingModeId,
    bodyRequest,
  );
  return response;
};

const getSpecificationUnits = async () => {
  const result = contractsAgent.getSpecificationUnits().then(({ data }) => {
    const results: tDropdownDataApi[] = data.results.map(({ id, name }) => {
      return {
        value: id,
        label: name,
      };
    });

    return {
      data: { results },
    };
  });
  return result;
};

export type tPriceListBilling = {
  id: number;
  acceptedPriceName: string;
  priceName: string;
  unitType: tDropdownDataApi | null;
  netValue: number;
  grossValue: number;
  apiId: string | number | null;
  show: boolean;
};

const getSinglePriceListBilling = async ({ id }: tGet) => {
  const result = contractsAgent.getSinglePriceListBilling(id).then(({ data }) => {
    const results: tPriceListBilling[] = data.map(
      ({ id, description, unit, net, gross, accounting_mode_id }) => {
        return {
          id,
          acceptedPriceName: description,
          priceName: '',
          unitType: { value: unit.id, label: unit.name },
          netValue: net,
          grossValue: gross,
          apiId: accounting_mode_id,
          show: true,
        };
      },
    );
    return {
      data: { results },
    };
  });
  return result;
};

export const getSingleAccountingMode = async (accountingModeId: string | number) => {
  const response = await contractsAgent.getSingleAccountingMode(accountingModeId);
  const {
    status,
    data,
  }: {
    status: number;
    data: tAccountingModeApiData;
  } = response;
  return {
    data,
    status,
  };
};

export type tGetVATRateParams = {
  id: number;
  isSubcontractorContract: boolean;
};
const getVATRate = async ({ id, isSubcontractorContract }: tGetVATRateParams) => {
  const response: AxiosResponse = isSubcontractorContract
    ? await subcontractorsAgent.getContract(id)
    : await contractsAgent.get(id);
  const { data }: { data: tContractAPI } = response;
  const {
    financial_conditions: { vat_rate },
  } = data;
  return {
    data: {
      VATRate: vat_rate,
    },
  };
};

const getUnreadCounters = async () => {
  const response: AxiosResponse = await contractsAgent.getUnreadCounters();
  const { data } = response;
  return data;
};

export default {
  getAll,
  get,
  sendAttachments,
  createContract,
  editContract,
  deleteContract,
  getStatus,
  getStatuses,
  getContractFinancialConditions,
  getCurrencies,
  getVATRates,
  getPaymentDeadlines,
  getBillingIntervalUnits,
  getValorizationIntervalUnits,
  getValorizations,
  patchContract,
  deleteAttachments,
  deleteContractAttachments,
  deleteAllAttachments,
  putITSManagersAssignedToLocation,
  getUsersRoles,
  getUsersByRoleAssignedToLocation,
  getAccountingModes,
  createUpdateSinglePriceListBilling,
  getSpecificationUnits,
  getSinglePriceListBilling,
  getUsersByFilters,
  getITSTechnicians,
  putUsersAndRegionalAdminsToLocation,
  getSingleAccountingMode,
  getVATRate,
  getUnreadCounters,
};
