import { useSelector } from 'react-redux';
import tStore from 'types/store';
import { Avatar, Icon } from 'components';
import { appVersion } from 'utils/constants';
import { colorBlack } from 'styles/GlobalStyles';
import Menu from './Menu/Menu';
import {
  Aside,
  TopContainer,
  Header,
  UserDataContainer,
  UserName,
  UserRole,
  ImpelLogo,
  GetinLogo,
  ImpelLogoBottom,
  Version,
  AsideFooter,
  UserAvatar,
  MenuButton,
  Backdrop,
} from './SideBar.style';

export type tSideBar = {
  showMenu: boolean;
  setShowMenu: (showMenu: boolean) => void;
};

const SideBar: React.FC<tSideBar> = ({ showMenu, setShowMenu }) => {
  const user = useSelector((state: tStore) => state.user);

  const getAvatar = () => {
    if (!!user?.avatar?.content) {
      const extension = user.avatar.filename.split('.').pop();
      return (
        <UserAvatar
          src={`data:image/${extension};base64,${user?.avatar?.content}`}
          alt={`${user.firstName} ${user.lastName}`}
        />
      );
    }
    return <Avatar />;
  };

  const getCompanyLogo = (companyName: string) => {
    switch (companyName) {
      case 'Getin':
        return <GetinLogo />;
      case 'ITS':
      default:
        return <ImpelLogo />;
    }
  };

  return (
    <>
      <Backdrop show={showMenu} onClick={() => setShowMenu(false)} />
      <Aside show={showMenu}>
        <MenuButton onClick={() => setShowMenu(!showMenu)}>
          <Icon icon='close' fill={colorBlack} />
        </MenuButton>
        <TopContainer>
          <Header>
            {getCompanyLogo(user.company?.name ?? 'ITS')}
            {getAvatar()}
            <UserDataContainer>
              <UserName>{`${user.firstName} ${user.lastName}`}</UserName>
              <UserRole>{user.position}</UserRole>
            </UserDataContainer>
          </Header>
          <Menu closeMenu={() => setShowMenu(false)} />
        </TopContainer>
        <AsideFooter>
          <ImpelLogoBottom />
          <Version>v{appVersion}</Version>
        </AsideFooter>
      </Aside>
    </>
  );
};

export default SideBar;
