import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 8px #0000000b;
  border-radius: 0.5rem;
  padding: 1.5rem 1rem 1rem;
  margin-top: 1.5rem;
`;

export const SingleItem = styled.div`
  background: var(--theme-gradientBlue);
  color: var(--theme-white);
  font-size: 0.75rem;
  box-shadow: var(--theme-mainShadow);
  border-radius: 1.25rem;
  padding: 0.25rem 0.75rem;
  display: flex;
  align-items: center;
  margin-right: 0.75rem;
  white-space: nowrap;
  svg {
    margin-left: 0.5625rem;
  }
`;

export const Title = styled.span`
  margin-left: 0.75rem;
  margin-right: 1.5rem;
  color: var(--theme-mainGrey);
  font-weight: 900;
`;

export const WrapperForScroll = styled.div`
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;
`;
