import styled from 'styled-components';

type tModalContent = {
  width: string;
};

export const Title = styled.h2`
  font-weight: normal;
  font-size: 1.125rem;
  font-weight: 900;
  margin-bottom: var(--theme-spacing-xl);
  color: var(--theme-mainGrey);
  margin: 0 0 0 1rem;
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: var(--theme-modalZIndex);
`;

export const ModalContent = styled.div<tModalContent>`
  max-width: 80%;
  max-height: 80vh;
  width: ${({ width }) => width};
  background: var(--theme-white);
  padding: var(--theme-spacing-xl);
  display: inline-block;
  top: 50%;
  left: 50%;
  position: fixed;
  overflow: hidden;
  overflow-y: auto;
  transform: translate(-50%, -50%);
  background: var(--theme-white);
  box-shadow: 0px 0px 6px;
  border-radius: var(--theme-spacing);
  z-index: var(--theme-modalZIndex);
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 2rem;
`;

export const LineGap = styled.div`
  border-bottom: 1px solid var(--theme-mainGrey);
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
  padding-bottom: 1rem;
  z-index: var(--theme-modalZIndex);
`;

export const TextWrapper = styled.div`
  padding: 0 1rem;
  font-size: 0.75rem;
  letter-spacing: 0.58px;
  padding: 1rem 1rem 2rem 1rem;
  z-index: var(--theme-modalZIndex);
`;

export const InnerButtonWrapper = styled.div`
  margin-right: 1rem;
`;
