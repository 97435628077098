import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useSingleFetch, useFetch } from 'hooks';
import { useDispatch } from 'react-redux';
import { addNotification } from 'store/notifications/actions';
import usersService, { tGetAddressesParams } from 'services/users';
import { withRouter, RouteComponentProps } from 'react-router';
import {
  Button,
  Column,
  DropdownWithList,
  ExpansionPanel,
  Form,
  Row,
  Spinner,
  Switch,
} from 'components';
import { REQUIRED_FIELDS } from 'utils/requiredFormFields';
import { validateRequiredFields } from 'utils/form';
import { catchErrors } from 'utils/helpers';
import { ExpansionPanelWrapper, FormWrapper } from 'styles/GlobalStyledComponents';
import { tGetLocation } from 'types/services/users';
import { tOption, tGet, tKeyString, tError, tDropdownDataApi } from 'types/global';
import { ButtonContainer } from '../AddEditViewUser.style';

interface iLocationForm extends RouteComponentProps<any> {
  disabled?: boolean;
  preview?: boolean;
  userCompany: tOption | null;
}

type tUserLocationAPIData = {
  values: {
    isRegionalAdmin: boolean;
    userSubordinateInstitutions: tDropdownDataApi[];
    userWorkplaces: tDropdownDataApi[];
  };
};

type tUserLocationData = Omit<tGetLocation, 'id'>;

const dataModel: tUserLocationData = {
  isRegionalAdmin: false,
  userWorkplaces: [],
  userSubordinateInstitutions: [],
};

const LocationForm: React.FC<iLocationForm> = ({
  disabled = false,
  preview = false,
  match,
  userCompany,
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const { id } = match.params;
  const [initialValues, setInitialValues] = useState<any>();

  const {
    fetch: setParamsHandler,
    loading: loadingUserData,
    data,
  } = useSingleFetch<tGetLocation, tGet>(
    usersService.getUserLocation,
    'An error occurred while getting users',
  );

  const {
    fetch: setParamsHandlerAddresses,
    loading: loadingLocationAddresses,
    data: locationAddresses,
  } = useFetch<tDropdownDataApi, tGetAddressesParams>(
    usersService.getAddresses,
    'Something went wrong. Try again',
  );

  const [errors, setErrors] = useState<tError[]>([]);

  const setNotificationHandler = (message: string, type = 'error' as 'error' | 'success') => {
    const enableTimeout = type === 'success';
    dispatch(addNotification(t(message), type, enableTimeout));
  };

  const fetchData = useCallback(() => {
    if (!!id) setParamsHandler({ id });
    if (!!userCompany?.value) setParamsHandlerAddresses({ clientId: userCompany?.value });
  }, [setParamsHandler, id, userCompany]);

  useEffect(() => {
    fetchData();
    setErrors([]);
  }, [fetchData, preview, disabled]);

  const getInitialValues = (result: tGetLocation): tUserLocationData => {
    if (result && Object.keys(result).length) {
      const { isRegionalAdmin, userWorkplaces, userSubordinateInstitutions } = result;
      return {
        isRegionalAdmin,
        userWorkplaces,
        userSubordinateInstitutions,
      };
    }

    return dataModel;
  };
  useEffect(() => {
    setInitialValues(getInitialValues(data));
  }, [data]);

  const setResponseErrors = (setter: Function, errors: tKeyString[]) => {
    const transformField = (field: string): string => {
      switch (field) {
        case 'workplaces_ids':
          return 'userWorkplaces';
        case 'administrated_locations_ids':
          return 'userSubordinateInstitutions';
        default:
          return '__noField__';
      }
    };

    setter(
      errors
        ? errors?.map((error: tKeyString) => {
            const { key, msg } = error;
            return {
              field: transformField(key),
              error: msg,
            };
          })
        : [],
    );
  };

  const onSubmit = async ({ values }: tUserLocationAPIData) => {
    try {
      setLoading(true);
      const { isRegionalAdmin, userWorkplaces, userSubordinateInstitutions } = values;
      const arrayWithUserWorkplaces: string[] | null = [];
      const arrayWithUserSubordinateInstitutions: string[] | null = [];

      if (userWorkplaces?.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        userWorkplaces?.forEach(({ id }) => {
          arrayWithUserWorkplaces.push(id);
        });
      }

      if (userSubordinateInstitutions?.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        userSubordinateInstitutions?.forEach(({ id }) => {
          arrayWithUserSubordinateInstitutions.push(id);
        });
      }

      const requestPayload = {
        is_regional_admin: isRegionalAdmin,
        workplaces_ids: arrayWithUserWorkplaces,
        administrated_locations_ids: arrayWithUserSubordinateInstitutions.length
          ? arrayWithUserSubordinateInstitutions
          : [],
      };

      const requiredFields = REQUIRED_FIELDS.USERS_FORM.LOCATION;
      const validationErrors = validateRequiredFields(
        requestPayload,
        isRegionalAdmin ? [...requiredFields, 'administrated_locations_ids'] : requiredFields,
      );
      if (validationErrors.length) return setResponseErrors(setErrors, validationErrors);

      const result = await usersService.addUserLocation(id, requestPayload);

      if (result?.status === 200) {
        setNotificationHandler(t('User location was updated'), 'success');
        setErrors([]);
      } else {
        throw new Error('Something went wrong. Try again');
      }
    } catch (error) {
      const setters = { setNotificationHandler, setResponseErrors, setErrors };
      catchErrors(error, setters);
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit } = useForm({
    initialValues,
    onSubmit: (values: tUserLocationAPIData) => onSubmit(values),
  });

  const { isRegionalAdmin, userWorkplaces, userSubordinateInstitutions }: tUserLocationData =
    values;

  const isLoading = loadingLocationAddresses || loadingUserData || loading;

  return (
    <Form onSubmit={handleSubmit}>
      {isLoading && <Spinner />}
      <ExpansionPanelWrapper>
        <ExpansionPanel hasBottomBorder={!preview} title='Location' hasIcon iconName='apartment'>
          <FormWrapper>
            <Row justifyContent='space-between'>
              <Column>
                <DropdownWithList
                  dropdownDisabled={disabled}
                  preview={preview}
                  dropdownErrors={errors}
                  dropdownLabel='Workplace'
                  optionsData={locationAddresses.map((option) => ({
                    ...option,
                    fieldName: 'userWorkplaces',
                  }))}
                  optionFieldName='userWorkplaces'
                  handleChange={handleChange}
                  defaultItems={!!userWorkplaces && !!userWorkplaces.length ? userWorkplaces : []}
                />
              </Column>
              <Column>
                <Switch
                  label='Regional Admin'
                  id='1'
                  name='isRegionalAdmin'
                  disabled={disabled}
                  preview={preview}
                  errors={errors}
                  checked={isRegionalAdmin}
                  onChange={handleChange}
                />
              </Column>
              {isRegionalAdmin && (
                <Column>
                  <DropdownWithList
                    dropdownDisabled={disabled}
                    preview={preview}
                    dropdownErrors={errors}
                    dropdownLabel='Subordinate Institutions'
                    optionsData={locationAddresses.map((option) => ({
                      ...option,
                      fieldName: 'userSubordinateInstitutions',
                    }))}
                    optionFieldName='userSubordinateInstitutions'
                    handleChange={handleChange}
                    defaultItems={
                      !!userSubordinateInstitutions && !!userSubordinateInstitutions.length
                        ? userSubordinateInstitutions
                        : []
                    }
                  />
                </Column>
              )}
            </Row>
          </FormWrapper>
        </ExpansionPanel>
        {!preview && (
          <ButtonContainer>
            <Button disabled={!!disabled} type='submit' variant='green'>
              {t('Save')}
            </Button>
          </ButtonContainer>
        )}
      </ExpansionPanelWrapper>
    </Form>
  );
};

export default withRouter(LocationForm);
