import { useState, useEffect } from 'react';
import { tError } from 'types/global';

type tFormError = tError & {
  errorIndex?: number;
};

const useFormErrorsReset = (errors) => {
  const [formElemErrors, setFormElemErrors] = useState<tFormError[]>([]);

  useEffect(() => {
    setFormElemErrors(errors);
  }, [errors]);

  const resetFormError = (fieldName: string, errorIndex = null, loc: string[] = []) => {
    let filteredErrors: tFormError[] = [];
    if (loc.length) {
      const [firstLoc, secondLoc] = loc;
      filteredErrors = errors.filter(
        (error) => !(error.loc[0] === firstLoc && error.loc[1] === secondLoc),
      );

      setFormElemErrors(filteredErrors);
    }

    if (fieldName) {
      filteredErrors = errors.filter((error) => {
        if (!!errorIndex) {
          return !(error.errorIndex === errorIndex && error.field === fieldName);
        }
        return error.field !== fieldName;
      });
      setFormElemErrors(filteredErrors);
    }
    return filteredErrors;
  };

  return {
    formElemErrors,
    resetFormError,
  };
};

export default useFormErrorsReset;
