import styled, { css } from 'styled-components';
import { capitalizeFirst } from 'utils/helpers';
import Icon from 'components/Icon';
import { tTileContainer, tInverted } from './Tiles';

export const TilesContainer = styled.button<tTileContainer>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 140px;
  width: 228px;
  max-width: 12vw;
  min-height: 140px;
  height: 228px;
  max-height: 12vw;
  justify-content: center;
  box-sizing: border-box;
  padding-top: 1rem;
  padding-top: min(max(0.5rem, 0.75vw), 1rem);
  border: none;
  outline: none;
  border-radius: 0.5rem;
  box-shadow: var(--theme-mainShadow);
  overflow: hidden;
  z-index: var(--theme-containerZIndex);
  background-color: var(--theme-white);
  ${({ filled, bgColor }) =>
    filled &&
    bgColor &&
    `
      background-color: ${bgColor};
      background-image: var(--theme-gradient${capitalizeFirst(bgColor)});
    `}
  ${({ disabled }) =>
    disabled &&
    `
      background-color: var(--theme-mainGrey);
      background-image: none;
      cursor: default;
    `}
  cursor: default;
  ${({ clickable }) =>
    clickable &&
    `
      &:hover {
        filter: brightness(95%);
      }
      cursor: pointer;
    `}
`;

export const ArrowContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  top: 1.5rem;
  right: 1.5rem;
  position: absolute;
`;

export const TileIcon = styled(Icon)`
  width: 5rem;
  width: min(max(3rem, 5vw), 5rem);
  height: 5rem;
  height: min(max(3rem, 5vw), 5rem);
  margin-bottom: 0.5rem;
`;

export const TileBGIcon = styled(Icon)`
  position: absolute;
  width: 14rem;
  width: min(max(9rem, 12vw), 14rem);
  height: 14rem;
  height: min(max(9rem, 12vw), 14rem);
  top: 35%;
  left: 35%;
  opacity: 0.2;
  z-index: var(--theme-containerZIndex);
`;

export const TilesContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TilesTitle = styled.div<tInverted>`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 90%;
  height: 5rem;
  height: min(max(3rem, 5vw), 5rem);
  margin-bottom: 0.5rem;
  font-size: 2.5rem;
  font-size: min(max(1.8rem, 2.4vw), 2.5rem);
  font-weight: 900;
  color: var(--theme-mainDarkGrey);
  ${({ inverted }) =>
    inverted &&
    css`
      color: white;
    `}
`;

export const TilesText = styled.div<tInverted>`
  display: inline-flex;
  margin: 0;
  max-width: 80%;
  padding: 0 1rem;
  text-align: center;
  vertical-align: middle;
  min-height: 3rem;
  align-items: center;
  font-size: 1.125rem;
  font-size: min(max(0.8rem, 1.2vw), 1.125rem);
  font-weight: 900;
  color: var(--theme-mainDarkGrey);
  ${({ inverted }) =>
    inverted &&
    css`
      color: white;
    `}
`;
