import { useTranslation } from 'react-i18next';
import { Button } from 'components';
import { LeftMargin1Rem } from 'styles/GlobalStyledComponents';
import issuesService from 'services/issues';

export type tConfirmReceptionIssueAction = {
  history: { push: Function };
  setNotification: Function;
  issueId: string | number;
};

const ConfirmReceptionIssueAction: React.FC<tConfirmReceptionIssueAction> = ({
  history,
  setNotification,
  issueId,
}) => {
  const { t } = useTranslation();

  const acceptIssueHandler = async () => {
    try {
      const result = await issuesService.confirmReceipt(issueId);
      if (result?.status === 200) {
        setNotification(t('Issue was confirmed'), 'success');
        history.push('/issues');
      }
    } catch (error) {
      if (error?.status >= 400) return error.data.map((err) => setNotification(err.msg));
      setNotification(t('Something went wrong. Try again'));
    }
  };

  return (
    <LeftMargin1Rem>
      <Button variant='green' onClick={acceptIssueHandler}>
        {t('Receipt confirmation')}
      </Button>
    </LeftMargin1Rem>
  );
};

export default ConfirmReceptionIssueAction;
