import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Left, Right, Title } from 'styles/GlobalStyledComponents';
import { Breadcrumbs, AccessCheck } from 'components';
import { MainForm, LocationForm, SingleQualificationsAndPermissionsForm } from './Forms';

type tAddEditFormItsStructure = {
  isAddMode?: boolean;
  disabled?: boolean;
  preview?: boolean;
};

type tRightFormState = {
  state: boolean;
  id: number | null;
};

const AddEditFormItsStructure: React.FC<tAddEditFormItsStructure> = ({
  isAddMode = false,
  disabled = false,
  preview = false,
}): JSX.Element => {
  const { t } = useTranslation();
  const accessPermissions = disabled || preview ? ['tunEmployeesRead'] : ['tunEmployeesWrite'];

  const [itsStructureId, setItsStructureId] = useState<number | string | null>(null);
  const [addQualificationsAndPermissions, setAddQualificationsAndPermissions] =
    useState<tRightFormState>({
      state: false,
      id: null,
    });
  const [formName, setFormName] = useState<string>('');

  const getTitle = () => {
    if (isAddMode) return t('New ITS employee');
    if (disabled || preview) return t('ITS employee preview');
    return t('Edit ITS employee');
  };

  return (
    <>
      <AccessCheck permissions={accessPermissions} redirectTo='/its-structure' />
      <Breadcrumbs aliases={[formName]} />
      <Title>{getTitle()}</Title>
      <div style={{ display: 'flex', position: 'relative' }}>
        <Left>
          <MainForm
            isAddMode={isAddMode}
            disabled={disabled}
            preview={preview}
            setId={setItsStructureId}
            setFormName={setFormName}
          />
          <LocationForm isAddMode={isAddMode} disabled={disabled} preview={preview} />
        </Left>
        <Right>
          {!!addQualificationsAndPermissions.state && (
            <SingleQualificationsAndPermissionsForm
              disabled={disabled}
              isAddMode={isAddMode}
              qualificationId={addQualificationsAndPermissions.id}
              id={itsStructureId}
              closeForm={() => setAddQualificationsAndPermissions({ state: false, id: null })}
            />
          )}
        </Right>
      </div>
    </>
  );
};

export default AddEditFormItsStructure;
