import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon';
import { colorRedBasic, colorGreyBasic } from 'styles/GlobalStyles';
import { SearchContainer, SearchInput } from './Search.style';

type tSearch = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  resetValue: () => void;
  value: string;
  name: string;
};

const Search: React.FC<tSearch> = ({ onChange, name, resetValue, value }): JSX.Element => {
  const { t } = useTranslation();
  return (
    <SearchContainer>
      <Icon icon='search' fill={colorGreyBasic} />
      <SearchInput name={name} placeholder={t('Search...')} onChange={onChange} value={value} />
      {value.length > 0 && <Icon fill={colorRedBasic} asButton icon='close' onClick={resetValue} />}
    </SearchContainer>
  );
};

export default Search;
