import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const InputWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding-right: 0.5rem;
`;

export const PreviewText = styled.div`
  width: 100%;
  font-size: 0.75rem;
`;

export const PreviewWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 1rem;
`;
