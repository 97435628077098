import * as XLSX from 'xlsx';
import i18n from 'i18next';

export type tXLSIssueData = {
  reporter: string;
  id: number;
  contractAddress: string;
  industry: string;
  mode: string;
  shortDescription: string;
  description: string;
  clientSystemId: string | null;
};

const issuesStartsRowNo = 12;
const issuesOffset = 4;

const importerXLSGetin = (
  xlsBinary: string | ArrayBuffer | null | undefined,
): tXLSIssueData[] | null => {
  if (!xlsBinary) return null;

  try {
    const readerData = XLSX.read(xlsBinary, { type: 'binary' });
    const sheetName = readerData.SheetNames[0];
    const ws = readerData.Sheets[sheetName];
    const d: any = XLSX.utils.sheet_to_json(ws, { header: 1 });

    const extractIssue = (issueNo: number) => {
      return {
        industry: d[issuesStartsRowNo + issuesOffset * (issueNo - 1)][4],
        shortDescription: d[issuesStartsRowNo + 1 + issuesOffset * (issueNo - 1)][4],
        description: d[issuesStartsRowNo + 2 + issuesOffset * (issueNo - 1)][4],
      };
    };

    if (!d) return null;

    const firstName = d[36][4];
    const lastName = d[37][4];
    const contactPersonPhone = d[44][4];
    const contactPersonEmail = d[45][4];
    const location = d[30][4];
    const mode = d[25][1];
    const clientSystemId = d[27][1];
    const contactPersonFirstName = d[42][4];
    const contactPersonLastName = d[43][4];

    const issues = [extractIssue(1), extractIssue(2), extractIssue(3)];

    const issuesData: tXLSIssueData[] = [];

    issues.forEach((issue) => {
      if (issue.industry) {
        issuesData.push({
          id: issuesData.length + 1,
          contractAddress: location,
          reporter: firstName && lastName ? `${firstName} ${lastName}` : '',
          industry: issue.industry,
          mode,
          shortDescription: issue.shortDescription,
          description: `${issue.description} #${i18n.t('Contact')}: ${
            contactPersonFirstName ?? '-'
          } ${contactPersonLastName ?? '-'} ${contactPersonPhone ?? '-'} ${
            contactPersonEmail ?? '-'
          }`,
          clientSystemId: clientSystemId ?? null,
        });
      }
    });

    return issuesData;
  } catch {
    return null;
  }
};

export default importerXLSGetin;
