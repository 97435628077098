import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon';
import Button from 'components/Button';

import { Container } from './ErrorBoundary.style';

type tErrorBoundaryInfo = {
  onClick: () => void;
};

const ErrorBoundaryInfo = ({ onClick }: tErrorBoundaryInfo) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Icon icon='warning' width='30rem' height='15rem' />
      <h1>{t('Something went wrong')}.</h1>
      <Button variant='blue' type='button' onClick={onClick}>
        {t('Refresh page')}
      </Button>
    </Container>
  );
};

export default ErrorBoundaryInfo;
