/* eslint-disable camelcase */
import issues from 'agents/issues';
import { AxiosResponse } from 'axios';
import {
  tGetAllByPost,
  tGetAllIssuesParams,
  tIssueOnList,
  tRequestBody,
  tContractAddresses,
  tCreateIssueData,
  tCommentsRequestBody,
  tVisitsList,
  tIssueOnListWithIndustry,
  tIssueWithTechnician,
  tAcceptanceReports,
  tAcceptanceReportsAPI,
  tIssueFullInfoAPI,
  tIssueFullInfo,
  tNewComplaintData,
  tRelatedIssuesInFullInfoAPI,
  tSingleIssueInVisit,
  tGetVisitData,
  tGetAllByGet,
} from 'types/services/issues';
import i18n from 'i18next';
import { tAttachmentsToDelete } from 'types/views/contracts';
import { dateToDashDDMMYYYYColonHHMM, dateToHHMM, capitalizeFirst } from 'utils/helpers';
import contractsService from 'services/contracts';
import { tGet, tDropdownOption } from 'types/global';
import {
  tIssueHistoryResponseData,
  tTypeAssignmentAPI,
  tTypeCommentAPI,
  tTypeVisitAPI,
  tTypeAssignment,
  tTypeComment,
  tTypeUpdate,
  tTypeVisit,
  tTypeReportAPI,
} from 'types/services/history';

export type tFilterItem = { value: string | number };

const transformField = (field: string) => {
  switch (field) {
    case 'accounting_mode':
      return 'Mode';
    case 'short_description':
      return 'Short description';
    case 'client_system_id':
      return `Order number in customer's system`;
    case 'appliance_group':
      return 'Group';
    case 'preferred_fix_date':
      return 'Preferred completion date';
    case 'appliance':
      return 'Device';
    case 'human_readable_id':
      return 'Smart FM number';
    case 'client_preferred_fix_date':
      return 'Preferred completion date';
    case 'assigned_at':
      return 'Assignment date';
    case 'archived_at':
      return 'Archiving date';
    case 'issue_type':
      return 'Issue type';
    case 'periodic_inspection':
      return 'Maintenance';
    case 'rejection_reason':
      return 'Rejection reason';
    default:
      return capitalizeFirst(field);
  }
};

const getFilters = () =>
  issues.getFilters().then((response) => {
    const { data = {}, status } = response;

    return {
      data: {
        count: Object.entries(data).length,
        results: {
          ...data,
          client: data?.clients,
        },
      },
      status,
    };
  });

const getAll = async ({
  limit,
  offset = 0,
  ordering = { by: '', order: '' },
  search = '',
  archived = false,
  filters = {},
}: tGetAllByPost) => {
  const params: tGetAllIssuesParams = { offset };
  const { by, order } = ordering;
  const requestFilters: {
    [key: string]: (number | string)[];
  } = {};
  let requestBody: tRequestBody = { filter: {}, sort: {} };

  const transformSortByField = (field: string) => {
    switch (field) {
      case 'issueDate':
        return 'created_at';
      case 'type':
        return 'issue_type';
      case 'mode':
        return 'accounting_mode';
      case 'location':
        return 'address';
      case 'shortDescription':
        return 'short_description';
      case 'SFMNumber':
        return 'human_readable_id';
      case 'longStopDate':
        return 'completion_time';
      case 'ownNumber':
        return 'client_system_id';
      case 'client':
        return 'client_name';
      case 'industry':
      case 'status':
      case 'contract':
      case 'reporter':
      default:
        return field;
    }
  };

  if (!!filters && Object.entries(filters).length) {
    if (!!filters?.status) {
      requestFilters.statuses = filters.status.map((item: tFilterItem) => item.value);
    }

    if (!!filters?.client) {
      requestFilters.clients_ids = filters.client.map((item: tFilterItem) => item.value);
    }

    if (!!filters?.type) {
      requestFilters.type_ids = filters.type.map((item: tFilterItem) => item.value);
    }

    if (!!filters?.mode) {
      requestFilters.accounting_mode_ids = filters.mode.map((item: tFilterItem) => item.value);
    }

    if (!!filters?.location) {
      requestFilters.address_ids = filters.location.map((item: tFilterItem) => item.value);
    }

    if (!!filters?.contract) {
      requestFilters.contract_ids = filters.contract.map((item: tFilterItem) => item.value);
    }

    if (!!filters?.industry) {
      requestFilters.appliance_industry_ids = filters.industry.map(
        (item: tFilterItem) => item.value,
      );
    }

    if (!!filters?.reporter) {
      requestFilters.reporters = filters.reporter.map((item: tFilterItem) => item.value);
    }
  }

  if (!!by) requestBody = { ...requestBody, sort: { by: transformSortByField(by), order } };
  if (by === 'none') requestBody = { ...requestBody, sort: {} };
  if (limit) params.limit = limit;
  if (search) params.search = search;
  if (archived) params.archived = archived;
  if (Object.entries(requestFilters).length) {
    requestBody = { ...requestBody, filter: requestFilters };
  }
  const result = await issues.getAll(requestBody, params).then(async (response) => {
    const {
      status,
      data: { count, results: issues },
    } = response;

    if (!count) {
      return {
        data: {
          count,
          results: [],
        },
        status,
      };
    }

    const results: {
      [key: string]: string | number | Date | boolean;
    }[] = [];

    issues.forEach((issue: tIssueOnList, index: number) => {
      const {
        accounting_mode: { name: mode },
        contract_address: {
          address: { city, name, street },
        },
        client_system_id: ownNumber,
        completion_time: longStopDate,
        contract: { name: contract },
        created_at: issueDate,
        human_readable_id: SFMNumber,
        id,
        assigned_at: assignedAt,
        industry: { name: industry },
        issue_type: { name: type },
        reporter: { first_name, last_name },
        short_description: shortDescription,
        status,
        client_name: client,
        opened,
      } = issue;

      results.push({
        contract,
        id,
        industry,
        issueDate: !!issueDate ? dateToDashDDMMYYYYColonHHMM(new Date(issueDate), '.') : '-',
        location: `${name}, ${street}, ${city}`,
        longStopDate: !!longStopDate
          ? dateToDashDDMMYYYYColonHHMM(new Date(longStopDate), '.')
          : '-',
        mode,
        assignedAt: !!assignedAt ? dateToDashDDMMYYYYColonHHMM(new Date(assignedAt), '.') : '-',
        order: index + 1 + offset,
        ownNumber: !!ownNumber ? ownNumber : '-',
        reporter: `${first_name} ${last_name}`,
        SFMNumber,
        shortDescription,
        status,
        type,
        client,
        opened,
      });
    });

    const apiFilters = await getFilters();
    const {
      data: { results: filtersToData },
    } = apiFilters;

    const {
      industries: industry,
      types: type,
      accounting_modes: mode,
      contracts: contract,

      ...restFilters
    } = filtersToData;
    return {
      data: {
        count,
        results,
        filters: { industry, type, mode, contract, ...restFilters },
      },
      status,
    };
  });

  return result;
};

const getComments = (id: string | number, resolved: boolean) =>
  issues.getComments(id, resolved).then((response) => {
    const { data } = response;

    return data;
  });

export type tGetIssueParams = {
  id: string | number;
  fullData?: boolean;
  resolved?: boolean;
};

const get = async ({ id, resolved = false, fullData = false }: tGetIssueParams) => {
  const result = await issues
    .get(id)
    .then(async (response: { status: number; data: tIssueOnListWithIndustry }) => {
      let issueComments = [];

      const {
        data: {
          accounting_mode: { name: mode, id: accountingModeId },
          contract_address: {
            id: addressId,
            address: {
              name: addressName,
              city,
              street,
              building_number: buildingNumber,
              postal_code: postalCode,
            },
          },
          appliance,
          appliance_group,
          attachments,
          budget,
          client_system_id: ownNumber,
          completion_time: longStopDate,
          contract: { name: contract, id: contractId },
          description,
          human_readable_id: SFMNumber,
          id,
          appliance_industry: {
            id: applianceIndustryId,
            industry: { name: industryName, id: generalIndustryId },
          },
          issue_type: { name: type },
          locations,
          mruk_sites: mrukSites,
          preferred_completion_time: preferDateOfExecution,
          reporter: { email: mail, first_name, last_name, phone_number: phone, id: reporterId },
          short_description: shortDescription,
          status,
          system,
          _actions: actions,
          created_at: createdAt,
          appointment_date: appointmentDate,
          technicians,
        },
        status: responseStatus,
      } = response;
      if (!!actions?.view_comments) {
        issueComments = await getComments(id, resolved);
      }

      const mainTechnician = technicians?.length
        ? [
            {
              id: technicians[0].id,
              name: `${technicians[0].first_name} ${technicians[0].last_name}`,
            },
          ]
        : null;

      const additionalTechnicians = technicians?.length
        ? technicians
            .filter((item, index) => index !== 0)
            .map(({ id, first_name, last_name }) => ({ id, name: `${first_name} ${last_name}` }))
        : null;

      const { data } = await contractsService.getSingleAccountingMode(accountingModeId);
      const { reaction_time: reactionTime, reaction_time_unit: reactionTimeUnit } = data;

      if (fullData) {
        return {
          data: {
            issueComments,
            attachments: !!attachments.length ? attachments : [],
            budget,
            contract: { contract, contractId },
            description,
            id,
            industry: { label: industryName, id: generalIndustryId, value: applianceIndustryId },
            industryDevice: !!appliance?.name
              ? { value: appliance?.id, label: appliance?.name }
              : null,
            industryGroup: !!appliance_group?.name
              ? { label: appliance_group?.name, value: appliance_group?.id }
              : null,
            industrySystem: !!system?.name ? { label: system?.name, value: system?.id } : null,
            issueLocations: locations,
            issueMrukSites: mrukSites,
            location: { label: addressName, value: addressId, contractId },
            longStopDate: !!longStopDate
              ? dateToDashDDMMYYYYColonHHMM(new Date(longStopDate), '.')
              : '-',
            mail: !!mail ? mail : '-',
            accountingMode: { label: mode, value: accountingModeId },
            accountingModeTime: { reactionTime, reactionTimeUnit },
            ownNumber: !!ownNumber ? ownNumber : '-',
            phone: !!phone ? phone : '-',
            preferDateOfExecution: !!preferDateOfExecution ? new Date(preferDateOfExecution) : null,
            reporter: { mail, reporterId, phone, first_name, last_name },
            SFMNumber,
            shortDescription,
            status,
            type,
            actions,
            edit: true,
            createdAt: !!createdAt ? dateToDashDDMMYYYYColonHHMM(new Date(createdAt), '.') : '-',
            appointmentDate: !!appointmentDate
              ? dateToDashDDMMYYYYColonHHMM(new Date(appointmentDate))
              : null,
            mainTechnician,
            additionalTechnicians,
          },
          status: responseStatus,
        };
      }

      return {
        data: {
          issueComments,
          attachments: !!attachments.length ? attachments : [],
          budget,
          contract,
          description,
          id,
          industry: industryName ?? '-',
          industryDevice: appliance?.name ?? '-',
          industryGroup: appliance_group?.name ?? '-',
          industrySystem: system?.name ?? '-',
          issueLocations: [...mrukSites, ...locations],
          location: `${addressName}, ${street} ${buildingNumber}, ${postalCode} ${city}`,
          longStopDate: !!longStopDate
            ? dateToDashDDMMYYYYColonHHMM(new Date(longStopDate), '.')
            : '-',
          mail: mail ?? '-',
          mode,
          ownNumber: ownNumber ?? '-',
          phone: phone ?? '-',
          preferDateOfExecution: !!preferDateOfExecution
            ? dateToDashDDMMYYYYColonHHMM(new Date(preferDateOfExecution), '.')
            : '-',
          reporter: `${first_name} ${last_name}`,
          SFMNumber,
          shortDescription,
          status,
          type,
          actions,
          createdAt: !!createdAt ? dateToDashDDMMYYYYColonHHMM(new Date(createdAt)) : '-',
          appointmentDate: !!appointmentDate
            ? dateToDashDDMMYYYYColonHHMM(new Date(appointmentDate))
            : null,
          mainTechnician,
          additionalTechnicians,
        },
        status: responseStatus,
      };
    });
  return result;
};

const sendIssueAttachments = async (issueId: string, data: any) => {
  const { attachments } = data;
  try {
    await Promise.all(
      attachments.map(async (attachment) => {
        await issues.postAttachment(issueId, attachment);
      }),
    );
  } catch (error) {
    return 'Something went wrong when sending attachments';
  }
};

const create = async (data: tCreateIssueData) => {
  const response: AxiosResponse = await issues.create(data);
  const issueId: string = response?.data?.id;
  const { attachments } = data;
  const hasAttachments = !!attachments?.length;
  if (hasAttachments && issueId) await sendIssueAttachments(issueId, data);
  return { response, error: null };
};

const deleteAllAttachments = async (attachmentsToDelete: tAttachmentsToDelete) => {
  try {
    attachmentsToDelete.attachments.map(async (attachment) => {
      await contractsService.deleteAttachments(attachment);
    });
    return '';
  } catch (error) {
    return (
      error?.message ??
      i18n.t('Issue was updated but something went wrong while deleting an attachments')
    );
  }
};

const edit = async (
  editedIssueId: string,
  data: tCreateIssueData,
  attachmentsToDelete: tAttachmentsToDelete,
) => {
  const response: AxiosResponse = await issues.edit(editedIssueId, data);
  const issueId: string = response?.data?.id;
  const { attachments } = data;
  const hasAttachments = !!attachments?.length;
  if (hasAttachments && issueId) await sendIssueAttachments(issueId, data);
  let error = '';
  if (!!Object.keys(attachmentsToDelete).length) {
    const deletingResponse = await deleteAllAttachments(attachmentsToDelete);
    if (!!deletingResponse) error = deletingResponse;
  }
  return { response, error };
};

const editAndSendToTechManager = async (
  editedIssueId: string,
  data: tCreateIssueData,
  attachmentsToDelete: tAttachmentsToDelete,
) => {
  const response: AxiosResponse = await issues.editAndSendToTechManager(editedIssueId, data);
  const issueId: string = response?.data?.id;
  const { attachments } = data;
  const hasAttachments = !!attachments?.length;
  if (hasAttachments && issueId) await sendIssueAttachments(issueId, data);
  let error = '';
  if (!!Object.keys(attachmentsToDelete).length) {
    const deletingResponse = await deleteAllAttachments(attachmentsToDelete);
    if (!!deletingResponse) error = deletingResponse;
  }
  return { response, error };
};

export type tGetContractAddressUsersParams = {
  contractAddressId: string | number;
  roleId: string | number;
};
const getContractAddressUsers = async ({
  contractAddressId,
  roleId,
}: tGetContractAddressUsersParams) => {
  const response = await issues.getContractAddressUsers(contractAddressId, roleId);
  const { status }: { status: number } = response;
  const {
    results,
    count,
  }: {
    results: { id: number | string; first_name: string; last_name: string }[];
    count: number;
  } = response.data;

  const mapResults = results.map((user) => ({
    id: user.id,
    name: `${user.first_name} ${user.last_name}`,
  }));

  return {
    data: {
      results: mapResults,
      status,
      count,
    },
  };
};

const getContractAddressesActive = async (userId: string | number) => {
  const response = await issues.getContractAddressesActive(userId);
  const { status }: { status: number } = response;
  const { results }: { results: tContractAddresses[] } = response.data;

  return {
    data: results,
    status,
  };
};

export type tGetContractAddressesActiveByRoleParams = {
  userId: string | number;
  roleId: string | number;
};
const getContractAddressesActiveByRole = async ({
  userId,
  roleId,
}: tGetContractAddressesActiveByRoleParams) => {
  const response = await issues.getContractAddressesActiveByRole(userId, roleId);
  const { status }: { status: number } = response;
  const { results, count }: { results: tContractAddresses[]; count: number } = response.data;
  return {
    data: {
      results,
      status,
      count,
    },
  };
};

export const getAccountingModesByIndustry = async (
  contractId: string | number,
  industryId: string | number,
) => {
  const response = await issues.getAccountingModesByIndustry(contractId, industryId);
  const { status, data }: { status: number; data: { name: string; id: number }[] } = response;
  return {
    data,
    status,
  };
};

export const rejectAcceptIssue = async (url: string) => {
  const response = await issues.rejectAcceptIssue(url);
  const { status, data }: { status: number; data: { status: string } } = response;
  return { status, data };
};

const rejectIssueReasons = async () => {
  const response: AxiosResponse = await issues.rejectIssueReasons();
  const { data } = response;
  const optionsData: tDropdownOption[] = data?.map((reason) => ({
    value: reason.id,
    label: reason.name,
  }));
  return { data: { results: optionsData } };
};

export const rejectIssue = (
  issueId: number | string,
  { reasonId, comment }: { reasonId?: string | number; comment?: string },
) => {
  const params: { reason_id?: string | number; comment?: string } = {};
  if (reasonId) params.reason_id = reasonId;
  if (comment) params.comment = comment;
  return issues.rejectIssue(issueId, params);
};

export const assignIssue = (
  url: string,
  {
    contractorTechnicianId,
    isSubcontractorAssigned,
  }: {
    contractorTechnicianId: string | number;
    isSubcontractorAssigned: boolean;
  },
) => {
  if (isSubcontractorAssigned) {
    return issues.assignIssue(url, { subcontractor_id: contractorTechnicianId });
  }
  return issues.assignIssue(url, { technician_id: contractorTechnicianId });
};

export const commentAndSendToRegionalManager = async (
  url: string,
  bodyRequest: tCommentsRequestBody[],
) => {
  const response = await issues.commentAndSendToRegionalManager(url, bodyRequest);
  const { status, data }: { status: number; data } = response;
  return { status, data };
};

const getVisitsFilters = () =>
  issues.getVisitsFilters().then((response) => {
    const { data, status } = response;

    return {
      data: {
        count: Object.entries(data).length,
        results: {
          ...data,
        },
      },
      status,
    };
  });

export const getAllVisits = async (
  params: tGetAllByPost & { filters?: { address: tFilterItem[] } },
) => {
  const { offset = 0, filters, limit, ordering } = params;

  const requestParmas: tGetAllByGet & { address?: (string | number)[] } = { offset, limit };
  if (!!ordering?.by) requestParmas.sort = ordering.by;
  if (!!ordering?.order) requestParmas.order = ordering.order;

  const addressIds = filters?.address
    ? filters.address.map((item: { value: string | number }) => item.value)
    : null;

  if (addressIds) requestParmas.address = addressIds;
  const response = await issues.getAllVisits(requestParmas);
  const results: tVisitsList[] = response.data.results.map((result, index) => {
    const { address, visit_start, id, opened } = result;

    return {
      order: index + 1 + offset,
      address: `${address.street} ${address.building_number} ${address.city} ${address.postal_code}`,
      dateOfPlannedVisit: dateToDashDDMMYYYYColonHHMM(new Date(visit_start)),
      id,
      opened,
    };
  });
  const { count } = response.data;
  const { status } = response;

  const apiFilters = await getVisitsFilters();
  const {
    data: { results: filtersToData },
  } = apiFilters;

  return {
    data: { results, count, filters: filtersToData },
    status,
  };
};

const getVisit = async ({ id }: tGet) => {
  const result: AxiosResponse = await issues.getVisit(id);
  const {
    data: {
      assignments,
      additional_technicians: additionalTechnicians,
      estimated_time: estimatedTime,
      id: visitId,
      visit_start: visitStart,
      comment: visitComment,
      _actions: visitActions,
    },
    status: responseStatus,
  } = result;

  const issuesList: tSingleIssueInVisit[] = assignments.map((assignment: tIssueWithTechnician) => {
    const {
      accounting_mode: { name: mode },
      contract_address: {
        address: {
          name: addressName,
          city,
          street,
          building_number: buildingNumber,
          postal_code: postalCode,
        },
      },
      appliance,
      appliance_group,
      attachments,
      budget,
      client_system_id: ownNumber,
      completion_time: longStopDate,
      contract: { name: contract },
      description,
      human_readable_id: SFMNumber,
      id: issueId,
      appliance_industry: {
        industry: { name: industry },
      },
      issue_type: { name: type },
      locations,
      preferred_completion_time: preferDateOfExecution,
      reporter: { email: mail, first_name, last_name, phone_number: phone },
      short_description: shortDescription,
      status,
      system,
      _actions: actions,
      created_at: createdAt,
    } = assignment.issue;

    const {
      first_name: technicianFirstName,
      last_name: technicianLastName,
      personal_id: technicianPersonalId,
    } = assignment.technician;

    return {
      attachments: !!attachments.length ? attachments : [],
      budget,
      contract,
      description,
      issueId,
      industry: industry ?? '-',
      industryDevice: appliance?.name ?? '-',
      industryGroup: appliance_group?.name ?? '-',
      industrySystem: system?.name ?? '-',
      issueLocations: locations,
      location: `${addressName}, ${street} ${buildingNumber}, ${postalCode} ${city}`,
      longStopDate: !!longStopDate ? dateToDashDDMMYYYYColonHHMM(new Date(longStopDate), '.') : '-',
      mail: mail ?? '-',
      mode,
      ownNumber: ownNumber ?? '-',
      phone: phone ?? '-',
      preferDateOfExecution: !!preferDateOfExecution
        ? dateToDashDDMMYYYYColonHHMM(new Date(preferDateOfExecution), '.')
        : '-',
      reporter: `${first_name} ${last_name}`,
      SFMNumber,
      shortDescription,
      status,
      type,
      actions,
      createdAt: !!createdAt ? dateToDashDDMMYYYYColonHHMM(new Date(createdAt), '.') : '-',
      issueTechnician: `${technicianFirstName} ${technicianLastName}`,
      issueTechnicianPersonalId: technicianPersonalId ?? null,
    };
  });

  const returnData: tGetVisitData = {
    issuesList,
    estimatedTime,
    visitId,
    visitStart,
    visitActions,
    visitComment,
    additionalTechnicians,
  };

  return {
    data: returnData,
    status: responseStatus,
  };
};

const acceptVisit = (visitId: number | string, bodyRequest: { comment: string }) =>
  issues.acceptVisit(visitId, bodyRequest);

const rejectVisit = (visitId: number | string, bodyRequest: { comment: string }) =>
  issues.rejectVisit(visitId, bodyRequest);

export const convertAcceptanceReports = (
  acceptanceReports: tAcceptanceReportsAPI[],
): tAcceptanceReports[] => {
  return acceptanceReports?.length
    ? acceptanceReports.map((report) => {
        const {
          issue: {
            human_readable_id: readableId,
            reporter,
            issue_type,
            contract_address: { address },
            locations,
            appliance_industry,
            system,
            appliance_group,
            appliance,
            short_description: shortDescription,
            description,
            accounting_mode,
            client_system_id: clientSystemId,
          },
          work_start: workStart,
          work_end: workEnd,
          technician,
          additional_technicians,
          description: workDescription,
          activities_amounts,
          materials,
          measuring_instruments,
          next_visit_required,
          accepter_name,
          attachments,
        } = report;

        const reporterName = `${reporter?.first_name} ${reporter?.last_name}`;
        const reporterPhoneNumber = reporter?.phone_number;
        const reporterEmail = reporter?.email;
        const industryName = appliance_industry?.industry?.name;
        const contractAddress = `${address?.name}, ${i18n.t('St.')} ${address?.street} ${
          address?.building_number
        }, ${address?.postal_code} ${address?.city}`;
        const modeName = accounting_mode?.name;
        const mainTechnician = `${technician?.first_name} ${technician?.last_name}`;
        const additionalTechnicians = additional_technicians.map(
          (tech) => `${tech?.first_name} ${tech?.last_name}`,
        );
        const technicians = additional_technicians.length
          ? [...additionalTechnicians, mainTechnician]
          : [mainTechnician];
        const issueType = issue_type?.name;
        const activitiesList = activities_amounts.map(
          (activity) => `${activity.amount} ${activity.unit} ${activity.name}`,
        );
        const materialsList = materials.map(
          (material) => `${material.amount} ${material.unit} ${material.name}`,
        );
        const measuringInstruments = measuring_instruments.map(
          (instrument) => `${instrument.number} ${instrument.name}`,
        );
        const nextVisitRequired = !!next_visit_required;

        return {
          workStart,
          workEnd,
          readableId,
          reporter: reporterName,
          reporterContact: {
            phone: reporterPhoneNumber,
            email: reporterEmail,
          },
          issueType,
          location: contractAddress,
          locations,
          industry: industryName,
          system: system?.name || null,
          group: appliance_group?.name || null,
          device: appliance?.name || null,
          shortDescription,
          description,
          mode: modeName,
          clientSystemId,
          technician: technicians,
          workDescription,
          activitiesList,
          materialsList,
          measuringInstruments,
          nextVisitRequired,
          receiver: accepter_name,
          attachment: attachments,
        };
      })
    : [];
};

export const convertRelatedIssues = (relatedIssues: tRelatedIssuesInFullInfoAPI[]) => {
  return relatedIssues.map((relatedIssue) => {
    const {
      human_readable_id,
      client,
      reporter,
      created_at,
      issue_type,
      status,
      contract_address: {
        address: { name, street, building_number, postal_code, city },
      },
      locations,
      appliance_industry: { industry },
      system,
      appliance_group,
      appliance,
      short_description,
      description,
      accounting_mode,
      client_system_id,
      acceptance_reports,
      appointment_date: appointmentDate,
      technicians,
    } = relatedIssue;

    const mainTechnician = technicians?.length
      ? [
          {
            id: technicians[0].id,
            name: `${technicians[0].first_name} ${technicians[0].last_name}`,
          },
        ]
      : null;

    const additionalTechnicians = technicians?.length
      ? technicians
          .filter((item, index) => index !== 0)
          .map(({ id, first_name, last_name }) => ({ id, name: `${first_name} ${last_name}` }))
      : null;

    const location = `${name}, ${street} ${building_number}, ${postal_code} ${city}`;
    const createdAt = dateToDashDDMMYYYYColonHHMM(new Date(created_at));
    const reporterName = `${reporter?.first_name} ${reporter?.last_name}`;

    const acceptanceReports = acceptance_reports?.length
      ? convertAcceptanceReports(acceptance_reports)
      : [];

    const result: tIssueFullInfo = {
      readableId: human_readable_id,
      clientName: client?.name,
      reporter: reporterName,
      phone: reporter?.phone_number,
      email: reporter?.email,
      createdAt,
      type: issue_type?.name,
      status,
      location,
      locations,
      industry: industry?.name,
      system: system?.name || null,
      industryGroup: appliance_group?.name || null,
      industryDevice: appliance?.name || null,
      shortDescription: short_description,
      description,
      mode: accounting_mode?.name,
      ownNumber: client_system_id,
      acceptanceReports,
      attachments: [],
      appointmentDate: !!appointmentDate
        ? dateToDashDDMMYYYYColonHHMM(new Date(appointmentDate))
        : null,
      mainTechnician,
      additionalTechnicians,
    };

    return result;
  });
};

export type tIssueIdParam = { issueId: string };

export const getIssueFullInfo = async ({ issueId }: tIssueIdParam) => {
  const response: AxiosResponse = await issues.getIssueFullInfo(issueId);
  const { data, status }: { status: number; data: tIssueFullInfoAPI } = response;

  const {
    contract_address: {
      address: { name: addressName, city, street, building_number, postal_code },
    },
    short_description,
    locations,
    reporter: { email: mail, first_name, last_name, phone_number: phone },
    created_at,
    status: issueStatus,
    issue_type: { name: type },
    related_issue_readable_id,
    description,
    human_readable_id,
    appliance_industry: {
      industry: { name: industryName },
    },
    system,
    appliance_group,
    appliance,
    contract: { name: contractName },
    attachments,
    budget,
    accounting_mode: { name: accountingMode },
    completion_time,
    preferred_completion_time,
    client_system_id,
    acceptance_reports,
    related_issues,
    client,
    appointment_date: appointmentDate,
    technicians,
    _actions,
  } = data;

  const mainTechnician = technicians?.length
    ? [
        {
          id: technicians[0].id,
          name: `${technicians[0].first_name} ${technicians[0].last_name}`,
        },
      ]
    : null;

  const additionalTechnicians = technicians?.length
    ? technicians
        .filter((item, index) => index !== 0)
        .map(({ id, first_name, last_name }) => ({ id, name: `${first_name} ${last_name}` }))
    : null;

  const acceptanceReportsModel = [
    {
      workStart: '',
      workEnd: '',
      technician: '-',
      nextVisitRequired: '',
      activitiesList: [],
      materialsList: [],
      measuringInstruments: [],
      description: '-',
      receiver: -'',
    },
  ];

  const acceptanceReports = acceptance_reports?.length
    ? convertAcceptanceReports(acceptance_reports)
    : acceptanceReportsModel;

  const {
    description: workDescription,
    workStart,
    workEnd,
    technician,
    nextVisitRequired,
    activitiesList,
    materialsList,
    measuringInstruments,
  } = acceptanceReports[0];

  const relatedIssues = !!related_issues.length ? convertRelatedIssues(related_issues) : [];

  const result = {
    type,
    relatedIssueId: related_issue_readable_id,
    description,
    attachments,
    ownNumber: client_system_id,
    readableId: human_readable_id,
    longStopDate: !!completion_time ? dateToDashDDMMYYYYColonHHMM(new Date(completion_time)) : null,
    preferDateOfExecution: preferred_completion_time
      ? dateToDashDDMMYYYYColonHHMM(new Date(preferred_completion_time))
      : null,
    budget,
    industryDevice: appliance?.name || null,
    industryGroup: appliance_group?.name || null,
    contractName,
    industry: industryName,
    industrySystem: system?.name || null,
    mode: accountingMode,
    issueLocations: locations,
    acceptanceReports: acceptance_reports.length ? acceptanceReports : [],
    status: issueStatus,
    createdAt: dateToDashDDMMYYYYColonHHMM(new Date(created_at)),
    reporter: `${first_name} ${last_name}`,
    mail,
    phone,
    shortDescription: short_description,
    location: `${addressName} ${i18n.t('St.')} ${street} ${building_number} ${postal_code} ${city}`,
    visitDate:
      workStart || workEnd
        ? `${dateToDashDDMMYYYYColonHHMM(new Date(workStart))} - ${dateToHHMM(new Date(workEnd))}`
        : '-',
    technician,
    workDescription,
    materialList: materialsList,
    activitiesList,
    nextVisitNeeded: nextVisitRequired ? i18n.t('Yes') : i18n.t('No'),
    measuringInstruments,
    relatedIssues,
    clientName: client?.name,
    appointmentDate: !!appointmentDate
      ? dateToDashDDMMYYYYColonHHMM(new Date(appointmentDate))
      : null,
    mainTechnician,
    additionalTechnicians,
    actions: _actions,
  };
  return {
    data: result,
    status,
  };
};

export const createComplaint = async (newComplaintData: tNewComplaintData) => {
  const response: AxiosResponse = await issues.createComplaint(newComplaintData);
  const complaintId: string = response?.data?.id;
  const { attachments } = newComplaintData;
  const hasAttachments = !!attachments?.length;
  let error = '';
  if (hasAttachments && complaintId) {
    const attachmentsResponse = await sendIssueAttachments(complaintId, newComplaintData);
    if (!!attachmentsResponse) error = attachmentsResponse;
  }
  return { response, error };
};

export const confirmReceipt = (issueId: string | number) => issues.confirmReceipt(issueId);

export const rejectNotifiedIssue = async (url: string, comment: string) => {
  const result = await issues.rejectNotifiedIssue(url, comment);
  const { status } = result;
  return { status };
};

export const rejectSubcontractorIssue = async (rejectAssignmentUri: string, comment: string) => {
  const result = await issues.rejectSubcontractorIssue(rejectAssignmentUri, comment);
  const { status } = result;
  return { status };
};

export const getIssueHistory = async ({ issueId }: { issueId: string }) => {
  const { data, status }: AxiosResponse<tIssueHistoryResponseData[]> = await issues.getIssueHistory(
    issueId,
  );

  const timeFormatKeys = ['client_preferred_fix_date', 'assigned_at', 'archived_at'];

  const historyItems: (tTypeAssignment | tTypeVisit | tTypeComment | tTypeUpdate | null)[] =
    data?.length
      ? data.map((historyItem) => {
          const { type, date } = historyItem;

          switch (type) {
            case 'assignment': {
              const { assignment }: { assignment: tTypeAssignmentAPI | null } = historyItem;
              const createdByFirstName = assignment?.created_by?.first_name;
              const createdByLastName = assignment?.created_by?.last_name;
              const user =
                createdByFirstName && createdByLastName
                  ? `${createdByFirstName} ${createdByLastName}`
                  : i18n.t('No information');

              const technicianFirstName = assignment?.technician?.first_name;
              const technicianLastName = assignment?.technician?.last_name;
              const assignedTechnician =
                technicianFirstName && technicianLastName
                  ? `${technicianFirstName} ${technicianLastName}`
                  : assignment?.subcontractor?.name;

              return {
                type: 'assignment',
                field: 'Assignment',
                date: !!date ? dateToDashDDMMYYYYColonHHMM(new Date(date)) : null,
                user,
                after: assignedTechnician || i18n.t('No information'),
                id: assignment?.id || '',
              };
            }
            case 'visit': {
              const { visit }: { visit: tTypeVisitAPI | null } = historyItem;

              const createdByFirstName = visit?.created_by?.first_name;
              const createdByLastName = visit?.created_by?.last_name;
              const visitStart = visit?.visit_start;
              const user =
                createdByFirstName && createdByLastName
                  ? `${createdByFirstName} ${createdByLastName}`
                  : i18n.t('No information');
              const additionalTechnicians = visit?.additional_technicians?.length
                ? visit.additional_technicians.map(
                    (technician) => `${technician.first_name} ${technician.last_name}`,
                  )
                : [];
              return {
                type: 'visit',
                field: 'Visit',
                date: !!date ? dateToDashDDMMYYYYColonHHMM(new Date(date)) : null,
                user,
                id: visit?.id || '',
                additionalTechnicians: additionalTechnicians?.length
                  ? additionalTechnicians.join(', ')
                  : i18n.t('None'),
                comment: visit?.comment || '',
                visitStart: !!visitStart ? dateToDashDDMMYYYYColonHHMM(new Date(visitStart)) : null,
              };
            }
            case 'comment': {
              const { comment }: { comment: tTypeCommentAPI | null } = historyItem;
              const createdByFirstName = comment?.created_by?.first_name;
              const createdByLastName = comment?.created_by?.last_name;
              const user =
                createdByFirstName && createdByLastName
                  ? `${createdByFirstName} ${createdByLastName}`
                  : i18n.t('No information');

              return {
                type: 'comment',
                field: comment?.field ? transformField(comment?.field) : '',
                date: !!date ? dateToDashDDMMYYYYColonHHMM(new Date(date)) : null,
                user,
                comment: comment?.message || '',
                id: comment?.id || '',
              };
            }
            case 'report': {
              const { report }: { report: tTypeReportAPI | null } = historyItem;
              const createdByFirstName = report?.created_by?.first_name;
              const createdByLastName = report?.created_by?.last_name;
              const user =
                createdByFirstName && createdByLastName
                  ? `${createdByFirstName} ${createdByLastName}`
                  : i18n.t('No information');

              return {
                type: 'comment',
                field: 'Comment',
                date: !!date ? dateToDashDDMMYYYYColonHHMM(new Date(date)) : null,
                user,
                comment: report?.comment || '',
                id: report?.id || '',
              };
            }
            case 'update': {
              const { changeset, updated_by } = historyItem;
              if (!changeset || !Object.keys(changeset).length) return null;

              const keys = Object.keys(changeset);
              const updateByFirsName = updated_by?.first_name;
              const updateLastName = updated_by?.last_name;
              const user =
                updateByFirsName && updateLastName
                  ? `${updateByFirsName} ${updateLastName}`
                  : i18n.t('No information');

              const mappedKeys = keys.map((key) => {
                if (key === 'rejection_reason') {
                  const updateComment = () => {
                    if (changeset[key]['comment']) {
                      return changeset[key]['comment'];
                    } else if (changeset[key]['name']) {
                      return changeset[key]['name'];
                    } else {
                      return '';
                    }
                  };

                  return {
                    type: 'comment',
                    field: transformField(key),
                    date: !!date ? dateToDashDDMMYYYYColonHHMM(new Date(date)) : null,
                    user,
                    before: '',
                    after: '',
                    comment: updateComment(),
                    id: Date.now(),
                  };
                }

                const isChangesetArray = Array.isArray(changeset[key]);
                const isTimeFormatKey = timeFormatKeys.includes(key);
                const before =
                  isChangesetArray && changeset[key][0]
                    ? isTimeFormatKey
                      ? dateToDashDDMMYYYYColonHHMM(new Date(changeset[key][0]))
                      : changeset[key][0]
                    : '';

                const after =
                  isChangesetArray && changeset[key][1]
                    ? isTimeFormatKey
                      ? dateToDashDDMMYYYYColonHHMM(new Date(changeset[key][1]))
                      : changeset[key][1]
                    : '';

                return {
                  type: 'update',
                  field: transformField(key),
                  user,
                  date: !!date ? dateToDashDDMMYYYYColonHHMM(new Date(date)) : null,
                  before,
                  after,
                  id: Date.now(),
                };
              });

              return mappedKeys;
            }

            default:
              return null;
          }
        })
      : [];

  const result = historyItems
    .filter((item) => item)
    .reduce((acc: unknown[], curr) => {
      if (Array.isArray(curr)) curr.forEach((item) => acc?.push(item));
      else acc.push(curr);
      return acc;
    }, []);

  return {
    data: result,
    status,
  };
};

export default {
  getVisit,
  getFilters,
  getAll,
  get,
  create,
  edit,
  getContractAddressUsers,
  getContractAddressesActive,
  getContractAddressesActiveByRole,
  getAccountingModesByIndustry,
  rejectAcceptIssue,
  rejectIssueReasons,
  rejectIssue,
  assignIssue,
  acceptVisit,
  rejectVisit,
  commentAndSendToRegionalManager,
  editAndSendToTechManager,
  getComments,
  getAllVisits,
  convertRelatedIssues,
  convertAcceptanceReports,
  createComplaint,
  getIssueFullInfo,
  confirmReceipt,
  rejectNotifiedIssue,
  rejectSubcontractorIssue,
  getIssueHistory,
};
