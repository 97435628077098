/* eslint-disable no-param-reassign */
import { tSinglePreviewItem } from 'types/views/preview';

export const getIssuePreviewData = (
  data,
  {
    showStatus = false,
    showCreatedAt = false,
    showVisitData = false,
    showTechnician = false,
    showContract = false,
    hasARorKTITSPermission = false,
  },
) => {
  const {
    reporter = '-',
    phone = '-',
    mail = '-',
    createdAt = '-',
    type = '-',
    status = '-',
    location = '-',
    issueLocations = [],
    issueComments,
    industry = '-',
    industrySystem = '-',
    industryGroup = '-',
    industryDevice = '-',
    shortDescription = '-',
    description = '-',
    attachments = [],
    budget = '-',
    mode = '-',
    longStopDate = '-',
    preferDateOfExecution = '-',
    ownNumber = '-',
    visitDate = '-',
    technician = [],
    workDescription = '-',
    activitiesList = [],
    materialList = [],
    nextVisitNeeded = '-',
    measuringInstruments = [],
    comment = '-',
    issueTechnician = '-',
    contract = '-',
    appointmentDate,
    mainTechnician,
    additionalTechnicians,
  } = data;

  let result: tSinglePreviewItem[] = [
    {
      apiComment: '',
      apiKey: 'reporter',
      commentMode: false,
      comment: '',
      type: 'string',
      label: 'Reporter',
      value: reporter,
    },
    {
      apiComment: '',
      apiKey: 'contact',
      commentMode: false,
      comment: '',
      type: phone === '-' && mail === '-' ? 'string' : 'list',
      label: 'Contact',
      value:
        phone === '-' && mail === '-'
          ? '-'
          : [
              {
                id: 1,
                name: 'phone',
                content: phone,
              },
              {
                id: 2,
                name: 'mail',
                content: mail,
              },
            ],
    },
  ];

  if (showCreatedAt) {
    result = [
      ...result,
      {
        apiComment: '',
        apiKey: 'crated_at',
        commentMode: false,
        comment: '',
        type: 'string',
        label: 'Issue date',
        value: createdAt,
      },
    ];
  }

  result = [
    ...result,
    {
      apiComment: '',
      apiKey: 'issue_type',
      commentMode: false,
      comment: '',
      type: 'string',
      label: 'Issue type',
      value: type,
    },
  ];

  if (showStatus) {
    result = [
      ...result,
      {
        apiComment: '',
        apiKey: 'status',
        commentMode: false,
        comment: '',
        type: 'string',
        label: 'Status',
        value: status,
      },
    ];
  }

  result = [
    {
      apiComment: '',
      apiKey: 'location',
      commentMode: false,
      comment: '',
      type: 'list',
      label: 'Location',
      value: [
        {
          id: 1,
          content: location,
        },
      ],
    },
    {
      apiComment: '',
      apiKey: 'locations',
      commentMode: false,
      comment: '',
      type: 'list',
      label: ' ',
      elementHidden: !issueLocations.length,
      value: issueLocations.length
        ? [
            ...issueLocations.map((issueLocation: { id: number; name: string }) => ({
              id: issueLocation.id,
              content: issueLocation.name,
            })),
          ]
        : [],
    },
    ...result,
    {
      apiComment: '',
      apiKey: 'industry',
      commentMode: true,
      comment: '',
      type: 'string',
      label: 'Industry',
      value: industry,
    },
    {
      apiComment: '',
      apiKey: 'system',
      commentMode: true,
      comment: '',
      type: 'string',
      label: 'System',
      value: industrySystem,
      labelOptional: true,
    },
    {
      apiComment: '',
      apiKey: 'appliance_group',
      commentMode: true,
      comment: '',
      type: 'string',
      label: 'Group',
      value: industryGroup,
      labelOptional: true,
    },
    {
      apiComment: '',
      apiKey: 'appliance',
      commentMode: true,
      comment: '',
      type: 'string',
      label: 'Device',
      value: industryDevice,
      labelOptional: true,
    },
  ];

  if (showContract) {
    result = [
      ...result,
      {
        commentMode: false,
        type: 'string',
        label: 'Contract',
        value: contract,
      },
    ];
  }

  result = [
    ...result,
    {
      apiComment: '',
      apiKey: 'short_description',
      commentMode: true,
      comment: '',
      type: 'string',
      label: 'Short description',
      value: shortDescription,
    },
    {
      apiComment: '',
      apiKey: 'description',
      commentMode: true,
      comment: '',
      type: 'string',
      label: 'Description',
      value: description,
    },
    {
      apiComment: '',
      apiKey: 'attachments',
      commentMode: true,
      comment: '',
      type: !attachments.length ? 'string' : 'attachment',
      label: 'Attachments',
      value: !attachments.length ? '-' : attachments,
    },
    {
      apiComment: '',
      apiKey: 'budget',
      commentMode: true,
      comment: '',
      type: 'string',
      label: 'Budget',
      value: budget || '-',
      labelOptional: true,
    },
    {
      apiComment: '',
      apiKey: 'accounting_mode',
      commentMode: true,
      comment: '',
      type: 'string',
      label: 'Mode',
      value: mode,
    },
    {
      apiComment: '',
      apiKey: 'completion_time',
      commentMode: false,
      comment: '',
      type: 'string',
      label: 'Execution date to',
      value: longStopDate,
    },
  ];

  if (type !== 'Przegląd') {
    result = [
      ...result,
      {
        apiComment: '',
        apiKey: 'preferred_fix_date',
        commentMode: true,
        comment: '',
        type: 'string',
        label: 'Preferred due date',
        value: preferDateOfExecution,
        labelOptional: true,
      },
    ];
  }

  result = [
    ...result,
    {
      apiComment: '',
      apiKey: 'client_system_id',
      commentMode: true,
      comment: '',
      type: 'string',
      label: `Order number in customer's system`,
      value: ownNumber,
      labelOptional: true,
    },
  ];

  if (showTechnician) {
    result = [
      ...result,
      {
        commentMode: false,
        type: 'string',
        label: 'Technician ITS',
        value: issueTechnician,
      },
    ];
  }

  if (showVisitData) {
    result = [
      ...result,
      {
        commentMode: false,
        type: 'string',
        label: 'Visit date',
        value: visitDate,
      },
      {
        commentMode: false,
        type: 'string',
        label: 'Service technician',
        value: technician,
      },
      {
        commentMode: false,
        type: 'string',
        label: 'Work description',
        value: workDescription,
      },
      {
        commentMode: false,
        type: 'string',
        label: 'Work list',
        value: activitiesList?.length ? activitiesList.join(', ') : '-',
      },
      {
        commentMode: false,
        type: 'string',
        label: 'Material list',
        value: materialList?.length ? materialList.join(', ') : '-',
      },
      {
        commentMode: false,
        type: 'string',
        label: 'Next visit needed',
        value: nextVisitNeeded,
      },
      {
        commentMode: false,
        type: 'string',
        label: 'Measuring instruments',
        value: measuringInstruments?.length ? measuringInstruments.join(', ') : '-',
      },
      {
        commentMode: false,
        type: 'string',
        label: 'Comment',
        value: comment,
      },
    ];
  }

  if (hasARorKTITSPermission) {
    result = [
      ...result,
      {
        commentMode: false,
        type: 'string',
        label: 'Visit date nad time',
        value: !!appointmentDate ? appointmentDate : '-',
      },
      {
        commentMode: false,
        comment: '',
        type: !!mainTechnician?.length ? 'list' : 'string',
        label: 'Service technician',
        value: !!mainTechnician?.length
          ? mainTechnician.map(({ id, name }) => ({
              id,
              content: name,
            }))
          : '-',
      },
      {
        commentMode: false,
        comment: '',
        type: !!additionalTechnicians?.length ? 'list' : 'string',
        label: 'Additional technicians',
        value: !!additionalTechnicians?.length
          ? additionalTechnicians.map(({ id, name }) => ({
              id,
              content: name,
            }))
          : '-',
      },
    ];
  }

  return result.map((el) => ({
    ...el,
    apiComment: issueComments?.find((com) => com.field === el.apiKey)?.message ?? '',
  }));
};
