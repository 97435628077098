import { useCallback, useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { useDispatch } from 'react-redux';
import { addNotification } from 'store/notifications/actions';
import { Button, ExpansionPanel, TreeView, EditElement, Spinner, Modal } from 'components';
import TreeViewUtils from 'utils/TreeView';
import { useTranslation } from 'react-i18next';
import { Absolute, BorderGap, ExpansionPanelWrapper } from 'styles/GlobalStyledComponents';
import { tAPIError, tOpenedRightForm } from 'types/global';
import { tApplianceIndustriesTree } from 'types/api/contracts';
import { useSingleFetch } from 'hooks';
import applianceTreeService from 'services/applianceTree';
import { removeHtmlTagsFromString } from 'utils/helpers';

interface iSingleTreeOfSystemsAndIndustriesForm extends RouteComponentProps<any> {
  disabled: boolean | undefined;
  openRightPanel: (data: tOpenedRightForm) => void;
}

type tItem = {
  id: number | string;
  name: string;
};

type tIndustries = {
  data: tItem[];
  loading: boolean;
};

const SingleTreeOfSystemsAndIndustriesForm: React.FC<iSingleTreeOfSystemsAndIndustriesForm> = ({
  disabled,
  openRightPanel,
  match,
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = match.params;

  const [industries, setIndustries] = useState<tIndustries>({
    data: [],
    loading: false,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [removedItem, setRemovedItem] = useState<{
    name: string;
    id: string;
    showModal: boolean;
    type: string;
  }>({
    name: '',
    id: '',
    showModal: false,
    type: '',
  });

  const {
    fetch: setParamsHandler,
    loading: loadingData,
    data,
  } = useSingleFetch<tApplianceIndustriesTree>(
    applianceTreeService.getTree,
    'Something went wrong. Try again',
  );

  const fetchData = useCallback(() => {
    if (!!id) setParamsHandler({ id });
  }, [setParamsHandler, id]);

  const setApiErrorHandler = (
    error: tAPIError,
    defaultErrorMessage = 'Something went wrong. Try again',
  ) => {
    const errorArray = error?.data;
    if (!!error?.message) {
      dispatch(addNotification(t(error?.message ?? defaultErrorMessage), 'error'));
    } else if (Array.isArray(errorArray) && !!errorArray.length) {
      errorArray.forEach((err) => {
        if (err?.key === '__general__') {
          dispatch(
            addNotification(
              t(removeHtmlTagsFromString(String(err?.msg)) ?? defaultErrorMessage),
              'error',
            ),
          );
        }
      });
    } else {
      dispatch(addNotification(t(defaultErrorMessage), 'error'));
    }
  };

  const addIndustry = async (industryId: string | number) => {
    try {
      setLoading(true);
      if (!industryId) throw new Error('There is no industry ID');
      if (!data?.id) throw new Error('There is no appliance tree ID');
      const result = await applianceTreeService.addIndustry(data.id, industryId);
      if (!!result) fetchData();
    } catch (error) {
      setApiErrorHandler(error, 'Industry adding error');
    } finally {
      setLoading(false);
    }
  };

  const manageSystem = async (
    industryId = null as string | number | null,
    name = '' as string,
    systemId = null as string | number | null,
  ) => {
    try {
      setLoading(true);
      if (!name) throw new Error('Name cannot be empty');
      const result = await applianceTreeService.manageSystem(industryId, name, systemId);
      if (!!result) fetchData();
    } catch (error) {
      setApiErrorHandler(error, 'System management error');
    } finally {
      setLoading(false);
    }
  };

  const manageGroup = async (
    systemId = null as string | number | null,
    name = '' as string,
    groupId = null as string | number | null,
  ) => {
    try {
      setLoading(true);
      if (!name) throw new Error('Name cannot be empty');
      const result = await applianceTreeService.manageGroup(systemId, name, groupId);
      if (!!result) fetchData();
    } catch (error) {
      setApiErrorHandler(error, 'Device group management error');
    } finally {
      setLoading(false);
    }
  };

  const manageAppliance = async (
    groupId = null as string | number | null,
    name = '' as string,
    applianceId = null as string | number | null,
  ) => {
    try {
      setLoading(true);
      if (!name) throw new Error('Name cannot be empty');
      const result = await applianceTreeService.manageAppliance(groupId, name, applianceId);
      if (!!result) fetchData();
    } catch (error) {
      setApiErrorHandler(error, 'Device management error');
    } finally {
      setLoading(false);
    }
  };

  const getIndustries = async () => {
    try {
      setLoading(true);
      setIndustries({ ...industries, loading: true });
      const result = await applianceTreeService.getIndustries();
      setIndustries({ ...industries, loading: false, data: result });
    } catch (error) {
      setIndustries({
        ...industries,
        loading: false,
      });
      setApiErrorHandler(error, 'There was a problem with downloading industries');
    } finally {
      setLoading(false);
    }
  };

  const prepareTree = useCallback(() => {
    const treeHelper: any = [];
    const treeFromApi = data.appliance_industries ?? [];
    treeFromApi.forEach((industry) => {
      treeHelper.push({
        id: `industry-${industry.id}`,
        name: industry.industry?.name ?? '-',
        parent: null,
        add: !disabled
          ? () => (
              <EditElement action={(val: any) => manageSystem(industry.id, val, null)}>
                {t('Add a system')}
              </EditElement>
            )
          : null,
        edit: null,
      });
      const industrySystems = industry?.systems ?? [];

      industrySystems.forEach((system) => {
        treeHelper.push({
          id: `system-${system.id}`,
          name: system.name,
          parent: `industry-${industry.id}`,
          add: !disabled
            ? () => (
                <EditElement action={(val: any) => manageGroup(system.id, val)}>
                  {t('Add a group of devices')}
                </EditElement>
              )
            : null,
          edit: !disabled
            ? (clickHandler) => (
                <EditElement
                  type='element'
                  action={(val: any) => manageSystem(null, val, system.id)}
                  clickHandler={clickHandler}
                >
                  {system.name}
                </EditElement>
              )
            : null,
        });
        const systemGroups = system?.groups ?? [];
        systemGroups.forEach((group) => {
          treeHelper.push({
            id: `group-${group.id}`,
            name: group.name,
            parent: `system-${system.id}`,
            add: !disabled
              ? () => (
                  <EditElement action={(val: any) => manageAppliance(group.id, val, null)}>
                    {t('Add device')}
                  </EditElement>
                )
              : null,
            edit: !disabled
              ? (clickHandler) => (
                  <EditElement
                    type='element'
                    action={(val: any) => manageGroup(null, val, group.id)}
                    clickHandler={clickHandler}
                  >
                    {group.name}
                  </EditElement>
                )
              : null,
          });
          const groupAppliances = group?.appliances ?? [];
          groupAppliances.forEach((appliance) => {
            treeHelper.push({
              id: `appliance-${appliance.id}`,
              name: appliance.name,
              parent: `group-${group.id}`,
              add: null,
              edit: !disabled
                ? (clickHandler) => (
                    <EditElement
                      type='element'
                      action={(val: any) => manageAppliance(null, val, appliance.id)}
                      clickHandler={clickHandler}
                    >
                      {appliance.name}
                    </EditElement>
                  )
                : null,
            });
          });
        });
      });
    });
    return treeHelper;
  }, [data]);

  const openModal = (itemName: string, id: string) => {
    const type = id.split('-');
    const getType = (type) => {
      switch (type) {
        case 'industry':
          return 'industry';
        case 'group':
          return 'group';
        case 'system':
          return 'system';
        case 'appliance':
          return 'device';
        default:
          return '';
      }
    };

    setRemovedItem({ name: itemName, id, showModal: true, type: getType(type[0]) });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    getIndustries();
  }, []);

  const deleteFromTree = async (id: string) => {
    try {
      setLoading(true);
      const item = id.split('-');
      let result = false;
      const possibilityOptions = ['industry', 'appliance', 'group', 'system'];
      if (!possibilityOptions.includes(item[0])) {
        throw new Error(`You cannot delete instance like ${item[0]}`);
      }
      if (item[0] === 'industry') {
        result = await applianceTreeService.deleteIndustry(item[1]);
      }
      if (item[0] === 'appliance') {
        result = await applianceTreeService.deleteAppliance(item[1]);
      }
      if (item[0] === 'group') {
        result = await applianceTreeService.deleteGroup(item[1]);
      }
      if (item[0] === 'system') {
        result = await applianceTreeService.deleteSystem(item[1]);
      }
      if (!!result) fetchData();
    } catch (error) {
      setApiErrorHandler(error, 'There was a problem deleting an item from the tree');
    } finally {
      setLoading(false);
    }
  };

  const isLoading = loading || loadingData || industries.loading;
  return (
    <>
      <Absolute>
        {isLoading && <Spinner />}
        <ExpansionPanelWrapper>
          <ExpansionPanel
            shouldExpanded={false}
            hasBottomBorder={false}
            title='Tree of systems and industries'
            hasIcon
            iconName='accountTree'
          >
            <TreeView
              data={TreeViewUtils.createTreeStructure({
                items: prepareTree(),
                depthActionLevel: 3,
              })}
              title={t('Industries for this client')}
              itemsActions={
                !disabled
                  ? [
                      {
                        key: 'ia2',
                        icon: 'close',
                        fill: '#FF3E4A',
                        onClick: (id: string, name: string) => {
                          openModal(name, id);
                        },
                      },
                    ]
                  : []
              }
              topLevelActionComponent={
                !disabled ? (
                  <EditElement
                    options={industries.data.map((industry) => ({
                      value: industry.id,
                      label: industry.name,
                      fieldName: 'industry',
                    }))}
                    action={(val: any) => addIndustry(val?.value ?? '')}
                    name='industry'
                  >
                    {t('Add industry')}
                  </EditElement>
                ) : null
              }
              isOpen
            />
          </ExpansionPanel>

          <BorderGap />
          <div>
            <div style={{ display: 'flex', padding: '2rem 0', justifyContent: 'flex-end' }}>
              <Button
                kind='outlined'
                variant='red'
                onClick={() => openRightPanel({ form: '', id: null })}
              >
                {t('Close')}
              </Button>
            </div>
          </div>
        </ExpansionPanelWrapper>
      </Absolute>
      {removedItem.showModal && (
        <Modal
          icon='announcement'
          cancelMethod={() => {
            setRemovedItem({ name: '', id: '', showModal: false, type: '' });
          }}
          confirmMethod={() => {
            deleteFromTree(removedItem.id);
            setRemovedItem({ name: '', id: '', showModal: false, type: '' });
          }}
          title={t('Removal of entries from the systems and industries tree')}
        >
          <>
            {t('Are you sure you want to delete')} {t(removedItem.type)}: {removedItem.name}?
          </>
        </Modal>
      )}
    </>
  );
};

export default withRouter(SingleTreeOfSystemsAndIndustriesForm);
