import styled, { css } from 'styled-components';

type tSingleTab = {
  flexSpace: number;
};

type tNavButton = {
  disabled?: boolean;
  priceTypeColor?: boolean;
};

export const ContentWrapper = styled.div`
  margin: 0 -1rem;
`;

export const TabWrapper = styled.div`
  display: flex;
  padding: 1rem 0 0.5rem;
  border-bottom: 1px solid var(--theme-mainGrey);
  margin-bottom: 1rem;
  font-weight: bold;
`;

export const SingleTab = styled.div<tSingleTab>`
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  ${({ flexSpace }) =>
    flexSpace &&
    css`
      flex: ${flexSpace};
    `}

  &:first-child {
    padding-left: 1rem;
  }

  &:last-child {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1rem;
  }

  @media screen and (min-width: 1536px) {
    ${({ flexSpace }) =>
      flexSpace &&
      css`
        flex: ${flexSpace} + 1;
      `}
  }
`;

export const AccountingModeContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 1rem;
`;

export const AccountingMode = styled.div`
  flex: 2;
  flex-direction: row;
  padding: 0 0.5rem;
`;
export const AccountingModeHeader = styled.div`
  padding: 0 0.5rem;
  display: flex;
  justify-content: center;
  font-weight: 900;
`;

export const AccountingModeNav = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 0.5rem;
`;

export const AccountingModeNavButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NavButton = styled.button<tNavButton>`
  width: 1.25rem;
  height: 1.25rem;
  background-color: ${({ priceTypeColor }) =>
    priceTypeColor ? 'var(--theme-mainBlue)' : 'var(--theme-mainGrey)'};
  border-radius: var(--theme-borderRadius-xs);
  outline: 0;
  padding: 0;
  margin: 0;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  border: 0;
`;

export const ButtonLabel = styled.div`
  padding: 0 1rem;
  font-weight: 900;
`;

export const ServiceActivitiesContainer = styled.div`
  padding: 0rem 0.5rem;
  display: flex;
`;

export const InputContainer = styled.div`
  padding-top: 1rem;
  display: flex;
  align-items: center;
  flex: 2;
  align-items: center;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0rem 0rem 0rem 0.5rem;
`;

export const PriceListWrapper = styled.div`
  display: flex;
  padding-top: 1rem;
  align-items: flex-start;
`;

export const UnitDropdownWrapper = styled.div`
  min-width: 7rem;
`;

export const MarginBox = styled.div<any>`
  margin: ${({ value }) => value};
`;

export const AcceptedPriceNameBox = styled.div`
  align-items: center;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 0.25rem;
  width: 100%;
`;

export const AcceptedPriceNameText = styled.div`
  line-height: 1.2;
  word-break: break-all;
`;

export const IconFlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SumSufix = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 32px;
  font-weight: 900;
  font-size: 0.75rem;
  text-transform: lowercase;
`;

export const ErrorText = styled.p`
  color: var(--theme-mainRed);
`;
