import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logoutIdleTimeWarning } from 'utils/constants';
import { logout } from 'store/auth/actions';
import { SessionCounter } from './Timer.style';

const warningTimeSec = logoutIdleTimeWarning * 60;

const Timer = () => {
  const dispatch = useDispatch();

  const [countdown, setCountdown] = useState<number>(warningTimeSec);

  useEffect(() => {
    const tick = setInterval(() => {
      setCountdown((countdown) => countdown - 1);
    }, 1000);
    return () => clearInterval(tick);
  }, []);

  const minutes = String(Math.floor(countdown / 60));
  const seconds = String(countdown % 60).padStart(2, '0');

  if (countdown <= 0) {
    dispatch(logout());
  }

  return <SessionCounter>{`${minutes}:${seconds}`}</SessionCounter>;
};

export default Timer;
