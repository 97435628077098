/* eslint-disable camelcase */
import i18n from 'i18next';
import contractsAgent from 'agents/contracts';
import { tGet, tEnumsResult } from 'types/global';
import { tGetContractualPenaltiesAPI } from 'types/api/contracts';
import { tGetContractualPenaltiesData } from 'types/services/contracts';

export type tPenaltyResponseObject = {
  discount: null | number;
  discount_type: null | string | number;
  id?: number;
  money_amount: null | number | string;
  name: string;
  penalty_unit: string;
  type: string;
  unit_amount: number;
  show?: boolean;
};

const setPropertyBasedOnType = (
  types: string[],
  values: (string | number | null)[],
  kind: string,
): string | null | number => {
  const firstType = types[0];
  const secondType = types[1];
  const firstOption = values[0];
  const secondValue = values[1];
  const secondOption = kind === secondType ? secondValue : '';

  return kind === firstType ? firstOption : secondOption;
};

const transformDiscountType = (discountType: string | null | number): string => {
  switch (discountType) {
    case 'invoice':
      return i18n.t('Invoice value');
    case 'labor_value':
      return i18n.t('Labor value');
    case 'service_value':
      return i18n.t('Service value');
    default:
      return '-';
  }
};

const updatePenalties = async (bodyRequest: tPenaltyResponseObject[], contractId: number) => {
  const response = await contractsAgent.updatePenalties(bodyRequest, contractId);
  return response;
};

const addPenaltiesToContract = async (
  bodyRequest: tPenaltyResponseObject[],
  contractId: number,
) => {
  const response = await contractsAgent.addPenalties(bodyRequest, contractId);
  return response;
};

const getPenaltiesTypes = () =>
  contractsAgent.getPenaltiesTypes().then((response: { data: string[] }) => {
    const { data } = response;
    const count: number = data.length;
    const transformPenalty = (penaltyString: string): string => {
      switch (penaltyString) {
        case 'amount':
          return i18n.t('Amount');
        case 'discount':
          return i18n.t('Discount');
        default:
          return '-';
      }
    };

    const results: tEnumsResult[] = data.map((penalty: string, index: number) => ({
      id: index + 1,
      fieldName: 'penaltyType',
      label: transformPenalty(penalty),
      value: penalty,
    }));

    return {
      data: {
        count,
        results,
      },
    };
  });

const getPenaltiesUnits = () =>
  contractsAgent.getPenaltiesUnits().then((response: { data: string[] }) => {
    const { data } = response;
    const count: number = data.length;
    const transformUnit = (unitString: string): string => {
      switch (unitString) {
        case 'hour':
          return i18n.t('Hour');
        case 'item':
          return i18n.t('Item');
        default:
          return '-';
      }
    };

    const results: tEnumsResult[] = data.map((unit: string, index: number) => ({
      id: index + 1,
      fieldName: 'unitType',
      label: transformUnit(unit),
      value: unit,
    }));

    return {
      data: {
        count,
        results,
      },
    };
  });

const getPenaltiesDiscountTypes = () =>
  contractsAgent.getPenaltiesDiscountTypes().then((response: { data: string[] }) => {
    const { data } = response;
    const count: number = data.length;

    const results: tEnumsResult[] = data.map((discountType: string, index: number) => ({
      id: index + 1,
      fieldName: 'moneyOrPercentageValue',
      label: transformDiscountType(discountType),
      value: discountType,
    }));

    return {
      data: {
        count,
        results,
      },
    };
  });

const getContractualPenalties = ({ id }: tGet) =>
  contractsAgent.getContractualPenalties(+id).then((response) => {
    const { data } = response;
    const count: number = data.length;

    const returnData: tGetContractualPenaltiesData[] = data.map(
      (penalty: tGetContractualPenaltiesAPI) => {
        const {
          discount: discountValue,
          discount_type,
          id,
          money_amount,
          name: acceptedPenaltyName,
          penalty_unit,
          type,
          unit_amount: unitValue,
        } = penalty;

        const unitType = setPropertyBasedOnType(
          ['hour', 'item'],
          ['Godzina', 'Sztuka'],
          penalty_unit,
        );
        const penaltyType = setPropertyBasedOnType(
          ['amount', 'discount'],
          ['Kwota', 'Rabat'],
          type,
        );
        const moneyOrPercentageValue = setPropertyBasedOnType(
          ['amount', 'discount'],
          [money_amount, transformDiscountType(discount_type)],
          type,
        );

        return {
          id,
          penaltyName: acceptedPenaltyName,
          penaltyType,
          moneyOrPercentageValue,
          discountValue,
          acceptedPenaltyName,
          unitType,
          unitValue,
          show: true,
        };
      },
    );

    return {
      data: {
        count,
        results: returnData,
      },
    };
  });

export default {
  addPenaltiesToContract,
  getContractualPenalties,
  getPenaltiesDiscountTypes,
  getPenaltiesTypes,
  getPenaltiesUnits,
  updatePenalties,
};
