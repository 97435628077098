import contracts from 'services/contracts';
import { loginFailure } from 'store/auth/actions';
import types from './types';

export const saveCounters = (counters) => {
  return {
    type: types.SAVE_COUNTERS,
    payload: counters,
  };
};

export const getUnreadCounters = () => async (dispatch) => {
  try {
    const counters = await contracts.getUnreadCounters();

    dispatch(saveCounters(counters));
  } catch (error) {
    dispatch(loginFailure('Cannot get unread message counters'));
  }
};
