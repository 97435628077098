export const zipCodeRegex = [/[0-9]/, /\d/, '-', /\d/, /\d/, /\d/];
export const timeRegex = [/[0-2]/, /\d/, ':', /[0-5]/, /\d/];
export const personalIdNumberRegex = [
  /[a-zA-Z]/,
  /[a-zA-Z]/,
  /[a-zA-Z]/,
  /\d/,
  '*',
  '*',
  '*',
  '*',
  /\d/,
];
