/* eslint-disable no-unused-expressions */
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { addNotification } from 'store/notifications/actions';
import tStore from 'types/store';
import { useLocation } from 'react-router-dom';
import { useSingleFetch } from 'hooks';
import issuesService, { tIssueIdParam, tGetIssueParams } from 'services/issues';
import { withRouter, RouteComponentProps } from 'react-router';
import {
  Breadcrumbs,
  Button,
  ExpansionPanel,
  ModHistory,
  Label,
  PageWrapper,
  Preview,
  Textarea,
  Icon,
} from 'components';
import {
  ButtonsFormContainer,
  ExpansionPanelWrapper,
  Flex,
  FormWrapper,
  Left,
  LeftMargin1Rem,
  Right,
  Title,
} from 'styles/GlobalStyledComponents';
import { colorBlueBasic } from 'styles/GlobalStyles';
import { tSinglePreviewItem } from 'types/views/preview';
import { tCommentToField } from 'types/views/issues';
import { tError, tDropdownOption } from 'types/global';
import SubcontractorTechnicianSelect from 'views/Issues/SubcontractorTechnicianSelect';
import { userHasARorKTITSPermission } from 'utils/helpers';
import { getIssuePreviewData } from './issuePreviewData';
import { getIssueCopy } from './helpers';
import TechnicianSelect from '../../TechnicianSelect';
import { CommentField, TextareaContainer, ButtonWrapper } from './IssuePreview.style';
import IssueActionsPanel from '../../Actions/IssueActionsPanel';
import NewEditIssueForm from '../NewEditIssue/NewEditIssueForm';

type tComment = {
  state: boolean;
  field: string;
  text: string;
};

interface iIssuePreview extends RouteComponentProps<any> {
  confirmation?: boolean;
  commentsMode?: boolean;
}

const IssuePreview: React.FC<iIssuePreview> = ({
  history,
  match,
  commentsMode = false,
  confirmation = false,
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: issueId } = match.params;
  const permissions = useSelector((state: tStore) => state.user.permissions);

  const hasARorKTITSPermission = userHasARorKTITSPermission(permissions);
  const canReadIssueHistory = permissions.includes('tunIssuesHistoryRead');

  const [errors, setErrors] = useState<tError[]>([]);
  const [previewData, setPreviewData] = useState<tSinglePreviewItem[]>([]);
  const [technician, setTechnician] = useState<tDropdownOption | null>(null);
  const [isSubcontractorAssigned, setIsSubcontractorAssigned] = useState<boolean>(false);
  const [loadingTechnicians, setLoadingTechnicians] = useState(false);
  const [comment, setComment] = useState<tComment>({ state: false, field: '', text: '' });
  const [commentsData, setCommentsData] = useState<tCommentToField[]>([]);

  const {
    fetch: setParamsHandler,
    loading: loadingData,
    data,
  } = useSingleFetch<any, tGetIssueParams>(issuesService.get, 'Something went wrong. Try again');
  const {
    fetch: setConfirmationParamsHandler,
    loading: loadingConfirmationData,
    data: confirmationData,
  } = useSingleFetch<any, tIssueIdParam>(
    issuesService.getIssueFullInfo,
    'Something went wrong. Try again',
  );
  const {
    fetch: fetchIssueHistory,
    loading: loadingIssueHistory,
    data: issueHistoryData,
  } = useSingleFetch<any, tIssueIdParam>(
    issuesService.getIssueHistory,
    'Something went wrong. Try again',
  );

  const location: { state: { archived: boolean } } = useLocation();
  const isIssueArchived: boolean = !!location?.state?.archived ?? false;
  const status = data?.status ?? '-';
  const issueTitle = (confirmation ? confirmationData?.readableId : data?.SFMNumber) ?? '-';

  const setNotificationHandler = (message: string, type = 'error' as 'error' | 'success') => {
    const enableTimeout = type === 'success';
    dispatch(addNotification(t(message), type, enableTimeout));
  };

  const fetchData = useCallback(() => {
    if (issueId && !confirmation) setParamsHandler({ id: issueId, resolved: false });
  }, [setParamsHandler, issueId]);

  const fetchConfirmationData = useCallback(() => {
    if (issueId && confirmation) setConfirmationParamsHandler({ issueId });
  }, [setConfirmationParamsHandler, issueId, confirmation]);

  useEffect(() => {
    if (issueId && canReadIssueHistory) fetchIssueHistory({ issueId });
  }, [issueId, canReadIssueHistory]);

  useEffect(() => {
    if (!confirmation) return fetchData();
    return fetchConfirmationData();
  }, [fetchData]);

  useEffect(() => {
    if (!commentsMode) setComment({ ...comment, state: false });
  }, [commentsMode]);

  const editAction = {
    actionId: 1,
    action: (
      <Icon
        asButton
        icon='edit'
        fill={colorBlueBasic}
        onClick={() => {
          history.push({ pathname: `/issues/${issueId}/edit` });
        }}
      />
    ),
  };

  const commentAction = {
    actionId: 2,
    action: (
      <Icon
        asButton
        icon='comment'
        fill={colorBlueBasic}
        onClick={() => {
          history.push(`/issues/${issueId}/comments`);
        }}
      />
    ),
  };

  const actions = confirmation ? confirmationData?.actions : data?.actions;
  const issueCopyData = { status, issueId, issueTitle, isIssueArchived, commentsMode };
  const {
    title,
    crumbs,
    showStatus,
    showCreatedAt,
    showVisitData,
    showTechnicianSelect,
    showSubcontractorTechnicianSelect,
    showActionPanel,
    showPreviewEdit,
    showPreviewComments,
    showCreateComplaintBtn,
  } = getIssueCopy(status, actions, issueCopyData);

  useEffect(() => {
    !confirmation &&
      setPreviewData(
        getIssuePreviewData(data, {
          showStatus,
          showCreatedAt,
          showVisitData,
          hasARorKTITSPermission,
        }) ?? [],
      );
    confirmation &&
      setPreviewData(
        getIssuePreviewData(confirmationData, {
          showStatus: true,
          showCreatedAt: true,
          showVisitData: true,
          hasARorKTITSPermission,
        }) ?? [],
      );
  }, [data, confirmationData]);

  useEffect(() => {
    if (showPreviewEdit) {
      issuesService.getComments(issueId, false).then((result) => {
        setCommentsData(
          result.map((com) => ({
            resolved: com?.resolved,
            field: com?.field,
            message: com?.message,
          })),
        );
      });
    }
  }, [showPreviewEdit, issueId]);

  const getActions = (data) => {
    let actions: any[] = [];
    const shouldShowCommentAction =
      // eslint-disable-next-line camelcase
      data?.actions?.view_comments && data?.actions?.comment_and_send_to_regional_manager;
    if (data?.actions?.edit) actions = [editAction];
    if (shouldShowCommentAction) actions = [...actions, commentAction];
    return actions;
  };

  const createNewComplaint = () => {
    history.push(`/issues/${issueId}/complaint/new`);
  };

  const renderRightCommentsForm = () => {
    return (
      <Right>
        <ExpansionPanelWrapper>
          <ExpansionPanel
            shouldExpanded={false}
            hasBottomBorder
            hasIcon
            iconName='comment'
            title={t('Add comments')}
          >
            <Flex style={{ marginBottom: '1rem' }}>
              <Label>{t('Refers field')}</Label>
              <CommentField>{t(comment.field)}</CommentField>
            </Flex>
            <TextareaContainer>
              <Textarea
                name='issueComment'
                label={t('Comments')}
                onChange={({ target: { value } }) =>
                  setComment({
                    ...comment,
                    text: value,
                  })
                }
                errors={[]}
                value={comment.text}
              />
            </TextareaContainer>
          </ExpansionPanel>
          <ButtonsFormContainer>
            <Flex>
              <Button
                kind='outlined'
                variant='red'
                onClick={() =>
                  setComment({
                    state: false,
                    field: '',
                    text: '',
                  })
                }
              >
                {t('Cancel')}
              </Button>
              <LeftMargin1Rem>
                <Button
                  kind='filled'
                  variant='green'
                  onClick={() => {
                    const changedItem = previewData.find(
                      (item: tSinglePreviewItem) => item.label === comment.field,
                    );
                    if (!!changedItem) {
                      changedItem.comment = comment.text;
                    }
                    setComment({ state: false, field: '', text: '' });
                  }}
                >
                  {t('Add')}
                </Button>
              </LeftMargin1Rem>
            </Flex>
          </ButtonsFormContainer>
        </ExpansionPanelWrapper>
      </Right>
    );
  };

  const isLoading =
    loadingData || loadingTechnicians || loadingConfirmationData || loadingIssueHistory;

  return (
    <PageWrapper loading={isLoading}>
      <>
        <Breadcrumbs crumbs={crumbs} />
        <Title>{title}</Title>
        {showPreviewEdit ? (
          <NewEditIssueForm
            previewEditMode
            comments={commentsData}
            issueHistoryData={issueHistoryData}
          />
        ) : (
          <Flex>
            <Left>
              <ExpansionPanelWrapper>
                <ExpansionPanel
                  hasBottomBorder={!!showActionPanel}
                  hasIcon
                  iconName='issue'
                  shouldExpanded={false}
                  title={issueTitle}
                  actions={getActions(data)}
                >
                  <>
                    <FormWrapper>
                      <Preview
                        data={previewData}
                        comments={commentsMode}
                        setComment={(state: boolean, field: string, text: string) =>
                          setComment({ state, field, text })
                        }
                        previewComments={showPreviewComments}
                      />
                      {showTechnicianSelect && (
                        <TechnicianSelect
                          technician={technician}
                          setTechnician={setTechnician}
                          setIsSubcontractorAssigned={setIsSubcontractorAssigned}
                          setLoading={setLoadingTechnicians}
                          errors={errors}
                        />
                      )}
                      {showSubcontractorTechnicianSelect && (
                        <SubcontractorTechnicianSelect
                          technician={technician}
                          setTechnician={setTechnician}
                          setLoading={setLoadingTechnicians}
                          errors={errors}
                        />
                      )}
                    </FormWrapper>
                    {showCreateComplaintBtn && (
                      <ButtonWrapper>
                        <Button type='button' variant='blue' onClick={createNewComplaint}>
                          {t('Complaint')}
                        </Button>
                      </ButtonWrapper>
                    )}
                  </>
                </ExpansionPanel>
                {showActionPanel && (
                  <IssueActionsPanel
                    issueId={issueId}
                    actions={confirmation ? confirmationData?.actions : data?.actions}
                    setNotification={setNotificationHandler}
                    contractorTechnicianId={technician?.value || null}
                    isSubcontractorAssigned={isSubcontractorAssigned}
                    commentsMode={commentsMode}
                    previewData={previewData.filter((data) => data.comment)}
                    setErrors={setErrors}
                  />
                )}
              </ExpansionPanelWrapper>
              {issueHistoryData?.length && canReadIssueHistory ? (
                <ModHistory data={issueHistoryData} />
              ) : null}
            </Left>

            {comment.state && commentsMode && renderRightCommentsForm()}
          </Flex>
        )}
      </>
    </PageWrapper>
  );
};

export default withRouter(IssuePreview);
