import styled from 'styled-components';
import { tColumnStyle } from './Column';

export const Wrapper = styled.div<tColumnStyle>`
  @media screen and (max-width: 1099px) {
    width: 100%;
    margin-left: 0 !important;
  }
  width: ${(props) => `${(Number(props.col) / 12) * 100 - (props.offset / 24) * 100}%`};
  flex: 'none !important';
  margin-left: ${(props) => (props.offset ? `${(props.offset / 24) * 100}% !important` : null)};
  margin-left: ${(props) => (props.labelMinWidth ? '106px' : null)};
`;
