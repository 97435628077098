import styled from 'styled-components';

type tWrapper = {
  labelMinWidth: number;
};

export const ErrorMessage = styled.p`
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--theme-mainRed);
  margin: 0;
  margin-top: var(--theme-spacing);
`;

export const Wrapper = styled.div<tWrapper>`
  padding-left: 1rem;
  margin-left: ${({ labelMinWidth }) => `${labelMinWidth}px`};
`;
