import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Label, Input, DropdownV2 } from 'components';
import { tOption } from 'types/global';
import { Wrapper, InputWrapper, PreviewText, PreviewWrapper } from './InputDropdown.style';

type tChangeData = {
  custom: boolean;
  type: string;
  name: string;
  value: any;
};

export type tValues = {
  optionLabel: string;
  value: string;
};

type tInputDropdown = {
  name: string;
  label: string;
  dropdownOptions: tOption[];
  errors: any[];
  disabled?: boolean;
  preview?: boolean;
  labelMinWidth?: number;
  values: tValues;
  onChange: (changeData: tChangeData) => void;
  inputSufix?: string;
  labelOptional?: boolean;
};

const InputDropdown: React.FC<tInputDropdown> = ({
  name,
  label,
  dropdownOptions,
  values,
  errors = [],
  disabled = false,
  preview = false,
  labelMinWidth = 90,
  onChange,
  inputSufix = '',
  labelOptional = false,
}): JSX.Element => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState<tOption>({ label: '', value: '' });

  useEffect(() => {
    setInputValue(values?.value);
  }, [values]);

  useEffect(() => {
    setSelectedOption({
      value: values?.optionLabel,
      label: t(values?.optionLabel, { count: +inputValue }),
    });
  }, [inputValue]);

  const changeHandler = (data) => {
    onChange({ custom: true, type: 'input-dropdown', name, value: data });
  };

  const inputChangeHandler = (event) => {
    const newInputValue = event.target.value;
    setInputValue(newInputValue);
    const data = {
      value: event.target.value,
      optionLabel: selectedOption.value,
    };
    changeHandler(data);
  };

  const dropdownChangeHandler = (event) => {
    const { label, value } = event;
    setSelectedOption({ label, value });
    const data = {
      value: inputValue,
      optionLabel: value,
    };
    changeHandler(data);
  };

  return preview ? (
    <PreviewWrapper>
      <Label optional={labelOptional} labelMinWidth={labelMinWidth}>
        {t(label)}
      </Label>
      <PreviewText>
        {inputSufix
          ? `${values?.value ?? '-'} ${t(inputSufix)} ${t(values?.optionLabel, {
              count: +values?.value,
            })}`
          : `${values?.value ?? '-'} ${t(values?.optionLabel, { count: +values?.value })}`}
      </PreviewText>
    </PreviewWrapper>
  ) : (
    <Wrapper>
      {label && (
        <Label optional={labelOptional} styles={{ height: '2rem' }} labelMinWidth={labelMinWidth}>
          {t(label)}
        </Label>
      )}
      <InputWrapper>
        <Input
          name={`${name}Input`}
          onChange={inputChangeHandler}
          errors={errors}
          value={inputValue}
          disabled={disabled}
          labelMinWidth={0}
          inputSufix={inputSufix}
        />
      </InputWrapper>
      <DropdownV2
        name={`${name}Dropdown`}
        options={dropdownOptions}
        onChange={dropdownChangeHandler}
        errors={errors}
        disabled={disabled}
        value={selectedOption}
        labelMinWidth={0}
      />
    </Wrapper>
  );
};

export default InputDropdown;
