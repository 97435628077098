import { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from 'services/telemetry.js';

interface Props extends RouteComponentProps<any> {
  children: JSX.Element | JSX.Element[];
  after: () => null;
  instrumentationKey: string | null;
}

interface State {
  initialized: boolean;
}

/**
 * This Component provides telemetry with Azure App Insights
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking
 * that requires this to be a Class Component rather than a Functional Component
 */
class TelemetryProvider extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { initialized: false };
  }

  componentDidMount() {
    const { initialized } = this.state;
    const { instrumentationKey: AppInsightsInstrumentationKey, after, history } = this.props;
    if (!initialized && !!AppInsightsInstrumentationKey && !!history) {
      ai.initialize(AppInsightsInstrumentationKey, history);
      this.setState({ initialized: true });
    }
    after();
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default withRouter(withAITracking(ai.reactPlugin, TelemetryProvider));
