import { useState, useEffect, useCallback } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { useDispatch } from 'react-redux';
import { addNotification } from 'store/notifications/actions';
import { useTranslation } from 'react-i18next';
import { useSingleFetch, useForm, useFetch } from 'hooks';
import { Form, ExpansionPanel, DropdownV2, Row, Column, Spinner } from 'components';
import { FormWrapper } from 'styles/GlobalStyledComponents';
import { dateToDashYYYMMDD, catchErrors } from 'utils/helpers';
import { tContractTerminationValues } from 'types/views/contracts';
import { tOption, tError, tAPIFieldError } from 'types/global';
import contractsTerminationService, {
  tGetContractTerminationParams,
} from 'services/contractsTermination';
import { tContractTerminationsData } from 'types/services/contracts';
import ContractTerminationSection from './ContractTerminationSection';

type tChosenOption = {
  label: string;
  value: string;
};

interface iContractTerminationForm extends RouteComponentProps<any> {
  disabled?: boolean;
  preview?: boolean;
  isSubcontractorContract?: boolean;
}

const ContractTerminationForm: React.FC<iContractTerminationForm> = ({
  disabled = false,
  preview = false,
  isSubcontractorContract = false,
  match,
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { id: contractId } = match.params;
  const [loading, setLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<{}>({});
  const [errors, setErrors] = useState<tError[]>([]);
  const [chosenOption, setChosenOption] = useState<tChosenOption>({ label: ' ', value: '' });
  const [isClientTerminationVisible, setIsClientTerminationVisible] = useState<boolean>(false);
  const [isContractorTerminationVisible, setIsContractorTerminationVisible] =
    useState<boolean>(false);

  const {
    fetch: setParamsHandler,
    loading: loadingData,
    data,
  } = useSingleFetch<tContractTerminationsData, tGetContractTerminationParams>(
    contractsTerminationService.getContractTermination,
    'Something went wrong while fetching data',
  );

  const {
    fetch: setTerminationTypesParamsHandler,
    loading: loadingTerminationTypesData,
    data: terminationTypesData,
  } = useFetch<tOption>(contractsTerminationService.getContractTerminationTypes);

  const fetchTerminationTypesParamsHandler = useCallback(
    () => setTerminationTypesParamsHandler(),
    [setTerminationTypesParamsHandler, 'Something went wrong while fetching data'],
  );

  const setNotificationHandler = (message: string, type = 'error' as 'error' | 'success') => {
    const enableTimeout = type === 'success';
    dispatch(addNotification(t(message), type, enableTimeout));
  };

  const fetchData = useCallback(() => {
    if (!!contractId) setParamsHandler({ id: Number(contractId), isSubcontractorContract });
  }, [setParamsHandler, contractId, isSubcontractorContract]);

  useEffect(() => {
    fetchData();
    if (!disabled) fetchTerminationTypesParamsHandler();
    setErrors([]);
  }, [fetchData, fetchTerminationTypesParamsHandler, preview, disabled]);

  const setResponseErrors = (setter: Function, errors: tAPIFieldError[]) => {
    const transformField = (field: string, loc: (string | number)[]): string => {
      const [errorLoc] = loc;
      if (errorLoc === 'contractor_condition') {
        switch (field) {
          case 'unit':
            return 'contractorTerminationUnit';
          case 'notice_period':
            return 'contractorTerminationPeriod';
          default:
            return field;
        }
      } else {
        switch (field) {
          case 'unit':
            return 'clientTerminationUnit';
          case 'notice_period':
            return 'clientTerminationPeriod';
          default:
            return field;
        }
      }
    };

    setter(
      errors
        ? errors?.map((error: tAPIFieldError) => {
            const { key, msg, loc } = error;
            const locParam = !!loc?.length ? loc : [];
            return {
              field: transformField(key, locParam),
              error: msg,
            };
          })
        : [],
    );
  };

  const getInitialValues = (data) => {
    if (Object.keys(data).length > 0) {
      const {
        contractTerminationOptions: { label, value },
      } = data;
      return {
        ...data,
        contractTerminationOptions: {
          label: t(label),
          value,
        },
      };
    }
    return {};
  };

  const onSubmit = async ({ values }: { values: tContractTerminationValues }) => {
    try {
      setLoading(true);
      const {
        contractTerminationOptions,
        clientTerminationPeriod,
        clientTerminationReceivedDate,
        clientTerminationUnit,
        contractorTerminationPeriod,
        contractorTerminationReceivedDate,
        contractorTerminationUnit,
      } = values;

      const clientConditionApplicationDate = !!clientTerminationReceivedDate
        ? dateToDashYYYMMDD(clientTerminationReceivedDate)
        : null;

      const contractorConditionApplicationDate = !!contractorTerminationReceivedDate
        ? dateToDashYYYMMDD(contractorTerminationReceivedDate)
        : null;

      const clientCondition = isClientTerminationVisible
        ? {
            application_date: clientConditionApplicationDate,
            notice_period: clientTerminationPeriod,
            unit: clientTerminationUnit?.value,
          }
        : null;

      const contractorCondition = isContractorTerminationVisible
        ? {
            application_date: contractorConditionApplicationDate,
            notice_period: contractorTerminationPeriod,
            unit: contractorTerminationUnit?.value,
          }
        : null;

      const dataToSend = {
        type: contractTerminationOptions.value,
        client_condition: clientCondition,
        contractor_condition: contractorCondition,
        contract_id: Number(contractId),
      };

      if (!contractId) {
        return setNotificationHandler(
          t('Cannot define contract termination if contract does not exist'),
          'error',
        );
      }

      const hasContractTermination = !!contractId && !!data.terminationId;

      let result;
      if (!hasContractTermination) {
        result = await contractsTerminationService.createContractTermination(
          contractId,
          dataToSend,
          isSubcontractorContract,
        );
        fetchData();
      } else {
        const { terminationId } = data;

        result = await contractsTerminationService.updateContractTermination(
          terminationId,
          dataToSend,
        );
      }

      if (result.id) {
        setNotificationHandler(t('Contract termination was updated'), 'success');
        setErrors([]);
      } else {
        setNotificationHandler(t('Something went wrong. Try again'));
      }
    } catch (error) {
      const setters = { setNotificationHandler, setResponseErrors, setErrors };
      catchErrors(error, setters);
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit } = useForm({
    initialValues,
    onSubmit: (values) => onSubmit(values),
  });

  const onChange = (e) => {
    setChosenOption(e);
    handleChange(e);
  };

  useEffect(() => {
    const clientVisible = chosenOption.value === 'client' || chosenOption.value === 'mutual';
    setIsClientTerminationVisible(clientVisible);
    const contractorVisible =
      chosenOption.value === 'contractor' || chosenOption.value === 'mutual';
    setIsContractorTerminationVisible(contractorVisible);
  }, [chosenOption]);

  useEffect(() => {
    const initialValues = getInitialValues(data);
    setInitialValues(initialValues);
  }, [data]);

  useEffect(() => {
    if (!!Object.keys(data).length) {
      const {
        contractTerminationOptions: { value },
      } = data;
      switch (value) {
        case 'client':
          setIsClientTerminationVisible(true);
          setIsContractorTerminationVisible(false);
          break;
        case 'contractor':
          setIsClientTerminationVisible(false);
          setIsContractorTerminationVisible(true);
          break;
        case 'mutual':
          setIsClientTerminationVisible(true);
          setIsContractorTerminationVisible(true);
          break;
        default:
      }
    }
  }, [data]);

  const { contractTerminationOptions } = values;

  const isLoading = loadingTerminationTypesData || loading || loadingData;

  return (
    <Form onSubmit={handleSubmit}>
      <>
        {isLoading && <Spinner />}
        <ExpansionPanel title='Contract termination' hasIcon iconName='deleteForever'>
          <FormWrapper>
            <Row>
              <Column>
                <DropdownV2
                  options={terminationTypesData.map((option) => ({
                    ...option,
                    fieldName: 'contractTerminationOptions',
                  }))}
                  name='contractTerminationOptions'
                  label='Contract termination options'
                  onChange={onChange}
                  errors={errors}
                  value={contractTerminationOptions}
                  disabled={disabled}
                  preview={preview}
                />
              </Column>
            </Row>
          </FormWrapper>
        </ExpansionPanel>
        <ContractTerminationSection
          isClientTerminationVisible={isClientTerminationVisible}
          isContractorTerminationVisible={isContractorTerminationVisible}
          disabled={disabled}
          preview={preview}
          handleChange={handleChange}
          values={values}
          errors={errors}
        />
      </>
    </Form>
  );
};

export default withRouter(ContractTerminationForm);
