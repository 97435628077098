import { useTranslation } from 'react-i18next';
import FormErrorMessage from 'components/FormErrorMessage';
import { tError } from 'types/global';
import { Label } from 'components';
import { useFormErrorsReset } from 'hooks';
import {
  Wrapper,
  SwitchContainer,
  SwitchLabel,
  SwitchInput,
  SwitchOption,
  SwitchWrapper,
  CheckedTextStyle,
} from './Switch.style';

export type tSwitch = {
  checked?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  disabled?: boolean;
  preview?: boolean;
  errors: tError[];
  label?: string;
  id: string;
  labelMinWidth?: number;
  archSwitch?: any;
};

export type tLabel = {
  name: string;
  disabled?: boolean;
  errors: tError[];
  checked?: boolean;
};

export type tTitle = {
  labelMinWidth: number;
};

const Switch: React.FC<tSwitch> = ({
  errors,
  label,
  disabled = false,
  preview = false,
  id,
  checked = false,
  onChange,
  name,
  labelMinWidth = 90,
  archSwitch,
}) => {
  const { t } = useTranslation();

  const { formElemErrors, resetFormError } = useFormErrorsReset(errors);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    resetFormError(event?.target?.name);
    return onChange(event);
  };

  const checkedText = checked ? t(`Yes`) : t(`No`);

  return (
    <Wrapper>
      <SwitchContainer>
        {label && <Label labelMinWidth={labelMinWidth}>{t(label)}</Label>}
        {preview ? (
          <CheckedTextStyle>{checkedText}</CheckedTextStyle>
        ) : (
          <SwitchWrapper>
            <SwitchInput
              id={id}
              type='checkbox'
              checked={checked}
              onChange={handleChange}
              name={name}
              disabled={disabled}
              errors={formElemErrors}
              ref={archSwitch}
            />
            <SwitchLabel
              name={name}
              errors={formElemErrors}
              checked={checked}
              disabled={disabled}
              htmlFor={id}
            />
            <SwitchOption disabled={disabled}>{checkedText}</SwitchOption>
          </SwitchWrapper>
        )}
      </SwitchContainer>
      <FormErrorMessage name={name} errors={formElemErrors} labelMinWidth={labelMinWidth} />
    </Wrapper>
  );
};

export default Switch;
