import { useTranslation } from 'react-i18next';
import { Column, Icon } from 'components';
import { CustomLabelTitle, Flex } from 'styles/GlobalStyledComponents';
import { colorGreyBasic } from 'styles/GlobalStyles';
import {
  RowModHistory,
  RowFieldModHistory,
  Space,
  ModSpace,
  Label,
  CommentText,
  AdditionalInfoText,
  AdditionalInfoLightText,
  SpaceLeft,
  SpaceRight,
} from './ModHistory.style';

export type tSingleModHistoryData = {
  date: string;
  user: string;
  field: string;
  type: string;
  id: string;
  before?: string;
  after?: string;
  additionalTechnicians?: string;
  comment?: string;
  visitStart?: string;
};

type tSingleModHistory = {
  data: tSingleModHistoryData;
};

const SingleModHistory: React.FC<tSingleModHistory> = ({ data }): JSX.Element => {
  const { t } = useTranslation();

  const { date, user, field, before, after, type, additionalTechnicians, comment, visitStart } =
    data;

  const renderContent = () => {
    switch (type) {
      case 'visit': {
        return (
          <Column col={8}>
            <RowModHistory>{user}</RowModHistory>
            <Space />
            <RowFieldModHistory>{t(field)}</RowFieldModHistory>
            <Flex>
              <AdditionalInfoLightText>{t('Additional technicians')}</AdditionalInfoLightText>
              <AdditionalInfoText>{additionalTechnicians}</AdditionalInfoText>
            </Flex>
            <Flex>
              <AdditionalInfoLightText>{t('Date of the proposed visit')}</AdditionalInfoLightText>
              <AdditionalInfoText>{visitStart}</AdditionalInfoText>
            </Flex>
            <Flex>
              <AdditionalInfoLightText>{t('Comment')}</AdditionalInfoLightText>
              <AdditionalInfoText>{comment}</AdditionalInfoText>
            </Flex>
          </Column>
        );
      }
      case 'assignment': {
        return (
          <Column col={8}>
            <RowModHistory>{user}</RowModHistory>
            <Space />
            <RowFieldModHistory>{t(field)}</RowFieldModHistory>
            <Space />
            <RowModHistory>{after}</RowModHistory>
          </Column>
        );
      }
      case 'comment': {
        return (
          <Column col={8}>
            <RowModHistory>{user}</RowModHistory>
            <Space />
            <RowFieldModHistory>{t(field)}</RowFieldModHistory>
            <Space />
            <RowModHistory>
              <Icon icon='chat' fill={colorGreyBasic} width='1rem' height='1rem' />
              <CommentText>{comment}</CommentText>
            </RowModHistory>
          </Column>
        );
      }
      case 'update': {
        return (
          <Column col={8}>
            <RowModHistory>{user}</RowModHistory>
            <Space />
            <RowFieldModHistory>{t(field)}</RowFieldModHistory>
            <Space />
            <Flex>
              <RowModHistory>{before || t('None')}</RowModHistory>
              <SpaceLeft />
              <Icon icon='arrowRightAlt' width='1rem' height='1rem' />
              <SpaceRight />
              <RowModHistory>{after || t('None')}</RowModHistory>
            </Flex>
          </Column>
        );
      }
      default:
        return null;
    }
  };

  return (
    <>
      <Flex>
        <Column col={4}>
          <CustomLabelTitle>
            <Label>{date}</Label>
          </CustomLabelTitle>
        </Column>
        {renderContent()}
      </Flex>
      <ModSpace />
    </>
  );
};

export default SingleModHistory;
