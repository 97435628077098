import { tAction } from 'components/ExpansionPanel/ExpansionPanel';
import { colorBlueBasic, colorRedBasic } from 'styles/GlobalStyles';
import { Icon, SimpleDialog } from 'components';

export type tFormActions = {
  type: 'preview' | 'edit' | 'remove';
  action: () => void;
  show?: boolean;
  icon?: string;
  dialogTitle?: string;
  dialogMessage?: string;
};

const formActions = (actions: tFormActions[]): tAction[] => {
  let formattedActions: tAction[] = [];

  actions.forEach((act, index) => {
    const {
      type,
      action = () => {},
      show = true,
      icon: customIcon,
      dialogTitle,
      dialogMessage,
    } = act;
    const color = type === 'remove' ? colorRedBasic : colorBlueBasic;
    const iconByType = (type: string) => {
      switch (type) {
        case 'edit':
          return 'edit';
        case 'remove':
          return 'deleteBin';
        case 'preview':
        default:
          return 'visibility';
      }
    };

    const icon = customIcon || iconByType(type);

    const getActionComponents = () => {
      if (type === 'remove' && !!dialogMessage) {
        return (
          <SimpleDialog action={action} type='remove' title={dialogTitle} copy={dialogMessage}>
            <Icon icon={icon} fill={color} />
          </SimpleDialog>
        );
      }
      return <Icon asButton icon={icon} fill={color} onClick={action} />;
    };
    if (show) {
      formattedActions = [
        ...formattedActions,
        {
          actionId: index,
          action: getActionComponents(),
        },
      ];
    }
  });
  return formattedActions;
};

export default formActions;
