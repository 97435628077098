import { useTranslation } from 'react-i18next';
import { FormErrorMessage, Label } from 'components';
import { tError } from 'types/global';
import { useFormErrorsReset } from 'hooks';
import { TextareaStyle, Wrapper, MainWrapper, PreviewText } from './Textarea.style';

export type tTextarea = {
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  label?: string;
  labelOptional?: boolean;
  placeholder?: string;
  errors: tError[];
  disabled?: boolean;
  preview?: boolean;
  labelMinWidth?: number;
};

const Textarea: React.FC<tTextarea> = ({
  name,
  onChange,
  value,
  label = '',
  labelOptional = false,
  placeholder = '',
  errors = [],
  disabled = false,
  preview = false,
  labelMinWidth = 90,
}): JSX.Element => {
  const { t } = useTranslation();
  const { formElemErrors, resetFormError } = useFormErrorsReset(errors);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    resetFormError(event?.target?.name);
    onChange(event);
  };

  return (
    <MainWrapper>
      <Wrapper>
        {label && (
          <Label
            styles={{ justifyContent: 'flex-start' }}
            labelMinWidth={labelMinWidth}
            optional={labelOptional}
          >
            {t(label)}
          </Label>
        )}
        {preview ? (
          <PreviewText>{value ?? '-'}</PreviewText>
        ) : (
          <TextareaStyle
            name={name}
            value={value}
            onChange={handleChange}
            placeholder={t(placeholder)}
            errors={formElemErrors}
            disabled={disabled}
          />
        )}
      </Wrapper>
      <FormErrorMessage
        name={name}
        errors={formElemErrors}
        labelMinWidth={!!label ? labelMinWidth : 0}
      />
    </MainWrapper>
  );
};

export default Textarea;
