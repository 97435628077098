import { useState, useCallback, useEffect, RefObject, createRef } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { addNotification } from 'store/notifications/actions';
import {
  Breadcrumbs,
  DropdownV2,
  ExpansionPanel,
  Input,
  PageWrapper,
  Switch,
  Form,
  Button,
  Modal,
  Column,
  Row,
  AccessCheck,
} from 'components';
import { validateRequiredFields } from 'utils/form';
import { REQUIRED_FIELDS } from 'utils/requiredFormFields';
import { useSingleFetch, useFetch, useForm } from 'hooks';
import clientsService from 'services/clients';
import { tOption, tGet, tError } from 'types/global';
import { tGetClientData, tClientData } from 'types/services/clients';
import { tCreateClientAPI } from 'types/api/clients';
import tStore from 'types/store';
import { FormWrapper } from 'styles/GlobalStyledComponents';
import { zipCodeRegex } from 'utils/regex';
import { catchErrors } from 'utils/helpers';
import formActions from 'utils/formActions';
import {
  ExpansionPanelWrapper,
  Title,
  Wrapper,
  ButtonWrapper,
  LineGap,
} from './FormAddClient.style';
import { typeOfClientOptions } from './mockData';

interface iAddEditFormClient extends RouteComponentProps<any> {
  isAddMode?: boolean;
  disabled?: boolean;
  preview?: boolean;
}

type tKeyString = { [key: string]: string };

const getInitialValues = (result: tGetClientData) => {
  if (result && Object.keys(result).length) {
    const {
      address,
      archivingSwitch,
      customerName,
      id,
      legalForm,
      regon,
      sapNumber,
      segment,
      taxPayerId,
      typeOfClient,
      webPage,
    } = result;

    return {
      archivingSwitch,
      customerName,
      id,
      legalForm,
      regon,
      sapNumber,
      segment,
      taxPayerId,
      typeOfClient,
      webPage,
      country: address.country,
      region: address.region,
      city: address.city,
      street: address.street,
      buildingNumber: address.buildingNumber,
      postCode: address.postCode,
    };
  }
  return {};
};

const AddEditFormClient: React.FC<iAddEditFormClient> = ({
  isAddMode = false,
  disabled = false,
  preview = false,
  match,
  history,
}): JSX.Element => {
  const { id } = match.params;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accessPermissions = disabled || preview ? ['tunClientsRead'] : ['tunClientsWrite'];

  const permissions = useSelector((state: tStore) => state.user.permissions);
  const canWrite = preview && permissions.includes('tunClientsWrite');

  const [open, setOpen] = useState(false);
  const [initialValues, setInitialValues] = useState<tGetClientData | {}>({});
  const [loading, setLoading] = useState<boolean>(false);

  const {
    fetch,
    loading: loadingClientData,
    data,
  } = useSingleFetch<tGetClientData, tGet>(
    clientsService.get,
    'An error occurred while getting client data',
  );

  const {
    fetch: setParamsHandlerSegment,
    loading: loadingSegmentsData,
    data: segmentsData,
  } = useFetch<tOption>(clientsService.getSegments, 'An error occurred while getting segment data');

  const {
    fetch: setParamsHandlerRegions,
    loading: loadingRegionsData,
    data: regionsData,
  } = useFetch<tOption>(clientsService.getRegions, 'An error occurred while getting regions list');

  const {
    fetch: setParamsHandlerCountries,
    loading: loadingCountriesData,
    data: countriesData,
  } = useFetch<tOption>(
    clientsService.getCountries,
    'An error occurred while getting countries list',
  );

  const {
    fetch: setParamsHandlerLegalForm,
    loading: loadingLegalFormData,
    data: legalFormData,
  } = useFetch<tOption>(
    clientsService.getLegalForm,
    'An error occurred while getting legal form data',
  );

  const [errors, setErrors] = useState<tError[]>([]);

  const archSwitch: RefObject<HTMLInputElement> = createRef();

  const fetchData = useCallback(() => {
    if (id) fetch({ id: String(id) });
    setParamsHandlerSegment({});
    setParamsHandlerRegions({});
    setParamsHandlerCountries({});
    setParamsHandlerLegalForm({});
  }, [fetch, id]);

  useEffect(() => {
    fetchData();
    setErrors([]);
  }, [fetchData, preview, disabled]);

  useEffect(() => {
    const initialValues = getInitialValues(data);
    setInitialValues(initialValues);
  }, [data]);

  const setResponseErrors = (setter: Function, errors: tKeyString[]) => {
    const transformField = (field: string): string => {
      switch (field) {
        case 'name':
          return 'customerName';
        case 'nip':
          return 'taxPayerId';
        case 'type':
          return 'typeOfClient';
        case 'web_page':
          return 'webPage';
        case 'sap_number':
          return 'sapNumber';
        case 'segment_id':
          return 'segment';
        case 'postal_code':
          return 'postCode';
        case 'region_id':
          return 'region';
        case 'building_number':
          return 'buildingNumber';
        case 'legal_form_id':
          return 'legalForm';
        case 'regon':
          return 'regon';
        case 'street':
          return 'street';
        case 'city':
          return 'city';
        case 'archived':
          return 'archivingSwitch';
        default:
          return field;
      }
    };

    setter(
      errors
        ? errors?.map((error: tKeyString) => {
            const { key, msg } = error;
            return {
              field: transformField(key),
              error: msg,
            };
          })
        : [],
    );
  };

  const setNotificationHandler = (message: string, type = 'error' as 'error' | 'success') => {
    const enableTimeout = type === 'success';
    dispatch(addNotification(t(message), type, enableTimeout));
  };

  const onSubmit = async ({ values }: { values: tClientData }, isAddMode: boolean) => {
    try {
      setLoading(true);
      const {
        archivingSwitch,
        buildingNumber,
        city,
        customerName,
        legalForm,
        postCode,
        regon,
        sapNumber,
        segment,
        street,
        taxPayerId,
        typeOfClient,
        webPage,
        region,
      } = values;

      const requestPayload: tCreateClientAPI = {
        name: customerName,
        type: typeOfClient?.value,
        nip: taxPayerId,
        regon,
        web_page: webPage,
        sap_number: sapNumber,
        archived: archivingSwitch || false,
        segment_id: segment?.value,
        legal_form_id: legalForm?.value,
        address: {
          city,
          street,
          building_number: buildingNumber,
          postal_code: postCode,
          region_id: region?.value,
        },
      };

      const requiredFields = REQUIRED_FIELDS.CLIENTS_FORM;
      const validationErrors = validateRequiredFields(
        {
          ...requestPayload,
          city,
          street,
          building_number: buildingNumber,
          postal_code: postCode,
          region_id: region?.value,
        },
        requiredFields,
      );
      if (validationErrors.length) return setResponseErrors(setErrors, validationErrors);

      const result = isAddMode
        ? await clientsService.create(requestPayload)
        : await clientsService.edit(id, requestPayload);

      if (result?.status === 201 || result?.status === 200) {
        const notificationMessage = isAddMode
          ? t('New client has been created')
          : t('Client details were updated');
        setNotificationHandler(notificationMessage, 'success');
        setErrors([]);
        if (result?.data?.id) {
          history.push(`/customers/${result.data.id}/edit`);
        }
      } else {
        setNotificationHandler('Something went wrong. Try again');
      }
    } catch (error) {
      const setters = { setNotificationHandler, setResponseErrors, setErrors };
      catchErrors(error, setters);
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit } = useForm({
    initialValues,
    onSubmit: (values) => onSubmit(values, isAddMode),
  });

  const {
    customerName,
    country,
    sapNumber,
    segment,
    legalForm,
    taxPayerId,
    regon,
    typeOfClient,
    webPage,
    archivingSwitch,
    region,
    city,
    street,
    buildingNumber,
    postCode,
  } = values;

  const setArchiving = () => {
    if (!archivingSwitch) {
      setOpen(true);
    } else {
      handleChange({
        target: {
          name: 'archivingSwitch',
          type: 'customCheckbox',
          checked: !archivingSwitch,
        },
      });
    }
  };

  const getTitle = () => {
    if (isAddMode) return t('New Client');
    if (disabled || preview) return t('Client View');
    return t('Edit Client');
  };

  const editClient = () => {
    if (id) history.push(`/customers/${id}/edit`);
  };

  const actions = formActions([{ type: 'edit', action: editClient, show: canWrite }]);

  const isLoading =
    loading ||
    loadingClientData ||
    loadingSegmentsData ||
    loadingCountriesData ||
    loadingLegalFormData ||
    loadingRegionsData;

  return (
    <>
      <AccessCheck permissions={accessPermissions} redirectTo='/customers' />
      <PageWrapper loading={isLoading}>
        <Wrapper>
          <Breadcrumbs aliases={customerName ? [customerName] : []} />
          <Title>{getTitle()}</Title>
          <Form onSubmit={handleSubmit}>
            <ExpansionPanelWrapper>
              <ExpansionPanel
                hasBottomBorder={!disabled && !preview}
                hasIcon
                iconName='assignmentInd'
                title={t('Client data')}
                actions={actions}
              >
                <FormWrapper>
                  <Row justifyContent='space-between'>
                    <Column>
                      <Input
                        name='customerName'
                        label={t('Customer name')}
                        onChange={handleChange}
                        errors={errors}
                        value={customerName}
                        disabled={disabled}
                        preview={preview}
                      />
                    </Column>
                    <Column>
                      <DropdownV2
                        name='country'
                        label={t('Country')}
                        options={countriesData.map((option) => ({
                          ...option,
                          fieldName: 'country',
                        }))}
                        onChange={handleChange}
                        errors={errors}
                        value={country}
                        disabled={disabled}
                        preview={preview}
                        labelOptional
                      />
                    </Column>
                    <Column>
                      <Input
                        name='sapNumber'
                        label={t(`Payer's number from SAP`)}
                        onChange={handleChange}
                        errors={errors}
                        value={sapNumber}
                        disabled={disabled}
                        preview={preview}
                      />
                    </Column>
                    <Column>
                      <DropdownV2
                        name='segment'
                        options={segmentsData.map((option) => ({
                          ...option,
                          fieldName: 'segment',
                        }))}
                        label={t('Section')}
                        onChange={handleChange}
                        errors={errors}
                        value={segment}
                        disabled={disabled}
                        preview={preview}
                      />
                    </Column>
                    <Column>
                      <DropdownV2
                        name='legalForm'
                        options={legalFormData.map((option) => ({
                          ...option,
                          fieldName: 'legalForm',
                        }))}
                        label={t('Legal form')}
                        onChange={handleChange}
                        errors={errors}
                        value={legalForm}
                        disabled={disabled}
                        preview={preview}
                      />
                    </Column>
                    <Column col={preview ? 12 : 6}>
                      <Input
                        name='taxPayerId'
                        label='NIP'
                        onChange={handleChange}
                        errors={errors}
                        value={taxPayerId}
                        disabled={disabled}
                        preview={preview}
                      />
                    </Column>
                    <Column col={preview ? 12 : 6}>
                      <Input
                        name='regon'
                        label='REGON'
                        onChange={handleChange}
                        errors={errors}
                        value={regon}
                        disabled={disabled}
                        preview={preview}
                      />
                    </Column>
                    <Column>
                      <DropdownV2
                        name='typeOfClient'
                        label={t('Type of client')}
                        options={typeOfClientOptions}
                        onChange={handleChange}
                        errors={errors}
                        value={typeOfClient}
                        disabled={disabled}
                        preview={preview}
                      />
                    </Column>
                    <Column>
                      <Input
                        name='webPage'
                        label={t('Web page')}
                        onChange={handleChange}
                        errors={errors}
                        value={webPage}
                        disabled={disabled}
                        preview={preview}
                        labelOptional
                      />
                    </Column>
                    <Column>
                      <Switch
                        name='archivingSwitch'
                        errors={errors}
                        checked={archivingSwitch}
                        id='archivingSwitch'
                        onChange={setArchiving}
                        label={t('Archiving')}
                        archSwitch={archSwitch}
                        disabled={disabled}
                        preview={preview}
                      />
                    </Column>
                  </Row>
                </FormWrapper>
                <Column>
                  <LineGap />
                </Column>
                <FormWrapper>
                  <Row justifyContent='space-between'>
                    <Column>
                      <DropdownV2
                        name='region'
                        label={t('Province')}
                        options={regionsData.map((option) => ({
                          ...option,
                          fieldName: 'region',
                        }))}
                        onChange={handleChange}
                        errors={errors}
                        value={region}
                        disabled={disabled}
                        preview={preview}
                      />
                    </Column>
                    <Column>
                      <Input
                        name='city'
                        label={t('City')}
                        onChange={handleChange}
                        errors={errors}
                        value={city}
                        disabled={disabled}
                        preview={preview}
                      />
                    </Column>
                    <Column col={preview ? 12 : 8}>
                      <Input
                        name='street'
                        label={t('Street')}
                        onChange={handleChange}
                        errors={errors}
                        value={preview ? `${street || '-'} ${buildingNumber ?? ''}` : street}
                        disabled={disabled}
                        preview={preview}
                      />
                    </Column>
                    {!preview && (
                      <Column col={4}>
                        <Input
                          name='buildingNumber'
                          label={t('Number')}
                          onChange={handleChange}
                          labelMinWidth={preview ? undefined : 50}
                          errors={errors}
                          value={buildingNumber}
                          disabled={disabled}
                          preview={preview}
                        />
                      </Column>
                    )}
                    <Column col={5}>
                      <Input
                        name='postCode'
                        label={t('Post Code')}
                        onChange={handleChange}
                        errors={errors}
                        value={postCode}
                        disabled={disabled}
                        preview={preview}
                        placeholder='__-___'
                        mask={zipCodeRegex}
                      />
                    </Column>
                  </Row>
                </FormWrapper>
              </ExpansionPanel>
              {!disabled && !preview && (
                <ButtonWrapper>
                  <Button variant='green' type='submit'>
                    {t('Save')}
                  </Button>
                </ButtonWrapper>
              )}
            </ExpansionPanelWrapper>
          </Form>
        </Wrapper>
      </PageWrapper>
      {open && (
        <Modal
          icon='announcement'
          cancelMethod={() => setOpen(false)}
          confirmMethod={() => {
            handleChange({
              target: {
                name: 'archivingSwitch',
                type: 'customCheckbox',
                checked: !archivingSwitch,
              },
            });
            setOpen(false);
          }}
          title={t('Status change')}
        >
          {t('Archiving the client will make it impossible to add new contracts to it')}
        </Modal>
      )}
    </>
  );
};

export default withRouter(AddEditFormClient);
