import { Children, ReactNode } from 'react';
import { Column } from './Column';
import { Wrapper } from './Row.style';

type tRow = {
  children?: ReactNode | ReactNode[];
  col?: number;
  justifyContent?: string;
  alignItems?: string;
  offset?: string;
  labelMinWidth?: boolean;
};

export type tWrapperStyle = {
  justifyContent?: string;
  alignItems?: string;
};

export const Row: React.FC<tRow> = ({
  children,
  justifyContent = 'flex-start',
  alignItems = 'center',
}: tRow): JSX.Element => {
  return (
    <Wrapper justifyContent={justifyContent} alignItems={alignItems}>
      {Children.toArray(children).map((item: any, index) => {
        return (
          item &&
          item.props && (
            <Column
              key={`column-${index}`}
              col={item.props.col}
              offset={item.props.offset}
              labelMinWidth={item.props.labelMinWidth}
            >
              {item && item.props && item.props.children}
            </Column>
          )
        );
      })}
    </Wrapper>
  );
};

export default Row;
