import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  height: 2rem;
`;

export const FlexH = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`;

export const FlexV = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const InputWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 0.5rem;
`;

export const InputSufixContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  width: min-width;
  min-height: 2rem;
  font-size: 0.75rem;
  color: var(--theme-mainGrey);
  padding-right: 0.75rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 2rem;
`;

export const ErrorWrapper = styled.div`
  position: relative;
  margin-top: -1rem;
  margin-bottom: 1rem;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0.5rem;
`;

export const ItemRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.5rem;
`;

export const ItemTitle = styled.div`
  font-weight: bold;
  font-size: 0.75rem;
  margin: 0;
  padding-right: 0.25rem;
`;
