type tKeyString = {
  [key: string]: string;
};

export const setResponseErrors = (setter: Function, errors: tKeyString[]) => {
  const transformField = (field: string): string => {
    switch (field) {
      case 'name':
        return 'contractsName';
      case 'admin_name':
        return 'clientsContractSupervisor';
      case 'start':
        return 'contractDuration';
      case 'end':
        return 'contractDuration';
      case 'client_id':
        return 'clientsName';
      case 'contract_attachment':
        return 'contractContent';
      case 'currency':
        return 'billingCurrency';
      case 'vat_rate':
        return 'VATRate';
      case 'payment_due_date':
        return 'paymentDeadlineInput';
      case 'payment_due_rule':
        return 'paymentDeadlineDropdown';
      case 'billing_interval':
        return 'invoicingIntervalInput';
      case 'billing_interval_unit':
        return 'invoicingIntervalDropdown';
      case 'billing_period_days':
        return 'billingPeriodTo';
      case 'billing_approval':
        return 'acceptanceOfSettlement';
      case 'billing_approval_period':
        return 'timeForAcceptance';
      case 'valorizations':
        return 'valorizationIndicator';
      case 'valorization_interval':
        return 'valorizationPeriodInput';
      case 'valorization_interval_unit':
        return 'valorizationPeriodDropdown';
      case 'night_time_start':
        return 'nightModeStart';
      case 'night_time_end':
        return 'nightModeEnd';
      case 'attachment':
        return 'attachment';
      default:
        return '__noField__';
    }
  };

  setter(
    errors
      ? errors?.map((error: tKeyString) => {
          const { key, msg } = error;
          return {
            field: transformField(key),
            error: msg,
          };
        })
      : [],
  );
};
