export const timeUnitOptions = [
  { id: 1, value: 'day', label: 'day', fieldName: 'unit' },
  { id: 2, value: 'week', label: 'week', fieldName: 'unit' },
  { id: 3, value: 'month', label: 'month', fieldName: 'unit' },
];

export const maintenanceIntervalOptions = [
  { id: 1, value: 'week', label: 'week', fieldName: 'maintenanceIntervals' },
  { id: 2, value: 'month', label: 'month', fieldName: 'maintenanceIntervals' },
  { id: 3, value: 'year', label: 'year', fieldName: 'maintenanceIntervals' },
];

export const measurementUnitOptions = [
  { id: 1, value: 'piece', label: 'piece', fieldName: 'unit' },
  { id: 2, value: 'kpl', label: 'kpl', fieldName: 'unit' },
  { id: 3, value: 'mb', label: 'mb', fieldName: 'unit' },
  { id: 4, value: 'm2', label: 'm2', fieldName: 'unit' },
  { id: 5, value: 'm3', label: 'm3', fieldName: 'unit' },
  { id: 6, value: 'liter', label: 'liter', fieldName: 'unit' },
  { id: 7, value: 'kg', label: 'kg', fieldName: 'unit' },
  { id: 8, value: 'g', label: 'g', fieldName: 'unit' },
];

export const maintenanceOperationTypeOptions = [
  { id: 1, value: 'measurement', label: 'measurement', fieldName: 'type' },
  { id: 2, value: 'inspection', label: 'inspection', fieldName: 'type' },
  { id: 3, value: 'maintenance', label: 'maintenance', fieldName: 'type' },
];
