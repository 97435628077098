import styled from 'styled-components';

export const ButtonContainer = styled.div`
  margin-bottom: 0.875rem;
  margin-top: 2rem;
  text-align: right;
`;

export const PageWrapper = styled.div`
  margin-top: 1.5rem;
  width: 40vw;
`;
