import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { baseURL } from 'utils/constants';

export const CALL_API = Symbol('Call API');

interface CallApiActionSettings extends AxiosRequestConfig {
  endpoint: string;
  types: string[];
  payload?: any;
  checkCondition?: (response: AxiosResponse) => boolean;
  checkFailureCondition?: (response: AxiosResponse) => boolean;
}

async function callApi(endpoint: string, settings: any, apiUrl = baseURL) {
  try {
    const apiConfig = {
      ...settings,
      url: `${apiUrl}${endpoint}`,
      validateStatus: (status: number) => status >= 200 && status < 300,
    };

    const response = await axios(apiConfig);

    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
}

export default () => (next: Dispatch) => (action: any) => {
  const settings: CallApiActionSettings = action[CALL_API];

  if (typeof settings === 'undefined') {
    return next(action);
  }

  const { url, endpoint, types, ...data } = settings;
  const [requestType, successType, errorType] = types;
  next({ type: requestType });

  return callApi(endpoint, data, url).then(
    (response: AxiosResponse) => next({ response, type: successType }),
    (response: AxiosResponse) => next({ response, type: errorType }),
  );
};
