import i18n from 'i18next';

export const validateRequiredFields = (
  bodyRequest: { [key: string]: any },
  requiredFields: string[],
) => {
  return requiredFields
    .filter((field) => {
      return Array.isArray(bodyRequest[field])
        ? !bodyRequest[field].length
        : bodyRequest[field] === '' || bodyRequest[field] == null;
    })
    .map((field) => ({
      key: field,
      msg: i18n.t('This field is mandatory'),
    }));
};

export const validateRequiredFieldsWithLoc = (
  bodyRequest: { [key: string]: any },
  requiredFields: string[],
) => {
  return requiredFields.flatMap((field) => {
    return bodyRequest
      .filter((elem) => {
        return Array.isArray(elem[field])
          ? !elem[field].length
          : elem[field] === '' || elem[field] == null;
      })
      .map(({ index }) => ({
        key: field,
        msg: i18n.t('This field is mandatory'),
        loc: ['__root__', index, field],
      }));
  });
};
