import Spinner from 'components/Spinner';
import { Main } from './PageWrapper.style';

type tPageWrapper = {
  children?: (JSX.Element | string | false)[] | JSX.Element;
  loading?: boolean;
};

const PageWrapper: React.FC<tPageWrapper> = ({ children, loading = false }): JSX.Element => {
  return (
    <Main>
      {loading && <Spinner />}
      {children && children}
    </Main>
  );
};

export default PageWrapper;
