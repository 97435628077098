import { useEffect, useCallback, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useFetch } from 'hooks';
import {
  ExpansionPanel,
  Input,
  Datepicker,
  Row,
  Column,
  DropdownV2,
  FileUpload,
  Spinner,
} from 'components';
import clients, { tGetAllArchived } from 'services/clients';
import tStore from 'types/store';
import { addNotification } from 'store/notifications/actions';
import contractsService from 'services/contracts';
import { FormWrapper } from 'styles/GlobalStyledComponents';
import formActions from 'utils/formActions';
import { tOption, tError, tDropdownOption } from 'types/global';
import { tGetAllClientsData } from 'types/services/clients';
import { tFormValues } from '../ContractData';

interface iContractDataForm extends RouteComponentProps<any> {
  disabled?: boolean;
  preview?: boolean;
  isAddMode?: boolean;
  handleChange: (event) => void;
  errors: tError[];
  values: tFormValues;
  handleSoftDelete: (attachment, fieldName) => void;
  setCompanyData: React.Dispatch<React.SetStateAction<tDropdownOption | null>>;
}

const ContractDataForm: React.FC<iContractDataForm> = ({
  disabled = false,
  preview = false,
  isAddMode = false,
  handleChange,
  errors,
  values,
  handleSoftDelete,
  match,
  history,
  setCompanyData,
}): JSX.Element => {
  const { id } = match.params;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const contractStatus = useSelector((state: tStore) => state.contract.status);

  const {
    clientsName,
    contractsName,
    clientsContractSupervisor,
    contractDuration,
    contractDurationEnd,
    contractContent,
    attachments,
  } = values;

  const permissions = useSelector((state: tStore) => state.user.permissions);
  const canWrite = preview && permissions.includes('tunAddressesWrite');
  const canRemove =
    !isAddMode && permissions.includes('tunAddressesWrite') && contractStatus === 'draft';

  const [loading, setLoading] = useState<boolean>(false);
  const {
    fetch: setClientsParamsHandler,
    loading: loadingData,
    data: clientsData,
  } = useFetch<tGetAllClientsData, tGetAllArchived>(
    clients.getAll,
    'An error occurred while getting client data',
  );
  const fetchClientsData = useCallback(
    () => setClientsParamsHandler({ archived: false }),
    [setClientsParamsHandler],
  );

  useEffect(() => {
    fetchClientsData();
  }, [fetchClientsData, preview, disabled]);

  useEffect(() => {
    setCompanyData(clientsName);
  }, [clientsName]);

  const clientOptions: tOption[] = clientsData.map((client) => {
    const { id, customerName } = client;
    return {
      value: id,
      label: customerName,
    };
  });

  const editContract = () => history.push(`/contracts/${id}/edit`);

  const deleteContract = async () => {
    try {
      setLoading(true);
      const result = await contractsService.deleteContract(id);
      if (result.status === 204) {
        dispatch(addNotification(t('Contract was deleted'), 'success'));
        history.push('/contracts');
      } else {
        dispatch(addNotification(t('Something went wrong. Try again'), 'error'));
      }
    } catch (error) {
      dispatch(
        addNotification(error ?? error?.msg ?? t('Something went wrong. Try again'), 'error'),
      );
    } finally {
      setLoading(false);
    }
  };

  const actions = formActions([
    { type: 'edit', action: editContract, show: canWrite },
    {
      type: 'remove',
      action: deleteContract,
      show: canRemove,
      dialogTitle: 'Delete contract',
      dialogMessage: `${t('Are you sure you want to delete contract')} "${contractsName}"?`,
    },
  ]);

  const indefinitely = contractDurationEnd === null || contractDuration === contractDurationEnd;

  const isLoading = loading || loadingData;

  return (
    <ExpansionPanel
      hasBottomBorder={!disabled && !preview}
      title='Contract data'
      hasIcon
      iconName='noteAdd'
      actions={actions}
    >
      {isLoading && <Spinner />}
      <FormWrapper>
        <Row justifyContent='space-between'>
          <Column>
            <DropdownV2
              options={clientOptions.map((option) => ({
                ...option,
                fieldName: 'clientsName',
              }))}
              name='clientsName'
              label='Client'
              onChange={handleChange}
              errors={errors}
              value={clientsName}
              disabled={disabled}
              preview={preview}
            />
          </Column>
          <Column>
            <Input
              name='contractsName'
              label='Contracts name'
              onChange={handleChange}
              errors={errors}
              value={contractsName}
              disabled={disabled}
              preview={preview}
            />
          </Column>
          <Column>
            <Input
              name='clientsContractSupervisor'
              label={`Client's contract supervisor`}
              onChange={handleChange}
              errors={errors}
              value={clientsContractSupervisor}
              disabled={disabled}
              preview={preview}
            />
          </Column>
          <Column>
            <Datepicker
              name='contractDuration'
              label='Contract duration'
              title='Contract duration'
              variant='range'
              onChange={handleChange}
              start={contractDuration}
              end={contractDurationEnd}
              indefinitely={indefinitely}
              noEndDateAllowed
              disabled={disabled}
              readOnly={preview}
              hideIcon={preview}
              errors={errors}
            />
          </Column>
          <Column>
            <FileUpload
              name='contractContent'
              onChange={handleChange}
              label='Contract content'
              errors={errors}
              files={contractContent}
              acceptExtensions={['pdf', 'jpg', 'png', 'doc', 'docx']}
              attachmentsMaxLength={1}
              disabled={disabled || preview}
              handleSoftDelete={(attachment) => handleSoftDelete(attachment, 'contractContent')}
              labelOptional
            />
          </Column>
          <Column>
            <FileUpload
              name='attachments'
              onChange={handleChange}
              label='Attachments'
              errors={errors}
              files={attachments}
              acceptExtensions={['pdf', 'jpg', 'png', 'doc', 'docx', 'xls', 'xlsx']}
              disabled={disabled || preview}
              handleSoftDelete={(attachment) => handleSoftDelete(attachment, 'attachments')}
              labelOptional
            />
          </Column>
        </Row>
      </FormWrapper>
    </ExpansionPanel>
  );
};

export default withRouter(ContractDataForm);
