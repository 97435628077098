import { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addNotification } from 'store/notifications/actions';
import { withRouter, RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useFetch, useForm, useSingleFetch } from 'hooks';
import mruService from 'services/mru';
import { tCreateMruAPI } from 'types/api/mru';
import { tOption, tGet, tError } from 'types/global';
import { tGetMruData, tMru } from 'types/services/mru';
import tStore from 'types/store';
import clientsService from 'services/clients';
import {
  PageWrapper,
  Breadcrumbs,
  ExpansionPanel,
  Button,
  Form,
  Input,
  Checkbox,
  Row,
  Column,
  DropdownV2,
  AccessCheck,
  Spinner,
} from 'components';
import { REQUIRED_FIELDS, POSTAL_CODE_LENGTH } from 'utils/requiredFormFields';
import { validateRequiredFields } from 'utils/form';
import { catchErrors } from 'utils/helpers';
import { Title, ExpansionPanelWrapper, FormWrapper } from 'styles/GlobalStyledComponents';
import { zipCodeRegex } from 'utils/regex';
import formActions from 'utils/formActions';
import { Wrapper, ButtonWrapper } from './AddEditFormMRU.style';

interface iAddEditFormMRU extends RouteComponentProps<any> {
  isAddMode?: boolean;
  disabled?: boolean;
  preview?: boolean;
}

type tKeyString = { [key: string]: string };

const initialData: tMru = {
  MRUName: '',
  SAPID: '',
  country: null,
  region: null,
  city: '',
  street: '',
  buildingNumber: '',
  postCode: '',
  GPSLatitude: '',
  GPSLongitude: '',
  localizationImpel: false,
};

const getInitialValues = (result: tGetMruData): tMru => {
  if (result && Object.keys(result).length) {
    const { MRUName, MRUAddress, sapNumber, localizationImpel } = result;
    const data = {
      MRUName: MRUName ?? '',
      SAPID: sapNumber ?? '',
      country: MRUAddress?.country ?? null,
      region: MRUAddress?.region ?? null,
      city: MRUAddress?.city ?? '',
      street: MRUAddress?.street ?? '',
      buildingNumber: MRUAddress?.buildingNumber ?? '',
      postCode: MRUAddress?.postCode ?? '',
      GPSLongitude: MRUAddress?.geolocation?.longitude ?? '',
      GPSLatitude: MRUAddress?.geolocation?.latitude ?? '',
      localizationImpel,
    };
    return data;
  }
  return initialData;
};

const AddEditFormMRU: React.FC<iAddEditFormMRU> = ({
  isAddMode = false,
  disabled = false,
  preview = false,
  match,
  history,
}): JSX.Element => {
  const { id } = match.params;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accessPermissions = disabled || preview ? ['tunAddressesRead'] : ['tunAddressesWrite'];

  const permissions = useSelector((state: tStore) => state.user.permissions);
  const canWrite = preview && permissions.includes('tunAddressesWrite');
  const canRemove = !isAddMode && permissions.includes('tunAddressesWrite');

  const [loading, setLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<tMru>(initialData);
  const [localizationImpel, setLocalizationImpel] = useState<boolean>(false);

  const {
    fetch: setParamsHandler,
    loading: mruDataLoading,
    data,
  } = useSingleFetch<tGetMruData, tGet>(mruService.get, 'An error occurred while getting MRU');

  const {
    fetch: setParamsHandlerRegions,
    loading: loadingRegionsData,
    data: regionsData,
  } = useFetch<tOption>(clientsService.getRegions, 'An error occurred while getting regions');
  const {
    fetch: setParamsHandlerCountries,
    loading: loadingCountriesData,
    data: countriesData,
  } = useFetch<tOption>(clientsService.getCountries, 'An error occurred while getting countries');
  const [errors, setErrors] = useState<tError[]>([]);

  const fetchData = useCallback(() => {
    if (!!id) setParamsHandler({ id });
    setParamsHandlerRegions({});
    setParamsHandlerCountries({});
  }, [setParamsHandler, id]);

  useEffect(() => {
    fetchData();
    setErrors([]);
  }, [fetchData, preview, disabled]);

  const setNotificationHandler = (message: string, type = 'error' as 'error' | 'success') => {
    const enableTimeout = type === 'success';
    dispatch(addNotification(t(message), type, enableTimeout));
  };

  const setResponseErrors = (setter: Function, errors: tKeyString[]) => {
    const transformField = (field: string): string => {
      switch (field) {
        case 'name':
          return 'MRUName';
        case 'sap_id':
          return 'SAPID';
        case 'postal_code':
          return 'postCode';
        case 'region_id':
          return 'region';
        case 'building_number':
          return 'buildingNumber';
        case 'street':
          return 'street';
        case 'city':
          return 'city';
        case 'longitude':
          return 'GPSLongitude';
        case 'latitude':
          return 'GPSLatitude';
        default:
          return '__noField__';
      }
    };

    setter(
      errors
        ? errors?.map((error: tKeyString) => {
            const { key, msg } = error;
            return {
              field: transformField(key),
              error: msg,
            };
          })
        : [],
    );
  };

  const onSubmit = async ({ values }: { values: tMru }, isAddMode: boolean) => {
    try {
      setLoading(true);
      const {
        GPSLatitude,
        GPSLongitude,
        MRUName,
        SAPID,
        buildingNumber,
        city,
        postCode,
        region,
        street,
      } = values;

      const geolocation = {
        latitude: !!GPSLatitude ? Number(GPSLatitude) : null,
        longitude: !!GPSLongitude ? Number(GPSLongitude) : null,
      };

      const postCodeWithoutUnderscores = postCode.split('_').join('');
      const requestPayload: tCreateMruAPI = {
        name: MRUName,
        region_id: Number(region?.value) ?? null,
        city,
        street,
        building_number: buildingNumber,
        postal_code: postCodeWithoutUnderscores,
        geolocation,
        impel_location: localizationImpel || false,
        sap_id: !!SAPID ? SAPID : null,
      };

      const requiredFields = REQUIRED_FIELDS.MRU_FORM;
      const validationErrors = validateRequiredFields(
        { ...requestPayload, ...geolocation },
        requiredFields,
      );

      if (validationErrors.length || postCodeWithoutUnderscores.length < POSTAL_CODE_LENGTH) {
        return setResponseErrors(
          setErrors,
          postCodeWithoutUnderscores.length < POSTAL_CODE_LENGTH
            ? [...validationErrors, { key: 'postal_code', msg: t('Invalid format') }]
            : validationErrors,
        );
      }

      const result = isAddMode
        ? await mruService.create(requestPayload)
        : await mruService.edit(id, requestPayload);

      if (result?.status === 201 || result?.status === 200) {
        const message = isAddMode ? t('MRU was added') : t('MRU was updated');
        setNotificationHandler(message, 'success');
        setErrors([]);
        history.push(`/mru/${result.data.id}/edit`);
      } else {
        setNotificationHandler('Something went wrong. Try again');
      }
    } catch (error) {
      const setters = { setNotificationHandler, setResponseErrors, setErrors };
      catchErrors(error, setters);
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit } = useForm({
    initialValues,
    onSubmit: (values) => onSubmit(values, isAddMode),
  });

  const {
    MRUName,
    SAPID,
    country,
    region,
    city,
    street,
    buildingNumber,
    postCode,
    GPSLongitude,
    GPSLatitude,
  } = values;

  useEffect(() => {
    const initialValues = getInitialValues(data);
    setLocalizationImpel(data?.localizationImpel);
    setInitialValues(initialValues);
  }, [data]);

  const getTitle = () => {
    if (isAddMode) return t('New MRU');
    if (disabled || preview) return t('MRU View');
    return t('Edit MRU');
  };

  const editMRU = () => history.push(`/mru/${id}/edit`);

  const deleteMRU = async () => {
    try {
      setLoading(true);
      const result = await mruService.deleteMRU(id);
      if (result?.status === 204) {
        dispatch(addNotification(t('MRU was deleted'), 'success'));
        history.push('/mru');
      } else {
        dispatch(addNotification(t('Something went wrong. Try again'), 'error'));
      }
    } catch ({ data }) {
      if (!!data.length && data[0]?.msg) {
        dispatch(addNotification(t(data[0].msg ?? 'Something went wrong. Try again'), 'error'));
      } else {
        dispatch(addNotification(t('Something went wrong. Try again'), 'error'));
      }
    } finally {
      setLoading(false);
    }
  };

  const actions = formActions([
    { type: 'edit', action: editMRU, show: canWrite },
    {
      type: 'remove',
      action: deleteMRU,
      show: canRemove,
      dialogTitle: 'Delete MRU',
      dialogMessage: `${t('Are you sure you want to delete MRU')} "${MRUName}"?`,
    },
  ]);

  const isLoading = loadingRegionsData || loadingCountriesData || mruDataLoading || loading;

  return (
    <PageWrapper loading={loading}>
      <AccessCheck permissions={accessPermissions} redirectTo='/mru' />
      <Wrapper>
        {isLoading && <Spinner />}
        <Breadcrumbs aliases={[MRUName]} />
        <Title>{getTitle()}</Title>
        <Form onSubmit={handleSubmit}>
          <ExpansionPanelWrapper>
            <ExpansionPanel
              hasBottomBorder={!disabled && !preview}
              title='MRU'
              hasIcon
              iconName='location'
              actions={actions}
            >
              <FormWrapper>
                <Row justifyContent='space-between'>
                  <Column>
                    <Input
                      name='MRUName'
                      label='MRU Name'
                      onChange={handleChange}
                      errors={errors}
                      value={MRUName}
                      disabled={disabled}
                      preview={preview}
                    />
                  </Column>
                  <Column>
                    <Input
                      name='SAPID'
                      label='SAP ID'
                      onChange={handleChange}
                      errors={errors}
                      value={SAPID}
                      disabled={disabled}
                      preview={preview}
                      labelOptional
                    />
                  </Column>
                  <Column>
                    <DropdownV2
                      name='country'
                      label='Country'
                      options={countriesData.map((option) => ({
                        ...option,
                        fieldName: 'country',
                      }))}
                      onChange={handleChange}
                      errors={errors}
                      value={country}
                      disabled={disabled}
                      preview={preview}
                      labelOptional
                    />
                  </Column>
                  <Column>
                    <DropdownV2
                      name='region'
                      options={regionsData.map((option) => ({
                        ...option,
                        fieldName: 'region',
                      }))}
                      label='Province'
                      onChange={handleChange}
                      errors={errors}
                      value={region}
                      disabled={disabled}
                      preview={preview}
                    />
                  </Column>
                  <Column>
                    <Input
                      name='city'
                      label={t('City')}
                      onChange={handleChange}
                      errors={errors}
                      value={city}
                      disabled={disabled}
                      preview={preview}
                    />
                  </Column>
                  <Column col={preview ? 12 : 8}>
                    <Input
                      name='street'
                      label='Street'
                      onChange={handleChange}
                      errors={errors}
                      value={preview ? `${street || '-'} ${buildingNumber ?? ''}` : street}
                      disabled={disabled}
                      preview={preview}
                    />
                  </Column>
                  {!preview && (
                    <Column col={4}>
                      <Input
                        name='buildingNumber'
                        label='Number'
                        labelMinWidth={preview ? undefined : 50}
                        onChange={handleChange}
                        errors={errors}
                        value={buildingNumber}
                        disabled={disabled}
                        preview={preview}
                      />
                    </Column>
                  )}
                  <Column col={8}>
                    <Input
                      name='postCode'
                      label='Post Code'
                      onChange={handleChange}
                      errors={errors}
                      value={postCode}
                      disabled={disabled}
                      preview={preview}
                      placeholder='__-___'
                      mask={zipCodeRegex}
                    />
                  </Column>
                  <Column>
                    <Input
                      double
                      name={['GPSLongitude', 'GPSLatitude']}
                      label='Localization GPS'
                      onChange={handleChange}
                      value={[GPSLongitude, GPSLatitude]}
                      errors={errors}
                      disabled={disabled}
                      preview={preview}
                    />
                  </Column>
                  <Column col={12} labelMinWidth={!preview}>
                    <Checkbox
                      name='LocalizationImpel'
                      label='Localization Impel'
                      onChange={() => setLocalizationImpel(!localizationImpel)}
                      errors={errors}
                      checked={localizationImpel}
                      disabled={disabled}
                      preview={preview}
                    />
                  </Column>
                </Row>
              </FormWrapper>
            </ExpansionPanel>
            {!disabled && !preview ? (
              <ButtonWrapper>
                <Button variant='green' type='submit'>
                  {t('Save')}
                </Button>
              </ButtonWrapper>
            ) : null}
          </ExpansionPanelWrapper>
        </Form>
      </Wrapper>
    </PageWrapper>
  );
};

export default withRouter(AddEditFormMRU);
