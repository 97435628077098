import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { tError } from 'types/global';
import { dateToDashDDMMYYYYColonHHMM, dateToHHMM } from 'utils/helpers';
import { tCustomInputEvent } from 'hooks/useForm';
import { useOutsideClickAction, useRect } from 'hooks';
import { colorRedBasic } from 'styles/GlobalStyles';

import { Label, Calendar, Button, Timepicker, FormErrorMessage, Icon } from 'components';

import {
  Wrapper,
  IconStyle,
  DisabledIconStyle,
  ErrorIconStyle,
  PositioningContainer,
  InputContainer,
  DateOutputContainer,
  DatepickerContainer,
  TitleContainer,
  TitleStyle,
  MiddleContainer,
  CalendarContainer,
  TimepickerContainer,
  FooterContainer,
  ButtonContainer,
  PreviewText,
} from './DateTimepicker.styles';

export type tInputContainer = {
  selected: boolean;
  clickable: boolean;
  error: boolean;
};

export type tOffset = {
  offset?: string;
};

export type tDateTimepicker = {
  name: string;
  label?: string;
  labelMinWidth?: number;
  labelOptional?: boolean;
  title?: string;
  date?: Date | null;
  min?: Date;
  max?: Date;
  onChange?: (e: tCustomInputEvent) => void;
  disableFilter?: (val: Date) => boolean;
  disabled?: boolean;
  readOnly?: boolean;
  preview?: boolean;
  allowDelete?: boolean;
  errors?: tError[];
  noFlip?: boolean;
  flip?: boolean;
};

const noError: tError[] = [];

const DateTimepicker: React.FC<tDateTimepicker> = ({
  name,
  label = '',
  labelMinWidth = 90,
  labelOptional = false,
  title = '',
  date,
  min,
  max,
  onChange = () => {},
  disableFilter,
  disabled = false,
  readOnly = false,
  preview = false,
  allowDelete = false,
  errors = noError,
  noFlip = false,
  flip = false,
}) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState(false);
  const [dateInput, setDateInput] = useState('');
  const [selectedDateTime, setSelectedDateTime] = useState(new Date());
  const [focusDate, setFocusDate] = useState(date ?? new Date());

  const datepickerContainerRef = useRef(null);
  const inputContainerRef = useRef(null);
  const inputRect = useRect(inputContainerRef);

  const offsetValue = '-25rem';
  const offsetThreshold = 650;
  const offset = window.innerWidth - inputRect.x < offsetThreshold || flip ? offsetValue : '0';

  const hasError = errors.map((error) => error.field).includes(name);

  const resetValues = () => {
    setSelected(false);
    setDateInput('');
    setSelectedDateTime(new Date());
    setFocusDate(new Date());
  };

  useEffect(() => {
    setVisible(false);
    if (date) {
      setDateInput(dateToDashDDMMYYYYColonHHMM(date));
      setSelectedDateTime(new Date(date));
      setFocusDate(date);
      setSelected(true);
    } else {
      resetValues();
    }
  }, [date]);

  const scrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  const inputClickHandler = () => {
    if (!disabled) {
      setVisible(true);
      scrollToRef(inputContainerRef);
    }
  };

  const calendarClickHandler = (newDate) => {
    const day = newDate.getDate();
    const month = newDate.getMonth();
    const year = newDate.getFullYear();
    const hours = selectedDateTime.getHours();
    const minutes = selectedDateTime.getMinutes();

    setSelectedDateTime(new Date(year, month, day, hours, minutes));
  };

  const timeClickHandler = ({ value }) => {
    const day = selectedDateTime.getDate();
    const month = selectedDateTime.getMonth();
    const year = selectedDateTime.getFullYear();
    const hours = +value.split(':')[0];
    const minutes = +value.split(':')[1];

    setSelectedDateTime(new Date(year, month, day, hours, minutes));
  };

  const calendarFocusDateHandler = (date) => {
    setFocusDate(date);
  };

  const saveButtonHandler = () => {
    const dateTimeStr = dateToDashDDMMYYYYColonHHMM(selectedDateTime);
    const customEvent = {
      name,
      type: 'date-time',
      custom: true,
      value: selectedDateTime,
    };

    setDateInput(dateTimeStr);
    setSelected(true);
    setVisible(false);
    onChange(customEvent);
  };

  const closeButtonHandler = () => {
    setVisible(false);
  };

  const deleteDateHandler = () => {
    const customEvent = {
      name,
      type: 'date-time',
      custom: true,
      value: null,
    };
    onChange(customEvent);

    setSelected(true);
    setVisible(false);
  };

  useOutsideClickAction(() => setVisible(false), datepickerContainerRef, inputContainerRef);

  const renderIcon = (disabled: boolean, hasError: boolean) => {
    if (disabled) return <DisabledIconStyle icon='dateRange' />;
    if (hasError) return <ErrorIconStyle icon='dateRange' />;
    return <IconStyle icon='dateRange' />;
  };

  const showAllowDeleteIcon = allowDelete && !readOnly && !disabled && date;

  return (
    <Wrapper>
      {label && (
        <Label optional={labelOptional} labelMinWidth={labelMinWidth}>
          {t(label)}
        </Label>
      )}
      {preview ? (
        <PreviewText>{date ? dateToDashDDMMYYYYColonHHMM(date) : '-'}</PreviewText>
      ) : (
        <PositioningContainer>
          <InputContainer
            selected={selected && !disabled}
            error={hasError}
            clickable={!disabled}
            onClick={inputClickHandler}
            ref={inputContainerRef}
          >
            {renderIcon(disabled, hasError)}
            <DateOutputContainer>{dateInput || t('Select date')}</DateOutputContainer>
            {showAllowDeleteIcon && (
              <Icon icon='close' fill={colorRedBasic} asButton onClick={deleteDateHandler} />
            )}
            <input
              id={`${name}Date`}
              name={`${name}Date`}
              value={dateInput}
              readOnly
              type='hidden'
            />
          </InputContainer>
          {visible && (
            <DatepickerContainer offset={noFlip ? '0' : offset} ref={datepickerContainerRef}>
              <TitleContainer>
                <DisabledIconStyle icon='dateRange' />
                <TitleStyle>{readOnly ? t('Calendar') : t(title)}</TitleStyle>
              </TitleContainer>
              <MiddleContainer>
                <CalendarContainer>
                  <Calendar
                    startDate={selectedDateTime}
                    focusDate={focusDate}
                    min={min}
                    max={max}
                    setDate={calendarClickHandler}
                    setFocusDate={calendarFocusDateHandler}
                    disableFilter={disableFilter}
                    disabled={disabled}
                    readOnly={readOnly}
                  />
                  <TimepickerContainer>
                    <Timepicker
                      name={name}
                      label='hour'
                      labelMinWidth={30}
                      value={dateToHHMM(selectedDateTime)}
                      onChange={timeClickHandler}
                      disabled={disabled || readOnly}
                      errors={errors}
                      fullWidth
                    />
                  </TimepickerContainer>
                </CalendarContainer>
              </MiddleContainer>
              <FooterContainer>
                <ButtonContainer>
                  <Button variant='red' kind='outlined' onClick={closeButtonHandler}>
                    {t('Close')}
                  </Button>
                </ButtonContainer>
                {!readOnly && (
                  <ButtonContainer>
                    <Button variant='green' onClick={saveButtonHandler}>
                      {t('Save')}
                    </Button>
                  </ButtonContainer>
                )}
              </FooterContainer>
            </DatepickerContainer>
          )}
          <FormErrorMessage name={name} errors={errors} />
        </PositioningContainer>
      )}
    </Wrapper>
  );
};

export default DateTimepicker;
