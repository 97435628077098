import { FormStyle } from './Form.style';

type tForm = {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  children: (JSX.Element | string | false)[] | JSX.Element | string | false | null;
  style?: React.CSSProperties;
};

const Form: React.FC<tForm> = ({ onSubmit, children, style }): JSX.Element => {
  return (
    <FormStyle style={style} onSubmit={onSubmit}>
      {children}
    </FormStyle>
  );
};

export default Form;
