import { useTranslation } from 'react-i18next';
import { Button, ExpansionPanel, Label } from 'components';
import { tOpenedRightForm } from 'types/global';
import { Flex, FormWrapper } from 'styles/GlobalStyledComponents';

type tTreeOfSystemsAndIndustries = {
  isAddMode?: boolean;
  disabled?: boolean;
  openRightPanel: (value: tOpenedRightForm) => void;
};

const TreeOfSystemsAndIndustries: React.FC<tTreeOfSystemsAndIndustries> = ({
  isAddMode = false,
  disabled = false,
  openRightPanel,
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ExpansionPanel
      hasBottomBorder
      title='Tree of systems and industries'
      hasIcon
      iconName='accountTree'
    >
      <FormWrapper>
        <Flex alignItems='flex-start' justifyContent='flex-start'>
          <Label>{t('Tree of systems and industries')}</Label>
          <Button
            disabled={isAddMode}
            icon='addCircle'
            variant='blue'
            onClick={() =>
              openRightPanel({ form: 'TreeOfSystemsAndIndustries', id: null, disabled })
            }
          >
            {t('Open')}
          </Button>
        </Flex>
      </FormWrapper>
    </ExpansionPanel>
  );
};

export default TreeOfSystemsAndIndustries;
