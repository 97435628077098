import { useEffect, useState, useCallback } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { addNotification } from 'store/notifications/actions';
import { useForm, useFetch, useSingleFetch, useFormErrorsReset } from 'hooks';
import devicesService, { tGetLocationParams } from 'services/devices';
import { dateStringToDashYYYMMDD, getNestedErrors, assignError, catchErrors } from 'utils/helpers';
import formActions from 'utils/formActions';
import { tGetDeviceData } from 'types/services/devices';
import { tGet, tIdName, tError, tAPIFieldError, tDropdownOption } from 'types/global';
import { tCreateDeviceAPI, tUpdateDeviceAPI } from 'types/api/devices';
import tStore from 'types/store';
import {
  ExpansionPanel,
  Button,
  Form,
  Breadcrumbs,
  Row,
  Column,
  Input,
  DropdownV2,
  Datepicker,
  InputDropdown,
  InputWithList,
  Spinner,
  AccessCheck,
} from 'components';
import { Left, Right, Title, ExpansionPanelWrapper } from 'styles/GlobalStyledComponents';
import { REQUIRED_FIELDS } from 'utils/requiredFormFields';
import { validateRequiredFields } from 'utils/form';
import {
  MaintenanceOperations,
  SingleMaintenanceOperations,
  Consumables,
  SingleConsumable,
  DeviceSpecs,
  DeviceSpec,
} from './Forms';
import { ButtonWrapper, LineGap } from './AddEditFormDevices.style';
import { maintenanceIntervalOptions } from './deviceENUMs';

const transformField = (field: string): string => {
  switch (field) {
    case 'manual_url':
      return 'manual';
    case 'serial_number':
      return 'sn';
    case 'part_number':
      return 'pn';
    case 'utility_id':
      return 'uid';
    case 'project_id':
      return 'pid';
    case 'production_date':
      return 'production';
    case 'installation_date':
      return 'installation';
    case 'warranty_expiration_date':
      return 'warranty';
    case 'last_maintenance_date':
      return 'lastMaintenance';
    case 'maintenance_intervals_value':
      return 'maintenanceIntervalsInput';
    case 'maintenance_intervals_unit':
      return 'maintenanceIntervalsDropdown';
    case 'periodic_assessment':
      return 'periodicAssessment';
    case 'maintenance_operations':
      return 'maintenanceOperations';
    case 'location_id':
      return 'location';
    case 'storey_id':
      return 'storey';
    case 'area_id':
      return 'area';
    case 'room_id':
      return 'room';
    case 'industry_id':
      return 'industry';
    default:
      return field;
  }
};

const dataModel: tGetDeviceData = {
  id: null,
  manufacturer: '',
  model: '',
  manual: '',
  production: null,
  sn: '',
  pn: '',
  ean: '',
  uid: '',
  pid: '',
  rfid: '',
  qr: '',
  warranty: null,
  installation: null,
  lastMaintenance: null,
  maintenanceIntervals: null,
  maintenanceOperations: [],
  periodicAssessment: '',
  warrantyName: '',
  warrantySapId: '',
  warrantyContact: '',
  maintenanceName: '',
  maintenanceSapId: '',
  maintenanceContact: '',
  parts: [],
  consumables: [],
  specifications: [],
  location: null,
  storey: null,
  area: null,
  room: null,
  industry: null,
  components: [],
};

const labelWidth = 100;

const getInitialValues = (data: tGetDeviceData) => {
  return data && Object.keys(data).length ? data : { ...dataModel };
};

interface iAddEditFormDevices extends RouteComponentProps<any> {
  isAddMode?: boolean;
  disabled?: boolean;
  preview?: boolean;
}

const AddEditFormDevices: React.FC<iAddEditFormDevices> = ({
  isAddMode = false,
  disabled = false,
  preview = false,
  match,
  history,
}): JSX.Element => {
  const { id } = match.params;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accessPermissions = disabled || preview ? ['tunAppliancesRead'] : ['tunAppliancesWrite'];

  const permissions = useSelector((state: tStore) => state.user.permissions);
  const canWrite = preview && permissions.includes('tunAppliancesWrite');
  const canRemove = !isAddMode && permissions.includes('tunAppliancesWrite');

  const [loading, setLoading] = useState(false);
  const [forms, setForms] = useState({
    maintenanceOperations: { state: false, id: null },
    consumables: { state: false, id: null },
    devicespecs: { state: false, id: null },
  });
  const [initialValues, setInitialValues] = useState<tGetDeviceData>(dataModel);
  const [errors, setErrors] = useState<tError[]>([]);

  const {
    fetch: fetchDeviceData,
    loading: loadingDeviceData,
    data,
  } = useSingleFetch<tGetDeviceData, tGet>(devicesService.get);

  const {
    fetch: fetchIndustries,
    loading: loadingIndustries,
    data: industries,
  } = useFetch<tDropdownOption>(devicesService.getIndustries);

  const {
    fetch: fetchLocationTypes,
    loading: loadingLocationTypes,
    data: locationTypes,
  } = useFetch<tIdName>(devicesService.getLocationTypes);

  const {
    fetch: fetchLocation,
    loading: loadingLocation,
    data: locationOptions,
  } = useFetch<tDropdownOption, tGetLocationParams>(devicesService.getLocation);

  const {
    fetch: fetchStorey,
    loading: loadingStorey,
    data: storeyOptions,
  } = useFetch<tDropdownOption, tGetLocationParams>(devicesService.getLocation);

  const {
    fetch: fetchArea,
    loading: loadingArea,
    data: areaOptions,
  } = useFetch<tDropdownOption, tGetLocationParams>(devicesService.getLocation);

  const {
    fetch: fetchRoom,
    loading: loadingRoom,
    data: roomOptions,
  } = useFetch<tDropdownOption, tGetLocationParams>(devicesService.getLocation);

  const { values, setValues, handleChange, handleSubmit } = useForm({
    initialValues,
    // eslint-disable-next-line no-use-before-define
    onSubmit: (values) => onSubmit(values, isAddMode),
  });

  const [filteredErrors, setFilteredErrors] = useState<tError[]>([]);
  const { formElemErrors, resetFormError } = useFormErrorsReset(filteredErrors);

  useEffect(() => {
    setFilteredErrors(errors);
  }, [errors]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>, loc: string[] = []) => {
    if (loc.length) setFilteredErrors(resetFormError(event?.target?.name, null, loc));
    else resetFormError(event?.target?.name);
    handleChange(event);
  };

  const {
    manufacturer,
    model,
    manual,
    production,
    sn,
    pn,
    ean,
    uid,
    pid,
    rfid,
    qr,
    warranty,
    installation,
    lastMaintenance,
    maintenanceIntervals,
    maintenanceOperations,
    periodicAssessment,
    warrantyName,
    warrantySapId,
    warrantyContact,
    maintenanceName,
    maintenanceSapId,
    maintenanceContact,
    parts,
    consumables,
    specifications,
    location,
    storey,
    area,
    room,
    industry,
  } = values;

  const fetchData = useCallback(() => {
    if (id) fetchDeviceData({ id: Number(id) });
  }, [fetchDeviceData, id]);

  useEffect(() => {
    fetchData();
    setErrors([]);
  }, [fetchData, disabled, preview]);

  useEffect(() => {
    const initialValues = getInitialValues(data);
    setInitialValues(initialValues);
  }, [data]);

  useEffect(() => {
    fetchIndustries();
  }, [fetchIndustries]);

  useEffect(() => {
    fetchLocationTypes();
  }, [fetchLocationTypes]);

  useEffect(() => {
    if (locationTypes?.length) {
      const locationsUUID = locationTypes[1]?.id;
      fetchLocation({ typeId: locationsUUID });
    }
  }, [locationTypes]);

  const getStoreyOptions = () => {
    const storeyUUID = locationTypes[2]?.id;
    const locationId = location?.value;
    if (storeyUUID && locationId) {
      fetchStorey({
        typeId: storeyUUID,
        parentId: locationId,
      });
    }
  };

  const getAreaOptions = () => {
    const areaUUID = locationTypes[3]?.id;
    const storeyId = storey?.value;
    if (areaUUID && storeyId) {
      fetchArea({
        typeId: areaUUID,
        parentId: storeyId,
      });
    }
  };

  const getRoomOptions = () => {
    const roomUUID = locationTypes[4]?.id;
    const areaId = area?.value;
    if (roomUUID && areaId) {
      fetchRoom({
        typeId: roomUUID,
        parentId: areaId,
      });
    }
  };

  useEffect(() => {
    setValues({ ...values, storey: null, area: null, room: null });
    getStoreyOptions();
  }, [location]);

  useEffect(() => {
    setValues({ ...values, area: null, room: null });
    getAreaOptions();
  }, [storey]);

  useEffect(() => {
    setValues({ ...values, room: null });
    getRoomOptions();
  }, [area]);

  useEffect(() => {
    setValues({ ...values });
  }, [room]);

  const setNotificationHandler = (message: string, type = 'error' as 'error' | 'success') => {
    const enableTimeout = type === 'success';
    dispatch(addNotification(t(message), type, enableTimeout));
  };

  const setResponseErrors = (setter: Function, errors: any) => {
    if (Array.isArray(errors) && !!errors.length) {
      setter(
        errors.map((error: tAPIFieldError) => {
          const { key, msg, loc = [] } = error;

          return {
            field: transformField(key),
            error: msg,
            loc,
          };
        }),
      );
    }

    if (errors?.includes('502 Bad Gateway')) {
      setNotificationHandler('Something went wrong. Try again');
    }
  };

  const createUpdateDevice = async (values: tGetDeviceData, isAddMode: boolean) => {
    try {
      setLoading(true);

      const body: tCreateDeviceAPI | tUpdateDeviceAPI = {
        manufacturer,
        model,
        manual_url: manual,
        serial_number: sn,
        part_number: pn,
        ean,
        utility_id: uid,
        project_id: pid,
        rfid,
        qr,
        production_date: dateStringToDashYYYMMDD(production?.toISOString()),
        installation_date: installation?.toISOString() ?? null,
        warranty_expiration_date: dateStringToDashYYYMMDD(warranty?.toISOString()),
        last_maintenance_date: lastMaintenance?.toISOString() ?? null,
        maintenance_intervals_value: maintenanceIntervals?.value || null,
        maintenance_intervals_unit: maintenanceIntervals?.optionLabel || null,
        periodic_assessment: periodicAssessment,
        warranty_company: {
          name: warrantyName ?? '',
          sap_id: warrantySapId ?? '',
          contact: warrantyContact,
        },
        maintenance_company: {
          name: maintenanceName ?? '',
          sap_id: maintenanceSapId ?? '',
          contact: maintenanceContact,
        },
        maintenance_operations:
          maintenanceOperations?.map((operation) => ({
            name: operation.name,
            value: operation.value,
            unit: operation.unit,
            type: operation.type,
          })) ?? [],
        consumables:
          consumables?.map((consumable) => ({
            name: consumable.name,
            quantity: consumable.quantity,
            unit: consumable.unit,
            ean: consumable.ean,
            manufacturer: consumable.manufacturer,
          })) ?? [],
        parts: parts ? parts?.map((part) => ({ name: part })) : [],
        specifications:
          specifications?.map((spec) => ({
            name: spec.name,
            value: spec.value,
            unit_id: spec.unit.value,
          })) ?? [],
        components: [],
        location_id: location?.value ? location.value : null,
        storey_id: storey?.value ? storey.value : null,
        area_id: area?.value ? area.value : null,
        room_id: room?.value ? room.value : null,
        industry_id: industry?.value ? industry.value : null,
      };

      const requiredFields = REQUIRED_FIELDS.DEVICES_FORM;
      const validationErrors = validateRequiredFields(
        { ...body, warrantyName, maintenanceName },
        requiredFields,
      );
      if (validationErrors.length) return setResponseErrors(setErrors, validationErrors);

      const result = isAddMode
        ? await devicesService.create(body)
        : await devicesService.update(body, id);

      const { state } = result;

      if (!state) {
        const { errors: responseErrors } = result;
        return setResponseErrors(setErrors, responseErrors);
      }
      const message = isAddMode ? 'Device was added' : 'Device was updated';
      setNotificationHandler(message, 'success');
      setErrors([]);

      // eslint-disable-next-line dot-notation
      const deviceId = result?.data['id'];
      if (deviceId) {
        history.push(`/devices/${deviceId}/edit`);
      }
    } catch (error) {
      const setters = { setNotificationHandler, setResponseErrors, setErrors };
      catchErrors(error, setters);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = (values: tGetDeviceData, isAddMode: boolean) =>
    createUpdateDevice(values, isAddMode);

  const getTitle = () => {
    if (isAddMode) return t('New device or facility');
    if (disabled || preview) return t('Device or facility');
    return t('Edit device or facility');
  };

  const toggleForms = (name: string, action: string, id: number | null) => {
    const formsHelper = { ...forms };
    Object.keys(formsHelper).forEach((formName) => {
      if (name === formName) {
        formsHelper[formName] = { state: action === 'open', id };
        return;
      }
      formsHelper[formName] = { state: false, id: null };
    });
    setForms(formsHelper);
  };

  const editDevice = () => history.push(`/devices/${id}/edit`);

  const deleteDevice = async () => {
    try {
      setLoading(true);
      const { status, error } = await devicesService.remove(id);
      if (status === 204) {
        dispatch(addNotification(t('Device was removed'), 'success'));
        history.push(`/devices`);
      } else {
        dispatch(addNotification(t(error ?? 'Something went wrong. Try again'), 'error'));
      }
    } catch (error) {
      dispatch(
        addNotification(error ?? error?.msg ?? t('Something went wrong. Try again'), 'error'),
      );
    } finally {
      setLoading(false);
    }
  };

  const actions = formActions([
    { type: 'edit', action: editDevice, show: canWrite },
    {
      type: 'remove',
      action: deleteDevice,
      show: canRemove,
      dialogTitle: 'Delete device',
      dialogMessage: `${t('Are you sure you want to delete device')} ${manufacturer} ${model}?`,
    },
  ]);

  const isLoading =
    loading ||
    loadingDeviceData ||
    loadingIndustries ||
    loadingLocationTypes ||
    loadingLocation ||
    loadingStorey ||
    loadingArea ||
    loadingRoom;

  return (
    <>
      <AccessCheck permissions={accessPermissions} redirectTo='/devices' />
      <Breadcrumbs aliases={manufacturer || model ? [`${manufacturer} ${model}`] : []} />
      <Title>{getTitle()}</Title>
      {isLoading && <Spinner />}
      <div style={{ display: 'flex', position: 'relative' }}>
        <Left>
          <Form onSubmit={handleSubmit}>
            <ExpansionPanelWrapper>
              <ExpansionPanel
                hasBottomBorder
                title={t('Device or facility data')}
                hasIcon
                iconName='construction'
                actions={actions}
              >
                <Row justifyContent='space-between'>
                  <Column col={11}>
                    <Input
                      name='manufacturer'
                      label='Manufacturer'
                      onChange={handleChange}
                      errors={errors}
                      value={manufacturer}
                      disabled={disabled}
                      preview={preview}
                      labelMinWidth={labelWidth}
                    />
                  </Column>
                  <Column col={11}>
                    <Input
                      name='model'
                      label='Model'
                      onChange={handleChange}
                      errors={errors}
                      value={model}
                      disabled={disabled}
                      preview={preview}
                      labelMinWidth={labelWidth}
                    />
                  </Column>
                  <Column col={11}>
                    <Input
                      name='manual'
                      label='Manual link'
                      onChange={handleChange}
                      errors={errors}
                      value={manual}
                      disabled={disabled}
                      preview={preview}
                      labelMinWidth={labelWidth}
                      labelOptional
                    />
                  </Column>
                  <Column col={11}>
                    <Datepicker
                      name='production'
                      label='Production date'
                      title='Production date'
                      onChange={handleChange}
                      errors={errors}
                      start={production}
                      disabled={disabled}
                      readOnly={preview}
                      hideIcon={preview}
                      labelMinWidth={labelWidth}
                      labelOptional
                    />
                  </Column>
                  <Column col={11}>
                    <Input
                      name='sn'
                      label='Serial number'
                      onChange={handleChange}
                      errors={errors}
                      value={sn}
                      disabled={disabled}
                      preview={preview}
                      labelMinWidth={labelWidth}
                    />
                  </Column>
                  <Column col={11}>
                    <Input
                      name='pn'
                      label='Part number'
                      onChange={handleChange}
                      errors={errors}
                      value={pn}
                      disabled={disabled}
                      preview={preview}
                      labelMinWidth={labelWidth}
                      labelOptional
                    />
                  </Column>
                  <Column col={11}>
                    <Input
                      name='ean'
                      label='EAN'
                      onChange={handleChange}
                      errors={errors}
                      value={ean}
                      disabled={disabled}
                      preview={preview}
                      labelMinWidth={labelWidth}
                      labelOptional
                    />
                  </Column>
                  <Column col={11}>
                    <Input
                      name='uid'
                      label='Utility ID'
                      onChange={handleChange}
                      errors={errors}
                      value={uid}
                      disabled={disabled}
                      preview={preview}
                      labelMinWidth={labelWidth}
                      labelOptional
                    />
                  </Column>
                  <Column col={11}>
                    <Input
                      name='pid'
                      label='Project ID'
                      onChange={handleChange}
                      errors={errors}
                      value={pid}
                      disabled={disabled}
                      preview={preview}
                      labelMinWidth={labelWidth}
                      labelOptional
                    />
                  </Column>
                  <Column col={11}>
                    <Input
                      name='rfid'
                      label='RFID'
                      onChange={handleChange}
                      errors={errors}
                      value={rfid}
                      disabled={disabled}
                      preview={preview}
                      labelMinWidth={labelWidth}
                      labelOptional
                    />
                  </Column>
                  <Column col={11}>
                    <Input
                      name='qr'
                      label='QR'
                      onChange={handleChange}
                      errors={errors}
                      value={qr}
                      disabled={disabled}
                      preview={preview}
                      labelMinWidth={labelWidth}
                      labelOptional
                    />
                  </Column>
                  <Column col={11}>
                    <Datepicker
                      name='warranty'
                      label='Warranty expiry date'
                      title='Warranty expiry date'
                      onChange={handleChange}
                      errors={errors}
                      start={warranty}
                      disabled={disabled}
                      readOnly={preview}
                      hideIcon={preview}
                      labelMinWidth={labelWidth}
                    />
                  </Column>
                  <Column col={11}>
                    <Datepicker
                      name='installation'
                      label='Installation date'
                      title='Installation date'
                      onChange={handleChange}
                      errors={errors}
                      start={installation}
                      disabled={disabled}
                      readOnly={preview}
                      hideIcon={preview}
                      labelMinWidth={labelWidth}
                      labelOptional
                    />
                  </Column>
                  <Column col={11}>
                    <Datepicker
                      name='lastMaintenance'
                      label='Last maintenance date'
                      title='Last maintenance date'
                      onChange={handleChange}
                      errors={errors}
                      start={lastMaintenance}
                      disabled={disabled}
                      readOnly={preview}
                      hideIcon={preview}
                      labelMinWidth={labelWidth}
                      labelOptional
                    />
                  </Column>
                  <Column col={11}>
                    <InputDropdown
                      name='maintenanceIntervals'
                      label='Maintenance intervals'
                      dropdownOptions={maintenanceIntervalOptions.map(({ value, label }) => ({
                        value,
                        label: t(label, { count: +maintenanceIntervals?.value }),
                      }))}
                      values={maintenanceIntervals}
                      errors={errors}
                      onChange={handleChange}
                      disabled={disabled}
                      preview={preview}
                      labelMinWidth={labelWidth}
                      labelOptional
                    />
                  </Column>
                </Row>
              </ExpansionPanel>
              <div style={{ marginTop: '1rem' }} />
              <ExpansionPanel
                hasBottomBorder
                title={t('Contractor providing warranty services')}
                hasIcon
                iconName='businessCenter'
                shouldExpanded={false}
              >
                <Row justifyContent='space-between'>
                  <Column col={11}>
                    <Input
                      name='warrantyName'
                      label='Name'
                      onChange={(e) => onChange(e, ['warranty_company', 'name'])}
                      errors={assignError(
                        getNestedErrors(formElemErrors, ['warranty_company', 'name']),
                        'warrantyName',
                      )}
                      value={warrantyName}
                      disabled={disabled}
                      preview={preview}
                      labelMinWidth={labelWidth}
                    />
                  </Column>
                  <Column col={11}>
                    <Input
                      name='warrantySapId'
                      label='SAP ID'
                      onChange={(e) => onChange(e, ['warranty_company', 'sap_id'])}
                      errors={assignError(
                        getNestedErrors(formElemErrors, ['warranty_company', 'sap_id']),
                        'warrantySapId',
                      )}
                      value={warrantySapId}
                      disabled={disabled}
                      preview={preview}
                      labelMinWidth={labelWidth}
                      labelOptional
                    />
                  </Column>
                  <Column col={11}>
                    <Input
                      name='warrantyContact'
                      label='Contact'
                      onChange={(e) => onChange(e, ['warranty_company', 'contact'])}
                      errors={assignError(
                        getNestedErrors(formElemErrors, ['warranty_company', 'contact']),
                        'warrantyContact',
                      )}
                      value={warrantyContact}
                      disabled={disabled}
                      preview={preview}
                      labelMinWidth={labelWidth}
                      labelOptional
                    />
                  </Column>
                </Row>
              </ExpansionPanel>
              <div style={{ marginTop: '1rem' }} />
              <ExpansionPanel
                hasBottomBorder
                title={t('Contractor providing maintenance')}
                hasIcon
                iconName='engineering'
                shouldExpanded={false}
              >
                <Row justifyContent='space-between'>
                  <Column col={11}>
                    <Input
                      name='maintenanceName'
                      label='Name'
                      onChange={(e) => onChange(e, ['maintenance_company', 'name'])}
                      errors={assignError(
                        getNestedErrors(formElemErrors, ['maintenance_company', 'name']),
                        'maintenanceName',
                      )}
                      value={maintenanceName}
                      disabled={disabled}
                      preview={preview}
                      labelMinWidth={labelWidth}
                    />
                  </Column>
                  <Column col={11}>
                    <Input
                      name='maintenanceSapId'
                      label='SAP ID'
                      onChange={(e) => onChange(e, ['warranty_company', 'contact'])}
                      errors={assignError(
                        getNestedErrors(formElemErrors, ['warranty_company', 'contact']),
                        'maintenanceSapId',
                      )}
                      value={maintenanceSapId}
                      disabled={disabled}
                      preview={preview}
                      labelMinWidth={labelWidth}
                      labelOptional
                    />
                  </Column>
                  <Column col={11}>
                    <Input
                      name='maintenanceContact'
                      label='Contact'
                      onChange={(e) => onChange(e, ['maintenance_company', 'contact'])}
                      errors={assignError(
                        getNestedErrors(formElemErrors, ['maintenance_company', 'contact']),
                        'maintenanceContact',
                      )}
                      value={maintenanceContact}
                      disabled={disabled}
                      preview={preview}
                      labelMinWidth={labelWidth}
                      labelOptional
                    />
                  </Column>
                  <Column>
                    <LineGap />
                  </Column>
                  <Column col={11}>
                    <Input
                      name='periodicAssessment'
                      label='Periodic assessment'
                      onChange={handleChange}
                      errors={errors}
                      value={periodicAssessment}
                      disabled={disabled}
                      preview={preview}
                      labelMinWidth={labelWidth}
                      labelOptional
                    />
                  </Column>
                </Row>
              </ExpansionPanel>
              <div style={{ marginTop: '1rem' }} />
              <ExpansionPanel
                hasBottomBorder
                title={t('Maintenance operations')}
                hasIcon
                iconName='miscellaneousServices'
              >
                <MaintenanceOperations
                  openForm={(operationId: number | null) =>
                    toggleForms('maintenanceOperations', 'open', operationId)
                  }
                  disabled={disabled}
                  preview={preview}
                  data={maintenanceOperations ?? []}
                  onChange={handleChange}
                  errors={getNestedErrors(errors, 'maintenance_operations')}
                />
              </ExpansionPanel>

              <div style={{ marginTop: '1rem' }} />
              <ExpansionPanel
                hasBottomBorder
                title={t('Consumables')}
                hasIcon
                iconName='developerBoard'
              >
                <Consumables
                  openForm={(consumableId: number | null) =>
                    toggleForms('consumables', 'open', consumableId)
                  }
                  disabled={disabled}
                  preview={preview}
                  data={consumables ?? []}
                  onChange={handleChange}
                  errors={getNestedErrors(errors, 'consumables')}
                />
              </ExpansionPanel>
              <div style={{ marginTop: '1rem' }} />
              <ExpansionPanel
                hasBottomBorder
                title={t('Parts and components')}
                hasIcon
                iconName='memory'
              >
                <Row>
                  <Column col={11}>
                    <InputWithList
                      disabled={disabled}
                      preview={preview}
                      errors={[]}
                      label='Name'
                      labelMinWidth={labelWidth}
                      name='parts'
                      handleChange={handleChange}
                      items={parts}
                      labelOptional
                    />
                  </Column>
                </Row>
              </ExpansionPanel>
              <div style={{ marginTop: '1rem' }} />
              <ExpansionPanel
                hasBottomBorder
                title={t('Device spec')}
                hasIcon
                iconName='settingsApplications'
              >
                <DeviceSpecs
                  openForm={(deviceId: number | null) =>
                    toggleForms('devicespecs', 'open', deviceId)
                  }
                  disabled={disabled}
                  preview={preview}
                  data={specifications ?? []}
                  onChange={handleChange}
                  errors={getNestedErrors(errors, 'specifications')}
                />
              </ExpansionPanel>
              <div style={{ marginTop: '1rem' }} />
              <ExpansionPanel
                hasBottomBorder
                title={t('Device location')}
                hasIcon
                iconName='location'
              >
                <Row justifyContent='space-between'>
                  <Column col={11}>
                    <DropdownV2
                      name='location'
                      label='Location'
                      value={location}
                      options={
                        locationOptions?.map((el) => ({ ...el, fieldName: 'location' })) ?? []
                      }
                      onChange={handleChange}
                      errors={errors}
                      disabled={disabled}
                      preview={preview}
                    />
                  </Column>
                  <Column col={11}>
                    <DropdownV2
                      name='storey'
                      label='Storey'
                      value={storey}
                      options={storeyOptions?.map((el) => ({ ...el, fieldName: 'storey' })) ?? []}
                      onChange={handleChange}
                      errors={errors}
                      disabled={location === null || disabled}
                      preview={preview}
                      labelOptional
                    />
                  </Column>
                  <Column col={11}>
                    <DropdownV2
                      name='area'
                      label='Area'
                      value={area}
                      options={areaOptions?.map((el) => ({ ...el, fieldName: 'area' })) ?? []}
                      onChange={handleChange}
                      errors={errors}
                      disabled={storey === null || disabled}
                      preview={preview}
                      labelOptional
                    />
                  </Column>
                  <Column col={11}>
                    <DropdownV2
                      name='room'
                      label='Room'
                      value={room}
                      options={roomOptions?.map((el) => ({ ...el, fieldName: 'room' })) ?? []}
                      onChange={handleChange}
                      errors={errors}
                      disabled={area === null || disabled}
                      preview={preview}
                      labelOptional
                    />
                  </Column>
                </Row>
              </ExpansionPanel>
              <div style={{ marginTop: '1rem' }} />
              <ExpansionPanel
                hasBottomBorder={!disabled && !preview}
                title={t('Industries and systems')}
                hasIcon
                iconName='widgets'
              >
                <Row justifyContent='space-between'>
                  <Column col={11}>
                    <DropdownV2
                      name='industry'
                      label='Industry'
                      value={industry}
                      options={industries?.map((el) => ({ ...el, fieldName: 'industry' })) ?? []}
                      onChange={handleChange}
                      errors={errors}
                      disabled={disabled}
                      preview={preview}
                    />
                  </Column>
                </Row>
              </ExpansionPanel>
              {!disabled && !preview && (
                <ButtonWrapper>
                  <Button variant='green' type='submit'>
                    {t('Save')}
                  </Button>
                </ButtonWrapper>
              )}
            </ExpansionPanelWrapper>
          </Form>
        </Left>
        <Right>
          {forms.maintenanceOperations.state && (
            <SingleMaintenanceOperations
              maintenanceOperationId={forms.maintenanceOperations.id}
              data={maintenanceOperations.map((el) => ({
                ...el,
                unit: !!el?.unit
                  ? { value: el.unit, label: t(el.unit, { count: +el.value }) }
                  : null,
                type: !!el?.type ? { value: el.type, label: t(el.type) } : null,
              }))}
              setData={handleChange}
              closeForm={() => toggleForms('maintenanceOperations', 'close', null)}
              disabled={disabled}
              preview={preview}
              errors={getNestedErrors(errors, 'maintenance_operations')}
            />
          )}
          {forms.consumables.state && (
            <SingleConsumable
              consumableId={forms.consumables.id}
              data={consumables}
              setData={handleChange}
              closeForm={() => toggleForms('consumables', 'close', null)}
              disabled={disabled}
              preview={preview}
              errors={getNestedErrors(errors, 'consumables')}
            />
          )}
          {forms.devicespecs.state && (
            <DeviceSpec
              devicespecId={forms.devicespecs.id}
              data={specifications}
              setData={handleChange}
              closeForm={() => toggleForms('devicespecs', 'close', null)}
              disabled={disabled}
              preview={preview}
              errors={getNestedErrors(errors, 'specifications')}
            />
          )}
        </Right>
      </div>
    </>
  );
};

export default withRouter(AddEditFormDevices);
