import { useEffect } from 'react';
import { useFetch } from 'hooks';
import { Row, Column, DropdownV2 } from 'components';
import { tOption, tDropdownOption, tError } from 'types/global';
import subcontractorsService from 'services/subcontractors';
import { useSelector } from 'react-redux';
import tStore from 'types/store';

export type tTechnicianSelect = {
  technician: tDropdownOption | null;
  setTechnician: (data: tDropdownOption | null) => void;
  setLoading: (loading: boolean) => void;
  errors: tError[];
};

export const SubcontractorTechnicianSelect: React.FC<tTechnicianSelect> = ({
  technician,
  setTechnician,
  setLoading,
  errors,
}): JSX.Element => {
  const {
    fetch: fetchSubcontractorTechnicians,
    loading: loadingSubcontractorsTechnicians,
    data: subcontractorsTechniciansOptions,
  } = useFetch<tOption>(
    subcontractorsService.getSubcontractorTechnicians,
    'An error occurred while getting subcontractors',
  );
  const userCompanyId = useSelector((state: tStore) => state.user?.company?.id);
  useEffect(() => {
    fetchSubcontractorTechnicians({ userCompanyId });
  }, [fetchSubcontractorTechnicians]);

  useEffect(() => {
    setLoading(loadingSubcontractorsTechnicians);
  }, [loadingSubcontractorsTechnicians]);

  const setSubcontractorTechnicianHandler = ({ value, label }) => setTechnician({ value, label });

  return (
    <Row justifyContent='space-between'>
      <Column>
        <DropdownV2
          name='subcontractorTechnician'
          label='Service technician'
          labelMinWidth={100}
          options={
            subcontractorsTechniciansOptions?.map((el) => ({
              ...el,
              fieldName: 'subcontractorTechnician',
            })) ?? []
          }
          onChange={setSubcontractorTechnicianHandler}
          errors={errors}
          value={technician}
        />
      </Column>
    </Row>
  );
};

export default SubcontractorTechnicianSelect;
