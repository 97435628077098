import { tError } from 'types/global';
import { ErrorMessage, Wrapper } from './FormErrorMessage.style';

export type tFormErrorMessage = {
  name: string;
  errors: tError[];
  labelMinWidth?: number;
};

const FormErrorMessage: React.FC<tFormErrorMessage> = ({
  name,
  errors,
  labelMinWidth = 0,
}): JSX.Element | null => {
  const filteredErrors = errors.find((elem) => elem.field === name);
  if (!filteredErrors) return null;

  if (labelMinWidth > 0) {
    return (
      <Wrapper labelMinWidth={labelMinWidth}>
        <ErrorMessage>{filteredErrors.error}</ErrorMessage>
      </Wrapper>
    );
  }
  return <ErrorMessage>{filteredErrors.error}</ErrorMessage>;
};

export default FormErrorMessage;
