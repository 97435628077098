import { useTranslation } from 'react-i18next';
import { useForm } from 'hooks';
import { newUID, errorsLocFilter } from 'utils/helpers';
import { ExpansionPanel, Button, Form, Input, Row, Column, DropdownV2 } from 'components';
import { tCurrentMaintenanceOperation } from 'types/services/devices';
import { tError } from 'types/global';
import { ExpansionPanelWrapper, FormWrapper, Flex } from 'styles/GlobalStyledComponents';
import { ButtonWrapper } from '../AddEditFormDevices.style';
import { timeUnitOptions, maintenanceOperationTypeOptions } from '../deviceENUMs';

const dataModel: tCurrentMaintenanceOperation = {
  id: 0,
  name: '',
  value: 0,
  unit: null,
  type: null,
};

type tSingleMaintenanceOperations = {
  disabled?: boolean;
  preview?: boolean;
  data: tCurrentMaintenanceOperation[];
  setData: (data: any) => void;
  closeForm: () => void;
  maintenanceOperationId: number | null;
  errors: tError[];
};

const SingleMaintenanceOperations: React.FC<tSingleMaintenanceOperations> = ({
  disabled = false,
  preview = false,
  maintenanceOperationId = null,
  data = [],
  setData,
  closeForm = () => {},
  errors = [],
}): JSX.Element => {
  const { t } = useTranslation();

  const current = data?.find((el) => el?.id === maintenanceOperationId) ?? dataModel;
  const currentIndex = data.indexOf(current);
  const itemErrors = errorsLocFilter(errors, currentIndex ?? '');

  const { values, handleChange, handleSubmit } = useForm({
    initialValues: current,
    // eslint-disable-next-line no-use-before-define
    onSubmit: () => onSubmit(!!!maintenanceOperationId),
  });
  const { name, value, unit, type } = values;

  const onSubmit = (isAddMode: boolean) => {
    let newOperation: tCurrentMaintenanceOperation[] = [];
    if (isAddMode) {
      newOperation = [
        ...data.map((el) => ({
          ...el,
          unit: el?.unit?.value ?? null,
          type: el?.type?.value ?? null,
        })),
        {
          id: newUID(data),
          name,
          value: +value,
          unit: unit?.value ?? null,
          type: type?.value ?? null,
        },
      ];
    } else {
      newOperation = data.map((el) =>
        el?.id !== maintenanceOperationId
          ? {
              ...el,
              unit: el?.unit?.value ?? null,
              type: el?.type?.value ?? null,
            }
          : {
              id: el?.id,
              name,
              value: +value,
              unit: unit?.value ?? null,
              type: type?.value ?? null,
            },
      );
    }

    setData({ fieldName: 'maintenanceOperations', label: newOperation });
    closeForm();
  };

  const saveButtonEnabled = !!name.replace(/\s/g, '') && !!value && !!unit?.value && !!type;

  return (
    <Form onSubmit={handleSubmit}>
      <ExpansionPanelWrapper>
        <ExpansionPanel
          hasBottomBorder
          title={t('Maintenance operations')}
          hasIcon
          iconName='miscellaneousServices'
        >
          <FormWrapper>
            <Row justifyContent='space-between'>
              <Column>
                <Input
                  name='name'
                  label='Permission name'
                  onChange={handleChange}
                  errors={itemErrors}
                  value={name}
                  disabled={disabled}
                  preview={preview}
                />
              </Column>
              <Column col={preview ? 12 : 6}>
                <Input
                  name='value'
                  label='Value'
                  onChange={handleChange}
                  errors={itemErrors}
                  value={
                    preview ? `${value || '-'} ${t(unit?.value ?? '', { count: +value })}` : value
                  }
                  disabled={disabled}
                  preview={preview}
                />
              </Column>
              {!preview && (
                <Column col={6}>
                  <DropdownV2
                    name='unit'
                    label='Unit'
                    options={timeUnitOptions.map(({ value: unitValue, label }) => ({
                      value: unitValue,
                      label: t(label, { count: +value }),
                      fieldName: 'unit',
                    }))}
                    value={
                      !!unit?.value ? { ...unit, label: t(unit.value, { count: +value }) } : null
                    }
                    onChange={handleChange}
                    errors={itemErrors}
                    disabled={disabled}
                    preview={preview}
                  />
                </Column>
              )}
              <Column>
                <DropdownV2
                  name='type'
                  label='Type'
                  options={maintenanceOperationTypeOptions.map(({ value, label }) => ({
                    value,
                    label: t(label),
                    fieldName: 'type',
                  }))}
                  value={type}
                  onChange={handleChange}
                  errors={itemErrors}
                  disabled={disabled}
                  preview={preview}
                />
              </Column>
            </Row>
          </FormWrapper>
        </ExpansionPanel>
        <ButtonWrapper>
          <Button onClick={closeForm} variant='red' kind='outlined'>
            {t('Close')}
          </Button>
          {!disabled && !preview && (
            <>
              <Flex marginRight='1rem' />
              <Button variant='green' type='submit' disabled={!saveButtonEnabled}>
                {t('Save')}
              </Button>
            </>
          )}
        </ButtonWrapper>
      </ExpansionPanelWrapper>
    </Form>
  );
};

export default SingleMaintenanceOperations;
