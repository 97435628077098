/* eslint-disable camelcase */
import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addNotification } from 'store/notifications/actions';
import { withRouter, RouteComponentProps } from 'react-router';
import { useForm, useSingleFetch } from 'hooks';
import itsStructureService from 'services/itsStructure';
import { tAddress, tITSUserLocation } from 'types/services/itsStructure';
import { tOption, tGet, tError, tKeyString } from 'types/global';
import { REQUIRED_FIELDS } from 'utils/requiredFormFields';
import { validateRequiredFields } from 'utils/form';
import { transformErrorsFromApi, catchErrors } from 'utils/helpers';
import { ExpansionPanel, Button, Form, Column, DropdownV2, Spinner } from 'components';
import { ExpansionPanelWrapper, FormWrapper } from 'styles/GlobalStyledComponents';
import { ButtonWrapper } from '../AddEditFormItsStructure.style';

interface iLocationForm extends RouteComponentProps<any> {
  isAddMode?: boolean;
  disabled?: boolean;
  preview?: boolean;
}

const dataModel = {
  workPlace: null,
};

type tDataModel = {
  workPlace: tOption | null;
};

const getInitialValues = (data: tITSUserLocation) => {
  return !!data?.workplaces?.length
    ? {
        workPlace: !!data?.workplaces[0]?.id
          ? { value: data.workplaces[0].id, label: data.workplaces[0].name }
          : null,
      }
    : dataModel;
};

const LocationForm: React.FC<iLocationForm> = ({
  isAddMode = false,
  disabled = false,
  preview = false,
  match,
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const { id } = match.params;
  const [initialValues, setInitialValues] = useState<tDataModel>(dataModel);
  const [addresses, setAddresses] = useState<tAddress[]>([]);

  const {
    fetch: setParamsHandler,
    loading: loadingData,
    data,
  } = useSingleFetch<tITSUserLocation, tGet>(
    itsStructureService.getItsUserLocation,
    'An error occurred while getting segment data',
  );
  const [errors, setErrors] = useState<tError[]>([]);

  const setNotificationHandler = (message: string, type = 'error' as 'error' | 'success') => {
    const enableTimeout = type === 'success';
    dispatch(addNotification(t(message), type, enableTimeout));
  };

  const fetchData = useCallback(() => {
    if (!!id) setParamsHandler({ id });
  }, [setParamsHandler, id]);

  useEffect(() => {
    if (id !== null || !isAddMode) fetchData();
    setErrors([]);
  }, [fetchData, id, preview, disabled]);

  useEffect(() => {
    const initialValues = getInitialValues(data);
    setInitialValues(initialValues);
  }, [data]);

  useEffect(() => {
    const get = async () => {
      try {
        setLoading(true);
        const result = await itsStructureService.getAllItsLocations();
        const {
          data: { results: addresses },
        } = result;
        setAddresses(addresses ?? []);
      } catch (error) {
        setNotificationHandler(error?.message ?? t('An error occurred while getting addresses'));
      } finally {
        setLoading(false);
      }
    };
    get();
  }, []);

  const setResponseErrors = (setter: Function, errors: tKeyString[]) => {
    const transformField = (field: string): string => {
      switch (field) {
        case 'workplaces_ids':
          return `workPlace`;
        default:
          return field;
      }
    };

    setter(
      errors
        ? errors?.map((error: tKeyString) => {
            const { key, msg } = error;
            return {
              field: transformField(key),
              error: msg,
            };
          })
        : [],
    );
  };

  const onSubmit = async (values: { values: { workPlace: tOption } }) => {
    try {
      setLoading(true);
      const {
        values: { workPlace },
      } = values;
      setErrors([]);
      const locations = !!workPlace ? [workPlace.value] : [];

      const requiredFields = REQUIRED_FIELDS.ITS_STRUCTURE_FORM.LOCATION;
      const validationErrors = validateRequiredFields(
        { workPlace: !!workPlace?.value ? workPlace?.value : null },
        requiredFields,
      );
      if (validationErrors.length) return setErrors(transformErrorsFromApi(validationErrors));

      const result = await itsStructureService.putItsUserLocation(id, locations);
      if (result) {
        dispatch(addNotification(t('User location was updated'), 'success'));
        fetchData();
      }
    } catch (error) {
      const setters = { setNotificationHandler, setResponseErrors, setErrors };
      catchErrors(error, setters);
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit } = useForm({
    initialValues,
    onSubmit: (values) => onSubmit(values),
  });
  const { workPlace } = values;

  const isLoading = loading || loadingData;

  return (
    <ExpansionPanelWrapper>
      <ExpansionPanel
        hasBottomBorder={!disabled && !preview}
        title={t('Location')}
        hasIcon
        iconName='location'
      >
        <FormWrapper>
          <Form onSubmit={handleSubmit}>
            <>
              {isLoading && <Spinner />}
              <Column>
                <DropdownV2
                  options={addresses.map((option: tAddress) => ({
                    value: option.id,
                    label: option.name,
                    fieldName: 'workPlace',
                  }))}
                  name='workPlace'
                  label='Work place'
                  onChange={handleChange}
                  errors={errors}
                  value={workPlace}
                  disabled={isAddMode || disabled}
                  preview={preview}
                />
              </Column>
              {!disabled && !preview && (
                <ButtonWrapper>
                  <Button variant='green' type='submit' disabled={isAddMode}>
                    {t('Save')}
                  </Button>
                </ButtonWrapper>
              )}
            </>
          </Form>
        </FormWrapper>
      </ExpansionPanel>
    </ExpansionPanelWrapper>
  );
};

export default withRouter(LocationForm);
