import styled from 'styled-components';
import { ClearButton } from 'styles/GlobalStyledComponents';

export const Wrapper = styled.div`
  position: relative;
  z-index: 2;
`;

export const Container = styled.div`
  position: absolute;
  top: 2.5rem;
  left: -15px;
  background: #fff;
  padding: 1rem;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: var(--theme-borderRadius-sm);
  min-width: 12rem;
  &:after {
    bottom: 100%;
    left: 15%;
    border: 5px solid #fff;
    border-color: transparent transparent #fff #fff;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(0, 0, 0, 0);
    border-bottom-color: #fff;
    border-width: 10px;
    margin-left: -15px;
    transform-origin: 0 0;
    transform: rotate(0);
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
export const CloseButton = styled(ClearButton)`
  font-weight: bold;
  color: #ff3e4a;
  margin-right: 1rem;
`;

export const Title = styled.span`
  margin-left: 0.75rem;
  margin-bottom: 0.75rem;
  font-weight: normal;
  white-space: nowrap;
`;

export const FilterHeader = styled.p`
  font-size: 0.875rem;
  font-weight: 900;
  margin-top: 0;
`;
