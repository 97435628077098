import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  ExpansionPanel,
  Input,
  Row,
  Column,
  DropdownV2,
  DropdownWithList,
  InputDropdown,
  DiscardAndAcceptButtons,
  InputWithList,
  Spinner,
} from 'components';
import { addNotification } from 'store/notifications/actions';
import { useForm, useFetch, useSingleFetch, useApplianceTree } from 'hooks';
import { catchErrors } from 'utils/helpers';
import { GapLine } from 'styles/GlobalStyledComponents';
import paService from 'services/contractsPeriodicActivities';
import { LineGap } from 'views/DevicesAndFacilities/AddEditFormDevices.style';
import { tPeriodicActivityValues } from 'types/views/contracts';
import { tPeriodicActivityCreate } from 'types/services/contracts';
import { tOption, tGet, tError, tDropdownOption } from 'types/global';
import { tListOption } from 'components/DropdownWithList/DropdownWithList';
import { validateRequiredFields } from 'utils/form';
import { REQUIRED_FIELDS } from 'utils/requiredFormFields';
import { ExpansionPanelWrapper } from '../../AddEditFormContracts.style';

const dataModel: tPeriodicActivityValues = {
  name: '',
  type: null,
  applianceIndustry: null,
  system: null,
  group: null,
  appliance: null,
  serviceActivities: [],
  mrukSites: [],
  maintenanceIntervals: { optionLabel: '', value: '' },
};

export const transformField = (field: string): string => {
  switch (field) {
    case 'name':
      return 'name';
    case 'type':
      return 'type';
    case 'appliance_industry_id':
      return 'applianceIndustry';
    case 'system_id':
      return 'system';
    case 'group_id':
      return 'group';
    case 'appliance_id':
      return 'appliance';
    case 'service_activities':
      return 'serviceActivities';
    case 'mruk_sites_ids':
      return 'mrukSites';
    case 'maintenance_interval':
      return 'maintenanceIntervalsInput';
    case 'maintenance_interval_unit':
      return 'maintenanceIntervalsDropdown';
    default:
      return '__noField__';
  }
};

type tSinglePeriodicActivitiesForm = {
  disabled?: boolean;
  preview?: boolean;
  activityId: string | number | null;
  contractId: number | null;
  openRightPanel: ({ form, id, disabled }) => void;
};

const SinglePeriodicActivitiesForm: React.FC<tSinglePeriodicActivitiesForm> = ({
  disabled = false,
  preview = false,
  activityId,
  contractId,
  openRightPanel,
}): JSX.Element => {
  const { t } = useTranslation();
  const isAddMode = !!!activityId;
  const [initialValues, setInitialValues] = useState<tPeriodicActivityValues>();
  const [errors, setErrors] = useState<tError[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    fetch: fetchPeriodicActivity,
    loading: loadingActivity,
    data,
  } = useSingleFetch<tPeriodicActivityValues, tGet>(paService.get);

  const {
    fetch: fetchTypes,
    loading: loadingTypes,
    data: activitiesTypes,
  } = useFetch<tDropdownOption>(paService.getPeriodicActivityTypes);

  const {
    fetch: fetchUnits,
    loading: loadingUnits,
    data: units,
  } = useFetch<tOption>(paService.getPeriodicActivityMaintenanceUnits);

  const {
    fetch: fetchLocations,
    loading: loadingLocations,
    data: locationOptions,
  } = useFetch<tListOption, tGet>(paService.getLocations);

  const dispatch = useDispatch();

  const getInitialValues = (data) => {
    if (Object.keys(data).length > 0) {
      return data;
    }
    return dataModel;
  };

  useEffect(() => {
    const initialValues = getInitialValues(data);
    setInitialValues(initialValues);
  }, [data]);

  const { values, setValues, handleChange } = useForm({
    initialValues,
    // eslint-disable-next-line no-use-before-define
    onSubmit: (values) => onSubmit(values, isAddMode),
  });

  const {
    name,
    type,
    applianceIndustry,
    system,
    group,
    appliance,
    serviceActivities,
    mrukSites,
    maintenanceIntervals,
  }: tPeriodicActivityValues = values;

  const applianceTreeValues = {
    applianceIndustry,
    system,
    group,
    appliance,
  };

  const {
    industryOptions,
    systemOptions,
    groupOptions,
    applianceOptions,
    loadingTree,
    fetchTree,
    setGroupOptions,
    setApplianceOptions,
  } = useApplianceTree(applianceTreeValues);

  useEffect(() => {
    fetchTypes();
    fetchUnits();
    fetchTree({ id: Number(contractId) });
    fetchLocations({ id: Number(contractId) });
  }, [fetchTypes, fetchUnits, fetchLocations, fetchTree]);

  const fetchData = useCallback(() => {
    if (!!activityId) fetchPeriodicActivity({ id: Number(activityId) });
    else setValues(dataModel);
  }, [fetchPeriodicActivity, activityId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const setResponseErrors = (setter: Function, errors: any) => {
    if (Array.isArray(errors) && !!errors.length) {
      setter(
        errors.map((error: { [key: string]: string }) => {
          const { key, msg } = error;

          return {
            field: transformField(key),
            error: msg,
          };
        }),
      );
    }
  };

  const isLoading =
    loading || loadingActivity || loadingTypes || loadingUnits || loadingTree || loadingLocations;

  useEffect(() => {
    if (!isLoading) {
      setValues({ ...values, system: null, group: null, appliance: null });
      setGroupOptions([]);
      setApplianceOptions([]);
    }
  }, [applianceIndustry]);

  useEffect(() => {
    if (!isLoading) {
      setValues({ ...values, group: null, appliance: null });
      setApplianceOptions([]);
    }
  }, [system]);

  useEffect(() => {
    if (!isLoading) setValues({ ...values, appliance: null });
  }, [group]);

  const setNotificationHandler = (message: string, type = 'error' as 'error' | 'success') => {
    const enableTimeout = type === 'success';
    dispatch(addNotification(t(message), type, enableTimeout));
  };

  const createUpdatePeriodicActivities = async (
    values: tPeriodicActivityCreate,
    isAddMode: boolean,
  ) => {
    try {
      setLoading(true);
      const body: tPeriodicActivityCreate = {
        name,
        type: type?.value ? String(type?.value) : '',
        appliance_industry_id:
          Number(
            industryOptions.find((opt) => opt?.applianceIndustryId === applianceIndustry?.value)
              ?.applianceIndustryId,
          ) || null,
        system_id: Number(systemOptions.find((opt) => opt?.value === system?.value)?.id) || null,
        group_id: Number(groupOptions.find((opt) => opt?.value === group?.value)?.id) || null,
        appliance_id:
          Number(applianceOptions.find((opt) => opt?.value === appliance?.value)?.id) || null,
        service_activities: serviceActivities.map((activity) => ({ name: activity })),
        mruk_sites_ids: mrukSites?.map((loc) => String(loc?.id)) ?? [],
        maintenance_interval: maintenanceIntervals?.value,
        maintenance_interval_unit: maintenanceIntervals?.optionLabel ?? '',
      };

      const requiredFields = REQUIRED_FIELDS.CONTRACTS_FORM.SINGLE_PERIODIC_ACTIVITIES_FORM;
      const validationErrors = validateRequiredFields(body, requiredFields);
      if (validationErrors.length) return setResponseErrors(setErrors, validationErrors);

      const result = isAddMode
        ? await paService.create(Number(contractId), body)
        : await paService.update(Number(activityId), {
            ...body,
            contract_id: Number(contractId),
          });

      const { state } = result;

      if (!state) {
        const { errors: responseErrors } = result;
        return setResponseErrors(setErrors, responseErrors);
      }

      setErrors([]);
      openRightPanel({ form: '', id: null, disabled });
    } catch (error) {
      const setters = { setNotificationHandler, setResponseErrors, setErrors };
      catchErrors(error, setters);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = (values: any, isAddMode: boolean) =>
    createUpdatePeriodicActivities(values, isAddMode);

  return (
    <>
      {isLoading && <Spinner />}
      <ExpansionPanelWrapper>
        <ExpansionPanel
          hasBottomBorder={false}
          title='Periodic activities'
          hasIcon
          iconName='timelapse'
          shouldExpanded={false}
        >
          <Row justifyContent='space-between'>
            <Column col={11}>
              <Input
                name='name'
                label='Review name'
                onChange={handleChange}
                errors={errors}
                value={name}
                disabled={disabled}
                preview={preview}
              />
            </Column>
            <Column col={11}>
              <DropdownV2
                name='type'
                label='Refers to'
                options={
                  activitiesTypes.map(({ value, label }) => ({
                    value,
                    label: t(label),
                    fieldName: 'type',
                  })) ?? []
                }
                onChange={handleChange}
                errors={errors}
                value={
                  !!type?.value
                    ? {
                        value: type.value,
                        label: t(type.label),
                      }
                    : null
                }
                disabled={disabled}
                preview={preview}
              />
            </Column>
          </Row>
          <GapLine />
          <Row>
            <Column col={11}>
              <DropdownV2
                name='applianceIndustry'
                label='Industry'
                options={
                  industryOptions?.map(({ applianceIndustryId, industryName }) => ({
                    label: industryName,
                    value: applianceIndustryId,
                    fieldName: 'applianceIndustry',
                  })) ?? []
                }
                onChange={handleChange}
                errors={errors}
                value={applianceIndustry}
                disabled={disabled}
                preview={preview}
              />
            </Column>
            <Column col={11}>
              <DropdownV2
                name='system'
                label='System'
                options={systemOptions?.map((el) => ({ ...el, fieldName: 'system' })) ?? []}
                onChange={handleChange}
                errors={errors}
                value={system}
                disabled={disabled}
                preview={preview}
                labelOptional
              />
            </Column>
            <Column col={11}>
              <DropdownV2
                name='group'
                label='Group'
                options={groupOptions?.map((el) => ({ ...el, fieldName: 'group' })) ?? []}
                onChange={handleChange}
                errors={errors}
                value={group}
                disabled={disabled}
                preview={preview}
                labelOptional
              />
            </Column>
            <Column col={11}>
              <DropdownV2
                name='appliance'
                label='Device'
                options={applianceOptions?.map((el) => ({ ...el, fieldName: 'appliance' })) ?? []}
                onChange={handleChange}
                errors={errors}
                value={appliance}
                disabled={disabled}
                preview={preview}
                labelOptional
              />
            </Column>
          </Row>
        </ExpansionPanel>
        <ExpansionPanel
          hasBottomBorder
          title='Maintenance scope'
          hasIcon
          iconName='construction'
          shouldExpanded={false}
        >
          <Row>
            <Column col={11}>
              <InputWithList
                disabled={disabled}
                preview={preview}
                errors={errors}
                label='Maintenance operations'
                handleChange={handleChange}
                items={serviceActivities}
                name='serviceActivities'
              />
            </Column>
          </Row>
          <Column>
            <LineGap />
          </Column>
          <Row>
            <Column col={11}>
              <DropdownWithList
                dropdownDisabled={disabled}
                preview={preview}
                dropdownErrors={errors}
                dropdownLabel='MRU point'
                optionsData={
                  locationOptions?.map((el) => ({ ...el, fieldName: 'mrukSites' })) ?? []
                }
                optionFieldName='mrukSites'
                handleChange={handleChange}
                defaultItems={mrukSites?.map((el) => ({ ...el, fieldName: 'mrukSites' })) ?? []}
              />
            </Column>
            <Column col={11}>
              <InputDropdown
                name='maintenanceIntervals'
                label='Maintenance intervals'
                dropdownOptions={
                  units.map(({ value, label }) => ({
                    value,
                    label: t(label, { count: +maintenanceIntervals?.value }),
                  })) ?? []
                }
                values={maintenanceIntervals}
                errors={errors}
                onChange={handleChange}
                disabled={disabled}
                preview={preview}
              />
            </Column>
          </Row>
        </ExpansionPanel>
        <DiscardAndAcceptButtons
          disabled={false}
          onAccept={!disabled && !preview ? () => onSubmit(values, isAddMode) : null}
          onDiscard={() => openRightPanel({ form: '', id: null, disabled })}
        />
      </ExpansionPanelWrapper>
    </>
  );
};

export default SinglePeriodicActivitiesForm;
