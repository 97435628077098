import { uuid } from 'utils/helpers';
import { ReactTooltipStyle, TooltipSpan } from './Tooltip2.styles';

type tTooltip = {
  tooltip: JSX.Element[] | JSX.Element | string;
  children: JSX.Element[] | JSX.Element | string;
  tipId?: string | number | undefined;
  place?: string;
  effect?: string;
};

const Tooltip2: React.FC<tTooltip> = ({
  children,
  tooltip,
  tipId,
  place = 'top',
  effect = 'solid',
}) => {
  const id = `tip-${!!tipId ? tipId : uuid()}`;

  return (
    <>
      <TooltipSpan data-tip data-for={id}>
        {children}
      </TooltipSpan>
      <ReactTooltipStyle id={id} place={place} effect={effect}>
        {tooltip}
      </ReactTooltipStyle>
    </>
  );
};

export default Tooltip2;
