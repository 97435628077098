import styled, { css } from 'styled-components';

export type tHorizontalBorder = 'top' | 'bottom' | 'both' | null;

export type tCell = {
  width?: string;
  minWidth?: string;
  align?: 'left' | 'center' | 'right' | null;
  separator?: 'left' | 'right' | null;
  horizontalBorder?: tHorizontalBorder;
  lowPadding?: boolean;
  topPadding?: boolean;
  bottomPadding?: boolean;
};

export type tStickyCell = {
  align?: 'left' | 'center' | 'right' | null;
};

type tBigSumCell = tStickyCell & {
  profitability?: number;
};

export const Table = styled.table`
  position: relative;
  margin-top: -1px;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  font-size: 0.75rem;
`;

export const HeaderCell = styled.th`
  position: sticky;
  top: 0;
  padding: 0.75rem 1.5rem;
  text-align: left;
  font-size: 1.125rem;
  font-weight: 900;
  color: var(--theme-mainGrey);
  background-color: var(--theme-white);
  border-bottom: 1px solid var(--theme-mainGrey);
  z-index: calc(var(--theme-formZIndex) + 1);
`;

const rowPadding = 1; // rem

export const Cell = styled.td<tCell>`
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth};
    `}
  ${({ topPadding = false, bottomPadding = false }) =>
    css`
      padding-top: ${1 + +topPadding * rowPadding}rem;
      padding-bottom: ${1 + +bottomPadding * rowPadding}rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    `}
  ${({ lowPadding, topPadding = false, bottomPadding = false }) =>
    lowPadding &&
    css`
      padding-top: ${0 + +topPadding * rowPadding}rem;
      padding-bottom: ${0 + +bottomPadding * rowPadding}rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    `}
  color: var(--theme-mainDarkGrey);
  background-color: var(--theme-white);
  text-align: right;
  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}
  border-left: 0px;
  border-right: 0px;
  vertical-align: top;
  ${({ separator }) =>
    separator &&
    css`
      border-${separator}: 1px solid var(--theme-mainGrey);
      border-top: 0;
      border-bottom: 0;
    `}
  ${({ horizontalBorder }) =>
    css`
      border-top: ${horizontalBorder === 'top' || horizontalBorder === 'both'
        ? '1px solid var(--theme-mainGrey)'
        : '0'};
      border-bottom: ${horizontalBorder === 'bottom' || horizontalBorder === 'both'
        ? '1px solid var(--theme-mainGrey)'
        : '0'};
    `}
`;

export const StickyCell = styled.th<tStickyCell>`
  position: sticky;
  padding: 1.25rem 1.5rem;
  color: var(--theme-mainDarkGrey);
  background-color: var(--theme-white);
  text-align: right;
  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}
`;

export const BoldCell = styled(Cell)`
  border-top: 1px solid var(--theme-mainGrey);
  border-bottom: 1px solid var(--theme-mainGrey);
  font-weight: 900;
`;

export const UppercaseCell = styled(StickyCell)`
  font-weight: 900;
  text-transform: uppercase;
`;

export const AddButtonCell = styled(StickyCell)`
  font-weight: 900;
  text-transform: uppercase;
  text-align: left;
  align-items: center;
  padding: 0 0.5rem;
`;

export const BigSumCell = styled(StickyCell)<tBigSumCell>`
  ${({ profitability }) =>
    Number(profitability) < 0 &&
    css`
      color: red;
    `}
  font-weight: 900;
  font-size: 1.125rem;
  text-transform: uppercase;
`;

export const SeparatorCell = styled(Cell)`
  width: 1.5rem;
  min-width: 0.75rem;
  padding-left: 0;
  padding-right: 0;
`;

export const SumSufix = styled.span`
  font-weight: 900;
  font-size: 0.75rem;
  text-transform: lowercase;
`;

export const SeparatorTH = styled.th`
  position: sticky;
  border-bottom: 1px solid var(--theme-mainGrey);
  background-color: var(--theme-white);
`;

export const SeparatorTR = styled.tr`
  width: 100%;
  height: 1rem;
`;

export const ActionsCell = styled.th`
  min-height: 32px;
  bottom: 0px;
  margin-top: -2rem;
  padding: 1rem 0.5rem;
  border-top: 1px solid var(--theme-mainGrey);
  text-align: right;
  position: sticky;
  background-color: var(--theme-white);
`;

export type tReportCell = {
  width?: string;
};

export const ReportCell = styled.th<tReportCell>`
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
  vertical-align: top;
  max-width: 250px;
  max-width: 350px;
`;

export const ReportContainer = styled.div`
  width: 28rem;
  height: 100%;
  max-height: 490px;
  margin-bottom: 1rem;
  margin-right: -1.5rem;
`;

export const MessageContainer = styled.div`
  width: 24rem;
  height: 100%;
  max-height: 490px;
  margin-bottom: 1rem;
  margin-right: -1.5rem;
`;

export const ReportTitleWrapper = styled.div`
  padding-top: 0.5rem;
  padding-bottom: 0.75rem;
  font-weight: bold;
  font-size: 1.125rem;
`;

export const ReportWrapper = styled.div`
  padding-right: 0.75rem;
`;

export const InvoiceTableWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-right: 0.5rem;
`;

export const CommentContainer = styled.div`
  margin: 0.5rem 0;
  text-align: left;
  color: var(--theme-mainRed);
  font-size: 0.75rem;
`;

export const MessageTextContainer = styled.div`
  width: 100%;
  font-size: 1rem;
  text-align: left;
  padding: 2rem 1.5rem;
`;

export const AcceptanceTextBox = styled(StickyCell)`
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
`;
