import { useCallback, useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { useDispatch } from 'react-redux';
import { addNotification } from 'store/notifications/actions';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'hooks';
import settlementModesService, {
  tGetAllSettlementModesParams,
  tAccountingMode,
} from 'services/settlementModes';
import { Button, ExpansionPanel, Icon, List, Spinner, Label, SimpleDialog } from 'components';
import { colorBlueBasic, colorRedBasic } from 'styles/GlobalStyles';
import { CenterWithGaps, FormWrapper, Flex } from 'styles/GlobalStyledComponents';
import { tOpenedRightForm } from 'types/global';

interface iSettlementModesForm extends RouteComponentProps<any> {
  disabled?: boolean;
  preview?: boolean;
  openRightPanel: Function;
  rightForm: tOpenedRightForm;
  isSubcontractorContract?: boolean;
  canAddOrDelete?: boolean;
}

const SettlementModes: React.FC<iSettlementModesForm> = ({
  disabled = false,
  preview = false,
  openRightPanel = () => {},
  match,
  rightForm,
  isSubcontractorContract = false,
  canAddOrDelete = true,
}): JSX.Element => {
  const { id: contractId } = match.params;
  const [lastFormOpened, setLastFormOpened] = useState('');
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    fetch: setParamsHandler,
    loading: loadingData,
    data,
  } = useFetch<tAccountingMode, tGetAllSettlementModesParams>(
    settlementModesService.getAll,
    'Something went wrong while fetching data',
  );

  const fetchData = useCallback(
    () => contractId && setParamsHandler({ contractId, isSubcontractorContract }),
    [contractId, isSubcontractorContract, setParamsHandler],
  );

  useEffect(() => {
    if (!!contractId) fetchData();
  }, [fetchData, contractId, preview, disabled]);

  useEffect(() => {
    if (lastFormOpened === 'SettlementModes') fetchData();
    setLastFormOpened(rightForm?.form);
  }, [rightForm?.form]);

  const removeSettlementMode = async (id: number | string) => {
    const result = await settlementModesService.remove({ accountingModeId: id });
    const { status, error } = result;

    if (status === 204) {
      dispatch(addNotification(t('Accounting mode was removed'), 'success', true));
    } else {
      dispatch(addNotification(t(error), 'error'));
    }
    fetchData();
  };

  const getActions = ({ id, name }: tAccountingMode) => {
    const editAction = {
      action: <Icon width='1rem' height='1rem' icon='edit' fill={colorBlueBasic} />,
      actionHandler: () => openRightPanel({ form: 'SettlementModes', id, disabled }),
    };
    const previewAction = {
      action: <Icon width='1rem' height='1rem' icon='visibility' fill={colorBlueBasic} />,
      actionHandler: () => openRightPanel({ form: 'SettlementModes', id, disabled }),
    };
    const deleteAction = {
      action: (
        <SimpleDialog
          action={() => removeSettlementMode(id)}
          type='remove'
          title='Delete settlement mode'
          copy={`${t('Are you sure you want to delete settlement mode')}${
            name ? ` "${name}"` : ''
          }?`}
        >
          <Icon width='1rem' height='1rem' icon='close' fill={colorRedBasic} />
        </SimpleDialog>
      ),
    };

    if (preview) return [previewAction];
    if (!canAddOrDelete || isSubcontractorContract) return [editAction];
    else return [editAction, deleteAction];
  };

  const items = data?.map((item) => {
    return {
      ...item,
      actions: getActions(item),
    };
  });

  return (
    <>
      {loadingData && <Spinner />}
      <ExpansionPanel hasBottomBorder title='Settlement modes' hasIcon iconName='payments'>
        <FormWrapper>
          <Flex alignItems='flex-start' justifyContent='flex-start'>
            <Label>{t('Settlement modes')}</Label>
            <List items={items} noItemsCopy={t('No settlement modes assigned')} />
          </Flex>
          {canAddOrDelete && !isSubcontractorContract && (
            <CenterWithGaps>
              <Button
                disabled={disabled}
                icon='addCircle'
                variant='blue'
                onClick={() => openRightPanel({ form: 'SettlementModes', id: null })}
              >
                {t('Add')}
              </Button>
            </CenterWithGaps>
          )}
        </FormWrapper>
      </ExpansionPanel>
    </>
  );
};

export default withRouter(SettlementModes);
