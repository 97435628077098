import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const BreadcrumbsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.75rem;
  font-weight: 900;
  letter-spacing: 0.02rem;
  color: var(--theme-mainDarkGrey);
  fill: var(--theme-mainDarkGrey);
`;

export const CrumbContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 0.5rem;
  color: var(--theme-mainDarkGrey);
`;

export const InactiveCrumbContainer = styled(CrumbContainer)`
  color: var(--theme-mainGrey);
  fill: var(--theme-mainGrey);
`;

export const NavLinkStyle = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.25rem 0.25rem 0.25rem 0;
  margin-right: 0.5rem;
  text-decoration: none;
  &:active {
    color: var(--theme-black);
  }
`;
