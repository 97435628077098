import { useTranslation } from 'react-i18next';
import { Button } from 'components';
import { LeftMargin1Rem } from 'styles/GlobalStyledComponents';
import { useLocation } from 'react-router-dom';

export type tComplaintIssueAction = {
  history: { push: Function };
  issueId: string | number;
};

const ComplaintIssueAction: React.FC<tComplaintIssueAction> = ({ history, issueId }) => {
  const { t } = useTranslation();
  const pathLocation = useLocation();

  const onComplaintClick = () => {
    history.push({ pathname: `/issues/${issueId}/complaint/new`, state: { confirmation: true } });
  };

  const onNewComplaintClick = () => {
    history.push(`/issues/${issueId}/complaint/new`);
  };

  return (
    <LeftMargin1Rem>
      <Button
        variant='blue'
        onClick={
          pathLocation.pathname.includes('/confirmation') ? onComplaintClick : onNewComplaintClick
        }
      >
        {t('Complaint')}
      </Button>
    </LeftMargin1Rem>
  );
};

export default ComplaintIssueAction;
