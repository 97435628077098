import { useState } from 'react';
import Icon from 'components/Icon';
import { colorGreyBasic } from 'styles/GlobalStyles';
import { Collapsible, FolderIcon, FolderLabel, FolderTitle, StyledFolder } from './TreeView.style';

type tFolder = {
  children: JSX.Element[];
  hasParent: boolean;
  name: string;
  renderActions: JSX.Element[] | null;
  edit?: any;
  isOpen?: boolean;
  type?: 'tree' | 'location';
};

const Folder: React.FC<tFolder> = ({
  children = null,
  hasParent = false,
  name = '-',
  renderActions = null,
  edit = null,
  type = 'tree',
  isOpen: isOpenHelper = false,
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(isOpenHelper);

  const handleToggle = (event: React.SyntheticEvent) => {
    event.preventDefault();

    setIsOpen(!isOpen);
  };

  const renderFolderIcon = (isFolderOpen: boolean) => (
    <FolderIcon onClick={handleToggle}>
      <Icon fill={colorGreyBasic} icon={isFolderOpen ? 'arrowDown' : 'chevronRight'} />
    </FolderIcon>
  );

  return (
    <StyledFolder
      className={
        hasParent
          ? `tree-item-with-parent ${type === 'location' ? 'without-line location-line' : ''}`
          : 'location-line'
      }
    >
      <FolderLabel type={type}>
        {renderFolderIcon(isOpen)}
        {!!edit && <FolderTitle>{edit(handleToggle)}</FolderTitle>}
        {!edit && <FolderTitle onClick={handleToggle}>{name}</FolderTitle>}
        {renderActions}
      </FolderLabel>
      <Collapsible isOpen={isOpen}>{children}</Collapsible>
    </StyledFolder>
  );
};

export default Folder;
