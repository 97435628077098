import { useTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars';
import { DateList } from 'components';
import { tDropdownOption, tError } from 'types/global';
import {
  tInvoice,
  tInvoiceRowData,
  tSubSumsData,
  tInvoiceRowType,
  tInvoiceComments,
} from 'types/components/invoices';
import { colorGreyBasic } from 'styles/GlobalStyles';
import { Flex } from 'styles/GlobalStyledComponents';
import {
  Table,
  HeaderCell,
  ReportTitleWrapper,
  ReportWrapper,
  ReportContainer,
  InvoiceTableWrapper,
  MessageContainer,
  MessageTextContainer,
} from './InvoiceTable.style';
import InvoiceRow from './InvoiceRow';
import InvoiceFooter from './InvoiceFooter';
import AddRow from './AddRow';
import SubHeader from './SubHeader';
import SubSums from './SubSums';
import Separator from './Separator';
import MarkupSums from './MarkupSums';

export type tOptionWithData = tDropdownOption & {
  unit: { id: number; name: string } | { value: number; label: string } | null;
  rate: number;
};

export type tInvoiceTable = {
  id: number | string;
  invoiceData: tInvoice | null;
  secondaryInvoiceData?: tInvoice | null;
  secondaryInvoiceMessage?: string | null;
  onChange: (tInvoice) => void;
  reportsData: any;
  edit?: boolean;
  labourOptions?: tDropdownOption[];
  billableActivitiesOptions?: tOptionWithData[];
  transportationOptions?: tDropdownOption[];
  unitOptions?: tDropdownOption[];
  showReport?: boolean;
  showGross?: boolean;
  showContractor?: boolean;
  clientActions?: React.ReactNode | null;
  contractorActions?: React.ReactNode | null;
  hideHeaders?: boolean;
  errors: tError[];
  addComment: (rowData: tInvoiceRowData) => void;
  commentMode: boolean;
  comments: tInvoiceComments;
  subcontractorCommentMode: boolean;
};

export const subSumsCalc = (rows: tInvoiceRowData[]): tSubSumsData => {
  if (!rows?.length) return null;
  return {
    client: {
      net: Object.values(rows).reduce((t, { client }) => t + (client?.net ?? 0), 0),
      gross: Object.values(rows).reduce((t, { client }) => t + (client?.gross ?? 0), 0),
    },
    contractor: {
      net: Object.values(rows).reduce(
        (t, { contractor }) => (!!contractor ? t + (contractor?.net ?? 0) : 0),
        0,
      ),
      gross: Object.values(rows).reduce(
        (t, { contractor }) => (!!contractor ? t + (contractor?.gross ?? 0) : 0),
        0,
      ),
    },
  };
};

type tInvoiceRowDataWithoutIdType = Omit<tInvoiceRowData, 'type' | 'id'>;

const newEmptyRow: tInvoiceRowDataWithoutIdType = {
  title: '',
  unit: null,
  quantity: 0,
  client: { value: 0, net: 0, gross: 0 },
  contractor: { value: 0, net: 0, gross: 0 },
  hidden: false,
};

const InvoiceTable: React.FC<tInvoiceTable> = ({
  invoiceData,
  onChange,
  reportsData,
  secondaryInvoiceData = null,
  secondaryInvoiceMessage = null,
  edit = false,
  labourOptions = [],
  billableActivitiesOptions = [],
  transportationOptions = [],
  unitOptions = [],
  showGross = false,
  showContractor = true,
  showReport = false,
  clientActions,
  contractorActions,
  hideHeaders = false,
  errors,
  addComment,
  commentMode,
  comments,
  subcontractorCommentMode,
}): JSX.Element => {
  const { t } = useTranslation();
  const colSpanShowGross = showGross ? 3 : 2;

  const data = invoiceData
    ? {
        ...invoiceData,
        sums: {
          clientNet: invoiceData?.sums?.clientNet,
          clientGross: invoiceData?.sums?.clientGross,
          contractorNet: secondaryInvoiceData?.sums?.clientNet,
          contractorGross: secondaryInvoiceData?.sums?.clientGross,
          profitNet:
            invoiceData?.sums?.clientNet && secondaryInvoiceData?.sums?.clientNet
              ? invoiceData.sums.clientNet - secondaryInvoiceData.sums.clientNet
              : null,
          profitGross:
            invoiceData?.sums?.clientGross && secondaryInvoiceData?.sums?.clientGross
              ? invoiceData.sums.clientGross - secondaryInvoiceData.sums.clientGross
              : null,
          profitability:
            invoiceData?.sums?.clientNet && secondaryInvoiceData?.sums?.clientNet
              ? ((invoiceData.sums.clientNet - secondaryInvoiceData.sums.clientNet) /
                  invoiceData.sums.clientNet) *
                100
              : null,
        },
      }
    : null;

  const primaryInvoiceRows = invoiceData?.rows ?? [];
  const secondaryInvoiceRows = secondaryInvoiceData?.rows ?? [];
  const combinedRows = [
    ...primaryInvoiceRows,
    ...secondaryInvoiceRows.map((row) => ({
      ...row,
      client: null,
      contractor: row.client,
      nonEditable: true,
      subcontractorRow: true,
    })),
  ];

  const labour = combinedRows.filter((row) => row?.type === 'labour' && !row?.hidden) ?? [];
  const billableActivitiesData =
    combinedRows.filter((row) => row?.type === 'billable_activities' && !row?.hidden) ?? [];
  const billableActivitiesSums = subSumsCalc(billableActivitiesData);
  const materialsData =
    combinedRows.filter((row) => row?.type === 'price_list' && !row?.hidden) ?? [];
  const materialsSums = subSumsCalc(materialsData);
  const transportation = combinedRows.filter((row) => row?.type === 'travel' && !row?.hidden) ?? [];
  const offers = combinedRows.filter((row) => row?.type === 'offer' && !row?.hidden);
  const clientBillableActivitiesData =
    primaryInvoiceRows.filter((row) => row?.type === 'billable_activities' && !row?.hidden) ?? [];

  //Markup materials data
  const filteredMarkupForMaterials =
    combinedRows.filter((row) => row?.type === 'materials_percent' && !row?.hidden) ?? [];
  const markupForMaterials = {
    contractor: filteredMarkupForMaterials.find((row) => row?.contractor && row?.contractor)
      ?.contractor,
    client: filteredMarkupForMaterials.find((row) => row?.client && row?.client)?.client,
  };

  const filterBillableActivitiesOptions = () => {
    const billableActivitiesDataArray = clientBillableActivitiesData.map(
      (activity) => activity.title,
    );

    return billableActivitiesOptions.filter(
      (item) => !billableActivitiesDataArray.includes(item.label),
    );
  };

  const onRowChange = (newRow) => {
    onChange({ ...data, rows: data?.rows.map((row) => (row?.id === newRow?.id ? newRow : row)) });
  };

  const addRow = (type: tInvoiceRowType) => {
    onChange({
      ...data,
      rows: [
        ...(data?.rows ?? []),
        { ...newEmptyRow, type, id: new Date().getTime(), rowNo: data?.rows.length },
      ],
    });
  };

  const deleteRow = (id) => {
    onChange({
      ...data,
      rows: data?.rows.map((row) => {
        if (row.id === id) {
          return {
            ...row,
            hidden: true,
          };
        }
        return row;
      }),
    });
  };

  return (
    <Flex>
      <InvoiceTableWrapper>
        <Scrollbars
          renderThumbHorizontal={(props) => (
            <div {...props} style={{ backgroundColor: colorGreyBasic, width: 5, height: 5 }} />
          )}
          style={{ minHeight: '500px', height: 'calc(90vh - 300px)', paddingBottom: '0.5rem' }}
          autoHide
          autoHideTimeout={1000}
        >
          <Table>
            <tbody>
              {!hideHeaders && (
                <tr>
                  <HeaderCell colSpan={3} />
                  <Separator />
                  <HeaderCell colSpan={colSpanShowGross}>{t('With client')}</HeaderCell>

                  {showContractor && (
                    <>
                      <Separator />
                      <HeaderCell colSpan={colSpanShowGross}>{t('With contractor')}</HeaderCell>
                    </>
                  )}
                  {(showReport || secondaryInvoiceMessage) && <Separator />}
                </tr>
              )}

              <SubHeader
                name='Labour'
                showQuantity
                showGross={showGross}
                showContractor={showContractor}
                showReport={showReport || !!secondaryInvoiceMessage}
                rateTitle='mode rate'
              />
              {labour.map((row, index) => (
                <InvoiceRow
                  key={row.id}
                  type='dropdown'
                  rowData={row}
                  onChange={onRowChange}
                  itemOptions={labourOptions}
                  rowConfig={{ hideUnit: true }}
                  allowDelete
                  deleteRow={deleteRow}
                  edit={edit}
                  unitOptions={unitOptions}
                  showGross={showGross}
                  showContractor={showContractor}
                  showReport={showReport || !!secondaryInvoiceMessage}
                  errors={errors}
                  topPadding={index === 0}
                  addComment={addComment}
                  commentMode={commentMode}
                  comments={comments}
                  subcontractorCommentMode={subcontractorCommentMode}
                />
              ))}
              <AddRow
                edit={edit}
                type='labour'
                addRow={addRow}
                showContractor={showContractor}
                showGross={showGross}
                showReport={showReport || !!secondaryInvoiceMessage}
              />
              {billableActivitiesData && (
                <>
                  <SubHeader
                    name='Flat-rate activities'
                    showUnit
                    showQuantity
                    showGross={showGross}
                    showContractor={showContractor}
                    showReport={showReport || !!secondaryInvoiceMessage}
                    rateTitle='unit rate'
                  />
                  {billableActivitiesData.map((row, index) => {
                    return (
                      <InvoiceRow
                        key={row.id}
                        rowData={row}
                        onChange={onRowChange}
                        allowDelete
                        deleteRow={deleteRow}
                        type='dropdown'
                        rowConfig={{ previewRate: true, previewUnit: true }}
                        edit={edit}
                        allItemOptions={billableActivitiesOptions}
                        itemOptions={filterBillableActivitiesOptions()}
                        unitOptions={unitOptions}
                        showGross={showGross}
                        showContractor={showContractor}
                        showReport={showReport || !!secondaryInvoiceMessage}
                        topPadding={index === 0}
                        errors={errors}
                        addComment={addComment}
                        commentMode={commentMode}
                        comments={comments}
                        subcontractorCommentMode={subcontractorCommentMode}
                      />
                    );
                  })}
                  {!!filterBillableActivitiesOptions().length && (
                    <AddRow
                      edit={edit}
                      type='billable_activities'
                      addRow={addRow}
                      showContractor={showContractor}
                      showGross={showGross}
                      showReport={showReport || !!secondaryInvoiceMessage}
                      row={{
                        rowLength: clientBillableActivitiesData?.length,
                        maxRowLength: billableActivitiesOptions?.length,
                      }}
                    />
                  )}
                  {billableActivitiesSums && (
                    <SubSums
                      data={billableActivitiesSums}
                      showGross={showGross}
                      showContractor={showContractor}
                      showReport={showReport || !!secondaryInvoiceMessage}
                    />
                  )}
                </>
              )}
              {materialsData && (
                <>
                  <SubHeader
                    name='Price list (material)'
                    showUnit
                    showQuantity
                    showGross={showGross}
                    showContractor={showContractor}
                    showReport={showReport || !!secondaryInvoiceMessage}
                    rateTitle='unit rate'
                  />
                  {materialsData.map((row, index) => (
                    <InvoiceRow
                      key={row.id}
                      rowData={row}
                      onChange={onRowChange}
                      allowDelete
                      deleteRow={deleteRow}
                      edit={edit}
                      unitOptions={unitOptions}
                      showGross={showGross}
                      showContractor={showContractor}
                      showReport={showReport || !!secondaryInvoiceMessage}
                      topPadding={index === 0}
                      errors={errors}
                      addComment={addComment}
                      commentMode={commentMode}
                      comments={comments}
                      subcontractorCommentMode={subcontractorCommentMode}
                    />
                  ))}
                  <AddRow
                    edit={edit}
                    type='price_list'
                    addRow={addRow}
                    showContractor={showContractor}
                    showGross={showGross}
                    showReport={showReport || !!secondaryInvoiceMessage}
                  />
                  {materialsSums && (
                    <SubSums
                      data={materialsSums}
                      showGross={showGross}
                      showContractor={showContractor}
                      showReport={showReport || !!secondaryInvoiceMessage}
                    />
                  )}
                  {markupForMaterials && (
                    <MarkupSums
                      name='Total with markup'
                      data={markupForMaterials}
                      showGross={showGross}
                      showContractor={showContractor}
                      showReport={showReport || !!secondaryInvoiceMessage}
                    />
                  )}
                </>
              )}
              <SubHeader
                name='Transportation'
                showQuantity
                showGross={showGross}
                showContractor={showContractor}
                showReport={showReport || !!secondaryInvoiceMessage}
                rateTitle='rate'
              />
              {transportation.map((row, index) => (
                <InvoiceRow
                  key={row.id}
                  type='dropdown'
                  rowData={row}
                  onChange={onRowChange}
                  allowDelete
                  deleteRow={deleteRow}
                  rowConfig={{ hideUnit: true }}
                  edit={edit}
                  itemOptions={transportationOptions}
                  showGross={showGross}
                  showContractor={showContractor}
                  showReport={showReport || !!secondaryInvoiceMessage}
                  topPadding={index === 0}
                  errors={errors}
                  addComment={addComment}
                  commentMode={commentMode}
                  comments={comments}
                  subcontractorCommentMode={subcontractorCommentMode}
                />
              ))}
              <AddRow
                edit={edit}
                type='travel'
                addRow={addRow}
                showContractor={showContractor}
                showGross={showGross}
                showReport={showReport || !!secondaryInvoiceMessage}
              />
              {offers && (
                <>
                  <SubHeader
                    name='Offer'
                    showGross={showGross}
                    showContractor={showContractor}
                    showReport={showReport || !!secondaryInvoiceMessage}
                  />
                  {offers.map((row, index) => (
                    <InvoiceRow
                      key={row.id}
                      rowData={row}
                      onChange={onRowChange}
                      allowDelete
                      deleteRow={deleteRow}
                      edit={edit}
                      rowConfig={{ hideUnit: true, hideQuantity: true }}
                      showGross={showGross}
                      showContractor={showContractor}
                      showReport={showReport || !!secondaryInvoiceMessage}
                      topPadding={index === 0}
                      errors={errors}
                      addComment={addComment}
                      commentMode={commentMode}
                      comments={comments}
                      subcontractorCommentMode={subcontractorCommentMode}
                    />
                  ))}
                  <AddRow
                    edit={edit}
                    type='offer'
                    addRow={addRow}
                    showContractor={showContractor}
                    showGross={showGross}
                    showReport={showReport || !!secondaryInvoiceMessage}
                  />
                </>
              )}
              <InvoiceFooter
                data={data?.sums ?? null}
                showGross={showGross}
                showContractor={showContractor}
                showReport={showReport || !!secondaryInvoiceMessage}
                clientActions={clientActions}
                contractorActions={contractorActions}
              />
            </tbody>
          </Table>
        </Scrollbars>
      </InvoiceTableWrapper>
      {secondaryInvoiceMessage && (
        <>
          <MessageContainer>
            <HeaderCell colSpan={colSpanShowGross}>{t('With contractor')}</HeaderCell>
            <MessageTextContainer>{secondaryInvoiceMessage}</MessageTextContainer>
          </MessageContainer>
          {showReport && <Separator />}
        </>
      )}
      {showReport && (
        <ReportContainer>
          <ReportTitleWrapper>{t('Technician report')}</ReportTitleWrapper>
          <Scrollbars
            renderThumbHorizontal={(props) => (
              <div {...props} style={{ backgroundColor: colorGreyBasic, width: 5, height: 5 }} />
            )}
            style={{ minHeight: '470px', height: 'calc(90vh - 325px)', marginBottom: '2rem' }}
            autoHide
            autoHideTimeout={1000}
          >
            <ReportWrapper>
              <DateList data={reportsData} hideGapLine />
            </ReportWrapper>
          </Scrollbars>
        </ReportContainer>
      )}
    </Flex>
  );
};

export default InvoiceTable;
