/* eslint-disable camelcase */
import mruAgent from 'agents/mru';
import { tCreateMruAPI, tEditMruAPI } from 'types/api/mru';
import { tGetAllMruData, tGetMruData } from 'types/services/mru';
import { tGetAll, tGet } from 'types/global';

const getAll = (params: tGetAll) => {
  const { offset = 0 } = params;

  const result = mruAgent.getAll(params).then((response) => {
    const results: tGetAllMruData[] = response.data.results.map((result, index) => {
      const { sap_id, name, city, street, building_number, id, postal_code } = result;

      return {
        id,
        order: index + 1 + offset,
        MRUFullAddress: `${street} ${building_number}, ${city} ${postal_code}`,
        MRUName: name,
        sapNumber: sap_id,
      };
    });

    const { status } = response;
    return {
      data: { ...response.data, results },
      status,
    };
  });

  return result;
};

const get = ({ id }: tGet) => {
  const result = mruAgent.get(id).then(({ data, status }) => {
    const {
      building_number,
      city,
      geolocation,
      name,
      impel_location,
      postal_code,
      region,
      sap_id,
      street,
      id,
    } = data;

    const result: tGetMruData = {
      id,
      MRUName: name,
      sapNumber: sap_id,
      localizationImpel: impel_location,
      MRUAddress: {
        city,
        street,
        buildingNumber: building_number,
        postCode: postal_code,
        region: {
          value: region.id,
          label: region.name,
          fieldName: 'province',
        },
        country: {
          value: region.country.id,
          label: region.country.name,
          fieldName: 'country',
        },
        geolocation: !!geolocation
          ? {
              id: geolocation?.id,
              latitude: String(geolocation?.latitude),
              longitude: String(geolocation?.longitude),
            }
          : null,
      },
    };

    return {
      data: result,
      status,
    };
  });
  return result;
};

const create = (data: tCreateMruAPI) => mruAgent.create(data);

const edit = async (id: string, data: tEditMruAPI) => mruAgent.edit(id, data);

const getRegions = () => {
  const result = mruAgent.getRegions().then(({ data }) => {
    const results = data.results.map(({ id, name }) => {
      return {
        value: id,
        label: name,
      };
    });

    return {
      data: { results },
    };
  });
  return result;
};

const getCountries = () => {
  const result = mruAgent.getCountries().then(({ data }) => {
    const results = data.results.map(({ id, name }) => {
      return {
        value: id,
        label: name,
      };
    });

    return {
      data: { results },
    };
  });
  return result;
};

const deleteMRU = (id: number) => mruAgent.delete(id);

export default {
  get,
  getAll,
  deleteMRU,
  getCountries,
  getRegions,
  create,
  edit,
};
