import { tTableHead } from 'types/components/table';

export const tableHead: tTableHead[] = [
  {
    sortable: false,
    name: 'Lp.',
    key: 'order',
  },
  {
    sortable: false,
    name: 'Name',
    key: 'customerName',
  },
  {
    sortable: false,
    name: 'NIP',
    key: 'taxPayerId',
  },
  {
    sortable: false,
    name: 'SAP Number',
    key: 'sapNumber',
  },
  {
    sortable: false,
    name: 'Headquarters',
    key: 'address',
  },
];
