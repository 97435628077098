import { tTableHead } from 'types/components/table';

export const tableHead: tTableHead[] = [
  {
    sortable: false,
    name: 'No.',
    key: 'order',
  },
  {
    sortable: false,
    name: 'ID',
    key: 'externalSystemId',
  },
  {
    sortable: false,
    name: 'Name and surname',
    key: 'userNameAndSurname',
  },
  {
    sortable: false,
    name: 'Position',
    key: 'position',
  },
  {
    sortable: false,
    name: 'Phone number',
    key: 'phoneNumber',
  },
  {
    sortable: false,
    name: 'E-mail',
    key: 'email',
  },
];

export const searchableFields = [
  'external_system_id',
  'first_name',
  'last_name',
  'position',
  'phone_number',
  'email',
];
