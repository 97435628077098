import styled from 'styled-components';
import { Button } from 'components/Button/Button.style';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100vh;
`;

export const Main = styled.main`
  flex: 1;
  padding-top: 4rem;
  overflow: hidden;
  overflow-y: auto;
  position: relative;
  background-color: var(--theme-lightGrey);
  z-index: var(--theme-containerZIndex);
`;

export const MenuButton = styled<any>(Button)`
  display: none;
  svg {
    &:hover {
      fill: var(--theme-black);
      transition: 0.7s;
    }
  }

  @media screen and (max-width: 1099px) {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    margin-right: 1rem;
    margin-top: 1rem;
  }
`;

export const View = styled.div`
  padding: 0 4rem 0 3rem;
  @media screen and (max-width: 1280px) {
    padding: 0 1rem 0 2rem;
  }
  height: 95%;
`;
