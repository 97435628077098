import { useTranslation } from 'react-i18next';
import { useForm } from 'hooks';
import { newUID, errorsLocFilter } from 'utils/helpers';
import { ExpansionPanel, Button, Form, Input, Row, Column, DropdownV2 } from 'components';
import { tConsumableDeviceData } from 'types/services/devices';
import { tError } from 'types/global';
import { ExpansionPanelWrapper, FormWrapper, Flex } from 'styles/GlobalStyledComponents';
import { ButtonWrapper } from '../AddEditFormDevices.style';
import { measurementUnitOptions } from '../deviceENUMs';

const dataModel: tConsumableDeviceData = {
  name: '',
  quantity: 0,
  unit: '',
  ean: '',
  manufacturer: '',
};

type tSingleConsumable = {
  disabled?: boolean;
  preview?: boolean;
  data: tConsumableDeviceData[];
  setData: (data: any) => void;
  closeForm: () => void;
  consumableId: number | null;
  errors: tError[];
};

const SingleConsumable: React.FC<tSingleConsumable> = ({
  disabled = false,
  preview = false,
  consumableId = null,
  data = [],
  setData,
  closeForm,
  errors = [],
}): JSX.Element => {
  const { t } = useTranslation();

  const current = data?.find((el) => el?.id === consumableId) ?? dataModel;
  const currentIndex = data.indexOf(current);
  const itemErrors = errorsLocFilter(errors, currentIndex ?? '');

  const { values, handleChange, handleSubmit } = useForm({
    initialValues: current,
    // eslint-disable-next-line no-use-before-define
    onSubmit: () => onSubmit(!!!consumableId),
  });
  const { name, quantity, unit, ean, manufacturer } = values;

  const onSubmit = (isAddMode: boolean) => {
    let newConsumable: tConsumableDeviceData[] = [];
    if (isAddMode) {
      newConsumable = [
        ...data,
        { id: newUID(data), name, quantity: +quantity, unit: unit?.value, ean, manufacturer },
      ];
    } else {
      newConsumable = data.map((el) =>
        el?.id !== consumableId
          ? el
          : { id: el?.id, name, quantity: +quantity, unit: unit?.value, ean, manufacturer },
      );
    }
    setData({ fieldName: 'consumables', label: newConsumable });
    closeForm();
  };

  const saveButtonEnabled = !!name.replace(/\s/g, '') && !!quantity && !!unit;

  return (
    <Form onSubmit={handleSubmit}>
      <ExpansionPanelWrapper>
        <ExpansionPanel hasBottomBorder title={t('Consumables')} hasIcon iconName='developerBoard'>
          <FormWrapper>
            <Row justifyContent='space-between'>
              <Column>
                <Input
                  name='name'
                  label='Name'
                  onChange={handleChange}
                  errors={itemErrors}
                  value={name}
                  disabled={disabled}
                  preview={preview}
                />
              </Column>
              <Column col={preview ? 12 : 6}>
                <Input
                  name='quantity'
                  label='Quantity'
                  onChange={handleChange}
                  errors={itemErrors}
                  value={preview ? `${quantity || '-'} ${t(unit) ?? ''}` : quantity}
                  disabled={disabled}
                  preview={preview}
                />
              </Column>
              {!preview && (
                <Column col={6}>
                  <DropdownV2
                    name='unit'
                    label='Unit'
                    options={measurementUnitOptions}
                    value={measurementUnitOptions?.find((el) => el.value === unit)}
                    onChange={handleChange}
                    errors={itemErrors}
                    disabled={disabled}
                    preview={preview}
                  />
                </Column>
              )}
              <Column>
                <Input
                  name='ean'
                  label='Ean'
                  onChange={handleChange}
                  errors={itemErrors}
                  value={ean}
                  disabled={disabled}
                  preview={preview}
                  labelOptional
                />
              </Column>
              <Column>
                <Input
                  name='manufacturer'
                  label='Manufacturer'
                  onChange={handleChange}
                  errors={itemErrors}
                  value={manufacturer}
                  disabled={disabled}
                  preview={preview}
                  labelOptional
                />
              </Column>
            </Row>
          </FormWrapper>
        </ExpansionPanel>
        <ButtonWrapper>
          <Button onClick={closeForm} variant='red' kind='outlined'>
            {t('Close')}
          </Button>
          {!disabled && !preview && (
            <>
              <Flex marginRight='1rem' />
              <Button variant='green' type='submit' disabled={!saveButtonEnabled}>
                {t('Save')}
              </Button>
            </>
          )}
        </ButtonWrapper>
      </ExpansionPanelWrapper>
    </Form>
  );
};

export default SingleConsumable;
