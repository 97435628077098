import styled, { css } from 'styled-components';
import { tInput } from './Input';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex: 0 1 100%;
  position: relative;
  justify-content: center;
`;

export const MainWrapper = styled(Wrapper)`
  margin-bottom: 1rem;
  flex-direction: column;
`;

export const InputStyle = styled.input<tInput>`
  min-height: 1.875rem;
  max-height: 2rem;
  font-size: 0.75rem;
  display: flex;
  width: 100%;
  flex: 0 1 100%;
  border-radius: 1rem;
  border: 1px solid var(--theme-mainGrey);
  border: 1px solid
    ${({ errors, name }) =>
      errors.find((error) => error.field === name)
        ? 'var(--theme-mainRed)'
        : 'var(--theme-mainGrey)'};
  ${({ disabled }) =>
    disabled &&
    css`
      border: none;
      background-color: var(--theme-lightGrey);
      color: var(--theme-mainGrey);
    `}
  &:focus {
    outline: none;
    border: 1px solid var(--theme-mainBlue);
  }
  box-sizing: border-box;
  padding: 0.5rem 1rem;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const InputSufixContainer = styled.div`
  position: absolute;
  right: 0.75rem;
  display: flex;
  align-items: center;
  width: min-width;
  min-height: 2rem;
  font-size: 0.75rem;
  color: var(--theme-mainGrey);
`;

export const MaxLength = styled.div`
  position: absolute;
  right: 0.25rem;
  bottom: -1rem;
  font-size: 0.625rem;
  color: var(--theme-mainGrey);
`;

export const PreviewText = styled.div`
  width: 100%;
  font-size: 0.75rem;
`;
