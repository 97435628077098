import styled from 'styled-components';

export const ItemRow = styled.div`
  align-items: center;
  display: flex;
  padding-bottom: 0.5rem;
`;

export const ItemTitle = styled.div`
  font-size: 0.875rem;
  margin: 0;
  padding-right: 0.25rem;
`;

export const List = styled.div`
  margin-left: 106px;
  padding-left: 1rem;
`;

export const ButtonWrapper = styled.div`
  margin-bottom: 1rem;
  position: absolute;
  top: 50%;
  right: -2rem;
  transform: translateY(-45%);
`;

export const Relative = styled.div`
  position: relative;
`;

export const PreviewWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const PreviewListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 0.75rem;
`;

export const ItemPreviewTitle = styled.div`
  font-size: 0.75rem;
  margin: 0;
  padding-right: 0.25rem;
`;

export const NoItem = styled.div`
  font-size: 0.75rem;
  font-style: italic;
  padding-right: 0.25rem;
`;
