import styled from 'styled-components';
import { tWrapperStyle } from './Row';

export const Wrapper = styled.div<tWrapperStyle>`
  flex-wrap: wrap;
  flex-direction: row;
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 -8px 0 -8px;
  justify-content: ${(props) => `${props.justifyContent}`};
  align-items: ${(props) => `${props.alignItems}`};
`;
