import { tDropdownOption } from 'types/global';
import SingleLocationForm from './SingleLocationForm';
import SingleWorkHoursForm from './SingleWorkHoursForm';
import SingleTechnicalManagersITSForm from './SingleTechnicalManagersITSForm';
import SingleUsersForm from './SingleUsersForm';

type tSingleLocationMainForm = {
  disabled: boolean;
  isAddMode: boolean;
  id: string;
  openRightPanel: ({ form, id, disabled }) => void;
  addressId: string | number | null;
  preview: boolean;
  companyData: tDropdownOption | null;
};

const SingleLocationMainForm: React.FC<tSingleLocationMainForm> = ({
  disabled = false,
  preview = false,
  isAddMode = false,
  id,
  openRightPanel,
  addressId,
  companyData,
}): JSX.Element => (
  <>
    <SingleLocationForm
      id={id}
      disabled={disabled}
      preview={preview}
      openRightPanel={openRightPanel}
    />
    <SingleWorkHoursForm id={id} disabled={disabled} preview={preview} />
    <SingleTechnicalManagersITSForm
      id={id}
      disabled={disabled}
      isAddMode={isAddMode}
      addressId={addressId}
      preview={preview}
    />
    <SingleUsersForm
      id={id}
      disabled={disabled}
      preview={preview}
      addressId={addressId}
      companyData={companyData}
    />
  </>
);

export default SingleLocationMainForm;
