import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useTranslation } from 'react-i18next';
import FormErrorMessage from 'components/FormErrorMessage';
import { tDropdownOption, tError } from 'types/global';
import { useFormErrorsReset } from 'hooks';
import { Icon, Label } from 'components';
import { colorRedBasic, colorGreyBasic } from 'styles/GlobalStyles';
import {
  DropdownContainer,
  customTheme,
  MessageWrapper,
  Message,
  InnerDropdownContainer,
  Wrapper,
  PreviewText,
} from './Dropdown.style';

type tOnChange = tDropdownOption & {
  type: string;
};

export type tDropdown = {
  options: tDropdownOption[];
  onChange: (val: tOnChange) => void;
  value?: any | null;
  menuPlacement?: 'top' | 'bottom';
  name: string;
  errors: tError[];
  disabled?: boolean;
  preview?: boolean;
  label?: string;
  wrapperStyle?: object;
  addNullOptions?: boolean;
  labelMinWidth?: number;
  labelOptional?: boolean;
  isCreatable?: boolean;
  menuPortalTarget?: HTMLElement | null;
  styles?: { [key: string]: (base) => React.CSSProperties };
};

const Dropdown: React.FC<tDropdown> = ({
  options,
  onChange,
  value,
  menuPlacement = 'bottom',
  name,
  errors,
  disabled,
  preview,
  label,
  wrapperStyle,
  addNullOptions = false,
  labelMinWidth = 90,
  labelOptional = false,
  isCreatable = false,
  menuPortalTarget,
  styles,
}): JSX.Element => {
  const { t } = useTranslation();
  const { formElemErrors, resetFormError } = useFormErrorsReset(errors);

  const handleChange = (val: tDropdownOption) => {
    const fieldName = val?.fieldName || name;
    resetFormError(fieldName);
    onChange(
      !!val
        ? { ...val, type: 'dropdownv2' }
        : { value: '', label: '', type: 'dropdownv2', fieldName: name },
    );
  };

  const DropdownIndicator = (props) => {
    const { selectProps } = props;

    if (!selectProps.menuIsOpen) {
      return (
        <components.DropdownIndicator {...props}>
          <Icon asButton icon='arrowDown' fill={disabled ? undefined : colorGreyBasic} />
        </components.DropdownIndicator>
      );
    }
    if (selectProps.menuIsOpen && selectProps.inputValue.length > 0) {
      return (
        <components.DropdownIndicator {...props}>
          <Icon asButton icon='search' />
        </components.DropdownIndicator>
      );
    }
    return null;
  };

  const ClearIndicator = (props) => {
    const { selectProps } = props;
    if (selectProps.menuIsOpen && !selectProps.inputValue.length) {
      return (
        <components.ClearIndicator {...props}>
          <Icon asButton icon='close' fill={colorRedBasic} />
        </components.ClearIndicator>
      );
    }
    return null;
  };

  const NoDataMessage = () => (
    <MessageWrapper>
      <Icon icon='thumbDown' fill={colorGreyBasic} />
      <Message>{t('Not found')}</Message>
    </MessageWrapper>
  );

  const customStyles = {
    option: (provided: any) => ({
      ...provided,
      padding: '0.5rem 1rem;',
      color: 'var(--theme-black)',
      fontSize: '0.75rem',
    }),
    container: (provided: any) => ({
      ...provided,
      width: '100%',
    }),
    control: (provided: any) => ({
      ...provided,
      width: '100%',
      minHeight: '32px',
      minWidth: '80px',
      height: '32px',
      borderRadius: '40px',
      flexWrap: 'unset',
      padding: '0.5rem 0.25rem 0.5rem 1rem',
      fontSize: '0.75rem',
      borderColor: formElemErrors.find((error) => error.field === name)
        ? 'var(--theme-mainRed)'
        : 'var(--theme-mainGrey)',
      ':hover': {
        borderColor: 'var(--theme-mainBlue)',
      },
      ':focus': {
        borderColor: 'var(--theme-mainBlue)',
      },
      backgroundColor: disabled && 'var(--theme-lightGrey)',
      border: disabled && 'none',
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: 0,
      height: '32px',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      marginLeft: 0,
      color: disabled ? 'var(--theme-mainGrey)' : 'var(--theme-black)',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    menu: (provided: any) => ({
      ...provided,
      borderRadius: 'var(--small-padding)',
      marginTop: 'var(--theme-spacing-xs)',
      marginBottom: 0,
      overflow: 'hidden',
    }),
    menuList: (provided: any) => ({
      ...provided,
      padding: 0,
      overflow: 'auto',
      maxHeight: '200px',
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      opacity: disabled ? 0.2 : 0.8,
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: disabled ? 'var(--theme-mainGrey)' : 'var(--theme-mainDarkGrey)',
    }),
    ...styles,
  };

  const props = {
    name,
    styles: customStyles,
    onChange: (val: tDropdownOption) => handleChange(val),
    options: addNullOptions ? [{ label: t('Select'), value: null }, ...options] : [...options],
    noOptionsMessage: () => NoDataMessage(),
    placeholder: t('Select'),
    blurInputOnSelect: true,
    menuPlacement,
    components: { DropdownIndicator, ClearIndicator },
    theme: customTheme,
    errors: formElemErrors,
    isDisabled: disabled,
  };

  return (
    <DropdownContainer wrapperStyle={wrapperStyle}>
      <Wrapper>
        {label && (
          <Label optional={labelOptional} labelMinWidth={labelMinWidth}>
            {t(label)}
          </Label>
        )}
        {preview ? (
          <PreviewText>{value?.label ?? '-'}</PreviewText>
        ) : (
          <InnerDropdownContainer>
            {isCreatable ? (
              <CreatableSelect
                {...props}
                value={!!value ? [{ label: value, value, fieldName: name }] : null}
                formatCreateLabel={(userInput) => `${t('Create')} ${userInput}`}
                isCreatable
                isDisabled={disabled}
              />
            ) : (
              <Select
                {...props}
                value={value}
                isClearable
                isDisabled={disabled}
                menuPortalTarget={menuPortalTarget}
              />
            )}
          </InnerDropdownContainer>
        )}
      </Wrapper>
      <FormErrorMessage name={name} errors={formElemErrors} labelMinWidth={labelMinWidth} />
    </DropdownContainer>
  );
};

export default Dropdown;
