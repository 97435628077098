import { useTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars';
import { Icon } from 'components';
import { ClearButton } from 'styles/GlobalStyledComponents';
import { capitalizeFirst } from 'utils/helpers';

import { tFilter, tFilters } from 'types/components/table';
import { colorGreyBasic, colorWhite, colorRedBasic } from 'styles/GlobalStyles';
import { Wrapper, SingleItem, Title, WrapperForScroll } from './List.style';

type tList = {
  filters: tFilters;
  removeFilter: (filter: string, item: tFilter) => void;
  removeFilters: () => void;
};

const List: React.FC<tList> = ({ filters, removeFilter, removeFilters }) => {
  const { t } = useTranslation();
  if (filters && !!Object.keys(filters).length) {
    return (
      <Wrapper>
        <WrapperForScroll>
          <Icon icon='filterAlt' fill={colorGreyBasic} />
          <Title>{t('Filters')}</Title>
        </WrapperForScroll>
        <Scrollbars
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          renderThumbHorizontal={(props) => (
            <div {...props} style={{ backgroundColor: colorGreyBasic, width: 5, height: 5 }} />
          )}
          style={{ height: '30px', paddingBottom: '0.5rem' }}
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {Object.keys(filters).map((filter) =>
              filters[filter].map((item: tFilter) => (
                <SingleItem key={`${filter}:${item.label}`}>
                  {t(capitalizeFirst(filter))}: {item.label}
                  <ClearButton onClick={() => removeFilter(filter, item)}>
                    <Icon icon='close' fill={colorWhite} width='1rem' height='1rem' />
                  </ClearButton>
                </SingleItem>
              )),
            )}
          </div>
        </Scrollbars>
        <WrapperForScroll>
          <Icon onClick={removeFilters} asButton icon='close' fill={colorRedBasic} />
        </WrapperForScroll>
      </Wrapper>
    );
  }
  return null;
};

export default List;
