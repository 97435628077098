import styled, { css } from 'styled-components';
import { tPasswordInput, tLabel } from './PasswordInput';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex: 0 1 100%;
  margin-bottom: 1rem;
`;

export const Relative = styled.div`
  width: 100%;
  position: relative;
`;

export const InputStyle = styled.input<tPasswordInput>`
  width: 100%;
  min-height: 2rem;
  padding: 0 1rem;
  box-sizing: border-box;
  outline: none;
  border-radius: var(--theme-borderRadius-sm);
  color: var(--theme-mainDarkGrey);
  background-color: var(--theme-white);
  font-size: 1rem;
  font-weight: normal;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: var(--theme-mainDarkGrey);
    opacity: 0.6;
  }
  &:hover,
  &:focus {
    border-color: var(--theme-mainBlue);
  }
  ${(props) => {
    if (props.errors.find((error) => error.field === props.name)) {
      return css`
        border: 1px solid var(--theme-mainRed);
      `;
    }
    if (props.disabled) {
      return css`
        color: var(--theme-mainGrey);
        border: 1px solid var(--theme-borderDisabled);
        background-color: var(--theme-lightGrey);
      `;
    }
    return css`
      border: 1px solid var(--theme-mainGrey);
    `;
  }}
`;

export const EyeButton = styled.button`
  position: absolute;
  display: flex;
  justify-content: center;
  margin: 0;
  align-items: center;
  right: 5px;
  top: 4px;
  z-index: calc(var(--theme-formZIndex) + 1);
  fill: var(--theme-mainGrey);
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
`;

export const InputLabel = styled.label<tLabel>`
  font-size: 0.75rem;
  flex: auto;
  color: var(--theme-mainGrey);
  margin-right: var(--theme-spacing-md);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: ${(props) => `${props.labelMinWidth}px`};
  text-align: right;
`;

export const ErrorMessage = styled.p`
  font-size: 0.75rem;
  color: var(--theme-mainRed);
  margin: 0;
  margin-top: 0.25rem;
`;
