import { tTableHead } from 'types/components/table';

export const tableHead: tTableHead[] = [
  {
    sortable: false,
    name: 'Lp.',
    key: 'order',
  },
  {
    sortable: false,
    name: 'Manufacturer',
    key: 'manufacturer',
  },
  {
    sortable: false,
    name: 'Model',
    key: 'model',
  },
  {
    sortable: false,
    name: 'MRU point',
    key: 'location',
  },
];
