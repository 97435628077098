import users from 'services/users';
import { loginFailure, logout } from 'store/auth/actions';
import types from './types';

export const setLoadingUser = (loading: boolean) => {
  return {
    type: types.LOADING_USER,
    payload: loading,
  };
};

export const saveUser = (user) => {
  return {
    type: types.SAVE_USER,
    payload: user,
  };
};

export const getCurrentUserData = () => async (dispatch) => {
  dispatch(setLoadingUser(true));
  try {
    const currentUser = await users.getCurrent();
    dispatch(saveUser(currentUser));
  } catch (error) {
    dispatch(loginFailure('Cannot get current user data'));
    dispatch(logout());
  }
  dispatch(setLoadingUser(false));
};

export const idleTimeIncrement = () => {
  return {
    type: types.IDLE_TIME_INCREMENT,
  };
};

export const idleTimeReset = () => {
  return {
    type: types.IDLE_TIME_RESET,
  };
};

export const tokenUpdate = (kc) => async (dispatch) => {
  try {
    if (kc?.updateToken) {
      await kc.updateToken(-1);
    }
  } catch (error) {
    dispatch(logout());
  }
};
