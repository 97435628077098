import styled from 'styled-components';

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 235px;
  fill: var(--theme-mainGrey);
  color: var(--theme-mainDarkGrey);
`;

export const MonthContainer = styled(SelectContainer)`
  width: 130px;
  font-weight: bold;
`;

export const YearContainer = styled(MonthContainer)`
  width: 95px;
`;

export const DateContainer = styled(MonthContainer)`
  margin-left: 0.75rem;
  margin-bottom: 1rem;
`;

export const ButtonStyle = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
  outline: none;
  padding: 0;
  ${({ disabled }) =>
    disabled &&
    `
      cursor: context-menu;
      opacity: 0.4
    `}
  ${({ disabled }) =>
    !disabled &&
    `
      &:hover {
        fill: var(--theme-mainDarkGrey);
        opacity: 0.6;
      }
    `}
`;
