import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash.isequal';
import { Scrollbars } from 'react-custom-scrollbars';
import { tFilter } from 'types/components/table';
import { Icon, Checkbox, Button, Search, Portal } from 'components';
import { useOutsideClickAction } from 'hooks';
import { colorGreyBasic, colorBlack } from 'styles/GlobalStyles';
import {
  Wrapper,
  Container,
  ButtonsContainer,
  CloseButton,
  ClearingButton,
  Title,
} from './Filters.style';

export type tPosition = {
  position?: 'left' | 'right';
  left?: number;
  top?: number;
};

export type tOnClickOrigin = {
  left: number;
  top: number;
} | null;

type tFilters = tPosition & {
  filters: tFilter[];
  onChange: (filters: tFilter[]) => void;
  checked: tFilter[];
  clearFilters: () => void;
};

const Filters: React.FC<tFilters> = ({
  filters,
  onChange,
  checked: parentChecked,
  clearFilters,
  position = 'left',
}) => {
  const { t } = useTranslation();
  const containerRef = useRef(null);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [checked, setChecked] = useState<{ [key: string]: boolean }>({});
  const [search, setSearch] = useState<string>('');
  const [coords, setCoords] = useState<tOnClickOrigin>(null);
  const getValueFromParentChecked = () => {
    const marked = {};
    parentChecked.forEach((item: tFilter) => {
      marked[item.value] = true;
    });
    setChecked(marked);
  };

  const closeFiltersWithoutSaving = () => {
    const checkedHelper: string[] = [];
    Object.keys(checked).forEach((key) => {
      if (!!checked[key]) checkedHelper.push(key);
    });
    if (!isEqual(checkedHelper, parentChecked)) getValueFromParentChecked();
    setShowFilters(false);
  };

  const outOfFocusEventsHandler = () => {
    setShowFilters(false);
  };

  useEffect(() => {
    getValueFromParentChecked();
  }, [parentChecked]);

  useEffect(() => {
    const mainScroll = document.getElementById('main-scroll');
    if (mainScroll) {
      mainScroll.addEventListener('scroll', outOfFocusEventsHandler);
    }
    const tableScroll = document.getElementById('table-scroll');
    if (tableScroll) {
      tableScroll.addEventListener('scroll', outOfFocusEventsHandler);
    }
    window.addEventListener('resize', outOfFocusEventsHandler);
    return () => {
      if (mainScroll) {
        mainScroll.removeEventListener('scroll', outOfFocusEventsHandler);
      }
      if (tableScroll) {
        tableScroll.removeEventListener('scroll', outOfFocusEventsHandler);
      }
      window.removeEventListener('resize', outOfFocusEventsHandler);
    };
  }, []);

  const sendFilters = () => {
    const filtersHelper: tFilter[] = [];

    Object.keys(checked).forEach((key: string) => {
      if (!!checked[key]) {
        const current = filters.find(
          (filter: tFilter) => filter.value.toString() === key.toString(),
        );
        if (!!current) filtersHelper.push(current);
      }
    });
    onChange(filtersHelper);
    setShowFilters(false);
  };

  useOutsideClickAction(() => setShowFilters(false), containerRef);

  const updateTooltipCoords = (button: HTMLElement) => {
    const rect = button.getBoundingClientRect();
    setCoords({
      left: rect.x + rect.width / 2,
      top: rect.y + rect.height,
    });
  };

  const clickHandler = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    updateTooltipCoords(event.target as HTMLElement);
    setShowFilters(!showFilters);
  };

  const searchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return (
    <Wrapper>
      <Icon
        asButton
        icon='filterAlt'
        onClick={clickHandler}
        fill={showFilters || !!Object.keys(checked).length ? colorBlack : colorGreyBasic}
        width='1.25rem'
        height='1.25rem'
      />
      {showFilters && coords && (
        <Portal>
          <Container ref={containerRef} position={position} top={coords.top} left={coords.left}>
            {filters.length > 5 && (
              <div style={{ marginBottom: '1.5rem' }}>
                <Search
                  name='search'
                  value={search}
                  onChange={searchHandler}
                  resetValue={() => setSearch('')}
                />
              </div>
            )}
            {!!Object.keys(checked).length && (
              <ClearingButton onClick={clearFilters}>{t('Clear selected')}</ClearingButton>
            )}
            <Scrollbars autoHeight autoHeightMin={50} autoHeightMax={170} hideTracksWhenNotNeeded>
              {filters
                .filter((filter) => filter.label.toLowerCase().includes(search.toLowerCase()))
                .map((filter) => (
                  <div key={filter.value} style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      name={filter.label}
                      checked={!!checked[filter.value]}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setChecked({
                          ...checked,
                          [filter.value]: event.target.checked,
                        });
                      }}
                      errors={[]}
                      wrapperStyles={{ marginBottom: '0.75rem', minHeight: 'unset' }}
                    />
                    <Title>{filter.label}</Title>
                  </div>
                ))}
            </Scrollbars>
            <div style={{ marginBottom: '1rem' }} />
            <ButtonsContainer>
              <CloseButton onClick={closeFiltersWithoutSaving}>{t('Close')}</CloseButton>
              <Button variant='blue' onClick={sendFilters}>
                {t('Filter')}
              </Button>
            </ButtonsContainer>
          </Container>
        </Portal>
      )}
    </Wrapper>
  );
};

export default Filters;
