import styled, { css } from 'styled-components';

type tButtonText = {
  icon?: string;
};

type tDisabledButton = {
  disabled?: boolean;
};

export const Button = styled.button`
  outline: 0;
  padding: 0;
  margin: 0;
  background: transparent;
  cursor: pointer;
  border: 0;
  text-transform: uppercase;
  height: 32px;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: auto;
    `}
`;

export const Blue = styled(Button)<tDisabledButton>`
  background: var(--theme-gradientBlue);
  color: var(--theme-white);
  font-weight: 900;
  font-size: 0.75rem;
  font-size: 0.75rem;
  font-weight: 600;
  border-radius: 20px;
  padding: 0 2rem;
  &:hover {
    ${({ disabled }) =>
      !disabled &&
      css`
        background: var(--theme-mainBlue);
      `}
  }
  ${({ disabled }) =>
    disabled &&
    css`
      background: var(--theme-mainGrey);
    `}
`;

export const Red = styled(Blue)<tDisabledButton>`
  background: var(--theme-white);
  border: 2px solid var(--theme-mainRed);
  color: var(--theme-mainRed);
  padding: 0 2rem;
  &:hover {
    ${({ disabled }) =>
      !disabled &&
      css`
        background: var(--theme-hoverRed);
      `}
  }

  ${({ disabled }) =>
    disabled &&
    css`
      border: 2px solid var(--theme-mainGrey);
      color: var(--theme-mainGrey);
    `}
  svg {
    fill: var(--theme-mainGrey) !important;
  }
`;

export const RedFilled = styled(Blue)<tDisabledButton>`
  background: var(--theme-gradientRed);

  &:hover {
    ${({ disabled }) =>
      !disabled &&
      css`
        background: var(--theme-mainRed);
      `}
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background: var(--theme-mainGrey);
    `}
`;

export const Green = styled(Blue)<tDisabledButton>`
  background: var(--theme-gradientGreen);
  &:hover {
    ${({ disabled }) =>
      !disabled &&
      css`
        background: var(--theme-mainGreen);
      `}
  }
  ${({ disabled }) =>
    disabled &&
    css`
      background: var(--theme-mainGrey);
    `}
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    fill: var(--theme-white);
  }
`;

export const ButtonText = styled.p<tButtonText>`
  margin: 0;
  ${({ icon }) =>
    icon &&
    css`
      padding-left: 0.5rem;
    `}
`;
