import styled from 'styled-components';

export const SingleIssueWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
`;

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 12rem;
`;

export const FieldWrapper = styled.p`
  font-size: 0.75rem;
  font-weight: normal;
`;

export const LabelWrapper = styled.span`
  font-weight: bold;
`;

export const SuccessStatus = styled(LabelWrapper)`
  color: green;
`;

export const FailureStatus = styled(LabelWrapper)`
  color: red;
`;

export const ErrorWrapper = styled.p`
  font-weight: bold;
  color: red;
`;

export const MessageWrapper = styled.p`
  font-weight: bold;
  color: #ffa500;
`;
