import { iMessageCounters, tAction } from 'types/store';
import types from './types';

const initialState: iMessageCounters = {
  issues: null,
  visits: null,
  invoices: null,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, action: tAction): iMessageCounters => {
  switch (action.type) {
    case types.SAVE_COUNTERS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
