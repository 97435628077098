import { useTranslation } from 'react-i18next';
import { TableBadge } from 'components';
import { timeDiffCalc, formatDate } from 'utils/helpers';

type tAfterTheDeadline = {
  date: Date;
  now?: Date;
};

const AfterTheDeadline: React.FC<tAfterTheDeadline> = ({ date, now = new Date() }) => {
  const { t } = useTranslation();
  const diff = timeDiffCalc(date.getTime(), now.getTime());
  let copy = t(`Time limit exceeded by`);

  if (diff[0] > 0) copy += ` ${diff[0]} ${t('day', { count: +diff[0] })}`;
  if (diff[1] > 0) copy += ` ${diff[1]} ${t('hour', { count: +diff[1] })}`;

  return <TableBadge copy={copy}>{formatDate(date)}</TableBadge>;
};

export default AfterTheDeadline;
