import styled, { css } from 'styled-components';
import { tEventCardContainer } from './EventCard';

export const Element = styled.div`
  background: transparent var(--theme-gradientRed) 0% 0% no-repeat padding-box;
  border-radius: 12px;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: var(--theme-white);
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 6rem;
`;

export const Text = styled.span`
  line-height: 1;
`;

const getBorderColor = (type) => {
  switch (type) {
    case 'afterDeadline': {
      return css`
        border: solid var(--theme-mainRed);
      `;
    }
    case 'closeDate': {
      return css`
        border: solid var(--theme-mainYellow);
      `;
    }
    default: {
      return css`
        border: solid var(--theme-mainGreen);
      `;
    }
  }
};

export const EventCardContainer = styled.div<tEventCardContainer>`
  ${({ type }) => getBorderColor(type)};
  border-width: 1px 1px 1px 8px;
  border-radius: var(--theme-borderRadius);
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
  max-width: 185px;
  min-height: 90px;
`;

export const EventCardHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 9px 8px 0 5px;
`;

export const ClientName = styled.div`
  margin-left: 0.5rem;
`;

export const EventCardContent = styled.div`
  padding: 0.375rem 0;
  margin-left: 0.375rem;
  font-size: 0.75rem;
`;

export const EventCardFooter = styled.div``;

export const FooterContent = styled.div`
  margin-left: 0.375rem;
  font-size: 0.625rem;
  padding-bottom: 9px;
  font-weight: 900;
`;

export const SmallElement = styled(Element)`
  padding: 0;
  margin-left: 6px;
  display: flex;
  align-items: center;
`;

export const SmallText = styled(Text)`
  font-size: 0.625rem;
  padding: 2px 4px 2px 8px;
`;
