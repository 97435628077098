import styled, { css } from 'styled-components';
import Icon from 'components/Icon';
import { tInputContainer, tOffset } from './DateTimepicker';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex: 0 1 100%;
  margin-bottom: 1rem;
`;

export const IconStyle = styled(Icon)`
  fill: var(--theme-mainBlue);
`;

export const ErrorIconStyle = styled(Icon)`
  fill: var(--theme-mainRed);
`;

export const DisabledIconStyle = styled(Icon)`
  fill: var(--theme-mainGrey);
`;

export const PositioningContainer = styled.div`
  display: inline-block;
`;

export const InputContainer = styled.div<tInputContainer>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: var(--theme-transparent60DarkGrey);
  font-size: 0.75rem;
  letter-spacing: 0.04rem;
  cursor: default;
  ${({ error }) =>
    error &&
    css`
      color: var(--theme-mainRed);
    `}
  ${({ selected }) =>
    selected &&
    css`
      color: var(--theme-mainDarkGrey);
    `}
  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}
`;

export const DateOutputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 32px;
  align-items: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

export const DatepickerContainer = styled.div<tOffset>`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: var(--theme-dropdownZIndex);
  margin-top: 0.25rem;
  ${({ offset }) =>
    offset &&
    css`
      margin-left: ${offset};
    `}
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: var(--theme-white);
  box-shadow: var(--theme-mainShadow);
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5rem;
  padding-top: 0;
  color: var(--theme-mainGrey);
  fill: var(--theme-mainGrey);
  font-size: 1.125rem;
  font-weight: bold;
  letter-spacing: 0.025rem;
`;

export const TitleStyle = styled.div`
  display: inline-block;
  padding-left: 1rem;
`;

export const MiddleContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--theme-mainGrey);
  border-bottom: 1px solid var(--theme-mainGrey);
`;

export const CalendarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const TimepickerContainer = styled.div`
  padding: 20px;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5rem;
  padding-left: 2rem;
  padding-top: 0;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 1.5rem;
`;

export const ButtonContainer = styled.div`
  margin-left: 1rem;
`;

export const PreviewText = styled.div`
  font-size: 0.75rem;
`;
