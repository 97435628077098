import styled from 'styled-components';
import impelLogo from 'assets/img/impel_logo.png';
import getinLogo from 'assets/img/getin_logo.png';

type tShow = {
  show: boolean;
};

export const Aside = styled.aside<tShow>`
  width: 15vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: var(--theme-containerZIndex);
  background: #fff;
  position: relative;
  box-shadow: var(--theme-mainShadow);
  height: 100vh;
  overflow: auto;
  @media screen and (max-width: 1280px) {
    width: 17vw;
  }
  @media screen and (max-width: 1099px) {
    display: ${({ show }) => (show ? 'flex' : `none`)};
    position: fixed;
    top: 0;
    right: 0;
    width: 30vw;
    height: calc(100vh);
    z-index: 1000;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;

export const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 4rem;
`;

export const UserAvatar = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;

export const UserDataContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const UserName = styled.p`
  padding-top: 1.9rem;
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0;
  text-align: center;
`;
export const UserRole = styled.p`
  padding-top: var(--small-padding);
  color: var(--theme-mainGrey);
  font-size: 0.875rem;
  text-align: center;
  margin: 0;
`;

export const GetinLogo = styled.img.attrs({
  src: getinLogo,
})`
  height: 40px;
  padding-bottom: var(--large-padding);
`;

export const ImpelLogo = styled.img.attrs({
  src: impelLogo,
})`
  height: 40px;
  padding-bottom: var(--large-padding);
`;

export const ImpelLogoBottom = styled.img.attrs({
  src: impelLogo,
})`
  width: 9rem;
`;

export const Version = styled.p`
  font-size: 0.75rem;
  line-height: 0.9325rem;
  color: var(--theme-mainGrey);
  margin: 0;
  padding: 0;
  margin-top: 0.75rem;
  margin-bottom: 1rem;
`;

export const AsideFooter = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  padding-top: 1rem;
  bottom: 0;
`;

export const MenuButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  @media screen and (min-width: 1099px) {
    display: none;
  }
`;

export const Backdrop = styled.div<tShow>`
  width: 100%;
  height: 100%;
  z-index: 999;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: ${({ show }) => (show ? 'block' : `none`)};

  @media screen and (min-width: 1099px) {
    display: none;
  }
`;
