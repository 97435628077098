import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Calendar, Modal, Pagination, Search, Table } from 'components';
import { tTable } from 'types/components/table';
import { Flex } from 'styles/GlobalStyledComponents';
import ColumnFilter from 'components/Table/ColumnFilter';
import { useDebounce } from 'hooks';
import { ComplexTableHeader } from './ComplexTable.style';

type tComplexTable = tTable & {
  onChange?: (e: any) => void;
  resetValue?: () => void;
  setParams: (offset: number, limit: number) => void;
  tableName: string;
  value?: string;
  name?: string;
  totalRecords: number;
  limit: number;
  offset: number;
  allowToExportData?: boolean;
  exportButtonDisabled?: boolean;
  exportData?: Function;
  isDataDownloading?: boolean;
  columnFilter?: boolean;
  more?: JSX.Element | null;
  filtersKey?: string;
  showSearch?: boolean;
  overflowStyles?: { [key: string]: string };
  onDownloadRowDataClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const ComplexTable: React.FC<tComplexTable> = ({
  isRowClickable,
  onRowClick,
  onChange = () => {},
  resetValue = () => {},
  tableBodyData,
  tableHead,
  tableRowActions = [],
  setParams,
  setSortParams = () => {},
  tableName,
  value = '',
  name = 'tableSearch',
  totalRecords,
  limit,
  offset,
  columnFilter,
  setFilters = () => {},
  allowToExportData = false,
  exportData = () => {},
  isDataDownloading = false,
  more = null,
  exportButtonDisabled = false,
  filtersKey = '',
  showSearch = true,
  overflowStyles = {},
  onDownloadRowDataClick,
}) => {
  const setMonth = (date: Date, months: number): Date =>
    new Date(date.getFullYear(), date.getMonth() + months, 1);
  const { t } = useTranslation();
  const [columnFilters, setColumnFilters] = useState({});
  const [sortedTableHead, setSortedTableHead] = useState(tableHead);
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search);

  useEffect(() => {
    setSortedTableHead(tableHead);
  }, [tableHead]);

  const filterColumns = (checkedColumns) => {
    setColumnFilters(checkedColumns);
    const filteredColumnData = tableHead.filter((column) => !!checkedColumns[column.key]);
    setSortedTableHead(filteredColumnData);
  };

  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [toggle, setToggle] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [primaryFocus, setPrimaryFocus] = useState<Date>(new Date());
  const [secondaryFocus, setSecondaryFocus] = useState<Date>(setMonth(new Date(), 1));

  const primaryCalendarHandler = (date: Date): void => {
    if (date.getTime() < startDate.getTime()) {
      setStartDate(date);
      setEndDate(date);
      setToggle(true);
    } else if (!toggle) {
      setStartDate(date);
      setEndDate(date);
      setToggle(true);
    } else {
      setEndDate(date);
      setToggle(false);
    }
  };

  const secondaryCalendarHandler = (date: Date): void => {
    if (date.getTime() <= startDate.getTime()) {
      setStartDate(date);
    }

    setEndDate(date);
  };

  const setPrimaryFocusHandler = (date: Date): void => {
    setPrimaryFocus(date);

    if (date.getTime() >= secondaryFocus.getTime()) {
      setSecondaryFocus(setMonth(date, 1));
    }
  };

  const setSecondaryFocusHandler = (date: Date): void => {
    setSecondaryFocus(setMonth(date, 0));

    if (date.getTime() <= primaryFocus.getTime()) {
      setPrimaryFocus(setMonth(date, -1));
    }
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e;
    setSearch(value);
  };

  useEffect(() => {
    setSearch(value);
  }, [value]);

  useEffect(() => {
    onChange({ target: { value: debouncedSearch } });
  }, [debouncedSearch]);

  return (
    <>
      <ComplexTableHeader>
        <div>
          <Flex>
            {columnFilter && (
              <div style={{ paddingRight: '0.75rem' }}>
                <ColumnFilter
                  filters={tableHead}
                  onClick={filterColumns}
                  actualColumnFilters={columnFilters}
                />
              </div>
            )}
            <h4>{t(tableName)}</h4>
          </Flex>
          {more}
        </div>
        <Flex>
          {showSearch && (
            <Search name={name} value={search} onChange={onChangeHandler} resetValue={resetValue} />
          )}
          {allowToExportData && (
            <div style={{ marginLeft: '2rem' }}>
              <Button
                disabled={exportButtonDisabled}
                variant='blue'
                type='button'
                onClick={() => exportData()}
              >
                <span style={{ fontWeight: 'normal' }}>
                  {isDataDownloading ? `${t('Downloading')}...` : t('Export to XLS')}
                </span>
              </Button>
            </div>
          )}
        </Flex>
      </ComplexTableHeader>
      <Table
        isRowClickable={isRowClickable}
        onRowClick={onRowClick}
        tableBodyData={tableBodyData}
        tableHead={sortedTableHead}
        tableRowActions={tableRowActions}
        setSortParams={setSortParams}
        setFilters={setFilters}
        columnFilters={columnFilters}
        filtersKey={filtersKey}
        overflowStyles={overflowStyles}
        onDownloadRowDataClick={onDownloadRowDataClick}
      />
      <Pagination totalRecords={totalRecords} setParams={setParams} limit={limit} offset={offset} />
      {showCalendar && (
        <Modal
          title={t('Export to XLS')}
          cancelButtonText={t('Close')}
          confirmButtonText={t('Download')}
          icon='download'
          cancelMethod={() => setShowCalendar(false)}
          confirmMethod={async () => {
            await exportData(startDate, endDate);
            setShowCalendar(false);
          }}
        >
          <Flex>
            <Calendar
              variant='range'
              startDate={startDate}
              endDate={endDate}
              focusDate={primaryFocus}
              setDate={primaryCalendarHandler}
              setFocusDate={setPrimaryFocusHandler}
            />
            <Calendar
              variant='range'
              startDate={startDate}
              endDate={endDate}
              focusDate={secondaryFocus}
              setDate={secondaryCalendarHandler}
              setFocusDate={setSecondaryFocusHandler}
            />
          </Flex>
        </Modal>
      )}
    </>
  );
};

export default ComplexTable;
