import { useState, useCallback, useEffect } from 'react';
import { FormWrapper } from 'styles/GlobalStyledComponents';
import { useDispatch } from 'react-redux';
import { addNotification } from 'store/notifications/actions';
import { withRouter, RouteComponentProps } from 'react-router';
import { useFetch } from 'hooks';
import { sortArrayById } from 'utils/helpers';
import {
  ExpansionPanel,
  DropdownWithCheck,
  Column,
  Row,
  TreeView,
  EditElement,
  Spinner,
} from 'components';
import structureService, { tGetStructureParams } from 'services/structures';
import mruService from 'services/mru';
import { tError, tDropdownOption, tUUIDName } from 'types/global';
import { tLocationNode } from 'types/services/locations';
import { tGetAllMruData } from 'types/services/mru';
import TreeViewUtils from 'utils/TreeView';
import { useTranslation } from 'react-i18next';
import { colorBlueBasic, colorRedBasic } from 'styles/GlobalStyles';
import { TreeViewWrapper } from '../../AddEditFormContracts.style';

interface iLocationForm extends RouteComponentProps<any> {
  isAddMode?: boolean;
  disabled?: boolean;
  preview?: boolean;
  openRightPanel: (value: any) => void;
  setAddressId?: (addressId: string | number | null) => void;
}

type tLocation = {
  id: string;
  name: string;
  parent: string | null;
  type: string;
};

export const prepareTree = (tree = [] as tLocation[]) => {
  const treeHelper: any = [];
  tree.forEach((location: tLocation) => {
    treeHelper.push({
      id: `location-${location.id}`,
      name: location?.name ?? '-',
      parent: location.parent,
      add: null,
      edit: null,
    });
  });

  return treeHelper;
};

const LocationForm: React.FC<iLocationForm> = ({
  isAddMode = false,
  disabled = false,
  preview = false,
  openRightPanel,
  match,
  setAddressId = () => {},
}): JSX.Element => {
  const { id } = match.params;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [tree, setTree] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [mru, setMru] = useState<tGetAllMruData[]>([]);
  const [errors, setErrors] = useState<tError[]>([]);
  const {
    fetch: setParamsHandler,
    loading: loadingData,
    data,
  } = useFetch<tLocationNode, tGetStructureParams>(
    structureService.getStructure,
    'An error occurred while getting location structure',
  );

  const [types, setTypes] = useState<tUUIDName[]>([]);

  const setApiErrorHandler = (error: string) => {
    dispatch(addNotification(t(error), 'error'));
  };

  const fetchData = useCallback(() => {
    const type = types.find((type) => type.name === 'MRUK');
    if (!id || isAddMode || !type?.id) return;
    setParamsHandler({ contractId: id, typeId: type.id });
  }, [setParamsHandler, id, types]);

  useEffect(() => {
    fetchData();
    setErrors([]);
  }, [fetchData, preview, disabled]);

  useEffect(() => {
    const get = async () => {
      try {
        setLoading(true);
        if (!isAddMode) {
          const types = await structureService.getLocationTypes();
          setTypes(types.data);
        }
      } catch (error) {
        setApiErrorHandler(error?.message ?? t('An error occurred while getting locations types'));
      } finally {
        setLoading(false);
      }
    };
    const getMru = async () => {
      try {
        setLoading(true);
        if (!isAddMode) {
          const result = await mruService.getAll({ offset: 0, limit: 10000 });
          if (!!result.data) {
            setMru(result.data?.results ?? []);
          }
        }
      } catch (error) {
        setApiErrorHandler(error?.message ?? t('An error occurred while getting MRU'));
      } finally {
        setLoading(false);
      }
    };
    get();
    getMru();
  }, [isAddMode]);

  useEffect(() => {
    setTree(sortArrayById(data));
  }, [data]);

  const deleteFromTree = async (id: string) => {
    try {
      setLoading(true);
      if (!id.includes('location-')) throw new Error('Invalid id format');
      const item = id.replace('location-', '');
      const result = await structureService.removeElement(item);

      if (!!result) {
        openRightPanel({ form: '', id: null, disabled: false });
        fetchData();
      }
    } catch (error) {
      setApiErrorHandler(error?.message ?? t('An error occurred while deleting the item'));
    } finally {
      setLoading(false);
    }
  };

  const addLocation = async (name, parentId) => {
    try {
      setLoading(true);
      const locationType = types.find((type) => type.name === 'lokalizacja');
      if (!locationType || !parentId) {
        throw new Error(t('Cannot find location type "lokalizacja"'));
      }
      if (!name) return;
      const result = await structureService.addElement(name, locationType.id, parentId);
      if (result) fetchData();
    } catch (error) {
      setApiErrorHandler(error?.message ?? t('An error occurred while adding the item'));
    } finally {
      setLoading(false);
    }
  };

  const addMru = async (addressId) => {
    try {
      setLoading(true);
      if (!addressId) throw new Error(t('Cannot read MRUK id'));
      const type = types.find((type) => type.name === 'MRUK');
      if (!type) throw new Error(t('Cannot find location type "MRUK"'));
      const result = await structureService.addMruk(type?.id ?? null, null, addressId, id);
      if (result) fetchData();
    } catch (errors) {
      if (!!errors.data) {
        return setErrors(
          errors.data.map((error) => ({
            field: 'locationMRU',
            error: error.msg,
          })),
        );
      }
      setApiErrorHandler(errors?.message ?? t('An error occurred while adding the MRUK item'));
    } finally {
      setLoading(false);
    }
  };

  const isLoading = loading || loadingData;

  return (
    <ExpansionPanel title='Location' hasIcon iconName='place'>
      <FormWrapper>
        {isLoading && <Spinner />}
        {!preview && (
          <Row>
            <Column>
              <DropdownWithCheck
                disabled={isAddMode || disabled}
                errors={errors}
                label='MRU'
                optionsData={mru.map((item: { id: number | string; MRUName: string }) => ({
                  value: item.id,
                  label: item.MRUName ?? '-',
                }))}
                optionFieldName='locationMRU'
                onChange={(value: tDropdownOption) => {
                  addMru(value?.value ?? null);
                }}
              />
            </Column>
          </Row>
        )}
        {tree.map((item) => (
          <TreeViewWrapper key={item.id}>
            <TreeView
              data={TreeViewUtils.createTreeStructure({
                items: prepareTree(item.children),
                depthActionLevel: 10,
                id: item.id,
              })}
              type='location'
              title={item?.name ?? ''}
              // eslint-disable-next-line camelcase
              extraInfo={`${item?.address?.street ?? ''} ${item?.address?.building_number ?? ''}, ${
                item?.address?.city ?? ''
              }`}
              itemsActions={
                disabled || preview
                  ? [
                      {
                        key: 'preview',
                        icon: 'visibility',
                        fill: colorBlueBasic,
                        onClick: (id: string) => {
                          setAddressId(item.address.id);
                          openRightPanel({ form: 'LocationForm', id: id.replace('location-', '') });
                        },
                      },
                    ]
                  : [
                      {
                        key: 'ia1',
                        icon: 'edit',
                        fill: colorBlueBasic,
                        onClick: (id: string) => {
                          setAddressId(item.address.id);
                          openRightPanel({ form: 'LocationForm', id: id.replace('location-', '') });
                        },
                      },
                      {
                        key: 'ia2',
                        icon: 'close',
                        fill: colorRedBasic,
                        onClick: (id: string) => {
                          deleteFromTree(id);
                        },
                      },
                    ]
              }
              topLevelActionComponent={
                disabled || preview ? null : (
                  <EditElement action={(value) => addLocation(value, item.id)}>
                    {t('Add location')}
                  </EditElement>
                )
              }
              isOpen
            />
          </TreeViewWrapper>
        ))}
      </FormWrapper>
    </ExpansionPanel>
  );
};

export default withRouter(LocationForm);
