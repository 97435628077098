import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import tStore from 'types/store';

export type tAccessCheck = {
  permissions: string[];
  redirectTo?: string;
};

const AccessCheck: React.FC<tAccessCheck> = ({ permissions, redirectTo = '/' }) => {
  const loading = useSelector((state: tStore) => state.user.loading);
  const userPermissions = useSelector((state: tStore) => state.user.permissions);

  if (!permissions.length) return null;
  const accessGranted = permissions.some((permission) => userPermissions.includes(permission));

  if (!loading && !accessGranted) return <Redirect to={redirectTo} />;
  return null;
};

export default AccessCheck;
