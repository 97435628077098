import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

let reactPlugin = null;
let appInsights = null;

const createTelemetryService = () => {
  const initialize = (instrumentationKey, browserHistory) => {
    if (!browserHistory) {
      throw new Error('Could not initialize Telemetry Service');
    }
    if (!instrumentationKey) {
      throw new Error('Instrumentation key not provided in telemetry provider!');
    }

    /* eslint-disable no-underscore-dangle */
    reactPlugin = new ReactPlugin();
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey,
        maxBatchInterval: +(window?.__env__?.MAX_BATCH_INTERVAL ?? '15000'),
        samplingPercentage: +(window?.__env__?.SAMPLING_PERCENTAGE ?? '1'),
        disableFetchTracking: false,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: {
            history: browserHistory,
          },
        },
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        distributedTracingMode: DistributedTracingModes.W3C,
      },
    });

    appInsights.loadAppInsights();
    appInsights.addTelemetryInitializer((telemetryItem) => {
      // eslint-disable-next-line no-param-reassign
      telemetryItem.tags['ai.cloud.role'] = window?.__env__?.AI_CLOUD_ROLE ?? 'not_given';
    });
  };

  return { reactPlugin, appInsights, initialize };
};

export const ai = createTelemetryService();
export const getAppInsights = () => appInsights;
