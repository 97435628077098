import { useState, useEffect, useCallback } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import { PageWrapper, Breadcrumbs, AccessCheck, Spinner } from 'components';
import { useSingleFetch } from 'hooks';
import contractsService from 'services/contracts';
import { tOpenedRightForm, tDropdownOption, tGet } from 'types/global';
import { Flex, Left, Right } from 'styles/GlobalStyledComponents';
import { ExpansionPanelWrapper, Title } from './AddEditFormContracts.style';
import { SettlementModes, SingleSettlementModeForm } from './Forms/SettlementModes';
import { ContractualPenalties, ContractualPenaltiesForm } from './Forms/ContractualPenalties';
import { ContractData } from './Forms/ContractData';
import { PeriodicActivitiesForm, SinglePeriodicActivitiesForm } from './Forms/PeriodicActivities';
import {
  TreeOfSystemsAndIndustriesForm,
  SingleTreeOfSystemsAndIndustriesForm,
} from './Forms/TreeOfSystemsAndIndustries';
import { SapStatusForm } from './Forms/SapStatus';
import { ContractTerminationForm } from './Forms/ContractTermination';
import {
  PriceListBillingActivities,
  SinglePriceListBillingActivitiesForm,
} from './Forms/PriceListBillingActivities';
import { LocationForm, SingleLocationMainForm } from './Forms/Location';
import { tSapStatusDataModel } from './Forms/SapStatus/SapStatusForm';

interface iAddEditFormContracts extends RouteComponentProps<any> {
  isAddMode?: boolean;
  disabled?: boolean;
  preview?: boolean;
}

const AddEditFormContracts: React.FC<iAddEditFormContracts> = ({
  isAddMode = false,
  disabled = false,
  preview = false,
  match,
}): JSX.Element => {
  const { id } = match.params;
  const { t } = useTranslation();
  const { id: contractId } = match.params;
  const accessPermissions = disabled || preview ? ['tunContractsRead'] : ['tunContractsWrite'];

  const [formName, setFormName] = useState<string>('');
  const [openedRightForm, setOpenedRightForm] = useState<tOpenedRightForm>({
    form: '',
    id: null,
    disabled: false,
  });
  const [addressId, setAddressId] = useState<string | number | null>(null);
  const [companyData, setCompanyData] = useState<tDropdownOption | null>(null);

  const {
    fetch: setParamsHandler,
    loading: loadingData,
    data: sapData,
  } = useSingleFetch<tSapStatusDataModel, tGet>(
    contractsService.getStatus,
    'An error occurred while getting status',
  );

  const fetchData = useCallback(() => {
    if (!!id) setParamsHandler({ id: Number(id) });
  }, [setParamsHandler, id]);

  useEffect(() => {
    fetchData();
  }, [fetchData, preview, disabled]);

  const getTitle = () => {
    if (isAddMode) return t('New contract');
    if (disabled || preview) return t('Contract view');
    return t('Edit contract');
  };

  useEffect(() => {
    setOpenedRightForm({ form: '', id: null, disabled: false });
  }, [preview, disabled]);

  const canAddOrDeleteSettlementMode = !preview && sapData.status === 'draft';

  return (
    <PageWrapper>
      {loadingData && <Spinner />}
      <AccessCheck permissions={accessPermissions} redirectTo='/contracts' />
      <Breadcrumbs aliases={[formName]} />
      <Title>{getTitle()}</Title>
      <Flex>
        <Left>
          <ExpansionPanelWrapper>
            <ContractData
              isAddMode={isAddMode}
              disabled={disabled}
              preview={preview}
              setFormName={setFormName}
              setCompanyData={setCompanyData}
            />
          </ExpansionPanelWrapper>
          <ExpansionPanelWrapper>
            <TreeOfSystemsAndIndustriesForm
              disabled={isAddMode || disabled}
              isAddMode={isAddMode}
              openRightPanel={({ form, id, disabled = false }) =>
                setOpenedRightForm({ form, id, disabled })
              }
            />
          </ExpansionPanelWrapper>
          <ExpansionPanelWrapper>
            <SettlementModes
              disabled={isAddMode || disabled}
              preview={preview}
              openRightPanel={({ form, id, disabled = false }) =>
                setOpenedRightForm({ form, id, disabled })
              }
              rightForm={openedRightForm}
              canAddOrDelete={canAddOrDeleteSettlementMode}
            />
            <PriceListBillingActivities
              isAddMode={isAddMode}
              disabled={isAddMode || disabled}
              openRightPanel={({ form, id, disabled = false }) =>
                setOpenedRightForm({ form, id, disabled })
              }
            />
          </ExpansionPanelWrapper>
          <ExpansionPanelWrapper>
            <LocationForm
              disabled={disabled}
              preview={preview}
              openRightPanel={({ form, id, disabled = false }) =>
                setOpenedRightForm({ form, id, disabled })
              }
              isAddMode={isAddMode}
              setAddressId={(id) => setAddressId(id)}
            />
          </ExpansionPanelWrapper>
          <ExpansionPanelWrapper>
            <PeriodicActivitiesForm
              id={id}
              disabled={isAddMode || disabled}
              preview={preview}
              openRightPanel={({ form, id, disabled = false }) =>
                setOpenedRightForm({ form, id, disabled })
              }
              rightForm={openedRightForm}
            />
          </ExpansionPanelWrapper>
          <ExpansionPanelWrapper>
            <ContractTerminationForm disabled={disabled || isAddMode} preview={preview} />
          </ExpansionPanelWrapper>
          <ExpansionPanelWrapper>
            <ContractualPenalties
              disabled={disabled || isAddMode}
              preview={preview}
              openRightPanel={({ form, id, disabled = false }) =>
                setOpenedRightForm({ form, id, disabled })
              }
            />
          </ExpansionPanelWrapper>
          <ExpansionPanelWrapper>
            <SapStatusForm
              isAddMode={isAddMode}
              disabled={disabled || isAddMode}
              preview={preview}
              data={sapData}
              fetchData={fetchData}
            />
          </ExpansionPanelWrapper>
        </Left>
        <Right>
          {openedRightForm.form === 'ContractualPenalties' && (
            <ContractualPenaltiesForm
              disabled={openedRightForm.disabled}
              preview={preview}
              openRightPanel={({ form, id, disabled = false }) =>
                setOpenedRightForm({ form, id, disabled })
              }
            />
          )}
          {openedRightForm.form === 'SettlementModes' && (
            <SingleSettlementModeForm
              id={openedRightForm.id}
              disabled={openedRightForm.disabled || preview}
              preview={preview}
              openRightPanel={({ form, id, disabled = false }) =>
                setOpenedRightForm({ form, id, disabled })
              }
            />
          )}
          {openedRightForm.form === 'SinglePeriodicActivitiesForm' && (
            <SinglePeriodicActivitiesForm
              activityId={openedRightForm?.id}
              contractId={id}
              disabled={openedRightForm.disabled}
              preview={preview}
              openRightPanel={({ form, id, disabled = false }) =>
                setOpenedRightForm({ form, id, disabled })
              }
            />
          )}
          {openedRightForm.form === 'TreeOfSystemsAndIndustries' && !isAddMode && (
            <SingleTreeOfSystemsAndIndustriesForm
              disabled={openedRightForm.disabled || preview}
              openRightPanel={({ form, id, disabled = false }) =>
                setOpenedRightForm({ form, id, disabled })
              }
            />
          )}
          {openedRightForm.form === 'PriceListBillingActivities' && (
            <SinglePriceListBillingActivitiesForm
              disabled={openedRightForm.disabled}
              preview={preview}
              openRightPanel={({ form, id, disabled = false }) =>
                setOpenedRightForm({ form, id, disabled })
              }
              contractId={contractId}
            />
          )}
          {openedRightForm.form === 'LocationForm' && (
            <SingleLocationMainForm
              id={openedRightForm.id as string}
              disabled={openedRightForm.disabled || preview}
              openRightPanel={({ form, id, disabled = false }) =>
                setOpenedRightForm({ form, id, disabled })
              }
              isAddMode={isAddMode}
              preview={preview}
              addressId={addressId}
              companyData={companyData}
            />
          )}
        </Right>
      </Flex>
    </PageWrapper>
  );
};

export default withRouter(AddEditFormContracts);
