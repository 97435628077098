import { useCallback, useEffect, useRef, useState } from 'react';
import { GoogleMap, useJsApiLoader, Polyline, Polygon } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/Spinner';
import { colorGreenBasic } from 'styles/GlobalStyles';
import { middleOfPoland } from 'utils/constants';
import { tLocationWithNameAndGPSLocation, tShortGPSLocation } from 'types/services/subcontractors';
import { Container, Error } from './Maps.style';
import { OverlayContainer } from './OverlayContainer';
import mapStyle from './mapStyle';
import { RemoveShapeButton } from './RemoveShapeButton';
import { RemoveShapeButtonContainer } from './RemoveShapeButton.style';

type tMap = {
  mapPath: { lat: Function; lng: Function }[] | tShortGPSLocation[];
  openedRightFormId: string | number | null;
  workingAreas: tLocationWithNameAndGPSLocation[];
  setMapPath: React.Dispatch<
    React.SetStateAction<
      | {
          lat: Function;
          lng: Function;
        }[]
      | tShortGPSLocation[]
    >
  >;
  preview?: boolean;
};

const Maps: React.FC<tMap> = ({
  mapPath,
  setMapPath,
  openedRightFormId,
  workingAreas,
  preview = false,
}): JSX.Element => {
  const { t } = useTranslation();
  const [overStartPoint, setOverStartPoint] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(true);
  const [position, setPosition] = useState<{ lat: number; lng: number }>(middleOfPoland);
  // eslint-disable-next-line no-underscore-dangle
  const googleMapsApiKey = window?.__env__?.APIMAPS ?? null;
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey,
    libraries: ['drawing'],
  });

  const polygonRef = useRef<any>(null);
  const listenersRef = useRef<any>([]);
  const [polygonPathArray, setPolygonPathArray] = useState<
    { lat: Function; lng: Function }[] | tShortGPSLocation[]
  >([]);

  useEffect(() => {
    const workingArea = workingAreas.find((area) => area.localId === openedRightFormId);
    if (workingArea?.locations) {
      setPolygonPathArray(workingArea?.locations?.slice(0, -1));
      setEdit(false);
      setMapPath(workingArea?.locations);
    } else {
      setMapPath([]);
      setEdit(true);
    }
  }, [openedRightFormId]);

  const addLatLng = (event) => {
    let localEdit = true;

    const editExistingPath = (polygonEdit = false) => {
      let nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return latLng;
        });
      if (polygonEdit) {
        setPolygonPathArray(nextPath);
        nextPath = [...nextPath, nextPath[0]];
      }
      setMapPath(nextPath);
    };

    if (overStartPoint && mapPath.length > 2 && edit) {
      if (event.vertex === 0) {
        setEdit(false);
        localEdit = false;
        setMapPath([...mapPath, event.latLng]);
        setPolygonPathArray(mapPath);
      }
    }

    if (edit && localEdit && !(event?.vertex >= 0 || event?.edge >= 0)) {
      setMapPath([...mapPath, event.latLng]);
    }

    // polygon edit
    if (polygonRef.current && !edit) {
      editExistingPath(true);
    }

    // polyline edit
    if ((event?.vertex >= 0 || event?.edge >= 0) && edit && !overStartPoint) {
      editExistingPath();
    }
  };

  const resetMapPath = () => {
    setMapPath([]);
    setEdit(true);
  };

  const showCloseAreaOverlay = (e) => {
    setPosition(e.latLng);
    if (e?.vertex === 0) setOverStartPoint(true);
  };

  const onLoad = useCallback((polygon) => {
    polygonRef.current = polygon;
    const path = polygon.getPath();
    listenersRef.current.push(
      path.addListener('set_at', addLatLng),
      path.addListener('insert_at', addLatLng),
      path.addListener('remove_at', addLatLng),
    );
  }, []);

  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  if (!googleMapsApiKey) {
    return (
      <Error>
        <h2>{t('Map cannot be loaded right now, sorry.')} #1</h2>
      </Error>
    );
  }

  if (loadError) {
    return (
      <Error>
        <h2>{t('Map cannot be loaded right now, sorry.')} #2</h2>
      </Error>
    );
  }

  return isLoaded ? (
    <Container>
      <GoogleMap
        mapContainerStyle={{
          flex: 1,
          borderRadius: 8,
          position: 'absolute',
          boxShadow: '0px 0px 8px #0000000a',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
        center={middleOfPoland}
        zoom={8}
        options={{
          styles: mapStyle,
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
          draggableCursor: !edit ? 'move' : 'crosshair',
        }}
        onClick={addLatLng}
      >
        {edit && (
          <Polyline
            draggable
            editable
            onMouseUp={addLatLng}
            path={mapPath}
            onMouseOver={showCloseAreaOverlay}
            onMouseOut={() => setOverStartPoint(false)}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{
              isClickable: false,
              strokeColor: colorGreenBasic,
              strokeOpacity: 1.0,
              strokeWeight: 3,
              fillColor: colorGreenBasic,
              fillOpacity: 0.35,
            }}
          />
        )}
        {!edit && (
          <Polygon
            draggable={!preview}
            editable={!preview}
            onMouseUp={addLatLng}
            path={polygonPathArray}
            onMouseOver={showCloseAreaOverlay}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{
              isClickable: false,
              strokeColor: colorGreenBasic,
              strokeOpacity: 1.0,
              strokeWeight: 3,
              fillColor: colorGreenBasic,
              fillOpacity: 0.35,
            }}
          />
        )}
        {!edit && !preview && (
          <RemoveShapeButtonContainer>
            <RemoveShapeButton resetMapPath={resetMapPath} />
          </RemoveShapeButtonContainer>
        )}
        <OverlayContainer
          position={position}
          mapEdit={edit}
          mapPath={mapPath}
          overStartPoint={overStartPoint}
        />
      </GoogleMap>
    </Container>
  ) : (
    <Spinner />
  );
};

export default Maps;
