import { useState, useCallback, useEffect } from 'react';
import {
  DiscardAndAcceptButtons,
  ExpansionPanel,
  TreeView,
  Checkbox,
  Spinner,
  Button,
} from 'components';
import { tIssueLocationItem } from 'types/views/issues';
import { tIssueLocationTree } from 'types/services/issues';
import { useTranslation } from 'react-i18next';
import { BorderGap, ExpansionPanelWrapper, FlexHWrapper } from 'styles/GlobalStyledComponents';
import { useSingleFetch } from 'hooks';
import structuresService, { tGetIssueLocationTreeParams } from 'services/structures';
import { ButtonsContainer, Label } from '../../AddEditFormIssues.style';

interface iIssueLocation {
  disabled: boolean | undefined;
  preview: boolean | undefined;
  openRightPanel: (data: any) => void;
  id: number | string;
  saveLocation: (locations) => void;
  saveRootLocation: (rootLocations) => void;
  singleChoose?: boolean;
  checkedLocations?: string[];
  checkedRootLocations?: string[];
}

const IssueLocation: React.FC<iIssueLocation> = ({
  disabled = false,
  preview = false,
  id,
  openRightPanel,
  saveLocation = () => {},
  saveRootLocation = () => {},
  singleChoose = false,
  checkedLocations = [],
  checkedRootLocations = [],
}): JSX.Element => {
  const { t } = useTranslation();

  const flatDataHelper: tIssueLocationItem[] = [];
  const [mainLocationId, setMainLocationId] = useState<string>('');
  const [flatData, setFlatData] = useState<tIssueLocationItem[]>([]);
  const [checked, setChecked] = useState<string[]>([]);
  const [rootsChecked, setRootsChecked] = useState<string[]>([]);

  const {
    fetch: setParamsHandler,
    loading,
    data,
  } = useSingleFetch<tIssueLocationTree[], tGetIssueLocationTreeParams>(
    structuresService.getIssueLocationTree,
    'An error occurred while getting locations',
    [],
  );

  const fetchData = useCallback(() => {
    if (!!id) setParamsHandler({ locationId: id });
  }, [setParamsHandler, id]);

  useEffect(() => {
    fetchData();
  }, [fetchData, preview, disabled]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const contain = checked.includes(e.target.name);
    if (singleChoose) {
      setChecked(contain ? [] : [e.target.name]);
    } else if (contain) {
      setChecked([...checked.filter((single) => single !== e.target.name)]);
    } else {
      setChecked([...checked, e.target.name]);
    }
  };

  const getAllChildren = (parentId) => {
    let children = flatData
      .filter((single) => single.parent === parentId)
      .map((single) => single.id);
    if (!!children.length) {
      children.forEach((childrenId) => {
        const childrenHelper = getAllChildren(childrenId);
        if (!!childrenHelper.length) children = [...children, ...childrenHelper];
      });
    }
    return children;
  };

  const renderEditElement = (item) => {
    const allChildren = getAllChildren(item.id);
    const halfCheck =
      allChildren.some((childId) => checked.includes(childId)) && !checked.includes(item.id);
    return (
      <FlexHWrapper>
        <Checkbox
          onChange={onChange}
          name={item.id}
          errors={[]}
          checked={checked.includes(item.id)}
          halfCheck={halfCheck}
        />
        <Label>{item.name}</Label>
      </FlexHWrapper>
    );
  };

  const prepareFlatData = (data: tIssueLocationItem[]) => {
    data.forEach((elem) => {
      const parent = elem.parent || elem.parent_id || null;
      const newElem = {
        ...elem,
        add: null,
        parent,
      };
      if (elem.id !== mainLocationId) flatDataHelper.push(newElem);
      if (!!elem.children.length) prepareFlatData(elem.children);
    });
    const newData = flatDataHelper.map((item, index) =>
      index === 0 ? { ...item, parent: null } : item,
    );
    setFlatData(newData);
  };

  useEffect(() => {
    if (data?.length) prepareFlatData(data);
  }, [data, checked]);

  useEffect(() => {
    if (data?.length) setMainLocationId(data[0].id);
  }, [data]);

  const dataStructure = data[0]?.children;

  useEffect(() => {
    setChecked(checkedLocations);
    setRootsChecked(checkedRootLocations);
  }, []);

  const setRootsCheckedHandler = (rootId) => {
    if (rootsChecked.includes(rootId)) {
      setRootsChecked(rootsChecked.filter((el) => el !== rootId));
    } else {
      setRootsChecked([...rootsChecked, rootId]);
    }
  };

  const checkHalfCheck = (parentId: string) => {
    const allChildren = getAllChildren(parentId);
    return allChildren.some((child) => checked.includes(child));
  };

  const sendChecked = () => {
    saveLocation(flatData.filter((single) => checked.includes(single.id)));
    saveRootLocation(
      dataStructure.filter(
        (parent) => rootsChecked.includes(parent.id) || checkHalfCheck(parent.id),
      ),
    );
    openRightPanel({ form: '', id: null });
  };

  return (
    <ExpansionPanelWrapper>
      {loading && <Spinner />}
      <ExpansionPanel
        shouldExpanded={false}
        title='Location of the issue'
        hasIcon
        iconName='location'
      >
        <>
          {dataStructure?.map((elem) => (
            <TreeView
              data={elem.children}
              edit={(item) => renderEditElement(item)}
              rootId={elem.id}
              title={elem.name}
              itemsActions={[]}
              topLevelActionComponent={null}
              type='location'
              isOpen={!!checkedLocations.length}
              isRootChecked={rootsChecked.includes(elem.id)}
              isRootHalfChecked={checkHalfCheck(elem.id)}
              setRootChecked={setRootsCheckedHandler}
            />
          ))}
          {!data.length && <p>{t('There is no defined structure at the given location')}</p>}
        </>
      </ExpansionPanel>
      <BorderGap />
      {!data.length ? (
        <ButtonsContainer>
          <Button
            disabled={!!disabled}
            kind='outlined'
            variant='red'
            onClick={() => openRightPanel({ form: '', id: null })}
          >
            {t('Close')}
          </Button>
        </ButtonsContainer>
      ) : (
        <DiscardAndAcceptButtons
          disabled={disabled}
          onAccept={sendChecked}
          onDiscard={() => openRightPanel({ form: '', id: null })}
          onDiscardCopy={t('Close')}
        />
      )}
    </ExpansionPanelWrapper>
  );
};

export default IssueLocation;
