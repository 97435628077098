import Icon from 'components/Icon';
import CustomBadge from 'components/CustomBadge/Button';
import { colorGreyBasic } from 'styles/GlobalStyles';
import { iMessageCounters } from 'types/store';

const renderIcon = (icon: string, messageCounter: number | null) => {
  if (messageCounter && messageCounter >= 0) {
    return <CustomBadge icon={icon} number={messageCounter} fill={colorGreyBasic} />;
  }
  return <Icon icon={icon} fill={colorGreyBasic} />;
};

export const getModules = (messageCounters: iMessageCounters) => [
  {
    id: 1,
    to: '/dashboard',
    label: 'Dashboard',
    icon: () => <Icon icon='home' fill={colorGreyBasic} />,
    permissions: [],
  },
  {
    id: 10,
    to: '/issues',
    label: 'Issues',
    icon: () => renderIcon('announcement', messageCounters?.issues),
    permissions: ['tunIssuesRead', 'tunIssuesWrite', 'tunSubcontractorsTechniciansWrite'],
  },
  {
    id: 18,
    to: '/issues/passes/list',
    label: 'Passes for visits',
    icon: () => renderIcon('fontDownload', messageCounters?.visits),
    permissions: ['tunIssuesAppointmentsAcceptOrReject'],
  },
  {
    id: 19,
    to: '/issues/maintenances',
    label: 'Maintenances plan',
    icon: () => <Icon icon='eventNote' fill={colorGreyBasic} />,
    permissions: ['tunSubcontractorsPeriodicInspectionsRead'],
  },
  {
    id: 12,
    to: '/schedule',
    label: 'Schedule',
    icon: () => <Icon icon='dateRange' fill={colorGreyBasic} />,
    permissions: [],
  },
  {
    id: 13,
    to: '/invoices',
    label: 'Invoices',
    icon: () => renderIcon('wallet', messageCounters?.invoices),
    permissions: ['tunInvoicesRead', 'tunInvoicesClientRead'] || ['tunSubcontractorInvoicesRead'],
  },
  {
    id: 11,
    to: '/reports',
    label: 'Reports',
    icon: () => <Icon icon='barChart' fill={colorGreyBasic} />,
    permissions: ['tunReportsTechmanagerRead', 'tunReportsRegionaladminRead'],
  },
  {
    id: 2,
    to: '/customers',
    label: 'Clients',
    icon: () => <Icon icon='assignmentInd' fill={colorGreyBasic} />,
    permissions: ['tunClientsRead', 'tunClientsWrite'],
  },
  {
    id: 14,
    to: '/subcontractors',
    label: 'Subcontractors',
    icon: () => <Icon icon='assignmentInd' fill={colorGreyBasic} />,
    permissions: ['tunSubcontractorsRead', 'tunSubcontractorsWrite'],
  },
  {
    id: 3,
    to: '/contracts',
    label: 'Contracts',
    icon: () => <Icon icon='description' fill={colorGreyBasic} />,
    permissions: ['tunContractsRead', 'tunContractsWrite'],
  },
  {
    id: 4,
    to: '/users',
    label: 'Users',
    icon: () => <Icon icon='group' fill={colorGreyBasic} />,
    permissions: ['tunUsersRead', 'tunUsersWrite'],
  },
  {
    id: 5,
    to: '/its-structure',
    label: 'ITS Structure',
    icon: () => <Icon icon='groups' fill={colorGreyBasic} />,
    permissions: ['tunEmployeesRead', 'tunEmployeesWrite'],
  },
  {
    id: 6,
    to: '/mru',
    label: 'MRU Base',
    icon: () => <Icon icon='corporateFare' fill={colorGreyBasic} />,
    permissions: ['tunAddressesRead', 'tunAddressesWrite'],
  },
  {
    id: 7,
    to: '/devices',
    label: 'Devices and Facilities',
    icon: () => <Icon icon='construction' fill={colorGreyBasic} />,
    permissions: ['tunAppliancesRead', 'tunAppliancesWrite'],
  },
  {
    id: 17,
    to: '/reports',
    label: 'Reports',
    icon: () => <Icon icon='analytics' fill={colorGreyBasic} />,
    // TO DO -> ADD REPORTS PERMISSIONS
    permissions: [],
  },
  {
    id: 15,
    to: '/workers',
    label: 'Workers',
    icon: () => <Icon icon='group' fill={colorGreyBasic} />,
    permissions: ['tunSubcontractorsTechniciansWrite', 'tunSubcontractorsTechniciansRead'],
  },
  {
    id: 16,
    to: '/subcontractors/contracts',
    label: 'Contracts',
    icon: () => <Icon icon='description' fill={colorGreyBasic} />,
    permissions: ['tunSubcontractorsContractsView'],
  },
  {
    id: 9,
    to: '/account',
    label: 'Account',
    icon: () => <Icon icon='person' fill={colorGreyBasic} />,
    permissions: [],
  },
  {
    id: 10,
    to: '/help',
    label: 'Help',
    icon: () => <Icon icon='help' fill={colorGreyBasic} />,
    roles: [],
  },
];
