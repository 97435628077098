import { colorGreyDark } from 'styles/GlobalStyles';
import styled from 'styled-components';

export const TooltipContainer = styled.div`
  background: ${colorGreyDark};
  opacity: 0.8;
  padding: 5px;
  border-radius: 4px;
  font-size: 1rem;
  margin: -35px 0px 0px 20px;
`;
