import { useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { initKeycloak } from 'store/auth/actions';
import { removeNotification } from 'store/notifications/actions';
import { Switch, Route } from 'react-router-dom';
import { Spinner, SessionMonitor, Notification } from 'components';
import Login from 'views/Login/Login';
import PrivateRoute from 'views/Login/PrivateRoute';
import { Dashboard } from 'views';
import tStore from 'types/store';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { getAppInsights } from 'services/telemetry';
import TelemetryProvider from 'utils/TelemetryProvider';

function App() {
  const dispatch = useDispatch();
  const loadingAuth = useSelector((state: tStore) => state.auth.loading, shallowEqual);
  const loadingCurrentUser = useSelector((state: tStore) => state.user.loading, shallowEqual);
  const kc = useSelector((state: tStore) => state.auth.kc, shallowEqual);
  const { notifications, position } = useSelector(
    (state: tStore) => state.notifications,
    shallowEqual,
  );

  useEffect(() => {
    dispatch(initKeycloak());
  }, []);

  const closeNotification = (id: number | string | undefined) => {
    if (id) dispatch(removeNotification(id));
  };

  const isLoading = loadingAuth || loadingCurrentUser;

  /* eslint-disable no-underscore-dangle */
  return (
    <TelemetryProvider
      instrumentationKey={window?.__env__?.INSTRUMENTATION_KEY ?? null}
      after={getAppInsights}
    >
      <ErrorBoundary>
        <>
          {isLoading && <Spinner />}
          {notifications &&
            position &&
            notifications.map((note) => (
              <Notification
                key={note.id}
                message={note.message}
                show
                type={note.type}
                position={position}
                customClose
                closeNotification={() => closeNotification(note.id)}
              />
            ))}
          <Switch>
            <Route exact path='/login'>
              <Login />
            </Route>
            <PrivateRoute authenticated={!!kc?.token} loading={loadingAuth || !!!kc}>
              <Dashboard />
              <SessionMonitor />
            </PrivateRoute>
          </Switch>
        </>
      </ErrorBoundary>
    </TelemetryProvider>
  );
}

export default App;
