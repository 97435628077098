import styled from 'styled-components';
import { InputStyle } from './Input.style';

export const DoubleInputWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const DoubleInputStyle = styled(InputStyle)`
  width: 100%;
  box-sizing: border-box;
`;

export const MiddleSign = styled.p`
  color: var(--theme-mainGrey);
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0 1rem;
`;

export const IconSufixWrapper = styled.div`
  position: absolute;
  right: 0.75rem;
  display: flex;
  align-items: center;
  width: min-width;
  min-height: 2rem;
  font-size: 0.75rem;
  color: var(--theme-mainGrey);
`;

export const IconFirstSufixWrapper = styled.div`
  position: absolute;
  left: 50%;
  display: flex;
  align-items: center;
  width: min-width;
  min-height: 2rem;
  font-size: 0.75rem;
  color: var(--theme-mainGrey);
`;
