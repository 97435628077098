import { tOption } from 'types/global';

export const getSectionData = (hasBottomBorder: boolean, terminationUnits: tOption[]) => {
  return [
    {
      sectionKey: 1,
      expansionPanel: {
        title: 'Contract termination by client',
        iconName: 'person',
        hasBottomBorder,
      },
      datepicker: {
        receivedDate: {
          name: 'clientTerminationReceivedDate',
          label: 'Termination received date',
        },
        endDate: {
          name: 'clientTerminationEndDate',
          label: 'End date',
        },
      },
      input: {
        name: 'clientTerminationPeriod',
        label: 'Termination period',
      },
      dropdown: {
        name: 'clientTerminationUnit',
        label: 'Unit',
        options: terminationUnits,
      },
    },
    {
      sectionKey: 2,
      expansionPanel: {
        title: 'Contract termination by contractor',
        iconName: 'apartment',
        hasBottomBorder: true,
      },
      datepicker: {
        receivedDate: {
          name: 'contractorTerminationReceivedDate',
          label: 'Termination received date',
        },
        endDate: {
          name: 'contractorTerminationEndDate',
          label: 'End date',
        },
      },
      input: {
        name: 'contractorTerminationPeriod',
        label: 'Termination period',
      },
      dropdown: {
        name: 'contractorTerminationUnit',
        label: 'Unit',
        options: terminationUnits,
      },
    },
  ];
};
