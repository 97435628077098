import { useTranslation } from 'react-i18next';
import { Button, ExpansionPanel, Label } from 'components';
import { Flex, FormWrapper } from 'styles/GlobalStyledComponents';

type tContractualPenalties = {
  disabled: boolean;
  preview?: boolean;
  openRightPanel: Function;
};

const ContractualPenalties: React.FC<tContractualPenalties> = ({
  disabled,
  preview = false,
  openRightPanel,
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ExpansionPanel hasBottomBorder={false} title='Contractual penalties' hasIcon iconName='gavel'>
      <FormWrapper>
        <Flex alignItems='flex-start' justifyContent='flex-start'>
          <Label>{t('Penalties list')}</Label>
          <Button
            variant='blue'
            disabled={disabled && !preview}
            onClick={() => openRightPanel({ form: 'ContractualPenalties', id: 666, disabled })}
          >
            {t('Open')}
          </Button>
        </Flex>
      </FormWrapper>
    </ExpansionPanel>
  );
};

export default ContractualPenalties;
