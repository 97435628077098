import { useEffect, useRef, useState, useCallback } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { addNotification } from 'store/notifications/actions';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { monetaryUnit, workerRoleUUID, regionalAdministratorRoleUUID } from 'utils/constants';
import {
  ExpansionPanelWrapper,
  FormWrapper,
  Flex,
  Left,
  Right,
  Relative,
  BottomMargin1Rem,
} from 'styles/GlobalStyledComponents';
import { colorBlueBasic, colorGreyBasic } from 'styles/GlobalStyles';
import { tAttachmentsToDelete } from 'types/views/contracts';
import { tIssueValues, tCommentToField } from 'types/views/issues';
import { tIdName, tUUIDName, tError, tAPIFieldError, tOpenedRightForm } from 'types/global';
import {
  DropdownV2,
  ExpansionPanel,
  Preview,
  Row,
  Column,
  Label,
  Input,
  Textarea,
  Form,
  FileUpload,
  DateTimepicker,
  Button,
  Icon,
  Spinner,
  Checkbox,
  ModHistory,
} from 'components';
import issuesService, { tGetContractAddressUsersParams, tGetIssueParams } from 'services/issues';
import contractsService from 'services/contracts';
import { tContractAddresses, tCreateIssueData } from 'types/services/issues';
import tStore from 'types/store';
import { useForm, useApplianceTree, useSingleFetch, useFetchNewIssues, useFetch } from 'hooks';
import {
  addDaysToDate,
  addHoursToDate,
  addMinutesToDate,
  toBase64,
  catchErrors,
} from 'utils/helpers';
import { validateRequiredFields } from 'utils/form';
import { tSingleModHistoryData } from 'components/ModHistory/SingleModHistory';
import { REQUIRED_FIELDS } from 'utils/requiredFormFields';
import IssueLocation from './IssueLocation';
import {
  ButtonWrapper,
  List,
  ListElement,
  IssueLocationContainer,
  ExecutionDateInput,
  ExecutionDateWrapper,
  MapIconWrapper,
  CommentContainer,
  PreviewWrapper,
} from '../../AddEditFormIssues.style';

interface iNewEditIssueForm extends RouteComponentProps<any> {
  disabled?: boolean;
  previewEditMode?: boolean;
  comments?: tCommentToField[];
  issueHistoryData?: tSingleModHistoryData[];
}

export type tAccountingMode = {
  label: string;
  value: number;
};

export type tPreviewData = { type: string; label: string; value: string | (number | string)[] };

const dataModel = {
  location: '',
  industry: '',
  system: '',
  group: '',
  appliance: '',
  shortDescription: '',
  description: '',
  attachments: null,
  budget: null,
  mode: '',
  preferredCompletionDate: null,
  clientSystemId: '',
};

const getInitialValues = (data) => {
  if (data && Object.keys(data).length) {
    const {
      shortDescription,
      description,
      industry,
      industrySystem,
      industryGroup,
      industryDevice,
      budget,
      clientSystemId,
      location,
      attachments,
      preferDateOfExecution,
      accountingMode,
    } = data;
    return {
      shortDescription,
      description,
      industry,
      system: industrySystem,
      group: industryGroup,
      appliance: industryDevice,
      mode: accountingMode,
      budget,
      clientSystemId,
      location,
      attachments,
      preferredCompletionDate: preferDateOfExecution,
    };
  }
  return {};
};

export const transformField = (field: string): string => {
  switch (field) {
    case 'short_description':
      return 'shortDescription';
    case 'appliance_industry_id':
      return 'industry';
    case 'system_id':
      return 'system';
    case 'group_id':
    case 'appliance_group':
      return 'group';
    case 'appliance_id':
      return 'appliance';
    case 'location_ids':
    case 'contract_address_id':
      return 'location';
    case 'accounting_mode_id':
    case 'accounting_mode':
      return 'mode';
    case 'preferred_fix_date':
      return 'preferredCompletionDate';
    case 'client_system_id':
      return 'clientSystemId';
    default:
      return field;
  }
};

export const isFieldEditable = (fields: string[], comments: tCommentToField[]): boolean => {
  let isEditable = false;
  fields.forEach((field) => {
    if (comments.map((com) => transformField(com?.field)).includes(field)) {
      isEditable = true;
    }
  });
  return isEditable;
};

const EXECUTION_DATE_TO_TIME = 60000;

const NewEditIssueForm: React.FC<iNewEditIssueForm> = ({
  match,
  history,
  disabled = false,
  previewEditMode = false,
  comments = [],
  issueHistoryData = [],
}): JSX.Element => {
  const { id: editedIssueId } = match.params;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector((state: tStore) => state.user);
  const permissions = useSelector((state: tStore) => state.user.permissions);
  const { id: userId } = user;

  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<tError[]>([]);
  const [accountingModeOptions, setAccountingModeOptions] = useState<tAccountingMode[]>([]);
  const [attachmentsToDelete, setAttachmentsToDelete] = useState<tAttachmentsToDelete>({});
  const [accountingMode, setAccountingMode] = useState<{
    reactionTime: string | number;
    reactionTimeUnit: string;
  }>({
    reactionTime: '',
    reactionTimeUnit: '',
  });

  const [contractId, setContractId] = useState<number | null>(null);
  const [initialValues, setInitialValues] = useState<{} | undefined>(dataModel);
  const [userContractAddresses, setUserContractAddresses] = useState<tContractAddresses[]>([]);
  const [previewData, setPreviewData] = useState<tPreviewData[]>([]);
  const [locations, setLocations] = useState<tUUIDName[]>([]);
  const [rootLocations, setRootLocations] = useState<tUUIDName[]>([]);
  const [timeLimit, setTimeLimit] = useState<string>(
    t('Calculated automatically based on the mode'),
  );
  const [reportForSomeoneChecked, setReportForSomeoneChecked] = useState<boolean>(false);
  const [openedRightForm, setOpenedRightForm] = useState<tOpenedRightForm>({
    form: '',
    id: null,
    disabled: false,
  });

  const { fetchAllIssues } = useFetchNewIssues();

  const ref = useRef(true);
  const mrukSitesRef = useRef(true);
  const pathLocation = useLocation();
  const canReportForSomeone = !!permissions.includes('tunIssuesWriteReporter');
  const canReadIssueHistory = permissions.includes('tunIssuesHistoryRead');

  const {
    fetch: setParamsHandler,
    loading: issueDataLoading,
    data: issueData,
  } = useSingleFetch<any, tGetIssueParams>(
    issuesService.get,
    'Something went wrong while fetching issue data',
  );

  const {
    fetch: fetchAddressWorkers,
    loading: addressWorkersLoading,
    data: addressWorkers,
  } = useFetch<tIdName, tGetContractAddressUsersParams>(
    issuesService.getContractAddressUsers,
    'Something went wrong while fetching data',
  );
  const {
    fetch: fetchAddressRegionalAdmins,
    loading: addressRegionalAdminsLoading,
    data: addressRegionalAdmins,
  } = useFetch<tIdName, tGetContractAddressUsersParams>(
    issuesService.getContractAddressUsers,
    'Something went wrong while fetching data',
  );

  const setNotificationHandler = (message: string, type = 'error' as 'error' | 'success') => {
    const enableTimeout = type === 'success';
    dispatch(addNotification(t(message), type, enableTimeout));
  };

  const reporters = [...addressWorkers, ...addressRegionalAdmins];
  const reportersUniqueArray = reporters.filter(
    (object, index) =>
      index === reporters.findIndex((obj) => JSON.stringify(obj) === JSON.stringify(object)),
  );

  const setResponseErrors = (setter: Function, errors: tAPIFieldError[]) => {
    setter(
      errors
        ? errors?.map((error: tAPIFieldError) => {
            const { key, msg } = error;
            return {
              field: transformField(key),
              error: msg,
            };
          })
        : [],
    );
  };

  const onSubmit = async ({
    values,
    redirectToList = true,
  }: {
    values: tIssueValues;
    redirectToList: boolean;
  }) => {
    try {
      setLoading(true);
      const {
        shortDescription,
        description,
        industry,
        system,
        group,
        appliance,
        mode,
        budget,
        preferredCompletionDate,
        clientSystemId,
        location,
        attachments,
        mainReporter,
      } = values;

      const filteredAttachments = attachments?.length
        ? attachments.filter((attachment) => attachment.fieldName === 'attachments')
        : [];

      const attachmentsData = filteredAttachments
        ? await Promise.all(
            attachments?.length
              ? filteredAttachments.map(async (contractAttachment) => {
                  const { currentFile } = contractAttachment;
                  const base64FullData = await toBase64(currentFile);
                  const base64Content = base64FullData.split('base64,')[1];
                  return {
                    filename: currentFile.name || '',
                    content: base64Content || '',
                  };
                })
              : [],
          )
        : [];

      const locationsData = locations?.length ? locations.map((elem) => elem.id) : [];
      const rootLocationsData = rootLocations?.length ? rootLocations.map((elem) => elem.id) : [];

      const data: tCreateIssueData = {
        short_description: shortDescription,
        description,
        issue_type_id: 1,
        appliance_industry_id: industry?.value || null,
        system_id: system?.value || null,
        group_id: group?.value || null,
        appliance_id: appliance?.value || null,
        accounting_mode_id: mode?.value || null,
        budget: Number(budget?.replace(',', '.')) || null,
        client_preferred_fix_date: preferredCompletionDate,
        client_system_id: clientSystemId,
        contract_address_id: location?.value || null,
        location_ids: locationsData,
        mruk_site_ids: rootLocationsData,
        attachments: attachmentsData || [],
      };

      const requiredFields = REQUIRED_FIELDS.ISSUES_FORM;
      const validationErrors = validateRequiredFields(data, requiredFields);
      if (validationErrors.length) return setResponseErrors(setErrors, validationErrors);

      const apiAction = () => {
        if (pathLocation.pathname.includes('/edit')) {
          if (issueData?.actions?.edit) {
            return issuesService.edit(editedIssueId, data, attachmentsToDelete);
          }
        }
        if (previewEditMode) {
          // eslint-disable-next-line camelcase
          if (issueData?.actions?.edit_and_send_to_tech_manager) {
            return issuesService.editAndSendToTechManager(editedIssueId, data, attachmentsToDelete);
          }
        }

        const dataToSend =
          mainReporter && reportForSomeoneChecked
            ? {
                ...data,
                reporter_id: mainReporter.id,
              }
            : data;
        return issuesService.create(dataToSend);
      };

      const response = await apiAction();

      if (response?.response?.status === 201 || response?.response?.status === 200) {
        if (redirectToList) {
          setErrors([]);
          setNotificationHandler(
            t(response?.response?.status === 201 ? 'New issue added' : 'Issue changed'),
            'success',
          );
          await fetchAllIssues();
          history.push('/issues');
        } else {
          return true;
        }
      } else {
        setNotificationHandler(t('Something went wrong. Try again'));
      }
    } catch (error) {
      const setters = { setNotificationHandler, setResponseErrors, setErrors };
      catchErrors(error, setters);
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, setValues, removeUploadedFile } = useForm({
    initialValues,
    onSubmit: (values) => onSubmit(values),
  });

  const handleAttachmentsSoftDelete = (attachment, fieldName) => {
    const file = { ...attachment, name: attachment.filename || attachment.name };
    if (!!file.id) {
      const fileToAdd = !!attachmentsToDelete[fieldName]
        ? [...attachmentsToDelete[fieldName], file.id]
        : [file.id];
      setAttachmentsToDelete({
        ...attachmentsToDelete,
        [fieldName]: fileToAdd,
      });
    }
    removeUploadedFile(file.name, fieldName);
  };

  const {
    location,
    industry,
    system,
    group,
    appliance,
    shortDescription,
    description,
    attachments,
    budget,
    mode,
    preferredCompletionDate,
    clientSystemId,
    mainReporter,
  } = values;

  const fetchReporters = useCallback(() => {
    fetchAddressWorkers({
      contractAddressId: location?.value,
      roleId: workerRoleUUID,
    });
    fetchAddressRegionalAdmins({
      contractAddressId: location?.value,
      roleId: regionalAdministratorRoleUUID,
    });
  }, [fetchAddressWorkers, location]);

  useEffect(() => {
    if (!!location?.value) fetchReporters();
  }, [fetchReporters, location]);

  const isModeChosen: boolean = !!mode && !!Object.keys(mode).length;

  const applianceTreeValues = {
    applianceIndustry: industry,
    system,
    group,
    appliance,
  };

  const {
    industryOptions,
    systemOptions,
    groupOptions,
    applianceOptions,
    loadingTree,
    fetchTree,
    applianceTreeError,
  } = useApplianceTree(applianceTreeValues);

  useEffect(() => {
    if (!!applianceTreeError && Array.isArray(applianceTreeError) && !!applianceTreeError?.length) {
      setNotificationHandler(t('Something went wrong while fetching data'));
    }
  }, [applianceTreeError]);

  useEffect(() => {
    const get = async () => {
      try {
        setLoading(true);
        if (canReportForSomeone && reportForSomeoneChecked) {
          const result = await issuesService.getContractAddressesActiveByRole({
            userId,
            roleId: regionalAdministratorRoleUUID,
          });
          return setUserContractAddresses(result?.data?.results ?? []);
        }
        const result = await issuesService.getContractAddressesActive(userId);
        setUserContractAddresses(result?.data ?? []);
      } catch (error) {
        setNotificationHandler(
          error?.message ?? t('An error occurred while getting contract addresses'),
          'error',
        );
      } finally {
        setLoading(false);
      }
    };
    get();
  }, [canReportForSomeone, reportForSomeoneChecked]);

  useEffect(() => {
    if (contractId && industry) {
      const get = async () => {
        const { id: generalIndustryId } = industry;
        try {
          setLoading(true);
          const result = await issuesService.getAccountingModesByIndustry(
            contractId,
            generalIndustryId,
          );
          const accountingModeData = result?.data?.length
            ? result.data.map((mode) => ({
                value: mode.id,
                label: mode.name,
              }))
            : [];
          setAccountingModeOptions(accountingModeData);
        } catch (error) {
          setNotificationHandler(
            error?.message ?? t('An error occurred while getting contract addresses'),
            'error',
          );
        } finally {
          setLoading(false);
        }
      };
      get();
    }
  }, [contractId, industry, mode]);

  useEffect(() => {
    if (location && contractId) fetchTree({ id: Number(contractId) });
  }, [fetchTree, location, contractId]);

  useEffect(() => {
    setValues({
      ...values,
      location: null,
      mainReporter: null,
      industry: null,
      system: null,
      group: null,
      appliance: null,
      mode: null,
    });
    setTimeLimit(t('Calculated automatically based on the mode'));
  }, [reportForSomeoneChecked]);

  useEffect(() => {
    setValues({
      ...values,
      mainReporter: null,
      industry: null,
      system: null,
      group: null,
      appliance: null,
      mode: null,
    });
    setTimeLimit(t('Calculated automatically based on the mode'));
  }, [location]);

  useEffect(() => {
    setValues({ ...values, system: null, group: null, appliance: null, mode: null });
    setTimeLimit(t('Calculated automatically based on the mode'));
  }, [industry]);

  useEffect(() => {
    setValues({ ...values, group: null, appliance: null });
  }, [system]);

  useEffect(() => {
    setValues({ ...values, appliance: null });
  }, [group]);

  useEffect(() => {
    setValues({ ...values });
  }, [appliance]);

  useEffect(() => {
    const { firstName, lastName, email, phoneNumber } = user;
    if (pathLocation.pathname.includes('/edit') && Object.keys(issueData).length) {
      const {
        reporter: {
          first_name: issueReporterFirstName,
          last_name: issueReporterLastName,
          mail,
          phone,
        },
      } = issueData;
      return setPreviewData([
        {
          type: 'string',
          label: t('Reporter'),
          value: `${issueReporterFirstName} ${issueReporterLastName}`,
        },
        { type: 'string', label: t('Contact'), value: phone.length ? phone : '-' },
        { type: 'string', label: ' ', value: mail },
      ]);
    }
    setPreviewData([
      { type: 'string', label: t('Reporter'), value: `${firstName} ${lastName}` },
      { type: 'string', label: t('Contact'), value: phoneNumber },
      { type: 'string', label: ' ', value: email },
    ]);
  }, [user, issueData]);

  useEffect(() => {
    if (issueData?.issueLocations?.length && ref.current) {
      setLocations(issueData.issueLocations);
      ref.current = false;
    } else {
      setLocations([]);
    }
  }, [location]);

  useEffect(() => {
    if (issueData?.issueMrukSites?.length && mrukSitesRef.current) {
      setRootLocations(issueData.issueMrukSites);
      mrukSitesRef.current = false;
    } else {
      setRootLocations([]);
    }
  }, [location]);

  const calculateTimeLimit = () => {
    if (isModeChosen) {
      const { reactionTime, reactionTimeUnit } = accountingMode;
      switch (reactionTimeUnit) {
        case 'days': {
          setTimeLimit(addDaysToDate(Number(reactionTime)));
          break;
        }
        case 'hours': {
          setTimeLimit(addHoursToDate(Number(reactionTime)));
          break;
        }
        case 'minutes': {
          setTimeLimit(addMinutesToDate(Number(reactionTime)));
          break;
        }
        default:
          return setTimeLimit('');
      }
    }
  };

  useEffect(() => {
    calculateTimeLimit();
  }, [mode]);

  useEffect(() => {
    const executionDateToInterval = setInterval(() => {
      calculateTimeLimit();
    }, EXECUTION_DATE_TO_TIME);
    return () => clearInterval(executionDateToInterval);
  }, [mode]);

  useEffect(() => {
    if (pathLocation.pathname.includes('/edit') || previewEditMode) {
      if (editedIssueId) {
        setParamsHandler({
          id: editedIssueId,
          fullData: true,
        });
      }
    }
  }, []);

  useEffect(() => {
    const initialValues = getInitialValues(issueData);
    setContractId(issueData?.contract?.contractId);
    setAccountingMode(issueData?.accountingModeTime);
    setInitialValues(initialValues);
    setTimeLimit(issueData?.longStopDate);
  }, [issueData]);

  const openLocationPanel = () => {
    setOpenedRightForm({ form: 'IssueLocation', id: location?.value ?? null });
  };

  const onLocationChange = (event) => {
    const { contractId, value } = event;
    setContractId(contractId);
    setOpenedRightForm({ form: '', id: null, disabled: !value });
    return handleChange(event);
  };

  const onModeChange = async (event) => {
    const { value: accountingModeId } = event;
    const { data } = await contractsService.getSingleAccountingMode(accountingModeId);
    const { reaction_time: reactionTime, reaction_time_unit: reactionTimeUnit } = data;
    setAccountingMode({ reactionTime, reactionTimeUnit });
    return handleChange(event);
  };

  const onReportForSomeoneChange = (event) => {
    setReportForSomeoneChecked(!reportForSomeoneChecked);
    return handleChange(event);
  };

  const clearValuesAndSubmit = async () => {
    const redirectToList = false;
    const success = await onSubmit({ values, redirectToList });
    if (!!success) {
      setTimeLimit(t('Calculated automatically based on the mode'));
      setValues({
        ...dataModel,
        attachments: [],
      });
      setLocations([]);
      setRootLocations([]);
    }
  };

  const renderComment = (field: string) => {
    if (comments.map((com) => transformField(com.field)).includes(field)) {
      const message = comments.find((com) => transformField(com.field) === field)?.message ?? '';
      return (
        <CommentContainer>
          <strong>{t('Comments')}: </strong>
          {message}
        </CommentContainer>
      );
    }
    return null;
  };

  const isIndustryDisabled = disabled || !location || !location?.value;
  const isSystemDisabled = isIndustryDisabled || !industry;
  const isGroupDisabled = isSystemDisabled || !system;
  const isApplianceDisabled = isGroupDisabled || !group;
  const isModeDisabled = isSystemDisabled;

  const isLoading =
    loadingTree ||
    loading ||
    issueDataLoading ||
    addressWorkersLoading ||
    addressRegionalAdminsLoading;

  const allSelectedLocations = [...rootLocations, ...locations];

  return (
    <Flex>
      {isLoading && <Spinner />}
      <Left>
        <ExpansionPanelWrapper>
          <Form onSubmit={handleSubmit}>
            <ExpansionPanel
              shouldExpanded={false}
              hasBottomBorder
              title={t('Issue')}
              hasIcon
              iconName='announcement'
            >
              <FormWrapper>
                <Row>
                  {canReportForSomeone && !previewEditMode && (
                    <Column labelMinWidth>
                      <Checkbox
                        name='reportForSomeone'
                        onChange={onReportForSomeoneChange}
                        errors={errors}
                        label='Report for someone'
                        checked={reportForSomeoneChecked}
                        disabled={disabled}
                      />
                      <BottomMargin1Rem />
                    </Column>
                  )}
                  <Column>
                    <Relative>
                      <DropdownV2
                        options={
                          userContractAddresses?.length
                            ? userContractAddresses.map((option: tContractAddresses) => ({
                                value: option.id,
                                label: option.name,
                                fieldName: 'location',
                                contractId: option.contract_id,
                              }))
                            : []
                        }
                        name='location'
                        label='Location'
                        onChange={onLocationChange}
                        errors={errors}
                        value={location}
                        disabled={disabled}
                        preview={previewEditMode && !isFieldEditable(['location'], comments)}
                      />

                      <MapIconWrapper>
                        <Icon
                          icon='map'
                          fill={!!location?.value ? colorBlueBasic : colorGreyBasic}
                          asButton
                          onClick={location?.value ? openLocationPanel : () => {}}
                          disable={previewEditMode && !isFieldEditable(['location'], comments)}
                        />
                      </MapIconWrapper>
                    </Relative>
                  </Column>
                  <Column>
                    <Flex>
                      <Label> </Label>
                      {!!allSelectedLocations?.length ? (
                        <IssueLocationContainer>
                          <List>
                            {allSelectedLocations.map((location) => (
                              <ListElement key={location.id}>{location.name}</ListElement>
                            ))}
                          </List>
                        </IssueLocationContainer>
                      ) : null}
                    </Flex>
                    {renderComment('location')}
                  </Column>
                  <Column>
                    <PreviewWrapper>
                      <Preview data={previewData} />
                    </PreviewWrapper>
                  </Column>
                  {reportForSomeoneChecked && canReportForSomeone && !previewEditMode && (
                    <Column>
                      <DropdownV2
                        options={
                          reportersUniqueArray && reportersUniqueArray?.length
                            ? reportersUniqueArray.map((user) => {
                                const { id, name } = user;
                                return {
                                  id,
                                  fieldName: 'mainReporter',
                                  label: name,
                                  value: id,
                                };
                              })
                            : []
                        }
                        name='mainReporter'
                        label='Issue on behalf'
                        onChange={handleChange}
                        errors={errors}
                        value={mainReporter}
                        disabled={!location || !location?.value}
                      />
                    </Column>
                  )}
                  <Column>
                    <PreviewWrapper>
                      <Preview
                        data={[{ type: 'string', label: t('Issue type'), value: 'Usterka' }]}
                      />
                    </PreviewWrapper>
                  </Column>
                  <Column>
                    <DropdownV2
                      options={industryOptions?.map(
                        ({ generalIndustryId, applianceIndustryId, industryName }) => ({
                          label: industryName,
                          value: applianceIndustryId,
                          id: generalIndustryId,
                          fieldName: 'industry',
                        }),
                      )}
                      name='industry'
                      label='Industry'
                      onChange={handleChange}
                      errors={errors}
                      value={industry}
                      disabled={isIndustryDisabled}
                      preview={
                        previewEditMode &&
                        !isFieldEditable(['appliance', 'industry', 'location'], comments)
                      }
                    />
                    {renderComment('industry')}
                  </Column>
                  <Column>
                    <DropdownV2
                      options={systemOptions.map((option) => ({
                        ...option,
                        fieldName: 'system',
                      }))}
                      name='system'
                      label='System'
                      onChange={handleChange}
                      errors={errors}
                      value={system}
                      disabled={isSystemDisabled}
                      preview={
                        previewEditMode &&
                        !isFieldEditable(
                          ['appliance', 'group', 'system', 'industry', 'location'],
                          comments,
                        )
                      }
                      labelOptional
                    />
                    {renderComment('system')}
                  </Column>
                  <Column>
                    <DropdownV2
                      options={groupOptions.map((option) => ({
                        ...option,
                        fieldName: 'group',
                      }))}
                      name='group'
                      label='Group'
                      onChange={handleChange}
                      errors={errors}
                      value={group}
                      disabled={isGroupDisabled}
                      preview={
                        previewEditMode &&
                        !isFieldEditable(
                          ['appliance', 'group', 'system', 'industry', 'location'],
                          comments,
                        )
                      }
                      labelOptional
                    />
                    {renderComment('group')}
                  </Column>
                  <Column>
                    <DropdownV2
                      options={applianceOptions.map((option) => ({
                        ...option,
                        fieldName: 'appliance',
                      }))}
                      name='appliance'
                      label='Device'
                      onChange={handleChange}
                      errors={errors}
                      value={appliance}
                      disabled={isApplianceDisabled}
                      preview={
                        previewEditMode &&
                        !isFieldEditable(
                          ['appliance', 'group', 'system', 'industry', 'location'],
                          comments,
                        )
                      }
                      labelOptional
                    />
                    {renderComment('appliance')}
                  </Column>
                  <Column>
                    <Input
                      name='shortDescription'
                      label='Short description'
                      onChange={handleChange}
                      errors={errors}
                      value={shortDescription}
                      labelOptional={false}
                      maxLength={30}
                      wrapperStyle={{ marginBottom: '2rem' }}
                      disabled={disabled}
                      preview={previewEditMode && !isFieldEditable(['shortDescription'], comments)}
                    />
                    {renderComment('shortDescription')}
                  </Column>
                  <Column>
                    <Textarea
                      name='description'
                      label='Description'
                      onChange={handleChange}
                      errors={errors}
                      value={description}
                      disabled={disabled}
                      preview={previewEditMode && !isFieldEditable(['description'], comments)}
                    />
                    {renderComment('description')}
                  </Column>
                  <Column>
                    <FileUpload
                      name='attachments'
                      labelOptional
                      onChange={handleChange}
                      label='Attachments'
                      errors={errors}
                      files={attachments}
                      disabled={
                        disabled || (previewEditMode && !isFieldEditable(['attachments'], comments))
                      }
                      acceptExtensions={['jpg', 'png', 'jpeg']}
                      handleSoftDelete={(attachment) =>
                        handleAttachmentsSoftDelete(attachment, 'attachments')
                      }
                    />
                    {renderComment('attachments')}
                  </Column>
                  <Column>
                    <Input
                      name='budget'
                      label='Budget'
                      onChange={handleChange}
                      errors={errors}
                      value={budget}
                      inputSufix={monetaryUnit}
                      // eslint-disable-next-line no-useless-escape
                      allowedExp={new RegExp(/^[0-9]*(\,[0-9]{0,2})?$/)}
                      labelOptional
                      disabled={disabled}
                      preview={previewEditMode && !isFieldEditable(['budget'], comments)}
                    />
                    {renderComment('budget')}
                  </Column>
                  <Column>
                    <DropdownV2
                      options={accountingModeOptions.map((option) => ({
                        ...option,
                        fieldName: 'mode',
                      }))}
                      name='mode'
                      label='Mode'
                      onChange={onModeChange}
                      errors={errors}
                      value={mode}
                      disabled={isModeDisabled}
                      preview={
                        previewEditMode &&
                        !isFieldEditable(['mode', 'industry', 'location'], comments)
                      }
                    />
                    {renderComment('mode')}
                  </Column>
                  <Column>
                    <ExecutionDateWrapper>
                      <Label>{t('Execution date to')}</Label>
                      <ExecutionDateInput
                        name='timeLimit'
                        value={timeLimit}
                        onChange={handleChange}
                        disabled
                        isModeChosen={isModeChosen}
                      />
                    </ExecutionDateWrapper>
                  </Column>
                  <Column>
                    <DateTimepicker
                      name='preferredCompletionDate'
                      label='Preferred completion date'
                      title='Preferred completion date'
                      onChange={handleChange}
                      errors={errors}
                      date={preferredCompletionDate}
                      disabled={disabled}
                      preview={
                        previewEditMode && !isFieldEditable(['preferredCompletionDate'], comments)
                      }
                      labelOptional
                      allowDelete
                    />
                    {renderComment('preferredCompletionDate')}
                  </Column>
                  <Column>
                    <Input
                      name='clientSystemId'
                      label="Order number in customer's system"
                      onChange={handleChange}
                      errors={errors}
                      value={clientSystemId}
                      labelOptional
                      disabled={disabled}
                      preview={previewEditMode && !isFieldEditable(['clientSystemId'], comments)}
                    />
                    {renderComment('clientSystemId')}
                  </Column>
                </Row>
              </FormWrapper>
            </ExpansionPanel>
            <ButtonWrapper>
              {!issueData?.actions?.edit && !previewEditMode && (
                <Button
                  variant='blue'
                  style={{ marginRight: '1rem' }}
                  onClick={clearValuesAndSubmit}
                >
                  {t('Send and add next')}
                </Button>
              )}
              <Button variant='green' type='submit'>
                {t('Send')}
              </Button>
            </ButtonWrapper>
          </Form>
        </ExpansionPanelWrapper>
        {issueHistoryData?.length && canReadIssueHistory ? (
          <ModHistory data={issueHistoryData} />
        ) : null}
      </Left>
      <Right>
        {openedRightForm.form === 'IssueLocation' && (
          <IssueLocation
            openRightPanel={({ form, id, disabled = false }) =>
              setOpenedRightForm({ form, id, disabled })
            }
            disabled={openedRightForm.disabled}
            id={openedRightForm.id as number}
            saveLocation={(checkedLocations) => setLocations(checkedLocations)}
            saveRootLocation={(checkedRootLocations) => setRootLocations(checkedRootLocations)}
            checkedLocations={locations.map((location) => location.id)}
            checkedRootLocations={rootLocations.map((location) => location.id)}
            preview={previewEditMode}
          />
        )}
      </Right>
    </Flex>
  );
};

export default withRouter(NewEditIssueForm);
