import { capitalizeFirst } from 'utils/helpers';

export const breadcrumbsLUT = [
  { path: '/', label: 'Home' },
  { path: '/login', label: 'Login' },
  { path: '/recover', label: 'Password recovery' },
  { path: '/recover/:user/:token', label: 'Password reset' },
  { path: '/dashboard', label: 'Dashboard' },
  { path: '/customers', label: 'Customers' },
  { path: '/customers/new', label: 'New customer' },
  { path: '/customers/:id', label: 'Customer' },
  { path: '/customers/:id/edit', label: 'Edit customer' },
  { path: '/contracts', label: 'Contracts' },
  { path: '/contracts/new', label: 'New contract' },
  { path: '/contracts/:id', label: 'Contract' },
  { path: '/contracts/:id/edit', label: 'Edit contract' },
  { path: '/users', label: 'Users' },
  { path: '/users/new', label: 'New user' },
  { path: '/users/:id', label: 'User' },
  { path: '/users/:id/edit', label: 'Edit user' },
  { path: '/its-structure', label: 'ITS Structure' },
  { path: '/its-structure/new', label: 'New ITS employee' },
  { path: '/its-structure/:id', label: 'ITS employee preview' },
  { path: '/its-structure/:id/edit', label: 'Edit ITS employee' },
  { path: '/mru', label: 'MRU base' },
  { path: '/mru/new', label: 'New MRU' },
  { path: '/mru/:id', label: 'MRU' },
  { path: '/mru/:id/edit', label: 'Edit MRU' },
  { path: '/devices', label: 'Devices and Facilities' },
  { path: '/devices/new', label: 'New device or facility' },
  { path: '/devices/:id', label: 'Device or facility' },
  { path: '/devices/:id/edit', label: 'Edit device or facility' },
  { path: '/account', label: 'Account' },
  { path: '/help', label: 'Help' },
  { path: '/issues', label: 'Issues' },
  { path: '/issues/new', label: 'New issue' },
  { path: '/issues/:id', label: 'Issue preview' },
  { path: '/issues/:id/edit', label: 'Edit issue' },
  { path: '/issues/visits', label: 'Passes for visits' },
  { path: '/issues/finished', label: 'Finished issues' },
  { path: '/issues/finished/:id', label: 'Finished issue' },
  { path: '/issues/finished/:id/complaint', label: 'Complaint' },
  { path: '/subcontractors', label: 'Subcontractors' },
  { path: '/subcontractors/new', label: 'New subcontractor' },
  { path: '/subcontractors/:id', label: 'Subcontractor view' },
  { path: '/subcontractors/:id/edit', label: 'Edit subcontractor' },
  { path: '/subcontractors/representatives', label: 'Representatives list' },
  { path: '/subcontractors/representatives/new', label: 'New representative' },
  { path: '/subcontractors/representatives/:id', label: 'Representative view' },
  { path: '/subcontractors/representatives/:id/edit', label: 'Representative edit' },
  { path: '/subcontractors/contracts', label: 'Contracts with subcontractors' },
  { path: '/subcontractors/contracts/new', label: 'New contract' },
  { path: '/subcontractors/contracts/:id', label: 'Contract view' },
  { path: '/subcontractors/contracts/:id/edit', label: 'Edit contract' },
  { path: '/workers', label: 'Workers list' },
  { path: '/workers/new', label: 'New worker' },
  { path: '/workers/:id', label: 'Worker' },
  { path: '/workers/:id/edit', label: 'Edit worker' },
  { path: '/issues/maintenances', label: 'Maintenances plan' },
  { path: '/issues/maintenances/new', label: 'New maintenance plan' },
  { path: '/issues/maintenances/:id/edit', label: 'Edition' },
  { path: '/issues/maintenances/:id', label: 'Maintenance view' },
];

export const crumbsFromRouter = (path: string) => {
  let correctedPath = path;
  if (correctedPath.slice(-1) === '/') {
    correctedPath = correctedPath.slice(0, -1);
  }
  const crumbsArr = correctedPath.split('/').slice(1);
  const pathForCrumb = (index) => `/${crumbsArr.slice(0, index + 1).join('/')}`;

  return crumbsArr.map((crumb, index) => {
    const subPath = pathForCrumb(index);
    const crumbAlias = breadcrumbsLUT.find((route) => route.path === subPath);

    if (crumbAlias) return { path: subPath, label: crumbAlias.label };
    return { path: subPath, label: capitalizeFirst(crumb) };
  });
};
