import { useTranslation } from 'react-i18next';
import { FormErrorMessage, Label } from 'components';
import { tError } from 'types/global';
import { useFormErrorsReset } from 'hooks';
import {
  InputStyle,
  Container,
  Title,
  Wrapper,
  CheckedTextStyle,
  PreviewWrapper,
} from './Checkbox.style';

export type tWrapper = {
  wrapperStyles?: React.CSSProperties | any;
  disabled?: boolean;
};

export type tTitle = {
  titleStyles?: React.CSSProperties | any;
  round?: boolean;
  disabled?: boolean;
};

export type tContainer = {
  round?: boolean;
};

export type tCheckbox = tTitle &
  tWrapper & {
    checked?: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    name: string;
    disabled?: boolean;
    preview?: boolean;
    errors: tError[];
    label?: string;
    labelMinWidth?: number;
    halfCheck?: boolean;
  };

const Checkbox: React.FC<tCheckbox> = ({
  checked,
  onChange,
  name,
  disabled = false,
  preview = false,
  errors,
  label,
  labelMinWidth = 90,
  wrapperStyles,
  titleStyles,
  halfCheck = false,
}): JSX.Element => {
  const { t } = useTranslation();
  const { formElemErrors, resetFormError } = useFormErrorsReset(errors);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    resetFormError(event?.target?.name);
    return onChange(event);
  };

  const previewText = () => {
    if (halfCheck) return '-';
    if (checked) return t('Yes');
    return t('No');
  };

  return preview ? (
    <PreviewWrapper>
      {label && <Label labelMinWidth={labelMinWidth}>{t(label)}</Label>}
      <CheckedTextStyle>{previewText()}</CheckedTextStyle>
    </PreviewWrapper>
  ) : (
    <Wrapper wrapperStyles={wrapperStyles}>
      <Container>
        <InputStyle
          checked={!!checked}
          onChange={handleChange}
          name={name}
          disabled={disabled}
          errors={formElemErrors}
          halfCheck={halfCheck}
        />
        {label && (
          <Title disabled={disabled} titleStyles={titleStyles}>
            {t(label)}
          </Title>
        )}
      </Container>
      <FormErrorMessage name={name} errors={formElemErrors} />
    </Wrapper>
  );
};

export default Checkbox;
