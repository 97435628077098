import styled, { css } from 'styled-components';

type tSvgDown = {
  menuIsOpen: boolean;
};

type tWrapperStyle = {
  wrapperStyle?: React.CSSProperties | any;
};

export const customTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: 'var(--theme-mainBlue)',
    primary25: 'var(--theme-lightBlue)',
    primary50: 'var(--theme-mainBlue)',
    primary75: 'var(--theme-mainBlue)',
  },
});

export const DownContainer = styled.span<tSvgDown>`
  display: flex;
  svg {
    margin-bottom: unset !important;
    transform: rotate(${({ menuIsOpen }) => (menuIsOpen ? '180' : '0')}deg);
  }
`;

export const DropdownContainer = styled.div<tWrapperStyle>`
  display: flex;
  width: 100%;
  flex: 0 1 100%;
  margin-bottom: 1rem;
  flex-direction: column;
  ${({ wrapperStyle }) =>
    wrapperStyle &&
    css`
      ${wrapperStyle}
    `}
`;

export const Wrapper = styled.div`
  display: flex;
`;

export const MessageWrapper = styled.div`
  svg {
    padding-top: 2rem;
  }
`;

export const Message = styled.p`
  margin: 0;
  padding: 0;
  color: var(--theme-mainGrey);
  font-size: 0.75rem;
  padding-bottom: 1.375rem;
  padding-top: 0.875rem;
`;

export const InnerDropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const PreviewText = styled.div`
  font-size: 0.75rem;
`;
