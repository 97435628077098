import { Ul, Li, Paragraph, Error, Button, NoItemsParagraph } from './List.style';

type tAction = {
  action: React.ReactNode | JSX.Element | string;
  actionHandler?: () => void;
  tooltip?: string;
};

type tItem = {
  id: number | string | null;
  name: string;
  actions: tAction[];
  error?: boolean;
};

export type tList = {
  items: tItem[];
  noItemsCopy?: string;
};

const List: React.FC<tList> = ({ items = [], noItemsCopy = '' }): JSX.Element => {
  if (items.length === 0 && !!noItemsCopy) {
    return <NoItemsParagraph>{noItemsCopy}</NoItemsParagraph>;
  }
  return (
    <Ul>
      {items.map((item: tItem, index) => (
        <Li key={item.id || index}>
          {item?.error ? <Error>{item.name}</Error> : <Paragraph>{item.name}</Paragraph>}
          {item.actions.map((action: tAction, i: number) => (
            <Button type='button' key={`list-li-${i}`} onClick={action.actionHandler}>
              {action.action}
            </Button>
          ))}
        </Li>
      ))}
    </Ul>
  );
};

export default List;
