import { iAPIResults, iAPIData, tGetAll } from 'types/global';
import {
  tGetAllMruAPI,
  tGetMruAPI,
  tCreateMruAPI,
  tEditMruAPI,
  tRegionMruAPI,
  tCountryMruAPI,
} from 'types/api/mru';
import { fetchProvider } from 'utils/Axios';

const microservicePath = 'structures';
const mru = {
  getAll: (params: tGetAll): iAPIResults<tGetAllMruAPI> =>
    fetchProvider.get(`${microservicePath}/addresses`, { params }),
  get: (id: number | string): iAPIData<tGetMruAPI> =>
    fetchProvider.get(`${microservicePath}/addresses/${id}`),
  delete: (id: number | string): iAPIData<{}> =>
    fetchProvider.delete(`${microservicePath}/addresses/${id}`),
  getRegions: (): iAPIResults<tRegionMruAPI> => fetchProvider.get(`${microservicePath}/regions`),
  getCountries: (): iAPIResults<tCountryMruAPI> =>
    fetchProvider.get(`${microservicePath}/countries`),
  create: (data: tCreateMruAPI): iAPIData<tGetMruAPI> =>
    fetchProvider.post(`${microservicePath}/addresses`, data),
  edit: (id: number | string, data: tEditMruAPI): iAPIData<tGetMruAPI> =>
    fetchProvider.put(`${microservicePath}/addresses/${id}`, data),
};

export default mru;
