/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components';
import { workerRoleUUID, regionalAdministratorRoleUUID } from 'utils/constants';
import issuesService from 'services/issues';
import applianceTreeService from 'services/applianceTree';
import { tXLSIssueData } from './importerXLSGetin';
import {
  SingleIssueWrapper,
  StatusWrapper,
  FieldWrapper,
  LabelWrapper,
  SuccessStatus,
  FailureStatus,
  ErrorWrapper,
  MessageWrapper,
} from './SingleIssue.style';

export type tContractAddress = { id: number | string; name: string; contract_id: number };

export type tIndustryOption = { id: number; industry: { name: string; id: number } };

export type tXLSDataStatus = 'ready to send' | 'failure' | 'sent' | 'errors';

export type tCreateIssueXLSData = {
  contractAddressId: string;
  reporterId: number | string | null;
  contractId: number | string;
  industryId: number;
  modeId: number;
  shortDescription: string;
  description: string;
  clientSystemId: string;
};

export type tXLSIssueFullData = tXLSIssueData & {
  status: tXLSDataStatus;
  alreadySent: boolean;
  loading: boolean;
  dataToSend: tCreateIssueXLSData | null;
};

export type tSingleIssue = {
  data: tXLSIssueFullData;
  setData: (data: tXLSIssueFullData) => void;
  remove: (id) => void;
  setLoading: (id: number, state: boolean) => void;
  contractAddresses: tContractAddress[];
  setNotification: (error: string, type: 'error' | 'success') => void;
};

const SingleIssue: React.FC<tSingleIssue> = ({
  data,
  setData,
  remove,
  contractAddresses,
  setLoading,
  setNotification,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string>('');
  const [invalidReporter, setInvalidReporter] = useState<boolean>(false);
  const [asyncData, setAsyncData] = useState<tXLSIssueFullData>();

  const {
    id,
    status,
    contractAddress = '-',
    reporter,
    industry = '-',
    mode = '-',
    shortDescription = '-',
    description = '-',
    clientSystemId = '-',
  } = data;

  useEffect(() => {
    if (asyncData) {
      setData(asyncData);
    }
  }, [asyncData]);

  const setStatus = (status: tXLSDataStatus) => {
    if (data) {
      setAsyncData({ ...data, status });
    }
  };

  const gatherIDs = async () => {
    setError('');
    setInvalidReporter(false);
    setLoading(id, true);
    if (id && contractAddresses && !!contractAddresses.length) {
      try {
        const currentAddress =
          contractAddresses.find((el) => el?.name === data?.contractAddress) ?? null;
        const contractAddressId = currentAddress?.id ?? null;
        const contractId = currentAddress?.contract_id ?? null;
        if (contractAddressId && contractId) {
          const {
            data: { results: workersReporterOptions },
          } = await issuesService.getContractAddressUsers({
            contractAddressId,
            roleId: workerRoleUUID,
          });
          const {
            data: { results: regionalAdminsReporterOptions },
          } = await issuesService.getContractAddressUsers({
            contractAddressId,
            roleId: regionalAdministratorRoleUUID,
          });
          const reporterOptions = [...workersReporterOptions, ...regionalAdminsReporterOptions];

          const reporterId = reporterOptions.find((user) => user.name === reporter)?.id ?? null;
          if (!reporterId) {
            setInvalidReporter(true);
          }

          const treeData = (await applianceTreeService.getTree({ id: contractId })) ?? [];
          const {
            data: { appliance_industries: applianceIndustries },
          }: { data: { appliance_industries: tIndustryOption[] | undefined } } = treeData;
          const industryOptions = applianceIndustries?.map((ind) => ({
            id: ind?.id,
            name: ind?.industry?.name,
          }));

          const industryId =
            industryOptions?.find((industry) => industry?.name === data?.industry)?.id ?? null;

          const accountingModesIndustryOptions = (await applianceTreeService.getIndustries()) ?? [];
          const accountingModesIndustryId =
            accountingModesIndustryOptions.find((industry) => industry?.name === data?.industry)
              ?.id ?? null;

          if (industryId && accountingModesIndustryId) {
            const modesOptions =
              (
                await issuesService.getAccountingModesByIndustry(
                  contractId,
                  accountingModesIndustryId,
                )
              )?.data ?? [];
            const modeId = modesOptions.find((mode) => mode.name === data.mode)?.id ?? null;
            if (modeId) {
              if (data?.description && data?.shortDescription) {
                const dataObj = {
                  ...data,
                  status: 'ready to send' as tXLSDataStatus,
                  dataToSend: {
                    contractAddressId: String(contractAddressId),
                    reporterId,
                    contractId,
                    industryId,
                    modeId,
                    shortDescription: data.shortDescription,
                    description: data.description,
                    clientSystemId: data.clientSystemId ?? '',
                  },
                };
                setAsyncData(dataObj);
              } else {
                setError(t('Description and short description cannot be empty'));
                setStatus('errors');
              }
            } else {
              setError(t('Mode name has no match in the database'));
              setStatus('errors');
            }
          } else {
            setError(t('Industry name has no match in the database'));
            setStatus('errors');
          }
        } else {
          setError(t('Full entity name has no match in the database'));
          setStatus('errors');
        }
      } catch {
        setNotification(t('Something went wrong while fetching data'), 'error');
      } finally {
        setLoading(id, false);
      }
    }
  };

  useEffect(() => {
    gatherIDs();
  }, [contractAddresses]);

  const removeHandler = () => {
    remove(id);
  };

  const renderStatus = () => {
    const renderStatusText = () => {
      switch (status) {
        case 'errors':
        case 'failure':
          return <FailureStatus>{t(status)}</FailureStatus>;
        case 'sent':
          return <SuccessStatus>{t(status)}</SuccessStatus>;
        case 'ready to send':
        default:
          return t(status);
      }
    };
    return (
      <LabelWrapper>
        {`${t('Status')}: `}
        {renderStatusText()}
      </LabelWrapper>
    );
  };

  return (
    <SingleIssueWrapper>
      <div>
        <FieldWrapper>
          <LabelWrapper>{t('Location')}</LabelWrapper>
          {`: ${contractAddress}`}
        </FieldWrapper>
        <FieldWrapper>
          <LabelWrapper>{t('Reporter')}</LabelWrapper>
          {`: ${reporter ?? '-'}`}
        </FieldWrapper>
        <FieldWrapper>
          <LabelWrapper>{t('Industry')}</LabelWrapper>
          {`: ${industry}`}
        </FieldWrapper>
        <FieldWrapper>
          <LabelWrapper>{t('Mode')}</LabelWrapper>
          {`: ${mode}`}
        </FieldWrapper>
        <FieldWrapper>
          <LabelWrapper>{t(`Order number in customer's system`)}</LabelWrapper>
          {`: ${clientSystemId}`}
        </FieldWrapper>
        <FieldWrapper>
          <LabelWrapper>{t('Short description')}</LabelWrapper>
          {`: ${shortDescription}`}
        </FieldWrapper>
        <FieldWrapper>
          <LabelWrapper>{t('Description')}</LabelWrapper>
          {`: ${description}`}
        </FieldWrapper>
        {invalidReporter && (
          <MessageWrapper>
            {t('Reporter has no match in the database - report on your own behalf')}
          </MessageWrapper>
        )}
        {error && <ErrorWrapper>{error}</ErrorWrapper>}
      </div>
      <StatusWrapper>
        <FieldWrapper>{renderStatus()}</FieldWrapper>
        <Icon asButton icon='deleteBin' onClick={removeHandler} />
      </StatusWrapper>
    </SingleIssueWrapper>
  );
};

export default SingleIssue;
