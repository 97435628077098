import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Preview, Icon } from 'components';
import { tSinglePreviewItem } from 'types/views/preview';
import { colorWhite, colorGreyBasic, gradientBlue } from 'styles/GlobalStyles';
import { GapLine, Flex } from 'styles/GlobalStyledComponents';
import { Wrapper, IconWrapper, DateText, EmptyListText } from './DateList.style';

type tDateList = {
  data: any[];
  hideGapLine?: boolean;
};

const DateList: React.FC<tDateList> = ({ data, hideGapLine = false }) => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const getChevronButtonStyles = (buttonDisabled) => {
    return {
      backgroundColor: buttonDisabled ? colorGreyBasic : '',
      backgroundImage: !buttonDisabled ? gradientBlue : '',
    };
  };

  const renderItems = (items: tSinglePreviewItem[], index: number) => {
    const chevronRightDisabled = activeIndex === data?.length - 1;
    const chevronLeftDisabled = activeIndex === 0;
    const listDate = items.find((item) => item.label === 'date')?.value;
    const itemsWithoutDate = items.filter((item) => item.label !== 'date');

    return index === activeIndex ? (
      <div key={listDate}>
        <IconWrapper>
          <Flex>
            <Icon
              icon='chevronLeft'
              width='1rem'
              height='1rem'
              fill={colorWhite}
              asButton
              disable={chevronLeftDisabled}
              buttonStyles={getChevronButtonStyles(chevronLeftDisabled)}
              onClick={() => setActiveIndex((prevState) => prevState - 1)}
            />
            {listDate && <DateText>{listDate}</DateText>}
          </Flex>
          <Icon
            icon='chevronRight'
            width='1rem'
            height='1rem'
            fill={colorWhite}
            asButton
            disable={chevronRightDisabled}
            buttonStyles={getChevronButtonStyles(chevronRightDisabled)}
            onClick={() => setActiveIndex((prevState) => prevState + 1)}
          />
        </IconWrapper>
        <Preview data={itemsWithoutDate} />
        {!hideGapLine && <GapLine />}
      </div>
    ) : null;
  };

  return (
    <Wrapper>
      {data?.length ? (
        data?.map((items, index) => {
          if (!items.length) {
            return (
              <EmptyListText key={items[index]?.label || index}>
                {t('No acceptance reports')}
              </EmptyListText>
            );
          }
          return renderItems(items, index);
        })
      ) : (
        <EmptyListText>{t('No acceptance reports')}</EmptyListText>
      )}
    </Wrapper>
  );
};

export default DateList;
