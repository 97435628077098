/* eslint-disable camelcase */
import contractsAgent from 'agents/contracts';
import subcontractorsAgent from 'agents/subcontractors';
import { AxiosResponse } from 'axios';
import { calculateDate } from 'utils/helpers';
import { tContractTerminationsPost, tContractTerminationsData } from 'types/services/contracts';

export const getTerminationTypeLabel = (type: string) => {
  switch (type) {
    case 'contractor':
      return {
        value: 'contractor',
        label: 'Rozwiązywanie umowy przez wykonawcę',
      };
    case 'client':
      return {
        value: 'client',
        label: 'Rozwiązywanie umowy przez klienta',
      };
    case 'mutual':
    default:
      return {
        value: 'mutual',
        label: 'Rozwiązywanie umowy możliwe dla obu stron',
      };
  }
};

export type tGetContractTerminationParams = {
  id: number;
  isSubcontractorContract: boolean;
};

const getContractTermination = async ({
  id,
  isSubcontractorContract,
}: tGetContractTerminationParams) => {
  const response: AxiosResponse = isSubcontractorContract
    ? await subcontractorsAgent.getContractTermination(id)
    : await contractsAgent.getContractTermination(id);
  const { data } = response;

  const { type, client_condition, contractor_condition, id: terminationId } = data;

  let contractTerminationsData: Omit<tContractTerminationsData, 'terminationId'> = {
    contractTerminationOptions: getTerminationTypeLabel('mutual'),
  };

  if (!!client_condition) {
    const clientTerminationReceivedDate =
      client_condition === null || client_condition.application_date === null
        ? null
        : new Date(client_condition.application_date);
    const clientTerminationPeriod =
      client_condition === null ? null : client_condition.notice_period;

    contractTerminationsData = {
      contractTerminationOptions: getTerminationTypeLabel(type),
      clientTerminationReceivedDate,
      clientTerminationPeriod,
      clientTerminationUnit: client_condition
        ? {
            label: client_condition.unit,
            value: client_condition.unit,
          }
        : null,
      clientTerminationEndDate: calculateDate(
        clientTerminationPeriod,
        client_condition.unit,
        clientTerminationReceivedDate,
      ),
    };
  }

  if (!!contractor_condition) {
    const contractorTerminationReceivedDate =
      contractor_condition === null || contractor_condition.application_date === null
        ? null
        : new Date(contractor_condition.application_date);
    const contractorTerminationPeriod =
      contractor_condition === null ? null : contractor_condition.notice_period;

    contractTerminationsData = {
      ...contractTerminationsData,
      contractorTerminationReceivedDate,
      contractorTerminationPeriod,
      contractorTerminationUnit: contractor_condition
        ? {
            label: contractor_condition.unit,
            value: contractor_condition.unit,
          }
        : null,
      contractorTerminationEndDate: calculateDate(
        contractorTerminationPeriod,
        contractor_condition.unit,
        contractorTerminationReceivedDate,
      ),
    };
  }

  const returnData: tContractTerminationsData = {
    terminationId,
    ...contractTerminationsData,
    contractTerminationOptions: getTerminationTypeLabel(type),
  };
  return { data: returnData };
};

const createContractTermination = async (
  contractId: number,
  data: tContractTerminationsPost,
  isSubcontractorContract: boolean,
) => {
  const agent = isSubcontractorContract ? subcontractorsAgent : contractsAgent;
  const response: AxiosResponse = await agent.createContractTermination(data, contractId);
  return response.data;
};

const updateContractTermination = async (
  terminationId: number,
  data: tContractTerminationsPost,
) => {
  const response: AxiosResponse = await contractsAgent.updateContractTermination(
    data,
    terminationId,
  );
  return response.data;
};

const getContractTerminationTypes = async () => {
  const response: AxiosResponse = await contractsAgent.getContractTerminationTypes();
  const { data }: { data: string[] } = response;
  return {
    data: {
      results: data.map((item) => getTerminationTypeLabel(item)),
    },
  };
};

const getContractTerminationsUnits = async () => {
  const response: AxiosResponse = await contractsAgent.getContractTerminationsUnits();
  const { data }: { data: string[] } = response;
  return {
    data: {
      results: data.map((item) => ({
        value: item,
        label: item,
      })),
    },
  };
};

export default {
  getContractTermination,
  createContractTermination,
  updateContractTermination,
  getContractTerminationTypes,
  getContractTerminationsUnits,
};
