import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import {
  Spinner,
  PageWrapper,
  ComplexTable,
  Breadcrumbs,
  Tiles,
  ListTiles,
  ListTitle,
} from 'components';
import { useFetch } from 'hooks';
import usersService from 'services/users';
import { ListBreadcrumb, ListTableContainer } from 'styles/GlobalStyledComponents';
import { kindSubcontractor } from 'utils/constants';
import { useSelector } from 'react-redux';
import tStore from 'types/store';
import { tGetAllUsersData } from 'types/services/users';
import { tableHead, searchableFields } from './TableConfig';

const WorkersList = ({ history }): JSX.Element => {
  const { t } = useTranslation();
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [sort, setSort] = useState<{ by: string; order: string }>({ by: '', order: '' });

  const userCompanyId = useSelector((state: tStore) => state.user?.company?.id);

  const { fetch, loading, data, count } = useFetch<tGetAllUsersData>(
    usersService.getAll,
    'An error occurred while getting workers data',
  );

  const fetchData = useCallback(
    () =>
      fetch({
        search,
        searchFields: searchableFields,
        limit,
        offset,
        ordering: sort,
        kind: kindSubcontractor,
        companyId: userCompanyId,
      }),
    [search, limit, offset, sort, fetch],
  );

  const setSortParams = (field: string, sort: string) => setSort({ by: field, order: sort });

  const changeToAddNewWorkerRoute = () => history.push('/workers/new');

  const showWorkerDetails = (workerId: number | string) => history.push(`/workers/${workerId}`);

  const setRequestParams = useCallback(
    (newOffset: number, newLimit: number) => {
      if (newOffset !== offset) setOffset(newOffset);
      if (newLimit !== limit) setLimit(newLimit);
    },
    [limit, offset],
  );

  const resetValue = () => setSearch('');

  const onChange = ({ target: { value } }) => setSearch(value);

  useEffect(() => {
    setOffset(0);
  }, [search]);

  useEffect(() => {
    fetchData();
  }, [limit, offset, sort, fetchData]);

  return (
    <PageWrapper>
      {loading && <Spinner />}
      <ListBreadcrumb>
        <Breadcrumbs />
      </ListBreadcrumb>
      <ListTitle title='Workers' />
      <ListTiles>
        <Tiles
          text={t('Add new worker')}
          icon='personAdd'
          color='orange'
          variant='filled'
          onClick={changeToAddNewWorkerRoute}
        />
      </ListTiles>
      <ListTableContainer>
        <ComplexTable
          isRowClickable
          onRowClick={showWorkerDetails}
          value={search}
          resetValue={resetValue}
          onChange={onChange}
          name='workersList'
          tableName='Workers list'
          tableBodyData={data}
          tableHead={tableHead}
          setSortParams={setSortParams}
          totalRecords={count}
          setParams={setRequestParams}
          limit={limit}
          offset={offset}
        />
      </ListTableContainer>
    </PageWrapper>
  );
};

export default withRouter(WorkersList);
