/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import { tTableHead } from 'types/components/table';

export type tBasicFilter = {
  id: number | string;
  name?: string;
  first_name?: string;
  last_name?: string;
};

export type tAddressFilter = {
  id: number | string;
  building_number: string;
  city: string;
  street: string;
};

type tReporterFilter = {
  id: number | string;
  first_name: string;
  last_name: string;
};

const useCommonFilters = (
  filtersToData: { [key: string]: any } | null,
  tableHead: tTableHead[],
) => {
  const [tableColumns, setTableColumns] = useState(tableHead);

  useEffect(() => {
    if (!!filtersToData && Object.entries(filtersToData)?.length) {
      const heads = tableColumns.map((head) => {
        if (head.key === 'reporter' && !!filtersToData?.reporters?.length) {
          return {
            ...head,
            customFilters: true,
            filters: filtersToData?.reporters.map((item: tReporterFilter) => ({
              value: item.id,
              label: `${item.first_name} ${item.last_name}`,
            })),
          };
        }

        if (
          (head.key === 'address' && !!filtersToData?.addresses?.length) ||
          (head.key === 'location' && !!filtersToData?.addresses?.length)
        ) {
          return {
            ...head,
            customFilters: true,
            filters: filtersToData?.addresses.map((item: tAddressFilter) => ({
              value: item.id,
              label: `${item.street} ${item.building_number}, ${item.city}`,
            })),
          };
        }

        if (head.key === 'status' && !!filtersToData?.statuses?.length) {
          return {
            ...head,
            customFilters: true,
            filters: filtersToData?.statuses.map((item: { [key: string]: string }) => ({
              value: item,
              label: item,
            })),
          };
        }

        if (Object.keys(filtersToData).includes(head.key) && !!filtersToData[head.key]?.length) {
          return {
            ...head,
            customFilters: true,
            filters: filtersToData[head.key].map((item: tBasicFilter) => ({
              value: item.id,
              label: item.name,
            })),
          };
        }

        return head;
      });
      setTableColumns([...heads]);
    }
  }, [filtersToData]);

  return {
    tableColumns,
  };
};

export default useCommonFilters;
