import { useCallback, useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import tStore from 'types/store';
import { tGetAllByPost, tGetAllIssuesData } from 'types/services/issues';
import { useTranslation } from 'react-i18next';
import {
  Spinner,
  PageWrapper,
  ComplexTable,
  Breadcrumbs,
  Icon,
  Tiles,
  ListTiles,
  ListTitle,
  AfterTheDeadline,
  SwitchView,
  DashboardCalendar,
  TableBadge,
} from 'components';
import { useFetch, useCommonFilters } from 'hooks';
import issues from 'services/issues';
import { tTableActions, tFilters } from 'types/components/table';
import { ListBreadcrumb, ListTableContainer } from 'styles/GlobalStyledComponents';
import { afterTheDeadlineBadgeBannedStatuses, representativeRoleUUID } from 'utils/constants';
import { regularTableHead, subcontractorTableHead, techManagerTableHead } from './TableConfig';
import ImportXLS from './ImportXLS';
import { getUnreadCounters } from 'store/messageCounters/actions';

const addBadgeWhen: { [key: string]: string[] } = {
  tunIssuesTransitionsAcceptance: ['otwarte', 'nie podjęte', 'zrealizowane'], // KTITS
  tunIssuesAppointmentsAcceptOrReject: [
    'do poprawy',
    'w kontynuacji',
    'usługa niepotwierdzona',
    'w akceptacji',
  ], // AR
};

export const colorTagIssue = (
  state: string | null | undefined,
  permissions: string[] | null | undefined,
): boolean => {
  if (!permissions || !state) return false;
  let shouldAddBadge = false;
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(addBadgeWhen)) {
    if (permissions.includes(key) && value.includes(state)) {
      shouldAddBadge = true;
    }
  }
  return shouldAddBadge;
};

interface issuesList extends RouteComponentProps<any> {}

const IssuesList: React.FC<issuesList> = ({ history }): JSX.Element => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const permissions = useSelector((state: tStore) => state.user.permissions);
  const userRoles = useSelector((state: tStore) => state.user.roles);
  const isSubcontractor = userRoles.map((role) => role.id).includes(representativeRoleUUID);
  const canCreateIssue = !!permissions.includes('tunIssuesWrite');
  const canAcceptOrRejectAppointments = !!permissions.includes(
    'tunIssuesAppointmentsAcceptOrReject',
  );
  const canReadAppointments = !!permissions.includes('tunIssuesAppointmentsRead');
  const canImportIssue = !!permissions.includes('tunIssuesWriteReporter');

  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [sort, setSort] = useState<{ [key: string]: string }>({ by: '', order: '' });
  const [filters, setFilters] = useState<tFilters | null>(null);
  const [view, setView] = useState<string | number>('List');

  const {
    fetch: setParamsHandler,
    loading,
    data,
    count,
    filters: filtersToData,
  } = useFetch<tGetAllIssuesData, tGetAllByPost>(issues.getAll);

  const hasSubcontractorsPermissions = permissions.includes('tunSubcontractorsTechniciansWrite');
  const hasTechManagerPermissions = permissions.includes('tunSubcontractorsWrite');
  const hasSubcontractorRepresentativePermissions = !!permissions.includes(
    'tunSubcontractorsContractsView',
  );

  const tableHead = () => {
    if (hasSubcontractorsPermissions) {
      return subcontractorTableHead;
    }
    if (hasTechManagerPermissions) {
      return techManagerTableHead;
    }
    return regularTableHead;
  };

  const { tableColumns } = useCommonFilters(filtersToData, tableHead());

  const fetchData = useCallback(() => {
    if (filters) {
      setParamsHandler({
        limit,
        offset,
        ordering: sort,
        filters: !!filters ? filters : {},
      });
    }
  }, [limit, offset, sort, setParamsHandler, filters]);

  const setRequestParams = useCallback(
    (newOffset: number, newLimit: number) => {
      if (newOffset !== offset) setOffset(newOffset);
      if (newLimit !== limit) setLimit(newLimit);
    },
    [limit, offset],
  );

  useEffect(() => {
    setOffset(0);
  }, [filters]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    dispatch(getUnreadCounters());
  }, []);

  const showIssueDetails = (id: string) => history.push(`/issues/${id}`);

  const showIssuePasses = (id: string) => history.push(`/issues/${id}/passes`);

  const showComplaintVerification = (id: string) => history.push(`/issues/${id}/complaint`);

  const showIssueConfirmation = (id: string) => history.push(`/issues/${id}/confirmation`);

  const showCompletedIssues = () => history.push(`/issues/finished`);

  const createNewIssue = () => history.push('/issues/new');

  const tableRowActions: tTableActions[] = [];

  const convertDataToString = (dataFromService: tGetAllIssuesData[]) => {
    return dataFromService.map((item) => {
      const afterTheDeadline = !!item.longStopDate ? item.longStopDate < new Date() : false;
      const longStopDateString = !!item.longStopDate ? item.longStopDate : '-';
      const { longStopDate, SFMNumber, status, assignedAt, opened } = item;

      const canSeeDeadlineBadge = canAcceptOrRejectAppointments || canReadAppointments;
      const showDeadlineBadge =
        canSeeDeadlineBadge && !afterTheDeadlineBadgeBannedStatuses.includes(status);

      const isColorTagged = colorTagIssue(status, permissions);

      if (hasSubcontractorRepresentativePermissions) {
        return {
          ...item,
          longStopDate: longStopDateString,
          bold: !opened,
          assignedAt:
            assignedAt && assignedAt !== '-' && status === 'otwarte' ? (
              <TableBadge>{assignedAt}</TableBadge>
            ) : (
              assignedAt
            ),
        };
      }

      return {
        ...item,
        bold: !opened,
        longStopDate:
          afterTheDeadline && showDeadlineBadge ? (
            <AfterTheDeadline date={longStopDate} />
          ) : (
            longStopDateString
          ),
        SFMNumber: isColorTagged ? <TableBadge copy={status}>{SFMNumber}</TableBadge> : SFMNumber,
      };
    });
  };

  const renderMoreInTable = () => (
    <div style={{ marginTop: '1.5rem' }}>
      <SwitchView
        items={[
          { value: 'List', label: t('List'), prefix: <Icon icon='list' /> },
          {
            value: 'Calendar',
            label: t('Calendar'),
            prefix: <Icon icon='calendar' />,
            placeholder: true,
          },
        ]}
        selected={view}
        onSelect={(val) => setView(val)}
      />
    </div>
  );
  const setSortParams = (by: string, order: string) => setSort({ by, order });

  const handleRowClick = (id) => {
    const passes = data.find((el) => el.id === id)?.status === 'Do awizacji';
    const complaint = data.find((el) => el.id === id)?.type === 'Reklamacja';
    const confirmation = data.find((el) => el.id === id)?.status === 'usługa niepotwierdzona';

    if (passes) return showIssuePasses(String(id));
    if (complaint) return showComplaintVerification(String(id));
    if (confirmation && permissions.includes('tunIssuesTransitionsIssueDoneAcceptance')) {
      return showIssueConfirmation(String(id));
    }
    return showIssueDetails(String(id));
  };

  return (
    <PageWrapper>
      {loading && <Spinner />}
      <ListBreadcrumb>
        <Breadcrumbs />
      </ListBreadcrumb>
      <ListTitle title={t('Issues')} />
      <ListTiles>
        <>
          {!isSubcontractor && (
            <Tiles
              text={t(`New issue`)}
              icon='addComment'
              color='orange'
              variant='filled'
              onClick={createNewIssue}
              disabled={!canCreateIssue}
            />
          )}
          {canImportIssue && <ImportXLS updateTrigger={fetchData} />}
          <Tiles
            text={t('Finished issues')}
            icon='assignmentTurned'
            color='aquamarine'
            onClick={showCompletedIssues}
          />
        </>
      </ListTiles>
      <ListTableContainer>
        {view === 'Calendar' && (
          <>
            <h4 style={{ marginBottom: '1.5rem' }}>{t('Calendar')}</h4>
            <SwitchView
              items={[
                { value: 'List', label: t('List'), prefix: <Icon icon='list' /> },
                { value: 'Calendar', label: t('Calendar'), prefix: <Icon icon='calendar' /> },
              ]}
              selected={view}
              onSelect={(val) => setView(val)}
            />
          </>
        )}

        {view === 'List' && (
          <ComplexTable
            isRowClickable
            onRowClick={handleRowClick}
            tableBodyData={convertDataToString(data)}
            tableHead={tableColumns}
            tableRowActions={tableRowActions}
            setSortParams={setSortParams}
            totalRecords={count}
            setParams={(offset: number, limit: number) => setRequestParams(offset, limit)}
            tableName='List of current issues'
            limit={limit}
            offset={offset}
            setFilters={setFilters}
            more={renderMoreInTable()}
            filtersKey='issuesFilters'
            showSearch={false}
            overflowStyles={{ overflowX: 'scroll' }}
          />
        )}
        {view === 'Calendar' && (
          <DashboardCalendar
            setFilters={setFilters}
            filters={regularTableHead?.filter((filter) => filter.customFilters) ?? []}
            filtersKey='issuesFilters'
          />
        )}
      </ListTableContainer>
    </PageWrapper>
  );
};

export default withRouter(IssuesList);
