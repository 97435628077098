import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addNotification } from 'store/notifications/actions';
import Api from 'utils/Api';

function useSingleFetch<TData = unknown, TParams = object>(
  fetchMethod: (params) => any,
  defaultErrorMessage = '' as string | null,
  defaultData = {} as TData,
) {
  const dispatch = useDispatch();
  const [data, setData] = useState<TData>(defaultData);
  const [errors, setErrors] = useState<unknown>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetch = useCallback(
    async (params: TParams) => {
      try {
        setLoading(true);
        const result = await fetchMethod({ ...params });
        setData(result.data);
        setLoading(false);
      } catch (error) {
        const apiErrors = Api.getErrors(error);
        setErrors(apiErrors);
        if (defaultErrorMessage !== null) {
          apiErrors.forEach((error) => {
            dispatch(
              addNotification(
                defaultErrorMessage || error?.message || 'Something went wrong. Try again',
                'error',
              ),
            );
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [fetchMethod],
  );

  return { fetch, loading, data, errors };
}

export default useSingleFetch;
