import styled from 'styled-components';

export const RemoveButton = styled.button`
  background: var(--theme-white);
  border-radius: 8px;
  top: 16px;
  max-height: 32px !important;
  max-width: 182px !important;
  padding: 0.5rem 2rem 0.5rem 2rem;
  position: absolute;
  z-index: var(--theme-containerZIndex);
  border: 1px solid var(--theme-mainRed);
  display: flex;
  align-items: center;
  margin-top: 1rem;
  width: 100%;
  color: var(--theme-mainRed);
  text-align: center;
  font-size: 0.75rem;
  font-weight: 900;
  outline: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  text-transform: uppercase;
  justify-content: center;
`;

export const RemoveShapeButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
