import { fetchProvider } from 'utils/Axios';

const microservicePath = 'contracts';

const applianceTree = {
  get: (id: number | string) =>
    fetchProvider.get(`${microservicePath}/contracts/${id}/appliance_tree`),
  getIndustries: () => fetchProvider.get(`${microservicePath}/industries`),
  addIndustry: (id: number | string, industryId: number | string) =>
    fetchProvider.post(`${microservicePath}/appliance_tree/${id}/appliance_industries`, {
      industry_id: industryId,
    }),
  deleteIndustry: (id: number | string) =>
    fetchProvider.delete(`${microservicePath}/appliance_industries/${id}`),
  addSystem: (industryId: number | string, name: string) =>
    fetchProvider.post(`${microservicePath}/appliance_industries/${industryId}/systems`, { name }),
  patchSystem: (id: number | string, name: string) =>
    fetchProvider.patch(`${microservicePath}/systems/${id}`, { name }),
  deleteSystem: (id: number | string) => fetchProvider.delete(`${microservicePath}/systems/${id}`),
  addGroup: (systemId: number | string, name: string) =>
    fetchProvider.post(`${microservicePath}/systems/${systemId}/groups`, { name }),
  patchGroup: (id: number | string, name: string) =>
    fetchProvider.patch(`${microservicePath}/groups/${id}`, { name }),
  deleteGroup: (id: number | string) => fetchProvider.delete(`${microservicePath}/groups/${id}`),
  addAppliance: (groupId: number | string, name: string) =>
    fetchProvider.post(`${microservicePath}/groups/${groupId}/appliances`, { name }),
  patchAppliance: (id: number | string, name: string) =>
    fetchProvider.patch(`${microservicePath}/appliances/${id}`, { name }),
  deleteAppliance: (id: number | string) =>
    fetchProvider.delete(`${microservicePath}/appliances/${id}`),
};

export default applianceTree;
