import { tTableHead } from 'types/components/table';

export const regularTableHead: tTableHead[] = [
  {
    sortable: false,
    name: 'No.',
    key: 'order',
  },
  {
    sortable: true,
    name: 'Issue date',
    key: 'issueDate',
    minWidth: '140px',
  },
  {
    sortable: true,
    name: 'Smart FM number',
    key: 'SFMNumber',
    minWidth: '150px',
  },
  {
    sortable: true,
    name: 'Type',
    key: 'type',
  },
  {
    sortable: true,
    name: 'Mode',
    key: 'mode',
  },
  {
    sortable: true,
    name: 'Location',
    key: 'location',
    minWidth: '260px',
  },
  {
    sortable: true,
    name: 'Short description',
    key: 'shortDescription',
    minWidth: '150px',
  },
  {
    sortable: true,
    name: 'Status',
    key: 'status',
  },
  {
    sortable: true,
    name: 'Long stop date',
    key: 'longStopDate',
    minWidth: '140px',
  },
  {
    sortable: true,
    name: 'Contract',
    key: 'contract',
  },
  {
    sortable: true,
    name: 'Industry',
    key: 'industry',
    minWidth: '160px',
  },
  {
    sortable: true,
    name: 'Own number',
    key: 'ownNumber',
    minWidth: '180px',
  },
  {
    sortable: true,
    name: 'Reporter',
    key: 'reporter',
    minWidth: '140px',
  },
];

export const techManagerTableHead: tTableHead[] = [
  {
    sortable: false,
    name: 'No.',
    key: 'order',
  },
  {
    sortable: true,
    name: 'Client',
    key: 'client',
    minWidth: '120px',
  },
  {
    sortable: true,
    name: 'Issue date',
    key: 'issueDate',
    minWidth: '140px',
  },
  {
    sortable: true,
    name: 'Smart FM number',
    key: 'SFMNumber',
    minWidth: '150px',
  },
  {
    sortable: true,
    name: 'Type',
    key: 'type',
  },
  {
    sortable: true,
    name: 'Mode',
    key: 'mode',
  },
  {
    sortable: true,
    name: 'Location',
    key: 'location',
    minWidth: '260px',
  },
  {
    sortable: true,
    name: 'Short description',
    key: 'shortDescription',
    minWidth: '150px',
  },
  {
    sortable: true,
    name: 'Status',
    key: 'status',
  },
  {
    sortable: true,
    name: 'Long stop date',
    key: 'longStopDate',
    minWidth: '140px',
  },
  {
    sortable: true,
    name: 'Contract',
    key: 'contract',
  },
  {
    sortable: true,
    name: 'Industry',
    key: 'industry',
    minWidth: '160px',
  },
  {
    sortable: true,
    name: 'Own number',
    key: 'ownNumber',
    minWidth: '180px',
  },
  {
    sortable: true,
    name: 'Reporter',
    key: 'reporter',
    minWidth: '140px',
  },
];

export const subcontractorTableHead: tTableHead[] = [
  {
    sortable: false,
    name: 'No.',
    key: 'order',
  },
  {
    sortable: true,
    name: 'Client',
    key: 'client',
    minWidth: '120px',
  },
  {
    sortable: false,
    name: 'Assignment date',
    key: 'assignedAt',
    minWidth: '150px',
  },
  {
    sortable: true,
    name: 'Smart FM number',
    key: 'SFMNumber',
    minWidth: '150px',
  },
  {
    sortable: true,
    name: 'Type',
    key: 'type',
  },
  {
    sortable: true,
    name: 'Mode',
    key: 'mode',
  },
  {
    sortable: true,
    name: 'Location',
    key: 'location',
    minWidth: '260px',
  },
  {
    sortable: true,
    name: 'Short description',
    key: 'shortDescription',
    minWidth: '150px',
  },
  {
    sortable: true,
    name: 'Status',
    key: 'status',
  },
  {
    sortable: true,
    name: 'Long stop date',
    key: 'longStopDate',
    minWidth: '140px',
  },
  {
    sortable: true,
    name: 'Contract',
    key: 'contract',
  },
  {
    sortable: true,
    name: 'Industry',
    key: 'industry',
    minWidth: '160px',
  },
  {
    sortable: true,
    name: 'Own number',
    key: 'ownNumber',
    minWidth: '180px',
  },
];
