/* eslint-disable camelcase */
import contractsAgent from 'agents/contracts';
import applianceTreeAgent from 'agents/applianceTree';
import { tGet, tEnumsResult } from 'types/global';
import { tAccountingModeBodyRequest, tSingleAccountingMode } from 'types/services/contracts';
import subcontractorsAgent from 'agents/subcontractors';

type tId = { [key: string]: string | number };
export type tAccountingMode = { id: number; name: string };

const updateAccountingMode = async (
  bodyRequest: tAccountingModeBodyRequest,
  accountingModeId: string | number,
) => {
  const result = await contractsAgent
    .updateAccountingMode(bodyRequest, accountingModeId)
    .then((response) => {
      const { status, data } = response;

      return { state: true, errors: [], data, status };
    })
    .catch((error) => {
      return { state: false, errors: !!error?.data ? error.data : [], data: {} };
    });

  return result;
};

const addAccountingMode = async (
  bodyRequest: Omit<tAccountingModeBodyRequest, 'contract_id'>,
  contractId: string | number,
  isSubcontractorContract: boolean,
) => {
  const agent = isSubcontractorContract ? subcontractorsAgent : contractsAgent;
  const result = await agent
    .addAccountingMode(bodyRequest, contractId)
    .then((response) => {
      const { status, data } = response;

      return { state: true, errors: [], data, status };
    })
    .catch((error) => {
      return { state: false, errors: !!error?.data ? error.data : [], data: {} };
    });

  return result;
};

const remove = ({ accountingModeId }: tId) =>
  contractsAgent
    .deleteAccountingMode(accountingModeId)
    .then((response) => {
      const { status } = response;

      return { status, error: '' };
    })
    .catch((error) => {
      const { data, status } = error;

      return {
        status,
        error: !!data.length && data[0]?.msg ? data[0].msg : 'Something went wrong. Try again',
      };
    });

export type tGetAllSettlementModesParams = {
  contractId: string | number;
  isSubcontractorContract: boolean;
};

const getAll = async ({ contractId, isSubcontractorContract }: tGetAllSettlementModesParams) => {
  if (!!contractId) {
    const agent = isSubcontractorContract ? subcontractorsAgent : contractsAgent;
    const response = await agent.getAccountingModes(contractId);
    const { data } = response;
    const count: number = data.length;
    let results: tAccountingMode[] = [];

    if (!!count) {
      results = data.map((accountingMode: tAccountingMode) => {
        const { id, name } = accountingMode;
        return {
          id,
          name,
        };
      });
    }

    return {
      data: {
        count,
        results,
      },
    };
  }
};

const getIndustries = () =>
  applianceTreeAgent
    .getIndustries()
    .then(({ data }: { data: { count: number; results: { id: number; name: string }[] } }) => {
      const { count, results: responseResults } = data;

      const results: tEnumsResult[] = responseResults.map((industry) => {
        const { id, name } = industry;

        return {
          id,
          fieldName: 'industry',
          label: name,
          value: name,
        };
      });

      return {
        data: {
          count,
          results,
        },
      };
    });

const getIndustriesAssignedToContract = ({ contractId }: { contractId: number | string }) =>
  contractsAgent
    .getIndustriesAssignedToContract(contractId)
    .then(({ data }: { data: { id: number; name: string }[] }) => {
      const results: tEnumsResult[] = data.map((industry) => {
        const { id, name } = industry;

        return {
          id,
          fieldName: 'industry',
          label: name,
          value: name,
        };
      });

      return {
        data: {
          results,
        },
      };
    });

const getSlaPrices = () =>
  contractsAgent.getSlaPrices().then(({ data }) => {
    const { count, results: responseResults } = data;

    const results: tEnumsResult[] = responseResults.map((industry) => {
      const { id, name } = industry;

      return {
        id,
        fieldName: 'attribute',
        label: name,
        value: id,
      };
    });

    return {
      data: {
        count,
        results,
      },
    };
  });

const getAccountingModesDaysTypes = () =>
  contractsAgent.getAccountingModesDaysTypes().then((response: { data: string[] }) => {
    const { data } = response;
    const count: number = data.length;

    const results: tEnumsResult[] = data.map((dayType: string, index: number) => ({
      id: index + 1,
      fieldName: 'dayType',
      label: dayType,
      value: dayType,
    }));

    return {
      data: {
        count,
        results,
      },
    };
  });

const getAccountingModesHoursTypes = () =>
  contractsAgent.getAccountingModesHoursTypes().then((response: { data: string[] }) => {
    const { data } = response;
    const count: number = data.length;

    const results: tEnumsResult[] = data.map((hourType: string, index: number) => ({
      id: index + 1,
      fieldName: 'hours',
      label: hourType,
      value: hourType,
    }));

    return {
      data: {
        count,
        results,
      },
    };
  });

const getAccountingModesValidityHoursTypes = () =>
  contractsAgent.getAccountingModesValidityHoursTypes().then((response: { data: string[] }) => {
    const { data } = response;
    const count: number = data.length;

    const results: tEnumsResult[] = data.map((validityHourType: string, index: number) => ({
      id: index + 1,
      fieldName: 'hoursOfValidity',
      label: validityHourType,
      value: validityHourType,
    }));

    return {
      data: {
        count,
        results,
      },
    };
  });

const getAccountingModesReactionTimeUnits = () =>
  contractsAgent.getAccountingModesReactionTimeUnits().then((response: { data: string[] }) => {
    const { data } = response;
    const count: number = data.length;

    const results: tEnumsResult[] = data.map((reactionTimeUnit: string, index: number) => ({
      id: index + 1,
      fieldName: 'unit',
      label: reactionTimeUnit,
      value: reactionTimeUnit,
    }));

    return {
      data: {
        count,
        results,
      },
    };
  });

const get = ({ id }: tGet) =>
  contractsAgent.getSingleAccountingMode(id).then((response) => {
    const {
      data: {
        attributes: apiAttributes,
        name,
        days_type,
        hours_type,
        validity_hours_type,
        industries,
        reaction_time,
        reaction_time_unit,
      },
    } = response;

    const industry =
      industries.length > 0
        ? industries.map((modeIndustry) => ({ id: modeIndustry.id, value: modeIndustry.name }))
        : [];

    const attributes =
      apiAttributes.length > 0
        ? apiAttributes.map((attribute) => ({
            id: attribute.id,
            value: attribute.value,
            unit: {
              name: attribute.sla_price.name,
            },
          }))
        : [];

    const result: tSingleAccountingMode = {
      attributes,
      settlementModeName: name,
      dayType: !!days_type ? { value: days_type, label: days_type } : null,
      hours: !!hours_type ? { value: hours_type, label: hours_type } : null,
      hoursOfValidity: !!validity_hours_type
        ? { value: validity_hours_type, label: validity_hours_type }
        : null,
      industry,
      reactionTime: reaction_time,
      unit: !!reaction_time_unit ? { value: reaction_time_unit, label: reaction_time_unit } : null,
    };

    return { data: result };
  });

export default {
  updateAccountingMode,
  getIndustriesAssignedToContract,
  addAccountingMode,
  remove,
  get,
  getSlaPrices,
  getIndustries,
  getAccountingModesDaysTypes,
  getAccountingModesHoursTypes,
  getAccountingModesValidityHoursTypes,
  getAccountingModesReactionTimeUnits,
  getAll,
};
