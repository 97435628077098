import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 40vw;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 0.875rem;
  display: flex;
  justify-content: flex-end;
`;

export const ContainerWithGap = styled.div`
  margin-bottom: 1rem;
`;
