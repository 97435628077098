import styled from 'styled-components';

export const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
  font-size: 0.875rem;
`;

export const ImporterWrapper = styled.div`
  min-height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const HiddenInput = styled.input`
  display: none;
`;
