const SAVE_USER = 'SAVE_USER';
const LOADING_USER = 'LOADING_USER';
const IDLE_TIME_INCREMENT = 'IDLE_TIME_INCREMENT';
const IDLE_TIME_RESET = 'IDLE_TIME_RESET';

export default {
  SAVE_USER,
  LOADING_USER,
  IDLE_TIME_INCREMENT,
  IDLE_TIME_RESET,
};
