import { useState, useEffect, Suspense, FC, lazy } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { getCurrentUserData } from 'store/user/actions';
import tStore from 'types/store';
import { colorGreyBasic } from 'styles/GlobalStyles';
import { SideBar, Icon, Spinner } from 'components';
import { Wrapper, Main, MenuButton, View } from './Dashboard.style';
import { getUnreadCounters } from 'store/messageCounters/actions';

const ClientsList = lazy(() => import('../Clients'));
const FinishedIssuesList = lazy(() => import('../Issues/FinishedIssuesList/FinishedIssuesList'));
const ComplaintPreview = lazy(
  () => import('../Issues/Complaints/ComplaintPreview/ComplaintPreview'),
);
const VisitsForNotification = lazy(() => import('../Issues/Visits/VisitsForNotificationList'));
const IssuesList = lazy(() => import('../Issues'));
const IssuePreview = lazy(() => import('../Issues/Issues/IssuePreview/IssuePreview'));
const AddEditFormClient = lazy(() => import('../Clients/AddEditFormClient'));
const Contracts = lazy(() => import('../Contracts'));
const AddEditFormContracts = lazy(() => import('../Contracts/AddEditFormContracts'));
const Mru = lazy(() => import('../Mru'));
const AddEditFormMRU = lazy(() => import('../Mru/AddEditFormMRU'));
const Users = lazy(() => import('../Users'));
const AddEditViewUser = lazy(() => import('../Users/AddEditViewUser'));
const ItsStructure = lazy(() => import('../ItsStructure'));
const AddEditFormItsStructure = lazy(() => import('../ItsStructure/AddEditFormItsStructure'));
const DevicesAndFacilities = lazy(() => import('../DevicesAndFacilities'));
const AddEditFormDevices = lazy(() => import('../DevicesAndFacilities/AddEditFormDevices'));
const AddEditFormIssues = lazy(() => import('../Issues/AddEditFormIssues'));
const InvoicesList = lazy(() => import('../Invoices/InvoicesList'));
const Invoice = lazy(() => import('../Invoices/Invoice'));
const VisitPreview = lazy(() => import('../Issues/Visits/VisitPreview'));
const Subcontractors = lazy(() => import('../Subcontractors/SubcontractorsList'));
const SubcontractorsContracts = lazy(
  () => import('../Subcontractors/contracts/SubcontractorsContractsList'),
);
const AddEditFormSubcontractorContract = lazy(
  () => import('../Subcontractors/contracts/AddEditFormSubcontractorContract'),
);
const NewComplaintForm = lazy(() => import('../Issues/Complaints/NewComplaint/NewComplaintForm'));
const AddEditFormSubcontractors = lazy(() => import('../Subcontractors/AddEditFormSubcontractors'));
const RepresentativesList = lazy(() => import('../Subcontractors/Representatives'));
const AddEditFormRepresentative = lazy(
  () => import('../Subcontractors/Representatives/AddEditFormRepresentative'),
);
const WorkersList = lazy(() => import('../Workers'));
const AddEditViewWorker = lazy(() => import('../Workers/AddEditViewWorker'));
const AddEditFormMaintenances = lazy(
  () => import('../Issues/Maintenances/AddEditFormMaintenances'),
);
const MaintenancesList = lazy(() => import('../Issues/Maintenances'));
const ReportsTechManager = lazy(() => import('../Reports/ReportsTechManager'));
const ReportsRegionalAdmin = lazy(() => import('../Reports/ReportsRegionalAdmin'));

const Dashboard: FC = () => {
  const kc = useSelector((state: tStore) => state.auth.kc, shallowEqual);
  const permissions = useSelector((state: tStore) => state.user.permissions, shallowEqual);
  const permissionsLoading = useSelector((state: tStore) => state.user.loading, shallowEqual);
  const userExists = !!useSelector((state: tStore) => state.user.username, shallowEqual);

  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState<boolean>(false);

  useEffect(() => {
    if (kc?.token) {
      dispatch(getCurrentUserData());
      dispatch(getUnreadCounters());
    }
  }, [kc?.token]);

  return (
    <Wrapper>
      <SideBar showMenu={showMenu} setShowMenu={setShowMenu} />
      <Main id='main-scroll'>
        <MenuButton onClick={() => setShowMenu(!showMenu)}>
          <Icon icon='menu' width='2.5rem' height='2.5rem' fill={colorGreyBasic} />
        </MenuButton>
        <View>
          <Suspense fallback={<Spinner />}>
            <Switch>
              {permissions.includes('tunSubcontractorsPeriodicInspectionsRead') && (
                <Route exact path='/issues/maintenances' component={MaintenancesList} />
              )}
              {permissions.includes('tunSubcontractorsPeriodicInspectionsWrite') && (
                <Route
                  exact
                  path='/issues/maintenances/new'
                  render={() => <AddEditFormMaintenances isAddMode />}
                />
              )}
              {permissions.includes('tunSubcontractorsPeriodicInspectionsRead') && (
                <Route
                  exact
                  path='/issues/maintenances/:id'
                  render={() => <AddEditFormMaintenances preview />}
                />
              )}
              {permissions.includes('tunSubcontractorsPeriodicInspectionsWrite') && (
                <Route
                  exact
                  path='/issues/maintenances/:id/edit'
                  component={AddEditFormMaintenances}
                />
              )}
              {permissions.includes('tunIssuesWrite') && (
                <Route exact path='/issues/new' render={() => <AddEditFormIssues />} />
              )}
              {permissions.includes('tunIssuesRead') && (
                <Route exact path='/issues/finished' component={FinishedIssuesList} />
              )}
              {permissions.includes('tunIssuesAppointmentsAcceptOrReject') && (
                <Route exact path='/issues/passes/list' component={VisitsForNotification} />
              )}
              {permissions.includes('tunIssuesRead') && (
                <Route exact path='/issues' component={IssuesList} />
              )}
              {permissions.includes('tunIssuesRead') && (
                <Route exact path='/issues/:id' component={IssuePreview} />
              )}
              {permissions.includes('tunIssuesWrite') && (
                <Route exact path='/issues/:id/complaint/new' component={NewComplaintForm} />
              )}
              {permissions.includes('tunIssuesRead') && (
                <Route exact path='/issues/:id/complaint' component={ComplaintPreview} />
              )}
              {permissions.includes('tunIssuesTransitionsIssueDoneAcceptance') && (
                <Route
                  exact
                  path='/issues/:id/confirmation'
                  render={() => <IssuePreview confirmation />}
                />
              )}
              {permissions.includes('tunIssuesWrite') && (
                <Route exact path='/issues/:id/edit' component={AddEditFormIssues} />
              )}
              {permissions.includes('tunIssuesCommentsWrite') && (
                <Route
                  exact
                  path='/issues/:id/comments'
                  render={() => <IssuePreview commentsMode />}
                />
              )}
              {permissions.includes('tunIssuesAppointmentsAcceptOrReject') && (
                <Route exact path='/issues/:id/passes' render={() => <VisitPreview passes />} />
              )}
              {permissions.includes('-------------') && (
                <Route exact path='/issues/:id/confirmation' component={IssuePreview} />
              )}
              {permissions.includes('tunClientsRead') && (
                <Route exact path='/customers' component={ClientsList} />
              )}
              {permissions.includes('tunClientsWrite') && (
                <Route exact path='/customers/new' render={() => <AddEditFormClient isAddMode />} />
              )}
              {permissions.includes('tunClientsWrite') && (
                <Route exact path='/customers/:id/edit' component={AddEditFormClient} />
              )}
              {permissions.includes('tunClientsRead') && (
                <Route exact path='/customers/:id' render={() => <AddEditFormClient preview />} />
              )}
              {permissions.includes('tunUsersRead') && (
                <Route exact path='/users' component={Users} />
              )}
              {permissions.includes('tunUsersWrite') && (
                <Route exact path='/users/new/' render={() => <AddEditViewUser isAddMode />} />
              )}
              {permissions.includes('tunUsersWrite') && (
                <Route exact path='/users/:id/edit' render={() => <AddEditViewUser />} />
              )}
              {permissions.includes('tunUsersRead') && (
                <Route exact path='/users/:id' render={() => <AddEditViewUser preview />} />
              )}
              {permissions.includes('tunEmployeesRead') && (
                <Route exact path='/its-structure' component={ItsStructure} />
              )}
              {permissions.includes('tunEmployeesWrite') && (
                <Route exact path='/its-structure/:id/edit'>
                  <AddEditFormItsStructure />
                </Route>
              )}
              {permissions.includes('tunEmployeesWrite') && (
                <Route exact path='/its-structure/new'>
                  <AddEditFormItsStructure isAddMode />
                </Route>
              )}
              {permissions.includes('tunEmployeesRead') && (
                <Route exact path='/its-structure/:id/'>
                  <AddEditFormItsStructure preview />
                </Route>
              )}
              {permissions.includes('tunAddressesRead') && (
                <Route exact path='/mru' component={Mru} />
              )}
              {permissions.includes('tunAddressesWrite') && (
                <Route exact path='/mru/new/' render={() => <AddEditFormMRU isAddMode />} />
              )}
              {permissions.includes('tunAddressesWrite') && (
                <Route exact path='/mru/:id/edit' render={() => <AddEditFormMRU />} />
              )}
              {permissions.includes('tunAddressesRead') && (
                <Route exact path='/mru/:id' render={() => <AddEditFormMRU preview />} />
              )}
              {permissions.includes('tunAppliancesRead') && (
                <Route exact path='/devices' component={DevicesAndFacilities} />
              )}
              {permissions.includes('tunAppliancesWrite') && (
                <Route exact path='/devices/new/' render={() => <AddEditFormDevices isAddMode />} />
              )}
              {permissions.includes('tunAppliancesWrite') && (
                <Route exact path='/devices/:id/edit' render={() => <AddEditFormDevices />} />
              )}
              {permissions.includes('tunAppliancesRead') && (
                <Route exact path='/devices/:id' render={() => <AddEditFormDevices preview />} />
              )}
              {(permissions.includes('tunInvoicesClientRead') ||
                permissions.includes('tunInvoicesRead') ||
                permissions.includes('tunSubcontractorInvoicesRead')) && (
                <Route exact path='/invoices' component={InvoicesList} />
              )}
              {(permissions.includes('tunInvoicesClientRead') ||
                permissions.includes('tunInvoicesRead')) && (
                <Route exact path='/invoices/:id'>
                  <Invoice canEdit={permissions.includes('tunInvoicesEdit')} />
                </Route>
              )}
              {permissions.includes('tunInvoicesEdit') && (
                <Route exact path='/invoices/:id/edit'>
                  <Invoice edit />
                </Route>
              )}
              {permissions.includes('tunInvoicesCommentsWrite') && (
                <Route exact path='/invoices/:id/comment'>
                  <Invoice commentMode />
                </Route>
              )}
              <Route exact path='/invoices/:id/comment'>
                <Invoice subcontractorCommentMode />
              </Route>
              {permissions.includes('tunContractsRead') && (
                <Route exact path='/contracts' component={Contracts} />
              )}
              {permissions.includes('tunContractsWrite') && (
                <Route
                  exact
                  path='/contracts/new/'
                  render={() => <AddEditFormContracts isAddMode />}
                />
              )}
              {permissions.includes('tunContractsWrite') && (
                <Route exact path='/contracts/:id/edit' render={() => <AddEditFormContracts />} />
              )}
              {permissions.includes('tunContractsRead') && (
                <Route
                  exact
                  path='/contracts/:id'
                  render={() => <AddEditFormContracts preview />}
                />
              )}
              {(permissions.includes('tunSubcontractorsContractsRead') ||
                permissions.includes('tunSubcontractorsContractsView')) && (
                <Route exact path='/subcontractors/contracts' component={SubcontractorsContracts} />
              )}
              {permissions.includes('tunSubcontractorsContractsWrite') && (
                <Route
                  exact
                  path='/subcontractors/contracts/new'
                  render={() => <AddEditFormSubcontractorContract isAddMode />}
                />
              )}
              {(permissions.includes('tunSubcontractorsContractsRead') ||
                permissions.includes('tunSubcontractorsContractsView')) && (
                <Route
                  exact
                  path='/subcontractors/contracts/:id'
                  render={() => <AddEditFormSubcontractorContract preview />}
                />
              )}
              {permissions.includes('tunSubcontractorsContractsWrite') && (
                <Route
                  exact
                  path='/subcontractors/contracts/:id/edit'
                  render={() => <AddEditFormSubcontractorContract />}
                />
              )}
              {permissions.includes('tunSubcontractorsRepresentativesRead') && (
                <Route
                  exact
                  path='/subcontractors/representatives'
                  component={RepresentativesList}
                />
              )}
              {permissions.includes('tunSubcontractorsRepresentativesWrite') && (
                <Route
                  exact
                  path='/subcontractors/representatives/new'
                  render={() => <AddEditFormRepresentative isAddMode />}
                />
              )}
              {permissions.includes('tunSubcontractorsRepresentativesRead') && (
                <Route
                  exact
                  path='/subcontractors/representatives/:id'
                  render={() => <AddEditFormRepresentative preview />}
                />
              )}
              {permissions.includes('tunSubcontractorsRepresentativesWrite') && (
                <Route
                  exact
                  path='/subcontractors/representatives/:id/edit'
                  component={AddEditFormRepresentative}
                />
              )}
              {permissions.includes('tunSubcontractorsRead') && (
                <Route exact path='/subcontractors' component={Subcontractors} />
              )}
              {permissions.includes('tunSubcontractorsWrite') && (
                <Route
                  exact
                  path='/subcontractors/new'
                  render={() => <AddEditFormSubcontractors isAddMode />}
                />
              )}
              {permissions.includes('tunSubcontractorsRead') && (
                <Route
                  exact
                  path='/subcontractors/:id'
                  render={() => <AddEditFormSubcontractors preview />}
                />
              )}
              {permissions.includes('tunSubcontractorsWrite') && (
                <Route
                  exact
                  path='/subcontractors/:id/edit'
                  component={AddEditFormSubcontractors}
                />
              )}
              {permissions.includes('tunSubcontractorsTechniciansRead') && (
                <Route exact path='/workers' component={WorkersList} />
              )}
              {permissions.includes('tunSubcontractorsTechniciansWrite') && (
                <Route exact path='/workers/new' render={() => <AddEditViewWorker isAddMode />} />
              )}
              {permissions.includes('tunSubcontractorsTechniciansRead') && (
                <Route exact path='/workers/:id' render={() => <AddEditViewWorker preview />} />
              )}
              {permissions.includes('tunSubcontractorsTechniciansWrite') && (
                <Route exact path='/workers/:id/edit' component={AddEditViewWorker} />
              )}
              {permissions.includes('tunReportsTechmanagerRead') && (
                <Route exact path='/reports' component={ReportsTechManager} />
              )}
              {permissions.includes('tunReportsRegionaladminRead') && (
                <Route exact path='/reports' component={ReportsRegionalAdmin} />
              )}
              {userExists && !permissionsLoading && <Redirect to='/issues' />}
            </Switch>
          </Suspense>
        </View>
      </Main>
    </Wrapper>
  );
};

export default Dashboard;
