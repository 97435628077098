import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addNotification } from 'store/notifications/actions';
import { Column, ExpansionPanel, Row, Form, Button, Timetable, Spinner } from 'components';
import { REQUIRED_FIELDS } from 'utils/requiredFormFields';
import { validateRequiredFieldsWithLoc } from 'utils/form';
import { TopMargin1Rem } from 'styles/GlobalStyledComponents';
import { useForm, useSingleFetch } from 'hooks';
import structureService, { tSchedulesStructure } from 'services/structures';
import { tGet, tError, tAPIFieldError } from 'types/global';
import { getDayNameFromNumber } from 'utils/helpers';
import {
  tSchedulesStructureValues,
  tSchedulesStructureValue,
  tSchedulesStructureApiElement,
} from 'types/services/locations';
import { ExpansionPanelWrapper, ButtonWrapper } from '../../AddEditFormContracts.style';

type tSingleWorkHoursForm = {
  disabled: boolean;
  id: string;
  preview: boolean;
};

const dataModel = {
  workHours: [],
  availabilitySchedule: [],
};

const SingleWorkHoursForm: React.FC<tSingleWorkHoursForm> = ({
  disabled = false,
  preview = false,
  id,
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<{}>({});
  const [workHoursErrors, setWorkHoursErrors] = useState<tError[]>([]);
  const [availabilityScheduleErrors, setAvailabilityScheduleErrors] = useState<tError[]>([]);
  const [availabilityScheduleCheckbox, setAvailabilityScheduleCheckbox] = useState<boolean>(false);

  const {
    fetch: setParamsHandler,
    loading: loadingData,
    data,
  } = useSingleFetch<tSchedulesStructure, tGet>(
    structureService.getSchedulesStructure,
    'An error occurred while getting schedule structure',
  );

  const fetchData = useCallback(() => {
    if (!!id) return setParamsHandler({ id });
    return setInitialValues(dataModel);
  }, [setParamsHandler, id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setWorkHoursErrors([]);
    setAvailabilityScheduleErrors([]);
  }, [id]);

  const getInitialValues = (data) => {
    if (Object.keys(data).length > 0) {
      setAvailabilityScheduleCheckbox(data.availabilityPeriodsChecked);
      return {
        workHours: data.workHours,
        availabilitySchedule: data.availabilitySchedule,
      };
    }
    return dataModel;
  };

  useEffect(() => {
    setInitialValues(getInitialValues(data));
  }, [data]);

  const setNotificationHandler = (message: string, type = 'error' as 'error' | 'success') => {
    const enableTimeout = type === 'success';
    dispatch(addNotification(t(message), type, enableTimeout));
  };

  const setResponseErrors = (
    setter: Function,
    errors: tAPIFieldError[],
    values: tSchedulesStructureValue[] | tSchedulesStructureApiElement[],
  ) => {
    const transformField = (field: string, loc: (string | number)[]): string => {
      const errorIndex = loc[1] as number;
      const sortedValues = !!values?.length ? values.sort((a, b) => a.index - b.index) : [];
      const weekdayName =
        !!sortedValues?.length && sortedValues[errorIndex] ? sortedValues[errorIndex].name : '';
      switch (field) {
        case 'to':
          return `to-${weekdayName}`;
        case 'since':
          return `from-${weekdayName}`;
        default:
          return '__general__';
      }
    };
    setter(
      errors
        ? errors?.map((error: tAPIFieldError) => {
            const { key, msg, loc } = error;
            const errorIndex = !!loc?.length ? loc[1] : null;
            return {
              field: transformField(key, loc || []),
              error: msg,
              errorIndex,
            };
          })
        : [],
    );
  };

  const getAvailabilityScheduleData = (availabilitySchedule: tSchedulesStructureValue[]) => {
    const availabilityHours = !!availabilitySchedule?.length
      ? availabilitySchedule.map((availability, index) => ({
          since: availability.firstValue.valueFrom,
          to: availability.firstValue.valueTo,
          day_type: availability.id,
          name: getDayNameFromNumber(availability.id, true),
          index: availabilityScheduleCheckbox ? index * 2 : index,
        }))
      : [];

    const availabilityHoursSecondValue = !!availabilitySchedule?.length
      ? availabilitySchedule
          .filter((elem) => elem.secondValue)
          .map(({ secondValue, id }, index) => ({
            since: secondValue.valueFrom,
            to: secondValue.valueTo,
            day_type: id,
            name: `${getDayNameFromNumber(id, true)}-second`,
            index: index * 2 + 1,
          }))
      : [];

    return availabilityScheduleCheckbox
      ? [...availabilityHours, ...availabilityHoursSecondValue]
      : availabilityHours;
  };

  const getWorkHoursData = (workHours: tSchedulesStructureValue[]) =>
    workHours.map(({ id, firstValue }, index) => {
      return {
        since: firstValue.valueFrom,
        to: firstValue.valueTo,
        day_type: id,
        name: getDayNameFromNumber(id, true),
        index,
      };
    });

  const onSubmit = async ({ values }: { values: tSchedulesStructureValues }) => {
    try {
      setLoading(true);
      const { workHours, availabilitySchedule } = values;
      const availabilityHoursData = getAvailabilityScheduleData(availabilitySchedule);
      const workHoursData = getWorkHoursData(workHours);
      const data = {
        opening_hours: !!workHours?.length ? workHoursData : [],
        availability_hours: !!availabilitySchedule?.length ? availabilityHoursData : [],
      };

      const requiredFields = REQUIRED_FIELDS.CONTRACTS_FORM.LOCATION.WORKING_HOURS_FORM;
      const validationErrorsWorkHours = validateRequiredFieldsWithLoc(
        workHoursData,
        requiredFields,
      );
      const validationErrorsAvailabilityHours = validateRequiredFieldsWithLoc(
        availabilityHoursData,
        requiredFields,
      );
      if (validationErrorsWorkHours.length || validationErrorsAvailabilityHours.length) {
        setResponseErrors(setWorkHoursErrors, validationErrorsWorkHours, workHoursData);
        setResponseErrors(
          setAvailabilityScheduleErrors,
          validationErrorsAvailabilityHours,
          availabilityHoursData,
        );
        return;
      }

      const response = await structureService.putSchedulesStructure(id, data);

      if (response.status === 200) {
        setNotificationHandler(t('Working hours and availability schedule were saved'), 'success');
        setWorkHoursErrors([]);
        setAvailabilityScheduleErrors([]);
      } else {
        setNotificationHandler('Something went wrong. Try again');
      }
    } catch (error) {
      const { workHours, availabilitySchedule } = values;
      switch (error?.status) {
        case 500:
        case 502:
        case 503:
          setNotificationHandler('Something went wrong. Try again');
          break;
        case 400: {
          const { data: errors } = error;
          const workHoursErrors = errors.filter((error) => error.loc.includes('opening_hours'));
          const availabilityScheduleErrors = errors.filter((error) =>
            error.loc.includes('availability_hours'),
          );
          setResponseErrors(setWorkHoursErrors, workHoursErrors, workHours);
          setResponseErrors(
            setAvailabilityScheduleErrors,
            availabilityScheduleErrors,
            getAvailabilityScheduleData(availabilitySchedule),
          );
          break;
        }
        default: {
          setNotificationHandler('Something went wrong. Try again');
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit } = useForm({
    initialValues,
    onSubmit: (values) => onSubmit(values),
  });

  const { workHours, availabilitySchedule } = values;

  const isLoading = loadingData || loading;
  return (
    <ExpansionPanelWrapper>
      {isLoading && <Spinner />}
      <Form onSubmit={handleSubmit}>
        <>
          <ExpansionPanel hasBottomBorder title='Work hours' hasIcon iconName='schedule'>
            <Row>
              <Column>
                <Timetable
                  name='workHours'
                  errors={workHoursErrors}
                  onChange={handleChange}
                  defaultItems={workHours}
                  preview={preview}
                  noItemsCopy='No assigned working hours'
                />
              </Column>
            </Row>
          </ExpansionPanel>
          <TopMargin1Rem />
          <ExpansionPanel hasBottomBorder title='Availability schedule' hasIcon iconName='schedule'>
            <Row>
              <Column>
                <Timetable
                  name='availabilitySchedule'
                  errors={availabilityScheduleErrors}
                  preview={preview}
                  onChange={handleChange}
                  defaultItems={availabilitySchedule}
                  twoAvailabilityPeriods
                  onCheckboxChange={() =>
                    setAvailabilityScheduleCheckbox(!availabilityScheduleCheckbox)
                  }
                  isCheckboxChecked={availabilityScheduleCheckbox}
                  noItemsCopy='No availability Schedule'
                />
              </Column>
            </Row>
          </ExpansionPanel>
          {!disabled ? (
            <ButtonWrapper>
              <Button variant='green' type='submit'>
                {t('Save')}
              </Button>
            </ButtonWrapper>
          ) : null}
        </>
      </Form>
    </ExpansionPanelWrapper>
  );
};

export default SingleWorkHoursForm;
