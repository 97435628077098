import styled from 'styled-components';

export const TimePickersWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TimePickersPreviewWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
  font-size: 0.75rem;
`;

export const TimePickersSpan = styled.span`
  margin: 0.5rem 1rem;
`;
