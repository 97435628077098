import { monetaryUnit } from 'utils/constants';
import { formatMonetaryValue } from 'utils/helpers';
import { useTranslation } from 'react-i18next';
import { Cell, UppercaseCell } from './InvoiceTable.style';
import Separator from './Separator';

export type tNetGross = {
  net: number;
  gross: number;
};

export type tSubSums = {
  name?: string;
  data: { client: tNetGross; contractor: tNetGross };
  showGross?: boolean;
  showContractor?: boolean;
  showReport?: boolean;
};

const SubSums: React.FC<tSubSums> = ({
  name = 'Sum',
  data,
  showGross = true,
  showContractor = true,
  showReport = false,
}) => {
  const { t } = useTranslation();
  const {
    client: { net: clientNet, gross: clientGross },
    contractor: { net: contractorNet, gross: contractorGross },
  } = data;

  return (
    <tr>
      <Cell colSpan={3} />
      <Separator />
      <UppercaseCell>{t(name)}:</UppercaseCell>
      <Cell>{`${formatMonetaryValue(clientNet) ?? '-'} ${monetaryUnit}`}</Cell>
      {showGross && <Cell>{`${formatMonetaryValue(clientGross) ?? '-'} ${monetaryUnit}`}</Cell>}

      {showContractor && (
        <>
          <Separator />
          <UppercaseCell>{t(name)}:</UppercaseCell>
          <Cell>{`${formatMonetaryValue(contractorNet) ?? '-'} ${monetaryUnit}`}</Cell>
          {showGross && (
            <Cell>{`${formatMonetaryValue(contractorGross) ?? '-'} ${monetaryUnit}`}</Cell>
          )}
        </>
      )}
      {showReport && <Separator />}
    </tr>
  );
};

export default SubSums;
