type tTreeStructureConfig = {
  depth?: number;
  depthActionLevel?: number;
  id?: number | string | null;
  items: any[];
  parentKey?: string;
};

class TreeView {
  public static createTreeStructure = ({
    depth = 1,
    depthActionLevel = 0,
    id = null,
    items = [],
    parentKey = 'parent',
  }: tTreeStructureConfig) =>
    items
      .filter((item) => item[parentKey] === id)
      .map((item) => {
        const { id: itemId } = item;

        return {
          ...item,
          allowAction: depth <= depthActionLevel,
          children: TreeView.createTreeStructure({
            depth: depth + 1,
            items,
            id: itemId,
            depthActionLevel,
          }),
          depth,
          key: id ? `${depth}-${id}-${itemId}` : `${depth}-${itemId}`,
        };
      });
}

export default TreeView;
