import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import contractsService, {
  tGetAccountingModesParams,
  tPriceListBilling,
  tGetVATRateParams,
} from 'services/contracts';
import { Button, Checkbox, Dropdown, Icon, Input, Modal, Spinner } from 'components';
import { validateRequiredFieldsWithLoc } from 'utils/form';
import { REQUIRED_FIELDS } from 'utils/requiredFormFields';
import { useFetch, useFormErrorsReset, useSingleFetch } from 'hooks';
import { addNotification } from 'store/notifications/actions';
import { BorderGap } from 'styles/GlobalStyledComponents';
import { colorBlueBasic, colorGreenBasic, colorGreyBasic, colorWhite } from 'styles/GlobalStyles';
import { tOption, tKeyString, tDropdownDataApi, tError, tGet } from 'types/global';
import { tPriceList } from 'types/services/contracts';
import { tAccountingMode } from 'services/settlementModes';
import { dropdownZIndex, monetaryUnit } from 'utils/constants';
import { calculateGrossValue, catchErrors, formatMonetaryValue } from 'utils/helpers';
import {
  AcceptedPriceNameBox,
  AcceptedPriceNameText,
  AccountingMode,
  AccountingModeContainer,
  AccountingModeHeader,
  AccountingModeNav,
  AccountingModeNavButtonWrapper,
  ButtonLabel,
  ContentWrapper,
  IconFlexWrapper,
  IconWrapper,
  MarginBox,
  NavButton,
  PriceListWrapper,
  SingleTab,
  SumSufix,
  TabWrapper,
  UnitDropdownWrapper,
  ErrorText,
} from './SinglePriceListBillingActivitiesForm.style';

type tSinglePriceListBillingActivitiesForm = {
  disabled?: boolean;
  preview?: boolean;
  openRightPanel: Function;
  contractId?: number;
  isSubcontractorContract?: boolean;
};

type tAccountingModeList = {
  apiId: number | string | null;
  index: number;
  name: string;
};

type tTemporaryPriceList = {
  id: number;
  acceptedPriceName: string;
  priceName: string;
  unitType: tDropdownDataApi | null;
  netValue: number;
  apiId: string | number | null;
  show: boolean;
};

const SinglePriceListBillingActivitiesForm: React.FC<tSinglePriceListBillingActivitiesForm> = ({
  disabled = false,
  preview = false,
  openRightPanel,
  contractId,
  isSubcontractorContract = false,
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [priceList, setPriceList] = useState<tTemporaryPriceList[]>([]);
  const [showGrossPrice, setShowGrossPrice] = useState(preview);
  const [accountingModeList, setAccountingModeList] = useState<tAccountingModeList[]>([]);
  const [activeAccountingMode, setActiveAccountingMode] = useState<tAccountingModeList>({
    apiId: null,
    index: 0,
    name: '',
  });
  const [errors, setErrors] = useState<tError[]>([]);
  const [filteredErrors, setFilteredErrors] = useState<tError[]>([]);
  const { formElemErrors, resetFormError } = useFormErrorsReset(filteredErrors);

  const {
    fetch: setParamsHandlerSpecificationUnits,
    loading: loadingSpecificationUnits,
    data: specificationUnits,
  } = useFetch<tOption>(
    contractsService.getSpecificationUnits,
    'An error occurred while getting units',
  );

  const {
    fetch: setParamsHandlerAccountingModes,
    loading: loadingAccountingModes,
    data: accountingModes,
  } = useFetch<tAccountingMode, tGetAccountingModesParams>(
    contractsService.getAccountingModes,
    'An error occurred while getting accounting modes',
  );

  const {
    fetch: setParamsHandlerPriceList,
    loading: loadingApiPriceList,
    data: apiPriceList,
  } = useFetch<tPriceListBilling, tGet>(
    contractsService.getSinglePriceListBilling,
    'An error occurred while getting list of service activities',
  );

  const {
    fetch: setParamsHandlerGetVATRate,
    loading: loadingVATRate,
    data: VATRateData,
  } = useSingleFetch<{ VATRate: string }, tGetVATRateParams>(
    contractsService.getVATRate,
    'An error occurred while getting contract data',
  );

  useEffect(() => {
    setFilteredErrors(errors);
  }, [errors]);

  const setNotificationHandler = (message: string, type = 'error' as 'error' | 'success') => {
    const enableTimeout = type === 'success';
    dispatch(addNotification(t(message), type, enableTimeout));
  };

  const setResponseErrors = (setter: Function, errors: tKeyString[]) => {
    const transformField = (field: string, loc): string => {
      const fieldId = loc[1];
      switch (field) {
        case 'description':
          return `priceName-${fieldId}`;
        case 'unit_id':
          return `unitType-${fieldId}`;
        case 'net':
          return `netValue-${fieldId}`;
        default:
          return '__noField__';
      }
    };

    setter(
      errors
        ? errors?.map((error: tKeyString) => {
            const { key, msg, loc } = error;
            return {
              field: transformField(key, loc),
              error: msg,
              errorIndex: loc[1],
            };
          })
        : [],
    );
  };

  const fetchData = useCallback(() => {
    if (!!contractId) {
      setParamsHandlerSpecificationUnits({});
      setParamsHandlerAccountingModes({
        contractId,
        priceList: true,
        isSubcontractorContract,
      });
      setParamsHandlerGetVATRate({ id: Number(contractId), isSubcontractorContract });
    }
  }, []);

  useEffect(() => {
    if (accountingModeList?.length && !!activeAccountingMode?.apiId) {
      setParamsHandlerPriceList({ id: activeAccountingMode.apiId });
    }
  }, [activeAccountingMode]);

  useEffect(() => {
    if (apiPriceList?.length) {
      setPriceList(apiPriceList);
    }
  }, [apiPriceList]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (accountingModes?.length) {
      const temporaryAccountingModesList: tAccountingModeList[] = [];

      accountingModes.forEach(({ id, name }, index) => {
        temporaryAccountingModesList.push({
          apiId: +id,
          index,
          name,
        });
        if (index === 0) {
          setActiveAccountingMode({
            apiId: +id,
            index,
            name,
          });
        }
      });
      setAccountingModeList(temporaryAccountingModesList);
    }
  }, [accountingModes]);

  const addNewPrice = () => {
    setPriceList([
      ...priceList,
      {
        id: new Date().getTime(),
        acceptedPriceName: '',
        priceName: '',
        unitType: null,
        netValue: 0,
        apiId: activeAccountingMode?.apiId,
        show: true,
      },
    ]);
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      if (activeAccountingMode.apiId !== null) {
        const activeAccountingModePriceList = priceList.filter((singlePriceListItem) => {
          return (
            singlePriceListItem.apiId === activeAccountingMode.apiId &&
            singlePriceListItem.acceptedPriceName.length > 0 &&
            singlePriceListItem.show
          );
        });

        const requestPayload: tPriceList[] = activeAccountingModePriceList.map(
          ({ acceptedPriceName, netValue, unitType }, index) => ({
            description: acceptedPriceName,
            unit_id: unitType?.value || null,
            net: netValue,
            index,
          }),
        );

        if (!requestPayload.length) {
          return setNotificationHandler(t('At least one element is required to save form'));
        }
        const requiredFields =
          REQUIRED_FIELDS.CONTRACTS_FORM.SINGLE_PRICE_LIST_BILLING_ACTIVITIES_FORM;
        const validationErrors = validateRequiredFieldsWithLoc(requestPayload, requiredFields);
        if (validationErrors.length) return setResponseErrors(setErrors, validationErrors);

        const result = await contractsService.createUpdateSinglePriceListBilling(
          activeAccountingMode.apiId,
          requestPayload,
        );

        if (result.status === 201 || result.status === 200) {
          setNotificationHandler(t('Price list for billing activities was updated'), 'success');
        } else {
          setNotificationHandler(t('Something went wrong. Try again'));
        }
        setErrors([]);
      }
    } catch (error) {
      const setters = { setNotificationHandler, setResponseErrors, setErrors };
      catchErrors(error, setters);
      setPriceList(priceList.filter(({ show }) => show === true));
    } finally {
      setLoading(false);
    }
  };

  const removePrice = (id: number | string) => {
    setPriceList(
      priceList.map((singlePrice) => {
        if (singlePrice.id === id) {
          return {
            ...singlePrice,
            show: false,
          };
        }
        return singlePrice;
      }),
    );
  };

  const updateFieldChanged = (field: string, index: number, value: string, itemId) => {
    setFilteredErrors(resetFormError(`${field}-${index}`));
    setPriceList(
      priceList.map((item: any) => {
        if (item.id === itemId && value !== null) {
          return { ...item, [field]: value };
        }
        return item;
      }),
    );
  };

  const getNextAccountingMode = () => {
    const nextAccMode = accountingModeList.find(
      (accMode) => accMode.index === activeAccountingMode.index + 1,
    );

    if (nextAccMode) setActiveAccountingMode(nextAccMode);
    setErrors([]);
  };

  const getPreviousAccountingMode = () => {
    const nextAccMode = accountingModeList.find(
      (accMode) => accMode.index === activeAccountingMode.index - 1,
    );

    if (nextAccMode) setActiveAccountingMode(nextAccMode);
    setErrors([]);
  };

  const isLoading =
    loadingSpecificationUnits ||
    loadingAccountingModes ||
    loadingApiPriceList ||
    loadingVATRate ||
    loading;

  const isAnyAccountingMode = !!accountingModeList.length;

  return (
    <Modal
      title={t(`Price list for billing activities'`)}
      icon='monetization'
      cancelButtonText={t('Close')}
      cancelMethod={() => openRightPanel({ form: '', id: null })}
      confirmButtonText={t('Save')}
      confirmMethod={() => onSubmit()}
      width='60vw'
      showOnlyCancel={disabled || preview}
    >
      {isLoading && <Spinner />}
      <ContentWrapper>
        <TabWrapper>
          <SingleTab flexSpace={showGrossPrice ? 4 : 6}>
            {t('Name/description of service activities')}
          </SingleTab>
          <SingleTab flexSpace={2}>{t('Unit')}</SingleTab>
          <SingleTab flexSpace={showGrossPrice ? 2 : 1}>{t('Net rate')}</SingleTab>
          <SingleTab flexSpace={showGrossPrice ? 2 : 1}>
            {preview ? (
              t('Gross rate')
            ) : (
              <>
                <Checkbox
                  wrapperStyles={{ marginBottom: '0', paddingRight: '0.5rem' }}
                  name='grossCheckbox'
                  checked={showGrossPrice}
                  errors={[]}
                  onChange={() => setShowGrossPrice(!showGrossPrice)}
                />
                {t('Gross display')}
              </>
            )}
          </SingleTab>
        </TabWrapper>
        <AccountingModeContainer>
          <div style={{ flex: '3' }} />
          <AccountingMode>
            <AccountingModeHeader>
              <p>{t('Settlement mode')}</p>
            </AccountingModeHeader>
            <AccountingModeNav>
              <AccountingModeNavButtonWrapper>
                <NavButton
                  disabled={activeAccountingMode.index === 0}
                  priceTypeColor={activeAccountingMode.index > 0}
                  onClick={getPreviousAccountingMode}
                >
                  <Icon
                    icon='chevronLeft'
                    disable={false}
                    width='1.25rem'
                    height='1.25rem'
                    fill={colorWhite}
                  />
                </NavButton>
                <ButtonLabel>{activeAccountingMode.name}</ButtonLabel>
              </AccountingModeNavButtonWrapper>
              <AccountingModeNavButtonWrapper>
                <NavButton
                  disabled={activeAccountingMode.index === accountingModeList?.length - 1}
                  priceTypeColor={activeAccountingMode.index < accountingModeList?.length - 1}
                  onClick={getNextAccountingMode}
                >
                  <Icon
                    icon='chevronRight'
                    disable={false}
                    fill={colorWhite}
                    width='1.25rem'
                    height='1.25rem'
                  />
                </NavButton>
              </AccountingModeNavButtonWrapper>
            </AccountingModeNav>
          </AccountingMode>
        </AccountingModeContainer>
        <BorderGap />
        {priceList.map((item: tTemporaryPriceList, index: number) => {
          const { id: itemId, acceptedPriceName, priceName, apiId, show } = item;
          const grossRate = `${formatMonetaryValue(
            calculateGrossValue(priceList[index].netValue, VATRateData?.VATRate),
          )} ${monetaryUnit}`;
          if (apiId === activeAccountingMode.apiId && show) {
            return (
              <PriceListWrapper key={itemId}>
                {acceptedPriceName === '' ? (
                  <>
                    <SingleTab flexSpace={showGrossPrice ? 4 : 6}>
                      <Input
                        wrapperStyle={{ marginBottom: 0 }}
                        name={`priceName-${index}`}
                        errors={formElemErrors}
                        value={priceName}
                        onChange={({ target: { value } }) =>
                          updateFieldChanged('priceName', index, value, itemId)
                        }
                        labelMinWidth={0}
                        preview={preview}
                      />
                      <IconWrapper>
                        <Icon
                          asButton
                          icon='checkCircle'
                          disable={disabled}
                          onClick={() =>
                            !priceName
                              ? {}
                              : updateFieldChanged('acceptedPriceName', index, priceName, itemId)
                          }
                          fill={!priceName ? colorGreyBasic : colorGreenBasic}
                        />
                      </IconWrapper>
                      <IconWrapper>
                        <Icon
                          asButton
                          icon='close'
                          fill='red'
                          onClick={() => removePrice(itemId)}
                        />
                      </IconWrapper>
                    </SingleTab>
                    <SingleTab flexSpace={2} />
                    <SingleTab flexSpace={2} />
                    <SingleTab flexSpace={2} />
                  </>
                ) : (
                  <>
                    <SingleTab flexSpace={showGrossPrice ? 4 : 6}>
                      <AcceptedPriceNameBox>
                        <AcceptedPriceNameText>{acceptedPriceName}</AcceptedPriceNameText>
                        {!disabled && !preview && (
                          <IconFlexWrapper>
                            <IconWrapper>
                              <Icon
                                asButton
                                icon='edit'
                                disable={disabled}
                                fill={colorBlueBasic}
                                onClick={() =>
                                  updateFieldChanged('acceptedPriceName', index, '', itemId)
                                }
                              />
                            </IconWrapper>
                            <IconWrapper>
                              <Icon
                                asButton
                                icon='close'
                                fill='red'
                                disable={disabled}
                                onClick={() => removePrice(itemId)}
                              />
                            </IconWrapper>
                          </IconFlexWrapper>
                        )}
                      </AcceptedPriceNameBox>
                    </SingleTab>
                    <SingleTab flexSpace={2}>
                      <UnitDropdownWrapper>
                        <Dropdown
                          options={specificationUnits?.map((option) => ({
                            ...option,
                            fieldName: `unitType-${index}`,
                          }))}
                          name={`unitType-${index}`}
                          errors={formElemErrors}
                          onChange={(target) =>
                            updateFieldChanged('unitType', index, target, itemId)
                          }
                          value={priceList[index]?.unitType?.label}
                          disabled={disabled}
                          preview={preview}
                          wrapperStyle={{ marginBottom: 0 }}
                          labelMinWidth={0}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: dropdownZIndex }) }}
                        />
                      </UnitDropdownWrapper>
                    </SingleTab>
                    <SingleTab flexSpace={2}>
                      <Input
                        name={`netValue-${index}`}
                        type='number'
                        errors={formElemErrors}
                        value={
                          preview
                            ? formatMonetaryValue(priceList[index].netValue)
                            : priceList[index].netValue
                        }
                        wrapperStyle={{ marginBottom: 0 }}
                        onChange={({ target: { value } }) =>
                          updateFieldChanged('netValue', index, value, itemId)
                        }
                        inputSufix={monetaryUnit}
                        labelMinWidth={0}
                        disabled={disabled}
                        preview={preview}
                      />
                    </SingleTab>
                    {showGrossPrice && (
                      <SingleTab flexSpace={2}>
                        {preview ? grossRate : <SumSufix>{grossRate}</SumSufix>}
                      </SingleTab>
                    )}
                  </>
                )}
              </PriceListWrapper>
            );
          }
          return null;
        })}
        {!disabled && !preview && (
          <MarginBox value='1.5rem 0 0 1rem'>
            <Button
              disabled={disabled || !isAnyAccountingMode}
              icon='addCircle'
              variant='blue'
              onClick={addNewPrice}
            >
              {t('Add')}
            </Button>
            {!isAnyAccountingMode && (
              <ErrorText>
                {t(
                  `Cannot add price list billing activities cause the appropriate option hasn't been chose in accounting mode's atributes`,
                )}
              </ErrorText>
            )}
          </MarginBox>
        )}
      </ContentWrapper>
    </Modal>
  );
};

export default SinglePriceListBillingActivitiesForm;
