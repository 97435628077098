import { useTranslation } from 'react-i18next';
import { LabelStyle, OptionalText } from './Label.style';

export type tLabelStyle = {
  labelMinWidth?: number;
  styles?: object;
  optional?: boolean;
};

type tLabel = tLabelStyle & {
  children: JSX.Element[] | JSX.Element | string;
  optional?: boolean;
};

const Label: React.FC<tLabel> = ({
  children,
  labelMinWidth = 90,
  styles = {},
  optional = false,
}): JSX.Element => {
  const { t } = useTranslation();
  return (
    <LabelStyle labelMinWidth={labelMinWidth} style={{ ...styles }}>
      {children}
      {!!optional && <OptionalText>({t('Optional')})</OptionalText>}
    </LabelStyle>
  );
};

export default Label;
