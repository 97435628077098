import styled from 'styled-components';

export const AddClientBreadcrumb = styled.div`
  margin-bottom: 1.5rem;
`;

export const Wrapper = styled.div`
  width: 40vw;
`;

export const ExpansionPanelWrapper = styled.div`
  background-color: white;
  border-radius: var(--theme-borderRadius);
  box-shadow: 0px 0px 8px #0000000a;
  padding: 1.5rem 2rem 2.5rem 2rem;
  margin-bottom: 2rem;
`;

export const Title = styled.p`
  font-size: 1.5rem;
  margin-top: 1.625rem;
  margin-bottom: 1.875rem;
  font-weight: 700;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 0.875rem;
  display: flex;
  justify-content: flex-end;
`;

export const LineGap = styled.div`
  margin: 1.8rem 0rem 1rem 0rem;
  border-bottom: 1px solid var(--theme-mainGrey);
`;
