import { Icon } from 'components';
import { BadgeWrapper, NumberWrapper } from './CustomBadge.style';

export type tCustomBadge = {
  icon: string;
  number: number;
  fill?: string;
  width?: string;
  height?: string;
};

const CustomBadge: React.FC<tCustomBadge> = ({
  icon,
  number,
  fill,
  width,
  height,
}): JSX.Element => {
  return (
    <BadgeWrapper>
      <Icon fill={fill} icon={icon} width={width} height={height} />
      <NumberWrapper>
        <p>{number > 99 ? '99+' : number}</p>
      </NumberWrapper>
    </BadgeWrapper>
  );
};
export default CustomBadge;
