import { useTranslation } from 'react-i18next';
import { Icon } from 'components';
import { colorBlueBasic } from 'styles/GlobalStyles';
import { FlexHWrapper, HorizontalMarginSmall } from 'styles/GlobalStyledComponents';
import { tInvoiceRowType } from 'types/components/invoices';
import { Cell, AddButtonCell } from './InvoiceTable.style';
import Separator from './Separator';

export type tAddRow = {
  type: tInvoiceRowType;
  edit: boolean;
  addRow: (type: tInvoiceRowType) => void;
  showGross?: boolean;
  showContractor?: boolean;
  showReport?: boolean;
  row?: { rowLength: number; maxRowLength: number };
};

const AddRow: React.FC<tAddRow> = ({
  type,
  edit,
  addRow,
  showGross = true,
  showContractor = true,
  showReport = false,
  row = null,
}): JSX.Element | null => {
  const { t } = useTranslation();

  if (!edit) return null;

  const addRowHandler = () => {
    addRow(type);
  };

  return (!!row && row?.rowLength < row?.maxRowLength) || row === null ? (
    <tr>
      <AddButtonCell>
        <FlexHWrapper>
          <Icon asButton icon='addBox' fill={colorBlueBasic} onClick={addRowHandler} />
          <HorizontalMarginSmall>{t('Add')}</HorizontalMarginSmall>
        </FlexHWrapper>
      </AddButtonCell>
      <Cell colSpan={2} />
      <Separator />
      <Cell colSpan={showGross ? 3 : 2} />
      {showContractor && (
        <>
          <Separator />
          <Cell colSpan={showGross ? 3 : 2} />
        </>
      )}
      {showReport && <Separator />}
    </tr>
  ) : null;
};

export default AddRow;
