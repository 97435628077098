import { useCallback, useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { useSelector } from 'react-redux';
import tStore from 'types/store';
import { useTranslation } from 'react-i18next';
import {
  Spinner,
  PageWrapper,
  ComplexTable,
  Breadcrumbs,
  Tiles,
  ListTiles,
  ListTitle,
} from 'components';
import { useFetch } from 'hooks';
import mruService from 'services/mru';
import { tGetAllMruData } from 'types/services/mru';
import { tGetAll, tKeyString } from 'types/global';
import { ListBreadcrumb, ListTableContainer } from 'styles/GlobalStyledComponents';
import { tableHead } from './TableConfig';

interface iMruList extends RouteComponentProps<any> {}

const MruList: React.FC<iMruList> = ({ history }): JSX.Element => {
  const { t } = useTranslation();

  const permissions = useSelector((state: tStore) => state.user.permissions);
  const canWrite = !!permissions.includes('tunAddressesWrite');

  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [sort, setSort] = useState<tKeyString>({ by: '', order: '' });

  const {
    fetch: setParamsHandler,
    loading,
    data,
    count,
  } = useFetch<tGetAllMruData, tGetAll>(mruService.getAll);

  const setRequestParams = useCallback(
    (newOffset: number, newLimit: number) => {
      if (newOffset !== offset) setOffset(newOffset);
      if (newLimit !== limit) setLimit(newLimit);
    },
    [limit, offset],
  );

  const fetchData = useCallback(
    () => setParamsHandler({ search, limit, offset, ordering: sort }),
    [search, limit, offset, sort, setParamsHandler],
  );

  const setSortParams = (by: string, order: string) => {
    setSort({ by, order });
  };

  useEffect(() => {
    setOffset(0);
  }, [search]);

  useEffect(() => {
    fetchData();
  }, [limit, offset, sort, fetchData]);

  const changeRoute = () => history.push('/mru/new');

  const showMRUDetails = (id: number | string) => history.push(`/mru/${id}`);

  const dataWithSapNo = data.map((row) => ({ ...row, sapNumber: row?.sapNumber || t('To fill') }));

  return (
    <PageWrapper>
      {loading && <Spinner />}
      <ListBreadcrumb>
        <Breadcrumbs />
      </ListBreadcrumb>
      <ListTitle title={t('MRU base')} />
      <ListTiles>
        <Tiles
          text={t('Create new MRU')}
          icon='apartment'
          bgIcon='addComment'
          color='orange'
          variant='filled'
          onClick={changeRoute}
          disabled={!canWrite}
        />
      </ListTiles>
      <ListTableContainer>
        <ComplexTable
          isRowClickable
          onRowClick={showMRUDetails}
          value={search}
          resetValue={() => setSearch('')}
          onChange={({ target: { value } }) => setSearch(value)}
          name='tableSearch'
          tableBodyData={dataWithSapNo}
          tableHead={tableHead}
          setSortParams={(field: string, sort: string) => setSortParams(field, sort)}
          totalRecords={count}
          limit={limit}
          offset={offset}
          setParams={(offset: number, limit: number) => setRequestParams(offset, limit)}
          tableName='MRU list'
        />
      </ListTableContainer>
    </PageWrapper>
  );
};

export default withRouter(MruList);
