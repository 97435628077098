export const allowedExtension = [
  'image/png',
  'png',
  'image/jpeg',
  'jpg',
  'application/pdf',
  'pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'docx',
  'doc',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'xlsx',
  'xls',
  'application/vnd.ms-excel',
  'application/msword',
];

class File {
  public static checkFileSize(size: number) {
    const sizeInMB = size / (1024 * 1024);
    const sizeError = 'File is too big';
    return (sizeInMB > 5 && sizeError) || false;
  }

  public static checkFileExtension(ext: string, accept: string[]) {
    const extError = 'File extension is not allowed';
    if ((accept.length && !accept.includes(ext)) || !allowedExtension.includes(ext)) {
      return extError;
    }
    if (!allowedExtension.includes(ext)) return extError;
    return false;
  }

  public static convertType(type: string) {
    switch (type) {
      case 'image/png':
      case 'png':
        return 'png';
      case 'image/jpeg':
      case 'jpg':
        return 'jpg';
      case 'application/pdf':
      case 'pdf':
        return 'pdf';
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/msword':
      case 'docx':
      case 'doc':
        return 'doc';
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'application/vnd.ms-excel':
      case 'xlsx':
      case 'xls':
        return 'xls';
      default:
        return type;
    }
  }
}

export default File;
