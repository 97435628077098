import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon';
import { Flex, LeftMargin1Rem } from 'styles/GlobalStyledComponents';
import { colorGreyBasic, colorGreyDark } from 'styles/GlobalStyles';
import {
  ExpansionButton,
  ExpansionChildrenContainer,
  ExpansionIconContainer,
  ExpansionLineGap,
  ExpansionTitle,
  ExpansionOptionsWrapper,
  ExpansionTitleContainer,
  ExpansionCenteredContainer,
} from './ExpansionPanel.style';

export type tAction = {
  actionId: number;
  action: JSX.Element;
  alignLeft?: boolean;
};

type tExpansionPanel = {
  actions?: tAction[];
  children: (JSX.Element | string | false)[] | JSX.Element | string;
  hasBottomBorder?: boolean;
  hasIcon?: boolean;
  iconName?: string;
  shouldExpanded?: boolean;
  title: string;
  expanded?: boolean;
  centered?: boolean;
  onArrowClick?: Function;
  titleStyles?: React.CSSProperties;
};

export type CenteredTitleStyle = {
  centered?: boolean;
  style?: React.CSSProperties;
};

const ExpansionPanel: React.FC<tExpansionPanel> = ({
  actions = [],
  children = <div>-</div>,
  hasBottomBorder = false,
  hasIcon = false,
  iconName = 'group',
  shouldExpanded = true,
  title = '-',
  expanded = true,
  centered = false,
  onArrowClick,
  titleStyles,
}) => {
  const { t } = useTranslation();
  const [showChildren, setShowChildren] = useState<boolean>(expanded);
  const onExpandClick = () => {
    setShowChildren(!showChildren);
    if (typeof onArrowClick === 'function') onArrowClick();
  };

  const renderExpand = (reverse = false) => {
    const iconWithoutChildren = reverse ? 'arrowDown' : 'arrowUp';
    const iconWithChildren = reverse ? 'arrowUp' : 'arrowDown';

    return (
      shouldExpanded && (
        <ExpansionButton type='button' onClick={onExpandClick}>
          <Icon
            icon={!showChildren ? iconWithoutChildren : iconWithChildren}
            fill={centered ? colorGreyDark : colorGreyBasic}
          />
        </ExpansionButton>
      )
    );
  };

  const actionsLeft = actions.filter((act) => act?.alignLeft);
  const actionsRight = actions.filter((act) => !act?.alignLeft);

  const renderActions = (actions: tAction[]) => {
    if (!actions.length) return null;

    return (
      <ExpansionOptionsWrapper>
        {actions.map((item: tAction) => {
          const { actionId, action } = item;

          return <LeftMargin1Rem key={actionId}>{action}</LeftMargin1Rem>;
        })}
      </ExpansionOptionsWrapper>
    );
  };

  const renderOptionals = () => (
    <Flex>
      {renderActions(actionsRight)}
      {renderExpand(true)}
    </Flex>
  );

  return (
    <>
      {centered ? (
        <>
          <ExpansionCenteredContainer>
            <ExpansionTitle centered={centered} style={titleStyles}>
              {t(title)}
            </ExpansionTitle>
            {renderOptionals()}
          </ExpansionCenteredContainer>
          <ExpansionLineGap />
        </>
      ) : (
        <ExpansionTitleContainer>
          <Flex>
            {hasIcon && (
              <ExpansionIconContainer>
                <Icon icon={iconName} fill={colorGreyBasic} />
              </ExpansionIconContainer>
            )}
            <ExpansionTitle style={titleStyles}>{t(title)}</ExpansionTitle>
            {renderActions(actionsLeft)}
          </Flex>
          {renderOptionals()}
        </ExpansionTitleContainer>
      )}
      {showChildren && <ExpansionChildrenContainer>{children}</ExpansionChildrenContainer>}
      {hasBottomBorder && showChildren && <ExpansionLineGap />}
    </>
  );
};

export default ExpansionPanel;
