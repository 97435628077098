import { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  ExpansionPanel,
  Input,
  Row,
  Column,
  Switch,
  InputDropdown,
  DropdownWithList,
  Timepicker,
  Label,
  DropdownV2,
  Spinner,
} from 'components';
import { tOption, tError } from 'types/global';
import { tListOption } from 'components/DropdownWithList/DropdownWithList';
import { useFetch } from 'hooks';
import contractsService from 'services/contracts';
import { FormWrapper, Flex, TopMargin1Rem } from 'styles/GlobalStyledComponents';
import {
  TimePickersWrapper,
  TimePickersPreviewWrapper,
  TimePickersSpan,
} from '../ContractData.style';

type tFormValues = {
  billingCurrency: {
    label: string;
    value: string;
  };
  VATRate: {
    label: string;
    value: string;
  };
  invoicingInterval: {
    value: string;
    optionLabel: string;
  };
  billingPeriodTo: number;
  acceptanceOfSettlement: boolean;
  timeForAcceptance: number;
  nightModeStart: string;
  nightModeEnd: string;
  valorizationIndicator: {
    label: string;
    value: string;
    id: number;
  }[];
  paymentDeadline: {
    value: string;
    optionLabel: string;
  };
  valorizationPeriod: {
    value: string;
    optionLabel: string;
  };
};

type tFinancialConditionsForm = {
  disabled?: boolean;
  preview?: boolean;
  handleChange: (event) => void;
  errors: tError[];
  values: tFormValues;
};

const FinancialConditionsForm: React.FC<tFinancialConditionsForm> = ({
  disabled = false,
  preview = false,
  handleChange,
  errors,
  values,
}): JSX.Element => {
  const { t } = useTranslation();
  const pathLocation = useLocation();
  const {
    fetch: setCurrenciesParamsHandler,
    loading: currenciesDataLoading,
    data: currenciesData,
  } = useFetch<tOption>(contractsService.getCurrencies, 'An error occurred while getting currency');
  const {
    fetch: setVATRatesParamsHandler,
    loading: VATRatesDataLoading,
    data: VATRatesData,
  } = useFetch<tOption>(contractsService.getVATRates, 'An error occurred while getting VAT rates');
  const {
    fetch: setPaymentDeadlinesParamsHandler,
    loading: paymentDeadlinesDataLoading,
    data: paymentDeadlinesData,
  } = useFetch<tOption>(
    contractsService.getPaymentDeadlines,
    'An error occurred while getting payment deadlines',
  );
  const {
    fetch: setBillingIntervalUnitsParamsHandler,
    loading: billingIntervalUnitsDataLoading,
    data: billingIntervalUnitsData,
  } = useFetch<tOption>(
    contractsService.getBillingIntervalUnits,
    'An error occurred while getting billing interval units',
  );
  const {
    fetch: setValorizationIntervalUnitsParamsHandler,
    loading: valorizationIntervalUnitsDataLoading,
    data: valorizationIntervalUnitsData,
  } = useFetch<tOption>(
    contractsService.getValorizationIntervalUnits,
    'An error occurred while getting valorization interval units data',
  );
  const {
    fetch: setValorizationParamsHandler,
    loading: valorizationsDataLoading,
    data: valorizationsData,
  } = useFetch<tListOption>(
    contractsService.getValorizations,
    'An error occurred while getting valorizations indicator units',
  );

  const fetchData = useCallback(() => {
    setVATRatesParamsHandler({});
    setCurrenciesParamsHandler();
    setPaymentDeadlinesParamsHandler();
    setBillingIntervalUnitsParamsHandler();
    setValorizationIntervalUnitsParamsHandler();
    setValorizationParamsHandler();
  }, [
    setCurrenciesParamsHandler,
    setVATRatesParamsHandler,
    setPaymentDeadlinesParamsHandler,
    setBillingIntervalUnitsParamsHandler,
    setValorizationIntervalUnitsParamsHandler,
    setValorizationParamsHandler,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const {
    billingCurrency,
    VATRate,
    invoicingInterval,
    billingPeriodTo,
    acceptanceOfSettlement,
    timeForAcceptance,
    nightModeStart,
    nightModeEnd,
    paymentDeadline,
    valorizationPeriod,
    valorizationIndicator,
  } = values;

  const isValorizationIndicatorDisabled = () => {
    const disabled =
      valorizationIndicator?.length &&
      valorizationIndicator.find((val) => val.value === 'Zakaz' || val.value === 'Brak');
    return !!disabled;
  };

  const isItemRemovable =
    pathLocation.pathname.includes('new') || pathLocation.pathname.includes('edit');

  const isLoading =
    currenciesDataLoading ||
    VATRatesDataLoading ||
    paymentDeadlinesDataLoading ||
    billingIntervalUnitsDataLoading ||
    valorizationIntervalUnitsDataLoading ||
    valorizationsDataLoading;

  return (
    <TopMargin1Rem>
      <ExpansionPanel
        shouldExpanded={false}
        title='Financial conditions'
        hasBottomBorder={!disabled && !preview}
        hasIcon
        iconName='wallet'
      >
        {isLoading && <Spinner />}
        <FormWrapper>
          <Row justifyContent='space-between'>
            <Column>
              <DropdownV2
                options={currenciesData.map((option) => ({
                  ...option,
                  fieldName: 'billingCurrency',
                }))}
                name='billingCurrency'
                label='Billing currency'
                onChange={handleChange}
                errors={errors}
                value={billingCurrency}
                disabled={disabled}
                preview={preview}
              />
            </Column>
            <Column>
              <DropdownV2
                name='VATRate'
                label='VAT rate'
                options={VATRatesData.map((option) => ({
                  ...option,
                  fieldName: 'VATRate',
                }))}
                onChange={handleChange}
                errors={errors}
                value={VATRate}
                disabled={disabled}
                preview={preview}
              />
            </Column>
            <Column>
              <Flex>
                <InputDropdown
                  name='paymentDeadline'
                  label='Payment deadline'
                  dropdownOptions={paymentDeadlinesData}
                  values={paymentDeadline}
                  errors={errors}
                  disabled={disabled}
                  preview={preview}
                  onChange={handleChange}
                  inputSufix={t('day', { count: +paymentDeadline?.value })}
                />
              </Flex>
            </Column>
            <Column>
              <InputDropdown
                name='invoicingInterval'
                label='Invoicing interval'
                dropdownOptions={billingIntervalUnitsData.map(({ value, label }) => ({
                  value,
                  label: t(label, { count: +invoicingInterval?.value }),
                }))}
                values={invoicingInterval}
                errors={errors}
                onChange={handleChange}
                disabled={disabled}
                preview={preview}
              />
            </Column>
            <Column>
              <Input
                name='billingPeriodTo'
                label='Billing period to'
                onChange={handleChange}
                errors={errors}
                value={billingPeriodTo}
                disabled={disabled}
                preview={preview}
                inputSufix={t('day', { count: +billingPeriodTo })}
              />
            </Column>
            <Column>
              <Switch
                name='acceptanceOfSettlement'
                id='acceptanceOfSettlement'
                label='Acceptance of the settlement'
                onChange={handleChange}
                errors={errors}
                checked={acceptanceOfSettlement}
                disabled={disabled}
                preview={preview}
              />
            </Column>
            <Column>
              <Input
                name='timeForAcceptance'
                label='Time for acceptance'
                onChange={handleChange}
                errors={errors}
                value={timeForAcceptance}
                disabled={disabled}
                preview={preview}
                inputSufix={t('day', { count: +timeForAcceptance })}
              />
            </Column>
            <Column>
              <DropdownWithList
                dropdownDisabled={disabled || isValorizationIndicatorDisabled()}
                preview={preview}
                dropdownErrors={errors}
                dropdownLabel='Valorization indicator'
                labelOptional
                optionsData={valorizationsData.map((item) => ({
                  ...item,
                  fieldName: 'valorizationIndicator',
                }))}
                optionFieldName='valorizationIndicator'
                handleChange={handleChange}
                defaultItems={!!valorizationIndicator?.length ? valorizationIndicator : []}
                isItemRemovable={isItemRemovable}
              />
            </Column>
            <Column>
              <InputDropdown
                name='valorizationPeriod'
                label='Valorization period'
                values={valorizationPeriod}
                dropdownOptions={valorizationIntervalUnitsData.map(({ value, label }) => ({
                  value,
                  label: t(label, { count: +valorizationPeriod?.value }),
                }))}
                errors={errors}
                disabled={disabled}
                preview={preview}
                onChange={handleChange}
              />
            </Column>
            <Column>
              {preview ? (
                <TimePickersPreviewWrapper>
                  <Label>{t('Night mode')}</Label>
                  {`${nightModeStart ?? '-'}${!!nightModeStart && !!nightModeEnd ? ' — ' : '-'}${
                    nightModeEnd ?? '-'
                  }`}
                </TimePickersPreviewWrapper>
              ) : (
                <TimePickersWrapper>
                  <Label optional>{t('Night mode')}</Label>
                  <Timepicker
                    name='nightModeStart'
                    value={nightModeStart}
                    errors={errors}
                    fullWidth
                    disabled={disabled}
                    preview={preview}
                    onChange={handleChange}
                    labelMinWidth={0}
                    canZero
                  />
                  <TimePickersSpan>—</TimePickersSpan>
                  <Timepicker
                    name='nightModeEnd'
                    value={nightModeEnd}
                    errors={errors}
                    fullWidth
                    disabled={disabled}
                    preview={preview}
                    onChange={handleChange}
                    labelMinWidth={0}
                    canZero
                  />
                </TimePickersWrapper>
              )}
            </Column>
          </Row>
        </FormWrapper>
      </ExpansionPanel>
    </TopMargin1Rem>
  );
};

export default FinancialConditionsForm;
