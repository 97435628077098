/* eslint-disable camelcase */
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addNotification } from 'store/notifications/actions';
import { withRouter, RouteComponentProps } from 'react-router';
import { useFetch, useFormErrorsReset } from 'hooks';
import contractualPenaltiesService, { tPenaltyResponseObject } from 'services/contractualPenalties';
import { Button, Column, Dropdown, Icon, Input, Modal, Row } from 'components';
import { validateRequiredFieldsWithLoc } from 'utils/form';
import { colorBlueBasic, colorGreenBasic, colorGreyBasic } from 'styles/GlobalStyles';
import { catchErrors } from 'utils/helpers';
import { tGet, tEnumsResult, tError, tKeyString } from 'types/global';
import { tGetContractualPenaltiesData } from 'types/services/contracts';
import { Flex, Relative } from 'styles/GlobalStyledComponents';
import { REQUIRED_FIELDS } from 'utils/requiredFormFields';
import {
  AcceptedPenaltyNameBox,
  FlexBox,
  Header,
  MarginBox,
  NoPenalties,
  PaddingBox,
} from './ContractualPenaltiesForm.style';
import { IconWrapper } from '../PriceListBillingActivities/SinglePriceListBillingActivitiesForm.style';

interface iContractualPenaltiesForm extends RouteComponentProps<any> {
  disabled?: boolean;
  preview?: boolean;
  openRightPanel: Function;
}

type tPenalty = {
  acceptedPenaltyName: string;
  discountValue: null | number;
  id: number;
  moneyOrPercentageValue: number | null;
  penaltyName: string;
  penaltyType: string;
  unitType: string;
  unitValue: number;
  show: boolean;
};

const ContractualPenaltiesForm: React.FC<iContractualPenaltiesForm> = ({
  disabled = false,
  preview = false,
  openRightPanel,
  match,
}): JSX.Element => {
  const { id: contractId } = match.params;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [penalties, setPenalties] = useState<any[]>([]);
  const [addMode, setAddMode] = useState<boolean>(true);
  const [errors, setErrors] = useState<tError[]>([]);
  const [filteredErrors, setFilteredErrors] = useState<tError[]>([]);
  const { formElemErrors, resetFormError } = useFormErrorsReset(filteredErrors);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    fetch: setParamsHandlerPenaltiesDiscountTypes,
    loading: loadingPenaltiesDiscountTypes,
    data: dataPenaltiesDiscountTypes,
  } = useFetch<tEnumsResult>(
    contractualPenaltiesService.getPenaltiesDiscountTypes,
    'Something went wrong while fetching data',
  );
  const {
    fetch: setParamsHandlerPenaltiesUnits,
    loading: loadingPenaltiesUnits,
    data: dataPenaltiesUnits,
  } = useFetch<tEnumsResult>(
    contractualPenaltiesService.getPenaltiesUnits,
    'Something went wrong while fetching data',
  );
  const {
    fetch: setParamsHandlerPenaltiesTypes,
    loading: loadingPenaltiesTypes,
    data: dataPenaltiesTypes,
  } = useFetch<tEnumsResult>(
    contractualPenaltiesService.getPenaltiesTypes,
    'Something went wrong while fetching data',
  );
  const {
    fetch: setParamsHandler,
    loading: loadingData,
    data,
  } = useFetch<tGetContractualPenaltiesData, tGet>(
    contractualPenaltiesService.getContractualPenalties,
    'Something went wrong while fetching data',
  );

  const fetchDataPenaltiesDiscountTypes = useCallback(
    () => setParamsHandlerPenaltiesDiscountTypes(),
    [setParamsHandlerPenaltiesDiscountTypes],
  );

  const fetchDataPenaltiesUnits = useCallback(
    () => setParamsHandlerPenaltiesUnits(),
    [setParamsHandlerPenaltiesUnits],
  );

  const fetchDataPenaltiesTypes = useCallback(
    () => setParamsHandlerPenaltiesTypes(),
    [setParamsHandlerPenaltiesTypes],
  );

  const fetchData = useCallback(() => {
    if (!!contractId) setParamsHandler({ id: Number(contractId) });
  }, [setParamsHandler, contractId]);

  const setNotificationHandler = (message: string, type = 'error' as 'error' | 'success') => {
    const enableTimeout = type === 'success';
    dispatch(addNotification(t(message), type, enableTimeout));
  };

  useEffect(() => {
    setFilteredErrors(errors);
  }, [errors]);

  useEffect(() => {
    fetchDataPenaltiesDiscountTypes();
  }, [fetchDataPenaltiesDiscountTypes]);

  useEffect(() => {
    fetchDataPenaltiesUnits();
  }, [fetchDataPenaltiesUnits]);

  useEffect(() => {
    fetchDataPenaltiesTypes();
  }, [fetchDataPenaltiesTypes]);

  useEffect(() => {
    fetchData();
  }, [fetchData, preview, disabled]);

  useEffect(() => {
    if (!!data.length) {
      setAddMode(false);
      setPenalties(data);
    }
  }, [data]);

  const setResponseErrors = (setter: Function, errors: tKeyString[]) => {
    const transformField = (field: string, loc): string => {
      const fieldId = loc[1];
      switch (field) {
        case 'name':
          return `penaltyName-${fieldId}`;
        case 'penalty_unit':
          return `unitType-${fieldId}`;
        case 'unit_amount':
          return `unitValue-${fieldId}`;
        case 'discount':
          return `discountValue-${fieldId}`;
        case 'discount_type':
          return `moneyOrPercentageValue-${fieldId}`;
        case 'type':
          return `penaltyType-${fieldId}`;
        case 'money_amount':
          return `moneyOrPercentageValue-${fieldId}`;
        default:
          return '__noField__';
      }
    };

    setter(
      errors
        ? errors?.map((error: tKeyString) => {
            const { key, msg, loc } = error;
            return {
              field: transformField(key, loc),
              error: msg,
              errorIndex: loc[1],
            };
          })
        : [],
    );
  };

  const addPenaltyButton = () => (
    <MarginBox value='1rem 0 0'>
      <Button
        disabled={disabled}
        icon='addCircle'
        variant='blue'
        onClick={() =>
          setPenalties([
            ...penalties,
            {
              id: new Date().getTime(),
              acceptedPenaltyName: '',
              penaltyName: '',
              penaltyType: '',
              unitValue: '',
              unitType: '',
              moneyOrPercentageValue: '',
              discountValue: '',
              show: true,
            },
          ])
        }
      >
        {t('Add')}
      </Button>
    </MarginBox>
  );

  const removePenalty = (id: number | string) => {
    setPenalties(
      penalties.map((penalty) => {
        if (penalty.id === id) {
          return {
            ...penalty,
            show: false,
          };
        }
        return penalty;
      }),
    );
  };

  const updateFieldChanged = (field: string, index: number, value: string) => {
    setFilteredErrors(resetFormError(`${field}-${index}`));
    setPenalties(
      penalties.map((item: any, itemIndex: number) => {
        if (index === itemIndex) {
          return { ...item, [field]: value };
        }

        return item;
      }),
    );
  };

  const onSubmit = async () => {
    try {
      setLoading(true);

      if (!!penalties.length) {
        const transformValue = (value: string | number | null): string | number | null => {
          switch (value) {
            case 'Od wartości usługi':
              return 'service_value';
            case 'Od wartości robocizny':
              return 'labor_value';
            case 'Od kwoty miesięcznej faktury':
              return 'invoice';
            default:
              return value;
          }
        };

        const bodyRequest: tPenaltyResponseObject[] = penalties
          .filter((penalty) => penalty.show)
          .map((penalty: tPenalty, index) => {
            const {
              acceptedPenaltyName: name,
              discountValue,
              penaltyType,
              unitValue: unit_amount,
              unitType,
              moneyOrPercentageValue,
            } = penalty;

            const typeAmountFromApi = 'amount';
            const typeAmountPl = 'Kwota';
            const typeDiscountFromApi = 'discount';
            const typeDiscountPl = 'Rabat';
            const unitHourFromApi = 'hour';
            const unitHourPl = 'Godzina';
            const unitPicePl = 'Sztuka';
            const unitItemFromApi = 'item';

            let type = '';
            if (penaltyType === typeAmountPl) type = typeAmountFromApi;
            if (penaltyType === typeDiscountPl) type = typeDiscountFromApi;
            let penalty_unit = '';
            if (unitType === unitHourPl) penalty_unit = unitHourFromApi;
            if (unitType === unitPicePl) penalty_unit = unitItemFromApi;
            const money_amount =
              type === typeDiscountFromApi ? null : transformValue(moneyOrPercentageValue);
            const discount = type === typeDiscountFromApi ? discountValue : null;
            const discount_type =
              type === typeAmountFromApi ? null : transformValue(moneyOrPercentageValue);

            return {
              discount,
              discount_type,
              money_amount,
              name,
              penalty_unit,
              type,
              unit_amount,
              index,
            };
          });

        const requiredFields = REQUIRED_FIELDS.CONTRACTS_FORM.CONTRACTUAL_PENALTIES;

        const requiredFieldsWithDiscount =
          REQUIRED_FIELDS.CONTRACTS_FORM.CONTRACTUAL_PENALTIES_FORM_DISCOUNT;
        const requiredFieldsMoneyAmount =
          REQUIRED_FIELDS.CONTRACTS_FORM.CONTRACTUAL_PENALTIES_FORM_MONEY_AMOUNT;

        const penaltiesWithoutType = bodyRequest.filter((penalty) => penalty.type === '');
        const penaltiesWithTypeDiscount = bodyRequest.filter(
          (penalty) => penalty.type === 'discount',
        );
        const penaltiesWithTypeMoneyAmount = bodyRequest.filter(
          (penalty) => penalty.type === 'amount',
        );

        const validationErrorsWithoutType = validateRequiredFieldsWithLoc(
          penaltiesWithoutType,
          requiredFields,
        );
        const validationErrorsWithTypeDiscount = validateRequiredFieldsWithLoc(
          penaltiesWithTypeDiscount,
          requiredFieldsWithDiscount,
        );
        const validationErrorsWithTypeMoneyAmount = validateRequiredFieldsWithLoc(
          penaltiesWithTypeMoneyAmount,
          requiredFieldsMoneyAmount,
        );

        const validationErrors = [
          ...validationErrorsWithoutType,
          ...validationErrorsWithTypeMoneyAmount,
          ...validationErrorsWithTypeDiscount,
        ];

        if (validationErrors.length) return setResponseErrors(setErrors, validationErrors);

        const result = addMode
          ? await contractualPenaltiesService.addPenaltiesToContract(bodyRequest, contractId)
          : await contractualPenaltiesService.updatePenalties(bodyRequest, contractId);

        if (result.status === 201 || result.status === 200) {
          setNotificationHandler('Contractual penalties changed', 'success');
          fetchData();
        }

        setErrors([]);
      }
    } catch (error) {
      const setters = { setNotificationHandler, setResponseErrors, setErrors };
      catchErrors(error, setters);
      setPenalties(penalties.filter(({ show }) => show === true));
    } finally {
      setLoading(false);
    }
  };

  const isLoading =
    loading ||
    loadingData ||
    loadingPenaltiesTypes ||
    loadingPenaltiesUnits ||
    loadingPenaltiesDiscountTypes;

  return (
    <Modal
      cancelButtonText={t('Close')}
      cancelMethod={() => openRightPanel({ form: '', id: null })}
      confirmButtonText={t('Save')}
      confirmMethod={onSubmit}
      icon='gavel'
      title={t('Penalties list')}
      width='60vw'
      loading={isLoading}
      showOnlyCancel={disabled || preview}
    >
      <MarginBox value='0 -1rem'>
        {penalties.length ? (
          <>
            <MarginBox value='0 0 1rem'>
              <Header>
                <FlexBox flex={2.5}>{t('Name/description')}</FlexBox>
                <FlexBox flex={1.5}>{t('Penalty type')}</FlexBox>
                <FlexBox flex={2}>{t('Units of measure')}</FlexBox>
                <FlexBox flex={2} style={{ paddingLeft: '2rem' }}>
                  {t('Amount or dimension')}
                </FlexBox>
              </Header>
              {penalties.map((item: any, index: number) => {
                const { id: itemId, penaltyName, acceptedPenaltyName, show } = item;
                if (show) {
                  return (
                    <Flex key={itemId}>
                      <FlexBox flex='2.5'>
                        <PaddingBox value='0 2rem 0 0'>
                          {!!acceptedPenaltyName ? (
                            <AcceptedPenaltyNameBox>
                              <PaddingBox value='0 1rem 0 0'>{acceptedPenaltyName}</PaddingBox>
                              {!disabled && !preview && (
                                <Icon
                                  asButton
                                  icon='edit'
                                  onClick={() =>
                                    updateFieldChanged('acceptedPenaltyName', index, '')
                                  }
                                  fill={colorBlueBasic}
                                />
                              )}
                              {!disabled && !preview && (
                                <IconWrapper>
                                  <Icon
                                    asButton
                                    icon='close'
                                    fill='red'
                                    onClick={() => removePenalty(itemId)}
                                  />
                                </IconWrapper>
                              )}
                            </AcceptedPenaltyNameBox>
                          ) : (
                            <Relative>
                              <Row justifyContent='space-between'>
                                <Column col={8}>
                                  <Input
                                    name={`penaltyName-${index}`}
                                    onChange={({ target: { value } }) =>
                                      updateFieldChanged('penaltyName', index, value)
                                    }
                                    errors={formElemErrors}
                                    value={penalties[index].penaltyName}
                                    disabled={disabled}
                                    preview={preview}
                                    labelMinWidth={3}
                                  />
                                </Column>
                                <Column col={2} offset={1}>
                                  <MarginBox value='-0.875rem 0 0'>
                                    <Icon
                                      asButton
                                      icon='checkCircle'
                                      onClick={() =>
                                        !penaltyName
                                          ? {}
                                          : updateFieldChanged(
                                              'acceptedPenaltyName',
                                              index,
                                              penaltyName,
                                            )
                                      }
                                      fill={!penaltyName ? colorGreyBasic : colorGreenBasic}
                                    />
                                  </MarginBox>
                                </Column>
                                <Column col={2}>
                                  <MarginBox value='-0.875rem 0 0'>
                                    <Icon
                                      asButton
                                      icon='close'
                                      fill='red'
                                      onClick={() => removePenalty(itemId)}
                                    />
                                  </MarginBox>
                                </Column>
                              </Row>
                            </Relative>
                          )}
                        </PaddingBox>
                      </FlexBox>
                      <FlexBox flex={1.5}>
                        <PaddingBox value='0 3rem 0 0'>
                          <Dropdown
                            name={`penaltyType-${index}`}
                            options={dataPenaltiesTypes}
                            onChange={(target) =>
                              updateFieldChanged('penaltyType', index, target.label)
                            }
                            errors={formElemErrors}
                            value={penalties[index].penaltyType}
                            disabled={disabled}
                            preview={preview}
                            labelMinWidth={3}
                          />
                        </PaddingBox>
                      </FlexBox>
                      <FlexBox flex={2}>
                        <PaddingBox value='0 3rem 0 0'>
                          <Row justifyContent='space-between' alignItems='flex-start'>
                            <Column col={4}>
                              <Input
                                name={`unitValue-${index}`}
                                onChange={({ target: { value } }) =>
                                  updateFieldChanged('unitValue', index, value)
                                }
                                errors={formElemErrors}
                                value={penalties[index].unitValue}
                                disabled={disabled}
                                preview={preview}
                                labelMinWidth={0}
                              />
                            </Column>
                            <Column col={8} offset={1}>
                              <Dropdown
                                name={`unitType-${index}`}
                                options={dataPenaltiesUnits}
                                onChange={(target) =>
                                  updateFieldChanged('unitType', index, target.label)
                                }
                                errors={formElemErrors}
                                value={penalties[index].unitType}
                                disabled={disabled}
                                preview={preview}
                                labelMinWidth={3}
                              />
                            </Column>
                          </Row>
                        </PaddingBox>
                      </FlexBox>
                      <FlexBox flex={2} style={{ paddingLeft: '2rem' }}>
                        {penalties[index].penaltyType === t('Amount') && (
                          <Row justifyContent='space-between'>
                            <Column>
                              <Input
                                name={`moneyOrPercentageValue-${index}`}
                                onChange={({ target: { value } }) =>
                                  updateFieldChanged('moneyOrPercentageValue', index, value)
                                }
                                errors={formElemErrors}
                                value={penalties[index].moneyOrPercentageValue}
                                disabled={disabled}
                                preview={preview}
                                labelMinWidth={5}
                              />
                            </Column>
                          </Row>
                        )}
                        {penalties[index].penaltyType === t('Discount') && (
                          <Row justifyContent='space-between' alignItems='flex-start'>
                            <Column col={3}>
                              <Input
                                name={`discountValue-${index}`}
                                onChange={({ target: { value } }) =>
                                  updateFieldChanged('discountValue', index, value)
                                }
                                errors={formElemErrors}
                                value={penalties[index].discountValue}
                                disabled={disabled}
                                preview={preview}
                                labelMinWidth={0}
                              />
                            </Column>
                            <Column col={8.5} offset={0.5}>
                              <Dropdown
                                name={`moneyOrPercentageValue-${index}`}
                                options={dataPenaltiesDiscountTypes}
                                onChange={(target) =>
                                  updateFieldChanged('moneyOrPercentageValue', index, target.label)
                                }
                                errors={formElemErrors}
                                value={penalties[index].moneyOrPercentageValue}
                                disabled={disabled}
                                preview={preview}
                                labelMinWidth={2}
                              />
                            </Column>
                          </Row>
                        )}
                      </FlexBox>
                    </Flex>
                  );
                }
                return null;
              })}
            </MarginBox>
            {!preview && addPenaltyButton()}
          </>
        ) : (
          <>
            <NoPenalties>{t('No contractual penalties assigned')}</NoPenalties>
            {!preview && addPenaltyButton()}
          </>
        )}
      </MarginBox>
    </Modal>
  );
};

export default withRouter(ContractualPenaltiesForm);
