import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { tTableActions } from 'types/components/table';
import { tError, tAvatarsFile } from 'types/global';
import store from 'store/store';
import { idleTimeReset } from 'store/user/actions';
import { Dispatch } from 'redux';
import throttle from 'lodash.throttle';
import {
  idleResetThrottleIntervalMS,
  uniqueARPermission,
  uniqueKTITSPermission,
} from 'utils/constants';
import { setSentryError } from 'utils/sentry';
import i18n from 'i18next';
import { tGPSLocation, tShortGPSLocation } from 'types/services/subcontractors';
import { tVisitsList } from 'types/services/issues';
import { tGetAllInvoicesData } from 'types/services/invoices';

export const getTodaysDate = () => {
  const now = new Date();
  let weekDay = now.getDay();
  if (weekDay === 0) weekDay = 6;
  return {
    weekDay,
    day: now.getDate(),
    month: now.getMonth() + 1,
    year: now.getFullYear(),
  };
};

export const extractDate = (date: Date) => {
  let weekDay = date.getDay();
  if (weekDay === 0) weekDay = 7;
  return {
    weekDay,
    day: date.getDate(),
    month: date.getMonth() + 1,
    year: date.getFullYear(),
  };
};

export const dateToDashDDMMYYYY = (date: Date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return [String(day).padStart(2, '0'), String(month).padStart(2, '0'), year].join('-');
};

export const dateToDashDMYYYY = (date: Date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return [day, month, year].join('-');
};

export const dateToDashYYYMMDD = (date: Date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return [year, month, day].join('-');
};

export const dateStringToDashYYYMMDD = (dateString: string | undefined | null) =>
  dateString ? dateToDashYYYMMDD(new Date(dateString)) : null;

export const dateToDashDDMMYYYYColonHHMM = (date: Date, separator = '-') => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear());
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}${separator}${month}${separator}${year} ${hours}:${minutes}`;
};

export const dateToHHMM = (date: Date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return [hours < 10 ? `0${hours}` : hours, minutes < 10 ? `0${minutes}` : minutes].join(':');
};

export const hoursMinutesToHHMM = (hours: number, minutes: number) =>
  `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

export const getWeekNumber = (date: Date): number => {
  const dateValue = new Date(date.valueOf());
  const dayNumber = (date.getDay() + 6) % 7;
  dateValue.setDate(dateValue.getDate() - dayNumber + 3);
  const firstThursday = dateValue.valueOf();
  dateValue.setMonth(0, 1);
  if (dateValue.getDay() !== 4) {
    dateValue.setMonth(0, 1 + ((4 - dateValue.getDay() + 7) % 7));
  }
  return 1 + Math.ceil((firstThursday - Number(dateValue)) / 604800000);
};

export const addDays = (date: Date, days: number): Date => {
  const d1 = new Date(date);
  d1.setDate(date.getDate() + days);
  return d1;
};

export const addMonths = (date: Date, monthsNumber: number): Date => {
  const newDate = new Date(date);
  newDate.setMonth(date.getMonth() + +monthsNumber);
  return newDate;
};

export const getDateRangeOfWeek = (date: Date) => {
  const lastDayOfWeek = addDays(date, 6);
  const rangeIsFrom = dateToDashDDMMYYYY(date);
  const rangeIsTo = dateToDashDDMMYYYY(lastDayOfWeek);
  return [rangeIsFrom, rangeIsTo];
};

export const getFirstDayOfTheWeek = (date: Date) => {
  const numOfdaysPastSinceLastMonday = date.getDay() - 1;
  date.setDate(date.getDate() - numOfdaysPastSinceLastMonday);
  return date;
};

export const getDayNameFromNumber = (weekNumber: number, short = false as boolean) => {
  let weekDay = {
    name: '',
    fullName: '',
  };
  switch (weekNumber) {
    case 1: {
      weekDay = {
        name: 'Mo',
        fullName: 'Monday',
      };
      break;
    }
    case 2: {
      weekDay = {
        name: 'Tu',
        fullName: 'Tuesday',
      };
      break;
    }
    case 3: {
      weekDay = {
        name: 'We',
        fullName: 'Wednesday',
      };
      break;
    }
    case 4: {
      weekDay = {
        name: 'Th',
        fullName: 'Thursday',
      };
      break;
    }
    case 5: {
      weekDay = {
        name: 'Fr',
        fullName: 'Friday',
      };
      break;
    }
    case 6: {
      weekDay = {
        name: 'Sa',
        fullName: 'Saturday',
      };
      break;
    }
    case 7: {
      weekDay = {
        name: 'Su',
        fullName: 'Sunday',
      };
      break;
    }
    default:
  }
  return short ? weekDay.name : weekDay.fullName;
};

export const sortArrayById = (array: any[]) => array.sort((a: any, b: any) => a.id - b.id);

export const getContactStatus = (status, actions): tTableActions[] => {
  let filteredActions = actions;
  const statusList = ['active', 'inactive', 'expired'];
  if (statusList.includes(status)) {
    filteredActions = actions.filter(
      (action) => action.name === 'Edit' || action.name === 'Details',
    );
  }
  if (status === 'archived') {
    filteredActions = actions.filter((action) => action.name === 'Details');
  }
  return filteredActions;
};

export const truncate = (str: string, maxLength = 40) =>
  str.length <= maxLength ? str : `${str.substring(0, maxLength)}...`;

export const capitalizeFirst = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1);

export const timeDiffCalc = (dateFuture: number, dateNow: number): number[] => {
  let diffInMilliseconds = Math.abs(dateFuture - dateNow) / 1000;

  // calculate days
  const days = Math.floor(diffInMilliseconds / 86400);
  diffInMilliseconds -= days * 86400;

  // calculate hours
  const hours = Math.floor(diffInMilliseconds / 3600) % 24;
  diffInMilliseconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliseconds / 60) % 60;
  diffInMilliseconds -= minutes * 60;

  return [days, hours, minutes];
};

export const formatDate = (date: Date, includeTime = false) => {
  let month = String(date.getMonth() + 1);
  let day = String(date.getDate());
  const year = String(date.getFullYear());
  const hour = String(date.getHours());
  let minutes = String(date.getMinutes());

  if (month.length < 2) {
    month = `0${month}`;
  }
  if (day.length < 2) {
    day = `0${day}`;
  }
  if (minutes.length < 2) {
    minutes = `0${minutes}`;
  }

  return includeTime ? `${day}.${month}.${year} ${hour}:${minutes}` : [day, month, year].join('.');
};

export const calculateDate = (value: string, unit: string, date: Date | null) => {
  if (date === null) return null;

  switch (unit) {
    case 'day': {
      return addDays(date, Number(value));
    }
    case 'week': {
      const daysNumber = Number(value) * 7;
      return addDays(date, Number(daysNumber));
    }
    case 'month': {
      return addMonths(date, Number(value));
    }
    default:
      return null;
  }
};

export const addHoursToDate = (
  hoursToAdd: number,
  format = 'nl-NL' as string,
  date = new Date() as Date,
) => {
  date.setHours(date.getHours() + hoursToAdd);
  const formattedDate = date.toLocaleString(format, {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
  return formattedDate;
};

export const addMinutesToDate = (
  minutesToAdd: number,
  format = 'nl-NL' as string,
  date = new Date() as Date,
) => {
  date.setMinutes(date.getMinutes() + minutesToAdd);
  const formattedDate = date.toLocaleString(format, {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
  return formattedDate;
};

export const addDaysToDate = (
  daysToAdd: number,
  format = 'nl-NL' as string,
  date = new Date() as Date,
) => {
  date.setDate(date.getDate() + daysToAdd);
  const formattedDate = date.toLocaleString(format, {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
  return formattedDate;
};

export const uuid = () => {
  let dt = new Date().getTime();

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    // eslint-disable-next-line no-bitwise
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

type tReport = {
  title: string;
  headers: string[];
  columnsWidth: { wch: number }[];
  data: any[];
};

export const downloadReport = ({
  title = '-',
  headers = [],
  columnsWidth = [],
  data = [],
}: tReport) => {
  if (!data.length) return [];

  const date = new Date();
  const fileId = date.getTime();
  const fileName = `${title.split(' ').join('-')}__${fileId}`;
  const s2ab = (dataToConvert: any) => {
    const buf = new ArrayBuffer(dataToConvert.length);
    const view = new Uint8Array(buf);

    // eslint-disable-next-line no-bitwise
    for (let i = 0; i < dataToConvert.length; i + 1) view[i] = dataToConvert.charCodeAt(i) & 0xff;

    return buf;
  };

  const wb = XLSX.utils.book_new();
  const wsData = [headers];

  data.forEach((dataItem: any) => {
    wsData.push(Object.values(dataItem));
  });

  const ws = XLSX.utils.aoa_to_sheet(wsData);

  ws['!cols'] = columnsWidth;
  wb.Sheets[title] = ws;
  wb.SheetNames.push(title);
  wb.Props = {
    Title: title,
    CreatedDate: new Date(),
  };

  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

  return saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
};

export const commonElements = (array1: string[], array2: string[]): string[] =>
  array1.filter((value) => array2.includes(value));

export const removeHtmlTagsFromString = (html: string) =>
  !!html ? html.replace(/(<([^>]+)>)/gi, '') : null;

export const toBase64 = (file: File): any =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const transformErrorsFromApi = (errors, transformField: Function | null = null) => {
  return errors.map(
    ({ key, msg, loc = [] }: { key: string; msg: string; loc: (number | string)[] }) => ({
      field: typeof transformField === 'function' ? transformField(key) : key,
      error: msg,
      loc,
    }),
  );
};

export const errorsLocFilter = (errors: tError[], loc: number | string): tError[] => {
  return [...errors.filter((error) => error?.loc?.includes(loc))];
};

export const getNestedErrors = (errors: tError[], loc: number | string | (number | string)[]) => {
  let errorsClone = JSON.parse(JSON.stringify(errors));
  if (Array.isArray(loc)) {
    loc.forEach((el) => {
      errorsClone = errorsClone.filter((error) => error?.loc && error?.loc.includes(el));
    });
    return errorsClone;
  }
  errorsClone = errorsClone.filter((error) => error?.loc && error?.loc[0] === loc);
  return [...errorsClone.map((err) => ({ ...err, loc: err?.loc?.splice(1) }))];
};

export const assignError = (errors: tError[], field: string) => {
  if (Array.isArray(errors) && !!errors.length) {
    return [{ ...errors[0], field }];
  }
  return [];
};

export const formatBytes = (bytes: number) => {
  if (bytes === 0) return '0 B';
  const sizes = ['B', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${(bytes / 1024 ** i).toFixed(2)} ${sizes[i]}`;
};

export const getAvatarBase64FromFiles = async (files: tAvatarsFile[]) => {
  let base64: null | { filename: string; content: string } = null;
  if (!!files[0]?.currentFile) {
    const base64FullData = await toBase64(files[0].currentFile);
    const content = base64FullData.split('base64,')[1];
    base64 = {
      filename: files[0].currentFile.name,
      content,
    };
  }
  return base64;
};

export const newUID = (data: object[], key = 'id') => {
  const idMap: number[] = data.map((el) => +el[key]);
  if (idMap.length && idMap[0]) {
    return Math.max(...idMap) + 1;
  }
  return 1;
};

export const removeIdFromObjects = (data: object[], key = 'id') => {
  const newData = JSON.parse(JSON.stringify(data));
  if (Array.isArray(newData)) {
    newData.forEach((obj) => {
      delete obj[key]; // eslint-disable-line no-param-reassign
    });
    return newData;
  }
  return null;
};

export const strToCamelCase = (str: string): string => {
  const specialCharsToReplace = ['-', ':', ';', '.', ',', ' '];
  let newStr = str.toLowerCase();

  specialCharsToReplace.forEach((specChar) => {
    newStr = newStr.split(specChar).join('_');
  });
  newStr = newStr
    .split('_')
    .map((el, i) => (i > 0 ? capitalizeFirst(el) : el))
    .join('');
  return newStr;
};

export const snakeToStr = (str: string): string =>
  str
    .split('')
    .map((ch) => (ch === '_' ? ' ' : ch))
    .join('');

const { dispatch }: { dispatch: Dispatch<any> } = store;
export const throttledResetIdleTimer = throttle(
  () => dispatch(idleTimeReset()),
  idleResetThrottleIntervalMS,
);

export const includesAll = (array: string[], keys: string[]) =>
  keys.every((el) => array.includes(el));

export const calcSecondsToDaysMinutesSeconds = (seconds: number): number[] => {
  let absSeconds = Math.abs(seconds);

  // calculate days
  const days = Math.floor(seconds / (60 * 60 * 24));
  absSeconds -= days * (60 * 60 * 24);

  // calculate hours
  const hours = Math.floor(absSeconds / (60 * 60)) % 24;
  absSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(absSeconds / 60) % 60;
  absSeconds -= minutes * 60;

  return [days, hours, minutes];
};

export const daysMinutesSecondsToString = (arr: number[]): string => {
  const [days, hours, minutes] = arr;
  const sDays = days > 0 ? `${days} ${i18n.t('day', { count: +days })}` : '';
  const sHours = hours > 0 ? `${hours} ${i18n.t('hour', { count: +hours })}` : '';
  const sMinutes = minutes > 0 ? `${minutes} ${i18n.t('minute', { count: +minutes })}` : '';
  return `${sDays}${sDays && (sHours || sMinutes) ? ' ' : ''}${sHours}${
    (sDays || sHours) && sMinutes ? ' ' : ''
  }${sMinutes}`;
};

export const formatMonetaryValue = (value: number | null | undefined | string, separator = '.') => {
  const formatedValue = Number(value);

  return formatedValue || value === 0
    ? formatedValue
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        .replace('.', separator)
    : null;
};

export const calculateGrossValue = (value, VATRate) => {
  if (!VATRate || VATRate === 'zwolniony') {
    return Number(value);
  }
  return value * (VATRate.replace('%', '') / 100 + 1);
};

export const catchErrors = (
  error: any,
  setters: {
    setNotificationHandler: (error, type) => void | Dispatch<any>;
    setResponseErrors: (setter, errors) => void;
    setErrors: (value) => void;
  },
) => {
  const { setNotificationHandler, setResponseErrors, setErrors } = setters;

  setSentryError(error);

  const errorsArray = Array.isArray(error) ? error : error?.data;

  const generalErrorsArray =
    !!Array.isArray(errorsArray) && errorsArray?.length
      ? errorsArray.filter((err) => err.key === '__general__')
      : [];

  if (!!Array.isArray(errorsArray) && generalErrorsArray.length) {
    setErrors([]);
    return generalErrorsArray.map((generalError) =>
      setNotificationHandler(generalError.msg, 'error'),
    );
  }
  if (!error || !Object.keys(error?.data).length || error?.status >= 500) {
    return setNotificationHandler('Something went wrong. Try again', 'error');
  }
  if (error?.message) return setNotificationHandler(error.message, 'error');
  if (error?.data?.msg) return setNotificationHandler(error.data.msg, 'error');

  setResponseErrors(setErrors, error?.data);
};

export const userHasARorKTITSPermission = (permissions: string[]) => {
  return permissions.length
    ? !!permissions.find((elem) => elem === uniqueKTITSPermission) ||
        !!permissions.find((elem) => elem === uniqueARPermission)
    : false;
};

export const convertGeolocationForApiFormat = (locations: tShortGPSLocation[]): tGPSLocation[] => {
  return locations.map(({ lat, lng }) => {
    return {
      latitude: lat,
      longitude: lng,
    };
  });
};

export const parseMapPath = (path) => {
  if (typeof path === 'string') {
    return path
      .replace('(', '')
      .split('),')
      .map((coordinates) => {
        const coordinatesArray = coordinates.replace('(', '').replace(')', '').split(', ');

        const [lat, lng] = coordinatesArray;
        return {
          lat: Number(lat),
          lng: Number(lng),
        };
      });
  }
  return path;
};

export const generateReport = (data: BlobPart, outputFilename: string) => {
  const url = URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.download = outputFilename;
  document.body.appendChild(link);
  link.click();
};

export const convertDataToString = (dataFromService: tVisitsList[] | tGetAllInvoicesData[]) => {
  return dataFromService.map((item) => {
    const { opened } = item;

    return {
      ...item,
      bold: !opened,
    };
  });
};
