import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Textarea } from 'components';
import { tError, tKeyString } from 'types/global';
import { LeftMargin1Rem, ModalText, AlignTextLeft } from 'styles/GlobalStyledComponents';
import { catchErrors } from 'utils/helpers';
import issuesService from 'services/issues';

export type tRejectSubcontractorIssueAction = {
  issueId: number | string;
  actions: { [key: string]: string };
  setNotification: (error, type) => void;
  history: { push: Function };
};

const setResponseErrors = (setter: Function, errors: tKeyString[]) => {
  const transformField = (field: string): string => {
    switch (field) {
      case 'rejection_reason':
        return 'reason';
      default:
        return field;
    }
  };
  setter(
    errors
      ? errors?.map((error: tKeyString) => {
          const { key, msg } = error;
          return {
            field: transformField(key),
            error: msg,
          };
        })
      : [],
  );
};

const RejectSubcontractorIssueAction: React.FC<tRejectSubcontractorIssueAction> = ({
  issueId,
  setNotification,
  actions,
  history,
}) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [reason, setReason] = useState<string>('');
  const [errors, setErrors] = useState<tError[]>([]);

  const { reject_by_subcontractor: rejectBySubcontractor } = actions;

  const rejectIssueHandler = async () => {
    if (issueId && reason) {
      try {
        const result = await issuesService.rejectSubcontractorIssue(rejectBySubcontractor, reason);
        if (result?.status === 200) {
          history.push('/issues');
          setNotification(t('Issue was rejected'), 'success');
        }
      } catch (error) {
        const setters = { setNotificationHandler: setNotification, setResponseErrors, setErrors };
        catchErrors(error, setters);
      }
    }
  };

  const reasonInputHandler = (event) => {
    setReason(event?.target?.value);
  };

  const showModalHandler = () => {
    setShowModal(true);
  };

  const cancelMethodHandler = () => {
    setShowModal(false);
  };

  const confirmMethodHandler = () => {
    rejectIssueHandler();
  };

  return (
    <>
      {showModal && (
        <AlignTextLeft>
          <Modal
            icon='announcement'
            cancelMethod={cancelMethodHandler}
            confirmMethod={confirmMethodHandler}
            title={t('Reject issue')}
            textWrapperStyle={{ padding: '1rem 1rem 1rem 1rem' }}
            cancelButtonText={t('Cancel')}
            confirmButtonText={t('Send')}
            confirmDisabled={!reason}
          >
            <ModalText>{t('Provide the reason for rejecting issue.')}</ModalText>
            <Textarea
              name='reason'
              label='Reason'
              onChange={reasonInputHandler}
              errors={errors}
              value={reason}
            />
          </Modal>
        </AlignTextLeft>
      )}
      <LeftMargin1Rem>
        <Button kind='filled' variant='redFilled' onClick={showModalHandler}>
          {t('Reject')}
        </Button>
      </LeftMargin1Rem>
    </>
  );
};

export default RejectSubcontractorIssueAction;
