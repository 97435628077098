import { tTableHead } from 'types/components/table';

export const tableHead: tTableHead[] = [
  {
    sortable: false,
    name: 'No.',
    key: 'order',
  },
  {
    sortable: false,
    name: 'Clients name',
    key: 'clientName',
  },
  {
    sortable: false,
    name: 'Contracts name',
    key: 'name',
  },
  {
    sortable: false,
    name: 'Status',
    key: 'status',
  },
  {
    sortable: false,
    name: 'Contract expiration date',
    key: 'contractExpirationDate',
  },
];
