import { useTranslation } from 'react-i18next';
import { months } from 'utils/constants';

import Icon from 'components/Icon';

import {
  ButtonStyle,
  SelectContainer,
  MonthContainer,
  YearContainer,
  DateContainer,
} from './MonthYearSelect.style';

export type tMonthYearSelect = {
  month: number;
  year: number;
  setYearMonth: (val: Date) => void;
  min?: Date;
  max?: Date;
  disabled?: boolean;
  readOnly?: boolean;
};

const MonthYearSelect: React.FC<tMonthYearSelect> = ({
  month,
  year,
  setYearMonth,
  min,
  max,
  disabled,
  readOnly,
}) => {
  const { t } = useTranslation();

  const subMonthHandler = () => {
    if (!disabled && (!min || month > min.getMonth() + 1 || year > min.getFullYear())) {
      if (month <= 1) {
        setYearMonth(new Date(year - 1, 11));
      } else {
        setYearMonth(new Date(year, month - 2));
      }
    }
  };
  const addMonthHandler = () => {
    if (!disabled && (!max || month < max.getMonth() + 1 || year < max.getFullYear())) {
      if (month >= 12) {
        setYearMonth(new Date(year + 1, 0));
      } else {
        setYearMonth(new Date(year, month));
      }
    }
  };
  const subYearHandler = () => {
    if (!disabled && (!min || year > min.getFullYear())) {
      if (min && month < min.getMonth() - 1 && year <= min.getFullYear() + 1) {
        setYearMonth(new Date(year - 1, min.getMonth()));
      } else {
        setYearMonth(new Date(year - 1, month - 1));
      }
    }
  };
  const addYearHandler = () => {
    if (!disabled && (!max || year < max.getFullYear())) {
      if (max && month > max.getMonth() - 1 && year >= max.getFullYear() - 1) {
        setYearMonth(new Date(year + 1, max.getMonth()));
      } else {
        setYearMonth(new Date(year + 1, month - 1));
      }
    }
  };

  return (
    <SelectContainer>
      {readOnly ? (
        <DateContainer>{`${t(months[month - 1])} ${year}`}</DateContainer>
      ) : (
        <>
          <MonthContainer>
            <ButtonStyle onClick={subMonthHandler} type='button' disabled={disabled}>
              <Icon icon='chevronLeft' />
            </ButtonStyle>
            {t(months[month - 1])}
            <ButtonStyle onClick={addMonthHandler} type='button' disabled={disabled}>
              <Icon icon='chevronRight' />
            </ButtonStyle>
          </MonthContainer>
          <YearContainer>
            <ButtonStyle onClick={subYearHandler} type='button' disabled={disabled}>
              <Icon icon='chevronLeft' />
            </ButtonStyle>
            {year}
            <ButtonStyle onClick={addYearHandler} type='button' disabled={disabled}>
              <Icon icon='chevronRight' />
            </ButtonStyle>
          </YearContainer>
        </>
      )}
    </SelectContainer>
  );
};

export default MonthYearSelect;
